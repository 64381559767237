import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import actionAffiliateGroup from '../../../redux/futures/affiliate_group/actions';

function ModalAddAffiliateGroup(props) {
    const { t } = props;

    const dispatch = useDispatch();
    const [group, setGroup] = useState({});

    const cbAffiliateGroup = () => {
        setGroup({});
        window.LadiUI.closeModal('modal-add-affiliate-group');
    };
    const actionCancel = () => {
        setGroup({});
    };

    const actionOk = () => {
        dispatch(actionAffiliateGroup.create(group, cbAffiliateGroup, true));
    };

    return <>
        <div className="ladiui modal" id="modal-add-affiliate-group">
            <div className="ladiui modal-dialog modal-sm">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">Thêm nhóm Affiliate</label>
                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2" >{t('GROUP_AFFILIATES.NAME')}</label>
                        <input id="name" name="name" className="ladiui form-control" placeholder="Nhập tên nhóm" required
                            value={group.name || ''}
                            onChange={(e) => setGroup({ ...group, name: e.target.value })} />
                    </div>

                    <div className="ladiui clearfix pt-20">
                        <label className="ladiui title-text-form-lever2 left margin-label">{t('GROUP_AFFILIATES.MAKE_AS_DEFAULT')}</label>
                        <div className="ladiui item-form switch">
                            <label className="ladiui switch">
                                <input type="checkbox" id="is_default" name="is_default" checked={group.is_default || false}
                                    onChange={(e) => setGroup({ ...group, is_default: e.target.checked })} />
                                <span className="ladiui slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2" >{t('GROUP_AFFILIATES.DESCRIPTION')}</label>
                        <textarea id="description" name="description" className="ladiui form-control" placeholder="Nhập mô tả" required
                            value={group.description || ''}
                            onChange={(e) => setGroup({ ...group, description: e.target.value })} />
                    </div>

                    <div className="ladiui modal-footer custom-ldp">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <button type="button" data-dismiss="modal" data-target="white-page"
                                className="ladiui btn btn-secondary bold" onClick={actionCancel}>Hủy</button>
                            <button type="button" className="ladiui btn btn-primary" onClick={actionOk}>Thêm mới</button>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    </>;
}

export default (withTranslation()(ModalAddAffiliateGroup));
