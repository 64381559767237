import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LadiDropdown from '../../../../components/LadiDropdown';

function CommissionPlanPaymentBoxOrderSku(props) {
    const conditionSku = [
        { name: 'Chứa tất cả', value: 'AND' },
        { name: 'Chứa 1 trong', value: 'OR' },
    ];

    const {
        t, orderSkuProp, cb,
    } = props;

    const [orderSku, setOrderSku] = useState({ enable: false, condition: 'AND', skus: [] });

    const renderExplain = () => {
        let explain = <></>;
        if (orderSku.enable) {
            if (orderSku.skus.length === 0) {
                explain = <>(*) Đơn hàng chứa bất kỳ sản phẩm nào cũng sẽ được tính hoa hồng</>;
            } else if (orderSku.skus.length === 1) {
                explain = <>(*) Đơn hàng chứa sản phẩm có mã sku là {orderSku.skus.join(' ,')} sẽ được tính hoa hồng</>;
            } else if (orderSku.condition === 'AND') {
                explain = <>(*) Đơn hàng phải chứa toàn bộ các sản phẩm có mã sku là {orderSku.skus.join(' ,')} sẽ được tính hoa hồng</>;
            } else {
                explain = <>(*) Đơn hàng chứa 1 trong các sản phẩm có mã sku là {orderSku.skus.join(' ,')} sẽ được tính hoa hồng</>;
            }
        }
        return explain;
    };

    const eventEnableOrderSku = (e) => {
        const value = e.target.checked;
        const data = { ...orderSku, enable: value };
        setOrderSku(data);
        cb(data);
    };

    const eventSelectConditionOrderSku = (value) => {
        const data = { ...orderSku, condition: value };
        setOrderSku(data);
        cb(data);
    };

    const eventChangeSkus = (e) => {
        const skus = e.target.value.split(',');
        const data = { ...orderSku, skus };
        setOrderSku(data);
        cb(data);
    };

    useEffect(() => {
        setOrderSku({ ...orderSku, ...orderSkuProp });
    }, [orderSkuProp]);

    return <>
        <div className="ladiui clearfix pt-20">
            <label className="ladiui title-text-form-lever2 left">Thiết lập điều kiện SKU cho đơn hàng</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox"
                        name="enable"
                        checked={orderSku !== null && (orderSku.enable || false)}
                        onChange={(e) => eventEnableOrderSku(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Kiểm tra đơn hàng có các mã sản phẩm theo cài đặt dưới đây</div>
        </div>
        {orderSku && orderSku.enable
            ? <>
                <div className="ladiui clearfix pt-20">
                    <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Điều kiện đơn hàng</label>
                        <LadiDropdown
                            labelDropdown={t('COMMISSIONS.SELECT_CONDITION')}
                            listProp={conditionSku}
                            valueProp={orderSku.condition}
                            hideLabel={true}
                            validProp={true}
                            classDropdown="dropdown-custom w-180"
                            classSize="btn-sm"
                            cbProp={eventSelectConditionOrderSku}
                        />

                    </div>
                    <div className="ladiui left">
                        <label className="ladiui title-text-form-lever3">Danh sách SKU</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <textarea className="ladiui form-control "
                                value={orderSku && orderSku.skus ? orderSku.skus.join(',') : ''}
                                onChange={eventChangeSkus} placeholder="Danh sách SKU ngăn cách bằng dấu phẩy" />
                        </div>
                    </div>
                </div>
                <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic', overflow: "auto" }}>
                    {renderExplain()}
                </div>
            </>
            : <></>}
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBoxOrderSku));
