import i18n from '../../i18n';

export default {
    ALL_AFFILIATE: '-1',
    COMMISSION_STATUS: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        CANCELED: 'CANCELED',
        INVOICED: 'INVOICED',
        INVOICE_CANCEL: 'INVOICE_CANCEL',
    },
    COMMISSION_STATUS_NAME: {
        PENDING: `${i18n.t('COMMISSIONS.COMMISSION_STATUS.PENDING')}`,
        APPROVED: `${i18n.t('COMMISSIONS.COMMISSION_STATUS.APPROVED')}`,
        CANCELED: `${i18n.t('COMMISSIONS.COMMISSION_STATUS.CANCELED')}`,
        INVOICED: `${i18n.t('COMMISSIONS.COMMISSION_STATUS.INVOICED')}`,
        INVOICE_CANCEL: `${i18n.t('COMMISSIONS.COMMISSION_STATUS.INVOICE_CANCEL')}`,
    },
    COMMISSION_TYPE: {
        ORDER: 'ORDER',
        COUPON: 'COUPON',
        LEAD: 'LEAD',
        PER_PRODUCT: 'PER_PRODUCT',
        NEW_CUSTOMER: 'NEW_CUSTOMER',
        VISITOR: 'VISITOR',
        CUSTOM: 'CUSTOM',
    },
    PAYMENT_TYPE: {
        PAYMENT_TYPE_PERCENT: 'PERCENT',
        PAYMENT_TYPE_FIXED: 'FIXED',
    },
    PAYMENT_FOR: {
        ORDER: 'ORDER',
        PER_PRODUCT: 'PER_PRODUCT',
        LEAD: 'LEAD',
        NEW_CUSTOMER: 'NEW_CUSTOMER',
        VISITOR: 'VISITOR',
        KPIS: 'KPIS',
    },
    PAYMENT_FOR_NAME: {
        ORDER: 'Đơn hàng',
        PER_PRODUCT: 'Sản phẩm',
        LEAD: 'Lead',
        NEW_CUSTOMER: 'Khách hàng mới',
        VISITOR: 'Visitor',
        KPIS: 'KPI thưởng',
        COURSE_PRODUCT: 'Khóa học'
    },
    PAYMENT_TERM_TYPE: {
        NOW: 'NOW',
        DAY: 'DAY',
        MONTH: 'MONTH',
        QUARTER: 'QUARTER',
        YEAR: 'YEAR',
        CUSTOM: 'CUSTOM',
    },
    PAYMENT_TERM_TYPE_NAME: {
        NOW: `${i18n.t('COMMISSIONS.PAYMENT_TERM_TYPE.NOW')}`,
        DAY: `${i18n.t('COMMISSIONS.PAYMENT_TERM_TYPE.DAY')}`,
        MONTH: `${i18n.t('COMMISSIONS.PAYMENT_TERM_TYPE.MONTH')}`,
        YEAR: `${i18n.t('COMMISSIONS.PAYMENT_TERM_TYPE.YEAR')}`,
    },
    EXPORT_ACTIONS: {
        FIRST_PAGE: 'FIRST_PAGE',
        SELECTED_RECORDS: 'SELECTED_RECORDS',
        ALL_RECORDS: 'ALL_RECORDS',
    },
    LEVEL_AFFILIATE: [
        { name: '1', value: 1 },
        { name: '2', value: 2 },
        { name: '3', value: 3 },
    ],
    PAYMENT_INTERVAL_TYPE: {
        OFF: 'OFF',
        COUNT: 'COUNT',
        PURCHASE_TOTAL: 'PURCHASE_TOTAL',
    },
    PAYMENT_KPI_TERM_TYPE: {
        TOTAL: 'TOTAL',
        UNIT: 'UNIT',
    },
};
