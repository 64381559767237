import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiPagination from '../../components/LadiPagination';
import SearchCondition from './components/SearchCondition';
import actionLink from '../../redux/futures/link/actions';
import ModalPublisherLink from './components/ModalPublisherLink';
import ConfirmModal from '../../components/ConfirmModal';

// eslint-disable-next-line max-lines-per-function
function PublisherLink(props) {
    const { t } = props;
    const defaultCondtions = {
        limit: 10,
        page: 1,
        sort: { created_at: -1 },
    };

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState(defaultCondtions);
    const [isEdit, setIsEdit] = useState(false);
    const [linkItem, setLinkItem] = useState();
    const listLink = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.links.linkPublisher }));

    const cbList = () => {
        dispatch(actionLink.list(conditions));
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const createPublisherLink = () => {
        setIsEdit(false);
        window.LadiUI.showModal('modal-publisher-link');
    };

    const copyClipboard = (id) => {
        if (!navigator.clipboard) return;

        const copyText = document.getElementById(id);
        navigator.clipboard.writeText(copyText.value);
        window.LadiUI.toast('success', '', t('COMMON.COPIED'), 5000, 'bottom-left');
    };

    const eventDeleteLink = (item) => {
        setLinkItem(item);
    };

    const eventOkDeleteLink = () => {
        dispatch(actionLink.deleteLink({ id: linkItem._id }, cbList));
    };

    useEffect(() => {
        cbList();
    }, [conditions]);

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui flex-row">
                    <div className="ladiui main-title">
                        Danh sách Link
                    </div>
                </div>
                <div className="ladiui flex" style={{ gap: 12 }}>
                    <button type="button" className="ladiui btn btn-primary btn-lg" onClick={() => createPublisherLink()}>
                        <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                        <div className="ladiui btn-custom-text ">Tạo link</div>
                    </button>
                </div>
            </div>

            <div className="ladiui flex-row">
                <SearchCondition
                    keywordProp={conditions.keyword}
                    eventSearchKeyboardProp={eventSearchKeyboard}
                    eventClickSearchButtonProp={eventClickSearchButton}
                />
            </div>
            <div className="ladiui mobile-table-scroll">
                <table className="ladiui table text-left" style={{ minWidth: '650px' }}>
                    <thead>
                        <tr className="ladiui table-vertical header">
                            <th scope="col" className="ladiui text-pre">Link gốc</th>
                            <th scope="col" className="ladiui text-pre">Link rút gọn</th>
                            <th scope="col" className="ladiui text-pre">UTM source</th>
                            <th scope="col" className="ladiui text-pre">UTM medium</th>
                            <th scope="col" className="ladiui text-pre">UTM campaign</th>
                            <th scope="col" className="ladiui text-pre">UTM content</th>
                            <th scope="col" className="ladiui text-pre" width="1%">Lượt click</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listLink.items.length > 0 ? listLink.items.map((item, key) => (<tr key={item._id} className="ladiui table-vertical main">
                            <td>
                                <div style={{ position: 'relative' }}>
                                    <input className="ladiui form-control input-copy-link" style={{ minWidth: '150px' }}
                                        id={`base_url_link_${item._id}`}
                                        defaultValue={`${item.base_url}`}
                                        readOnly />
                                    <a href="#/" className="button-copy-link" onClick={() => copyClipboard(`base_url_link_${item._id}`)}>
                                        <i className="ladiui icon icon-ldp-replication"></i>
                                    </a>
                                </div>
                            </td>
                            <td>
                                <div style={{ position: 'relative' }}>
                                    <input className="ladiui form-control input-copy-link" style={{ minWidth: '150px' }}
                                        id={`short_link_${item._id}`}
                                        defaultValue={`${item.short_link}`}
                                        readOnly />
                                    <a href="#/" className="button-copy-link" onClick={() => copyClipboard(`short_link_${item._id}`)}>
                                        <i className="ladiui icon icon-ldp-replication"></i>
                                    </a>
                                </div>
                            </td>
                            <td>
                                {item.utm_source}
                            </td>
                            <td>
                                {item.utm_medium}
                            </td>
                            <td>
                                {item.utm_campaign}
                            </td>
                            <td>{item.utm_content}</td>
                            <td className="ladiui text-center">{item.number_click || 0}</td>
                            <td className="ladiui-dropdown">
                                <div className="ladiui btn-group">
                                    <div className="ladiui dropdown">
                                        <button data-toggle="dropdown"
                                            className="ladiui-btn-dropdown dropdown-toggle">
                                            <i className="ladiui icon icon-ldp-dot"></i>
                                        </button>
                                        <ul className="ladiui dropdown-menu custom-dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1">
                                            <li onClick={() => eventDeleteLink(item)}>
                                                <a className="ladiui dropdown-item lastChild btn-open-modal" data-toggle="modal" data-target="#delete-modal" href="#/">
                                                    Xoá
                                                </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>))
                            : <tr><td colSpan={7}>{t('NO_DATA')}</td></tr>}

                    </tbody>
                </table>
            </div>
            {listLink.items.length > 0
                ? <LadiPagination
                    conditionsProp={conditions}
                    listName='link'
                    items={listLink.items}
                    total={listLink.total}
                    limitAction={selectLimit}
                    pageAction={selectPage}
                /> : ''}
        </div>

        <ModalPublisherLink isEdit={isEdit} cb={cbList} />

        <ConfirmModal
            id="delete-modal"
            title="Bạn có chắc muốn xoá link này không?"
            content="Link sẽ được xoá khỏi hệ thống"
            onOk={() => eventOkDeleteLink()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.DELETE')}
            classBtn="btn-danger"
        />
    </>;
}

export default (withTranslation()(PublisherLink));
