import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiPagination from '../../../components/LadiPagination';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';
import trafficAction from '../../../redux/futures/source/actions';
import DefineConst from '../DefineConst';
import DefineConstAppIntegrate from '../../apps/DefineConst';

function Order(props) {
    const { t, conditionProps } = props;

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState();

    const listOrder = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.traffics.traffics }));

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    useEffect(() => {
        setConditions({ ...conditions, ...conditionProps, type: DefineConst.TRAFFIC_TYPE.PER_PRODUCT });
    }, [conditionProps]);

    useEffect(() => {
        if (conditions) dispatch(trafficAction.list(conditions));
    }, [conditions]);

    return <>
        <table className="ladiui table text-left">
            <thead>
                <tr className="ladiui table-vertical header">
                    <th scope="col">
                        <input data-toggle="checkbox" type="checkbox" className="ladiui checkbox-all size-checkbox form-check-input-checkbox"
                            name="checkbox" />
                    </th>
                    <th scope="col">Tên</th>
                    <th scope="col">Nguồn</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">Thuế</th>
                    <th scope="col">Ngày trả</th>
                    <th scope="col">Hoa hồng</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {listOrder.total > 0
                    ? listOrder.items.map((item) => <tr className="ladiui table-vertical main" key={item._id}>
                        <td scope="row">
                            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" name="checkbox" />
                        </td>
                        <td>
                            {item.data ? item.data.fullname : ''}
                        </td>
                        <td>
                            <img src={item.source ? DefineConstAppIntegrate.INTEGRATE_APPS[item.source].logo : ''} height="20" />&nbsp;
                            {item.source ? DefineConstAppIntegrate.INTEGRATE_APPS[item.source].name : ''}
                        </td>
                        <td>
                            {item.data ? BaseHelper.formatMoney(item.data.total) : BaseHelper.formatMoney(0)}
                        </td>
                        <td>{item.status ? DefineConst.TRAFFIC_STATUS[item.status] : ''}</td>
                        <td>{item.data ? BaseHelper.formatMoney(item.data.tax) : BaseHelper.formatMoney(0)}</td>
                        <td>{item.data ? item.data.date : ''}</td>
                        <td>{item.totalCommission ? BaseHelper.formatMoney(item.totalCommission) : BaseHelper.formatMoney(0)}</td>
                        <td className="ladiui-dropdown">
                            <div className="ladiui btn-group">
                                <div className="ladiui dropdown">
                                    <button data-toggle="dropdown"
                                        className="ladiui-btn-dropdown dropdown-toggle">
                                        <i className="ladiui icon icon-ldp-dot"></i>
                                    </button>
                                    <ul className="ladiui dropdown-menu custom-dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1">
                                        <li><a className="ladiui dropdown-item" href="#/">
                                            <i className="ladiui icon icon-ldp-report-icon"></i>
                                            Tính hoa hồng
                                        </a>
                                        </li>
                                        <li><a className="ladiui dropdown-item" href="#/">
                                            <i className="ladiui icon icon-ldp-decentralize"></i>
                                            Tính lại hoa hồng
                                        </a>
                                        </li>
                                        <li><a className="ladiui dropdown-item" href="#/">
                                            <i className="ladiui icon icon-ldp-replication"></i>
                                            Hủy
                                        </a>
                                        </li>
                                        <li><a className="ladiui dropdown-item lastChild" href="#/">
                                            <i className="ladiui icon icon-ldp-delete"></i>
                                            Xoá
                                        </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </td>
                    </tr>)
                    : <tr><td colSpan={9}>{t('SOURCES.NO_PRODUCT_EXIST')}</td></tr>
                }
            </tbody>
        </table>
        {listOrder.total > 0
            ? <LadiPagination
                conditionsProp={conditions}
                listName={t('SOURCES.PRODUCT')}
                items={listOrder.items}
                total={listOrder.total}
                limitAction={selectLimit}
                pageAction={selectPage}
            /> : <></>}
    </>;
}

export default (withTranslation()(Order));
