import React, { useEffect } from 'react';
import Pagination from 'react-js-pagination';
import appConfig from '../config/app';

function LadiPagination(props) {
    const {
        conditionsProp, listName, items, total, limitAction, pageAction,
    } = props;

    return <>
        {conditionsProp
            ? <div className="ladiui search-action">
                <div className="ladiui from-filter flex-row">
                    <div className="ladiui pre-page">
                        <div className="ladiui btn-group custom-pre-page">
                            <div className="ladiui dropdown">
                                <button
                                    className="ladiui btn btn-outline-light dropdown-toggle select-dropdown custom-pre-page"
                                    id="dropdownMenuButton3" data-toggle="dropdown">
                                    <i></i>
                                    <span className="ladiui dropdown-text custom-pre-page">{conditionsProp.limit}</span>
                                </button>
                                <ul className="ladiui dropdown-menu custom-pre-page dropup">
                                    {
                                        appConfig.PAGINATION.LIMIT_OPTIONS.map((limit, indexLimit) => (
                                            <li key={indexLimit} onClick={() => limitAction(limit.value)}><a className="ladiui dropdown-item" href="#/">{limit.name}</a></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="ladiui showing">
                        <span>Đang hiển thị&nbsp;</span>
                        {1 + (conditionsProp.page - 1) * conditionsProp.limit}
                        <span>&nbsp;đến&nbsp;</span>
                        {items.length + (conditionsProp.page - 1) * conditionsProp.limit}
                        <span>&nbsp;của&nbsp;</span>
                        {total}
                        <span style={{ textTransform: 'lowercase' }}>&nbsp;{listName}</span>
                    </div>
                </div>

                <div className="ladiui pagination">
                    <Pagination
                        activePage={conditionsProp.page}
                        itemsCountPerPage={conditionsProp.limit}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        prevPageText={<img src="https://w.ladicdn.com/ladiui/icons/ldicon-prev.svg" />}
                        nextPageText={<img src="https://w.ladicdn.com/ladiui/icons/ldicon-next.svg" />}
                        firstPageText={<img src="https://w.ladicdn.com/design-system/icons/icon-firts-page.svg" />}
                        lastPageText={<img src="https://w.ladicdn.com/design-system/icons/icon-last-page.svg" />}
                        onChange={pageAction}
                        itemClass="ladiui number-page"
                        innerClass="ladiui page-container"
                        activeClass="active"
                        activeLinkClass="active"
                    />
                </div>
            </div>
            : <></>}
    </>;
}

export default LadiPagination;
