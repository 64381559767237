import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import appConfig from '../../config/app';
import LadiDateRangePicker from '../../components/LadiDateRangePicker';
import BaseHelper from '../../helpers/BaseHelper';
import FilterCondition from './components/FilterCondition';
import SearchCondition from './components/SearchCondition';
import ListCommission from './components/ListCommission';
import ExportCommission from './components/ExportCommission';
import DefineConst from './DefineConst';
import actionCommission from '../../redux/futures/commission/actions';
import config from '../../config/config';
import ModalCommission from './components/ModalCommission';
import ExportConfirmCommission from './components/ExportConfirmCommission';

// eslint-disable-next-line max-lines-per-function
function Commission(props) {
    const { t } = props;
    const search = new URLSearchParams(window.location.search);
    const keyword = search.get('keyword');

    const moment = BaseHelper.getMoment();
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { created_at: -1 },
        keyword,
        date_from: moment().startOf('month').format('YYYY-MM-DD 00:00:01'),
        date_to: moment().format('YYYY-MM-DD 23:59:59'),
        tab: 'ALL',
    };

    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(moment().startOf('month'));
    const [toDate, setToDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);
    const [conditions, setConditions] = useState(defaultConditions);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const filterActionProp = (conditionRecived) => {
        setConditions({ ...conditionRecived });
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const eventExportData = () => {
        window.LadiUI.showModal('modal-export-commission');
    };

    const actionExportProp = (actionExport) => {
        let exportCondition = { ...conditions };
        switch (actionExport) {
            case DefineConst.EXPORT_ACTIONS.FIRST_PAGE:
                exportCondition.page = 1;
                break;
            case DefineConst.EXPORT_ACTIONS.SELECTED_RECORDS:
                if (selectedIds.length <= 0) {
                    window.LadiUI.toast('danger', '', 'Vui lòng chọn ít nhất 1 bản ghi', 5000, 'bottom-left');
                    return;
                }
                exportCondition = { ids: selectedIds.join(',') };
                break;
            case DefineConst.EXPORT_ACTIONS.ALL_RECORDS:
                delete exportCondition.page;
                delete exportCondition.limit;
                break;
            default:
        }
        dispatch(actionCommission.exportCommission(exportCondition));
    };

    const actionExportConfirmProp = (actionExport) => {
        let exportCondition = { ...conditions };
        switch (actionExport) {
            case DefineConst.EXPORT_ACTIONS.FIRST_PAGE:
                exportCondition.page = 1;
                break;
            case DefineConst.EXPORT_ACTIONS.SELECTED_RECORDS:
                if (selectedIds.length <= 0) {
                    window.LadiUI.toast('danger', '', 'Vui lòng chọn ít nhất 1 bản ghi', 5000, 'bottom-left');
                    return;
                }
                exportCondition = { ids: selectedIds.join(',') };
                break;
            case DefineConst.EXPORT_ACTIONS.ALL_RECORDS:
                delete exportCondition.page;
                delete exportCondition.limit;
                break;
            default:
        }
        dispatch(actionCommission.exportConfirmCommission(exportCondition));
    };

    const openModalCommission = () => {
        setIsEdit(false);
        window.LadiUI.showModal('modal-commission-custom');
    };

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title content-main-report flex-row">
                <div className="ladiui flex-row header-main-report">
                    <div className="ladiui main-title">
                        {t('COMMISSIONS.LIST_COMMISSION')}
                    </div>
                    <div className="calendar-search">
                        <LadiDateRangePicker
                            startDateId="fromDate"
                            endDateId="toDate"
                            startDate={fromDate}
                            endDate={toDate}
                            onDatesChange={({ startDate, endDate }) => {
                                const startDateNew = BaseHelper.getFromDate(startDate);
                                const endDateNew = BaseHelper.getToDate(endDate);
                                setFromDate(startDateNew);
                                setToDate(endDateNew);
                                if ((startDateNew && endDateNew) || (!startDateNew && !endDateNew)) {
                                    setConditions({ ...conditions, ...{ date_from: startDateNew ? startDateNew.format('YYYY-MM-DD 00:00:01') : null, date_to: endDateNew ? endDateNew.format('YYYY-MM-DD 23:59:59') : null } });
                                }
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => {
                                setFocusedInput(focusedInput);
                            }}
                            showDefaultInputIcon={true}
                            inputIconPosition="after"
                            small={true}
                            hideKeyboardShortcutsPanel={true}
                            customInputIcon={
                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                            }
                            startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            isOutsideRange={() => false}
                            isDayHighlighted={(date) => moment().isSame(date, 'day')}
                            minimumNights={0}
                            showClearDates={true}
                        />
                    </div>
                </div>
                {!config.APP_AFFILIATE
                    ? <div className="ladiui flex" style={{ gap: 12 }}>
                        <div className="ladiui">
                            <div className="ladiui dropdown">
                                <button className="ladiui btn btn-outline-primary dropdown-toggle import-data-btn" data-toggle="dropdown" style={{ minWidth: 0 }}>
                                    Xuất dữ liệu
                                </button>
                                <ul className="ladiui dropdown-menu">
                                    <li onClick={() => eventExportData()}>
                                        <a className="ladiui dropdown-item" href="#">Xuất danh sách hoa hồng</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button type="button" className="ladiui btn btn-primary btn-lg" onClick={() => openModalCommission()}>
                            <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                            <div className="ladiui btn-custom-text ">Tạo hoa hồng</div>
                        </button>
                    </div>
                    : <></>}
            </div>
            <div className="ladiui content-main-tool flex-row align-item-baseline content-main-tool-2">
                <div className="ladiui btn-group main-filter-condition">
                    <FilterCondition conditionProp={conditions} filterActionProp={filterActionProp} />
                </div>
                {!config.APP_AFFILIATE ? <div style={{ minWidth: 'calc(100% - 210px)' }}>
                    <SearchCondition
                        keywordProp={conditions.keyword}
                        eventSearchKeyboardProp={eventSearchKeyboard}
                        eventClickSearchButtonProp={eventClickSearchButton}
                    />
                </div> : <></>
                }
            </div>
            <div style={{ marginTop: '20px' }}>
                <ul className="ladiui nav nav-tabs new-tab-custom commission-tabs">
                    <li className="ladiui nav-item" onClick={() => setConditions({ ...conditions, page: 1, tab: 'ALL' })}>
                        <span className={conditions.tab === 'ALL' ? 'ladiui tab-link active' : 'ladiui tab-link'}>Tất cả hoa hồng</span>
                    </li>
                    <li className="ladiui nav-item" onClick={() => setConditions({ ...conditions, page: 1, tab: 'PENDING' })}>
                        <span className={conditions.tab === 'PENDING' ? 'ladiui tab-link active' : 'ladiui tab-link'}>Hoa hồng chờ duyệt</span>
                    </li>
                    <li className="ladiui nav-item" role="presentation" onClick={() => setConditions({ ...conditions, page: 1, tab: 'APPROVED' })}>
                        <span className={conditions.tab === 'APPROVED' ? 'ladiui tab-link active' : 'ladiui tab-link'}>Hoa hồng tạm duyệt</span>
                    </li>
                    <li className="ladiui nav-item" role="presentation" onClick={() => setConditions({ ...conditions, page: 1, tab: 'IS_CONFIRM' })}>
                        <span className={conditions.tab === 'IS_CONFIRM' ? 'ladiui tab-link active' : 'ladiui tab-link'}>Hoa hồng đã đối soát</span>
                    </li>
                    <li className="ladiui nav-item" role="presentation" onClick={() => setConditions({ ...conditions, page: 1, tab: 'INVOICED' })}>
                        <span className={conditions.tab === 'INVOICED' ? 'ladiui tab-link active' : 'ladiui tab-link'}>Hoa hồng đã duyệt</span>
                    </li>
                    <li className="ladiui nav-item" role="presentation" onClick={() => setConditions({ ...conditions, page: 1, tab: 'CANCELED' })}>
                        <span className={conditions.tab === 'CANCELED' ? 'ladiui tab-link active' : 'ladiui tab-link'}>Hoa hồng đã từ chối</span>
                    </li>
                    <li className="ladiui nav-item" role="presentation" onClick={() => setConditions({ ...conditions, page: 1, tab: 'IS_PAID' })}>
                        <span className={conditions.tab === 'IS_PAID' ? 'ladiui tab-link active' : 'ladiui tab-link'}>Hoa hồng đã thanh toán</span>
                    </li>
                </ul>

            </div>
            <ListCommission
                selectedIdsProp={selectedIds}
                setSelectedIdsProp={setSelectedIds}
                conditionsProp={conditions}
                setIsEdit={setIsEdit}
                conditionActionProp={(conditionRecived) => setConditions({ ...conditions, ...conditionRecived })} />
        </div>
        <ExportCommission actionExportProp={actionExportProp} />
        <ExportConfirmCommission actionExportProp={actionExportConfirmProp} />
        <ModalCommission isEdit={isEdit} cbProp={() => dispatch(actionCommission.list(conditions))} />
    </>;
}

export default (withTranslation()(Commission));
