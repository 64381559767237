import { isArray } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../assets/css/custom.module.css';
import config from '../../../config/config';
import actionCampaign from '../../../redux/futures/campaign/actions';
import ModalShareCampaign from '../../campaigns/components/ModalShareCampaign';
import DefineConst from '../../campaigns/DefineConst';

function CampaignPublisher() {
    const dispatch = useDispatch();
    const listCampaign = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.campaigns.campaigns }));

    const getPlatform = (item) => {
        let platform = <></>;
        switch (item.type) {
            case DefineConst.PLATFORM_CAMPAIGN.FACEBOOK:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/fb.svg" className="mr-8" />Facebook
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.INSTAGRAM:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/instagram.svg" width={16} height={16} className="mr-8" />Instagram
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.LINKEDIN:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/linkedin.svg" width={16} height={16} className="mr-8" />Linkedin
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.TWITTER:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/twitter.svg" width={16} height={16} className="mr-8" />Twitter
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.BANNER:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/banner-ads-icon.svg" width={16} height={16} className="mr-8" />Banner
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.MAIL:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/mail.svg" width={16} height={16} className="mr-8" />Mail
                </div>;
                break;

            default:
        }
        return platform;
    };

    const eventShareCampaign = (item) => {
        dispatch(actionCampaign.show(item._id));
        window.LadiUI.showModal('modal-campaign-share');
    };

    useEffect(() => {
        const conditions = {
            limit: 5,
            page: 1,
            sort: { is_priority: -1, created_at: -1 },
        };
        dispatch(actionCampaign.list(conditions));
    }, []);
    return <>
        <div className={`${styles['box-statistics-publisher']} ${styles['no-height']} ${styles['p-0']} ${styles['mt-16']}`}>
            <div className={styles['box-title']}>
                <label className={styles.bold}>Tham gia chiến dịch để được nhận hoa hồng</label>
                <a href="/campaigns">Xem tất cả</a>
            </div>
            <div className="box-body">
                <ul className={styles['m-16']}>
                    {
                        listCampaign.items ? listCampaign.items.map((item) => <li key={item._id} className="item-campaign">
                            <div className={styles.flex}>
                                {item.image_profile ? <div className={styles['img-campaign']}>
                                    <img src={`${config.DOMAIN_CDN}/${item.image_profile.path}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                </div>
                                    : <></>}
                                <div style={{ marginLeft: '16px', flex: 'auto', width: 'calc(100% - 116px)' }}>
                                    <div className={styles.title}>{item.title}</div>
                                    <div style={{ padding: '8px 0' }}>
                                        {getPlatform(item)}
                                    </div>
                                    <div className={`${styles.flex} ${styles['default-align']} ${styles['mt-16']}`} style={{ width: 'calc(100% - 116px)' }}>
                                        {/* <div><span style={{ color: '#41B983' }}>10%</span><span style={{ color: '#6D6D6D' }}>&nbsp;Hoa hồng</span></div> */}
                                        <div></div>
                                        <div onClick={() => eventShareCampaign(item)}><img src="https://w.ladicdn.com/design-system/icons/share.svg" /><a href="#/" className="link-share-campaign" style={{ color: '#1C00C2' }}>&nbsp;Chia sẻ</a></div>
                                    </div>
                                </div>
                            </div>
                        </li>) : <></>
                    }
                </ul>
            </div>
            <div className="box-footer"></div>
        </div>
        <ModalShareCampaign />
    </>;
}

export default CampaignPublisher;
