import localForage from 'localforage';

const appLocalStorage = localForage.createInstance({
    driver: localForage.LOCALSTORAGE,
    name: 'LF',
});

const appIndexedDB = localForage.createInstance({
    driver: localForage.INDEXEDDB,
    name: 'LF',
});

export {
    appLocalStorage,
    appIndexedDB,
};