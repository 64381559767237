import i18n from '../../i18n';

export default {
    SENDER_EMAIL_STATUS: {
        PENDING: 'PENDING',
        VERIFIED: 'VERIFIED',
    },
    SENDER_EMAIL_STATUS_NAME: {
        PENDING: `${i18n.t('STORES.SENDER_EMAIL_STATUS.PENDING', { interpolation: { escapeValue: false } })}`,
        VERIFIED: `${i18n.t('STORES.SENDER_EMAIL_STATUS.VERIFIED', { interpolation: { escapeValue: false } })}`,
    },
    HISTORY_ACTION: {
        AFFILIATE_CREATE: 'Thêm mới',
        AFFILIATE_UPDATE: 'Chỉnh sửa',
        AFFILIATE_DELETE: 'Xoá',
        AFFILIATE_EXPORT: 'Xuất dữ liệu',
        AFFILIATE_RESET_PASSWORD: 'Khôi phục mật khẩu',
        AFFILIATE_UPDATE_OTHER: 'Cập nhật thông tin',
        AFFILIATE_EXPORT_BANK_INFO: 'Xuất file mẫu thanh toán',

        AFFILIATE_COUPON_CREATE: 'Thêm mới',
        AFFILIATE_COUPON_UPDATE: 'Chỉnh sửa',
        AFFILIATE_COUPON_DELETE: 'Xoá',
        AFFILIATE_COUPON_SYNC: 'Đồng bộ mã khuyến mãi',
        AFFILIATE_COUPON_DELETE_MULTI: 'Xoá nhiều mã khuyến mãi',
        AFFILIATE_COUPON_SYNC_MULTI: 'Đồng bộ nhiều mã khuyến mãi',

        AFFILIATE_GROUP_CREATE: 'Thêm mới',
        AFFILIATE_GROUP_UPDATE: 'Chỉnh sửa',
        AFFILIATE_GROUP_DELETE: 'Xoá',
        AFFILIATE_GROUP_DELETE_MULTI: 'Xoá nhiều group',
        AFFILIATE_GROUP_SET_DEFAULT: 'Đặt làm nhóm affiliate mặc định',
        AFFILIATE_GROUP_SET_UNDEFAULT: 'Huỷ nhóm affiliate mặc định',

        CAMPAIGN_CREATE: 'Tạo chiến dịch',
        CAMPAIGN_UPDATE: 'Sửa chiến dịch',
        CAMPAIGN_DELETE: 'Xoá chiến dịch',

        COMMISSION_CREATE: 'Thêm mới',
        COMMISSION_UPDATE: 'Chỉnh sửa',
        COMMISSION_DELETE: 'Xoá',
        COMMISSION_INVOICED: 'Duyệt hoa hồng',
        COMMISSION_RE_CALCULATE: 'Tính lại hoa hồng',
        COMMISSION_EXPORT: 'Xuất danh sách hoa hồng',
        COMMISSION_DELETE_MULTI: 'Xoá nhiều hoa hồng',
        COMMISSION_UPDATE_STATUS_MULTI: 'Cập nhật trạng thái nhiều hoa hồng',
        COMMISSION_INVOICED_MULTI: 'Duyệt nhiều hoa hồng',
        COMMISSION_CREATE_NEW_CUSTOMER: 'Tạo hoa hồng cho khách hàng mới',
        COMMISSION_CREATE_LEAD: 'Tạo hoa hồng cho Lead',
        COMMISSION_CREATE_ORDER: 'Tạo hoa hồng cho đơn hàng',
        COMMISSION_CREATE_PRODUCT: 'Tạo hoa hồng cho sản phẩm',
        COMMISSION_UPDATE_STATUS: 'Thay đổi trạng thái',
        COMMISSION_CONFIRM: 'Chấp nhận đối soát',
        COMMISSION_RECHECK: 'Yêu cầu kiểm tra lại',

        INVOICE_CREATE: 'Thêm mới',
        CREATE_INVOICE: 'Thêm mới',
        INVOICE_UPDATE: 'Chỉnh sửa',
        INVOICE_DELETE: 'Xoá',
        INVOICE_UPDATE_STATUS: 'Cập nhật trạng thái hoá đơn',
        INVOICE_DELETE_MULTI: 'Xoá nhiều hoá đơn',
        INVOICE_UPDATE_STATUS_MULTI: 'Cập nhật trạng thái nhiều hoá đơn',
        INVOICE_EXPORT: 'Xuất danh sách hoá đơn',
        SEND_MAIL_CONFIRM: 'Gửi mail đối soát',
        AFFILIATE_CREATE_INVOICE_REQUEST: 'Tạo yêu cầu',

        REPORT_EXPORT: 'Xuất báo cáo',

        INVOICE_REQUEST_APPROVED: 'Duyệt yêu cầu',
    },
    HISTORY_OBJECT: {
        AFFILIATE: 'Affiliate',
        AFFILIATE_COUPON: 'Mã khuyến mãi',
        AFFILIATE_GROUP: 'Nhóm affiliate',
        CAMPAIGN: 'Chiến dịch',
        COMMISSION: 'Hoa hồng',
        INVOICE: 'Hoá đơn',
        INVOICE_REQUEST: 'Yêu cầu lên hoá đơn',
        REPORT: 'Báo cáo',
    },
    HISTORY_OBJECT_TYPE_DROPDOWN: [
        { name: 'Affiliate', value: 'AFFILIATE' },
        { name: 'Mã khuyến mãi', value: 'AFFILIATE_COUPON' },
        { name: 'Nhóm affiliate', value: 'AFFILIATE_GROUP' },
        { name: 'Chiến dịch', value: 'CAMPAIGN' },
        { name: 'Hoa hồng', value: 'COMMISSION' },
        { name: 'Hoá đơn', value: 'INVOICE' },
        { name: 'Báo cáo', value: 'REPORT' },
    ],
    HISTORY_ACTION_TYPE_DROPDOWN: {
        AFFILIATE: [
            { name: 'Thêm mới', value: 'AFFILIATE_CREATE' },
            { name: 'Chỉnh sửa', value: 'AFFILIATE_UPDATE' },
            { name: 'Xoá', value: 'AFFILIATE_DELETE' },
            { name: 'Xuất dữ liệu', value: 'AFFILIATE_EXPORT' },
        ],
        AFFILIATE_COUPON: [
            { name: 'Thêm mới', value: 'AFFILIATE_COUPON_CREATE' },
            { name: 'Chỉnh sửa', value: 'AFFILIATE_COUPON_UPDATE' },
            { name: 'Xoá', value: 'AFFILIATE_COUPON_DELETE' },
            { name: 'Đồng bộ mã khuyến mãi', value: 'AFFILIATE_COUPON_SYNC' },
            { name: 'Xoá nhiều mã khuyến mãi', value: 'AFFILIATE_COUPON_DELETE_MULTI' },
            { name: 'Đồng bộ nhiều mã khuyến mãi', value: 'AFFILIATE_COUPON_SYNC_MULTI' },
        ],
        AFFILIATE_GROUP: [
            { name: 'Thêm mới', value: 'AFFILIATE_GROUP_CREATE' },
            { name: 'Chỉnh sửa', value: 'AFFILIATE_GROUP_UPDATE' },
            { name: 'Xoá', value: 'AFFILIATE_GROUP_DELETE' },
            { name: 'Xoá nhiều group', value: 'AFFILIATE_GROUP_DELETE_MULTI' },
            { name: 'Đặt làm nhóm affiliate mặc định', value: 'AFFILIATE_GROUP_SET_DEFAULT' },
            { name: 'Huỷ nhóm affiliate mặc định', value: 'AFFILIATE_GROUP_SET_UNDEFAULT' },
        ],
        CAMPAIGN: [
            { name: 'Thêm mới', value: 'AFFILIATE_CREATE' },
            { name: 'Chỉnh sửa', value: 'AFFILIATE_UPDATE' },
            { name: 'Xoá', value: 'AFFILIATE_DELETE' },
        ],
        COMMISSION: [
            { name: 'Thêm mới', value: 'COMMISSION_CREATE' },
            { name: 'Chỉnh sửa', value: 'COMMISSION_UPDATE' },
            { name: 'Duyệt hoa hồng', value: 'COMMISSION_INVOICED' },
            { name: 'Xoá', value: 'COMMISSION_DELETE' },
            { name: 'Tính lại hoa hồng', value: 'COMMISSION_RE_CALCULATE' },
            { name: 'Xuất danh sách hoa hồng', value: 'COMMISSION_EXPORT' },
            { name: 'Xoá nhiều hoa hồng', value: 'COMMISSION_DELETE_MULTI' },
            { name: 'Cập nhật trạng thái nhiều hoa hồng', value: 'COMMISSION_UPDATE_STATUS_MULTI' },
            { name: 'Duyệt nhiều hoa hồng', value: 'COMMISSION_INVOICED_MULTI' },
            { name: 'Tạo hoa hồng cho khách hàng mới', value: 'COMMISSION_CREATE_NEW_CUSTOMER' },
            { name: 'Tạo hoa hồng cho Lead', value: 'COMMISSION_CREATE_LEAD' },
            { name: 'Tạo hoa hồng cho đơn hàng', value: 'COMMISSION_CREATE_ORDER' },
            { name: 'Tạo hoa hồng cho sản phẩm', value: 'COMMISSION_CREATE_PRODUCT' },
        ],
        INVOICE: [
            { name: 'Thêm mới', value: 'AFFILIATE_CREATE' },
            { name: 'Chỉnh sửa', value: 'AFFILIATE_UPDATE' },
            { name: 'Xoá', value: 'AFFILIATE_DELETE' },
            { name: 'Cập nhật trạng thái hoá đơn', value: 'INVOICE_UPDATE_STATUS' },
            { name: 'Xoá nhiều hoá đơn', value: 'INVOICE_DELETE_MULTI' },
            { name: 'Cập nhật trạng thái nhiều hoá đơn', value: 'INVOICE_UPDATE_STATUS_MULTI' },
            { name: 'Xuất danh sách hoá đơn', value: 'INVOICE_EXPORT' },
        ],
        REPORT: [
            { name: 'Xuất báo cáo', value: 'REPORT_EXPORT' },
        ],
    },
};
