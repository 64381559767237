import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actionPublisher from '../../redux/futures/publisher/actions';

function CampaignShare() {
    const search = new URLSearchParams(window.location.search);
    const url = search.get('url');
    const campaignId = search.get('cpid');
    const affiliateCode = search.get('afc');

    const dispatch = useDispatch();
    const campaign = useSelector((state) => (state.publishers.campaignShare));

    const getUrl = (link, affifiliateCode) => {
        if (!link) return '#';
        try {
            const url = new URL(link);
            url.searchParams.append('ref', affifiliateCode);
            return url.toString();
        } catch (err) {
            return link;
        }
    };

    useEffect(() => {
        dispatch(actionPublisher.campaignShareDetail({ id: campaignId }));
    }, [campaignId]);
    return <>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <a href={campaign ? getUrl(campaign.url, affiliateCode) : ''} target="_blank" rel="noreferrer">
                <img src={url} />
            </a>
        </div>
    </>;
}

export default CampaignShare;
