import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import authActions from '../redux/futures/auth/actions';
import storeActions from '../redux/futures/store/actions';
import * as storeTypes from '../redux/futures/store/types';
import AppLayout from './apps/AppLayout';
import LoadingScene from '../components/LoadingScene';
import baseHelper from '../helpers/BaseHelper';
import appConfig from '../config/app';
import config from '../config/config';

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        };
    }

    componentDidMount() {
        this.props.getUserInfo();

        let domainUrl = window.location.href;
        if (domainUrl.includes('affiliate.ladipage.com') || domainUrl.includes('aff.dev.ladishare.com')) {
            document.title = "LadiPage Affiliate";
        }
    }

    showPopupSurvey = () => {
        if (window.LadiPagePopupXScript) {
            if (window.LadiPagePopupXScript.actionPopupX) {
                window.LadiPagePopupXScript.actionPopupX('show_popupx', '66418f519ae79400116994ca', 'POPUP_survey_0KH');
            } else {
                setTimeout(() => {
                    this.showPopupSurvey();
                }, 100)
            }
        } else {
            setTimeout(() => {
                this.showPopupSurvey();
            }, 100)
        }
    }

    showPopupMinicamp = () => {
        if (window.LadiPagePopupXScript) {
            if (window.LadiPagePopupXScript.actionPopupX) {
                window.LadiPagePopupXScript.actionPopupX('show_popupx', '66418f519ae79400116994ca', 'POPUP_minicamp_coKH');
            } else {
                setTimeout(() => {
                    this.showPopupMinicamp();
                }, 100)
            }
        } else {
            setTimeout(() => {
                this.showPopupMinicamp();
            }, 100)
        }
    }

    showStickyBarWebinar = () => {
        if (window.LadiPagePopupXScript) {
            if (window.LadiPagePopupXScript.actionPopupX) {
                window.LadiPagePopupXScript.actionPopupX('show_popupx_inline', '66418f519ae79400116994ca', 'STICKY_BAR_webinar');
            } else {
                setTimeout(() => {
                    this.showStickyBarWebinar();
                }, 100)
            }
        } else {
            setTimeout(() => {
                this.showStickyBarWebinar();
            }, 100)
        }
    }

    showStickyBarMinicamp = () => {
        if (window.LadiPagePopupXScript) {
            if (window.LadiPagePopupXScript.actionPopupX) {
                window.LadiPagePopupXScript.actionPopupX('show_popupx_inline', '66418f519ae79400116994ca', 'STICKY_BAR_minicamp');
            } else {
                setTimeout(() => {
                    this.showStickyBarMinicamp();
                }, 100)
            }
        } else {
            setTimeout(() => {
                this.showStickyBarMinicamp();
            }, 100)
        }
    }


    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }
        if (this.props.storeReducer.action !== nextProps.storeReducer.action) {
            if (nextProps.storeReducer.action === storeTypes.GET_USER_INFO) {
                if (nextProps.storeReducer.status) {
                    if (!nextProps.storeReducer.noStore) {
                        const ladiUID = nextProps.storeReducer.userInfo.ladi_uid;
                        if (nextProps.storeReducer.userInfo.store) {
                            const token = baseHelper.getCookie(appConfig.COOKIE.SSID);
                            if (!window.LadiChat) {
                                window.LadiChat = [];
                            }

                            const name = nextProps.storeReducer.userInfo.user.fullname;
                            const phone = nextProps.storeReducer.userInfo.user.phone;
                            const pkgName = nextProps.storeReducer.userInfo.store.pkg_name;

                            window.LadiChat.push([
                                ['set', 'user:name', name],
                                ['set', 'user:phone', phone],
                                ['set', 'custom_field:pkg_name_ladiflow', pkgName],
                                ['set', 'user:token', token],
                            ]);
                        }

                        if (nextProps.storeReducer.userInfo.currentStore) {
                            const storeID = nextProps.storeReducer.userInfo.currentStore.store_id;
                            this.props.signInSuccess(ladiUID, storeID);
                        }
                    }

                    let domainUrl = window.location.href;

                    if (domainUrl.includes('affiliate.ladipage.com') || domainUrl.includes('aff.dev.ladishare.com')) {
                        let user = nextProps.storeReducer.userInfo.user;
                        let fixedDate = new Date('2024-06-24');
                        let currentDate = new Date();
                        let isShowPopupWebinar = baseHelper.getCookie('IS_SHOW_POPUP_WEBINAR');
                        let isShowPopupComisstion = baseHelper.getCookie('IS_SHOW_COMISSTION_POPUP');
                        let isDoneSurvey = false;
                        let user_data = user.user_data ? JSON.parse(user.user_data) : {};

                        if (user_data && user_data.complete_survey) {
                            isDoneSurvey = true;
                        } else {
                            isDoneSurvey = false;
                        }

                        if (user && user.customer_statics && user.customer_statics.total_invited == 0 && currentDate >= fixedDate && !isDoneSurvey) {
                            this.showPopupSurvey();
                        }

                        if (user && user.customer_statics && user.customer_statics.total_invited > 0 && isShowPopupComisstion != 1) {
                            this.showPopupMinicamp();
                        }

                        if (user && user.customer_statics && user.customer_statics.total_invited == 0 && user.customer_statics.total_order_count == 0 && isShowPopupWebinar != 1) {
                            this.showStickyBarWebinar();
                        }

                        if (user && user.customer_statics && user.customer_statics.total_invited > 0) {
                            this.showStickyBarMinicamp();
                        }
                    }

                    this.setState({
                        loaded: true,
                    });
                } else {
                    window.LadiUI.toastr('error', '', nextProps.storeReducer.message);
                }
            }
        }
    }

    render() {
        if (!this.state.loaded) {
            return <LoadingScene />;
        }

        const stores = this.props.storeReducer.userInfo && this.props.storeReducer.userInfo.stores ? this.props.storeReducer.userInfo.stores : [];

        if (stores.length <= 0) {
            return <div style={{ marginTop: '15%' }}>
                <div style={{
                    width: 290,
                    margin: '0 auto',
                    border: 0,
                    float: 'none',
                }}>
                    <a href="https://www.ladishare.com/"><img src={appConfig.COLOR_LOGO} alt="https://www.ladishare.com/"></img></a>
                </div>
                <p style={{
                    width: '100%', textAlign: 'center', fontSize: '16px', lineHeight: 1.6, padding: '30px', color: '#000',
                }}>
                    {/* <span style={{ fontWeight: 'bold' }}>Thông báo!<br></br></span> */}
                    Ladishare chỉ áp dụng cho gói LadiPage Pro trở lên. Vui lòng liên hệ Hỗ trợ để biết thêm chi tiết.<br></br>Xin cảm ơn!<br></br></p>
            </div>;
        }

        return <Switch>
            <Route component={AppLayout} />
        </Switch>;
    }
}

const mapDispatchToProps = (dispatch) => ({
    getUserInfo: () => dispatch(storeActions.getUserInfo()),
    signInSuccess: (ladiUID, storeID) => dispatch(authActions.signInSuccess(ladiUID, storeID)),
});

const mapStateToProps = (state) => ({
    storeReducer: { ...state.store },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
