import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumericFormat from 'react-number-format';
import ConfirmModal from '../../components/ConfirmModal';
import LadiDropdown from '../../components/LadiDropdown';
import actionStore from '../../redux/futures/store/actions';

// eslint-disable-next-line max-lines-per-function
function SDKSetting(props) {
    const { t } = props;

    const dispatch = useDispatch();
    const [cookieSetting, setCookieSetting] = useState({});
    const apiKey = useSelector((state) => (state.store.apiKeyInfo));
    const store = useSelector((state) => (state.store.userInfo.store));
    const cookieSettingData = useSelector((state) => (state.store.cookieSetting));

    const copyClipboard = (id) => {
        const copyText = document.getElementById(id);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        window.LadiUI.toast('success', '', t('COMMON.COPIED'), 5000, 'bottom-left');
    };

    const cbGetApiKeyInfo = () => {
        dispatch(actionStore.getApiKeyInfo());
    };

    const eventConfirmReCreateApiKey = () => {
        window.LadiUI.showModal('re-create-api-key');
    };

    const eventOkReCreateApiKey = () => {
        dispatch(actionStore.reCreateApiKey({}, cbGetApiKeyInfo));
    };

    const eventSelectMethod = (type) => {
        setCookieSetting({ ...cookieSetting, click_type: type });
    };

    const getMethodTrackingClick = () => [{ name: 'First click', value: 'FIRST_CLICK' }, { name: 'Last click', value: 'LAST_CLICK' }];

    const eventEnableOverwrite = (event) => {
        if (event.target.checked) {
            setCookieSetting({ ...cookieSetting, enable_overwrite: true });
        } else {
            setCookieSetting({ ...cookieSetting, enable_overwrite: false });
        }
    };

    const saveCookieSetting = () => {
        if (!cookieSetting.click_type) {
            window.LadiUI.toast('danger', '', 'Vui lòng chọn cách tính hoa hồng', 5000, 'bottom-left');
            return;
        }
        if (!cookieSetting.life_time) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập thời gian sống cho cookie. Đơn vị tính: Ngày', 5000, 'bottom-left');
            return;
        }
        dispatch(actionStore.setCookieSetting(cookieSetting));
    };

    useEffect(() => {
        dispatch(actionStore.getApiKeyInfo());
        dispatch(actionStore.getCookieSetting());
    }, []);

    useEffect(() => {
        if (cookieSettingData) {
            setCookieSetting(cookieSettingData);
        }
    }, [cookieSettingData]);

    return <>
        <div className="ladiui content-main-title flex-row">
            <div className="ladiui">
                <div className="ladiui main-title">
                    {t('STORES.INTEGRATION_SDK')}
                </div>
            </div>
        </div>
        <div className="ladiui general-setting-content" style={{ maxWidth: '750px' }}>
            <div>
                Bạn chỉ cần cài đặt một lần, chỉ cần dán mã nhúng vào trang web của bạn ở trước thẻ &lt;/head&gt; hoặc trước thẻ &lt;/body&gt; của webstie
            </div>
            <div className="ladiui form-group" style={{ position: 'relative' }}>
                <input type="text" className="ladiui form-control" id="script-head" name="api-key" style={{ paddingRight: '40px', color: '#6D6D6D' }}
                    readOnly
                    defaultValue={`<script src="https://w.ladicdn.com/ladishare/sdk.js?v=2.0" async id="${store._id}"></script>`}
                />
                <div className="api-key-action" onClick={() => copyClipboard('script-head')}><img src="https://w.ladicdn.com/ladiui/ladishare/copy-20808.svg" style={{ marginLeft: '15px' }} /></div>
            </div>

            <div style={{ marginTop: '40px' }}>
                Đặt code dưới đây trên trang đặt hàng thành công
            </div>
            <div>
                Lưu ý: Không thay đổi tên các biến mặc định dưới đây. Bạn có thể gửi thêm các trường dữ liệu khác
            </div>
            <div className="ladiui form-group" style={{ position: 'relative' }}>
                <textarea id="script-body" className="ladiui form-control" rows={12} readOnly
                    style={{ color: '#6D6D6D' }}
                    defaultValue={
                        `<script type="text/javascript">
        var ladishare_order = {
                order_id: 'Mã đơn hàng',// Bắt buộc
                shop_id: 'Shop ID trong Tích hợp cửa hàng',// Bắt buộc
                total: 'Tổng tiền hàng',// Bắt buộc - Dùng để tính hoa hồng
                currency: 'Đơn vị tiền tệ',// Bắt buộc
                status: 'Trạng thái đơn hàng: PENDING, COMPLETED, CANCELED',// Không bắt buộc, mặc định là PENDING
        }
</script>`} />
                <div className="api-key-action" onClick={() => copyClipboard('script-body')}><img src="https://w.ladicdn.com/ladiui/ladishare/copy-20808.svg" style={{ marginLeft: '15px' }} /></div>
            </div>
            <div className="mt-8"><span>Lấy Shop ID:</span> <a href="/apps" className="ladiui link">Tích hợp cửa hàng</a></div>

            <label className="ladiui title-text-form-lever2" style={{ marginTop: '60px' }}>Tích hợp API</label>
            <div style={{ color: '#6D6D6D' }}>Tạo và sử dụng API Key bên dưới để tích hợp kết nối với cổng API của LadiShare. (<a href="#/">Xem Tài liệu hướng dẫn</a>)</div>
            <div className="ladiui form-group flex-row" style={{ gap: '12px' }}>
                <div className="box-api-key">
                    <i className="ladiui icon icon-ldp-on-access-key"></i>
                    <input type='text' className="ladiui form-control" id="api-key" name="api-key"
                        readOnly
                        defaultValue={apiKey ? apiKey.api_key : ''}
                    />
                    <div onClick={() => copyClipboard('api-key')}><img src="https://w.ladicdn.com/ladiui/ladishare/copy-20808.svg" className="icon-ldp" /></div>
                </div>
                <button className="ladiui btn" onClick={() => eventConfirmReCreateApiKey()}>Tạo API key mới</button>
            </div>

            <label className="ladiui title-text-form-lever2" style={{ marginTop: '60px' }}>Cài đặt Cookie</label>
            <div style={{ color: '#6D6D6D' }}>Thiết lập thời gian sống cho cookie khi bạn tích hợp SDK với cửa hàng của bạn</div>
            <div className="ladiui box-gray">
                <div className="ladiui form-group flex-row" style={{ gap: '12px', marginTop: 0, alignItems: 'flex-start' }}>
                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever2 left">Kiểu tính</label>
                        <div className="clearfix font-size-12">Phương thức ghi nhận cookie</div>
                        <LadiDropdown
                            labelDropdown="Kiểu tính"
                            listProp={getMethodTrackingClick()}
                            valueProp={cookieSetting.click_type}
                            hideLabel={true}
                            validProp={true}
                            classDropdown="dropdown-custom"
                            classSize="btn-size-34"
                            defaultTextProp="Chọn cách tính hoa hồng"
                            cbProp={eventSelectMethod}
                        />
                    </div>
                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever2 left">Thời gian sống</label>
                        <div className="clearfix font-size-12">Thiết lập thời gian sống cho cookie</div>
                        <NumericFormat
                            style={{ margin: '14px 0' }}
                            value={cookieSetting.life_time || ''}
                            allowLeadingZeros
                            allowNegative={false}
                            decimalScale={0}
                            thousandSeparator=","
                            className="ladiui form-control"
                            placeholder="Nhập thời gian sống (ngày)"
                            onValueChange={(values) => setCookieSetting({ ...cookieSetting, life_time: values.value })}
                        />
                    </div>
                    {cookieSetting.click_type === 'FIRST_CLICK'
                        ? <div className="ladiui">
                            <div className="ladiui clearfix">
                                <label className="ladiui title-text-form-lever2 left">Ghi đè cookie</label>
                                <div className="ladiui item-form switch">
                                    <label className="ladiui switch" style={{ minWidth: 0, margin: 0 }}>
                                        <input type="checkbox"
                                            field="enable"
                                            checked={cookieSetting.enable_overwrite || false}
                                            onChange={(e) => eventEnableOverwrite(e)} />
                                        <span className="ladiui slider round"></span>
                                    </label>
                                </div>
                                <div className="clearfix font-size-12">Ghi đè cookie cho affiliate khác nếu quá hạn</div>
                            </div>
                            {cookieSetting.enable_overwrite
                                ? <div className="ladiui" style={{ width: '100%' }}>
                                    <NumericFormat
                                        style={{ margin: '14px 0' }}
                                        value={cookieSetting.overwrite_life_time || ''}
                                        allowLeadingZeros
                                        allowNegative={false}
                                        decimalScale={0}
                                        thousandSeparator=","
                                        className="ladiui form-control"
                                        placeholder="Nhập thời hạn (ngày)"
                                        onValueChange={(values) => setCookieSetting({ ...cookieSetting, overwrite_life_time: values.value })}
                                    />
                                </div> : <></>}
                        </div> : <></>}
                </div>

                <div className="ladiui">
                    <div style={{ width: '142px' }}>
                        <button className="ladiui btn btn-sm btn-primary" onClick={() => saveCookieSetting()}>Lưu cài đặt</button>
                    </div>
                </div>
            </div>

            <div className="clearfix"></div>
        </div >
        <ConfirmModal
            id="re-create-api-key"
            title="Bạn có chắc muốn tạo lại API Key không?"
            content="Các ứng dụng sử dụng API Key cũ sẽ không sử dụng được nữa nếu không cập nhật lại."
            onOk={() => eventOkReCreateApiKey()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />
    </>;
}

export default (withTranslation()(SDKSetting));
