export const LIST_AFFILIATE = 'LIST_AFFILIATE';
export const AFFILIATE_LIST_FILTER = 'AFFILIATE_LIST_FILTER';
export const CREATE_AFFILIATE = 'CREATE_AFFILIATE';
export const SHOW_AFFILIATE = 'SHOW_AFFILIATE';
export const UPDATE_AFFILIATE = 'UPDATE_AFFILIATE';
export const DELETE_AFFILIATE = 'DELETE_AFFILIATE';
export const EXPORT_AFFILIATE = 'EXPORT_AFFILIATE';

export const DELETE_MULTI_AFFILIATE = 'DELETE_MULTI_AFFILIATE';
export const UPDATE_STATUS_MULTI_AFFILIATE = 'UPDATE_STATUS_MULTI_AFFILIATE';
export const UPDATE_OTHER_AFFILIATE = 'UPDATE_OTHER_AFFILIATE';
export const UPDATE_MULTI_AFFILIATE = 'UPDATE_MULTI_AFFILIATE';
export const GET_LEVEL_AFFILIATE = 'GET_LEVEL_AFFILIATE';
export const IMPORT_AFFILIATE = 'IMPORT_AFFILIATE';
export const AFFILIATE_PASSED_INVOICE = 'AFFILIATE_PASSED_INVOICE';
export const CREATE_MULTI_AFFILIATE_INVOICE_MONTH = 'CREATE_MULTI_AFFILIATE_INVOICE_MONTH';
export const LIST_AFFILIATE_REF = 'LIST_AFFILIATE_REF';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
