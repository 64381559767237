import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.LIST_TRAFFIC,
    meta: {
        endpoint: endpoint.TRAFFIC_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const listOrder = (data) => ({
    type: types.LIST_ORDER,
    meta: {
        endpoint: endpoint.LIST_ORDER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const listLead = (data) => ({
    type: types.LIST_LEAD,
    meta: {
        endpoint: endpoint.LIST_LEAD,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const listNewCustomer = (data) => ({
    type: types.LIST_NEW_CUSTOMER,
    meta: {
        endpoint: endpoint.LIST_NEW_CUSTOMER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const listProduct = (data) => ({
    type: types.LIST_PRODUCT,
    meta: {
        endpoint: endpoint.LIST_PRODUCT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const listVisitor = (data) => ({
    type: types.LIST_VISITOR,
    meta: {
        endpoint: endpoint.LIST_VISITOR,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const create = (data, cb) => ({
    type: types.CREATE_TRAFFIC,
    meta: {
        endpoint: endpoint.TRAFFIC_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const show = (id) => ({
    type: types.SHOW_TRAFFIC,
    meta: {
        endpoint: endpoint.TRAFFIC_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
    },
    hasSubAction: true,
});

const update = (data, cb) => ({
    type: types.UPDATE_TRAFFIC,
    meta: {
        endpoint: endpoint.TRAFFIC_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteTraffic = (data, cb) => ({
    type: types.DELETE_TRAFFIC,
    meta: {
        endpoint: endpoint.TRAFFIC_DELETE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const calcCommissionForTraffic = (data, cb) => ({
    type: types.CALCULATE_COMMISSION_FOR_TRAFFIC,
    meta: {
        endpoint: endpoint.TRAFFIC_CALCULATE_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const reCalcCommissionForTraffic = (data, cb) => ({
    type: types.RE_CALCULATE_COMMISSION_FOR_TRAFFIC,
    meta: {
        endpoint: endpoint.TRAFFIC_RE_CALCULATE_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const cancelCommissionForTraffic = (data, cb) => ({
    type: types.CANCEL_COMMISSION_FOR_TRAFFIC,
    meta: {
        endpoint: endpoint.TRAFFIC_CANCEL_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

export default {
    list,
    listOrder,
    listLead,
    listNewCustomer,
    listProduct,
    listVisitor,
    create,
    show,
    update,
    delete: deleteTraffic,
    calcCommissionForTraffic,
    reCalcCommissionForTraffic,
    cancelCommissionForTraffic,
};
