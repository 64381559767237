import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LadiDropdownMenu from '../../../components/LadiDropdownMenu';
import ConfirmModal from '../../../components/ConfirmModal';
import actionInvoiceRequest from '../../../redux/futures/request_invoice/actions';
import DefineConst from '../DefineConst';
import config from '../../../config/config';

function CheckAllActionRequestInvoice(props) {
    const { t, cb } = props;
    const ids = props.ids || [];

    const listAction = [
        { value: 'ACCEPT_ALL', name: t('COMMON.ACCEPT_ALL') },
        { value: 'REJECT_ALL', name: 'Từ chối' },
    ];

    const dispatch = useDispatch();
    const [actionSelected, setActionSelected] = useState(null);
    const [modalConfirm, setModalConfirm] = useState({});
    const [classBtnConfirm, setClassBtnConfirm] = useState('btn-primary');

    const getListAction = () => {
        if (config.APP_AFFILIATE) {
            return [{ value: 'CANCEL_ALL', name: 'Huỷ' }];
        }
        return listAction;
    };

    const eventSelectAction = (action) => {
        let dataModal = {};
        switch (action) {
            case 'ACCEPT_ALL':
                setClassBtnConfirm('btn-primary');
                dataModal.title = 'Bạn có chắc chắn muốn duyệt toàn bộ yêu cầu được chọn?';
                dataModal.content = 'Những yêu cầu ở trạng thái chờ duyệt mới được phép chuyển sang trạng thái duyệt';
                break;
            case 'CANCEL_ALL':
                setClassBtnConfirm('btn-primary');
                dataModal.title = 'Bạn có chắc chắn muốn huỷ toàn bộ yêu cầu được chọn?';
                dataModal.content = 'Những yêu cầu đã duyệt hoặc đã từ chối sẽ không bị huỷ';
                break;
            case 'REJECT_ALL':
                setClassBtnConfirm('btn-primary');
                dataModal.title = 'Bạn có chắc chắn muốn từ chối toàn bộ yêu cầu được chọn?';
                dataModal.content = 'Những yêu cầu ở trạng thái chờ mới được phép chuyển sang trạng thái từ chối';
                break;
            default:
        }
        setActionSelected(action);
        setModalConfirm(dataModal);
        window.LadiUI.showModal('modal-confirm-action-all');
    };

    const eventOkActionAll = () => {
        if (actionSelected) {
            switch (actionSelected) {
                case 'ACCEPT_ALL':
                    dispatch(actionInvoiceRequest.acceptMultiInvoiceRequest({ ids }, cb));
                    break;
                case 'CANCEL_ALL':
                    dispatch(actionInvoiceRequest.cancelMultiInvoiceRequest({ ids }, cb));
                    break;
                case 'REJECT_ALL':
                    dispatch(actionInvoiceRequest.rejectMultiInvoiceRequest({ ids }, cb));
                    break;
                default:
            }
        }
    };

    return ids.length > 0
        ? <>
            <div className="ladiui ladi-check-all-action">
                <LadiDropdownMenu
                    labelDropdown={'Chọn hành động'}
                    listProp={getListAction()}
                    defaultTextProp={t('COMMON.ACTION')}
                    hideLabel={true}
                    cbProp={eventSelectAction}
                    classSize=""
                    classDropdown=""
                />
            </div>
            <ConfirmModal
                id="modal-confirm-action-all"
                title={modalConfirm.title || ''}
                content={modalConfirm.content || ''}
                onOk={() => eventOkActionAll()}
                cancelText={t('COMMISSIONS.CANCELED')}
                okText={t('COMMISSIONS.OK')}
                classBtn={classBtnConfirm || 'btn-primary'}
            />
        </>
        : <></>;
}

export default (withTranslation()(CheckAllActionRequestInvoice));
