import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LadiDropdownMenu from '../../../components/LadiDropdownMenu';
import ConfirmModal from '../../../components/ConfirmModal';
import actionGroup from '../../../redux/futures/affiliate_group/actions';

function CheckAllActionGroup(props) {
    const { t, cb } = props;
    const ids = props.ids || [];

    const listAction = [
        { value: 'DELETE_ALL', name: t('COMMON.DELETE_ALL') },
    ];

    const dispatch = useDispatch();
    const [actionSelected, setActionSelected] = useState(null);
    const [modalConfirm, setModalConfirm] = useState({});
    const [classBtnConfirm, setClassBtnConfirm] = useState('btn-primary');

    const eventSelectAction = (action) => {
        let dataModal = {};
        switch (action) {
            case 'DELETE_ALL':
                setClassBtnConfirm('btn-danger');
                dataModal.title = 'Bạn có chắc chắn muốn xóa toàn bộ nhóm được chọn?';
                dataModal.content = <div>Những nhóm chứa thành viên hoặc là nhóm mặc định sẽ không được phép xóa.</div>;
                break;
            default:
        }
        setActionSelected(action);
        setModalConfirm(dataModal);
        window.LadiUI.showModal('modal-confirm-action-all');
    };

    const eventOkActionAll = () => {
        if (actionSelected) {
            switch (actionSelected) {
                case 'DELETE_ALL':
                    dispatch(actionGroup.deleteMultiAffiliateGroup({ ids }, cb));
                    break;
                default:
            }
        }
    };

    return ids.length > 0
        ? <>
            <div className="ladiui ladi-check-all-action">
                <LadiDropdownMenu
                    labelDropdown={'Chọn hành động'}
                    listProp={listAction}
                    defaultTextProp={t('COMMON.ACTION')}
                    hideLabel={true}
                    cbProp={eventSelectAction}
                    classSize=""
                    classDropdown=""
                />
            </div>
            <ConfirmModal
                id="modal-confirm-action-all"
                title={modalConfirm.title || ''}
                content={modalConfirm.content || ''}
                onOk={() => eventOkActionAll()}
                cancelText={t('COMMON.CANCELED')}
                okText={t('COMMON.OK')}
                classBtn={classBtnConfirm || 'btn-primary'}
            />
        </>
        : <></>;
}

export default (withTranslation()(CheckAllActionGroup));
