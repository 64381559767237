import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CheckAllActionEmailSender from './CheckAllActionEmailSender';
import appConfig from '../../../config/app';
import actionStore from '../../../redux/futures/store/actions';
import LadiPagination from '../../../components/LadiPagination';
import ModalSenderEmail from './ModalSenderEmail';
import BaseHelper from '../../../helpers/BaseHelper';
import ConfirmModal from '../../../components/ConfirmModal';
import DefineConst from '../DefineConst';

// eslint-disable-next-line max-lines-per-function
function SenderEmail(props) {
    const { t } = props;
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { _id: 'DESC' },
    };

    const dispatch = useDispatch();
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [senderEmail, setSenderEmail] = useState({});
    const [conditions, setConditions] = useState(defaultConditions);
    const listSender = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.store.senders }));

    const loadListSender = () => {
        dispatch(actionStore.listSender(conditions));
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setSelectedIds(ids);
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const eventCreateSender = () => {
        setIsEdit(false);
        window.LadiUI.showModal('modal-sender-email');
    };
    const eventEditSender = (item) => {
        setIsEdit(true);
        dispatch(actionStore.showSenderEmail(item._id));
        window.LadiUI.showModal('modal-sender-email');
    };

    const eventVerifySender = (item) => {
        setSenderEmail(item);
        window.LadiUI.showModal('modal-confirm-send-verify-email');
    };

    const eventMakeAsDefaultSender = (item) => {
        setSenderEmail(item);
        window.LadiUI.showModal('modal-confirm-make-as-default');
    };

    const eventDeleteSender = (item) => {
        setSenderEmail(item);
        window.LadiUI.showModal('modal-confirm-delete');
    };

    const eventOkActionDelete = () => {
        dispatch(actionStore.deleteSenderEmail({ id: senderEmail._id }, loadListSender));
    };

    const eventOkActionMakeAsDefault = () => {
        dispatch(actionStore.makeAsDefaultSenderEmail(senderEmail._id, loadListSender));
    };

    const eventOkActionSendVerify = () => {
        dispatch(actionStore.verifySenderEmail({ id: senderEmail._id }, loadListSender));
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.SENDER_EMAIL_STATUS.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-warning"></i>
                        <div>
                            {t('STORES.SENDER_EMAIL_STATUS.PENDING')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.SENDER_EMAIL_STATUS.VERIFIED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {t('STORES.SENDER_EMAIL_STATUS.VERIFIED')}
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    const renderRecord = (item) => (<tr className="ladiui table-vertical main" key={item._id}>
        <td scope="row">
            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element" name="checkbox" checked={selectedIds.includes(item._id)}
                value={item._id} onChange={eventCheck} />
        </td>
        <td>
            {item.name}
        </td>
        <td>
            {item.email}
        </td>
        <td>
            {getStatus(item.status)}
        </td>
        <td>
            {item.is_default ? <span className="ladiui badge badge-success">Mặc định</span> : <></>}
        </td>
        <td>
            {BaseHelper.formatStrToDate(item.created_at, 'DD/MM/YYYY')}
        </td>
        <td className="ladiui-dropdown">
            <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                    <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                        <i className="ladiui icon icon-ldp-dot"></i>
                    </button>
                    <ul className="ladiui dropdown-menu custom-dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {!item.is_default
                            ? <li><a className="ladiui dropdown-item" href="#/" onClick={() => eventMakeAsDefaultSender(item)}>
                                {t('COMMON.MAKE_AS_DEFAULT')}
                            </a></li>
                            : <></>}
                        <li><a className="ladiui dropdown-item" href="#/" onClick={() => eventEditSender(item)}>
                            {t('COMMON.EDIT')}
                        </a></li>
                        {item.status === DefineConst.SENDER_EMAIL_STATUS.PENDING
                            ? <li><a className="ladiui dropdown-item" href="#/" onClick={() => eventVerifySender(item)}>
                                {t('STORES.SEND_EMAIL_VERIFY')}
                            </a></li>
                            : <></>}
                        {!item.is_default
                            ? <li><a className="ladiui dropdown-item" href="#/" onClick={() => eventDeleteSender(item)}>
                                {t('COMMON.DELETE')}
                            </a></li>
                            : <></>}
                    </ul>
                </div>
            </div>
        </td>
    </tr>);

    useEffect(() => {
        loadListSender();
    }, [conditions]);

    return <>
        <div className="ladiui content-main-title flex-row">
            <div className="ladiui">
                <div className="ladiui main-title">
                    {t('STORES.EMAIL_SENDER')}
                </div>
                <span className="color-1">Đăng ký và quản lý email người gửi.</span>
            </div>
            <button type="button" className="ladiui btn-open-modal btn btn-primary" onClick={eventCreateSender}>
                <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                <div className="ladiui btn-custom-text">{t('STORES.ADD_SENDER_EMAIL')}</div>
            </button>
        </div>
        <table className="ladiui table text-left">
            <thead>
                <tr className="ladiui table-vertical header">
                    <th className="ladiui col-first" scope="col">
                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-commission"
                            name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                    </th>
                    <th width="250">
                        {selectedIds.length > 0 ? <CheckAllActionEmailSender ids={selectedIds} cb={loadListSender} /> : t('STORES.NAME_SENDER')}
                    </th>
                    <th>{t('STORES.EMAIL_SENDER')}</th>
                    <th>{t('STORES.STATUS')}</th>
                    <th></th>
                    <th scope="col">{t('COMMON.CREATED_AT')}</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {listSender.items.length > 0
                    ? listSender.items.map((values) => (renderRecord(values)))
                    : (<tr className="ladiui table-vertical main"><td colSpan="8" className="text-center"><div>{t('NO_DATA')}</div></td></tr>)}
            </tbody>
        </table>
        {listSender.total
            ? <LadiPagination
                conditionsProp={conditions}
                listName={t('STORES.EMAIL_SENDER')}
                items={listSender.items}
                total={listSender.total}
                limitAction={selectLimit}
                pageAction={selectPage}
            /> : ''}

        <ModalSenderEmail isEdit={isEdit} cb={() => dispatch(actionStore.listSender(conditions))} />

        <ConfirmModal
            id="modal-confirm-delete"
            title="Bạn chắc chắn muốn xoá email này?"
            content="Email sẽ được xoá khỏi hệ thống."
            onOk={() => eventOkActionDelete()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-danger"
        />
        <ConfirmModal
            id="modal-confirm-make-as-default"
            title="Bạn chắc chắn muốn đặt email này làm mặc định?"
            content="Email sẽ được tự động sử dụng khi gửi email thông báo."
            onOk={() => eventOkActionMakeAsDefault()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="modal-confirm-send-verify-email"
            title="Bạn có muốn gửi lại email xác thực?"
            content="Vui lòng check hòm thư hoặc trong thư mục spam."
            onOk={() => eventOkActionSendVerify()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />
    </>;
}

export default (withTranslation()(SenderEmail));
