import * as types from './types';

export default (state = {}, action) => {
    switch (action.type) {
        case types.REDIRECT:
            return {
                ...state,
                redirectTo: action.redirectTo,
            };
        default:
            return state;
    }
};

