import { differenceWith, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import BaseHelper from '../../../../helpers/BaseHelper';

function LadiDropdownMultiSelect2(props) {
    const {
        t, listProp, listCheckedProp, cb,
    } = props;

    const idRandom = BaseHelper.generateRandomString(10);
    const [selectedItem, setSelectedItem] = useState([]);
    const [unSelectedItem, setUnSelectedItem] = useState([]);

    const eventCheckItem = (item) => {
        const stateItems = [...selectedItem, item];
        stateItems.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setSelectedItem(stateItems);
        const data = unSelectedItem.filter((i) => i.value !== item.value);
        data.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setUnSelectedItem(data);
        cb(stateItems);
    };

    const eventUnCheckItem = (item) => {
        const stateItems = [...unSelectedItem, item];
        stateItems.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setUnSelectedItem(stateItems);
        const data = selectedItem.filter((i) => i.value !== item.value);
        data.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setSelectedItem(data);
        cb(data);
    };

    const showListChecked = () => {
        let showListChecked = [];
        for (let i = 0; i < selectedItem.length; i++) {
            showListChecked.push(selectedItem[i].name);
        }
        return showListChecked.join(', ');
    };

    const searchItem = () => {
        const keyword = document.getElementById(`multi-select-search-input-${idRandom}`).value;
        const dif = differenceWith(listProp, selectedItem, isEqual);
        const listSearch = dif.filter((item) => item.name.includes(keyword));
        setUnSelectedItem(listSearch);
    };

    const eventSearchItem = (e) => {
        if (e.keyCode === 13) {
            searchItem();
        }
    };

    const eventRemoveAllSelectItem = () => {
        const stateItems = [...unSelectedItem, ...selectedItem];
        stateItems.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setUnSelectedItem(stateItems);
        setSelectedItem([]);
        cb([]);
    };

    useEffect(() => {
        if (listProp) {
            const unSelectedItem = differenceWith(listProp, listCheckedProp, isEqual);
            unSelectedItem.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
            setUnSelectedItem(unSelectedItem);
        }
    }, [listProp]);

    useEffect(() => {
        if (listCheckedProp) {
            listCheckedProp.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
            setSelectedItem(listCheckedProp);
        }
    }, [listCheckedProp]);

    return <>
        <label className="ladiui title-text-form-lever2">Shop ID</label>
        <div className="ladiui btn-group ladi-dropdown-multi-select">
            <div className="ladiui select-search dropdown">
                <button className="ladiui btn btn-outline-light btn-sm dropdown-toggle custom-main-tool ml-0" data-toggle="dropdown" style={{ width: '100%' }}>
                    <i></i>
                    {selectedItem.length > 0
                        ? <>
                            <span className="ladiui dropdown-text" style={{ marginRight: '20px', width: '100%', maxWidth: '100%' }}>{showListChecked()}</span>
                            <span className="ladiui-btn-tag">{selectedItem.length}</span>
                            <span className="ladiui removeName" style={{ marginRight: '16px' }}
                                onClick={() => eventRemoveAllSelectItem()}> <i
                                    className="ladiui icon icon-ldp-close"></i></span>
                        </>
                        : <><span className="ladiui dropdown-text" style={{ marginRight: '10px' }}>Chọn Shop ID</span></>}
                </button>

                <ul className="ladiui dropdown-menu dropdown-right custom-people" style={{ left: 'auto', right: 0, width: '100%', maxHeight: '400px', overflowY: 'auto' }}>
                    <li className="ladiui search-group custom-people">
                        <input
                            className="ladiui search-field dropdown-toggle form-control custom-people"
                            placeholder="Tìm kiếm" aria-expanded="false" id={`multi-select-search-input-${idRandom}`}
                            onKeyDown={(e) => eventSearchItem(e)}
                            style={{ width: '100%' }} />
                        <i className="ladiui icon icon-search" onClick={searchItem}></i>
                    </li>
                    <li className="ladiui checked">
                        <ul className="ladiui ul-selected">
                            {selectedItem.map((item) => <li key={item.value} className="ladiui"><label
                                className="ladiui search-multi"><input type="checkbox" onChange={() => eventUnCheckItem(item)} checked
                                    className="ladiui size-checkbox" />{item.name}</label>
                            </li>)}
                        </ul>
                    </li>
                    <li className="ladiui unchecked">
                        <ul className="ladiui ul-unselected">
                            {unSelectedItem.map((item) => <li key={item.value} className="ladiui"><label
                                className="ladiui search-multi"><input type="checkbox" onChange={() => eventCheckItem(item)}
                                    className="ladiui size-checkbox" />{item.name}</label>
                            </li>)}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </>;
}

export default LadiDropdownMultiSelect2;
