import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import actionInvoice from '../../../redux/futures/invoice/actions';

// eslint-disable-next-line max-lines-per-function
function ModalNoteInvoice(props) {
    const { t } = props;

    const [note, setNote] = useState();

    const dispatch = useDispatch();
    const invoice = useSelector((state) => (state.invoices.invoice || {}));

    const cb = () => {
        window.LadiUI.closeModal('modal-note-invoice');
    };

    const closeModalNote = () => {
        setNote(invoice ? invoice.note : '');
        window.LadiUI.closeModal('modal-note-invoice');
    };

    const okModalNote = () => {
        const data = {
            id: invoice._id,
            note,
        };
        dispatch(actionInvoice.updateNote(data, cb));
    };

    useEffect(() => {
        if (invoice) setNote(invoice.note);
    }, [invoice]);

    return invoice ? <>
        <div className="ladiui modal" id="modal-note-invoice">
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">Ghi chú hoá đơn</label>
                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close" onClick={closeModalNote}>
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <textarea className="ladiui form-control" value={note || ''} onChange={(e) => setNote(e.target.value)} rows="5" placeholder="Nhập nội dung"></textarea>
                    </div>
                    <div className="ladiui modal-footer custom-ldp-popupX mod">
                        <div className="ladiui modal-footer-link">
                        </div>
                        <div className="ladiui footer-modal-btn">
                            <button type="button" className="ladiui btn btn-secondary bold" onClick={() => closeModalNote()}>{t('COMMON.CANCELED')}</button>
                            <button type="button" className="ladiui btn btn-primary" onClick={() => okModalNote()}>Chỉnh sửa</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </> : <></>;
}

export default (withTranslation()(ModalNoteInvoice));
