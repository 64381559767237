import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';

import DefineConst from '../DefineConst';

function ModalInvoiceRequestDetail(props) {
    const { t } = props;

    const invoiceRequest = useSelector((state) => (state.requestInvoice.invoiceRequest || {}));
    const affiliate = useSelector((state) => (state.requestInvoice.invoiceRequestAffiliate || {}));
    const commission = useSelector((state) => (state.requestInvoice.invoiceRequestCommission || []));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.INVOICE_STATUS.PENDING:
                statusContent = <>
                    <span className='ladiui badge badge-warning'>
                        {t('INVOICES.INVOICE_STATUS.PENDING')}
                    </span>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.APPROVED:
                statusContent = <>
                    <span className='ladiui badge badge-success'>
                        {t('INVOICES.INVOICE_STATUS.APPROVED')}
                    </span>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.REJECT:
                statusContent = <>
                    <span className='ladiui badge badge-mute'>
                        Từ chối
                    </span>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.CANCELED:
                statusContent = <>
                    <span className='ladiui badge badge-mute'>
                        {t('INVOICES.INVOICE_STATUS.CANCELED')}
                    </span>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    return invoiceRequest ? <>
        <div className="ladiui modal" id="modal-detail-invoice-request">
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">Yều cầu rút tiền</label>
                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="detail-invoice">
                            <div>
                                <div className="detail-invoice-affiliate-name">
                                    <label>Affiliate</label>
                                    <div><Link to={`/affiliates/${affiliate._id || ''}`} className="ladiui link">{affiliate.fullname || ''}</Link></div>
                                    <div>{affiliate.email || ''}</div>
                                </div>
                                <div className="detail-invoice-affiliate-code">
                                    <label>Mã Affiliate</label>
                                    <div>{affiliate.code || ''}</div>
                                </div>
                            </div>
                            <div className="detail-invoice-total-content">
                                <div className="detail-invoice-total">
                                    <div className="detail-invoice-total-total">
                                        <label>Tổng hoa hồng</label>
                                        <div>{BaseHelper.formatMoney(parseFloat(invoiceRequest.total || 0))}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-invoice">
                            <div>
                                <label>Trạng thái yêu cầu</label>
                                <div>{getStatus(invoiceRequest.status)}</div>
                            </div>
                            <div>
                                <label>Ngày lên yêu cầu</label>
                                <div>{BaseHelper.formatStrToDate(invoiceRequest.created_at || null)}</div>
                            </div>
                        </div>
                        <div style={{ marginTop: '25px' }}>
                            <label>Danh sách hoa hồng</label>
                            {commission && commission.length > 0
                                ? <div className="list-commission-invoice" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                    <table className="ladiui table text-left" style={{ marginTop: 0, minWidth: '680px' }}>
                                        <thead>
                                            <tr className="ladiui table-vertical header">
                                                <th>#</th>
                                                <th>Mã tham chiếu</th>
                                                <th>Loại</th>
                                                <th>Ngày duyệt</th>
                                                <th className="ladiui text-right">Tổng tiền</th>
                                                <th className="ladiui text-right">Hoa hồng</th>
                                                <th className="ladiui text-right">Thưởng</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {commission.map((item, index) => <tr className="ladiui table-vertical main" key={item._id}>
                                                <td>{index + 1}</td>
                                                <td>{item.data.code || item._id}</td>
                                                <td>{appConfig.LADISHARE.COMMISSION_TYPE.map((type) => (type.value === item.type ? type.name : ''))}</td>
                                                <td>{BaseHelper.formatStrToDate(item.commission_date)}</td>
                                                <td className="ladiui text-right">{BaseHelper.formatMoney(item.total, '', systemCurrency)}</td>
                                                <td className="ladiui text-right">{BaseHelper.formatMoney(item.amount, '', systemCurrency)}</td>
                                                <td className="ladiui text-right">{BaseHelper.formatMoney(item.bonus, '', systemCurrency)}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                : <></>}
                        </div>
                    </div>

                </div>
            </div>
        </div >
    </> : <></>;
}

export default (withTranslation()(ModalInvoiceRequestDetail));
