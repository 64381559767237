import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
// eslint-disable-next-line import/no-extraneous-dependencies
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../config/app';
import LadiDateRangePicker from '../../components/LadiDateRangePicker';
import BaseHelper from '../../helpers/BaseHelper';
import RecentlyCommission from './components/RecentlyCommission';
import RecentlyAffiliate from './components/RecentlyAffiliate';
import HistoryAction from './components/HistoryAction';
import actionStatistics from '../../redux/futures/statistics/actions';
import ChartConfig from './ChartConfig';
import styles from '../../assets/css/custom.module.css';
import FilterDashboardPublisher from './components/FilterDashboardPublisher';

// eslint-disable-next-line max-lines-per-function
function DashboardAdvertiser(props) {
    const { t } = props;
    const options = ChartConfig.options;
    const chartLang = ChartConfig.lang;
    const dispatch = useDispatch();
    const [conditions, setConditions] = useState();
    const [highChartOptions, setHighChartOptions] = useState(options);
    const report = useSelector((state) => (state.statistics.dailyReport));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const filterAction = (data) => {
        setConditions({ ...conditions, ...data });
    };

    const getCR = (p1, p2) => {
        if (p2 === 0) return 0;
        const rs = (100 * parseFloat(p1)) / parseFloat(p2);
        return Math.round((rs + Number.EPSILON) * 100) / 100;
    };

    useEffect(() => {
        const pointStartPendingCommission = report && report.chartData ? new Date(report.chartData.chartDataPendingCommission.start) : new Date();
        const pointStartApprovedCommission = report && report.chartData ? new Date(report.chartData.chartDataApprovedCommission.start) : new Date();
        const pointStartInvoicedCommission = report && report.chartData ? new Date(report.chartData.chartDataInvoicedCommission.start) : new Date();

        const pointStartNumberPendingCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberPendingCommission.start) : new Date();
        const pointStartNumberApprovedCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberApprovedCommission.start) : new Date();
        const pointStartNumberInvoicedCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberInvoicedCommission.start) : new Date();

        const series = [
            {
                name: 'Số hoa hồng chờ duyệt',
                color: '#E7B269',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberPendingCommission.data : [],
                pointStart: pointStartNumberPendingCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Số hoa hồng tạm duyệt',
                color: 'rgba(28, 0, 194, 0.65)',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberApprovedCommission.data : [],
                pointStart: pointStartNumberApprovedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Số hoa hồng đã duyệt',
                color: '#41B983',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberInvoicedCommission.data : [],
                pointStart: pointStartNumberInvoicedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Hoa hồng chờ duyệt',
                color: '#E7B269',
                data: report && report.chartData ? report.chartData.chartDataPendingCommission.data : [],
                pointStart: pointStartPendingCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
            {
                name: 'Hoa hồng tạm duyệt',
                color: 'rgba(28, 0, 194, 0.65)',
                data: report && report.chartData ? report.chartData.chartDataApprovedCommission.data : [],
                pointStart: pointStartApprovedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
            {
                name: 'Hoa hồng đã duyệt',
                color: '#41B983',
                data: report && report.chartData ? report.chartData.chartDataInvoicedCommission.data : [],
                pointStart: pointStartInvoicedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
        ];
        const tooltip = {
            dateTimeLabelFormats: { day: '%A, %e/%b' },
        };
        setHighChartOptions({ ...highChartOptions, series, tooltip });
    }, [report]);

    useEffect(() => {
        if (conditions) dispatch(actionStatistics.dailyReport(conditions));
    }, [conditions]);

    useEffect(() => {
        Highcharts.setOptions({
            lang: chartLang,
        });
    }, []);

    return <>
        <div className="ladiui content-main-full">
            <FilterDashboardPublisher filterAction={filterAction} />
            <div className="ladiui content-main-title summary-box">
                <div className={styles['box-statistics-publisher']}>
                    <div className={styles['box-content-publisher-name']} style={{ marginTop: '10px' }}>Affiliates</div>
                    <div className={styles['box-content-publisher-title']}>{BaseHelper.formatNumber(report ? report.totalAffiliate : 0)}</div>
                </div>
                <div className={styles['box-statistics-publisher']}>
                    <div className={styles['box-content-publisher-name']} style={{ marginTop: '10px' }}>Chuyển đổi&nbsp;
                        <div className="tooltip">
                            <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                            <span className="tooltiptext top" tooltiptext="Chuyển đổi = Đơn hàng hoặc Lead"></span>
                        </div>
                    </div>
                    <div className={styles['box-content-publisher-title']}>{BaseHelper.formatNumber(report ? report.totalConvert : 0)}</div>
                </div>
                <div className={styles['box-statistics-publisher']}>
                    <div className={styles['box-content-publisher-name']}>Hoa hồng chờ duyệt</div>
                    <div className={styles['box-content-publisher-title']}>{BaseHelper.formatMoney(report ? report.totalPendingCommission : 0, '', systemCurrency)}</div>
                    <div className={styles['box-content-publisher-number']}>
                        <div>{BaseHelper.formatNumber(report ? report.numberPendingCommission : 0)}</div>
                        <div>&nbsp;({getCR(report ? report.numberPendingCommission : 0, report ? report.totalConvert : 0)}%)&nbsp;</div>
                        <div className="tooltip">
                            <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                            <span className="tooltiptext top" tooltiptext="CR = Chờ duyệt/Số chuyển đổi"></span>
                        </div>
                    </div>
                </div>
                <div className={styles['box-statistics-publisher']}>
                    <div className={styles['box-content-publisher-name']}>Hoa hồng tạm duyệt</div>
                    <div className={styles['box-content-publisher-title']}>{BaseHelper.formatMoney(report ? report.totalApprovedCommission : 0, '', systemCurrency)}</div>
                    <div className={styles['box-content-publisher-number']}>
                        <div>{BaseHelper.formatNumber(report ? report.numberApprovedCommission : 0)}</div>
                        <div>&nbsp;({getCR(report ? report.numberApprovedCommission : 0, report ? report.numberPendingCommission : 0)}%)&nbsp;</div>
                        <div className="tooltip">
                            <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                            <span className="tooltiptext top" tooltiptext="CR = Tạm duyệt/Chờ duyệt"></span>
                        </div>
                    </div>
                </div>
                <div className={styles['box-statistics-publisher']}>
                    <div className={styles['box-content-publisher-name']}>Hoa hồng đã duyệt</div>
                    <div className={styles['box-content-publisher-title']}>{BaseHelper.formatMoney(report ? report.totalInvoicedCommission : 0, '', systemCurrency)}</div>
                    <div className={styles['box-content-publisher-number']}>
                        <div>{BaseHelper.formatNumber(report ? report.numberInvoicedCommission : 0)}</div>
                        <div>&nbsp;({getCR(report ? report.numberInvoicedCommission : 0, report ? report.numberApprovedCommission : 0)}%)&nbsp;</div>
                        <div className="tooltip">
                            <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                            <span className="tooltiptext top" tooltiptext="CR = Đã duyệt/Tạm duyệt"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ladiui content-main-title flex-row gap-24 align-item-top">
                <div className="ladiui box-statistics box-no-padding box-no-heigh" style={{ flex: '1 1 auto', display: 'block' }}>
                    <div className="ladiui box-title flex-row">
                        <label className="ladiui">Thống kê hoa hồng và chuyển đổi</label>
                        <div className="ladiui">
                        </div>
                    </div>
                    <div className="box-body" style={{ padding: '0 12px' }}>
                        <HighchartsReact highcharts={Highcharts} options={highChartOptions} />
                    </div>
                    <div className="box-footer"></div>
                </div>
                <HistoryAction />
            </div>
            <div className="ladiui content-main-title flex-row gap-24 align-item-top">
                <RecentlyAffiliate />
                <RecentlyCommission />
            </div>
        </div>
    </>;
}

export default (withTranslation()(DashboardAdvertiser));
