import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import actionStore from '../../../redux/futures/store/actions';

function EmailSmtp(props) {
    const { t } = props;
    const dispatch = useDispatch();
    const setting = useSelector((state) => (state.store.affiliateSetting));
    const [emailSetting, setEmailSetting] = useState({});

    const eventChangeSmtp = (e) => {
        const smtp = { ...emailSetting.smtp };
        smtp[e.target.name] = e.target.value;
        setEmailSetting({ ...emailSetting, smtp });
    };
    const eventEditEmailConfig = () => { };
    const eventDefaultEmailConfig = () => { };
    const eventDeleteEmailConfig = () => { };

    const cbLoadData = () => {
        const data = {
            field: 'email_setting',
        };
        dispatch(actionStore.getAffiliateSetting(data));
    };

    const updateEmailSetting = () => {
        const dataUpdate = {
            email_setting: emailSetting,
        };
        dispatch(actionStore.updateAffiliateSetting(dataUpdate));
    };

    useEffect(() => {
        if (setting) setEmailSetting(setting);
    }, [setting]);

    return <>
        <div className="ladiui content-main-title">
            <div className="ladiui">
                <div className="ladiui main-title">
                    {t('STORES.EMAIL_CONFIG_SMTP')}
                </div>
                <span className="color-1">Đăng ký và quản lý cấu hình SMTP email.</span>
            </div>
            <div className="email-notify-setting-content">
                <div className="mt-16">
                    <label className="ladiui title-text-form-lever2">Host</label>
                    <input className="ladiui form-control" type="text" name="host"
                        value={emailSetting.smtp ? emailSetting.smtp.host : ''}
                        onChange={(e) => eventChangeSmtp(e)}
                    />
                </div>
                <div className="mt-16">
                    <label className="ladiui title-text-form-lever2">Port</label>
                    <input className="ladiui form-control" type="text" name="port"
                        value={emailSetting.smtp ? emailSetting.smtp.port : ''}
                        onChange={(e) => eventChangeSmtp(e)}
                    />
                </div>
                <div className="mt-16">
                    <label className="ladiui title-text-form-lever2">Username</label>
                    <input className="ladiui form-control" type="text" name="username"
                        value={emailSetting.smtp ? emailSetting.smtp.username : ''}
                        onChange={(e) => eventChangeSmtp(e)}
                    />
                </div>
                <div className="mt-16">
                    <label className="ladiui title-text-form-lever2">Password</label>
                    <input className="ladiui form-control" type="text" name="password"
                        value={emailSetting.smtp ? emailSetting.smtp.password : ''}
                        onChange={(e) => eventChangeSmtp(e)}
                    />
                </div>
                <div className="mt-16">
                    <label className="ladiui title-text-form-lever2">Secure</label>
                    <input className="ladiui form-control" type="text" name="secure"
                        value={emailSetting.smtp ? emailSetting.smtp.secure : ''}
                        onChange={(e) => eventChangeSmtp(e)}
                    />
                </div>
                <div className="ladiui form-group">
                    <button className="ladiui btn btn-primary right" onClick={updateEmailSetting}>Cập nhật</button>
                    <button className="ladiui btn right btn-secondary" style={{ marginRight: '40px' }} onClick={cbLoadData}>Hủy bỏ</button>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(EmailSmtp));
