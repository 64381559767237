import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';
import BaseHelper from '../../../../helpers/BaseHelper';
import DefineConst from '../../DefineConst';

function CommissionPlanPaymentBoxConditionParentLevel(props) {
    const {
        t, paymentFor, paymentConditionParentProp, cb,
    } = props;
    const [paymentConditionParent, setPaymentConditionParent] = useState({ enable: false });

    const renderExplain = () => {
        let explain = <></>;
        if (paymentConditionParent.min_affiliate && paymentConditionParent.min_revenue) {
            explain = <>(*) Hoa hồng sẽ được tính nếu {paymentConditionParent.monthly_reset ? 'trong tháng' : ''} số affiliate cấp dưới lớn hơn hoặc bằng {BaseHelper.formatNumber(paymentConditionParent.min_affiliate)} và tổng doanh thu của affiliate đó lớn hơn hoặc bằng {BaseHelper.formatNumber(paymentConditionParent.min_revenue)}</>;
        }
        if (paymentConditionParent.min_affiliate && !paymentConditionParent.min_revenue) {
            explain = <>(*) Hoa hồng sẽ được tính nếu {paymentConditionParent.monthly_reset ? 'trong tháng' : ''} số affiliate cấp dưới lớn hơn hoặc bằng {BaseHelper.formatNumber(paymentConditionParent.min_affiliate)}</>;
        }
        if (!paymentConditionParent.min_affiliate && paymentConditionParent.min_revenue) {
            explain = <>(*) Hoa hồng sẽ được tính nếu {paymentConditionParent.monthly_reset ? 'trong tháng' : ''} tổng doanh thu của affiliate đó lớn hơn hoặc bằng {BaseHelper.formatNumber(paymentConditionParent.min_revenue)}</>;
        }
        return explain;
    };

    const eventChangeInput = (e) => {
        const name = e.target.name;
        let value;
        if (
            e.target.tagName.toLowerCase() === 'input'
            && e.target.getAttribute('type') === 'checkbox'
        ) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        const data = { ...paymentConditionParent };
        data[name] = value;
        setPaymentConditionParent(data);
        cb(data);
    };

    const changeInputValue = (fieldName, value) => {
        const data = { ...paymentConditionParent };
        data[fieldName] = value;
        setPaymentConditionParent(data);
        cb(data);
    };

    useEffect(() => {
        setPaymentConditionParent({ ...paymentConditionParent, ...paymentConditionParentProp });
    }, [paymentConditionParentProp]);

    return <>
        <div className="ladiui clearfix pt-20">
            <label className="ladiui title-text-form-lever2 left">Điều kiện hoa hồng cấp trên</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox" name="enable"
                        checked={paymentConditionParent !== null && (paymentConditionParent.enable || false)}
                        onChange={(e) => eventChangeInput(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Thiết lập điều kiện cho affiliate cấp trên</div>
        </div>
        {paymentConditionParent && paymentConditionParent.enable
            ? <>
                <div className="ladiui clearfix pt-20 d-grid-4" style={{ gridTemplateColumns: '169px 169px 169px 169px' }}>
                    <div className="ladiui left">
                        <label className="ladiui title-text-form-lever3">Số affiliate cấp dưới tối thiểu</label>
                        <div className="group-input-custom custom-form-dynamic">
                            <NumericFormat
                                value={paymentConditionParent.min_affiliate || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm"
                                placeholder="Giá trị tối thiểu"
                                onValueChange={(values) => changeInputValue('min_affiliate', values.value)}
                            />
                        </div>

                    </div>
                    <div className="ladiui left">
                        <label className="ladiui title-text-form-lever3">Tổng doanh thu tối thiểu</label>
                        <div className="group-input-custom custom-form-dynamic">
                            <NumericFormat
                                value={paymentConditionParent.min_revenue || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm"
                                placeholder="Giá trị tối thiểu"
                                onValueChange={(values) => changeInputValue('min_revenue', values.value)}
                            />
                        </div>
                    </div>
                    {paymentFor !== DefineConst.PAYMENT_FOR.KPIS
                        ? <div className="ladiui left">
                            <label className="ladiui title-text-form-lever3" htmlFor="monthly_reset">Reset hàng tháng</label>
                            <div className="ladiui item-form switch check-switch-2">
                                <label className="ladiui switch">
                                    <input type="checkbox"
                                        name="monthly_reset"
                                        checked={paymentConditionParent.monthly_reset || false}
                                        onChange={(e) => eventChangeInput(e)}
                                    />
                                    <span className="ladiui slider round"></span>
                                </label>
                            </div>
                        </div>
                        : <></>}
                </div>
                <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>
                    {renderExplain()}
                </div>
            </>
            : <></>}
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBoxConditionParentLevel));
