import React from 'react';
import { useSelector } from 'react-redux';
import CampaignAdvertiser from './CampaignAdvertiser';
import CampaignPublisher from './CampaignPublisher';

// eslint-disable-next-line max-lines-per-function
function Campaign() {
    const scope = useSelector((state) => (state.store.userInfo.scopes));

    return <>
        {scope === 'ADVERTISER' ? <CampaignAdvertiser /> : <CampaignPublisher />}
    </>;
}

export default Campaign;
