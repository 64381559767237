import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_COMMISSION_PLAN: {
            return {
                ...state,
                action: types.LIST_COMMISSION_PLAN,
                commissionPlans: action.status ? { items: action.payload.items, total: action.payload.total } : { items: [], total: 0 },
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_COMMISSION_PLAN: {
            return {
                ...state,
                action: types.CREATE_COMMISSION_PLAN,
                commissionPlan: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_COMMISSION_PLAN: {
            return {
                ...state,
                action: types.SHOW_COMMISSION_PLAN,
                commissionPlan: action.status ? action.payload.commissionPlan : {},
                commissionPlanPayments: action.status ? action.payload.commissionPlanPayments : [],
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_COMMISSION_PLAN_DEFAULT: {
            return {
                ...state,
                action: types.SHOW_COMMISSION_PLAN_DEFAULT,
                commissionPlanDefault: action.status ? action.payload.commissionPlan : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_COMMISSION_PLAN: {
            return {
                ...state,
                action: types.UPDATE_COMMISSION_PLAN,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_COMMISSION_PLAN: {
            return {
                ...state,
                action: types.DELETE_COMMISSION_PLAN,
                status: action.status,
                message: action.message,
                showNotify: true,
            };
        }

        case types.MAKE_AS_DEFAULT_COMMISSION_PLAN: {
            return {
                ...state,
                action: types.MAKE_AS_DEFAULT_COMMISSION_PLAN,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
