import validator from 'validator';
import baseHelper from './BaseHelper';

class ValidatorHelper {
    isLength(data, max = undefined, min = 0) {
        return validator.isLength(data, min, max);
    }

    isLengthMoreThan(data = '', min) {
        return data.length < min;
    }

    isLengthLessThan(data = '', max) {
        return data.length > max;
    }

    isEmpty(data = '') {
        return baseHelper.isEmpty(data);
    }

    isPhone(data) {
        if (this.isEmpty(data)) {
            return true;
        }

        return validator.isMobilePhone(data);
    }

    isEmail(data) {
        if (this.isEmpty(data)) {
            return true;
        }

        return validator.isEmail(data);
    }

    isUrl(data) {
        if (this.isEmpty(data)) {
            return true;
        }

        return validator.isURL(data);
    }

    isNumber(data) {
        if (this.isEmpty(data)) {
            return true;
        }

        return validator.toInt(data);
    }

    isJSON(data) {
        if (this.isEmpty(data)) {
            return true;
        }

        return validator.isJSON(data);
    }

    isURL(data) {
        if (this.isEmpty(data)) {
            return true;
        }

        return validator.isURL(data);
    }
}

export default new ValidatorHelper();
