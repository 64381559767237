import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data, cb) => ({
    type: types.LIST_LINK,
    meta: {
        endpoint: endpoint.LIST_LINK,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const show = (id, cb) => ({
    type: types.SHOW_LINK,
    meta: {
        endpoint: endpoint.SHOW_LINK,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const create = (data, cb) => ({
    type: types.CREATE_LINK_PUBLISHER,
    meta: {
        endpoint: endpoint.CREATE_LINK_PUBLISHER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteLink = (data, cb) => ({
    type: types.DELETE_LINK_PUBLISHER,
    meta: {
        endpoint: endpoint.DELETE_LINK_PUBLISHER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

export default {
    list,
    show,
    create,
    deleteLink,
};
