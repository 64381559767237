import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiDateRangePicker from '../../components/LadiDateRangePicker';
import BaseHelper from '../../helpers/BaseHelper';
import appConfig from '../../config/app';
import SearchCondition from './components/SearchCondition';
import ListInvoice from './components/ListInvoice';
import FilterCondition from './components/FilterCondition';
import ExportInvoice from './components/ExportInvoice';
import DefineConst from './DefineConst';
import actionInvoice from '../../redux/futures/invoice/actions';
import ExportBankInfoAffiliate from './components/ExportBankInfoAffiliate';
import config from '../../config/config';
import ModalCreateInvoice from './components/ModalCreateInvoice';

// eslint-disable-next-line max-lines-per-function
function Invoice(props) {
    const { t } = props;

    const defaultCondtions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { _id: 'DESC' },
    };
    const dispatch = useDispatch();
    const moment = BaseHelper.getMoment();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [conditions, setConditions] = useState(defaultCondtions);
    const [selectedIds, setSelectedIds] = useState([]);

    const filterActionProp = (conditionRecived) => {
        setConditions({ ...conditionRecived });
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const actionExportProp = (actionExport) => {
        let exportCondition = { ...conditions };
        switch (actionExport) {
            case DefineConst.EXPORT_ACTIONS.FIRST_PAGE:
                exportCondition.page = 1;
                break;
            case DefineConst.EXPORT_ACTIONS.SELECTED_RECORDS:
                if (selectedIds.length <= 0) {
                    window.LadiUI.toast('danger', '', 'Vui lòng chọn ít nhất 1 bản ghi', 5000, 'bottom-left');
                    return;
                }
                exportCondition = { ids: selectedIds.join(',') };
                break;
            case DefineConst.EXPORT_ACTIONS.ALL_RECORDS:
                delete exportCondition.page;
                delete exportCondition.limit;
                break;
            default:
        }
        dispatch(actionInvoice.exportInvoice(exportCondition));
    };

    const eventExportInvoiceData = () => {
        window.LadiUI.showModal('modal-export-invoice');
    };

    const eventExportBankInfoData = () => {
        window.LadiUI.showModal('modal-export-bank-info-data');
    };

    const actionExportBankInfoProp = (actionExport) => {
        let exportCondition = { ...conditions };
        switch (actionExport) {
            case DefineConst.EXPORT_ACTIONS.FIRST_PAGE:
                exportCondition.page = 1;
                break;
            case DefineConst.EXPORT_ACTIONS.SELECTED_RECORDS:
                if (selectedIds.length === 0) {
                    window.LadiUI.toast('danger', '', 'Vui lòng chọn ít nhất 1 bản ghi', 5000, 'bottom-left');
                    return;
                }
                exportCondition = { ids: selectedIds.join(',') };
                break;
            case DefineConst.EXPORT_ACTIONS.ALL_RECORDS:
                delete exportCondition.page;
                delete exportCondition.limit;
                break;
            default:
        }
        dispatch(actionInvoice.exportBankInfoAffiliate(exportCondition));
    };

    const openModalCreateInvoice = () => {
        window.LadiUI.showModal('modal-create-invoice');
    };

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title flex-row content-main-report">
                <div className="ladiui flex-row header-main-report">
                    <div className="ladiui main-title">
                        {t('INVOICES.LIST_INVOICE')}
                    </div>
                    <div className="calendar-search">
                        <LadiDateRangePicker
                            startDateId="fromDate"
                            endDateId="toDate"
                            startDate={fromDate}
                            endDate={toDate}
                            onDatesChange={({ startDate, endDate }) => {
                                const startDateNew = BaseHelper.getFromDate(startDate);
                                const endDateNew = BaseHelper.getToDate(endDate);
                                setFromDate(startDateNew);
                                setToDate(endDateNew);
                                if ((startDateNew && endDateNew) || (!startDateNew && !endDateNew)) {
                                    setConditions({ ...conditions, ...{ date_from: startDateNew ? startDateNew.format('YYYY-MM-DD 00:00:01') : null, date_to: endDateNew ? endDateNew.format('YYYY-MM-DD 23:59:59') : null } });
                                }
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => {
                                setFocusedInput(focusedInput);
                            }}
                            showDefaultInputIcon={true}
                            inputIconPosition="after"
                            small={true}
                            hideKeyboardShortcutsPanel={true}
                            customInputIcon={
                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                            }
                            startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            isOutsideRange={() => false}
                            isDayHighlighted={(date) => moment().isSame(date, 'day')}
                            minimumNights={0}
                            showClearDates={true}
                        />
                    </div>
                </div>
                <div className="ladiui flex" style={{ gap: 12 }}>
                    <div className="ladiui">
                        <div className="ladiui dropdown">
                            <button className="ladiui btn btn-outline-primary dropdown-toggle import-data-btn btn-export-invoice" data-toggle="dropdown" style={{ minWidth: 0 }}>
                                Xuất dữ liệu
                            </button>
                            <ul className="ladiui dropdown-menu" style={{ right: 0 }}>
                                <li onClick={() => eventExportInvoiceData()}>
                                    <a className="ladiui dropdown-item" href="#/">Xuất danh sách hoá đơn</a>
                                </li>
                                <li onClick={() => eventExportBankInfoData()}>
                                    <a className="ladiui dropdown-item" href="#/">Xuất file mẫu thanh toán</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {!config.APP_AFFILIATE
                        ? <button className="ladiui btn btn-primary btn-request-invoice" style={{ minWidth: 0 }}
                            onClick={() => openModalCreateInvoice()}>
                            <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt=""></img>
                            <div className="ladiui btn-custom-text ">Tạo hoá đơn</div>
                        </button>
                        : <></>}
                </div>
            </div>
            <div className="ladiui content-main-tool flex-row align-item-baseline content-main-tool-2">
                <div className="ladiui btn-group main-filter-condition">
                    <FilterCondition conditionProp={conditions} filterActionProp={filterActionProp} />
                </div>
                <div className="main-search-condition">
                    <SearchCondition
                        keywordProp={conditions.keyword}
                        eventSearchKeyboardProp={eventSearchKeyboard}
                        eventClickSearchButtonProp={eventClickSearchButton}
                    />
                </div>
            </div>
            <ListInvoice
                selectedIdsProp={selectedIds}
                setSelectedIdsProp={setSelectedIds}
                conditionsProp={conditions}
                conditionActionProp={(conditionRecived) => setConditions({ ...conditions, ...conditionRecived })} />
        </div>
        <ExportInvoice actionExportProp={actionExportProp} />
        <ExportBankInfoAffiliate actionExportProp={actionExportBankInfoProp} />
        <ModalCreateInvoice conditionsProp={conditions} />
    </>;
}

export default (withTranslation()(Invoice));
