import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiPagination from '../../../components/LadiPagination';

import actionInvoice from '../../../redux/futures/invoice/actions';
import CheckAllActionInvoice from '../../invoices/components/CheckAllActionInvoice';
import ModalInvoiceDetail from '../../invoices/components/ModalInvoiceDetail';
import DefineConst from '../../invoices/DefineConst';
import BaseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import LadiDateRangePicker from '../../../components/LadiDateRangePicker';
import SearchCondition from '../../commissions/components/SearchCondition';
import ExportInvoice from '../../invoices/components/ExportInvoice';

// eslint-disable-next-line max-lines-per-function
function ListInvoice(props) {
    const { t, affiliateIdProp } = props;

    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { _id: 'DESC' },
        affiliate_id: affiliateIdProp,
    };

    const dispatch = useDispatch();
    const moment = BaseHelper.getMoment();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const [invoice, setInvoice] = useState();
    const listInvoice = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.invoices.invoices }));
    const affiliates = useSelector((state) => (state.invoices.affiliates));

    const [conditions, setConditions] = useState(defaultConditions);

    const cbLoadList = () => {
        dispatch(actionInvoice.list(conditions));
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const eventExportData = () => {
        window.LadiUI.showModal('modal-export-invoice');
    };

    const actionExportProp = (actionExport) => {
        let exportCondition = { ...conditions };
        switch (actionExport) {
            case DefineConst.EXPORT_ACTIONS.FIRST_PAGE:
                exportCondition.page = 1;
                break;
            case DefineConst.EXPORT_ACTIONS.SELECTED_RECORDS:
                if (selectedIds.length <= 0) {
                    window.LadiUI.toast('danger', '', 'Vui lòng chọn ít nhất 1 bản ghi', 5000, 'bottom-left');
                    return;
                }
                exportCondition = { ids: selectedIds.join(',') };
                break;
            case DefineConst.EXPORT_ACTIONS.ALL_RECORDS:
                delete exportCondition.page;
                delete exportCondition.limit;
                break;
            default:
        }
        dispatch(actionInvoice.exportInvoice(exportCondition));
    };

    const eventDetailInvoice = (item) => {
        setInvoice(item);
        window.LadiUI.showModal('modal-detail-invoice');
    };

    const eventUpdateStatusInvoice = (data) => {
        dispatch(actionInvoice.updateStatus(data, cbLoadList));
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        if (e.target.checked) {
            setSelectedIds([...selectedIds, ...[id]]);
        } else {
            const ids = selectedIds.filter((item) => item !== id);
            setSelectedIds(ids);
        }
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.INVOICE_STATUS.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-warning"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.PENDING')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.APPROVED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.APPROVED')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.PAID:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.PAID')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.CANCELED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-mute"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.CANCELED')}
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    const renderRecord = (item) => <tr className="ladiui table-vertical main" key={item._id}>
        <td scope="row">
            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element" name="checkbox" checked={selectedIds.includes(item._id)}
                value={item._id} onChange={eventCheck} />
        </td>
        <td>{affiliates.map((affiliate) => (affiliate._id === item.affiliate_id ? affiliate.code : ''))}</td>
        <td>{affiliates.map((affiliate) => (affiliate._id === item.affiliate_id ? affiliate.email : ''))}</td>
        <td>{BaseHelper.formatMoney(item.amount || 0)}</td>
        <td>{BaseHelper.formatMoney(item.tax || 0)}</td>
        <td>{getStatus(item.status)}</td>
        <td>{BaseHelper.formatStrToDate(item.created_at || null, 'DD/MM/YYYY')}</td>
        <td>{BaseHelper.formatStrToDate(item.paid_at || null, 'DD/MM/YYYY')}</td>
        <td>{BaseHelper.formatMoney(parseFloat(item.amount || 0) - parseFloat(item.tax || 0))}</td>
        <td className="ladiui-dropdown">
            <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                    <button data-toggle="dropdown"
                        className="ladiui-btn-dropdown dropdown-toggle">
                        <i className="ladiui icon icon-ldp-dot"></i>
                    </button>
                    <ul className="ladiui dropdown-menu custom-dropdown-menu"
                        aria-labelledby="dropdownMenuButton1">
                        <li onClick={() => eventDetailInvoice(item)}>
                            <a className="ladiui dropdown-item" href="#/">
                                {t('INVOICES.DETAIL_INVOICE')}
                            </a>
                        </li>
                        {item.status === DefineConst.INVOICE_STATUS.PENDING
                            ? <li onClick={() => eventUpdateStatusInvoice({ status: DefineConst.INVOICE_STATUS.APPROVED, id: item._id })}>
                                <a className="ladiui dropdown-item" href="#/">
                                    {t('INVOICES.ACCEPT_INVOICE')}
                                </a>
                            </li> : <></>
                        }
                        {item.status === DefineConst.INVOICE_STATUS.PENDING || item.status === DefineConst.INVOICE_STATUS.APPROVED
                            ? <li onClick={() => eventUpdateStatusInvoice({ status: DefineConst.INVOICE_STATUS.CANCELED, id: item._id })}>
                                <a className="ladiui dropdown-item" href="#/">
                                    {t('INVOICES.REFUSE_INVOICE')}
                                </a>
                            </li> : <></>
                        }

                    </ul>
                </div>
            </div>
        </td>
    </tr>;

    useEffect(() => {
        if (affiliateIdProp) cbLoadList();
    }, [conditions, affiliateIdProp]);

    return <>
        <div className="ladiui content-main-tool flex-row align-item-baseline" style={{ marginTop: '24px' }}>
            <div className="ladiui btn-group">
                <LadiDateRangePicker
                    startDateId="fromDate"
                    endDateId="toDate"
                    startDate={fromDate}
                    endDate={toDate}
                    onDatesChange={({ startDate, endDate }) => {
                        const startDateNew = BaseHelper.getFromDate(startDate);
                        const endDateNew = BaseHelper.getToDate(endDate);
                        setFromDate(startDateNew);
                        setToDate(endDateNew);
                        setConditions({ ...conditions, ...{ date_from: startDateNew, date_to: endDateNew } });
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => {
                        setFocusedInput(focusedInput);
                    }}
                    showDefaultInputIcon={true}
                    inputIconPosition="after"
                    small={true}
                    hideKeyboardShortcutsPanel={true}
                    customInputIcon={
                        <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                    }
                    startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                    endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                    isOutsideRange={() => false}
                    isDayHighlighted={(date) => moment().isSame(date, 'day')}
                    minimumNights={0}
                    showClearDates={true}
                />
            </div>
            <div style={{ minWidth: 'calc(100% - 470px)', marginLeft: '40px' }}>
                <SearchCondition
                    keywordProp={conditions.keyword}
                    eventSearchKeyboardProp={eventSearchKeyboard}
                    eventClickSearchButtonProp={eventClickSearchButton}
                />
            </div>
            <div className="ladiui btn-group custom-btn-list-landingpage">
                <button className="ladiui btn btn-light" onClick={eventExportData}>
                    <img src="https://w.ladicdn.com/design-system/icons/icon-export.svg"
                        style={{ marginBottom: '2px', marginRight: '10px' }} />{t('COMMON.EXPORT_DATA')}
                </button>
            </div>
        </div>
        <table className="ladiui table text-left">
            <thead>
                <tr className="ladiui table-vertical header">
                    <th className="ladiui col-first" scope="col">
                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-invoice"
                            name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                    </th>
                    <th>
                        {selectedIds.length > 0 ? <CheckAllActionInvoice ids={selectedIds} cb={() => cbLoadList()} /> : t('AFFILIATES.CODE_AFFILIATE')}
                    </th>
                    <th>{t('AFFILIATES.EMAIL')}</th>
                    <th>{t('INVOICES.AMOUNT')}</th>
                    <th scope="col">{t('INVOICES.TAX')}</th>
                    <th>{t('INVOICES.STATUS')}</th>
                    <th scope="col">{t('COMMON.CREATED_AT')}</th>
                    <th scope="col">{t('INVOICES.PAID_DATE')}</th>
                    <th scope="col">{t('INVOICES.TOTAL_AMOUNT')}</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {listInvoice.items.length > 0
                    ? listInvoice.items.map((values) => (renderRecord(values)))
                    : (<tr className="ladiui table-vertical main"><td colSpan="8" className="text-center"><div>{t('INVOICES.NO_INVOICE')}</div></td></tr>)}
            </tbody>
            <tfoot>
                <tr className="ladiui table-vertical summary">
                    <td colSpan={8}><span className="bold-500 ml-32">{t('AFFILIATES.TOTAL_ALL')}</span></td>
                    <td>
                        <span className="bold-500">
                            {listInvoice.summary.totalAmount ? BaseHelper.formatMoney(listInvoice.summary.totalAmount) : BaseHelper.formatMoney(0)}
                        </span>
                    </td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
        {listInvoice.items.length > 0
            ? <LadiPagination
                conditionsProp={conditions}
                listName={t('INVOICES.INVOICE')}
                items={listInvoice.items}
                total={listInvoice.total}
                limitAction={selectLimit}
                pageAction={selectPage}
            /> : ''}
        <ModalInvoiceDetail id={invoice ? invoice._id : null} conditionsProp={conditions} />
        <ExportInvoice actionExportProp={actionExportProp} />
    </>;
}

export default (withTranslation()(ListInvoice));
