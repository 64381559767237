import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import appConfig from '../../config/app';
import LadiDateRangePicker from '../../components/LadiDateRangePicker';
import BaseHelper from '../../helpers/BaseHelper';
import Order from './components/Order';
import Lead from './components/Lead';
import NewCustomer from './components/NewCustomer';
import Product from './components/Product';
import Visitor from './components/Visitor';

function Source(props) {
    const { t } = props;

    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { _id: -1 },
    };

    const moment = BaseHelper.getMoment();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [conditions, setConditions] = useState(defaultConditions);
    const [tabSelected, setTabSelected] = useState('ORDER');

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui flex-row">
                    <div className="ladiui main-title">
                        {t('SOURCES.LIST_SOURCE')}
                    </div>
                    <div style={{ marginLeft: '22px' }}>
                        <LadiDateRangePicker
                            startDateId="fromDate"
                            endDateId="toDate"
                            startDate={fromDate}
                            endDate={toDate}
                            onDatesChange={({ startDate, endDate }) => {
                                const startDateNew = BaseHelper.getFromDate(startDate);
                                const endDateNew = BaseHelper.getToDate(endDate);
                                setFromDate(startDateNew);
                                setToDate(endDateNew);
                                setConditions({ ...conditions, ...{ date_from: startDateNew, date_to: endDateNew } });
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => {
                                setFocusedInput(focusedInput);
                            }}
                            showDefaultInputIcon={true}
                            inputIconPosition="after"
                            small={true}
                            hideKeyboardShortcutsPanel={true}
                            customInputIcon={
                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg"/>
                            }
                            startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            isOutsideRange={() => false}
                            isDayHighlighted={(date) => moment().isSame(date, 'day')}
                            minimumNights={0}
                            showClearDates={true}
                        />
                    </div>
                </div>
            </div>
            <div className="ladiui list-source">
                <ul className="ladiui nav nav-tabs new-tab-custom" id="myTab" role="tablist">
                    <li className="ladiui nav-item" role="presentation" onClick={() => setTabSelected('ORDER')}>
                        <span className={`ladiui tab-link ${tabSelected === 'ORDER' ? 'active' : ''}`} id="order-tab" data-toggle="tab"
                            data-parent="myTab" data-target="order">{t('SOURCES.ORDER')}</span>
                    </li>
                    <li className="ladiui nav-item" role="presentation" onClick={() => setTabSelected('LEAD')}>
                        <span className={`ladiui tab-link ${tabSelected === 'LEAD' ? 'active' : ''}`} id="lead-tab" data-toggle="tab" data-parent="myTab"
                            data-target="lead">Lead</span>
                    </li>
                    <li className="ladiui nav-item" role="presentation" onClick={() => setTabSelected('NEW_CUSTOMER')}>
                        <span className={`ladiui tab-link ${tabSelected === 'NEW_CUSTOMER' ? 'active' : ''}`} id="new-customer-tab" data-toggle="tab" data-parent="myTab"
                            data-target="new-customer">{t('SOURCES.NEW_CUSTOMER')}</span>
                    </li>
                    <li className="ladiui nav-item" role="presentation" onClick={() => setTabSelected('PRODUCT')}>
                        <span className={`ladiui tab-link ${tabSelected === 'PRODUCT' ? 'active' : ''}`} id="product-tab" data-toggle="tab" data-parent="myTab"
                            data-target="product">{t('SOURCES.PRODUCT')}</span>
                    </li>
                    <li className="ladiui nav-item" role="presentation" onClick={() => setTabSelected('VISITOR')}>
                        <span className={`ladiui tab-link ${tabSelected === 'VISITOR' ? 'active' : ''}`} id="visitor-tab" data-toggle="tab" data-parent="myTab"
                            data-target="visitor">Visitor</span>
                    </li>
                </ul>
                <div className="ladiui tab-content" id="myTabContent">
                    <div className={`ladiui tab-pane fade ${tabSelected === 'ORDER' ? 'open' : ''}`} id="order" role="tabpanel"
                        aria-labelledby="order-tab">
                        {<Order conditionProps={conditions} />}

                    </div>
                    <div className={`ladiui tab-pane fade ${tabSelected === 'LEAD' ? 'open' : ''}`} id="lead" role="tabpanel"
                        aria-labelledby="lead-tab">
                        {<Lead conditionProps={conditions} />}
                    </div>
                    <div className={`ladiui tab-pane fade ${tabSelected === 'NEW_CUSTOMER' ? 'open' : ''}`} id="new-customer" role="tabpanel"
                        aria-labelledby="new-customer-tab">
                        {<NewCustomer conditionProps={conditions} />}
                    </div>
                    <div className={`ladiui tab-pane fade ${tabSelected === 'PRODUCT' ? 'open' : ''}`} id="product" role="tabpanel"
                        aria-labelledby="product-tab">
                        {<Product conditionProps={conditions} />}
                    </div>
                    <div className={`ladiui tab-pane fade ${tabSelected === 'VISITOR' ? 'open' : ''}`} id="visitor" role="tabpanel"
                        aria-labelledby="visitor-tab">
                        {<Visitor conditionProps={conditions} />}
                    </div>
                </div>
            </div>

        </div>
    </>;
}

export default (withTranslation()(Source));
