import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BaseHelper from '../../../helpers/BaseHelper';
import actionPublisherLink from '../../../redux/futures/link/actions';

// eslint-disable-next-line max-lines-per-function
function ModalPublisherLink(props) {
    const {
        t, isEdit, cb,
    } = props;

    const [linkPublisher, setLinkPublisher] = useState({});
    const campaign = useSelector((state) => (state.campaigns.campaign));

    const dispatch = useDispatch();

    const cbHideModal = () => {
        cb();
        setLinkPublisher({});
        window.LadiUI.closeModal('modal-publisher-link');
    };

    const eventCancel = () => {
        setLinkPublisher({});
        window.LadiUI.closeModal('modal-publisher-link');
    };

    const eventCreate = () => {
        if (!campaign._id) {
            window.LadiUI.toast('danger', '', 'Vui lòng chọn chiến dịch', 5000, 'bottom-left');
            return;
        }
        if (!campaign.url) {
            window.LadiUI.toast('danger', '', 'Vui lòng chọn chiến dịch chứa link gốc', 5000, 'bottom-left');
            return;
        }
        if (!BaseHelper.isValidUrl(campaign.url)) {
            window.LadiUI.toast('danger', '', 'Vui lòng chọn chiến dịch chứa link gốc đúng định dạng', 5000, 'bottom-left');
            return;
        }

        const data = {
            ...linkPublisher,
            campaign_id: campaign._id,
            base_url: campaign.url,
        };
        dispatch(actionPublisherLink.create(data, cbHideModal));
    };

    const eventUpdate = () => {

    };

    return <div className="ladiui modal" id="modal-publisher-link">
        <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
            <div className="ladiui modal-content custom-ldp">
                <div className="ladiui modal-header custom-ldp">
                    <label className="ladiui title-text-form-lever1">{isEdit ? 'Sửa link' : 'Tạo link'}</label>
                    <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close" onClick={() => eventCancel()}>
                        <i className="ladiui icon icon-ldp-close"></i>
                    </button>
                </div>
                <div className="ladiui form-group-custom">
                    <label className="ladiui title-text-form-lever2" >Chiến dịch</label>
                    <input className="ladiui form-control"
                        value={campaign.title || ''} readOnly
                    />
                </div>
                <div className="ladiui form-group-custom">
                    <label className="ladiui title-text-form-lever2" >Link gốc</label>
                    <input id="name" name="name" className="ladiui form-control" placeholder="Nhập link gốc" required
                        style={{ wordBreak: 'break-all' }}
                        value={campaign.url} readOnly
                    />

                </div>

                <div>
                    <div className="ladiui form-group-custom">
                        <div className="ladiui flex-row">
                            <label className="ladiui title-text-form-lever2">Thông tin thêm</label>
                        </div>
                    </div>
                    <div className="order-info-content ladiui item bg-form bg-commissionn-plan" style={{ marginTop: 0 }}>
                        <div className="ladiui item bg-form-sub" style={{ paddingBottom: '16px', margin: '0 16px' }}>
                            <div className="d-grid-2">
                                <div className="ladiui pt-20">
                                    <label className="ladiui title-text-form-lever2" >Utm Source</label>
                                    <input id="utm_source" name="utm_source" className="ladiui form-control" placeholder="facebook, google,..."
                                        value={linkPublisher.utm_source || ''}
                                        onChange={(e) => setLinkPublisher({ ...linkPublisher, utm_source: e.target.value })}
                                    />
                                </div>
                                <div className="ladiui pt-20">
                                    <label className="ladiui title-text-form-lever2" >Utm Medium</label>
                                    <input id="utm_medium" name="utm_medium" className="ladiui form-control" placeholder="email, cpc, banner,..."
                                        value={linkPublisher.utm_medium || ''}
                                        onChange={(e) => setLinkPublisher({ ...linkPublisher, utm_medium: e.target.value })}
                                    />
                                </div>
                                <div className="ladiui pt-20">
                                    <label className="ladiui title-text-form-lever2" >Utm Campaign</label>
                                    <input id="utm_campaign" name="utm_campaign" className="ladiui form-control" placeholder="chương trình, sự kiện, ..."
                                        value={linkPublisher.utm_campaign || ''}
                                        onChange={(e) => setLinkPublisher({ ...linkPublisher, utm_campaign: e.target.value })}
                                    />
                                </div>
                                <div className="ladiui pt-20">
                                    <label className="ladiui title-text-form-lever2" >Utm Content</label>
                                    <input id="utm_content" name="utm_content" className="ladiui form-control" placeholder="nội dung chiến dịch"
                                        value={linkPublisher.utm_content || ''}
                                        onChange={(e) => setLinkPublisher({ ...linkPublisher, utm_content: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ladiui modal-footer custom-ldp-popupX mod">
                    <div className="ladiui modal-footer-link">
                    </div>
                    <div className="ladiui footer-modal-btn">
                        <button type="button" className="ladiui btn btn-secondary bold" onClick={() => eventCancel()}>{t('COMMON.CANCELED')}</button>
                        {!isEdit
                            ? <button type="button" className="ladiui btn btn-primary" onClick={eventCreate}>Tạo mới</button>
                            : <button type="button" className="ladiui btn btn-primary" onClick={eventUpdate}>Cập nhật link</button>}
                    </div>
                </div>
            </div>
        </div>
    </div >;
}

export default (withTranslation()(ModalPublisherLink));
