import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import actionStore from '../../redux/futures/store/actions';
import EmailSmtp from './components/EmailSmtp';
import EmailTemplate from './components/EmailTemplate';
import SenderEmail from './components/SenderEmail';

// eslint-disable-next-line max-lines-per-function
function EmailSetting(props) {
    const { t } = props;

    const dispatch = useDispatch();
    const setting = useSelector((state) => (state.store.affiliateSetting));
    const [emailSetting, setEmailSetting] = useState({});

    const cbLoadData = () => {
        const data = {
            field: 'email_setting',
        };
        dispatch(actionStore.getAffiliateSetting(data));
    };

    const updateEmailSetting = () => {
        const dataUpdate = {
            email_setting: emailSetting,
        };
        dispatch(actionStore.updateAffiliateSetting(dataUpdate));
    };

    const changeDataForm = (e) => {
        const name = e.target.name;
        const field = {};
        if (e.target.type === 'checkbox') {
            field[name] = e.target.checked;
        } else {
            field[name] = e.target.value;
        }
        setEmailSetting({ ...emailSetting, ...field });
    };

    const eventCheckOption = (e, fieldName) => {
        const name = e.target.name;
        const data = {};
        data[fieldName] = emailSetting[fieldName] || {};
        data[fieldName][name] = e.target.checked;
        setEmailSetting({ ...emailSetting, ...data });
    };

    useEffect(() => {
        cbLoadData();
    }, []);

    useEffect(() => {
        if (setting) setEmailSetting(setting);
    }, [setting]);

    return <>
        <div className="ladiui content-main-title flex-row">
            <div className="ladiui">
                <div className="ladiui main-title">
                    {t('STORES.EMAIL_SETTING')}
                </div>
            </div>
        </div>
        <div className="ladiui action-history">
            <ul className="ladiui nav nav-tabs new-tab-custom" id="tab-detail-affiliate" role="tablist">
                <li className="ladiui nav-item" role="presentation">
                    <span className="ladiui tab-link active" data-toggle="tab"
                        data-parent="myTab" data-target="tab-setting-notify-email">Cài đặt thông báo</span>
                </li>
                {/* <li className="ladiui nav-item" role="presentation">
                    <span className="ladiui tab-link" data-toggle="tab" data-parent="myTab"
                        data-target="tab-config-smtp">SMTP</span>
                </li> */}
                <li className="ladiui nav-item" role="presentation">
                    <span className="ladiui tab-link" data-toggle="tab" data-parent="myTab"
                        data-target="tab-email-sender">Email người gửi</span>
                </li>
                <li className="ladiui nav-item" role="presentation">
                    <span className="ladiui tab-link" data-toggle="tab" data-parent="myTab"
                        data-target="tab-email-template">Mẫu email</span>
                </li>
            </ul>
            <div className="ladiui tab-content" id="tab-detail-affiliate-content">
                <div className="ladiui tab-pane fade show open" id="tab-setting-notify-email" role="tabpanel"
                    aria-labelledby="tab-setting-notify-email">
                    <div className="ladiui">
                        <div className="ladiui " style={{ maxWidth: '750px' }}>
                            <div className="ladiui affiliate-setting-content">
                                <div className="ladiui form-group flex-row">
                                    <div className="label-setting">
                                        <label className="ladiui title-text-form-lever2">Thông báo thay đổi trạng thái hoa hồng</label>
                                        <div>Affiliate sẽ nhận được thông báo khi có thay đổi về trạng thái của hoa hồng.</div>
                                        {emailSetting.notify_aff_commission_status_change
                                            ? <ul className="list-option-check">
                                                <li>
                                                    <label>
                                                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                                            name="commission_status_approved"
                                                            checked={emailSetting && emailSetting.notify_aff_commission_status_change_option
                                                                ? emailSetting.notify_aff_commission_status_change_option.commission_status_approved || false
                                                                : false}
                                                            onChange={(e) => eventCheckOption(e, 'notify_aff_commission_status_change_option')}
                                                        />
                                                        Tạm duyệt hoa hồng
                                                    </label>
                                                </li>
                                                <li>
                                                    <label>
                                                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                                            name="commission_status_invoiced"
                                                            checked={emailSetting && emailSetting.notify_aff_commission_status_change_option
                                                                ? emailSetting.notify_aff_commission_status_change_option.commission_status_invoiced || false
                                                                : false}
                                                            onChange={(e) => eventCheckOption(e, 'notify_aff_commission_status_change_option')}
                                                        />
                                                        Duyệt hoa hồng
                                                    </label>
                                                </li>
                                                <li>
                                                    <label>
                                                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                                            name="commission_status_cancel"
                                                            checked={emailSetting && emailSetting.notify_aff_commission_status_change_option
                                                                ? emailSetting.notify_aff_commission_status_change_option.commission_status_cancel || false
                                                                : false}
                                                            onChange={(e) => eventCheckOption(e, 'notify_aff_commission_status_change_option')} />
                                                        Huỷ hoa hồng
                                                    </label>
                                                </li>
                                            </ul>
                                            : <></>}
                                    </div>
                                    <div className="ladiui item-form switch">
                                        <label className="ladiui switch">
                                            <input type="checkbox" id="notify_aff_commission_status_change" name="notify_aff_commission_status_change"
                                                checked={emailSetting.notify_aff_commission_status_change || false} onChange={changeDataForm} />
                                            <span className="ladiui slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="ladiui affiliate-setting-content">
                                <div className="ladiui form-group flex-row">
                                    <div className="label-setting">
                                        <label className="ladiui title-text-form-lever2">Thông báo thay đổi trạng thái hoá đơn</label>
                                        <div>Affiliate sẽ nhận được thông báo khi có thay đổi về trạng thái của hoá đơn.</div>
                                        {emailSetting.notify_aff_invoice_status_change
                                            ? <ul className="list-option-check">
                                                <li>
                                                    <label>
                                                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                                            name="invoice_status_approved"
                                                            checked={emailSetting && emailSetting.notify_aff_invoice_status_change_option
                                                                ? emailSetting.notify_aff_invoice_status_change_option.invoice_status_approved || false
                                                                : false}
                                                            onChange={(e) => eventCheckOption(e, 'notify_aff_invoice_status_change_option')}
                                                        />
                                                        Duyệt hoá đơn
                                                    </label>
                                                </li>
                                                <li>
                                                    <label>
                                                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                                            name="invoice_status_paid"
                                                            checked={emailSetting && emailSetting.notify_aff_invoice_status_change_option
                                                                ? emailSetting.notify_aff_invoice_status_change_option.invoice_status_paid || false
                                                                : false}
                                                            onChange={(e) => eventCheckOption(e, 'notify_aff_invoice_status_change_option')}
                                                        />
                                                        Đã thanh toán hoá đơn
                                                    </label>
                                                </li>
                                                <li>
                                                    <label>
                                                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                                            name="invoice_status_cancel"
                                                            checked={emailSetting && emailSetting.notify_aff_invoice_status_change_option
                                                                ? emailSetting.notify_aff_invoice_status_change_option.invoice_status_cancel || false
                                                                : false}
                                                            onChange={(e) => eventCheckOption(e, 'notify_aff_invoice_status_change_option')} />
                                                        Huỷ hoá đơn
                                                    </label>
                                                </li>
                                            </ul>
                                            : <></>}
                                    </div>
                                    <div className="ladiui item-form switch">
                                        <label className="ladiui switch">
                                            <input type="checkbox" id="notify_aff_invoice_status_change" name="notify_aff_invoice_status_change"
                                                checked={emailSetting.notify_aff_invoice_status_change || false} onChange={changeDataForm} />
                                            <span className="ladiui slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="ladiui affiliate-setting-content">
                                <div className="ladiui form-group flex-row">
                                    <div className="label-setting">
                                        <label className="ladiui title-text-form-lever2">Thông báo khi có đơn hàng mới được tạo</label>
                                        <div>Affiliate sẽ nhận được thông báo khi có đơn hàng mới được tạo.</div>
                                    </div>
                                    <div className="ladiui item-form switch">
                                        <label className="ladiui switch">
                                            <input type="checkbox" id="notify_aff_order_is_created" name="notify_aff_order_is_created"
                                                checked={emailSetting.notify_aff_order_is_created || false} onChange={changeDataForm} />
                                            <span className="ladiui slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="ladiui affiliate-setting-content">
                                <div className="ladiui form-group flex-row">
                                    <div className="label-setting">
                                        <label className="ladiui title-text-form-lever2">Không gửi email đối soát hoa hồng</label>
                                        <div>Affiliate sẽ không nhận được email đối soát hoa hồng khi store lên hoá đơn.</div>
                                    </div>
                                    <div className="ladiui item-form switch">
                                        <label className="ladiui switch">
                                            <input type="checkbox" id="disable_notify_confirm_invoice" name="disable_notify_confirm_invoice"
                                                checked={emailSetting.disable_notify_confirm_invoice || false} onChange={changeDataForm} />
                                            <span className="ladiui slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="ladiui affiliate-setting-content">
                                <div className="ladiui form-group">
                                    <button className="ladiui btn btn-primary right" onClick={updateEmailSetting}>Cập nhật</button>
                                    <button className="ladiui btn right btn-secondary" style={{ marginRight: '40px' }} onClick={cbLoadData}>Hủy bỏ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="ladiui tab-pane fade" id="tab-config-smtp" role="tabpanel"
                    aria-labelledby="tab-config-email">
                    <EmailSmtp />
                </div> */}
                <div className="ladiui tab-pane fade" id="tab-email-sender" role="tabpanel"
                    aria-labelledby="tab-config-email">
                    <SenderEmail />
                </div>
                <div className="ladiui tab-pane fade" id="tab-email-template" role="tabpanel"
                    aria-labelledby="tab-email-template">
                    <EmailTemplate />
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(EmailSetting));
