import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data, cb) => ({
    type: types.LIST_COMMISSION,
    meta: {
        endpoint: endpoint.COMMISSION_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const create = (data, cb) => ({
    type: types.CREATE_COMMISSION,
    meta: {
        endpoint: endpoint.COMMISSION_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const show = (id, cb) => ({
    type: types.SHOW_COMMISSION,
    meta: {
        endpoint: endpoint.COMMISSION_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const update = (data, cb) => ({
    type: types.UPDATE_COMMISSION,
    meta: {
        endpoint: endpoint.COMMISSION_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const updateStatusCommission = (data, cb) => ({
    type: types.UPDATE_STATUS_COMMISSION,
    meta: {
        endpoint: endpoint.UPDATE_STATUS_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const backToPending = (data, cb) => ({
    type: types.BACK_TO_PENDING_STATUS_COMMISSION,
    meta: {
        endpoint: endpoint.BACK_TO_PENDING_STATUS_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteCommission = (data, cb) => ({
    type: types.DELETE_COMMISSION,
    meta: {
        endpoint: endpoint.COMMISSION_DELETE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const exportCommission = (data, cb) => ({
    type: types.EXPORT_COMMISSION,
    meta: {
        endpoint: endpoint.COMMISSION_EXPORT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const exportConfirmCommission = (data, cb) => ({
    type: types.EXPORT_CONFIRM_COMMISSION,
    meta: {
        endpoint: endpoint.EXPORT_CONFIRM_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteMultiCommission = (data, cb) => ({
    type: types.DELETE_MULTI_COMMISSION,
    meta: {
        endpoint: endpoint.COMMISSION_DELETE_MULTI,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const acceptMultiCommission = (data, cb) => ({
    type: types.ACCEPT_MULTI_COMMISSION,
    meta: {
        endpoint: endpoint.APPROVE_MULTI_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const rejectMultiCommission = (data, cb) => ({
    type: types.REJECT_MULTI_COMMISSION,
    meta: {
        endpoint: endpoint.CANCEL_MULTI_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const invoiceMultiCommission = (data, cb) => ({
    type: types.INVOICE_MULTI_COMMISSION,
    meta: {
        endpoint: endpoint.INVOICE_MULTI_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const groupCommissionInvoiced = (data, cb) => ({
    type: types.GROUP_INVOICED_MULTI_COMMISSION,
    meta: {
        endpoint: endpoint.GROUP_INVOICED_MULTI_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

const confirmMultiCommission = (data, cb) => ({
    type: types.CONFIRM_MULTI_COMMISSION,
    meta: {
        endpoint: endpoint.CONFIRM_MULTI_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const approveCommission = (data, cb) => ({
    type: types.APPROVE_COMMISSION,
    meta: {
        endpoint: endpoint.APPROVE_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const invoiceCommission = (data, cb) => ({
    type: types.INVOICE_COMMISSION,
    meta: {
        endpoint: endpoint.INVOICE_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const cancelCommission = (data, cb) => ({
    type: types.CANCEL_COMMISSION,
    meta: {
        endpoint: endpoint.CANCEL_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const confirmCommission = (data, cb) => ({
    type: types.CONFIRM_COMMISSION,
    meta: {
        endpoint: endpoint.CONFIRM_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const recheckCommission = (data, cb) => ({
    type: types.RECHECK_COMMISSION,
    meta: {
        endpoint: endpoint.RECHECK_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const getTopCommission = (data, cb) => ({
    type: types.TOP_COMMISSION,
    meta: {
        endpoint: endpoint.TOP_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const getTopTotalCommission = (data, cb) => ({
    type: types.TOP_TOTAL_COMMISSION,
    meta: {
        endpoint: endpoint.TOP_TOTAL_COMMISSION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

export default {
    list,
    create,
    show,
    update,
    delete: deleteCommission,
    exportCommission,
    deleteMultiCommission,
    acceptMultiCommission,
    updateStatusCommission,
    rejectMultiCommission,
    invoiceMultiCommission,
    approveCommission,
    invoiceCommission,
    cancelCommission,
    getTopCommission,
    getTopTotalCommission,
    confirmMultiCommission,
    confirmCommission,
    exportConfirmCommission,
    groupCommissionInvoiced,
    recheckCommission,
    backToPending,
};
