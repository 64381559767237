import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiDropdown from '../../../components/LadiDropdown';
import DefineConst from '../DefineConst';
import actionCampaign from '../../../redux/futures/campaign/actions';
import config from '../../../config/config';
import BaseHelper from '../../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function
function ModalCampaignSociety(props) {
    const {
        t, isEdit, campaignProp, cb,
    } = props;
    const dispatch = useDispatch();
    const [campaign, setCampaign] = useState({});
    const listAffiliateGroup = useSelector((state) => ({ ...{ items: [], total: 0, pluckItems: {} }, ...state.affiliateGroups.groups }));
    const imageUpload = useSelector((state) => (state.campaigns.imageUpload));
    const imageProfileUpload = useSelector((state) => (state.campaigns.imageProfileUpload));

    const cbEvent = () => {
        window.LadiUI.closeModal('modal-campaign-society');
        setCampaign({});
        cb();
    };

    const eventSelectPlatformSociety = (type) => {
        setCampaign({ ...campaign, type });
    };

    const eventCheckGroup = (e) => {
        const restrictGroups = campaign.restrict_affiliate_group_id || [];
        const restrictGroup = e.target.value;
        if (e.target.checked) {
            setCampaign({ ...campaign, restrict_affiliate_group_id: [...restrictGroups, ...[restrictGroup]] });
        } else {
            const filterRestrictGroups = restrictGroups.filter((item) => item !== restrictGroup);
            setCampaign({ ...campaign, restrict_affiliate_group_id: filterRestrictGroups });
        }
    };

    const eventCancelCampaign = () => {
        if (!isEdit) setCampaign({});
        window.LadiUI.closeModal('modal-campaign-society');
    };

    const eventBackCampaign = () => {
        if (!isEdit) setCampaign({});
        window.LadiUI.closeModal('modal-campaign-society');
        window.LadiUI.showModal('modal-select-platform');
    };

    const eventCreateCampaign = () => {
        // validate
        if (!campaign.type) {
            window.LadiUI.toast('danger', '', 'Vui lòng chọn nền tảng chiến dịch', 5000, 'bottom-left');
            return;
        }
        if (!campaign.title) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập tiêu đề chiến dịch', 5000, 'bottom-left');
            return;
        }
        if (!campaign.url) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập url thay thế cho chiến dịch', 5000, 'bottom-left');
            return;
        }

        if (!BaseHelper.isValidUrl(campaign.url)) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập đúng định dạng url', 5000, 'bottom-left');
            return;
        }

        if (!campaign.image_profile || !campaign.image_profile.path) {
            window.LadiUI.toast('danger', '', 'Vui lòng tải ảnh đại diện cho chiến dịch', 5000, 'bottom-left');
            return;
        }

        dispatch(actionCampaign.create(campaign, cbEvent));
    };

    const eventEditCampaign = () => {
        // validate
        if (!campaign.type) {
            window.LadiUI.toast('danger', '', 'Vui lòng chọn nền tảng chiến dịch', 5000, 'bottom-left');
            return;
        }
        if (!campaign.title) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập tiêu đề chiến dịch', 5000, 'bottom-left');
            return;
        }
        if (!campaign.url) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập url thay thế cho chiến dịch', 5000, 'bottom-left');
            return;
        }

        if (!BaseHelper.isValidUrl(campaign.url)) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập đúng định dạng url', 5000, 'bottom-left');
            return;
        }

        if (!campaign.image_profile || !campaign.image_profile.path) {
            window.LadiUI.toast('danger', '', 'Vui lòng tải ảnh đại diện cho chiến dịch', 5000, 'bottom-left');
            return;
        }

        const data = { ...campaign, id: campaign._id };
        dispatch(actionCampaign.update(data, true, cbEvent));
    };

    const cbResetFile = () => {
        document.getElementById('input-file-campaign-society').value = '';
    };

    const cbResetProfileFile = () => {
        document.getElementById('input-profile-file-campaign-society').value = '';
    };

    const eventUploadImage = (event) => {
        const files = event.target.files;
        const form = new FormData();
        form.append('json_data', JSON.stringify({
            ladi_app: 'LADISHARE',
            file_type: 'IMAGE',
        }));
        const images = campaign.image ? campaign.image : [];
        for (let i = 0; i < files.length; i++) {
            const uploadFile = files[i];

            const reader = new FileReader();
            reader.readAsDataURL(uploadFile);
            reader.onload = (e) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = () => {
                    const height = image.height;
                    const width = image.width;
                    images.push({ width, height, name: uploadFile.name });
                    setCampaign({ ...campaign, image: images });
                    return true;
                };
            };
            form.append('files[]', uploadFile, uploadFile.name);
        }
        dispatch(actionCampaign.uploadImage(form, cbResetFile));
    };

    const eventUploadImageProfile = (event) => {
        const files = event.target.files;
        const uploadFile = files[0];

        const reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => {
                const height = image.height;
                const width = image.width;

                const imageProfile = { width, height, name: uploadFile.name };
                setCampaign({ ...campaign, image_profile: imageProfile });
                return true;
            };
        };

        const form = new FormData();
        form.append('json_data', JSON.stringify({
            ladi_app: 'LADISHARE',
            file_type: 'IMAGE',
        }));
        form.append('files', uploadFile, uploadFile.name);
        dispatch(actionCampaign.uploadImageProfile(form, cbResetProfileFile));
    };

    const eventRemoveImgBanner = (img) => {
        const listImage = campaign.image.filter((item) => item.path !== img.path);
        setCampaign({ ...campaign, image: listImage });
    };

    const eventRemoveImgProfile = () => {
        setCampaign({ ...campaign, image_profile: null });
    };

    useEffect(() => {
        if (campaignProp) setCampaign(campaignProp);
    }, [campaignProp]);

    useEffect(() => {
        if (imageUpload) {
            const image = campaign.image ? campaign.image : [];
            for (let i = 0; i < image.length; i++) {
                for (let j = 0; j < imageUpload.length; j++) {
                    if (image[i].name === imageUpload[j].name) {
                        image[i] = { ...image[i], ...imageUpload[j] };
                    }
                }
            }
            setCampaign({ ...campaign, image });
        }
    }, [imageUpload]);

    useEffect(() => {
        if (imageProfileUpload) {
            setCampaign({ ...campaign, image_profile: { ...campaign.image_profile, ...imageProfileUpload[0] } });
        }
    }, [imageProfileUpload]);
    return <>
        <div className="ladiui modal bd-example-modal-lg" id="modal-campaign-society">
            <div className="ladiui modal-dialog modal-lg">
                <div className="ladiui modal-content">
                    <div className="ladiui modal-header">
                        <button className="ladiui btn btn-sm btn-link modal-back"
                            onClick={eventBackCampaign}
                        >
                            <img src="https://w.ladicdn.com/design-system/icons/icon-back.svg" />
                        </button>
                        <h2 className="ladiui title-text-form-lever1">{isEdit ? 'Chỉnh sửa chiến dịch' : 'Tạo chiến dịch'}</h2>
                        <button data-dismiss="modal" data-target="modal-campaign-society"
                            className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui modal-body">
                        <div className="ladiui form-group">
                            <LadiDropdown
                                labelDropdown='Nền tảng'
                                listProp={DefineConst.PLATFORM_SOCIETY}
                                valueProp={campaign.type}
                                hideLabel={false}
                                validProp={true}
                                classSize='w-100-percent'
                                classDropdown='campaign-society-platform'
                                defaultTextProp='Chọn nền tảng'
                                cbProp={eventSelectPlatformSociety}
                            />
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-affiliate">Tiêu đề</label>
                            <input className="ladiui form-control" placeholder="Nhập tiêu đề" id="input-affiliate"
                                value={campaign.title || ''}
                                onChange={(e) => (setCampaign({ ...campaign, title: e.target.value }))}
                            />
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-url">URL thay thế</label>
                            <input className="ladiui form-control" placeholder="https://" id="input-url"
                                value={campaign.url || ''}
                                onChange={(e) => (setCampaign({ ...campaign, url: e.target.value }))}
                            />
                        </div>

                        <div className="ladiui form-group upload-img-banner">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-profile-file-campaign-society">Ảnh đại diện</label>
                            <div className="list-img-banner-preview">
                                {campaign.image_profile
                                    ? <div className="img-banner-preview">
                                        {campaign.image_profile.path ? <>
                                            <img src={`${config.DOMAIN_CDN}/${campaign.image_profile.path}`} />
                                            <div className="remove-img-banner-preview" onClick={() => eventRemoveImgProfile()}>
                                                <img src="https://w.ladicdn.com/ladiui/ladishare/close0808.svg" />
                                            </div>
                                        </> : <></>}
                                    </div>
                                    : <></>}
                                <div>
                                    <button className="ladiui btn btn-outline-primary" onClick={() => { document.getElementById('input-profile-file-campaign-society').click(); }}>
                                        <img src="https://w.ladicdn.com/ladiui/ladishare/upload0808.svg" className="mr-8" />Tải ảnh lên
                                    </button>
                                </div>
                            </div>
                            <input type="file"
                                id="input-profile-file-campaign-society"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => eventUploadImageProfile(e)}
                            />
                        </div>

                        <div className="ladiui form-group upload-img-banner">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-file-campaign-society">Ảnh</label>
                            <div className="list-img-banner-preview">
                                {campaign.image && campaign.image.length > 0
                                    ? campaign.image.map((item, index) => <div className="img-banner-preview" key={index}>
                                        {item.path ? <>
                                            <img src={`${config.DOMAIN_CDN}/${item.path}`} />
                                            <div className="remove-img-banner-preview" onClick={() => eventRemoveImgBanner(item)}>
                                                <img src="https://w.ladicdn.com/ladiui/ladishare/close0808.svg" />
                                            </div>
                                        </> : <></>}
                                    </div>)
                                    : <></>}
                                <div>
                                    <button className="ladiui btn btn-outline-primary" onClick={() => { document.getElementById('input-file-campaign-society').click(); }}>
                                        <img src="https://w.ladicdn.com/ladiui/ladishare/upload0808.svg" className="mr-8" />Tải ảnh lên
                                    </button>
                                </div>
                            </div>
                            <input type="file"
                                id="input-file-campaign-society"
                                accept="image/png, image/gif, image/jpeg"
                                multiple
                                onChange={(e) => eventUploadImage(e)}
                            />
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-body">Nội dung bài viết</label>
                            <textarea className="ladiui form-control" placeholder="Nhập nội dung" id="input-body" rows={4}
                                value={campaign.contents || ''}
                                onChange={(e) => (setCampaign({ ...campaign, contents: e.target.value }))}
                            />
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-body">Hạn chế hiển thị với:</label>
                            <div className="ladiui flex-row">
                                {listAffiliateGroup ? listAffiliateGroup.items.map((item) => <div key={item._id} className="mr-8">
                                    <input type="checkbox" className="ladiui form-check-input-checkbox mr-8"
                                        id={`input-group-${item._id}`}
                                        name="checkbox"
                                        checked={campaign.restrict_affiliate_group_id ? campaign.restrict_affiliate_group_id.indexOf(item._id) !== -1 : false}
                                        value={item._id || ''}
                                        onChange={(e) => eventCheckGroup(e)}
                                    />
                                    <label className="" htmlFor={`input-group-${item._id}`}>{item.name}</label>
                                </div>) : null}

                            </div>
                        </div>
                    </div>
                    <div className="ladiui modal-footer">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <button className="ladiui btn btn-secondary" onClick={eventCancelCampaign}>Huỷ bỏ</button>
                            {isEdit
                                ? <button className="ladiui btn btn-primary" onClick={eventEditCampaign}>Cập nhật chiến dịch</button>
                                : <button className="ladiui btn btn-primary" onClick={eventCreateCampaign}>Tạo chiến dịch</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(ModalCampaignSociety));
