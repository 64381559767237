import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';
import BaseHelper from '../../../helpers/BaseHelper';

function ConditionChangeGroupBoxForCustomer(props) {
    const { t, conditionChangeGroupProp, cb } = props;
    const [paymentCount, setPaymentCount] = useState({ enable: false });

    const renderExplain = () => {
        let explain = <></>;
        if (paymentCount.min && paymentCount.max) {
            explain = <>(*) Affiliate sẽ được nâng hoặc hạ cấp nếu số lượng khách hàng của affiliate đó nằm trong khoảng từ {BaseHelper.formatNumber(paymentCount.min)} đến {BaseHelper.formatNumber(paymentCount.max)}</>;
        }
        if (paymentCount.min && !paymentCount.max) {
            explain = <>(*) Affiliate sẽ được nâng hoặc hạ cấp nếu số lượng khách hàng của affiliate đó lớn hơn hoặc bằng {BaseHelper.formatNumber(paymentCount.min)}</>;
        }
        if (!paymentCount.min && paymentCount.max) {
            explain = <>(*) Affiliate sẽ được nâng hoặc hạ cấp nếu số lượng khách hàng của affiliate đó nhỏ hơn hoặc bằng {BaseHelper.formatNumber(paymentCount.max)}</>;
        }
        return <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>{explain}</div>;
    };

    const eventChangeInput = (e) => {
        const name = e.target.getAttribute('field');
        let value;
        if (
            e.target.tagName.toLowerCase() === 'input'
            && e.target.getAttribute('type') === 'checkbox'
        ) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        const data = { ...paymentCount };
        data[name] = value;
        setPaymentCount(data);
        cb(data);
    };

    const changeInputValue = (fieldName, value) => {
        const data = { ...paymentCount };
        data[fieldName] = value;
        setPaymentCount(data);
        cb(data);
    };

    useEffect(() => {
        if (conditionChangeGroupProp) setPaymentCount({ ...paymentCount, ...conditionChangeGroupProp.condition_per_customer });
    }, [conditionChangeGroupProp]);

    return <div>
        <div className="ladiui clearfix mt-8">
            <label className="ladiui title-text-form-lever2 left">Theo số khách hàng</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox"
                        field="enable"
                        checked={paymentCount.enable || false}
                        onChange={(e) => eventChangeInput(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Thỏa mãn nếu số khách hàng đạt được nằm trong khoảng bên dưới</div>
        </div>
        {paymentCount && paymentCount.enable
            ? <>
                <div className="ladiui clearfix pt-20 d-grid-2">
                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever3">Số khách tối thiểu</label>
                        <div className="group-input-custom custom-form-dynamic">
                            <NumericFormat
                                value={paymentCount.min || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control"
                                placeholder="Số khách tối thiểu"
                                onValueChange={(values) => changeInputValue('min', values.value)}
                            />
                        </div>

                    </div>
                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever3">Số khách tối đa</label>
                        <div className="group-input-custom custom-form-dynamic">
                            <NumericFormat
                                value={paymentCount.max || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control"
                                placeholder="Số khách tối đa"
                                onValueChange={(values) => changeInputValue('max', values.value)}
                            />
                        </div>
                    </div>
                </div>
                {renderExplain()}
            </>
            : <></>}
    </div>;
}

export default (withTranslation()(ConditionChangeGroupBoxForCustomer));
