import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../../../assets/css/custom.module.css';
import DefineConst from '../../affiliates/DefineConst';

function WarningAccountActive() {
    const user = useSelector((state) => state.store.userInfo);

    return (user && user.user && user.user.status === DefineConst.AFFILIATE_STATUS.PENDING)
        ? <div className={styles['warrning-account-active']}>
            <img src="https://w.ladicdn.com/ladiui/ladishare/warning0808.svg" width="20"/>&nbsp;
            Tài khoản của bạn đang trong quá trình kiểm duyệt&nbsp;
        </div>
        : <></>;
}

export default WarningAccountActive;
