export default {
    CONFIRM_COMMISSION: [
        {
            name: 'Tên store',
            params: '{{company_name}}',
        },
        {
            name: 'Tên affiliate',
            params: '{{affiliate_name}}',
        },
        {
            name: 'Năm',
            params: '{{year}}',
        },
        {
            name: 'Tháng',
            params: '{{month}}',
        },
    ],
    CONFIRM_COMMISSION_COMPANY: [
        {
            name: 'Tên store',
            params: '{{company_name}}',
        },
        {
            name: 'Tên affiliate',
            params: '{{affiliate_name}}',
        },
        {
            name: 'Năm',
            params: '{{year}}',
        },
        {
            name: 'Tháng',
            params: '{{month}}',
        },
    ],
    REGISTERED_EMAIL: [
        {
            name: 'Tên store',
            params: '{{company_name}}',
        },
        {
            name: 'Link chính sách affiliate',
            params: '{{aff_policy_link}}',
        },
        {
            name: 'Link màn hình tổng quan affiliate',
            params: '{{aff_store_link}}',
        },
        {
            name: 'Họ tên',
            params: '{{affiliate_name}}',
        },
        {
            name: 'Email affiliate',
            params: '{{affiliate_email}}',
        },
        {
            name: 'Email ref của affiliate',
            params: '{{affiliate_ref_email}}',
        },
        {
            name: 'Mã ref affiliate',
            params: '{{affiliate_code}}',
        },
        {
            name: 'Mật khẩu affiliate',
            params: '{{affiliate_pass}}',
        },
    ],
    APPROVED_EMAIL: [
        {
            name: 'Tên store',
            params: '{{company_name}}',
        },
        {
            name: 'Link chính sách affiliate',
            params: '{{aff_policy_link}}',
        },
        {
            name: 'Link màn hình tổng quan affiliate',
            params: '{{aff_store_link}}',
        },
        {
            name: 'Họ tên',
            params: '{{affiliate_name}}',
        },
        {
            name: 'Email affiliate',
            params: '{{affiliate_email}}',
        },
        {
            name: 'Email ref của affiliate',
            params: '{{affiliate_ref_email}}',
        },
        {
            name: 'Mã ref affiliate',
            params: '{{affiliate_code}}',
        },
        {
            name: 'Mật khẩu affiliate',
            params: '{{affiliate_pass}}',
        },
    ],
};
