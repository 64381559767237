import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NumericFormat from 'react-number-format';
import styles from '../../../assets/css/custom.module.css';
import LadiDropdown from '../../../components/LadiDropdown';
import BaseHelper from '../../../helpers/BaseHelper';
import DefineConst from '../DefineConst';

function AppDiscount(props) {
    const {
        t, appProp, appIntegrateCouponProp, changeAppProp, removeAppProp,
    } = props;

    const defaultApp = {
        _id: BaseHelper.generateRandomString(16),
        app_type: null,
        customer_discount_number: 0,
        customer_discount_type: DefineConst.COUPON_PAYMENT_TYPE.PERCENT,
    };

    const [app, setApp] = useState(defaultApp);

    const listAppIntegrate = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.appIntegrate.integrateApps }));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const eventSelectPaymentTypeCustomer = (value) => {
        setApp({ ...app, customer_discount_type: value });
        changeAppProp({ ...app, customer_discount_type: value });
    };

    const eventSelectApp = (value) => {
        const integrate = listAppIntegrate.items.find((item) => item._id === value);
        const dataSet = {
            ...app,
            integration_id: integrate._id,
            app_type: integrate.type,
            integration: integrate,
        };
        if (integrate.enable_discount_for_customer) {
            dataSet.customer_discount_number = integrate.discount_for_customer;
            dataSet.customer_discount_type = integrate.discount_for_customer_type;
        }
        setApp(dataSet);
        changeAppProp(dataSet);
    };

    const changeInputValue = (fieldName, value) => {
        const data = { ...app };
        data[fieldName] = value;
        setApp(data);
        changeAppProp(data);
    };

    const eventRemoveApp = () => {
        removeAppProp(app);
    };

    const getListAppExist = () => {
        const items = [];
        for (let i = 0; i < listAppIntegrate.items.length; i++) {
            const item = listAppIntegrate.items[i];
            const itemSelect = appIntegrateCouponProp.find((ob) => ob.integration_id === item._id);
            if (!itemSelect) items.push(item);
        }
        const newItems = [];
        for (let i = 0; i < items.length; i++) {
            newItems.push({ name: items[i].name, value: items[i]._id });
        }
        return newItems;
    };

    useEffect(() => {
        setApp(appProp);
    }, [appProp]);

    return <>
        <div className="box-app-discount">
            <div className="ladiui">
                <div className="ladiui d-grid-2">
                    <div>
                        {app.integration_id
                            ? <><input type="text" className="ladiui form-control" value={app.integration ? app.integration.name : ''} onChange={() => { }} readOnly /></>
                            : <LadiDropdown
                                defaultTextProp="Cửa hàng"
                                listProp={getListAppExist()}
                                valueProp={app.integration_id}
                                hideLabel={true}
                                validProp={true}
                                classDropdown="dropdown-max-height"
                                cbProp={eventSelectApp}
                            />
                        }
                    </div>
                    <div className="ladiui d-grid-2">
                        <LadiDropdown
                            classDropdown="dropdown-custom-2"
                            listProp={DefineConst.COUPON_PAYMENT_TYPE_ARRAY}
                            valueProp={app.customer_discount_type || DefineConst.COUPON_PAYMENT_TYPE.PERCENT}
                            hideLabel={true}
                            validProp={true}
                            cbProp={eventSelectPaymentTypeCustomer}
                        />
                        <div className="">
                            <NumericFormat
                                value={app.customer_discount_number || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control"
                                placeholder="Nhập giá trị"
                                onValueChange={(values) => changeInputValue('customer_discount_number', values.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles['comment-coupon-special']} ${styles['group-flex-coupon']}`}>
                {app.customer_discount_type === DefineConst.COUPON_PAYMENT_TYPE.FIXED
                    ? <div>* Đơn hàng sẽ được giảm {BaseHelper.formatMoney(app.customer_discount_number || 0, '', systemCurrency)}</div>
                    : <div>* Đơn hàng sẽ được giảm {BaseHelper.formatNumber(app.customer_discount_number || 0)}%</div>}

                {app.integration && app.integration.auto_sync_coupon_code
                    ? <></>
                    : <button type="button" className="ladiui font-size-12 btn btn-secondary right" onClick={eventRemoveApp}>
                        <i className="ladiui icon icon-ldp-delete-black-sz"></i> {t('COMMON.DELETE')}
                    </button>}
            </div>
        </div>
    </>;
}

export default (withTranslation()(AppDiscount));
