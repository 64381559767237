export default {
    EXPORT_ACTIONS: {
        FIRST_PAGE: 'FIRST_PAGE',
        SELECTED_RECORDS: 'SELECTED_RECORDS',
        ALL_RECORDS: 'ALL_RECORDS',
    },
    INVOICE_STATUS: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        CANCELED: 'CANCELED',
        PAID: 'PAID',
    },
    INVOICE_NAME_STATUS: {
        PENDING: 'Chờ phê duyệt',
        APPROVED: 'Đã phê duyệt',
        CANCELED: 'Hủy',
        PAID: 'Đã thanh toán',
    },
};
