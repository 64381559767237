import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import config from '../../config/config';
import actionPublisher from '../../redux/futures/publisher/actions';
import PublisherFooter from './PublisherFooter';
import actionStore from '../../redux/futures/store/actions';
import PublisherLeft from './components/PublisherLeft';
import PublisherLogo from './components/PublisherLogo';

function ForgotPassword(props) {
    const { t } = props;
    const store = useParams();
    const dispatch = useDispatch();
    const [email, setEmail] = useState({ ...store, url: window.location.origin });

    const eventSubmitForgotPassword = () => {
        dispatch(actionPublisher.forgotPassword(email));
    };

    useEffect(() => {
        const data = {
            ...store,
            url: window.location.origin,
            field: 'affiliate_dashboard_setting',
        };
        dispatch(actionStore.getAffiliateDashboardSettingPublic(data));
    }, []);

    return <>
        <div className="publisher-container">

            <div className="box-publisher">
                <PublisherLogo />
                <PublisherLeft />
                <div className="box-publisher-right">
                    <div className="box-title">Quên mật khẩu</div>
                    <div className="box-sub-title">Nhập email để nhận thông tin truy cập vào tài khoản.</div>
                    <form action="#">
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2">Địa chỉ email</label>
                            <input type="email" className="ladiui form-control" placeholder="Nhập địa chỉ email"
                                autoFocus
                                value={email.email || ''}
                                onChange={(e) => setEmail({ ...email, email: e.target.value })} />
                        </div>
                        <div className="ladiui form-group">
                            <button type="button" className="ladiui btn btn-primary" onClick={eventSubmitForgotPassword}>Gửi</button>
                        </div>
                        <div className="ladiui form-group">
                            Bạn đã có tài khoản? <Link to={config.APP_AFFILIATE && origin !== config.AFFILIATE_DOMAIN ? '/login' : `/${store.store}/login`} className="ladiui link">Đăng nhập</Link>
                        </div>
                    </form>

                </div>

                <PublisherFooter />
            </div>

        </div>
    </>;
}
export default (withTranslation()(ForgotPassword));
