import axios from 'axios';
import {
    has, isEmpty, isFunction, omit,
} from 'lodash';
import { saveAs } from 'file-saver';
import * as commonTypes from '../futures/common/types';
import {
    SIGNOUT, GET_USER_INFO,
} from '../futures/auth/types';

import appConfig, { status } from '../../config/app';
import config from '../../config/config';

import { appLocalStorage } from '../../localforage';
import baseHelper from '../../helpers/BaseHelper';

export const REQUEST_METHOD = {
    GET: 'get',
    POST: 'post',
};

function requestHeaders(hasAuth = true, form, hasStoreID = true, checkPublisher = true) {
    const headers = (form)
        ? {
            'Content-Type': 'multipart/form-data',
        }
        : {
            'Content-Type': 'application/json',
        };

    const origin = window.location.origin;

    if (hasStoreID) {
        if (origin === config.AFFILIATE_DOMAIN) {
            headers.Publisher = 1;
        }
        if (config.APP_AFFILIATE) {
            headers.Publisher = 1;
        }
        let storeID = baseHelper.getCookie(appConfig.COOKIE.STORE_ID);
        if (storeID && storeID !== '') {
            headers['store-id'] = storeID;
        } else {
            const metadata = JSON.parse(localStorage.getItem(appConfig.MY_INFO));
            if (metadata && metadata.stores && metadata.stores.length > 0) {
                storeID = metadata.stores[0]._id;
                baseHelper.setCookie(appConfig.COOKIE.STORE_ID, storeID);
                headers['store-id'] = storeID;
            } else if (metadata && metadata.store) {
                storeID = metadata.store._id;
                baseHelper.setCookie(appConfig.COOKIE.STORE_ID, storeID);
                headers['store-id'] = storeID;
            }
        }
    }

    if (!hasAuth) {
        return headers;
    }

    const token = baseHelper.getCookie(appConfig.COOKIE.SSID);
    headers.authorization = token;

    if (!checkPublisher) {
        delete headers.Publisher;
    }
    return headers;
}

async function cacheData(action) {
    if (action.type === GET_USER_INFO && !action.no_store) {
        appLocalStorage.setItem(appConfig.LOCAL_FORAGE.USER_INFO, omit(action.payload, ['stores', 'scope']));
        const moment = baseHelper.getMoment();
        moment.tz.setDefault(action.payload.timezone);
        moment.locale(action.payload.language);
    }
}

const serviceMiddleware = () => (next) => (action) => {
    next({ type: commonTypes.ASYNC_START, waitting: action.type });
    if (action.meta) {
        const {
            endpoint, body, method, hasAuth, form, hasStoreID, showNotify, checkPublisher, cb,
        } = action.meta;
        if (form) {
            let data = {};
            for (const [key, prop] of form) {
                data[key] = JSON.parse(JSON.stringify(prop));
            }

            data = JSON.parse(JSON.stringify(data, null, 2));
        }

        const req = {
            method,
            url: endpoint,
            headers: requestHeaders(hasAuth, form, hasStoreID, checkPublisher),
            data: body || form,
            // withCredentials: true
        };

        axios(req).then((response) => {
            const { data } = response;
            if (has(response.headers, 'store-id')) {
                action.new_store_id = response.headers['store-id'];
            }

            // console.log(data);
            // debugger;

            switch (data.code) {
                case status.OK:
                case status.CREATED:
                    action.status = true;
                    action.payload = data.data;
                    action.message = data.message;
                    // Handle cache data
                    cacheData(action);
                    break;
                case status.UNAUTHORIZED:
                    baseHelper.removeCookie(appConfig.COOKIE.SSID);
                    baseHelper.removeCookie(appConfig.COOKIE.STORE_ID);
                    localStorage.clear();
                    next({ type: SIGNOUT });
                    return;
                case status.FORBIDDEN:
                    action.status = false;
                    action.message = data.message;
                    break;
                default:
                    action.status = false;
                    action.message = data.message;
                    break;
            }

            if (!isEmpty(data.data) && action.type.indexOf('EXPORT_') >= 0 && action.status) {
                const fileBuffer = data.data.buffer;
                const blob = new Blob([new Uint8Array(fileBuffer.data)]);
                saveAs(blob, data.data.name);
            }

            if (data.code === status.FORBIDDEN) {
                window.LadiUI.toast('danger', '', action.message, 5000, 'bottom-left');
            } else {
                if (showNotify) {
                    if (action.status) {
                        window.LadiUI.toast('success', '', action.message, 5000, 'bottom-left');
                    } else {
                        window.LadiUI.toast('danger', '', action.message, 5000, 'bottom-left');
                    }
                }
                if (isFunction(cb)) {
                    cb.response = response;
                    cb();
                }
            }

            next(action);
            next({ type: commonTypes.ASYNC_END, done: action.type });
        }).catch((error) => {
            action.status = false;
            let message = error.message;
            if (error.response && error.response.data) message = error.response.data.message;
            action.message = message;
            window.LadiUI.showErrorMessage('Thông báo', message, 'OK');
            if (error.response && error.response.data && error.response.data.code === 401) {
                baseHelper.removeCookie(appConfig.COOKIE.SSID);
                baseHelper.removeCookie(appConfig.COOKIE.STORE_ID);
                localStorage.clear();
                next({ type: SIGNOUT });
            }

            next({ type: commonTypes.ASYNC_END, done: action.type });
        });

        return;
    }

    next(action);
    next({ type: commonTypes.ASYNC_END, done: action.type });
};

const localStorageMiddleware = () => (next) => (action) => {
    switch (action.type) {
        case GET_USER_INFO:
            if (action.status) {
                baseHelper.setCookie(appConfig.SESSION_KEY, JSON.stringify(action.payload.sessionKey), appConfig.COOKIE.EXPIRE);
                delete action.payload.sessionKey;
                localStorage.setItem(appConfig.MY_INFO, JSON.stringify(action.payload));
                let storeID = baseHelper.getCookie(appConfig.COOKIE.STORE_ID);
                // if (action.payload.stores && action.payload.stores.length > 0) {
                //     if (!storeID || storeID === '') {
                //         for (let i = 0; i < action.payload.stores.length; i++) {
                //             const store = action.payload.stores[i];
                //             if (store.is_owner) {
                //                 storeID = store._id;
                //             }
                //         }
                //         if (!storeID || storeID === '') {
                //             storeID = action.payload.stores[0]._id;
                //         }
                //         baseHelper.setCookie(appConfig.COOKIE.STORE_ID, storeID);
                //         console.log(555555555);
                //     }
                // }
                if (action.payload.store) {
                    if (!storeID || storeID === '') {
                        const store = action.payload.store;
                        storeID = store._id;
                        baseHelper.setCookie(appConfig.COOKIE.STORE_ID, storeID);
                    }
                }
            } else {
                localStorage.clear();
            }
            break;

        case SIGNOUT:
            localStorage.clear();
            break;
        default:
            break;
    }

    next(action);
};

export { serviceMiddleware, localStorageMiddleware };
