import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import config from '../../../config/config';
import actionCampaign from '../../../redux/futures/campaign/actions';
import DefineConst from '../DefineConst';

// eslint-disable-next-line max-lines-per-function
function ModalCampaignMail(props) {
    const {
        t, isEdit, campaignProp, cb,
    } = props;
    const dispatch = useDispatch();
    const [campaign, setCampaign] = useState({ type: DefineConst.PLATFORM_CAMPAIGN.MAIL });
    const listAffiliateGroup = useSelector((state) => ({ ...{ items: [], total: 0, pluckItems: {} }, ...state.affiliateGroups.groups }));
    const imageProfileUpload = useSelector((state) => (state.campaigns.imageProfileUpload));

    const cbEvent = () => {
        window.LadiUI.closeModal('modal-campaign-mail');
        setCampaign({ type: DefineConst.PLATFORM_CAMPAIGN.MAIL });
        cb();
    };

    const eventCheckGroup = (e) => {
        const restrictGroups = campaign.restrict_affiliate_group_id || [];
        const restrictGroup = e.target.value;
        if (e.target.checked) {
            setCampaign({ ...campaign, restrict_affiliate_group_id: [...restrictGroups, ...[restrictGroup]] });
        } else {
            const filterRestrictGroups = restrictGroups.filter((item) => item !== restrictGroup);
            setCampaign({ ...campaign, restrict_affiliate_group_id: filterRestrictGroups });
        }
    };

    const eventCancelCampaign = () => {
        if (!isEdit) setCampaign({ type: DefineConst.PLATFORM_CAMPAIGN.MAIL });
        window.LadiUI.closeModal('modal-campaign-mail');
    };

    const eventBackCampaign = () => {
        if (!isEdit) setCampaign({ type: DefineConst.PLATFORM_CAMPAIGN.MAIL });
        window.LadiUI.closeModal('modal-campaign-mail');
        window.LadiUI.showModal('modal-select-platform');
    };

    const eventCreateCampaign = () => {
        // validate
        if (!campaign.title) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập tiêu đề chiến dịch', 5000, 'bottom-left');
            return;
        }

        if (!campaign.image_profile || !campaign.image_profile.path) {
            window.LadiUI.toast('danger', '', 'Vui lòng tải ảnh đại diện cho chiến dịch', 5000, 'bottom-left');
            return;
        }

        dispatch(actionCampaign.create(campaign, cbEvent));
    };

    const eventEditCampaign = () => {
        // validate
        if (!campaign.title) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập tiêu đề chiến dịch', 5000, 'bottom-left');
            return;
        }

        if (!campaign.image_profile || !campaign.image_profile.path) {
            window.LadiUI.toast('danger', '', 'Vui lòng tải ảnh đại diện cho chiến dịch', 5000, 'bottom-left');
            return;
        }

        const data = { ...campaign, id: campaign._id };
        dispatch(actionCampaign.update(data, true, cbEvent));
    };

    const cbResetProfileFile = () => {
        document.getElementById('input-profile-file-campaign-mail').value = '';
    };

    const eventUploadImageProfile = (event) => {
        const files = event.target.files;
        const uploadFile = files[0];

        const reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => {
                const height = image.height;
                const width = image.width;

                const imageProfile = { width, height, name: uploadFile.name };
                setCampaign({ ...campaign, image_profile: imageProfile });
                return true;
            };
        };

        const form = new FormData();
        form.append('json_data', JSON.stringify({
            ladi_app: 'LADISHARE',
            file_type: 'IMAGE',
        }));
        form.append('files', uploadFile, uploadFile.name);
        dispatch(actionCampaign.uploadImageProfile(form, cbResetProfileFile));
    };

    const eventRemoveImgProfile = () => {
        setCampaign({ ...campaign, image_profile: null });
    };

    useEffect(() => {
        if (campaignProp) setCampaign(campaignProp);
    }, [campaignProp]);

    useEffect(() => {
        if (imageProfileUpload) {
            setCampaign({ ...campaign, image_profile: { ...campaign.image_profile, ...imageProfileUpload[0] } });
        }
    }, [imageProfileUpload]);

    return <>
        <div className="ladiui modal bd-example-modal-lg" id="modal-campaign-mail">
            <div className="ladiui modal-dialog modal-lg">
                <div className="ladiui modal-content">
                    <div className="ladiui modal-header">
                        <button className="ladiui btn btn-sm btn-link modal-back"
                            onClick={eventBackCampaign}
                        >
                            <img src="https://w.ladicdn.com/design-system/icons/icon-back.svg" />
                        </button>
                        <h2 className="ladiui title-text-form-lever1">{isEdit ? 'Chỉnh sửa chiến dịch' : 'Tạo chiến dịch'}</h2>
                        <button data-dismiss="modal" data-target="modal-campaign-mail"
                            className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui modal-body">
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-mail-title">Tiêu đề</label>
                            <input className="ladiui form-control" placeholder="Nhập tiêu đề" id="input-mail-title"
                                value={campaign.title || ''}
                                onChange={(e) => (setCampaign({ ...campaign, title: e.target.value }))}
                            />
                        </div>

                        <div className="ladiui form-group upload-img-banner">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-profile-file-campaign-mail">Ảnh đại diện</label>
                            <div className="list-img-banner-preview">
                                {campaign.image_profile
                                    ? <div className="img-banner-preview">
                                        {campaign.image_profile.path ? <>
                                            <img src={`${config.DOMAIN_CDN}/${campaign.image_profile.path}`} />
                                            <div className="remove-img-banner-preview" onClick={() => eventRemoveImgProfile()}>
                                                <img src="https://w.ladicdn.com/ladiui/ladishare/close0808.svg" />
                                            </div>
                                        </> : <></>}
                                    </div>
                                    : <></>}
                                <div>
                                    <button className="ladiui btn btn-outline-primary" onClick={() => { document.getElementById('input-profile-file-campaign-mail').click(); }}>
                                        <img src="https://w.ladicdn.com/ladiui/ladishare/upload0808.svg" className="mr-8" />Tải ảnh lên
                                    </button>
                                </div>
                            </div>
                            <input type="file"
                                id="input-profile-file-campaign-mail"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => eventUploadImageProfile(e)}
                            />
                        </div>

                        <div className="ladiui form-group flex-row">
                            <label className="ladiui title-text-form-lever2 mb-0">Cho phép Affiliate chỉnh sửa</label>
                            <label className="ladiui switch">
                                <input type="checkbox"
                                    checked={campaign.enable_affiliate_edit || false}
                                    onChange={(e) => (setCampaign({ ...campaign, enable_affiliate_edit: e.target.checked }))}
                                />
                                <span className="ladiui slider round"></span>
                            </label>
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-mail-subject">Subject</label>
                            <input className="ladiui form-control" placeholder="Nhập chủ đề" id="input-mail-subject"
                                value={campaign.subject || ''}
                                onChange={(e) => (setCampaign({ ...campaign, subject: e.target.value }))}
                            />
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-file">Nội dung</label>
                            <div>
                                <Editor
                                    apiKey='d7comlpl6oluuhzdhk7kog9ell01o7otr0awkzev7cxd08wt'
                                    onEditorChange={(contents) => setCampaign({ ...campaign, contents })}
                                    initialValue={campaignProp.contents || ''}
                                    init={{
                                        height: 300,
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code wordcount',
                                        ],
                                        toolbar: 'undo redo | formatselect | '
                                            + 'bold italic backcolor | alignleft aligncenter '
                                            + 'alignright alignjustify | bullist numlist outdent indent | '
                                            + 'removeformat',
                                        content_style: 'body { font-family:Roboto, sans-serif; font-size:14px; color:#464646; }',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2" htmlFor="input-body">Hạn chế hiển thị với:</label>
                            <div className="ladiui flex-row">
                                {listAffiliateGroup ? listAffiliateGroup.items.map((item) => <div key={item._id} className="mr-8">
                                    <input type="checkbox" className="ladiui form-check-input-checkbox mr-8"
                                        id={`input-group-mail-${item._id}`}
                                        name="checkbox"
                                        checked={campaign.restrict_affiliate_group_id ? campaign.restrict_affiliate_group_id.indexOf(item._id) !== -1 : false}
                                        value={item._id || ''}
                                        onChange={(e) => eventCheckGroup(e)}
                                    />
                                    <label className="" htmlFor={`input-group-mail-${item._id}`}>{item.name}</label>
                                </div>) : null}
                            </div>
                        </div>
                    </div>
                    <div className="ladiui modal-footer">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <button className="ladiui btn btn-secondary" onClick={eventCancelCampaign}>Huỷ bỏ</button>
                            {isEdit
                                ? <button className="ladiui btn btn-primary" onClick={eventEditCampaign}>Cập nhật chiến dịch</button>
                                : <button className="ladiui btn btn-primary" onClick={eventCreateCampaign}>Tạo chiến dịch</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(ModalCampaignMail));
