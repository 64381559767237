import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';
import BaseHelper from '../../../../helpers/BaseHelper';
import DefineConst from '../../DefineConst';

function CommissionPlanPaymentBoxCountIntervals(props) {
    const {
        t, paymentFor, paymentIntervalsProp, cb,
    } = props;
    const [paymentInterval, setPaymentInterval] = useState({ enable: false });

    const renderExplain = () => {
        const monthlyResetText = paymentInterval.monthly_reset ? ' trong tháng' : '';
        let explain = <></>;
        if (paymentFor === DefineConst.PAYMENT_FOR.NEW_CUSTOMER) {
            if (paymentInterval.start && paymentInterval.end) explain = <>(*) Cứ {paymentInterval.step || 1} khách hàng mới sẽ được tính 1 lần hoa hồng, bắt đầu từ khách hàng thứ {paymentInterval.start || 0} đến {paymentInterval.end || 0}{monthlyResetText}</>;

            if (paymentInterval.start && !paymentInterval.end) explain = <>(*) Cứ {paymentInterval.step || 1} khách hàng mới sẽ được tính 1 lần hoa hồng, bắt đầu từ khách hàng thứ {paymentInterval.start || 0}{monthlyResetText}</>;

            if (!paymentInterval.start && paymentInterval.end) explain = <>(*) Cứ {paymentInterval.step || 1} khách hàng mới sẽ được tính 1 lần hoa hồng, cho đến khách hàng thứ {paymentInterval.end || 0}{monthlyResetText}</>;
        }
        if (paymentFor === DefineConst.PAYMENT_FOR.PER_PRODUCT) {
            if (paymentInterval.start && paymentInterval.end) explain = <>(*) Cứ {paymentInterval.step || 1} sản phẩm sẽ được tính 1 lần hoa hồng, bắt đầu từ sản phẩm thứ {paymentInterval.start || 0} đến {paymentInterval.end || 0}{monthlyResetText}</>;

            if (paymentInterval.start && !paymentInterval.end) explain = <>(*) Cứ {paymentInterval.step || 1} sản phẩm sẽ được tính 1 lần hoa hồng, bắt đầu từ sản phẩm thứ {paymentInterval.start || 0}{monthlyResetText}</>;

            if (!paymentInterval.start && paymentInterval.end) explain = <>(*) Cứ {paymentInterval.step || 1} sản phẩm sẽ được tính 1 lần hoa hồng, cho đến sản phẩm thứ{paymentInterval.end || 0}{monthlyResetText}</>;
        }
        if (paymentFor === DefineConst.PAYMENT_FOR.LEAD) {
            if (paymentInterval.start && paymentInterval.end) explain = <>(*) Cứ {paymentInterval.step || 1} lead sẽ được tính 1 lần hoa hồng, bắt đầu từ lead thứ {paymentInterval.start || 0} đến {paymentInterval.end || 0}{monthlyResetText}</>;

            if (paymentInterval.start && !paymentInterval.end) explain = <>(*) Cứ {paymentInterval.step || 1} lead sẽ được tính 1 lần hoa hồng, bắt đầu từ lead thứ {paymentInterval.start || 0}{monthlyResetText}</>;

            if (!paymentInterval.start && paymentInterval.end) explain = <>(*) Cứ {paymentInterval.step || 1} lead sẽ được tính 1 lần hoa hồng, cho đến lead thứ {paymentInterval.end || 0}{monthlyResetText}</>;
        }

        return explain;
    };

    const eventChangeInput = (e) => {
        const name = e.target.name;
        let value;
        if (
            e.target.tagName.toLowerCase() === 'input'
            && e.target.getAttribute('type') === 'checkbox'
        ) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        const data = { ...paymentInterval };
        data[name] = value;
        setPaymentInterval(data);
        cb(data);
    };

    const changeInputValue = (fieldName, value) => {
        const data = { ...paymentInterval };
        data[fieldName] = value;
        setPaymentInterval(data);
        cb(data);
    };

    useEffect(() => {
        setPaymentInterval({ ...paymentInterval, ...paymentIntervalsProp });
    }, [paymentIntervalsProp]);

    return <>
        <div className="ladiui clearfix pt-20">
            <label className="ladiui title-text-form-lever2 left">Trả theo tần suất</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox"
                        name="enable"
                        checked={paymentInterval !== null && (paymentInterval.enable || false)}
                        onChange={(e) => eventChangeInput(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Trả hoa hồng nếu affiliate có tần suất đạt yêu cầu</div>
        </div>
        {paymentInterval && paymentInterval.enable
            ? <>
                <div className="ladiui clearfix pt-20">
                    <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Số lượng tối thiểu</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                value={paymentInterval.start || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Số lượng tối thiểu"
                                onValueChange={(values) => changeInputValue('start', values.value)}
                            />
                        </div>
                    </div>
                    {paymentFor !== DefineConst.PAYMENT_FOR.PER_PRODUCT
                        ? <div className="ladiui left mr-8">
                            <label className="ladiui title-text-form-lever3">Khoảng cách</label>
                            <div className="group-input-custom custom-form-dynamic w-180">
                                <NumericFormat
                                    value={paymentInterval.step || ''}
                                    allowLeadingZeros
                                    allowNegative={false}
                                    decimalScale={0}
                                    thousandSeparator=","
                                    className="ladiui form-control btn-sm w-175"
                                    placeholder="Khoảng cách"
                                    onValueChange={(values) => changeInputValue('step', values.value)}
                                />
                            </div>
                        </div>
                        : <></>}
                    <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Số lượng tối đa</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                value={paymentInterval.end || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Số lượng tối đa"
                                onValueChange={(values) => changeInputValue('end', values.value)}
                            />
                        </div>
                    </div>
                    <div className="ladiui left">
                        <label className="ladiui title-text-form-lever3" htmlFor="monthly_reset">Reset hàng tháng</label>
                        <div className="ladiui item-form switch check-switch-2">
                            <label className="ladiui switch">
                                <input type="checkbox"
                                    name="monthly_reset"
                                    checked={paymentInterval.monthly_reset || false}
                                    onChange={(e) => eventChangeInput(e)}
                                />
                                <span className="ladiui slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>
                    {renderExplain()}
                </div>
            </>
            : <></>}
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBoxCountIntervals));
