import { has } from 'lodash';
import appConfig from '../../config/app';
import config, { authEndpoint } from '../../config/config';
import i18n from '../../i18n';
import baseHelper from '../../helpers/BaseHelper';
import {
    SIGNOUT, GET_USER_INFO,
} from '../futures/auth/types';

const cookiesMiddleware = () => (next) => (action) => {
    const origin = window.location.origin;
    const paths = window.location.pathname.split('/');

    if (has(action, 'new_store_id')) {
        const oldStoreID = baseHelper.getCookie(appConfig.COOKIE.STORE_ID);
        if (oldStoreID !== action.new_store_id) {
            baseHelper.setCookie(appConfig.COOKIE.STORE_ID, action.new_store_id);
            window.location.reload();
        }
    }

    if (action.type === SIGNOUT) {
        const storeAlias = baseHelper.getCookie(appConfig.COOKIE.STORE_ALIAS);
        let resetCookieLogout = function () {
            try {
                if (typeof (Storage) !== 'undefined' && Storage != undefined) {
                    localStorage.clear();
                    sessionStorage.clear();
                }
            } catch (e) {
                // no code
            }

            let cookies = document.cookie.split(';');
            let excepts = ['LANGUAGE', 'STORE_ALIAS'];

            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                let index = cookie.indexOf('=');
                let name = index > -1 ? cookie.substr(0, index) : cookie;
                name = name.trim();

                if (excepts.indexOf(name) != -1) {
                    continue;
                }

                let str = `${name} =; expires = Thu, 01 Jan 1970 00:00:00 GMT; path = /`;
                document.cookie = str;

                if (window.location.protocol == 'https:') {
                    str += '; SameSite = None; secure';
                }

                document.cookie = str;
            }
        };
        resetCookieLogout();

        if (config.APP_AFFILIATE) {
            if (origin === config.AFFILIATE_DOMAIN) {
                window.location.href = `${authEndpoint.PUBLISHER_SIGNIN}/${storeAlias || paths[1]}/signin`;
            } else {
                window.location.href = origin;
            }
        } else {
            window.location.href = authEndpoint.AUTH_SIGNOUT;
        }
    }

    if (action.type === GET_USER_INFO && !action.no_store) {
        i18n.changeLanguage(action.payload.store && action.payload.store.language ? action.payload.store.language : appConfig.LANGUAGE.VI.code);
    }

    next(action);
};

export default cookiesMiddleware;
