import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import DefineConst from '../DefineConst';

function ExportAffiliate(props) {
    const { t, actionExportProp } = props;

    const [exportValue, setExportValue] = useState(DefineConst.EXPORT_ACTIONS.FIRST_PAGE);

    const eventExportValue = () => {
        actionExportProp(exportValue);
    };
    return <>
        <div className="ladiui modal" id="modal-export-data">
            <div className="ladiui modal-dialog modal-sm">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">{t('COMMON.EXPORT_DATA')}</label>
                        <button type="button" data-dismiss="modal" data-target="modal-export-commission" className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui radio">
                        <input type="radio" className="ladiui form-check-input" id="export-first-page" checked={exportValue === DefineConst.EXPORT_ACTIONS.FIRST_PAGE}
                            name="export" value={DefineConst.EXPORT_ACTIONS.FIRST_PAGE} onChange={() => setExportValue(DefineConst.EXPORT_ACTIONS.FIRST_PAGE)} />
                        <label htmlFor="export-first-page">{t('COMMON.FIRST_PAGE')}</label>
                    </div>
                    <div className="ladiui radio">
                        <input type="radio" className="ladiui form-check-input" id="export-selected-record" checked={exportValue === DefineConst.EXPORT_ACTIONS.SELECTED_RECORDS}
                            name="export" value={DefineConst.EXPORT_ACTIONS.SELECTED_RECORDS} onChange={() => setExportValue(DefineConst.EXPORT_ACTIONS.SELECTED_RECORDS)} />
                        <label htmlFor="export-selected-record">{t('COMMON.SELECTED_RECORDS')}</label>
                    </div>
                    <div className="ladiui radio">
                        <input type="radio" className="ladiui form-check-input" id="export-all-with-conditions" checked={exportValue === DefineConst.EXPORT_ACTIONS.ALL_RECORDS}
                            name="export" value={DefineConst.EXPORT_ACTIONS.ALL_RECORDS} onChange={() => setExportValue(DefineConst.EXPORT_ACTIONS.ALL_RECORDS)} />
                        <label htmlFor="export-all-with-conditions">{t('COMMON.ALL_RECORDS')}</label>
                    </div>

                    <div className="ladiui modal-footer custom-ldp">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <button type="button" data-dismiss="modal" data-target="modal-export-commission"
                                className="ladiui btn btn-secondary bold">{t('COMMISSIONS.CANCELED')}</button>
                            <button type="button" className="ladiui btn btn-primary" onClick={eventExportValue}>{t('COMMISSIONS.DOWNLOAD')}</button>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    </>;
}
export default (withTranslation()(ExportAffiliate));
