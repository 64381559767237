import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { status } from '../../../config/app';
import DefineConst from '../DefineConst';
import actionAppIntegrate from '../../../redux/futures/integrate_app/actions';
import FormShopify from './forms/FormShopify';
import FormGetfly from './forms/FormGetfly';
import FormWooCommerce from './forms/FormWooCommerce';
import FormHaravan from './forms/FormHaravan';
import FormSapo from './forms/FormSapo';
import FormWordpress from './forms/FormWordpress';
import FormLadiSales from './forms/FormLadiSales';
import FormSdk from './forms/FormSdk';
import FormNhanh from './forms/FormNhanh';

// eslint-disable-next-line max-lines-per-function
function ModalAppIntegrate(props) {
    const {
        t, title, resetModal, cbResetModal, cb,
    } = props;

    const [appIntegrate, setAppIntegrate] = useState(null);
    const [enableIntegrate, setEnableIntegrate] = useState(false);

    const dispatch = useDispatch();

    const cbModal = () => {
        const response = cbModal.response;
        if (response.data.code === status.CREATED || response.data.code === status.OK) {
            setAppIntegrate(null);
            window.LadiUI.closeModal('modal-add-integrate-app');
            cb();
        }
    };

    const eventAddAppIntegrate = () => {
        if (!appIntegrate.name) return;
        dispatch(actionAppIntegrate.create(appIntegrate, cbModal));
    };

    const eventForm = (data) => {
        setAppIntegrate({ ...appIntegrate, ...data });
    };

    const renderForm = () => {
        let formContent = <></>;
        switch (appIntegrate.type) {
            case DefineConst.INTEGRATE_APPS_TYPE.LADISALES:
                formContent = <FormLadiSales cb={eventForm} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.SHOPIFY:
                formContent = <FormShopify cb={eventForm} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.GETFLY:
                formContent = <FormGetfly cb={eventForm} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.WOOCOMMERCE:
                formContent = <FormWooCommerce cb={eventForm} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.HARAVAN:
                formContent = <FormHaravan cb={eventForm} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.SAPO:
                formContent = <FormSapo cb={eventForm} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.NHANH:
                formContent = <FormNhanh cb={eventForm} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.WORDPRESS:
                formContent = <FormWordpress cb={eventForm} />;
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.SDK:
                formContent = <FormSdk cb={eventForm} />;
                break;

            default: break;
        }
        return formContent;
    };

    const getPlatformName = () => {
        let formContent;
        switch (appIntegrate.type) {
            case DefineConst.INTEGRATE_APPS_TYPE.LADISALES:
                formContent = '';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.SHOPIFY:
                formContent = 'Shopify';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.GETFLY:
                formContent = 'Getfly';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.WOOCOMMERCE:
                formContent = 'Woocommerce';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.HARAVAN:
                formContent = 'Haravan';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.SAPO:
                formContent = '';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.NHANH:
                formContent = 'Nhanh.vn';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.WORDPRESS:
                formContent = 'Woocommerce';
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.SDK:
                formContent = 'SDK - Custom API';
                break;

            default: break;
        }
        return formContent;
    };

    useEffect(() => {
        if (!appIntegrate || !appIntegrate.type) return;
        let enableButton = false;
        switch (appIntegrate.type) {
            case DefineConst.INTEGRATE_APPS_TYPE.LADISALES:
                if (appIntegrate.name && appIntegrate.api_key) enableButton = true;
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.HARAVAN:
                if (appIntegrate.name && appIntegrate.app_token && appIntegrate.api_url && appIntegrate.token && appIntegrate.shop_id) enableButton = true;
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.SAPO:
                if (appIntegrate.name && appIntegrate.api_url && appIntegrate.token) enableButton = true;
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.NHANH:
                if (appIntegrate.name && appIntegrate.shop_id && appIntegrate.token) enableButton = true;
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.SHOPIFY:
                if (appIntegrate.name && appIntegrate.api_url) enableButton = true;
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.GETFLY:
                if (appIntegrate.name && appIntegrate.api_url) enableButton = true;
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.WORDPRESS:
                if (appIntegrate.name && appIntegrate.api_url && appIntegrate.api_key) enableButton = true;
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.SDK:
                if (appIntegrate.name && appIntegrate.shop_id) enableButton = true;
                break;

            default: enableButton = false;
        }
        if (enableButton) {
            setEnableIntegrate(true);
        } else {
            setEnableIntegrate(false);
        }
    }, [appIntegrate]);

    useEffect(() => {
        if (resetModal) {
            setAppIntegrate(null);
            cbResetModal();
        }
    }, [resetModal]);

    return <div className="ladiui modal" id="modal-add-integrate-app">
        <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
            <div className="ladiui modal-content custom-ldp">
                {appIntegrate
                    ? <>
                        <div className="ladiui modal-header custom-ldp">
                            <button className="ladiui btn btn-sm btn-link modal-back"
                                onClick={() => setAppIntegrate(null)}>
                                <img src="https://w.ladicdn.com/design-system/icons/icon-back.svg" />
                            </button>
                            <label className="ladiui title-text-form-lever1" style={{ display: 'flex' }}><img src={appIntegrate.logo} height="24px" />{getPlatformName()}</label>
                            <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="box-app-integrate-content-description" style={{ padding: '12px 0 24px 0' }}>
                            {appIntegrate.description}
                        </div>
                        <div className="box-app-integrate-content-setting">
                            {renderForm()}
                        </div>
                        <div className="ladiui modal-footer custom-ldp">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button type="button" data-dismiss="modal" data-target="white-page"
                                    className="ladiui btn btn-secondary" onClick={() => setAppIntegrate(null)}>Hủy bỏ</button>
                                {enableIntegrate
                                    ? <button type="button" className="ladiui btn btn-primary" onClick={eventAddAppIntegrate}>Thêm tài khoản liên kết</button>
                                    : <button type="button" className="ladiui btn disabled btn-primary" disabled>Thêm tài khoản liên kết</button>}

                            </div>

                        </div>
                    </>
                    : <>
                        <div className="ladiui modal-header custom-ldp">
                            <label className="ladiui title-text-form-lever1">{title}</label>
                            <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="list-app-integrate grid-2">
                            <div className="box-app-integrate" onClick={() => setAppIntegrate(DefineConst.INTEGRATE_APPS.LADISALES)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src={DefineConst.INTEGRATE_APPS.LADISALES.logo} /></div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">{DefineConst.INTEGRATE_APPS.LADISALES.description}</div>
                                </div>
                            </div>
                            <div className="box-app-integrate" onClick={() => setAppIntegrate(DefineConst.INTEGRATE_APPS.HARAVAN)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src={DefineConst.INTEGRATE_APPS.HARAVAN.logo} />&nbsp;Haravan</div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">{DefineConst.INTEGRATE_APPS.HARAVAN.description}</div>
                                </div>
                            </div>
                            <div className="box-app-integrate" onClick={() => setAppIntegrate(DefineConst.INTEGRATE_APPS.SAPO)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src={DefineConst.INTEGRATE_APPS.SAPO.logo} /></div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">{DefineConst.INTEGRATE_APPS.SAPO.description}</div>
                                </div>
                            </div>
                            <div className="box-app-integrate" onClick={() => setAppIntegrate(DefineConst.INTEGRATE_APPS.NHANH)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src={DefineConst.INTEGRATE_APPS.NHANH.logo} />&nbsp;Nhanh.vn</div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">{DefineConst.INTEGRATE_APPS.NHANH.description}</div>
                                </div>
                            </div>
                            <div className="box-app-integrate" onClick={() => setAppIntegrate(DefineConst.INTEGRATE_APPS.WORDPRESS)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src={DefineConst.INTEGRATE_APPS.WORDPRESS.logo} />&nbsp;Woocommerce</div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">{DefineConst.INTEGRATE_APPS.WORDPRESS.description}</div>
                                </div>
                            </div>
                            <div className="box-app-integrate" onClick={() => setAppIntegrate(DefineConst.INTEGRATE_APPS.SHOPIFY)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src={DefineConst.INTEGRATE_APPS.SHOPIFY.logo} />&nbsp;Shopify</div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">{DefineConst.INTEGRATE_APPS.SHOPIFY.description}</div>
                                </div>
                            </div>
                            <div className="box-app-integrate" onClick={() => setAppIntegrate(DefineConst.INTEGRATE_APPS.GETFLY)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src={DefineConst.INTEGRATE_APPS.GETFLY.logo} />&nbsp;Getfly</div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">{DefineConst.INTEGRATE_APPS.GETFLY.description}</div>
                                </div>
                            </div>
                            <div className="box-app-integrate" onClick={() => setAppIntegrate(DefineConst.INTEGRATE_APPS.SDK)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src={DefineConst.INTEGRATE_APPS.SDK.logo} />&nbsp;SDK - Custom API</div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">{DefineConst.INTEGRATE_APPS.SDK.description}</div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    </div >;
}

export default (withTranslation()(ModalAppIntegrate));
