/* eslint-disable max-lines-per-function */
import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_INVOICE: {
            return {
                ...state,
                action: types.LIST_INVOICE,
                invoices: action.status ? { items: action.payload.items, total: action.payload.total, summary: action.payload.summary } : { items: [], total: 0, summary: {} },
                affiliates: action.status ? action.payload.affiliates : [],
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_INVOICE: {
            return {
                ...state,
                action: types.CREATE_INVOICE,
                invoice: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_INVOICE: {
            return {
                ...state,
                action: types.SHOW_INVOICE,
                invoice: action.status ? action.payload.invoice : {},
                invoiceAffiliate: action.status ? action.payload.affiliate : {},
                invoiceCommission: action.status ? action.payload.commissions : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_INVOICE: {
            return {
                ...state,
                action: types.UPDATE_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_INVOICE: {
            return {
                ...state,
                action: types.DELETE_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        case types.EXPORT_INVOICE: {
            return {
                ...state,
                action: types.EXPORT_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        case types.EXPORT_COMMISSION_INVOICE: {
            return {
                ...state,
                action: types.EXPORT_COMMISSION_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_MULTI_INVOICE: {
            return {
                ...state,
                action: types.DELETE_MULTI_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        case types.ACCEPT_MULTI_INVOICE: {
            return {
                ...state,
                action: types.ACCEPT_MULTI_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        case types.REJECT_MULTI_INVOICE: {
            return {
                ...state,
                action: types.REJECT_MULTI_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        case types.PAID_MULTI_INVOICE: {
            return {
                ...state,
                action: types.PAID_MULTI_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_STATUS_INVOICE: {
            return {
                ...state,
                action: types.UPDATE_STATUS_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        case types.EXPORT_BANK_INFO_AFFILIATE: {
            return {
                ...state,
                action: types.EXPORT_BANK_INFO_AFFILIATE,
                status: action.status,
                message: action.message,
            };
        }

        case types.SEND_MAIL_CONFIRM_INVOICE: {
            return {
                ...state,
                action: types.SEND_MAIL_CONFIRM_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_REQUEST_WITHDRAW: {
            return {
                ...state,
                action: types.CREATE_REQUEST_WITHDRAW,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_NOTE_INVOICE: {
            return {
                ...state,
                action: types.UPDATE_NOTE_INVOICE,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
