import i18n from '../../i18n';

export default {
    TRAFFIC_TYPE: {
        VISITOR: 'VISITOR',
        LEAD: 'LEAD',
        ORDER: 'ORDER',
        NEW_CUSTOMER: 'NEW_CUSTOMER',
        PER_PRODUCT: 'PER_PRODUCT',
        COUPON: 'COUPON',
    },
    TRAFFIC_STATUS_VALUES: {
        PENDING: 'PENDING',
        CALCULATE_COMMISSION: 'CALCULATE_COMMISSION',
        RE_CALCULATE_COMMISSION: 'RE_CALCULATE_COMMISSION',
        CANCELED: 'CANCELED',
    },
    TRAFFIC_STATUS: {
        PENDING: 'Chưa tính hoa hồng',
        CALCULATE_COMMISSION: 'Đã tính hoa hồng',
        RE_CALCULATE_COMMISSION: 'Tính lại hoa hồng',
        CANCELED: 'Hủy',
    },
};
