import React from 'react';

import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

class HaravanPostMessageIntegrate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        const accessToken = url.searchParams.get('code');
        const shopID = url.searchParams.get('shop');
        const data = {
            accessToken,
            shopID,
        };

        try {
            if (window.opener) {
                if (!isEmpty(accessToken)) {
                    window.opener.postMessage(data, `${window.location.protocol}//${window.location.hostname}`);
                }

                window.close();
            } else {
                window.location.href = '/apps';
            }
        } catch (e) {
            // no code
        }
    }

    render() {
        return (
            <div></div>
        );
    }
}

export default (withTranslation('translation', { withRef: true })(HaravanPostMessageIntegrate));
