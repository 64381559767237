import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import actionAffiliateGroup from '../../redux/futures/affiliate_group/actions';

function ModalGroupAffiliate(props) {
    const {
        t, title, btnOk, btnCancel, dataGroupAffiliate, cb,
    } = props;

    const styleInvalid = { border: '1px solid var(--danger-color)' };
    const styleValid = { boder: '1px solid var(--main-line)' };
    const defaultValidated = {
        name: false,
    };
    const defaultIsValidated = {
        name: true,
    };

    const dispatch = useDispatch();
    const [group, setGroup] = useState(dataGroupAffiliate);
    const [valid, setValid] = useState(defaultValidated);

    const changeDataForm = (e) => {
        const name = e.target.name;
        const field = {};
        if (e.target.type === 'checkbox') {
            field[name] = e.target.checked;
        } else {
            const value = e.target.value;
            field[name] = value;
            const validatedData = {};
            if (e.target.hasAttribute('required')) {
                if (value === '') {
                    validatedData[name] = false;
                } else {
                    validatedData[name] = true;
                }
            }
            setValid({ ...valid, ...validatedData });
        }
        setGroup({ ...group, ...field });
    };

    const actionOk = () => {
        let isValid = true;
        const nameElement = document.getElementById('name');
        if (nameElement.value === '') {
            isValid = false;
            setValid({ ...valid, ...{ name: false } });
        }
        if (!isValid) {
            window.LadiUI.toast('danger', '', 'Vui lòng điền đầy đủ thông tin');
            return;
        }

        if (group._id) {
            const data = { ...group, ...{ id: group._id } };
            dispatch(actionAffiliateGroup.update(data, cb));
        } else {
            dispatch(actionAffiliateGroup.create(group, cb));
        }
        window.LadiUI.closeModal('white-page');
    };

    const eventCancel = () => {
        setValid(defaultIsValidated);
    };

    useEffect(() => {
        setGroup(dataGroupAffiliate);
        setValid(defaultIsValidated);
    }, [props.dataGroupAffiliate]);

    return <div className="ladiui modal" id="white-page">
        <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
            <div className="ladiui modal-content custom-ldp">
                <div className="ladiui modal-header custom-ldp">
                    <label className="ladiui title-text-form-lever1">{title}</label>
                    <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                        <i className="ladiui icon icon-ldp-close"></i>
                    </button>
                </div>
                <div className="ladiui form-group-custom">
                    <label className="ladiui title-text-form-lever2" >{t('GROUP_AFFILIATES.NAME')}</label>
                    <input id="name" name="name" className="ladiui form-control" placeholder="Nhập tên nhóm" required
                        style={!valid.name ? styleInvalid : styleValid}
                        value={group.name || ''} onChange={changeDataForm} />
                </div>
                <div className="ladiui form-group-custom">
                    <label className="ladiui title-text-form-lever2" >Nhãn</label>
                    <input id="label" name="label" className="ladiui form-control" placeholder="Nhập tên nhãn"
                        value={group.label || ''} onChange={changeDataForm} />
                </div>

                <div className="ladiui form-group-custom">
                    <label className="ladiui title-text-form-lever2" >{t('GROUP_AFFILIATES.DESCRIPTION')}</label>
                    <textarea id="description" name="description" className="ladiui form-control" placeholder="Nhập mô tả" required
                        value={group.description || ''}
                        onChange={changeDataForm} />
                </div>

                <div className="ladiui clearfix pt-20">
                    <label className="ladiui title-text-form-lever2 left margin-label">{t('GROUP_AFFILIATES.MAKE_AS_DEFAULT')}</label>
                    <div className="ladiui item-form switch">
                        <label className="ladiui switch">
                            <input type="checkbox" id="is_default" name="is_default" checked={group.is_default} onChange={changeDataForm} />
                            <span className="ladiui slider round"></span>
                        </label>
                    </div>
                </div>

                <div className="ladiui modal-footer custom-ldp">
                    <div></div>
                    <div className="ladiui footer-modal-btn">
                        <button type="button" data-dismiss="modal" data-target="white-page"
                            className="ladiui btn btn-secondary bold" onClick={eventCancel}>{btnCancel}</button>
                        <button type="button" className="ladiui btn btn-primary" onClick={actionOk}>{btnOk}</button>
                    </div>

                </div>
            </div>
        </div>
    </div >;
}

export default (withTranslation()(ModalGroupAffiliate));
