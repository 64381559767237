import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal';
import config from '../../../config/config';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';

import actionInvoice from '../../../redux/futures/invoice/actions';
import DefineConst from '../DefineConst';

// eslint-disable-next-line max-lines-per-function
function ModalInvoiceDetail(props) {
    const { t, id, conditionsProp } = props;

    const dispatch = useDispatch();
    const invoice = useSelector((state) => (state.invoices.invoice || {}));
    const affiliate = useSelector((state) => (state.invoices.invoiceAffiliate || {}));
    const commission = useSelector((state) => (state.invoices.invoiceCommission || {}));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const getInvoice = () => {
        dispatch(actionInvoice.show(id));
    };

    const getListInvoice = () => {
        dispatch(actionInvoice.list(conditionsProp));
    };

    const reloadData = () => {
        getInvoice();
        getListInvoice();
    };

    const eventConfirmPurchase = () => {
        window.LadiUI.showModal('confirm-purchase-modal');
    };

    const eventConfirmAprovedInvoice = () => {
        window.LadiUI.showModal('confirm-approved-invoice-modal');
    };

    const eventOkPurchaseInvoice = () => {
        const data = {
            id,
            status: DefineConst.INVOICE_STATUS.PAID,
        };
        dispatch(actionInvoice.updateStatus(data, reloadData));
    };

    const eventOkApprovedInvoice = () => {
        const data = {
            id,
            status: DefineConst.INVOICE_STATUS.APPROVED,
        };
        dispatch(actionInvoice.updateStatus(data, reloadData));
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.INVOICE_STATUS.PENDING:
                statusContent = <>
                    <span className='ladiui badge badge-warning'>
                        {t('INVOICES.INVOICE_STATUS.PENDING')}
                    </span>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.APPROVED:
                statusContent = <>
                    <span className='ladiui badge badge-success'>
                        {t('INVOICES.INVOICE_STATUS.APPROVED')}
                    </span>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.PAID:
                statusContent = <>
                    <span className='ladiui badge badge-success'>
                        {t('INVOICES.INVOICE_STATUS.PAID')}
                    </span>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.CANCELED:
                statusContent = <>
                    <span className='ladiui badge badge-mute'>
                        {t('INVOICES.INVOICE_STATUS.CANCELED')}
                    </span>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    const getVat = (invoice) => {
        const invoiceAmount = invoice.is_include_vat ? (parseFloat(invoice.amount) - parseFloat(invoice.tax)) : parseFloat(invoice.amount);
        return invoiceAmount <= 0 ? 0 : Math.round((100 * parseFloat(invoice.tax || 0)) / invoiceAmount);
    };

    const getTotalBeforeVat = (item) => {
        const isIncludeVatInvoice = item.is_include_vat || false;
        if (isIncludeVatInvoice) return parseFloat(item.amount || 0) - parseFloat(item.tax || 0);

        return parseFloat(item.amount || 0);
    };

    const getTotalAfterVat = (item) => {
        const isIncludeVatInvoice = item.is_include_vat || false;
        if (isIncludeVatInvoice) return parseFloat(item.amount || 0);

        return parseFloat(item.amount || 0) - parseFloat(item.tax || 0);
    };

    useEffect(() => {
        if (id) getInvoice();
    }, [id]);
    return invoice ? <>
        <div className="ladiui modal" id="modal-detail-invoice">
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">Hóa đơn trả hoa hồng</label>
                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="invoice-code"><label>Mã giao dịch:</label> #{invoice.code || ''}</div>
                        <div className="detail-invoice">
                            <div>
                                <div className="detail-invoice-affiliate-name">
                                    <label>Affiliate</label>
                                    <div><Link to={`/affiliates/${affiliate._id || ''}`} className="ladiui link">{affiliate.fullname || ''}</Link></div>
                                    <div>{affiliate.email || ''}</div>
                                </div>
                                <div className="detail-invoice-affiliate-code">
                                    <label>Mã Affiliate</label>
                                    <div>{affiliate.code || ''}</div>
                                </div>
                                <div className="detail-invoice-note">
                                    <label>Ghi chú</label>
                                    <div>{invoice.note || ''}</div>
                                </div>
                            </div>
                            <div className="detail-invoice-total-content">
                                <div className="detail-invoice-total">
                                    <div>
                                        <label>Hoa hồng</label>
                                        <div>{BaseHelper.formatMoney(getTotalBeforeVat(invoice))}</div>
                                    </div>
                                    <div>
                                        <label>VAT {getVat(invoice)}%</label>
                                        <div>{BaseHelper.formatMoney(invoice.tax || 0)}</div>
                                    </div>
                                    <hr />
                                    <div className="detail-invoice-total-total">
                                        <label>Tổng tiền</label>
                                        <div>{BaseHelper.formatMoney(getTotalAfterVat(invoice))}</div>
                                    </div>
                                </div>
                                <div className="detail-invoice-action">
                                    <a href={`/invoices/print/${invoice._id}`} target="_blank" className="ladiui btn btn-link" rel="noreferrer">In hóa đơn</a>
                                    {
                                        !config.APP_AFFILIATE ? <>
                                            {
                                                invoice.status === DefineConst.INVOICE_STATUS.PENDING
                                                    ? <button className="ladiui btn btn-primary" onClick={eventConfirmAprovedInvoice}>Duyệt hoá đơn</button> : <></>
                                            }
                                            {
                                                invoice.status === DefineConst.INVOICE_STATUS.APPROVED
                                                    ? <button className="ladiui btn btn-primary" onClick={eventConfirmPurchase}>Thanh toán hoá đơn</button> : <></>
                                            }
                                        </> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="info-invoice">
                            <div>
                                <label>Trạng thái hóa đơn</label>
                                <div>{getStatus(invoice.status)}</div>
                            </div>
                            <div>
                                <label>Ngày lên hoá đơn</label>
                                <div>{BaseHelper.formatStrToDate(invoice.created_at || null)}</div>
                            </div>
                            {invoice.status === DefineConst.INVOICE_STATUS.PAID
                                ? <>
                                    <div>
                                        <label>Ngày thanh toán</label>
                                        <div>{BaseHelper.formatStrToDate(invoice.paid_at || null)}</div>
                                    </div>
                                    <div>
                                        <label>Hình thức thanh toán</label>
                                        <div>Chuyển khoản ngân hàng</div>
                                    </div>
                                </>
                                : <></>
                            }
                        </div>
                        <div style={{ marginTop: '25px' }}>
                            <label>Danh sách hoa hồng</label>
                            {commission && commission.length > 0
                                ? <div className="list-commission-invoice" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                    <table className="ladiui table text-left" style={{ marginTop: 0, minWidth: '680px' }}>
                                        <thead>
                                            <tr className="ladiui table-vertical header">
                                                <th>#</th>
                                                <th>Mã tham chiếu</th>
                                                <th>Loại</th>
                                                <th>Ngày duyệt</th>
                                                <th className="ladiui text-right">Tổng tiền</th>
                                                <th className="ladiui text-right">Hoa hồng</th>
                                                <th className="ladiui text-right">Thưởng</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {commission.map((item, index) => <tr className="ladiui table-vertical main" key={item._id}>
                                                <td>{index + 1}</td>
                                                <td>{item.data.code || item._id}</td>
                                                <td>{appConfig.LADISHARE.COMMISSION_TYPE.map((type) => (type.value === item.type ? type.name : ''))}</td>
                                                <td>{BaseHelper.formatStrToDate(item.commission_date)}</td>
                                                <td className="ladiui text-right">{BaseHelper.formatMoney(item.total, '', systemCurrency)}</td>
                                                <td className="ladiui text-right">{BaseHelper.formatMoney(item.amount, '', systemCurrency)}</td>
                                                <td className="ladiui text-right">{BaseHelper.formatMoney(item.bonus, '', systemCurrency)}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                : <></>}
                        </div>
                    </div>

                </div>
            </div>
        </div >
        <ConfirmModal
            id="confirm-purchase-modal"
            title="Bạn có chắc muốn thanh toán hoá đơn này không?"
            content="Sau khi thanh toán sẽ không được phép thay đổi trạng thái"
            onOk={() => eventOkPurchaseInvoice()}
            cancelText={t('INVOICES.CANCELED')}
            okText={t('INVOICES.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="confirm-approved-invoice-modal"
            title="Bạn có chắc muốn duyệt hoá đơn này không?"
            content="Sau khi duyệt, bạn có thể thay đổi sang các trạng thái mới"
            onOk={() => eventOkApprovedInvoice()}
            cancelText={t('INVOICES.CANCELED')}
            okText={t('INVOICES.OK')}
            classBtn="btn-primary"
        />
    </> : <></>;
}

export default (withTranslation()(ModalInvoiceDetail));
