import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isArray } from 'lodash';
import LadiDropdown from '../../components/LadiDropdown';
import SearchCondition from '../apps/components/SearchCondition';
import DefineConst from './DefineConst';
import actionAffiliateGroup from '../../redux/futures/affiliate_group/actions';
import actionCampaign from '../../redux/futures/campaign/actions';
import LadiPagination from '../../components/LadiPagination';
import styles from '../../assets/css/custom.module.css';
import ModalShareCampaign from './components/ModalShareCampaign';
import config from '../../config/config';

// eslint-disable-next-line max-lines-per-function
function CampaignPublisher(props) {
    const { t } = props;

    const defaultCondtions = {
        limit: 10,
        page: 1,
        sort: { is_priority: -1, created_at: -1 },
    };

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState(defaultCondtions);
    const listCampaign = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.campaigns.campaigns }));

    const [isShowBannerAffiliate, setIsShowBannerAffiliate] = useState(false);

    const cbList = () => {
        dispatch(actionCampaign.list(conditions));
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventSelectApp = (data) => {
        setConditions({ ...conditions, type: data });
    };

    const getCampImage = (item) => {
        const img = item.image_profile.path;
        let _imgSrc = '';
        if (img.startsWith("http")) {
            _imgSrc = img;
        } else {
            _imgSrc = 'https://w.ladicdn.com/' + img;
        }
        
        return _imgSrc;
    }

    const getPlatform = (item) => {
        let platform = <></>;
        switch (item.type) {
            case DefineConst.PLATFORM_CAMPAIGN.FACEBOOK:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/fb.svg" className="mr-8" />Facebook
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.INSTAGRAM:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/instagram.svg" width={16} height={16} className="mr-8" />Instagram
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.LINKEDIN:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/linkedin.svg" width={16} height={16} className="mr-8" />Linkedin
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.TWITTER:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/twitter.svg" width={16} height={16} className="mr-8" />Twitter
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.BANNER:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/banner-ads-icon.svg" width={16} height={16} className="mr-8" />Banner
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.MAIL:
                platform = <div style={{ color: '#6D6D6D' }}>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/mail.svg" width={16} height={16} className="mr-8" />Mail
                </div>;
                break;

            default:
        }
        return platform;
    };

    const eventShareCampaign = (item) => {
        dispatch(actionCampaign.show(item._id));
        window.LadiUI.showModal('modal-campaign-share');
    };

    useEffect(() => {
        dispatch(actionAffiliateGroup.list({ pluck: ['_id', 'name'] }));

        let domainUrl = window.location.href;
        if (domainUrl.includes('affiliate.ladipage.com') || domainUrl.includes('aff.dev.ladishare.com')) {
            setIsShowBannerAffiliate(true);
        }
    }, []);

    useEffect(() => {
        cbList();
    }, [conditions]);

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui flex-row">
                    <div className="ladiui main-title">
                        {t('CAMPAIGNS.LIST_CAMPAIGN')}
                    </div>
                </div>
            </div>
            <div className="ladiui content-main-tool flex-row align-item-baseline">
                <div>
                    <SearchCondition
                        keywordProp={conditions.keyword}
                        eventSearchKeyboardProp={eventSearchKeyboard}
                        eventClickSearchButtonProp={eventClickSearchButton}
                    />
                </div>
                <div className="ladiui btn-group custom-btn-list-landingpage">
                    <LadiDropdown
                        listProp={[{ name: 'Chọn nền tảng', value: null }, ...DefineConst.PLATFORM_SOCIETY_ARRAY]}
                        valueProp={null}
                        hideLabel={true}
                        validProp={true}
                        defaultTextProp={'Chọn nền tảng'}
                        cbProp={eventSelectApp}
                    />
                </div>
            </div>

            <div className="campaign-publisher-list">
                {
                    isShowBannerAffiliate
                    &&
                    <>
                        <div className="box-campaign">
                            <a href="http://ldp.ink/5RBO0" target="_blank">
                                <img
                                    src="https://w.ladicdn.com/5938d6181deeeec1a5d3ffaf/asset-93x-8-20240620042049-bvdbb.png"
                                    alt=""
                                    className="img-bg-campaign"
                                    style={{height: "100%"}}
                                />
                            </a>
                        </div>
                        <div className="box-campaign">
                            <a href="http://ldp.ink/0GW0r" target="_blank">
                                <img
                                    src="https://w.ladicdn.com/5938d6181deeeec1a5d3ffaf/300x300-banner-minicamp-ladipage-learning3x-100-20240628092422-lvuts.jpg"
                                    alt=""
                                    className="img-bg-campaign"
                                    style={{height: "100%"}}
                                />
                            </a>
                        </div>
                    </>
                }
                {listCampaign.items.length > 0 ? listCampaign.items.map((item) => <div
                        className="publisher-box-campaign" key={item._id}>
                        <div className="box-campaign">
                            {item.image_profile ? <img src={getCampImage(item)} className="img-bg-campaign"/>
                                : <div style={{height: '200px'}}><img
                                    src="https://w.ladicdn.com/ladiui/ladishare/logo-ladishare.svg"
                                    style={{padding: '50px', height: 'auto'}} className="img-bg-campaign" /></div>}
                        <div className={styles['m-16']}>

                            <div className={styles.title}>{item.title}</div>
                            <div className={`${styles.flex} ${styles['default-align']} ${styles['mt-16']}`}>
                                <div style={{ padding: '8px 0' }}>{getPlatform(item)}</div>
                                <div><img src="https://w.ladicdn.com/design-system/icons/share.svg" /><a href="#/" className="link-share-campaign" style={{ color: '#1C00C2' }}
                                    onClick={() => eventShareCampaign(item)}>&nbsp;<span>Chia sẻ</span></a></div>
                            </div>
                        </div>
                    </div>
                </div>)
                    : ""
                    // <div>{t('NO_DATA')}</div>
                    }
            </div>

            {listCampaign.items.length > 0
                ? <LadiPagination
                    conditionsProp={conditions}
                    listName={t('CAMPAIGNS.CAMPAIGN')}
                    items={listCampaign.items}
                    total={listCampaign.total}
                    limitAction={selectLimit}
                    pageAction={selectPage}
                /> : ''}
        </div>
        <ModalShareCampaign />
    </>;
}

export default (withTranslation()(CampaignPublisher));
