import { differenceWith, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

function LadiDropdownMultiSelect(props) {
    const { t, unCheckedListProp, cb } = props;

    const [selectedItem, setSelectedItem] = useState([]);
    const [unSelectedItem, setUnSelectedItem] = useState([]);

    const eventCheckItem = (item) => {
        const stateItems = [...selectedItem, item];
        setSelectedItem(stateItems);
        const data = unSelectedItem.filter((i) => i.value !== item.value);
        setUnSelectedItem(data);
        cb(stateItems);
    };

    const eventUnCheckItem = (item) => {
        setUnSelectedItem([...unSelectedItem, item]);
        const data = selectedItem.filter((i) => i.value !== item.value);
        setSelectedItem(data);
        cb(data);
    };

    const showListChecked = () => {
        let showListChecked = [];
        for (let i = 0; i < selectedItem.length; i++) {
            showListChecked.push(selectedItem[i].name);
        }
        return showListChecked.join(', ');
    };

    const searchItem = () => {
        const keyword = document.getElementById('multi-select-search-input').value;
        const dif = differenceWith(unCheckedListProp, selectedItem, isEqual);
        const listSearch = dif.filter((item) => item.name.includes(keyword));
        setUnSelectedItem(listSearch);
    };

    const eventSearchItem = (e) => {
        if (e.keyCode === 13) {
            searchItem();
        }
    };

    const eventRemoveAllSelectItem = () => {
        setUnSelectedItem([...unSelectedItem, ...selectedItem]);
        setSelectedItem([]);
        cb([]);
    };

    useEffect(() => {
        if (unCheckedListProp) {
            let selectedItemIds = [];
            let unCheckedList = [];
            for (let i = 0; i < selectedItem.length; i++) {
                selectedItemIds.push(selectedItem[i]._id);
            }
            for (let i = 0; i < unCheckedListProp.length; i++) {
                if (!selectedItemIds.includes(unCheckedListProp[i]._id)) unCheckedList.push(unCheckedListProp[i]);
            }
            setUnSelectedItem(unCheckedList);
        }
    }, [unCheckedListProp]);

    return <>
        <div className="ladiui btn-group ladi-dropdown-multi-select">
            <div className="ladiui select-search dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle custom-main-tool ml-0" data-toggle="dropdown">
                    <i></i>
                    {selectedItem.length > 0
                        ? <>
                            <span className="ladiui dropdown-text">{showListChecked()}</span>
                            <span className="ladiui-btn-tag">{selectedItem.length}</span>
                            <span className="ladiui removeName" style={{ marginRight: '12px' }}
                                onClick={() => eventRemoveAllSelectItem()}> <i
                                    className="ladiui icon icon-ldp-close"></i></span>
                        </>
                        : <><span className="ladiui dropdown-text">Chọn giá trị</span></>}
                </button>

                <ul className="ladiui dropdown-menu custom-people" style={{ left: 0 }}>
                    <li className="ladiui search-group custom-people">
                        <input
                            className="ladiui search-field dropdown-toggle form-control custom-people"
                            placeholder="Tìm kiếm" aria-expanded="false" id="multi-select-search-input"
                            onKeyDown={(e) => eventSearchItem(e)} />
                        <i className="ladiui icon icon-search" onClick={searchItem}></i>
                    </li>
                    <li className="ladiui checked">
                        <ul className="ladiui ul-selected">
                            {selectedItem.map((item) => <li key={item.value} className="ladiui"><label
                                className="ladiui search-multi"><input type="checkbox" onChange={() => eventUnCheckItem(item)} checked
                                    className="ladiui size-checkbox" />{item.name}</label>
                            </li>)}
                        </ul>
                    </li>
                    <li className="ladiui unchecked">
                        <ul className="ladiui ul-unselected">
                            {unSelectedItem.map((item) => <li key={item.value} className="ladiui"><label
                                className="ladiui search-multi"><input type="checkbox" onChange={() => eventCheckItem(item)}
                                    className="ladiui size-checkbox" />{item.name}</label>
                            </li>)}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </>;
}

export default LadiDropdownMultiSelect;
