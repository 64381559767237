import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.LIST_INTEGRATE_APP,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const create = (data, cb) => ({
    type: types.CREATE_INTEGRATE_APP,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const show = (id) => ({
    type: types.SHOW_INTEGRATE_APP,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
    },
    hasSubAction: true,
});

const update = (data, cb) => ({
    type: types.UPDATE_INTEGRATE_APP,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const updateStatus = (data, cb) => ({
    type: types.UPDATE_STATUS_INTEGRATE_APP,
    meta: {
        endpoint: endpoint.UPDATE_STATUS_INTEGRATE_APP,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteIntegrateApp = (data, cb) => ({
    type: types.DELETE_INTEGRATE_APP,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_DELETE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const integrateAppHaravan = (data, cb) => ({
    type: types.INTEGRATE_APP_HARAVAN,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_HARAVAN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

const integrateAppSapo = (data, cb) => ({
    type: types.INTEGRATE_APP_SAPO,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_SAPO,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

const getAccessSapo = (data, cb) => ({
    type: types.GET_ACCESS_SAPO,
    meta: {
        endpoint: endpoint.GET_ACCESS_SAPO,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

const integrateAppNhanh = (data, cb) => ({
    type: types.INTEGRATE_APP_NHANH,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_NHANH,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

const getAccessNhanh = (data, cb) => ({
    type: types.GET_ACCESS_NHANH,
    meta: {
        endpoint: endpoint.GET_ACCESS_NHANH,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

const integrateAppShopify = (data, cb) => ({
    type: types.INTEGRATE_APP_SHOPIFY,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_SHOPIFY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

const getAccessShopify = (data, cb) => ({
    type: types.GET_ACCESS_SHOPIFY,
    meta: {
        endpoint: endpoint.GET_ACCESS_SHOPIFY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

const integrateAppShopifyWebhook = (data, cb) => ({
    type: types.INTEGRATE_APP_SHOPIFY_WEBHOOK,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_SHOPIFY_WEBHOOK,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

const integrateAppGetflyWebhook = (data, cb) => ({
    type: types.INTEGRATE_APP_GETFLY_WEBHOOK,
    meta: {
        endpoint: endpoint.INTEGRATE_APP_GETFLY_WEBHOOK,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

export default {
    list,
    create,
    show,
    update,
    updateStatus,
    delete: deleteIntegrateApp,
    integrateAppHaravan,
    integrateAppSapo,
    getAccessSapo,
    integrateAppNhanh,
    getAccessNhanh,
    integrateAppShopify,
    getAccessShopify,
    integrateAppShopifyWebhook,
    integrateAppGetflyWebhook,
};
