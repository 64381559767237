export const LIST_COMMISSION = 'LIST_COMMISSION';
export const CREATE_COMMISSION = 'CREATE_COMMISSION';
export const SHOW_COMMISSION = 'SHOW_COMMISSION';
export const UPDATE_COMMISSION = 'UPDATE_COMMISSION';
export const UPDATE_STATUS_COMMISSION = 'UPDATE_STATUS_COMMISSION';
export const BACK_TO_PENDING_STATUS_COMMISSION = 'BACK_TO_PENDING_STATUS_COMMISSION';
export const DELETE_COMMISSION = 'DELETE_COMMISSION';
export const EXPORT_COMMISSION = 'EXPORT_COMMISSION';
export const EXPORT_CONFIRM_COMMISSION = 'EXPORT_CONFIRM_COMMISSION';
export const DELETE_MULTI_COMMISSION = 'DELETE_MULTI_COMMISSION';
export const ACCEPT_MULTI_COMMISSION = 'ACCEPT_MULTI_COMMISSION';
export const REJECT_MULTI_COMMISSION = 'REJECT_MULTI_COMMISSION';
export const INVOICE_MULTI_COMMISSION = 'INVOICE_MULTI_COMMISSION';
export const GROUP_INVOICED_MULTI_COMMISSION = 'GROUP_INVOICED_MULTI_COMMISSION';
export const CONFIRM_MULTI_COMMISSION = 'CONFIRM_MULTI_COMMISSION';
export const APPROVE_COMMISSION = 'APPROVE_COMMISSION';
export const INVOICE_COMMISSION = 'INVOICE_COMMISSION';
export const CANCEL_COMMISSION = 'CANCEL_COMMISSION';
export const CONFIRM_COMMISSION = 'CONFIRM_COMMISSION';
export const RECHECK_COMMISSION = 'RECHECK_COMMISSION';
export const TOP_COMMISSION = 'TOP_COMMISSION';
export const TOP_TOTAL_COMMISSION = 'TOP_TOTAL_COMMISSION';
