import React, { useEffect } from 'react';
import {
    withRouter, Route, Switch, Redirect, useLocation,
} from 'react-router-dom';
import { map } from 'lodash';
import { createBrowserHistory } from 'history';
import routes from '../routes';
import config from '../config/config';
import appConfig from '../config/app';
import BaseHelper from '../helpers/BaseHelper';

function LayoutPublisher(props) {
    const scopes = 'PUBLISHER';
    const search = useLocation().search;
    const availableRoutes = routes.getRoutesByScopes(scopes);
    const paths = window.location.pathname.split('/');

    const history = createBrowserHistory({
        basename: paths[1],
    });

    useEffect(() => {
        let refAffiliate = new URLSearchParams(search).get('ref');
        if (refAffiliate) {
            BaseHelper.setCookie(appConfig.COOKIE.REF, refAffiliate, 90);
        }
    }, [search]);

    return config.APP_AFFILIATE && window.location.origin === config.AFFILIATE_DOMAIN
        ? <>
            <section className="ladiui wrapper">
                <Switch>
                    {
                        map(availableRoutes, (route, index) => <Route history={history} key={index} path={route.path} exact={route.exact} component={route.component} />)
                    }
                    <Route history={history} render={() => <Redirect to='/' />} />
                </Switch>
            </section>
        </>
        : <>
            <section className="ladiui wrapper">
                <Switch>
                    {
                        map(availableRoutes, (route, index) => <Route key={index} path={route.path} exact={route.exact} component={route.component} />)
                    }
                    <Route render={() => <Redirect to='/' />} />
                </Switch>
            </section>
        </>;
}

export default withRouter((LayoutPublisher));
