import Highcharts from 'highcharts';
import BaseHelper from '../../helpers/BaseHelper';

export default {
    options: {
        chart: {
            type: 'spline',
        },
        title: {
            text: null,
        },
        tooltip: {
            formatter() {
                return `${BaseHelper.formatStrToDate(this.x, 'DD/MM/YYYY')}<br/>${this.series.name}: <b>${BaseHelper.formatNumber(this.y)}</b>`;
            },
        },
        xAxis: {
            title: {
                text: null,
            },
            type: 'datetime',
            labels: {
                formatter() {
                    return Highcharts.dateFormat('%e/%b', this.value);
                },
            },
        },
        yAxis: [
            {
                labels: {
                    format: '{value}',
                    style: {
                        color: '#000000',
                    },
                },
                title: {
                    text: '',
                    style: {
                        color: '#000000',
                    },
                },

            },
            {
                labels: {
                    formatter() {
                        let pref = '';
                        let value = this.value;
                        if (Math.abs(value / 1000000) > 1) {
                            value /= 1000000;
                            pref = 'M';
                        }

                        if (value >= 0) {
                            return `${value} ${pref}`;
                        }
                        return `-${-value} ${pref}`;
                    },
                    style: {
                        color: '#000000',
                    },
                },
                title: {
                    text: '',
                    style: {
                        color: '#000000',
                    },
                },
                opposite: true,

            },
        ],
        legend: {
            align: 'left',
            verticalAlign: 'top',
            width: 650,
            itemMarginBottom: 14,
        },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
        },
        time: {
            timezoneOffset: 0,
        },
        credits: {
            enabled: false,
        },
        series: [],
    },
    lang: {
        months: [
            'Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4',
            'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8',
            'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12',
        ],
        shortMonths: [
            '1', '2', '3', '4',
            '5', '6', '7', '8',
            '9', '10', '11', '12',
        ],
        weekdays: [
            'Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5',
            'Thứ 6', 'Thứ 7',
        ],
    },
};
