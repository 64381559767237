/* eslint-disable no-restricted-syntax */
import React from 'react';

import { map } from 'lodash';
import { Redirect, Route, Switch } from 'react-router-dom';

import { connect } from 'react-redux';
import Footer from './Footer';
import SideBar from './Sidebar';
import Topbar from './Topbar';
import routes from '../../routes';
import config from '../../config/config';

import baseHelper from '../../helpers/BaseHelper';
import LoadingScene from '../../components/LoadingScene';
baseHelper.removeCookie('SET_CUSTOM_SCRIPT');

class AppLayout extends React.Component {
    // eslint-disable-next-line class-methods-use-this
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.store.affiliateDashboardSetting) {
            if (prevProps.store.affiliateDashboardSetting.custom_script != this.state) {
                const _customScript = this.props.store.affiliateDashboardSetting.custom_script;
                var script = document.createRange().createContextualFragment(_customScript);     
                const isSetCustomScript = baseHelper.getCookie('SET_CUSTOM_SCRIPT');                
                if (!isSetCustomScript && config.APP_AFFILIATE)  {
                    document.body.appendChild(script);
                    baseHelper.setCookie('SET_CUSTOM_SCRIPT', true, 86400);
                }
            }
        }

        window.LadiUI.init();
    }

    onOkNotification = () => {
        baseHelper.setCookie('NOTIFICATION_CREDIT', true, 86400);
        window.LadiUI.closeModalById('confirm-notification-credit');
        window.LadiChat.push([['do', 'chat:open']]);
    }

    openNotification = () => {
        setTimeout(() => {
            window.LadiUI.showModalById('confirm-notification-credit');
        }, 2000);
    }

    render() {
        const { userInfo } = this.props.store;
        const { scopes, store } = this.props.store.userInfo;
        const availableRoutes = routes.getRoutesByScopes(scopes);
        const notificationCredit = baseHelper.getCookie('NOTIFICATION_CREDIT');
        if (!notificationCredit && store && (store.app_credit <= 30)) {
            if (window.LadiUI) {
                this.openNotification();
            }
        }

        return (
            <>
                <div className="ladi-popupx-inline" data-ladipage-id="66418f519ae79400116994ca" data-align="center"
                     data-scrolling="no"></div>

                <section className="ladiui wrapper">
                    <SideBar scopes={scopes} userInfo={userInfo}/>

                    <div className="ladiui main">
                        <Topbar availableRoutes={availableRoutes}/>

                        <Switch>
                            {
                                map(availableRoutes, (route, index) => <Route key={index} path={route.path}
                                                                              exact={route.exact}
                                                                              component={route.component}/>)
                            }
                            <Route render={() => <Redirect to='/'/>}/>
                        </Switch>
                    </div>
                    <script src="https://w.ladicdn.com/ladichat/sdk.js"
                            id="60bb4ba3d2603b0013018749_65d8106452f9fd002c0efe5b" async=""></script>
                </section>
                {this.props.loadingApi
                    ? <LoadingScene blur={true}/>
                    : <></>
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => {
    let loadingApi = false;
    for (const [, value] of Object.entries(state)) {
        if (value.loading) {
            loadingApi = true;
        }
    }
    return { store: state.store, loadingApi };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
