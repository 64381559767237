import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

function PublisherLogo() {
    const affiliateDashboardSettingPublic = useSelector((state) => (state.store.affiliateDashboardSettingPublic));
    const affiliateDashboardSettingStorePublic = useSelector((state) => (state.store.affiliateDashboardSettingStorePublic));

    const getTopPosition = (height) => {
        if (!height) return -32;

        const pos = height + 32;
        return -pos;
    };

    useEffect(() => {
        if (affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.icon) {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = affiliateDashboardSettingPublic.icon;
        }
    }, [affiliateDashboardSettingPublic]);

    useEffect(() => {
        if (affiliateDashboardSettingStorePublic && affiliateDashboardSettingStorePublic.name) {
            const nameStore = affiliateDashboardSettingStorePublic.name ? affiliateDashboardSettingStorePublic.name : 'Ladishare - Affiliate Marketing Platform';
            let title = document.querySelector('title');
            if (!title) {
                title = document.createElement('title');
                document.getElementsByTagName('head')[0].appendChild(title);
            }
            title.innerText = nameStore;
        }
    }, [affiliateDashboardSettingStorePublic]);
    return <>
        {affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.logo
            ? < div className="box-logo"
                style={{ top: getTopPosition(affiliateDashboardSettingPublic.logo_height) }}>
                <img src={affiliateDashboardSettingPublic.logo} height={affiliateDashboardSettingPublic.logo_height || '32px'}
                    width={affiliateDashboardSettingPublic.logo_width || 'auto'} alt="" />
            </div>
            : <></>
        }
    </>;
}

export default PublisherLogo;
