import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../../../assets/css/custom.module.css';

function PublisherLeft() {
    const affiliateDashboardSettingPublic = useSelector((state) => (state.store.affiliateDashboardSettingPublic));

    const resizeIframe = () => {
        const iframe = document.getElementById('iframe-content');
        iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight + 40}px`;
    };

    return <>
        <div className="box-publisher-left">
            {
                affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.greeting_title
                    ? <div className="box-title">{affiliateDashboardSettingPublic.greeting_title}</div> : <></>
            }
            <div className="box-description">
                {/* <div dangerouslySetInnerHTML={{
                    __html: affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.greeting_contents
                        ? affiliateDashboardSettingPublic.greeting_contents : '',
                }}></div> */}
                <iframe id="iframe-content" className={styles.iframe}
                    onLoad={resizeIframe}
                    srcDoc={`${`<!DOCTYPE html><html><head><meta charset="UTF-8"><style>body{font-family:Roboto, sans-serif; }</style></head><body>${affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.greeting_contents
                        ? affiliateDashboardSettingPublic.greeting_contents : ''}</body></html>`}`} />
            </div>
        </div>
    </>;
}

export default PublisherLeft;
