import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import appConfig, { status } from '../../config/app';
import PublisherFooter from './PublisherFooter';
import actionPublisher from '../../redux/futures/publisher/actions';
import BaseHelper from '../../helpers/BaseHelper';
import config from '../../config/config';
import actionStore from '../../redux/futures/store/actions';
import PublisherLeft from './components/PublisherLeft';
import PublisherLogo from './components/PublisherLogo';

function Login(props) {
    const { t } = props;
    const store = useParams();

    const dispatch = useDispatch();
    const [login, setLogin] = useState({ ...store, url: window.location.origin });

    const [isAffiliateDomain, setIsAffiliateDomain] = useState(false);

    const cb = () => {
        const response = cb.response;
        if (response.data.code === status.OK) {
            const res = response.data.data;
            BaseHelper.setCookie(appConfig.COOKIE.SSID, res.token);
            BaseHelper.setCookie(appConfig.COOKIE.STORE_ID, res.store_id);
            BaseHelper.setCookie(appConfig.COOKIE.STORE_ALIAS, res.store_alias);
            window.location.href = `/${store.store}/`;
        } else {
            window.LadiUI.toast('danger', null, response.data.message, 5000, 'bottom-left');
        }
    };

    const eventLogin = () => {
        dispatch(actionPublisher.publisherLogin(login, cb));
    };

    const eventKeyDownInput = (e) => {
        if (e.keyCode === 13) eventLogin();
    };

    useEffect(() => {
        BaseHelper.removeCookie(appConfig.COOKIE.STORE_ALIAS);
        const ssid = BaseHelper.getCookie(appConfig.COOKIE.SSID);
        if (ssid) {
            window.location.href = '/';
        } else {
            const data = {
                ...store,
                url: window.location.origin,
                field: 'affiliate_dashboard_setting',
            };
            dispatch(actionStore.getAffiliateDashboardSettingPublic(data));
        }

        let domainUrl = window.location.href;
        if (domainUrl.includes('affiliate.ladipage.com') || domainUrl.includes('aff.dev.ladishare.com')) {
            setIsAffiliateDomain(true);
        }
    }, []);

    return <>
        <div className="publisher-container">

            <div className="box-publisher">
                <PublisherLogo />
                <PublisherLeft />
                <div className="box-publisher-right">
                    <div className="box-title">{t('PUBLISHER.LOGIN')}</div>
                    {
                        isAffiliateDomain
                        ?
                        <div className="box-sub-title">Tài khoản quản trị khách hàng của LadiPage Affiliate Publisher.</div>
                            :
                            <div className="box-sub-title">Nhập thông tin để đăng nhập vào tài khoản.</div>
                    }
                    <form action="#">
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2">Địa chỉ email</label>
                            <input type="email" className="ladiui form-control" placeholder="Nhập địa chỉ email"
                                required autoFocus
                                onKeyDown={(e) => eventKeyDownInput(e)}
                                value={login.email || ''}
                                onChange={(e) => setLogin({ ...login, email: e.target.value })}
                            />
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2">Mật khẩu</label>
                            <input type="password" className="ladiui form-control" placeholder="Nhập mật khẩu"
                                required
                                onKeyDown={(e) => eventKeyDownInput(e)}
                                value={login.password || ''}
                                onChange={(e) => setLogin({ ...login, password: e.target.value })}
                            />
                        </div>
                        <Link className="box-forgot-password" to={config.APP_AFFILIATE && origin !== config.AFFILIATE_DOMAIN ? 'forgot-password' : `/${store.store}/forgot-password`}>Quên mật khẩu?</Link>
                        <div className="ladiui form-group">
                            <button type="button" className="ladiui btn btn-primary" onClick={() => eventLogin()}>{t('PUBLISHER.LOGIN')}</button>
                        </div>
                        {
                            isAffiliateDomain
                                ?
                                <>
                                    <div className="ladiui form-group">
                                        <p style={{ whiteSpace: "nowrap" }}>Bạn chưa đăng ký trở thành Affiliate của LadiPage?</p>
                                        <a href={`https://ladipage.vn/affiliate?utm_source=nopaid-0-affladipage-ldp-tranglogin-dangkyaffiliate-all`}
                                           target="_blank" className="ladiui link"> Đăng ký ngay</a>
                                    </div>
                                </>
                                :
                                <div className="ladiui form-group">
                                    Bạn chưa có tài khoản?
                                    <Link
                                        to={config.APP_AFFILIATE && origin !== config.AFFILIATE_DOMAIN ? '/register' : `/${store.store}/register`}
                                        className="ladiui link">Đăng ký ngay</Link>
                                </div>
                        }
                    </form>

                </div>

                <PublisherFooter/>
            </div>

        </div>
    </>;
}

export default (withTranslation()(Login));
