import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Campaign from './components/Campaign';
import DailyReport from './components/DailyReport';
import Invoice from './components/Invoice';
import TopAffiliate from './components/TopAffiliate';
import config from '../../config/config';
import PublisherReport from './components/PublisherReport';

function Report(props) {
    const { t } = props;
    const [tabShow, setTabShow] = useState('DAILY_REPORT');

    return !config.APP_AFFILIATE
        ? <>
            <div className="ladiui content-main-full">
                <div className="ladiui action-history" style={{ marginTop: '28px' }}>
                    <ul className="ladiui nav nav-tabs new-tab-custom" id="tab-detail-affiliate" role="tablist">
                        <li className="ladiui nav-item" role="presentation" onClick={() => setTabShow('DAILY_REPORT')}>
                            <span className="ladiui tab-link active" id="tab-daily-report-tab" data-toggle="tab"
                                data-parent="myTab" data-target="tab-daily-report">Báo cáo ngày</span>
                        </li>
                        <li className="ladiui nav-item" role="presentation" onClick={() => setTabShow('TOP_AFFILIATE')}>
                            <span className="ladiui tab-link" id="tab-affiliate-tab" data-toggle="tab" data-parent="myTab"
                                data-target="tab-affiliate">Top Affiliate</span>
                        </li>
                        <li className="ladiui nav-item" role="presentation" onClick={() => setTabShow('CAMPAIGN')} style={{ display: 'none' }}>
                            <span className="ladiui tab-link" id="tab-campaign-tab" data-toggle="tab" data-parent="myTab"
                                data-target="tab-campaign">Chiến dịch</span>
                        </li>
                        <li className="ladiui nav-item" role="presentation" onClick={() => setTabShow('INVOICE')}>
                            <span className="ladiui tab-link" id="tab-commission-tab" data-toggle="tab" data-parent="myTab"
                                data-target="tab-commission">Doanh thu</span>
                        </li>
                    </ul>
                    <div className="ladiui tab-content" id="tab-detail-affiliate-content">
                        <div className="ladiui tab-pane fade show open" id="tab-daily-report" role="tabpanel"
                            aria-labelledby="tab-daily-report-tab">
                            <div className="ladiui">
                                {tabShow === 'DAILY_REPORT'
                                    ? <DailyReport />
                                    : <></>}
                            </div>
                        </div>
                        <div className="ladiui tab-pane fade" id="tab-affiliate" role="tabpanel"
                            aria-labelledby="tab-affiliate-tab">
                            <div className="ladiui">
                                {tabShow === 'TOP_AFFILIATE'
                                    ? <TopAffiliate />
                                    : <></>}
                            </div>
                        </div>
                        <div className="ladiui tab-pane fade" id="tab-campaign" role="tabpanel" style={{ display: 'none' }}
                            aria-labelledby="tab-campaign-tab">
                            <div className="ladiui">
                                {tabShow === 'CAMPAIGN'
                                    ? <Campaign />
                                    : <></>}
                            </div>
                        </div>
                        <div className="ladiui tab-pane fade" id="tab-commission" role="tabpanel"
                            aria-labelledby="tab-commission-tab">
                            <div className="ladiui">
                                {tabShow === 'INVOICE'
                                    ? <Invoice />
                                    : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        : <><PublisherReport /></>;
}

export default (withTranslation()(Report));
