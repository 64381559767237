/* eslint-disable max-lines-per-function */
import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_INTEGRATE_APP: {
            return {
                ...state,
                action: types.LIST_INTEGRATE_APP,
                integrateApps: action.status ? { items: action.payload.items, total: action.payload.total } : { items: [], total: 0 },
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_INTEGRATE_APP: {
            return {
                ...state,
                action: types.CREATE_INTEGRATE_APP,
                staff: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_INTEGRATE_APP: {
            return {
                ...state,
                action: types.SHOW_INTEGRATE_APP,
                staff: action.status ? action.payload.staff : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_INTEGRATE_APP: {
            return {
                ...state,
                action: types.UPDATE_INTEGRATE_APP,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_STATUS_INTEGRATE_APP: {
            return {
                ...state,
                action: types.UPDATE_STATUS_INTEGRATE_APP,
                status: action.status,
                message: action.message,
            };
        }


        case types.DELETE_INTEGRATE_APP: {
            return {
                ...state,
                action: types.DELETE_INTEGRATE_APP,
                status: action.status,
                message: action.message,
            };
        }

        case types.INTEGRATE_APP_HARAVAN: {
            return {
                ...state,
                urlHaravan: action.status ? action.payload : null,
                action: types.INTEGRATE_APP_HARAVAN,
                status: action.status,
                message: action.message,
            };
        }

        case types.INTEGRATE_APP_SAPO: {
            return {
                ...state,
                urlSapo: action.status ? action.payload : null,
                action: types.INTEGRATE_APP_SAPO,
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_ACCESS_SAPO: {
            return {
                ...state,
                sapoAccess: action.status ? action.payload : null,
                action: types.GET_ACCESS_SAPO,
                status: action.status,
                message: action.message,
            };
        }

        case types.INTEGRATE_APP_NHANH: {
            return {
                ...state,
                urlNhanh: action.status ? action.payload : null,
                action: types.INTEGRATE_APP_NHANH,
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_ACCESS_NHANH: {
            return {
                ...state,
                nhanhAccess: action.status ? action.payload : null,
                action: types.GET_ACCESS_NHANH,
                status: action.status,
                message: action.message,
            };
        }

        case types.INTEGRATE_APP_SHOPIFY: {
            return {
                ...state,
                urlShopify: action.status ? action.payload : null,
                action: types.INTEGRATE_APP_SHOPIFY,
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_ACCESS_SHOPIFY: {
            return {
                ...state,
                shopifyAccess: action.status ? action.payload : null,
                action: types.GET_ACCESS_SHOPIFY,
                status: action.status,
                message: action.message,
            };
        }

        case types.INTEGRATE_APP_SHOPIFY_WEBHOOK: {
            return {
                ...state,
                webhookUrlShopify: action.status ? action.payload : null,
                action: types.INTEGRATE_APP_SHOPIFY_WEBHOOK,
                status: action.status,
                message: action.message,
            };
        }

        case types.INTEGRATE_APP_GETFLY_WEBHOOK: {
            return {
                ...state,
                webhookUrlGetfly: action.status ? action.payload : null,
                action: types.INTEGRATE_APP_GETFLY_WEBHOOK,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
