import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BaseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import actions from '../../../redux/futures/activity/actions';
import DefineConst from '../../commissions/DefineConst';

// eslint-disable-next-line max-lines-per-function
function HistoryAction(props) {
    const { t } = props;
    const moment = BaseHelper.getMoment();

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        date_from: moment().startOf('month').format('YYYY-MM-DD 00:00:01'),
        date_to: moment().format('YYYY-MM-DD 23:59:59'),
    });
    const activities = useSelector((state) => state.activities.activities);

    const getNameOwner = (item) => {
        let name = 'Hệ thống';
        if (item && item.staff) {
            name = [item.staff.first_name, item.staff.last_name].join(' ');
            if (name.trim() === '') {
                name = 'Hệ thống';
            }
        }
        return name;
    };

    const renderActivity = (item) => {
        const data = JSON.parse(item.data);
        switch (item.action) {
            case 'COMMISSION_CREATE':
                return <>
                    <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiui/icons/ldicon-payment.svg" /></div>
                    <div className="content">
                        <label>{getNameOwner(item)}</label>
                        <div>
                            Hoa hồng mới
                        </div>
                    </div>
                    <div className="time">{moment(item.created_at).startOf('minute').fromNow()}</div>
                </>;
            case 'COMMISSION_CONFIRM':
                return <>
                    <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiui/icons/ldicon-payment.svg" /></div>
                    <div className="content">
                        <label>{getNameOwner(item)}</label>
                        <div>
                            Xác nhận hoa hồng
                        </div>
                    </div>
                    <div className="time">{moment(item.created_at).startOf('minute').fromNow()}</div>
                </>;

            case 'COMMISSION_RECHECK':
                return <>
                    <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiui/icons/ldicon-payment.svg" /></div>
                    <div className="content">
                        <label>{getNameOwner(item)}</label>
                        <div>
                            Yêu cầu kiểm tra hoa hồng
                        </div>
                    </div>
                    <div className="time">{moment(item.created_at).startOf('minute').fromNow()}</div>
                </>;

            case 'COMMISSION_UPDATE_STATUS':
                return <>
                    <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiui/icons/ldicon-payment.svg" /></div>
                    <div className="content">
                        <label>{getNameOwner(item)}</label>
                        <div>
                            Chuyển trạng thái hoa hồng
                        </div>
                    </div>
                    <div className="time">{moment(item.created_at).startOf('minute').fromNow()}</div>
                </>;

            case 'AFFILIATE_CREATE':
                return <>
                    <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                    <div className="content">
                        <label>{getNameOwner(item)}</label>
                        <div>
                            Affiliate mới <a className="ladiui link" href={`/affiliates/${item.object_id}`}>{item.object_name}</a>
                        </div>
                    </div>
                    <div className="time">{moment(item.created_at).startOf('minute').fromNow()}</div>
                </>;

            case 'AFFILIATE_UPDATE':
            case 'AFFILIATE_UPDATE_OTHER':
                return <>
                    <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                    <div className="content">
                        <label>{getNameOwner(item)}</label>
                        <div>
                            Cập nhật thông tin <a className="ladiui link" href={`/affiliates/${item.object_id}`}>{item.object_name}</a>
                        </div>
                    </div>
                    <div className="time">{moment(item.created_at).startOf('minute').fromNow()}</div>
                </>;

            case 'AFFILIATE_RESET_PASSWORD':
                return <>
                    <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                    <div className="content">
                        <label>{getNameOwner(item)}</label>
                        <div>
                            Reset mật khẩu
                        </div>
                    </div>
                    <div className="time">{moment(item.created_at).startOf('minute').fromNow()}</div>
                </>;

            case 'AFFILIATE_CREATE_INVOICE_REQUEST':
                return <>
                    <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiui/icons/ldicon-payment2.svg" /></div>
                    <div className="content">
                        <label>{getNameOwner(item)}</label>
                        <div>
                            Tạo yêu cầu rút tiền
                        </div>
                    </div>
                    <div className="time">{moment(item.created_at).startOf('minute').fromNow()}</div>
                </>;

            case 'SEND_MAIL_CONFIRM':
                return <>
                    <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiui/icons/ldicon-mail.svg" /></div>
                    <div className="content">
                        <label>{getNameOwner(item)}</label>
                        <div>
                            <p>Gửi email yêu cầu đối soát hoa hồng cho hoá đơn <a href="#/" className="ladiui link text-uppercase">#{item.object_name}</a></p>
                        </div>
                    </div>
                    <div className="time">{moment(item.created_at).startOf('minute').fromNow()}</div>
                </>;

            case 'CREATE_INVOICE':
                return <>
                    <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg" /></div>
                    <div className="content">
                        <label>{getNameOwner(item)}</label>
                        <div>
                            <p>Tạo hoá đơn cho affiliate</p>
                        </div>
                    </div>
                    <div className="time">{moment(item.created_at).startOf('minute').fromNow()}</div>
                </>;
            default:
        }
    };
    useEffect(() => {
        dispatch(actions.list(conditions));
    }, []);
    return <>
        <div className="ladiui box-statistics box-no-padding box-no-heigh" style={{ display: 'block' }}>
            <div className="ladiui box-title">
                <label className="ladiui">Hoạt động</label>
            </div>
            <div className="box-body" style={{ minHeight: '100px', maxHeight: '399px', overflowY: 'auto' }}>
                <ul className="history-action">
                    {activities && activities.items && activities.items.length > 0 ? activities.items.map((item) => <li key={item._id}>
                        {renderActivity(item)}
                    </li>)
                        : <></>}
                </ul>
            </div>
            <div className="box-footer"></div>
        </div>
    </>;
}
export default (withTranslation()(HistoryAction));
