import React, { useEffect } from 'react';

import { isEmpty } from 'lodash';

function NhanhPostMessageIntegrate() {
    useEffect(() => {
        const url = new URL(window.location.href);
        const code = url.searchParams.get('accessCode');
        const data = {
            code,
        };

        try {
            if (window.opener) {
                if (!isEmpty(code)) {
                    window.opener.postMessage(data, `${window.location.protocol}//${window.location.hostname}`);
                }

                window.close();
            } else {
                window.location.href = '/apps';
            }
        } catch (e) {
            // no code
        }
    }, []);

    return <></>;
}

export default (NhanhPostMessageIntegrate);
