import * as types from './types';
import * as commonTypes from '../common/types';

// eslint-disable-next-line max-lines-per-function
export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_AFFILIATE: {
            return {
                ...state,
                action: types.LIST_AFFILIATE,
                affiliates: action.status ? {
                    items: action.payload.items, total: action.payload.total, summary: action.payload.summary, findGroups: action.payload.findGroups,
                } : {
                    items: [], total: 0, summary: {}, findGroups: [],
                },
                status: action.status,
                message: action.message,
            };
        }

        case types.AFFILIATE_LIST_FILTER: {
            return {
                ...state,
                action: types.AFFILIATE_LIST_FILTER,
                filterAffiliates: action.status ? { items: action.payload.items, total: action.payload.total, summary: action.payload.summary } : { items: [], total: 0, summary: {} },
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_AFFILIATE: {
            return {
                ...state,
                action: types.CREATE_AFFILIATE,
                affiliate: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_AFFILIATE: {
            return {
                ...state,
                action: types.SHOW_AFFILIATE,
                affiliate: action.status ? action.payload.affiliate : {},
                statistics: action.status ? action.payload.statistics : {},
                refAffiliate: action.status ? action.payload.refAffiliate : null,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_AFFILIATE: {
            return {
                ...state,
                action: types.UPDATE_AFFILIATE,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_AFFILIATE: {
            return {
                ...state,
                action: types.DELETE_AFFILIATE,
                status: action.status,
                message: action.message,
            };
        }

        case types.EXPORT_AFFILIATE: {
            return {
                ...state,
                action: types.EXPORT_AFFILIATE,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_MULTI_AFFILIATE: {
            return {
                ...state,
                action: types.DELETE_MULTI_AFFILIATE,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_STATUS_MULTI_AFFILIATE: {
            return {
                ...state,
                action: types.UPDATE_STATUS_MULTI_AFFILIATE,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_OTHER_AFFILIATE: {
            return {
                ...state,
                action: types.UPDATE_OTHER_AFFILIATE,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_MULTI_AFFILIATE: {
            return {
                ...state,
                action: types.UPDATE_MULTI_AFFILIATE,
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_LEVEL_AFFILIATE: {
            return {
                ...state,
                action: types.SHOW_AFFILIATE,
                levelAffiliate: action.status ? action.payload.level : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.IMPORT_AFFILIATE: {
            return {
                ...state,
                action: types.IMPORT_AFFILIATE,
                importAffiliateResult: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.AFFILIATE_PASSED_INVOICE: {
            return {
                ...state,
                action: types.AFFILIATE_PASSED_INVOICE,
                passedAffiliate: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_MULTI_AFFILIATE_INVOICE_MONTH: {
            return {
                ...state,
                action: types.CREATE_MULTI_AFFILIATE_INVOICE_MONTH,
                status: action.status,
                message: action.message,
            };
        }

        case types.LIST_AFFILIATE_REF: {
            return {
                ...state,
                action: types.LIST_AFFILIATE_REF,
                refAffiliates: action.status ? { items: action.payload.items, total: action.payload.total } : { items: [], total: 0 },
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_USER_DATA: {
            return {
                ...state,
                action: types.UPDATE_USER_DATA,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
