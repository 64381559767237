export const LIST_STORE = 'LIST_STORE';
export const DELETE_STORES = 'DELETE_STORES';
export const DELETE_STORE = 'DELETE_STORE';
export const SHOW_STORE = 'SHOW_STORE';
export const CREATE_STORE = 'CREATE_STORE';
export const UPDATE_STORE = 'UPDATE_STORE';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_API_KEY_INFO = 'GET_API_KEY_INFO';
export const RE_CREATE_API_KEY = 'RE_CREATE_API_KEY';
export const APPLY_DEFAULT_ADDRESS_STORE = 'APPLY_DEFAULT_ADDRESS_STORE';
export const CREATE_NEW_IP = 'CREATE_NEW_IP';
export const DELETE_IP = 'DELETE_IP';
export const UPDATE_AFFILIATE_SETTING = 'UPDATE_AFFILIATE_SETTING';
export const GET_AFFILIATE_SETTING = 'GET_AFFILIATE_SETTING';
export const LIST_SENDER_EMAIL = 'LIST_SENDER_EMAIL';
export const CREATE_SENDER_EMAIL = 'CREATE_SENDER_EMAIL';
export const UPDATE_SENDER_EMAIL = 'UPDATE_SENDER_EMAIL';
export const SHOW_SENDER_EMAIL = 'SHOW_SENDER_EMAIL';
export const DELETE_SENDER_EMAIL = 'DELETE_SENDER_EMAIL';
export const MAKE_AS_DEFAULT_SENDER_EMAIL = 'MAKE_AS_DEFAULT_SENDER_EMAIL';
export const VERIFY_SENDER_EMAIL = 'VERIFY_SENDER_EMAIL';
export const VERIFY_MULTI_SENDER_EMAIL = 'VERIFY_MULTI_SENDER_EMAIL';
export const DELETE_MULTI_SENDER_EMAIL = 'DELETE_MULTI_SENDER_EMAIL';
export const GET_AFFILIATE_DASHBOARD_SETTING = 'GET_AFFILIATE_DASHBOARD_SETTING';
export const GET_AFFILIATE_DASHBOARD_SETTING_PUBLIC = 'GET_AFFILIATE_DASHBOARD_SETTING_PUBLIC';
export const GET_AFFILIATE_SETTING_PUBLIC = 'GET_AFFILIATE_SETTING_PUBLIC';
export const UPLOAD_LOGO_STORE = 'UPLOAD_LOGO_STORE';
export const UPLOAD_ICON_STORE = 'UPLOAD_ICON_STORE';
export const UPDATE_AFFILIATE_DASHBOARD_SETTING = 'UPDATE_AFFILIATE_DASHBOARD_SETTING';
export const VERIFY_DOMAIN_AFFILIATE = 'VERIFY_DOMAIN_AFFILIATE';
export const GET_BLOCK_IP_SETTING = 'GET_BLOCK_IP_SETTING';
export const UPDATE_BLOCK_IP_SETTING = 'UPDATE_BLOCK_IP_SETTING';
export const GET_AFFILIATE_CHANGE_GROUP_SETTING = 'GET_AFFILIATE_CHANGE_GROUP_SETTING';
export const UPDATE_AFFILIATE_CHANGE_GROUP_SETTING = 'UPDATE_AFFILIATE_CHANGE_GROUP_SETTING';
export const GET_TEMPLATE_EMAIL = 'GET_TEMPLATE_EMAIL';
export const UPDATE_TEMPLATE_EMAIL = 'UPDATE_TEMPLATE_EMAIL';
export const GET_COOKIE_SETTING = 'GET_COOKIE_SETTING';
export const SET_COOKIE_SETTING = 'SET_COOKIE_SETTING';
export const GET_REQUEST_WITHDRAW_SETTING = 'GET_REQUEST_WITHDRAW_SETTING';
