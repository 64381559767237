import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data, cb) => ({
    type: types.LIST_AFFILIATE_GROUP,
    meta: {
        endpoint: endpoint.AFFILIATE_GROUP_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const create = (data, cb, enableNotify = false) => ({
    type: types.CREATE_AFFILIATE_GROUP,
    meta: {
        endpoint: endpoint.AFFILIATE_GROUP_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: enableNotify,
        cb,
    },
});

const show = (id, cb) => ({
    type: types.SHOW_AFFILIATE_GROUP,
    meta: {
        endpoint: endpoint.AFFILIATE_GROUP_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const update = (data, cb) => ({
    type: types.UPDATE_AFFILIATE_GROUP,
    meta: {
        endpoint: endpoint.AFFILIATE_GROUP_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const deleteAffiliateGroup = (data, cb) => ({
    type: types.DELETE_AFFILIATE_GROUP,
    meta: {
        endpoint: endpoint.AFFILIATE_GROUP_DELETE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteMultiAffiliateGroup = (data, cb) => ({
    type: types.DELETE_MULTI_AFFILIATE_GROUP,
    meta: {
        endpoint: endpoint.DELETE_MULTI_AFFILIATE_GROUP,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const makeAsDefault = (data, cb) => ({
    type: types.MAKE_AS_DEFAULT_AFFILIATE_GROUP,
    meta: {
        endpoint: endpoint.AFFILIATE_GROUP_MAKE_AS_DEFAULT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

export default {
    list,
    create,
    show,
    update,
    delete: deleteAffiliateGroup,
    deleteMultiAffiliateGroup,
    makeAsDefault,
};
