import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal';
import LadiPagination from '../../../components/LadiPagination';

import appConfig from '../../../config/app';
import config from '../../../config/config';
import BaseHelper from '../../../helpers/BaseHelper';
import actionInvoice from '../../../redux/futures/invoice/actions';
import DefineConst from '../DefineConst';
import CheckAllActionInvoice from './CheckAllActionInvoice';
import ModalInvoiceDetail from './ModalInvoiceDetail';
import ModalNoteInvoice from './ModalNoteInvoice';

// eslint-disable-next-line max-lines-per-function
function ListInvoice(props) {
    const {
        t, conditionsProp, conditionActionProp, selectedIdsProp, setSelectedIdsProp,
    } = props;

    const dispatch = useDispatch();
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const [invoice, setInvoice] = useState();
    const listInvoice = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.invoices.invoices }));
    const affiliates = useSelector((state) => (state.invoices.affiliates));

    const cbLoadList = () => {
        dispatch(actionInvoice.list(conditionsProp));
    };

    const selectLimit = (number) => {
        conditionActionProp({ limit: number, page: 1 });
    };

    const selectPage = (number) => {
        conditionActionProp({ page: number });
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
        setSelectedIdsProp(ids);
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
        setSelectedIdsProp(ids);
    };

    const eventDetailInvoice = (item) => {
        setInvoice(item);
        window.LadiUI.showModal('modal-detail-invoice');
    };

    const eventUpdateStatusInvoice = (data) => {
        dispatch(actionInvoice.updateStatus(data, cbLoadList));
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.INVOICE_STATUS.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-warning"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.PENDING')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.APPROVED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.APPROVED')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.PAID:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.PAID')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.CANCELED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-mute"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.CANCELED')}
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    const eventUpdateStatusApprovedInvoice = (item) => {
        setInvoice(item);
        window.LadiUI.showModal('modal-confirm-approved-invoice');
    };

    const eventUpdateStatusPaidInvoice = (item) => {
        setInvoice(item);
        window.LadiUI.showModal('modal-confirm-paid-invoice');
    };

    const eventUpdateStatusCancelInvoice = (item) => {
        setInvoice(item);
        window.LadiUI.showModal('modal-confirm-cancel-invoice');
    };

    const eventOkUpdateStatusApprovedInvoice = () => {
        eventUpdateStatusInvoice({ status: DefineConst.INVOICE_STATUS.APPROVED, id: invoice._id });
    };

    const eventOkUpdateStatusPaidInvoice = () => {
        eventUpdateStatusInvoice({ status: DefineConst.INVOICE_STATUS.PAID, id: invoice._id });
    };

    const eventOkUpdateStatusCancelInvoice = () => {
        eventUpdateStatusInvoice({ status: DefineConst.INVOICE_STATUS.CANCELED, id: invoice._id });
    };

    const eventReSendMailConfirm = (item) => {
        setInvoice(item);
        window.LadiUI.showModal('modal-resend-mail-confirm-invoice');
    };

    const eventOkResendMailConfirm = () => {
        const data = { id: invoice._id };
        dispatch(actionInvoice.sendMailConfirmInvoice(data, cbLoadList));
    };

    const eventNote = (item) => {
        setInvoice(item);
        window.LadiUI.showModal('modal-note-invoice');
    };

    const eventDownloadCommissionOfInvoice = (item) => {
        dispatch(actionInvoice.exportCommissionInvoice({ id: item._id }));
    };

    const getTotalAfterVat = (item) => {
        const isIncludeVatInvoice = item.is_include_vat || false;
        if (isIncludeVatInvoice) return parseFloat(item.amount || 0);

        return parseFloat(item.amount || 0) - parseFloat(item.tax || 0);
    };

    const getTotalBeforeVat = (item) => {
        const isIncludeVatInvoice = item.is_include_vat || false;
        if (isIncludeVatInvoice) return parseFloat(item.amount || 0) - parseFloat(item.tax || 0);

        return parseFloat(item.amount || 0);
    };

    const renderRecord = (item) => <tr className="ladiui table-vertical main" key={item._id}>
        {!config.APP_AFFILIATE
            ? <td scope="row">
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element"
                    name="checkbox" checked={selectedIds.includes(item._id)}
                    value={item._id} onChange={eventCheck} />
            </td>
            : <></>}

        <td><a href="#/" className="ladiui link"
            style={{ display: 'block', overflow: 'hidden', maxWidth: '120px', textOverflow: 'ellipsis' }}
            onClick={() => eventDetailInvoice(item)}>{item.code || item._id}</a></td>
        <td>
            {affiliates.map((affiliate) => (affiliate._id === item.affiliate_id
                ? config.APP_AFFILIATE
                    ? <div key={affiliate._id}>{affiliate.code}</div>
                    : <Link key={affiliate._id} to={`/affiliates/${affiliate._id}`} className="ladiui link">{affiliate.code}</Link>
                : ''))}
        </td>
        <td>{affiliates.map((affiliate) => (affiliate._id === item.affiliate_id ? affiliate.email : ''))}</td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(getTotalBeforeVat(item))}</td>
        <td className="ladiui text-right">{item.tax !== null ? BaseHelper.formatMoney(item.tax || 0) : 'Chưa tính'}</td>
        <td>{getStatus(item.status)}</td>
        <td>{BaseHelper.formatStrToDate(item.paid_at || null, 'DD/MM/YYYY')}</td>
        <td>{BaseHelper.formatStrToDate(item.created_at || null, 'DD/MM/YYYY')}</td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(getTotalAfterVat(item))}</td>
        <td className="ladiui-dropdown">
            <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                    <button data-toggle="dropdown"
                        className="ladiui-btn-dropdown dropdown-toggle">
                        <i className="ladiui icon icon-ldp-dot"></i>
                    </button>
                    <ul className="ladiui dropdown-menu custom-dropdown-menu"
                        aria-labelledby="dropdownMenuButton1">
                        <li onClick={() => eventDetailInvoice(item)}>
                            <a className="ladiui dropdown-item" href="#/">
                                {t('INVOICES.DETAIL_INVOICE')}
                            </a>
                        </li>
                        {!config.APP_AFFILIATE
                            ? <>
                                {item.status === DefineConst.INVOICE_STATUS.PENDING
                                    ? <li onClick={() => eventUpdateStatusApprovedInvoice(item)}>
                                        <a className="ladiui dropdown-item" href="#/">
                                            {t('INVOICES.ACCEPT_INVOICE')}
                                        </a>
                                    </li> : <></>
                                }
                                {item.status === DefineConst.INVOICE_STATUS.PENDING || item.status === DefineConst.INVOICE_STATUS.APPROVED
                                    ? <li onClick={() => eventUpdateStatusCancelInvoice(item)}>
                                        <a className="ladiui dropdown-item" href="#/">
                                            {t('INVOICES.REFUSE_INVOICE')}
                                        </a>
                                    </li> : <></>
                                }
                                {item.status === DefineConst.INVOICE_STATUS.APPROVED
                                    ? <li onClick={() => eventUpdateStatusPaidInvoice(item)}>
                                        <a className="ladiui dropdown-item" href="#/">
                                            Thanh toán hoá đơn
                                        </a>
                                    </li> : <></>
                                }
                                <li onClick={() => eventNote(item)}>
                                    <a className="ladiui dropdown-item" href="#/">
                                        Ghi chú
                                    </a>
                                </li>
                                <li onClick={() => eventReSendMailConfirm(item)}>
                                    <a className="ladiui dropdown-item" href="#/">
                                        Gửi mail xác nhận đối soát
                                    </a>
                                </li>
                                <li onClick={() => eventDownloadCommissionOfInvoice(item)}>
                                    <a className="ladiui dropdown-item" href="#/">
                                        Tải danh sách hoa hồng
                                    </a>
                                </li>
                            </>
                            : <></>}

                    </ul>
                </div>
            </div>
        </td>
    </tr>;

    useEffect(() => {
        dispatch(actionInvoice.list(conditionsProp));
        setSelectedIds([]);
        setCheckAll(false);
    }, [conditionsProp]);

    useEffect(() => {
        setSelectedIds(selectedIdsProp);
    }, [selectedIdsProp]);

    return <>
        <div className="ladiui mobile-table-scroll">
            <table className="ladiui table text-left" style={{ minWidth: '1050px' }}>
                <thead>
                    <tr className="ladiui table-vertical header">
                        {!config.APP_AFFILIATE
                            ? <th className="ladiui col-first" scope="col">
                                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-invoice"
                                    name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                            </th>
                            : <></>}
                        <th className="col-action" style={{ width: '170px' }}>
                            {selectedIds.length > 0 ? <CheckAllActionInvoice ids={selectedIds} cb={() => cbLoadList()} /> : 'Mã giao dịch'}
                        </th>
                        <th>
                            {t('AFFILIATES.CODE_AFFILIATE')}
                        </th>
                        <th>{t('AFFILIATES.EMAIL')}</th>
                        <th className="ladiui text-right">{t('INVOICES.AMOUNT')}</th>
                        <th scope="col" className="ladiui text-right">{t('INVOICES.TAX')}</th>
                        <th>{t('INVOICES.STATUS')}</th>
                        <th scope="col">{t('INVOICES.PAID_DATE')}</th>
                        <th scope="col">Ngày tạo</th>
                        <th scope="col" className="ladiui text-right">Tổng tiền</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {listInvoice.items.length > 0
                        ? listInvoice.items.map((values) => (renderRecord(values)))
                        : (<tr className="ladiui table-vertical main"><td colSpan={config.APP_AFFILIATE ? 6 : 7} className="text-center"><div>{t('INVOICES.NO_INVOICE')}</div></td></tr>)}
                </tbody>
                <tfoot>
                    <tr className="ladiui table-vertical summary">
                        <td colSpan={config.APP_AFFILIATE ? 8 : 9}><span className="bold-500 ml-32">{t('AFFILIATES.TOTAL_ALL')}</span></td>
                        <td className="ladiui text-right">
                            <span className="bold-500">
                                {listInvoice.summary.totalAmount ? BaseHelper.formatMoney(listInvoice.summary.totalAmount) : BaseHelper.formatMoney(0)}
                            </span>
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
        {listInvoice.items.length > 0
            ? <LadiPagination
                conditionsProp={conditionsProp}
                listName={t('INVOICES.INVOICE')}
                items={listInvoice.items}
                total={listInvoice.total}
                limitAction={selectLimit}
                pageAction={selectPage}
            /> : ''}
        <ModalInvoiceDetail id={invoice ? invoice._id : null} />
        <ConfirmModal
            id="modal-confirm-approved-invoice"
            title='Bạn có chắc chắn muốn duyệt hoá đơn được chọn?'
            content={<div>Những hoá đơn ở trạng thái chờ duyệt mới được phép chuyển sang trạng thái duyệt.
            </div>}
            onOk={() => eventOkUpdateStatusApprovedInvoice()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="modal-confirm-paid-invoice"
            title='Bạn có chắc chắn muốn thanh toán hoá đơn được chọn?'
            content={<div>Những hoá đơn ở trạng thái duyệt mới được phép chuyển sang trạng thái đã thanh toán.
            </div>}
            onOk={() => eventOkUpdateStatusPaidInvoice()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="modal-confirm-cancel-invoice"
            title='Bạn có chắc chắn muốn huỷ hoá đơn được chọn?'
            content={<div>Những hoá đơn chưa thanh toán mới được phép huỷ.
            </div>}
            onOk={() => eventOkUpdateStatusCancelInvoice()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />

        <ConfirmModal
            id="modal-resend-mail-confirm-invoice"
            title='Bạn có chắc chắn muốn gửi email xác nhận đối soát'
            content={<div>Email sẽ được gửi tới affiliate
            </div>}
            onOk={() => eventOkResendMailConfirm()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />
        <ModalNoteInvoice />
    </>;
}

export default (withTranslation()(ListInvoice));
