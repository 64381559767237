import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiPagination from '../../../components/LadiPagination';
import BaseHelper from '../../../helpers/BaseHelper';
import actionCustomer from '../../../redux/futures/customer/actions';
import SearchCondition from '../../customers/components/SearchCondition';

function ListCustomer(props) {
    const { t, affiliateIdProp } = props;
    const defaultCondtions = {
        affiliate_id: affiliateIdProp,
        limit: 10,
        page: 1,
        sort: { created_at: -1 },
    };

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState(defaultCondtions);
    const listCustomer = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.customers.customers }));

    const cbList = () => {
        dispatch(actionCustomer.list(conditions));
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const getTypeCustomer = (item) => {
        let text = '';
        switch (item.type) {
            case 'LEAD': text = 'LEAD'; break;
            case 'CUSTOMER': text = 'Khách hàng'; break;
            case 'NEW_CUSTOMER': text = 'Khách hàng mới'; break;
            default:
        }
        return text;
    };

    const hideEmail = (text) => {
        if (!text) return '';
        return text.replace(/(.{2})(.*)(?=@)/,
            (gp1, gp2, gp3) => {
                for (let i = 0; i < gp3.length; i++) {
                    gp2 += '*';
                } return gp2;
            });
    };

    const hidePhone = (phone) => {
        if (!phone) return '';
        const last4Digits = phone.slice(-4);
        const maskedNumber = last4Digits.padStart(phone.length, '*');
        return maskedNumber;
    };

    useEffect(() => {
        cbList();
    }, [conditions]);
    return <>
        <div className="ladiui">
            <div className="ladiui content-main-tool flex-row align-item-baseline" style={{ marginTop: '24px' }}>
                <SearchCondition
                    keywordProp={conditions.keyword}
                    eventSearchKeyboardProp={eventSearchKeyboard}
                    eventClickSearchButtonProp={eventClickSearchButton}
                />
            </div>

            <table className="ladiui table text-left">
                <thead>
                    <tr className="ladiui table-vertical header">
                        <th scope="col">Tên</th>
                        <th scope="col">Email</th>
                        <th scope="col">Số điện thoại</th>
                        <th scope="col">Loại</th>
                        <th scope="col">Ngày cập nhật</th>
                    </tr>
                </thead>
                <tbody>
                    {listCustomer.items.length > 0 ? listCustomer.items.map((item, key) => (<tr key={item._id} className="ladiui table-vertical main">
                        <td>
                            {item.name || 'Khách hàng'}
                        </td>
                        <td>
                            {item.email}
                        </td>
                        <td>
                            {item.phone}
                        </td>
                        <td>
                            {getTypeCustomer(item)}
                        </td>
                        <td>{BaseHelper.formatStrToDate(item.updated_at, 'DD/MM/YYYY')}</td>
                    </tr>))
                        : <tr><td colSpan={6}>{t('NO_DATA')}</td></tr>}

                </tbody>
            </table>
            {listCustomer.items.length > 0
                ? <LadiPagination
                    conditionsProp={conditions}
                    listName={t('CUSTOMERS.CUSTOMERS')}
                    items={listCustomer.items}
                    total={listCustomer.total}
                    limitAction={selectLimit}
                    pageAction={selectPage}
                /> : ''}
        </div>
    </>;
}

export default (withTranslation()(ListCustomer));