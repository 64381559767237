import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../config/app';
import ModalStaff from './ModalStaff';
import LadiPagination from '../../components/LadiPagination';
import actionStaff from '../../redux/futures/staff/actions';
import ConfirmModal from '../../components/ConfirmModal';
import CheckAllAction from './components/CheckAllAction';
import DefineConst from './DefineConst';

// eslint-disable-next-line max-lines-per-function
function Staff(props) {
    const { t } = props;
    const defaultGroup = {
        _id: '',
        name: '',
        is_default: false,
        description: '',
    };
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { _id: 'DESC' },
    };

    const dispatch = useDispatch();
    const [staff, setStaff] = useState(defaultGroup);
    const [conditions, setConditions] = useState(defaultConditions);
    const [modalData, setModalData] = useState({ title: t('STAFFS.CREATE_STAFF'), btnOk: t('STAFFS.CREATE_STAFF') });
    const [isFetchData, setIsFetchData] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const listStaff = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.staffs.staffs }));
    const loadingApi = useSelector((state) => (state.staffs.loading));

    const listStaffFn = () => {
        dispatch(actionStaff.list(conditions));
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setSelectedIds(ids);
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const eventCreateStaff = () => {
        setStaff(defaultGroup);
        setModalData({ title: t('STAFFS.CREATE_STAFF'), btnOk: t('STAFFS.CREATE_STAFF') });
        window.LadiUI.showModal('white-page');
    };
    const eventEditStaff = (item) => {
        setStaff(item);
        setModalData({ title: t('STAFFS.EDIT_STAFF'), btnOk: t('STAFFS.UPDATE') });
        window.LadiUI.showModal('white-page');
    };

    const eventDeleteStaff = (item) => {
        setStaff(item);
        window.LadiUI.showModal('modal-delete-staff');
    };

    const eventOkDeleteStaff = () => {
        dispatch(actionStaff.delete({ id: staff._id }, listStaffFn));
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    useEffect(() => {
        dispatch(actionStaff.list(conditions));
    }, []);

    useEffect(() => {
        if (loadingApi !== undefined) dispatch(actionStaff.list(conditions));
    }, [conditions]);

    useEffect(() => {
        if (loadingApi === false && isFetchData === true) {
            dispatch(actionStaff.list(conditions));
            setIsFetchData(false);
        }
    }, [loadingApi]);

    const renderListStaff = (item) => (<tr className="ladiui table-vertical main" key={item._id}>
        <td scope="row">
            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element" name="checkbox" checked={selectedIds.includes(item._id)}
                value={item._id} onChange={eventCheck} />
        </td>
        <td>
            {item.first_name} {item.last_name}
        </td>
        <td>
            {item.email}
        </td>
        <td>
            {
                appConfig.LADISHARE.STAFF_ROLES.map((roleItem) => (roleItem.value === item.role ? roleItem.name : ''))
            }
        </td>
        <td className="ladiui-dropdown">
            <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                    <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                        <i className="ladiui icon icon-ldp-dot"></i>
                    </button>
                    <ul className="ladiui dropdown-menu custom-dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a className="ladiui dropdown-item" href="#/" onClick={() => eventEditStaff(item)}>
                            {t('STAFFS.EDIT')}
                        </a></li>
                        {item.role !== DefineConst.STAFF_ROLE.OWNER
                            ? <li><a className="ladiui dropdown-item" href="#/" onClick={() => eventDeleteStaff(item)}>
                                {t('COMMON.DELETE')}
                            </a></li>
                            : <></>}
                    </ul>
                </div>
            </div>
        </td>
    </tr>);

    return listStaff.items
        ? <>
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui">
                    <div className="ladiui main-title">
                        {t('STAFFS.LIST_STAFF')}
                    </div>
                    <span className="color-1" style={{ marginTop: '8px', display: 'block' }}>Các thành viên có thể được cấp một số quyền để truy cập vào quản lý tài khoản của bạn.</span>
                </div>
                <button type="button" className="ladiui btn-open-modal btn btn-primary" onClick={eventCreateStaff}>
                    <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                    <div className="ladiui btn-custom-text">{t('STAFFS.ADD_STAFF')}</div>
                </button>
            </div>
            <div className="ladiui access content-main-tool flex-row" style={{ marginTop: '10px' }}>
                <div className="ladiui search-group">
                    <input id="keyword_search" className="ladiui search-field dropdown-toggle form-control search-width" name="keyword"
                        placeholder={t('COMMON.SEARCH')} aria-expanded="false"
                        defaultValue={conditions.keyword || ''} onKeyDown={eventSearchKeyboard} />
                    <i className="ladiui icon icon-search" onClick={() => eventClickSearchButton()}></i>
                </div>
            </div>
            <div className="list-group-affiliate">
                <table className="ladiui table text-left">
                    <thead>
                        <tr className="ladiui table-vertical header">
                            <th className="ladiui col-first" scope="col">
                                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-commission"
                                    name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                            </th>
                            <th width="250">
                                {selectedIds.length > 0 ? <CheckAllAction ids={selectedIds} cb={listStaffFn} /> : t('STAFFS.FULLNAME')}
                            </th>
                            <th className="ladiui" scope="col">{t('STAFFS.EMAIL')}</th>
                            <th className="ladiui" scope="col">{t('STAFFS.ROLE')}</th>
                            <th className="ladiui" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listStaff.items.length > 0
                            ? listStaff.items.map((values) => (renderListStaff(values)))
                            : (<tr className="ladiui table-vertical main">
                                <td colSpan={5}>
                                    <span className="ladiui small-label">{t('STAFFS.NO_STAFF')}</span>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
                {listStaff.items
                    ? <LadiPagination
                        conditionsProp={conditions}
                        listName={t('STAFFS.STAFF')}
                        items={listStaff.items}
                        total={listStaff.total}
                        limitAction={selectLimit}
                        pageAction={selectPage}
                    /> : ''}

            </div>
            <ModalStaff
                title={modalData.title}
                btnOk={modalData.btnOk}
                btnCancel={t('STAFFS.CANCELED')}
                dataStaff={staff}
                setIsFetchData={() => setIsFetchData(true)}
            />
            <ConfirmModal
                id="modal-delete-staff"
                title="Bạn có chắc muốn xoá nhân viên này không?"
                content="Sau khi xoá nhân viên sẽ bị xoá vĩnh viễn bạn sẽ không thể xem lại được nữa"
                onOk={() => eventOkDeleteStaff()}
                cancelText={t('COMMON.CANCELED')}
                okText={t('COMMON.OK')}
                classBtn="btn-danger"
            />
        </> : '';
}

export default (withTranslation()(Staff));
