import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../config/app';
import ModalGroupAffiliate from './ModalGroupAffiliate';
import LadiPagination from '../../components/LadiPagination';
import actionAffiliateGroup from '../../redux/futures/affiliate_group/actions';
import ConfirmModal from '../../components/ConfirmModal';
import CheckAllActionGroup from './components/CheckAllActionGroup';
import BaseHelper from '../../helpers/BaseHelper';
import ModalConditionChangeGroupAffiliate from './ModalConditionChangeGroupAffiliate';
import actionStore from '../../redux/futures/store/actions';

// eslint-disable-next-line max-lines-per-function
function GroupAffiliate(props) {
    const { t } = props;
    const defaultGroup = {
        _id: '',
        name: '',
        is_default: false,
        description: '',
    };
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { is_default: -1, _id: -1 },
    };

    const dispatch = useDispatch();
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const [group, setGroup] = useState(defaultGroup);
    const [conditions, setConditions] = useState(defaultConditions);
    const [modalData, setModalData] = useState({ title: t('GROUP_AFFILIATES.CREATE_GROUP'), btnOk: t('GROUP_AFFILIATES.CREATE_GROUP') });

    const listGroup = useSelector((state) => ({ ...{ items: [], total: 0, pluckItems: {} }, ...state.affiliateGroups.groups }));

    const cbLoadList = () => {
        dispatch(actionAffiliateGroup.list(conditions));
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setSelectedIds(ids);
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const eventCreateGroup = () => {
        setGroup(defaultGroup);
        setModalData({ title: t('GROUP_AFFILIATES.CREATE_GROUP'), btnOk: t('GROUP_AFFILIATES.CREATE_GROUP') });
        window.LadiUI.showModal('white-page');
    };
    const eventEditGroup = (item) => {
        setGroup(item);
        setModalData({ title: t('GROUP_AFFILIATES.EDIT_GROUP'), btnOk: t('GROUP_AFFILIATES.UPDATE') });
        window.LadiUI.showModal('white-page');
    };

    const eventDeleteGroup = (item) => {
        setGroup(item);
        window.LadiUI.showModal('modal-confirm-delete-group');
    };

    const eventOkDeleteGroup = () => {
        dispatch(actionAffiliateGroup.delete({ id: group._id }, cbLoadList));
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventMakeAsDefault = (item) => {
        const data = { is_default: true, id: item._id };
        dispatch(actionAffiliateGroup.makeAsDefault(data, cbLoadList));
    };

    const eventOpenModalSettingChangeGroup = () => {
        const data = { field: 'affiliate_change_group_setting' };
        dispatch(actionStore.getAffiliateChangeGroupSetting(data));
        window.LadiUI.showModal('modal-setting-change-group');
    };

    useEffect(() => {
        dispatch(actionAffiliateGroup.list(conditions));
        setCheckAll(false);
        setSelectedIds([]);
    }, [conditions]);

    const renderListGroup = (item) => (<tr className="ladiui table-vertical main" key={item._id}>
        <td scope="row">
            {!item.is_default
                ? <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element" name="checkbox" checked={selectedIds.includes(item._id)}
                    value={item._id} onChange={eventCheck} />
                : <></>}
        </td>
        <td>
            {item.name}&nbsp;
            {item.is_default ? <span className="ladiui badge badge-success">{t('GROUP_AFFILIATES.DEFAULT')}</span> : ''}
        </td>
        <td>
            {item.description}
        </td>
        <td>
            {item.total_affiliate}
        </td>
        <td>
            {item.label}
        </td>
        <td>{BaseHelper.formatStrToDate(item.created_at, 'DD/MM/YYYY')}</td>
        <td className="ladiui-dropdown">
            <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                    <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                        <i className="ladiui icon icon-ldp-dot"></i>
                    </button>
                    <ul className="ladiui dropdown-menu custom-dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {item.is_default ? <></> : <li><a className="ladiui dropdown-item" href="#/" onClick={() => eventMakeAsDefault(item)}>
                            {t('GROUP_AFFILIATES.MAKE_AS_DEFAULT')}
                        </a></li>
                        }
                        <li><a className="ladiui dropdown-item" href="#/" onClick={() => eventEditGroup(item)}>
                            {t('GROUP_AFFILIATES.EDIT')}
                        </a></li>
                        {!item.is_default
                            ? <li><a className="ladiui dropdown-item" href="#/" onClick={() => eventDeleteGroup(item)}>
                                {t('GROUP_AFFILIATES.DELETE')}
                            </a></li>
                            : <></>}
                    </ul>
                </div>
            </div>
        </td>
    </tr>);

    return listGroup.items
        ? <>
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui">
                    <div className="ladiui main-title">
                        {t('GROUP_AFFILIATES.LIST_GROUP_AFFILIATE')}
                    </div>
                    <span className="color-1" style={{ marginTop: '8px', display: 'block' }}>Tạo và quản lý các nhóm affiliate của bạn.</span>
                </div>
                <div className="ladiui flex-row">
                    <button className="ladiui btn btn-link btn-change-group-setting" onClick={eventOpenModalSettingChangeGroup}>
                        <i className="ladiui icon icon-menu-settings"></i> Thiết lập điều kiện chuyển nhóm
                    </button>
                    <button type="button" className="ladiui btn-open-modal btn btn-primary" onClick={eventCreateGroup}>
                        <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                        <div className="ladiui btn-custom-text">{t('GROUP_AFFILIATES.ADD_GROUP_AFFILIATE')}</div>
                    </button>
                </div>
            </div>
            <div className="ladiui access content-main-tool flex-row" style={{ marginTop: '10px' }}>
                <div className="ladiui search-group">
                    <input id="keyword_search" className="ladiui search-field dropdown-toggle form-control search-width" name="keyword" placeholder="Tìm kiếm" aria-expanded="false"
                        defaultValue={conditions.keyword || ''} onKeyDown={eventSearchKeyboard} />
                    <i className="ladiui icon icon-search" onClick={() => eventClickSearchButton()}></i>
                </div>
            </div>
            <div className="list-group-affiliate">
                <table className="ladiui table text-left">
                    <thead>
                        <tr className="ladiui table-vertical header">
                            <th className="ladiui col-first" scope="col">
                                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-commission"
                                    name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                            </th>
                            <th width="250">
                                {selectedIds.length > 0 ? <CheckAllActionGroup ids={selectedIds} cb={cbLoadList} /> : 'Tên nhóm'}
                            </th>
                            <th>{t('COMMON.DESCRIPTION')}</th>
                            <th className="ladiui" scope="col">Thành viên</th>
                            <th className="ladiui" scope="col">Nhãn</th>
                            <th>{t('COMMON.CREATED_AT')}</th>
                            <th className="ladiui" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listGroup.items.length > 0
                            ? listGroup.items.map((values) => (renderListGroup(values)))
                            : (<tr className="ladiui table-vertical main">
                                <td colSpan={5}>
                                    <span className="ladiui small-label">{t('GROUP_AFFILIATES.NO_GROUP_AFFILIATE')}</span>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
                {listGroup.items
                    ? <LadiPagination
                        conditionsProp={conditions}
                        listName={t('GROUP_AFFILIATES.GROUP_AFFILIATE')}
                        items={listGroup.items}
                        total={listGroup.total}
                        limitAction={selectLimit}
                        pageAction={selectPage}
                    /> : ''}

            </div>
            <ModalGroupAffiliate
                title={modalData.title}
                btnOk={modalData.btnOk}
                btnCancel={t('AFFILIATES.CANCELED')}
                dataGroupAffiliate={group}
                cb={cbLoadList}
            />
            <ModalConditionChangeGroupAffiliate />
            <ConfirmModal
                id="modal-confirm-delete-group"
                title='Bạn có chắc chắn muốn xóa nhóm?'
                content='Những nhóm chứa thành viên hoặc là nhóm mặc định sẽ không được phép xóa.'
                onOk={() => eventOkDeleteGroup()}
                cancelText={t('GROUP_AFFILIATES.CANCELED')}
                okText={t('GROUP_AFFILIATES.OK')}
                classBtn="btn-danger"
            />
        </> : '';
}

export default (withTranslation()(GroupAffiliate));
