import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Highcharts from 'highcharts';
// eslint-disable-next-line import/no-extraneous-dependencies
import HighchartsReact from 'highcharts-react-official';
import actionCampaign from '../../redux/futures/campaign/actions';
import actionAffiliateGroup from '../../redux/futures/affiliate_group/actions';
import DefineConst from './DefineConst';
import FacebookContent from './components/contents/FacebookContent';
import TwitterContent from './components/contents/TwitterContent';
import InstagramContent from './components/contents/InstagramContent';
import LinkedinContent from './components/contents/LinkedinContent';
import BannerContent from './components/contents/BannerContent';
import MailContent from './components/contents/MailContent';
import ModalCampaignSociety from './components/ModalCampaignSociety';
import ModalCampaignBanner from './components/ModalCampaignBanner';
import ModalCampaignMail from './components/ModalCampaignMail';

// eslint-disable-next-line max-lines-per-function
function CampaignDetail(props) {
    const { t } = props;
    const { id } = useParams();
    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: null,
        },
        xAxis: {
            title: {
                text: null,
            },
            type: 'datetime',
            labels: {
                formatter() {
                    return Highcharts.dateFormat('%e/%b', this.value);
                },
            },
        },
        yAxis: {
            title: {
                text: null,
            },
            type: 'number',
        },
        legend: {
            enabled: false,
            align: 'right',
            verticalAlign: 'top',
        },
        tooltip: {
            dateTimeLabelFormats: { day: '%A, %e/%b' },
        },
        time: {
            timezoneOffset: -7 * 60,
        },
        series: [
            {
                name: 'Lượt chia sẻ',
                color: '#A6CEE3',
                data: [1, 3, 7, 3, 3, 10],
                pointStart: Date.UTC(2022, 6, 1),
                pointInterval: 3600 * 1000 * 24,
            },
        ],
    };
    const dispatch = useDispatch();
    const [highChartOptions, setHighChartOptions] = useState(options);
    const campaign = useSelector((state) => (state.campaigns.campaign));

    const getPlatform = (type) => {
        let platform = <></>;
        switch (type) {
            case DefineConst.PLATFORM_CAMPAIGN.FACEBOOK:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/fb.svg" />
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.INSTAGRAM:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/instagram.svg" width={16} height={16} />
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.LINKEDIN:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/linkedin.svg" width={24} height={24} />
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.TWITTER:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/twitter.svg" width={24} height={24} />
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.BANNER:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/banner-ads-icon.svg" width={24} height={24} />
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.MAIL:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/mail.svg" width={24} height={24} />
                </div>;
                break;

            default:
        }
        return platform;
    };

    const getContents = (type) => {
        switch (type) {
            case DefineConst.PLATFORM_CAMPAIGN.FACEBOOK:
                return <FacebookContent contents={campaign ? campaign.contents : ''} />;
            case DefineConst.PLATFORM_CAMPAIGN.INSTAGRAM:
                return <InstagramContent contents={campaign || {}} />;
            case DefineConst.PLATFORM_CAMPAIGN.LINKEDIN:
                return <LinkedinContent contents={campaign ? campaign.contents : ''} />;
            case DefineConst.PLATFORM_CAMPAIGN.TWITTER:
                return <TwitterContent contents={campaign ? campaign.contents : ''} />;
            case DefineConst.PLATFORM_CAMPAIGN.BANNER:
                return <BannerContent campaign={campaign || {}} />;
            case DefineConst.PLATFORM_CAMPAIGN.MAIL:
                return <MailContent contents={campaign || {}} />;
            default: return <></>;
        }
    };

    const getModalCampaignEdit = (type) => {
        switch (type) {
            case DefineConst.PLATFORM_CAMPAIGN.FACEBOOK:
                return <ModalCampaignSociety isEdit={true} campaignProp={campaign} cb={() => dispatch(actionCampaign.show(id))} />;
            case DefineConst.PLATFORM_CAMPAIGN.INSTAGRAM:
                return <ModalCampaignSociety isEdit={true} campaignProp={campaign} cb={() => dispatch(actionCampaign.show(id))} />;
            case DefineConst.PLATFORM_CAMPAIGN.LINKEDIN:
                return <ModalCampaignSociety isEdit={true} campaignProp={campaign} cb={() => dispatch(actionCampaign.show(id))} />;
            case DefineConst.PLATFORM_CAMPAIGN.TWITTER:
                return <ModalCampaignSociety isEdit={true} campaignProp={campaign} cb={() => dispatch(actionCampaign.show(id))} />;
            case DefineConst.PLATFORM_CAMPAIGN.BANNER:
                return <ModalCampaignBanner isEdit={true} campaignProp={campaign} cb={() => dispatch(actionCampaign.show(id))} />;
            case DefineConst.PLATFORM_CAMPAIGN.MAIL:
                return <ModalCampaignMail isEdit={true} campaignProp={campaign} cb={() => dispatch(actionCampaign.show(id))} />;
            default: return <></>;
        }
    };

    const eventEditCampaign = (type) => {
        let modalCampaign;
        switch (type) {
            case DefineConst.PLATFORM_CAMPAIGN.FACEBOOK:
            case DefineConst.PLATFORM_CAMPAIGN.INSTAGRAM:
            case DefineConst.PLATFORM_CAMPAIGN.LINKEDIN:
            case DefineConst.PLATFORM_CAMPAIGN.TWITTER:
                modalCampaign = 'modal-campaign-society';
                break;
            case DefineConst.PLATFORM_CAMPAIGN.BANNER:
                modalCampaign = 'modal-campaign-banner';
                break;
            case DefineConst.PLATFORM_CAMPAIGN.MAIL:
                modalCampaign = 'modal-campaign-mail';
                break;
            default:
        }
        window.LadiUI.showModal(modalCampaign);
    };

    useEffect(() => {
        dispatch(actionCampaign.show(id));
        dispatch(actionAffiliateGroup.list({ pluck: ['_id', 'name'] }));
    }, []);

    return campaign
        ? <><div className="ladiui content-main-full campaign-detail">
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui flex-row">
                    <div className="ladiui main-title">
                        {t('CAMPAIGNS.DETAIL_CAMPAIGN')}
                    </div>
                </div>
            </div>

            <div className="campaign-detail-title ladiui flex-row">
                {getPlatform(campaign.type || null)}
                <label>{campaign.title || ''}</label>
            </div>
            <div className="campaign-detail-options ladiui flex-row">
                <div>
                    <label>Hiển thị trên chiến dịch của Affiliate</label>
                    <div className="bold">{(campaign.status === DefineConst.STATUS.PUBLIC) ? 'Có' : 'Không'}</div>
                </div>
                <div>
                    <label>Ưu tiên</label>
                    <div className="bold">{(campaign.is_priority === true) ? 'Có' : 'Không'}</div>
                </div>
                <div><button className="ladiui btn btn-outline-primary" onClick={() => eventEditCampaign(campaign ? campaign.type : null)}>Chỉnh sửa</button></div>
            </div>
            {campaign.type === DefineConst.PLATFORM_CAMPAIGN.MAIL
                ? <>
                    <div className="campaign-detail-content">
                        <div className="content-left">
                            <label>Subject</label>
                            <div className="mt-8">{campaign.subject}</div>
                        </div>
                    </div>
                </>
                : <div className="campaign-detail-url">
                    <input className="ladiui form-control" id="public_key" name="public_key" readOnly value={campaign.url || ''} onChange={() => { }} />
                </div>
            }
            <div className="campaign-detail-content">
                <div className="content-left">
                    {getContents(campaign.type || null)}
                </div>
                <div className="content-right" style={{ display: 'none' }}>
                    <div>
                        <label className="label-share">Lượt chia sẻ:</label>
                        <span className="number-share">{campaign.share_number || 0}</span>
                        <div style={{ width: '400px' }}>
                            <HighchartsReact highcharts={Highcharts} options={highChartOptions} />
                        </div>
                        <div className="ladiui box-statistics box-no-padding box-no-heigh" style={{ display: 'block', width: '400px' }}>
                            <div className="ladiui box-title">
                                <label className="ladiui">Hoạt động</label>
                            </div>
                            <div className="box-body">
                                <ul className="history-action">
                                    <li>
                                        <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                                        <div className="title">Affiliate mới</div>
                                        <div className="content"><a href="#/">sontruonggiang@gmail.com</a></div>
                                        <div className="time">12 phút trước</div>
                                    </li>
                                    <li>
                                        <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                                        <div className="title">Lead mới</div>
                                        <div className="content"><a href="#/">sontruonggiang@gmail.com</a></div>
                                        <div className="time">12 phút trước</div>
                                    </li>
                                    <li>
                                        <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                                        <div className="title">Đơn hàng mới</div>
                                        <div className="content"><a href="#/">sontruonggiang@gmail.com</a></div>
                                        <div className="time">12 phút trước</div>
                                    </li>
                                    <li>
                                        <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                                        <div className="title">Đơn hàng mới</div>
                                        <div className="content"><a href="#/">sontruonggiang@gmail.com</a></div>
                                        <div className="time">12 phút trước</div>
                                    </li>
                                    <li>
                                        <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                                        <div className="title">Lead mới</div>
                                        <div className="content"><a href="#/">sontruonggiang@gmail.com</a></div>
                                        <div className="time">12 phút trước</div>
                                    </li>
                                </ul>
                            </div>
                            <div className="box-footer"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {getModalCampaignEdit(campaign.type || null)}
            </div>

        </div>
        </>
        : <></>;
}
export default (withTranslation()(CampaignDetail));
