import React from 'react';
import PropTypes from 'prop-types';

export default class ConfirmModal extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.string,
        // content: PropTypes.object,
        onCancel: PropTypes.func,
        cancelText: PropTypes.string,
        onOk: PropTypes.func,
        okText: PropTypes.string,
        isLoading: PropTypes.bool,
        maxWidth: PropTypes.number,
        disableCancel: PropTypes.bool,
    }

    static defaultProps = {
        id: 'modal-confirm-action',
        disableCancel: false,
    }

    onCancel = () => {
        window.LadiUI.closeModal(this.props.id);
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    onOk = () => {
        window.LadiUI.closeModal(this.props.id);
        if (this.props.onOk) {
            this.props.onOk();
        }
    }

    render() {
        const {
            id, title, content, cancelText, okText, classBtn,
        } = this.props;

        return (
            <div className="ladiui modal" id={id}>
                <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width1">
                    <div className="ladiui modal-content custom-ldp">
                        <div className="ladiui modal-header-">
                            <label className="ladiui title-text-form-lever1">{title}</label>
                        </div>
                        {/* <label className="ladiui text on-access-key" dangerouslySetInnerHTML={{ __html: content }}></label> */}
                        <label className="ladiui text on-access-key">{content}</label>
                        <div className="ladiui modal-footer custom-ldp1">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button data-dismiss="modal" data-target="delete-modal"
                                    className="ladiui btn btn-secondary bold" onClick={this.onCancel}>{cancelText}</button>
                                <button type="button" className={`ladiui btn ${classBtn || 'btn-secondary'}`} onClick={this.onOk}>{okText}</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
