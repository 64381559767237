import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumericFormat from 'react-number-format';
import LadiDropdown from '../../../components/LadiDropdown';
import actionStore from '../../../redux/futures/store/actions';

// eslint-disable-next-line max-lines-per-function
function AffiliateConfigSetting(props) {
    const { t } = props;

    const dispatch = useDispatch();
    const [affiliateSetting, setAffiliateSetting] = useState({});

    const setting = useSelector((state) => (state.store.affiliateSetting));

    const updateAffiliateSetting = () => {
        const dataUpdate = {
            affiliate_setting: affiliateSetting,
        };
        dispatch(actionStore.updateAffiliateSetting(dataUpdate));
    };

    const changeDataForm = (e) => {
        const name = e.target.name;
        const field = {};
        if (e.target.type === 'checkbox') {
            field[name] = e.target.checked;
        } else {
            field[name] = e.target.value;
        }
        setAffiliateSetting({ ...affiliateSetting, ...field });
    };

    const cbLoadData = () => {
        const data = {
            field: 'affiliate_setting',
        };
        dispatch(actionStore.getAffiliateSetting(data));
    };

    const getListInvoiceTimeSetting = () => [
        { value: 'BY_CREATED_AT', name: 'Theo ngày tạo hoa hồng' },
        { value: 'BY_INVOICED_AT', name: 'Theo ngày duyệt hoa hồng' },
    ];

    const eventSelectInvoiceTimeSetting = (type) => {
        setAffiliateSetting({ ...affiliateSetting, commission_invoice_time_setting: type });
    };

    const getDays = () => [{ name: '1', value: '1' }];

    useEffect(() => {
        cbLoadData();
    }, []);

    useEffect(() => {
        if (setting) setAffiliateSetting(setting);
    }, [setting]);

    return <>
        <div className="ladiui affiliate-setting-content" style={{ display: 'none' }}>
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Trả tiền hoa hồng cho nhiều lần mua</label>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tristique tincidunt eu </div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="pay_commissions_for_multiple_purchase" name="pay_commissions_for_multiple_purchase"
                            checked={affiliateSetting.pay_commissions_for_multiple_purchase || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>

        <div className="ladiui affiliate-setting-content" style={{ display: 'none' }}>
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Cho phép các Affiliates sử dụng đường dẫn giới thiệu riêng</label>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tristique tincidunt eu </div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="allow_use_link_of_affiliate" name="allow_use_link_of_affiliate"
                            checked={affiliateSetting.allow_use_link_of_affiliate || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>

        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Rút gọn đường dẫn Affiliates</label>
                    <div>Sử dụng đường dẫn rút gọn với tên miền là ldp.to để chuyển hướng</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="use_short_link_affiliate" name="use_short_link_affiliate"
                            checked={affiliateSetting.use_short_link_affiliate || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>

        <div className="ladiui affiliate-setting-content" style={{ display: 'none' }}>
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Cho phép sử dụng UTM</label>
                    <div>Hỗ trợ tracking chi tiết hơn đa nền tảng</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="allow_set_utm_parameters" name="allow_set_utm_parameters"
                            checked={affiliateSetting.allow_set_utm_parameters || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>

        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Tự động tạo mã Affiliates</label>
                    <div>Mã Affiliates sẽ được tự động tạo khi khởi tạo tài khoản Affiliates mới</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="auto_create_discount_code_affiliate" name="auto_create_discount_code_affiliate"
                            checked={affiliateSetting.auto_create_discount_code_affiliate || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>

        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Tự động duyệt Affiliates</label>
                    <div>Affiliates khi đăng ký sẽ được hệ thống tự động duyệt.</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="auto_approved_affiliate" name="auto_approved_affiliate"
                            checked={affiliateSetting.auto_approved_affiliate || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>

        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Vô hiệu đường dẫn Affiliates, chỉ cho phép mã coupon</label>
                    <div>Các đường dẫn Affiliates sẽ bị vô hiệu, chỉ có thể sử dụng mã coupon code.</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="only_using_discount_code" name="only_using_discount_code"
                            checked={affiliateSetting.only_using_discount_code || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>

        <div className="ladiui affiliate-setting-content" style={{ display: 'none' }}>
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Chỉ ghi nhận hoa hồng cho Affiliates đầu tiên</label>
                    <div>Lượt giới thiệu chỉ được ghi nhận cho lượt giới thiệu đầu tiên </div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="only_first_affiliate_has_commission" name="only_first_affiliate_has_commission"
                            checked={affiliateSetting.only_first_affiliate_has_commission || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>

        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Số lượng khách hàng tối thiểu</label>
                    <div>Chỉ tạo được hóa đơn thanh toán khi số lượng khách hàng đạt giá trị theo cài đặt</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="limit_customer_status" name="limit_customer_status"
                            checked={affiliateSetting.limit_customer_status || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
            {affiliateSetting.limit_customer_status
                ? <div className="ladiui form-group flex-row">
                    <NumericFormat id="limit_customer_value" name="limit_customer_value"
                        value={affiliateSetting.limit_customer_value || ''}
                        allowLeadingZeros
                        allowNegative={false}
                        decimalScale={0}
                        thousandSeparator=","
                        className="ladiui form-control"
                        placeholder="Nhập số lượng tối thiểu"
                        onValueChange={(values) => setAffiliateSetting({ ...affiliateSetting, limit_customer_value: values.value })}
                    />
                </div> : <></>
            }
        </div>

        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Giá trị hóa đơn thanh toán tối thiểu</label>
                    <div>Chỉ tạo được hóa đơn thanh toán khi số dư đạt giá trị cài đặt</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="limit_invoice_status" name="limit_invoice_status"
                            checked={affiliateSetting.limit_invoice_status || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
            {affiliateSetting.limit_invoice_status
                ? <div className="ladiui form-group flex-row">
                    <NumericFormat id="limit_invoice_value" name="limit_invoice_value"
                        value={affiliateSetting.limit_invoice_value || ''}
                        allowLeadingZeros
                        allowNegative={false}
                        decimalScale={2}
                        thousandSeparator=","
                        className="ladiui form-control"
                        placeholder="Nhập giá trị tối thiểu"
                        onValueChange={(values) => setAffiliateSetting({ ...affiliateSetting, limit_invoice_value: values.value })}
                    />
                </div> : <></>
            }
        </div>
        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Tạo affiliate cho khách hàng mới</label>
                    <div>Khách hàng sau khi đặt hàng thành công sẽ được tự động đăng ký làm affiliate</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="auto_create_affiliate" name="auto_create_affiliate"
                            checked={affiliateSetting.auto_create_affiliate || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
            {affiliateSetting.auto_create_affiliate
                ? <ul className="list-option-check">
                    <li>
                        <label>
                            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                name="enable_mail_register_affiliate"
                                checked={affiliateSetting.enable_mail_register_affiliate || false}
                                onChange={(e) => changeDataForm(e)}
                            />
                            Gửi email thông báo đăng ký affiliate cho khách hàng
                        </label>
                    </li>
                    {/* <li>
                        <label>
                            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                name="create_commission_register_affiliate_from_customer"
                                checked={affiliateSetting.create_commission_register_affiliate_from_customer || false}
                                onChange={(e) => changeDataForm(e)}
                            />
                            Tạo hoa hồng cho khách hàng
                        </label>
                    </li> */}
                </ul>
                : <></>}
        </div>
        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Sử dụng số điện thoại làm mã affiliate</label>
                    <div>Khách hàng nhập số điện thoại sẽ được tự động lấy làm mã affiliate</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="use_phone_as_affiliate_code" name="use_phone_as_affiliate_code"
                            checked={affiliateSetting.use_phone_as_affiliate_code || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Tắt chức năng nhập người giới thiệu</label>
                    <div>Bật/tắt nhập mã người giới thiệu khi người dùng đăng ký làm affiliate</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="disable_input_ref_aff" name="disable_input_ref_aff"
                            checked={affiliateSetting.disable_input_ref_aff || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Tự động duyệt hoa hồng khi affiliate đồng ý đối soát</label>
                    <div>Hoa hồng sẽ tự động chuyển sang trạng thái Duyệt nếu affiliate đồng ý đối soát</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="auto_invoice_commission_when_confirm" name="auto_invoice_commission_when_confirm"
                            checked={affiliateSetting.auto_invoice_commission_when_confirm || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Cho phép duyệt hoa hồng mà không cần đối soát</label>
                    <div>Store có thể duyệt hoa hồng mà không cần chờ affiliate đối soát</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="allow_invoice_commission_without_confirm" name="allow_invoice_commission_without_confirm"
                            checked={affiliateSetting.allow_invoice_commission_without_confirm || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Lên hoá đơn theo thời gian</label>
                    <div>Hoa hồng được lên hoá đơn cho khách hàng sẽ dựa theo thời gian tạo hoa hồng hoặc duyệt hoa hồng</div>
                </div>
            </div>
            <div className="ladiui flex-row">
                <LadiDropdown
                    labelDropdown='Loại affiliate'
                    listProp={getListInvoiceTimeSetting()}
                    valueProp={affiliateSetting.commission_invoice_time_setting || 'BY_INVOICED_AT'}
                    hideLabel={true}
                    validProp={true}
                    classDropdown={'dropdown-setting-custom'}
                    cbProp={eventSelectInvoiceTimeSetting}
                />
            </div>
        </div>
        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group flex-row">
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Yêu cầu rút tiền</label>
                    <div>Thiết lập khoảng thời gian cho phép affiliate tạo yêu cầu rút tiền</div>
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch">
                        <input type="checkbox" id="enable_create_request_withdraw_money" name="enable_create_request_withdraw_money"
                            checked={affiliateSetting.enable_create_request_withdraw_money || false} onChange={changeDataForm} />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
            {affiliateSetting.enable_create_request_withdraw_money
                ? <>
                    <div className="ladiui form-group flex-row" style={{ justifyContent: 'flex-start' }}>
                        <div className="radio-button-ao">
                            <input className="ladiui form-check-input one" id="choose_type_withdraw_1" name="type_create_request_withdraw_money" type="radio"
                                onChange={changeDataForm}
                                checked={affiliateSetting.type_create_request_withdraw_money === 'SINGLE_DATE'}
                                defaultValue="SINGLE_DATE" />
                            <label htmlFor="choose_type_withdraw_1">Ngày cụ thể trong tháng</label>
                        </div>
                        <div className="radio-button-ao" style={{ marginLeft: '36px' }}>
                            <input className="ladiui form-check-input one" id="choose_type_withdraw_2" name="type_create_request_withdraw_money" type="radio"
                                onChange={changeDataForm}
                                checked={affiliateSetting.type_create_request_withdraw_money === 'MULTI_DATE'}
                                defaultValue="MULTI_DATE" />
                            <label htmlFor="choose_type_withdraw_2">Khoảng ngày trong tháng</label>
                        </div>
                    </div>
                    <div className="ladiui flex-row" style={{ gap: '12px', justifyContent: 'flex-start' }}>
                        <div>Ngày</div>
                        <div className="ladiui" style={{ marginTop: '12px' }}>
                            <input type="number" id="create_request_withdraw_money_start_date"
                                name="create_request_withdraw_money_start_date"
                                className="ladiui form-control" placeholder=""
                                style={{ width: '80px' }}
                                value={affiliateSetting.create_request_withdraw_money_start_date || ''}
                                onChange={changeDataForm} />
                        </div>
                        {affiliateSetting.type_create_request_withdraw_money === 'MULTI_DATE'
                            ? <><div>Đến</div>
                                <div className="ladiui" style={{ marginTop: '12px' }}>
                                    <input type="number" id="create_request_withdraw_money_end_date"
                                        name="create_request_withdraw_money_end_date"
                                        className="ladiui form-control" placeholder=""
                                        style={{ width: '80px' }}
                                        value={affiliateSetting.create_request_withdraw_money_end_date || ''}
                                        onChange={changeDataForm} />
                                </div>
                            </> : <></>}
                        <div style={{ width: '50%' }}>Hàng tháng</div>
                    </div>
                </> : <></>}
        </div>
        <div className="ladiui affiliate-setting-content">
            <div className="ladiui form-group">
                <button className="ladiui btn btn-primary right" onClick={updateAffiliateSetting}>{t('STORES.UPDATE')}</button>
                <button className="ladiui btn right btn-secondary" style={{ marginRight: '40px' }} onClick={cbLoadData}>{t('STORES.CANCELED')}</button>
            </div>
        </div>
    </>;
}

export default (withTranslation()(AffiliateConfigSetting));
