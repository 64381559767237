import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';
import BaseHelper from '../../../../helpers/BaseHelper';
import DefineConst from '../../DefineConst';

function CommissionPlanPaymentBoxPriceRange(props) {
    const { t, paymentFor, paymentPriceRangeProp, cb } = props;
    const [paymentPriceRange, setPaymentPriceRange] = useState({ enable: false });

    const renderExplain = () => {
        let explain = <></>;
        if (paymentFor === DefineConst.PAYMENT_FOR.ORDER) {
            if (paymentPriceRange.min && paymentPriceRange.max) {
                explain = <>(*) Hoa hồng sẽ được tính nếu giá trị đơn hàng nằm trong khoảng từ {BaseHelper.formatMoney(paymentPriceRange.min)} đến {BaseHelper.formatMoney(paymentPriceRange.max)}</>;
            }
            if (paymentPriceRange.min && !paymentPriceRange.max) {
                explain = <>(*) Hoa hồng sẽ được tính nếu giá trị đơn hàng lớn hơn {BaseHelper.formatMoney(paymentPriceRange.min)}</>;
            }
            if (!paymentPriceRange.min && paymentPriceRange.max) {
                explain = <>(*) Hoa hồng sẽ được tính nếu giá trị đơn hàng nhỏ hơn {BaseHelper.formatMoney(paymentPriceRange.max)}</>;
            }
        }
        if (paymentFor === DefineConst.PAYMENT_FOR.PER_PRODUCT) {
            if (paymentPriceRange.min && paymentPriceRange.max) {
                explain = <>(*) Hoa hồng sẽ được tính nếu giá trị sản phẩm nằm trong khoảng từ {BaseHelper.formatMoney(paymentPriceRange.min)} đến {BaseHelper.formatMoney(paymentPriceRange.max)}</>;
            }
            if (paymentPriceRange.min && !paymentPriceRange.max) {
                explain = <>(*) Hoa hồng sẽ được tính nếu giá trị sản phẩm lớn hơn {BaseHelper.formatMoney(paymentPriceRange.min)}</>;
            }
            if (!paymentPriceRange.min && paymentPriceRange.max) {
                explain = <>(*) Hoa hồng sẽ được tính nếu giá trị sản phẩm nhỏ hơn {BaseHelper.formatMoney(paymentPriceRange.max)}</>;
            }
        }
        return explain;
    };

    const eventChangeInput = (e) => {
        const name = e.target.name;
        let value;
        if (
            e.target.tagName.toLowerCase() === 'input'
            && e.target.getAttribute('type') === 'checkbox'
        ) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        const data = { ...paymentPriceRange };
        data[name] = value;
        setPaymentPriceRange(data);
        cb(data);
    };

    const changeInputValue = (fieldName, value) => {
        const data = { ...paymentPriceRange };
        data[fieldName] = value;
        setPaymentPriceRange(data);
        cb(data);
    };

    useEffect(() => {
        setPaymentPriceRange({ ...paymentPriceRange, ...paymentPriceRangeProp });
    }, [paymentPriceRangeProp]);

    return <>
        <div className="ladiui clearfix pt-20">
            <label className="ladiui title-text-form-lever2 left">Trả theo khoảng giá</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox"
                        name="enable"
                        checked={paymentPriceRange !== null && (paymentPriceRange.enable || false)}
                        onChange={(e) => eventChangeInput(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Trả hoa hồng nếu điều kiện trả hoa hồng nằm trong khoảng giá xác định</div>
        </div>
        {paymentPriceRange && paymentPriceRange.enable
            ? <>
                <div className="ladiui clearfix pt-20">
                    <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Giá tối thiểu</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                disabled={paymentPriceRange.interval_type === DefineConst.PAYMENT_INTERVAL_TYPE.OFF}
                                value={paymentPriceRange.min || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Giá tối thiểu"
                                onValueChange={(values) => changeInputValue('min', values.value)}
                            />
                        </div>

                    </div>
                    <div className="ladiui left">
                        <label className="ladiui title-text-form-lever3">Giá tối đa</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                disabled={paymentPriceRange.interval_type === DefineConst.PAYMENT_INTERVAL_TYPE.OFF}
                                value={paymentPriceRange.max || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Giá tối đa"
                                onValueChange={(values) => changeInputValue('max', values.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>
                    {renderExplain()}
                </div>
            </>
            : <></>}
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBoxPriceRange));
