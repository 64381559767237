import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DashboardAdvertiser from './DashboardAdvertiser';
import DashboardPublisher from './DashboardPublisher';

function Dashboard() {
    const scope = useSelector((state) => (state.store.userInfo.scopes));

    return <>
        {scope === 'ADVERTISER' ? <DashboardAdvertiser /> : <DashboardPublisher />}
    </>;
}

export default (withTranslation()(Dashboard));
