import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LadiPagination from '../../../components/LadiPagination';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';
import trafficAction from '../../../redux/futures/source/actions';
import DefineConst from '../DefineConst';
import DefineConstAppIntegrate from '../../apps/DefineConst';
import ConfirmModal from '../../../components/ConfirmModal';
import CheckAllAction from './CheckAllAction';

// eslint-disable-next-line max-lines-per-function
function Order(props) {
    const { t, conditionProps } = props;

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState();
    const [traffic, setTraffic] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const listOrder = useSelector((state) => ({ ...{ items: [], affiliates: [], total: 0 }, ...state.traffics.orders }));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const cb = () => {
        dispatch(trafficAction.list(conditions));
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventCalcCommission = (item) => {
        setTraffic(item);
        window.LadiUI.showModal('modal-confirm-calc-commission-for-order');
    };

    const eventOkCalcCommissionForTraffic = () => {
        const data = { id: traffic._id, type: DefineConst.TRAFFIC_TYPE.ORDER };
        dispatch(trafficAction.calcCommissionForTraffic(data, cb));
    };

    const eventReCalcCommission = (item) => {
        setTraffic(item);
        window.LadiUI.showModal('modal-confirm-re-calc-commission-for-order');
    };

    const eventOkReCalcCommissionForTraffic = () => {
        const data = { id: traffic._id, type: DefineConst.TRAFFIC_TYPE.ORDER };
        dispatch(trafficAction.reCalcCommissionForTraffic(data, cb));
    };

    const eventCancelTraffic = (item) => {
        setTraffic(item);
        window.LadiUI.showModal('modal-confirm-cancel-commission-for-order');
    };

    const eventOkCancelCommissionForTraffic = () => {
        const data = { id: traffic._id, type: DefineConst.TRAFFIC_TYPE.ORDER };
        dispatch(trafficAction.cancelCommissionForTraffic(data, cb));
    };

    const eventDeleteTraffic = (item) => {
        setTraffic(item);
        window.LadiUI.showModal('modal-confirm-delete-order');
    };

    const eventOkDeleteTraffic = () => {
        const data = { id: traffic._id, type: DefineConst.TRAFFIC_TYPE.ORDER };
        dispatch(trafficAction.delete(data, cb));
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.TRAFFIC_STATUS_VALUES.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-warning"></i>
                        <div>
                            {DefineConst.TRAFFIC_STATUS.PENDING}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.TRAFFIC_STATUS_VALUES.CALCULATE_COMMISSION:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {DefineConst.TRAFFIC_STATUS.CALCULATE_COMMISSION}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.TRAFFIC_STATUS_VALUES.RE_CALCULATE_COMMISSION:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {DefineConst.TRAFFIC_STATUS.RE_CALCULATE_COMMISSION}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.TRAFFIC_STATUS_VALUES.CANCELED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-mute"></i>
                        <div>
                            {DefineConst.TRAFFIC_STATUS.CANCELED}
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setSelectedIds(ids);
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    useEffect(() => {
        setConditions({ ...conditions, ...conditionProps, type: DefineConst.TRAFFIC_TYPE.ORDER });
    }, [conditionProps]);

    useEffect(() => {
        if (conditions) dispatch(trafficAction.listOrder(conditions));
    }, [conditions]);

    return <>
        <table className="ladiui table text-left">
            <thead>
                <tr className="ladiui table-vertical header">
                    <th className="ladiui col-first" scope="col">
                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox"
                            name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                    </th>
                    <th>
                        {selectedIds.length > 0 ? <CheckAllAction ids={selectedIds} cb={() => cb} /> : 'Mã đơn'}
                    </th>
                    <th scope="col">Affiliate</th>
                    <th scope="col">Nguồn</th>
                    <th scope="col">Số tiền</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">Thuế</th>
                    <th scope="col">{t('COMMON.CREATED_AT')}</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {listOrder.total > 0
                    ? listOrder.items.map((item) => <tr className="ladiui table-vertical main" key={item._id}>
                        <td scope="row">
                            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element" name="checkbox" checked={selectedIds.includes(item._id)}
                                value={item._id} onChange={eventCheck} />
                        </td>
                        <td>{item.order_number}</td>
                        <td>{listOrder.affiliates.map((affiliate) => (affiliate._id === item.affiliate_id ? <Link key={affiliate._id} to={`/affiliates/${affiliate._id}`}>
                            {affiliate.email}</Link> : ''))}
                        </td>
                        <td>
                            <img src={item.source ? DefineConstAppIntegrate.INTEGRATE_APPS[item.source].logo : ''} height="20" />&nbsp;
                            {item.source ? DefineConstAppIntegrate.INTEGRATE_APPS[item.source].name : ''}
                        </td>
                        <td>
                            {item.total ? BaseHelper.formatMoney(item.total, '', systemCurrency) : BaseHelper.formatMoney(0, '', systemCurrency)}
                        </td>
                        <td>{getStatus(item.status)}</td>
                        <td>{item.tax ? BaseHelper.formatMoney(item.tax, systemCurrency) : BaseHelper.formatMoney(0, '', systemCurrency)}</td>
                        <td>{BaseHelper.formatStrToDate(item.created_at)}</td>
                        <td className="ladiui-dropdown">
                            <div className="ladiui btn-group">
                                <div className="ladiui dropdown">
                                    <button data-toggle="dropdown"
                                        className="ladiui-btn-dropdown dropdown-toggle">
                                        <i className="ladiui icon icon-ldp-dot"></i>
                                    </button>
                                    <ul className="ladiui dropdown-menu custom-dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1">
                                        {item.status === DefineConst.TRAFFIC_STATUS_VALUES.PENDING
                                            || item.status === DefineConst.TRAFFIC_STATUS_VALUES.CANCELED
                                            ? <li onClick={() => eventCalcCommission(item)}>
                                                <a className="ladiui dropdown-item" href="#/">
                                                    Tính hoa hồng
                                                </a>
                                            </li> : <></>
                                        }
                                        {item.status === DefineConst.TRAFFIC_STATUS_VALUES.CALCULATE_COMMISSION
                                            ? <li onClick={() => eventReCalcCommission(item)}>
                                                <a className="ladiui dropdown-item" href="#/">
                                                    Tính lại hoa hồng
                                                </a>
                                            </li> : <></>
                                        }
                                        {item.status === DefineConst.TRAFFIC_STATUS_VALUES.CALCULATE_COMMISSION
                                            || item.status === DefineConst.TRAFFIC_STATUS_VALUES.RE_CALCULATE_COMMISSION
                                            ? <li onClick={() => eventCancelTraffic(item)}>
                                                <a className="ladiui dropdown-item" href="#/">
                                                    Hủy hoa hồng
                                                </a>
                                            </li> : <></>
                                        }
                                        {item.status === DefineConst.TRAFFIC_STATUS_VALUES.PENDING
                                            || item.status === DefineConst.TRAFFIC_STATUS_VALUES.CANCELED
                                            ? <li onClick={() => eventDeleteTraffic(item)}>
                                                <a className="ladiui dropdown-item lastChild" href="#/">
                                                    Xoá
                                                </a>
                                            </li> : <></>
                                        }

                                    </ul>
                                </div>
                            </div>
                        </td>
                    </tr>)
                    : <tr><td colSpan={9}>{t('SOURCES.NO_ORDER_EXIST')}</td></tr>
                }
            </tbody>
        </table>
        {listOrder.total > 0
            ? <LadiPagination
                conditionsProp={conditions}
                listName={t('SOURCES.ORDER')}
                items={listOrder.items}
                total={listOrder.total}
                limitAction={selectLimit}
                pageAction={selectPage}
            /> : <></>}
        <ConfirmModal
            id="modal-confirm-delete-order"
            title="Bạn có chắc muốn xoá nguồn này không?"
            content="Sau khi xoá nguồn sẽ bị xoá vĩnh viễn bạn sẽ không thể xem lại được nữa"
            onOk={() => eventOkDeleteTraffic()}
            cancelText={t('SOURCES.CANCELED')}
            okText={t('SOURCES.OK')}
            classBtn="btn-danger"
        />

        <ConfirmModal
            id="modal-confirm-calc-commission-for-order"
            title="Bạn có chắc muốn tính hoa hồng cho nguồn này không?"
            content="Bạn chỉ có thể tính lại hoa hồng khi chúng chưa được lên hóa đơn."
            onOk={() => eventOkCalcCommissionForTraffic()}
            cancelText={t('SOURCES.CANCELED')}
            okText={t('SOURCES.OK')}
            classBtn="btn-primary"
        />

        <ConfirmModal
            id="modal-confirm-re-calc-commission-for-order"
            title="Bạn có chắc muốn tính lại hoa hồng cho nguồn này không?"
            content="Bạn chỉ có thể tính lại hoa hồng khi chúng chưa được lên hóa đơn."
            onOk={() => eventOkReCalcCommissionForTraffic()}
            cancelText={t('SOURCES.CANCELED')}
            okText={t('SOURCES.OK')}
            classBtn="btn-primary"
        />

        <ConfirmModal
            id="modal-confirm-cancel-commission-for-order"
            title="Bạn có chắc muốn hủy hoa hồng cho nguồn này không?"
            content="Dữ liệu hoa hồng cho nguồn này sẽ bị xóa vĩnh viễn."
            onOk={() => eventOkCancelCommissionForTraffic()}
            cancelText={t('SOURCES.CANCELED')}
            okText={t('SOURCES.OK')}
            classBtn="btn-primary"
        />
    </>;
}

export default (withTranslation()(Order));
