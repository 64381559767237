import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import config from '../config/config';

class SidebarItem extends React.Component {
    static propTypes = {
        keyStr: PropTypes.array,
        classLi: PropTypes.string,
        classNav: PropTypes.string,
        classIcon: PropTypes.string,
        to: PropTypes.any,
        title: PropTypes.string,
    }

    render() {
        const {
            classLi, classNav, classIcon, to, title, keyStr, t, children, scopes,
        } = this.props;
        const paths = window.location.pathname.split('/');
        let pathCheck = paths[1];
        if (scopes !== 'ADVERTISER' && !config.APP_AFFILIATE) {
            pathCheck = paths[2];
        }

        const classOpen = (keyStr.indexOf(pathCheck) >= 0) ? `${classLi} active` : classLi;
        return (
            <li className={classOpen} >
                {
                    to
                        ? <NavLink to={to} className={classNav}> <i className={classIcon} /> <span className="ladiui menu-text">{t(title)}</span> </NavLink>
                        : <a className={classNav}> <i className={classIcon} /> <span className="ladiui menu-text">{t(title)}</span></a>
                }
                {
                    children
                    && <ul className="ladiui submenu" onClick={(event) => {
                        if (document.body.classList.contains('mini-navbar')) {
                            event.currentTarget.classList.remove('show');
                        }
                    }}>
                        <li></li>
                        {children}
                    </ul>
                }

            </li>
        );
    }
}

export default withTranslation()(SidebarItem);
