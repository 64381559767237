import { isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import actionAppIntegrate from '../../../../redux/futures/integrate_app/actions';

function FormNhanh(props) {
    const { t, dataApp, cb } = props;
    const isCreate = props.isCreate === undefined ? true : props.isCreate;

    const dispatch = useDispatch();
    const [appIntegrate, setAppIntegrate] = useState({});
    const [isConnect, setIsConnect] = useState(false);
    const [openWindowsConfirm, setOpenWindowsConfirm] = useState(false);

    const urlOauthNhanh = useSelector((state) => state.appIntegrate.urlNhanh);
    const nhanhAccess = useSelector((state) => state.appIntegrate.nhanhAccess);

    const eventChangeData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = {};
        data[name] = value;
        setAppIntegrate({ ...appIntegrate, ...data });
        if (isFunction(cb)) cb(data);
    };
    const cbAccessNhanh = () => {
        setIsConnect(true);
    };

    const eventListenerNhanh = (e) => {
        const data = e.data;
        if (data.code) {
            const reqDataAccess = { code: data.code };
            dispatch(actionAppIntegrate.getAccessNhanh(reqDataAccess, cbAccessNhanh));
        }
    };

    const cbOpenWindowConfirm = () => {
        setOpenWindowsConfirm(true);
    };

    const eventConnect = () => {
        if (isConnect) {
            setIsConnect(false);
        } else {
            setOpenWindowsConfirm(false);
            dispatch(actionAppIntegrate.integrateAppNhanh({}, cbOpenWindowConfirm));
        }
    };

    useEffect(() => {
        if (dataApp) setAppIntegrate(dataApp);
    }, [dataApp]);

    useEffect(() => {
        if (!openWindowsConfirm || !urlOauthNhanh) return;
        const width = 500;
        const height = 650;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);
        const targetWin = window.open(urlOauthNhanh,
            'popupAuthNhanh', `width=${width}, height=${height}, left=${left}, top=${top}`);
        targetWin.focus();
        window.addEventListener('message', eventListenerNhanh, false);
    }, [urlOauthNhanh, openWindowsConfirm]);

    useEffect(() => {
        if (isConnect && nhanhAccess) {
            const data = { ...appIntegrate, token: nhanhAccess.access_token, shop_id: nhanhAccess.business_id };
            setAppIntegrate(data);
            if (isFunction(cb)) cb(data);
        }
    }, [nhanhAccess]);
    return <>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="name">Tên</label>
            <input id="name" name="name" className="ladiui form-control" placeholder="Nhập Tên"
                value={appIntegrate.name || ''}
                onChange={(e) => eventChangeData(e)}
            />
        </div>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="api_url">Shop ID</label>
            <div className="ladiui flex-row">
                <input id="api_url" name="api_url" className="ladiui form-control mr-8" placeholder="" readOnly
                    defaultValue={appIntegrate.shop_id || ''}
                />
                {isCreate ? <button className="ladiui btn btn-primary" onClick={() => eventConnect()}>{isConnect ? 'Kết nối lại' : 'Kết nối'}</button> : <></>}
            </div>
        </div>
    </>;
}

export default (withTranslation()(FormNhanh));
