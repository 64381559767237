import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.DAILY_REPORT: {
            return {
                ...state,
                action: types.DAILY_REPORT,
                dailyReport: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.DAILY_REPORT_PUBLISHER: {
            return {
                ...state,
                action: types.DAILY_REPORT_PUBLISHER,
                dailyReportPublisher: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.EXPORT_STATISTICS: {
            return {
                ...state,
                action: types.EXPORT_STATISTICS,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
