import React, { Fragment, useEffect } from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import appConfig from '../../config/app';
import { appLocalStorage } from '../../localforage';
import CommissionPlan from '../commissions/CommissionPlan';
import GroupAffiliate from '../groups/GroupAffiliate';
import GeneralSetting from './GeneralSetting';
import AffiliateSetting from './AffiliateSetting';
import EmailSetting from './EmailSetting';
import Staff from '../staffs/Staff';
import CouponCode from '../coupons/CouponCode';
import SDKSetting from './SDKSetting';
import History from './History';
import PackageInfo from './PackageInfo';
import IPSetting from './IPSetting';

function Setting(props) {
    const { t } = props;

    useEffect(() => {

    });

    const pathName = window.location.pathname;
    const lastPath = pathName.split('/').pop();

    return (
        <Fragment>
            <div className="ladiui content-list-landingpage">
                <div className="ladiui setting-content-left">
                    <div className="ladiui setting-content">
                        <Link to="/settings/general" className={pathName.indexOf('settings/general') >= 0 || lastPath === 'settings' >= 0
                            ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>Cài đặt chung</Link>
                    </div>
                    <div className="ladiui setting-content">
                        <Link to="/settings/affiliate" className={pathName.indexOf('settings/affiliate') >= 0
                            ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>Cài đặt Affiliate</Link>
                    </div>
                    <div className="ladiui setting-content">
                        <Link to="/settings/group-affiliate" className={pathName.indexOf('settings/group-affiliate') >= 0
                            ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>Nhóm Affiliate</Link>
                    </div>
                    <div className="ladiui setting-content">
                        <Link to="/settings/commission-plan"
                            className={pathName.indexOf('settings/commission-plan') >= 0
                                ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>Cài đặt gói hoa hồng</Link>
                    </div>
                    <div className="ladiui setting-content">
                        <Link to="/settings/coupon-code" className={pathName.indexOf('settings/coupon-code') >= 0
                            ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>{t('STORES.MANAGER_COUPON_CODE')}</Link>
                    </div>
                    <div className="ladiui setting-content">
                        <Link to="/settings/email" className={pathName.indexOf('settings/email') >= 0
                            ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>{t('STORES.EMAIL_SETTING')}</Link>
                    </div>
                    <div className="ladiui setting-content">
                        <Link to="/settings/member" className={pathName.indexOf('settings/member') >= 0
                            ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>Thành viên</Link>
                    </div>
                    <div className="ladiui setting-content">
                        <Link to="/settings/history" className={pathName.indexOf('settings/history') >= 0
                            ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>Lịch sử hoạt động</Link>
                    </div>
                    <div className="ladiui setting-content">
                        <Link to="/settings/sdk" className={pathName.indexOf('settings/sdk') >= 0
                            ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>{t('STORES.INTEGRATION_SDK')}</Link>
                    </div>
                    <div className="ladiui setting-content">
                        <Link to="/settings/ip" className={pathName.indexOf('settings/ip') >= 0
                            ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>IP</Link>
                    </div>
                    <div className="ladiui setting-content">
                        <Link to="/settings/package-info" className={pathName.indexOf('settings/package-info') >= 0
                            ? 'ladiui subtitle-2 content-tag-text color-2 active' : 'ladiui subtitle-2 content-tag-text color-2'}>Thông tin gói sử dụng</Link>
                    </div>
                </div>
            </div>
            <div className="ladiui content-main">
                <Switch>
                    <Route path="/settings/general">
                        <GeneralSetting />
                    </Route>
                    <Route path="/settings/affiliate">
                        <AffiliateSetting />
                    </Route>
                    <Route path="/settings/member">
                        <Staff />
                    </Route>
                    <Route path="/settings/group-affiliate">
                        <GroupAffiliate />
                    </Route>
                    <Route path="/settings/history">
                        <History />
                    </Route>
                    <Route path="/settings/email">
                        <EmailSetting />
                    </Route>
                    <Route path="/settings/commission-plan">
                        <CommissionPlan />
                    </Route>
                    <Route path="/settings/sdk">
                        <SDKSetting />
                    </Route>
                    <Route path="/settings/coupon-code">
                        <CouponCode />
                    </Route>
                    <Route path="/settings/ip">
                        <IPSetting />
                    </Route>
                    <Route path="/settings/package-info">
                        <PackageInfo />
                    </Route>

                    <Route>
                        Đang trong giai đoạn phát triển
                    </Route>
                </Switch>
            </div>
        </Fragment>);
}

export default (withTranslation()(Setting));
