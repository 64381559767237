import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const dailyReport = (data, cb) => ({
    type: types.DAILY_REPORT,
    meta: {
        endpoint: endpoint.DAILY_REPORT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const dailyReportPublisher = (data, cb) => ({
    type: types.DAILY_REPORT_PUBLISHER,
    meta: {
        endpoint: endpoint.DAILY_REPORT_PUBLISHER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const exportStatistics = (data, cb) => ({
    type: types.EXPORT_STATISTICS,
    meta: {
        endpoint: endpoint.EXPORT_STATISTICS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

export default {
    dailyReport,
    dailyReportPublisher,
    exportStatistics,
};
