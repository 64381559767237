import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data, cb) => ({
    type: types.LIST_CUSTOMER,
    meta: {
        endpoint: endpoint.LIST_CUSTOMER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const create = (data, cb) => ({
    type: types.CREATE_CUSTOMER,
    meta: {
        endpoint: endpoint.CREATE_CUSTOMER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const show = (id, cb) => ({
    type: types.SHOW_CUSTOMER,
    meta: {
        endpoint: endpoint.SHOW_CUSTOMER,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const update = (data, cb) => ({
    type: types.UPDATE_CUSTOMER,
    meta: {
        endpoint: endpoint.UPDATE_CUSTOMER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteCoupon = (data, cb) => ({
    type: types.DELETE_CUSTOMER,
    meta: {
        endpoint: endpoint.DELETE_CUSTOMER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteMulti = (data, cb) => ({
    type: types.DELETE_MULTI_CUSTOMER,
    meta: {
        endpoint: endpoint.DELETE_MULTI_CUSTOMER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const exportCustomer = (data, cb) => ({
    type: types.EXPORT_CUSTOMER,
    meta: {
        endpoint: endpoint.EXPORT_CUSTOMER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

export default {
    list,
    create,
    show,
    update,
    delete: deleteCoupon,
    deleteMulti,
    exportCustomer,
};
