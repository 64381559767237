import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';
import BaseHelper from '../../../../helpers/BaseHelper';
import DefineConst from '../../DefineConst';

function CommissionPlanPaymentBoxMaxAmount(props) {
    const {
        t, paymentFor, maxAmountCommissionProp, cb,
    } = props;
    const [maxAmountCommission, setPaymentPriceRange] = useState({ enable: false });

    const renderExplain = () => {
        let explain = <></>;
        if (maxAmountCommission.max) {
            explain = <>(*) Hoa hồng tối đa cho mỗi yêu cầu là {BaseHelper.formatMoney(maxAmountCommission.max)}</>;
        }
        return explain;
    };

    const eventChangeInput = (e) => {
        const name = e.target.name;
        let value;
        if (
            e.target.tagName.toLowerCase() === 'input'
            && e.target.getAttribute('type') === 'checkbox'
        ) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        const data = { ...maxAmountCommission };
        data[name] = value;
        setPaymentPriceRange(data);
        cb(data);
    };

    const changeInputValue = (fieldName, value) => {
        const data = { ...maxAmountCommission };
        data[fieldName] = value;
        setPaymentPriceRange(data);
        cb(data);
    };

    useEffect(() => {
        setPaymentPriceRange({ ...maxAmountCommission, ...maxAmountCommissionProp });
    }, [maxAmountCommissionProp]);

    return <>
        <div className="ladiui clearfix pt-20">
            <label className="ladiui title-text-form-lever2 left">Thiết lập hoa hồng tối đa</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox"
                        name="enable"
                        checked={maxAmountCommission !== null && (maxAmountCommission.enable || false)}
                        onChange={(e) => eventChangeInput(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Hoa hồng tối đa mà affiliate có thể nhận được với mỗi yêu cầu</div>
        </div>
        {maxAmountCommission && maxAmountCommission.enable
            ? <>
                <div className="ladiui clearfix pt-20">
                    {/* <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Giá tối thiểu</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <input type="number" className="ladiui form-control btn-sm w-175" placeholder="Giá tối thiểu" min="0" name="min"
                                disabled={maxAmountCommission.interval_type === DefineConst.PAYMENT_INTERVAL_TYPE.OFF}
                                value={maxAmountCommission.min || ''}
                                onChange={(e) => eventChangeInput(e)} />
                        </div>

                    </div> */}
                    <div className="ladiui left">
                        <label className="ladiui title-text-form-lever3">Hoa hồng tối đa</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                value={maxAmountCommission.max || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Hoa hồng tối đa"
                                onValueChange={(values) => changeInputValue('max', values.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>
                    {renderExplain()}
                </div>
            </>
            : <></>}
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBoxMaxAmount));
