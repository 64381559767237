import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';
import styles from '../../../../assets/css/custom.module.css';
import ChartConfig from '../../../dashboard/ChartConfig';

function ChartCommission(props) {
    const options = ChartConfig.options;
    const chartLang = ChartConfig.lang;
    const [highChartOptions, setHighChartOptions] = useState(options);

    const report = useSelector((state) => (state.statistics.dailyReportPublisher));

    useEffect(() => {
        Highcharts.setOptions({
            lang: chartLang,
        });
    }, []);

    useEffect(() => {
        const pointStartPendingCommission = report && report.chartData ? new Date(report.chartData.chartDataPendingCommission.start) : new Date();
        const pointStartApprovedCommission = report && report.chartData ? new Date(report.chartData.chartDataApprovedCommission.start) : new Date();
        const pointStartInvoicedCommission = report && report.chartData ? new Date(report.chartData.chartDataInvoicedCommission.start) : new Date();

        const pointStartNumberPendingCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberPendingCommission.start) : new Date();
        const pointStartNumberApprovedCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberApprovedCommission.start) : new Date();
        const pointStartNumberInvoicedCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberInvoicedCommission.start) : new Date();

        const series = [
            {
                name: 'Số hoa hồng chờ duyệt',
                color: '#E7B269',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberPendingCommission.data : [],
                pointStart: pointStartNumberPendingCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Số hoa hồng tạm duyệt',
                color: 'rgba(28, 0, 194, 0.65)',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberApprovedCommission.data : [],
                pointStart: pointStartNumberApprovedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Số hoa hồng đã duyệt',
                color: '#41B983',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberInvoicedCommission.data : [],
                pointStart: pointStartNumberInvoicedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Hoa hồng chờ duyệt',
                color: '#E7B269',
                data: report && report.chartData ? report.chartData.chartDataPendingCommission.data : [],
                pointStart: pointStartPendingCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
            {
                name: 'Hoa hồng tạm duyệt',
                color: 'rgba(28, 0, 194, 0.65)',
                data: report && report.chartData ? report.chartData.chartDataApprovedCommission.data : [],
                pointStart: pointStartApprovedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
            {
                name: 'Hoa hồng đã duyệt',
                color: '#41B983',
                data: report && report.chartData ? report.chartData.chartDataInvoicedCommission.data : [],
                pointStart: pointStartInvoicedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
        ];
        const tooltip = {
            dateTimeLabelFormats: { day: '%A, %e/%b' },
        };
        setHighChartOptions({ ...highChartOptions, series, tooltip });
    }, [report]);

    return <>
        <div className={`${styles['box-statistics-publisher']} ${styles['no-height']} ${styles['mt-16']} ${styles['p-0']}`}>
            <div className={styles['box-title']}>
                <label className={styles.bold}>Thống kê hoa hồng và chuyển đổi</label>
            </div>
            <div className="box-body" style={{ overflowX: 'auto' }}>
                <div style={{ minWidth: '650px' }}>
                    <HighchartsReact highcharts={Highcharts} options={highChartOptions} />
                </div>
            </div>
            <div className="box-footer"></div>
        </div>
    </>;
}

export default ChartCommission;
