import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import appConfig from '../../../config/app';
import actionStore from '../../../redux/futures/store/actions';
import BaseHelper from '../../../helpers/BaseHelper';
import DefineConst from '../DefineConst';
import LadiDropdown from '../../../components/LadiDropdown';
import MailParams from './MailParams';

// eslint-disable-next-line max-lines-per-function
function EmailTemplate(props) {
    const { t } = props;
    const [type, setType] = useState();
    const [templateEmail, setTemplateEmail] = useState(null);
    const [templateEmailTmp, setTemplateEmailTmp] = useState(null);
    const rsTemplateEmail = useSelector((state) => (state.store.templateEmail));

    const dispatch = useDispatch();

    const getListTemplateEmail = () => [
        { name: 'Mẫu email đối soát cá nhân', value: 'confirm_commission' },
        { name: 'Mẫu email đối soát công ty', value: 'confirm_commission_company' },
        { name: 'Mẫu email đăng ký affiliate thành công', value: 'registered_affiliate' },
        { name: 'Mẫu email affiliate đã được duyệt', value: 'approved_affiliate' },
    ];

    const selectTemplate = (val) => {
        setType(val);
        dispatch(actionStore.getTemplateEmail({ type: val }));
    };

    const eventUpdate = () => {
        const data = {
            type,
            content: templateEmailTmp,
        };
        dispatch(actionStore.updateTemplateEmail(data));
    };

    const eventCancel = () => {

    };

    const showModalExplain = () => {
        window.LadiUI.showModal('modal-explain-mail');
    };

    const renderMailParams = () => {
        let mailParams = [];
        switch (type) {
            case 'confirm_commission':
                mailParams = MailParams.CONFIRM_COMMISSION;
                break;
            case 'confirm_commission_company':
                mailParams = MailParams.CONFIRM_COMMISSION_COMPANY;
                break;
            case 'registered_affiliate':
                mailParams = MailParams.REGISTERED_EMAIL;
                break;
            case 'approved_affiliate':
                mailParams = MailParams.APPROVED_EMAIL;
                break;
            default:
        }
        return mailParams.map((item, index) => <tr key={index} className="ladiui table-vertical main"><td>{item.params}</td><td>{item.name}</td></tr>);
    };

    useEffect(() => {
        if (rsTemplateEmail) {
            setTemplateEmail(rsTemplateEmail);
            setTemplateEmailTmp(rsTemplateEmail);
        }
    }, [rsTemplateEmail]);

    return <>
        <div className="ladiui content-main-title flex-row">
            <div className="ladiui">
                <div className="ladiui main-title">
                    Mẫu email
                </div>
                <span className="color-1">Đăng ký và quản lý mẫu email.</span>
            </div>
        </div>
        <div className="ladiui access content-main-tool flex-row" style={{ marginTop: '10px', justifyContent: 'space-between' }}>
            <div className="">
                <LadiDropdown
                    labelDropdown="Chọn mẫu email"
                    defaultTextProp="Chọn mẫu email"
                    listProp={getListTemplateEmail()}
                    valueProp={null}
                    hideLabel={true}
                    validProp={true}
                    classDropdown="custom-dropdown-2 dropdown-310"
                    cbProp={selectTemplate}
                />
            </div>
            {type
                ? <div className="ladiui right">
                    <a className="ladiui link flex" onClick={showModalExplain}>Chú giải&nbsp;
                        <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" width={15} alt="" /></a>
                </div>
                : <></>}
        </div>
        {templateEmail
            ? <div className="mt-24">
                <Editor
                    apiKey='d7comlpl6oluuhzdhk7kog9ell01o7otr0awkzev7cxd08wt'
                    onEditorChange={(contents) => setTemplateEmailTmp(contents)}
                    initialValue={templateEmail || ''}
                    init={{
                        skin: 'snow',
                        height: 500,
                        menubar: false,
                        statusbar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code wordcount',
                        ],
                        toolbar: 'undo redo | formatselect | fontselect |'
                            + 'bold italic backcolor | alignleft aligncenter '
                            + 'alignright alignjustify | bullist numlist outdent indent link | '
                            + 'removeformat | code',
                        content_style: 'body {font-size:14px; color:#464646;}',
                    }}
                />
                <div className="ladiui form-group">
                    <button className="ladiui btn btn-primary right" onClick={eventUpdate}>{t('COMMON.UPDATE')}</button>
                    <button className="ladiui btn right btn-secondary" style={{ marginRight: '40px' }} onClick={eventCancel}>{t('COMMON.CANCELED')}</button>
                </div>
            </div> : <></>}

        <div className="ladiui modal" id="modal-explain-mail">
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                <div className="ladiui modal-content">
                    <div className="ladiui modal-header">
                        <label className="ladiui title-text-form-lever1">Chú giải</label>
                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui modal-body">
                        <table className="ladiui table text-left" style={{ marginTop: 0 }}>
                            <tbody>
                                {renderMailParams()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    </>;
}

export default (withTranslation()(EmailTemplate));
