import { isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

function FormSdk(props) {
    const { t, dataApp, cb } = props;
    const isCreate = props.isCreate === undefined ? true : props.isCreate;

    const [appIntegrate, setAppIntegrate] = useState({});

    const eventChangeData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = {};
        data[name] = value;
        setAppIntegrate({ ...appIntegrate, ...data });
        if (isFunction(cb)) cb(data);
    };

    useEffect(() => {
        if (dataApp) setAppIntegrate(dataApp);
    }, [dataApp]);
    return <>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="name">Tên</label>
            <input id="name" name="name" className="ladiui form-control" placeholder="Nhập Tên"
                value={appIntegrate.name || ''}
                onChange={(e) => eventChangeData(e)}
            />
        </div>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="token">Tên miền</label>
            <input id="shop_id" name="shop_id" className="ladiui form-control" placeholder="Nhập tên miền"
                value={appIntegrate.shop_id || ''}
                onChange={(e) => eventChangeData(e)}
                readOnly={!isCreate}
            />
        </div>
    </>;
}

export default (withTranslation()(FormSdk));
