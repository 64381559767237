import moment from 'moment-timezone';
import {
    map, isNumber, isBoolean, isEmpty, includes, find, isEqual, reduce, isNull,
} from 'lodash';
import Cookies from 'universal-cookie';
import i18next from 'i18next';
import React from 'react';
import validator from 'validator';
import appConfig from '../config/app';

const crypto = require('crypto');

class BaseHelper {
    getFromDate(date) {
        if (!this.getMoment().isMoment(date)) {
            return date;
        }

        const _date = date.clone();
        _date.set({
            hour: 0,
            minute: 0,
            second: 0,
            milisecond: 0,
        });

        return _date;
    }

    getToDate(date) {
        if (!this.getMoment().isMoment(date)) {
            return date;
        }

        const _date = date.clone();
        _date.set({
            hour: 23,
            minute: 59,
            second: 59,
            milisecond: 999,
        });

        return _date;
    }

    getMoment() {
        if (!this.moment) {
            this.moment = moment;
        }

        return this.moment;
    }

    formatStrToDate(str, format = appConfig.DEFAULT_FORMAT_DATE_TIME) {
        try {
            const date = this.getMoment()(str || '');
            if (!date.isValid()) {
                return '';
            }

            return date.format(format);
        } catch (e) {
            return '';
        }
    }

    formatNumber(data, _default = 0) {
        if (!data) {
            return 0;
        }
        let formatter = new Intl.NumberFormat('en-US');
        return formatter.format(data);

        // return data.toLocaleString('en-EN', { minimumFractionDigits: _default });
    }

    formatMoney(data, prefix = '', postFix = 'đ') {
        let postFixNew = 'đ';
        switch (postFix) {
            case 'VND': postFixNew = 'đ'; break;
            case 'USD': postFixNew = '$'; break;
            default:
        }
        return `${prefix} ${this.formatNumber(data)} ${postFixNew}`;
    }

    getText(data) {
        return data || '';
    }

    hasAllEmptyValues(obj) {
        let result = true;

        map(obj, (value) => {
            if (value) {
                result = false;
            }
        });

        return result;
    }

    resetForm(id) {
        window.LadiUI.forEach(window.LadiUI.querySelector(`#${id} .ladiui input`), (e) => {
            e.classList.remove('error');
        });
    }

    isEmpty(...values) {
        let result = false;

        map(values, (value) => {
            if (!(isNumber(value) || isBoolean(value)) && isEmpty(value)) {
                result = true;
            }
        });

        return result;
    }

    getRandomInt(max = 10000) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    generateRandomString(length) {
        const id = crypto.randomBytes(length).toString('hex');
        return id;
    }

    parseFloat(value, _default = 0, fix_length = 2) {
        if (this.isEmpty(value) || isNaN(value)) {
            return parseFloat(parseFloat(_default).toFixed(fix_length));
        }

        return parseFloat(parseFloat(value).toFixed(fix_length));
    }

    parseInt(value, _default = 0) {
        if (this.isEmpty(value) || isNaN(value)) {
            return _default;
        }

        return parseInt(value, 10);
    }

    setCookie(key, value, day = appConfig.COOKIE.EXPIRE) {
        const expire = new Date();
        expire.setDate(new Date().getDate() + day);
        const cookies = new Cookies();
        let options = { path: '/', expires: expire };
        if (window.location.protocol == 'https:') {
            options.sameSite = 'none';
            // options.httpOnly = true;
            options.secure = true;
        }
        cookies.set(key, value, options);
    }

    getCookie(key) {
        const cookies = new Cookies();

        return cookies.get(key);
    }

    removeCookie(key) {
        try {
            const cookies = new Cookies();
            let options = { path: '/' };
            if (window.location.protocol == 'https:') {
                options.sameSite = 'none';
                // options.httpOnly = true;
                options.secure = true;
            }
            cookies.remove(key, options);
        } catch (err) {
            console.log(err);
        }
    }

    calculateDiscountFee(price, type, value) {
        let fee = 0;
        if (type === appConfig.DISCOUNT.TYPE.PERCENT.value) {
            fee = this.parseFloat(price) * this.parseFloat(value / 100);
        } else if (type === appConfig.DISCOUNT.TYPE.FIXED.value) {
            fee = this.parseFloat(value);
        }

        if (fee < 0) {
            fee = 0;
        }

        if (fee > price) {
            fee = price;
        }

        return fee;
    }

    copyToClipboard(data) {
        const textField = document.createElement('textarea');
        textField.innerHTML = data;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }

    getStaffNameById(store, ladiUID, user) {
        const staffs = store ? store.staffs : null;
        const meID = user ? user.ladi_uid : null;

        if (!ladiUID) {
            return '';
        }

        const matched = find(staffs, (item) => item === ladiUID);

        if (!matched) {
            return '';
        }

        if (meID && meID === matched) {
            return i18next.t('COMMON.ME');
        }
        return matched.name;
    }

    compareShippingAndBilling(shippingAddress, billingAddress) {
        return isEqual(shippingAddress, billingAddress);
    }

    sanitizeUTF8(text) {
        const vnTexts = ['á', 'à', 'ả', 'ã', 'ạ', 'â', 'ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'ă', 'ắ', 'ằ', 'ẳ', 'ẵ', 'ặ', 'đ', 'é', 'è', 'ẻ', 'ẽ', 'ẹ', 'ê', 'ế', 'ề', 'ể', 'ễ', 'ệ', 'í', 'ì', 'ỉ', 'ĩ', 'ị', 'ó', 'ò', 'ỏ', 'õ', 'ọ', 'ô', 'ố', 'ồ', 'ổ', 'ỗ', 'ộ', 'ơ', 'ớ', 'ờ', 'ở', 'ỡ', 'ợ', 'ú', 'ù', 'ủ', 'ũ', 'ụ', 'ư', 'ứ', 'ừ', 'ử', 'ữ', 'ự', 'ý', 'ỳ', 'ỷ', 'ỹ', 'ỵ', 'Á', 'À', 'Ả', 'Ã', 'Ạ', 'Â', 'Ấ', 'Ầ', 'Ẩ', 'Ẫ', 'Ậ', 'Ă', 'Ắ', 'Ằ', 'Ẳ', 'Ẵ', 'Ặ', 'Đ', 'É', 'È', 'Ẻ', 'Ẽ', 'Ẹ', 'Ê', 'Ế', 'Ề', 'Ể', 'Ễ', 'Ệ', 'Í', 'Ì', 'Ỉ', 'Ĩ', 'Ị', 'Ó', 'Ò', 'Ỏ', 'Õ', 'Ọ', 'Ô', 'Ố', 'Ồ', 'Ổ', 'Ỗ', 'Ộ', 'Ơ', 'Ớ', 'Ờ', 'Ở', 'Ỡ', 'Ợ', 'Ú', 'Ù', 'Ủ', 'Ũ', 'Ụ', 'Ư', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Ý', 'Ỳ', 'Ỷ', 'Ỹ', 'Ỵ'];
        const replaceText = ['a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'd', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'i', 'i', 'i', 'i', 'i', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'y', 'y', 'y', 'y', 'y', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'D', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'I', 'I', 'I', 'I', 'I', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'Y', 'Y', 'Y', 'Y', 'Y'];
        let index;
        for (let i = 0; i < vnTexts.length; i++) {
            index = text.indexOf(vnTexts[i]);
            if (index > -1) {
                text = text.replace(new RegExp(vnTexts[i], 'g'), replaceText[i]);
            }
        }
        return text;
    }

    getAliasName(name) {
        if (!name) {
            return '';
        }

        name = this.sanitizeUTF8(name);
        name = name.replace(/ /g, '-');
        name = name.replace(/[^A-Za-z0-9-_\.]/g, '');
        name = name.replace(/\.+/g, '');
        name = name.replace(/-+/g, '-');
        name = name.replace(/_+/g, '_');
        name = name.toLowerCase();
        return name;
    }

    getTrackingHref = (shipping) => {
        if (!shipping.tracking_number) {
            return <span>N/A</span>;
        }

        const partnerCode = shipping.shipping_partner_code;
        switch (partnerCode) {
            case appConfig.SHIPPING_PARTNERS.GHN.CODE:
                return <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://track.ghn.vn/order/tracking?code=${shipping.tracking_number}`}
                >
                    {shipping.tracking_number}
                </a>;
            case appConfig.SHIPPING_PARTNERS.GHTK.CODE:
                return <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://khachhang.giaohangtietkiem.vn/khach-hang/tracking/order/${shipping.tracking_number}`}
                >
                    {shipping.tracking_number}
                </a>;
            case appConfig.SHIPPING_PARTNERS.VNPOST.CODE:
                return <a
                    target="_blank"
                    href={`http://www.vnpost.vn/vi-vn/dinh-vi/buu-pham?key=${shipping.tracking_number}`}
                    rel="noopener noreferrer"
                >
                    {shipping.tracking_number}
                </a>;
            default:
                break;
        }

        return <span>{shipping.tracking_number}</span>;
    }

    generateBarCode = (html, option = {}) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        const barcodes = div.querySelectorAll('#barcode');
        if (!barcodes) {
            return div;
        }

        barcodes.forEach((barcode) => {
            const val = barcode.getAttribute('val');
            if (val) {
                window.JsBarcode(barcode, val, option);
            }
        });

        return div;
    }

    print = (html, option) => {
        const div = this.generateBarCode(html, option);
        const printwindow = window.frames.print_frame;
        printwindow.document.body.innerHTML = '<style>@page{size:auto;margin:0}</style>';
        printwindow.document.body.innerHTML += div.innerHTML;
        setTimeout(() => {
            printwindow.document.close();
            printwindow.focus();
            printwindow.print();
            printwindow.close();
        }, 250);
    }

    isDevelopEnv = () => process.env.REACT_APP_ENVIRONMENT !== 'production'

    getDisplayName = (name) => {
        if (!name) {
            return '';
        }
        const words = name.split(' ');
        let result = reduce(words, (result, item) => result + item.substring(0, 1).toUpperCase(), '');

        result = result.substring(0, 2);

        return result;
    }

    checkImageUrl(url, timeout) {
        return new Promise((resolve, reject) => {
            const _timeout = timeout || 500;
            const img = new Image();

            let timer;
            img.onerror = img.onabort = function () {
                clearTimeout(timer);
                reject('error');
            };

            img.onload = function () {
                clearTimeout(timer);
                resolve('success');
            };

            timer = setTimeout(() => {
                reject('timeout');
            }, _timeout);

            img.src = url;
        });
    }

    decodeEntities(encodedString) {
        const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
        const translate = {
            nbsp: ' ',
            amp: '&',
            quot: '"',
            lt: '<',
            gt: '>',
        };
        return encodedString.replace(translate_re, (match, entity) => translate[entity]).replace(/&#(\d+);/gi, (match, numStr) => {
            const num = parseInt(numStr, 10);
            return String.fromCharCode(num);
        });
    }

    encodeEntities(str) {
        return str.replace(/[\u00A0-\u9999<>\&]/gim, (i) => `&#${i.charCodeAt(0)};`);
    }

    isJson(item) {
        item = typeof item !== 'string' ? JSON.stringify(item) : item;
        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }

        if (typeof item === 'object' && item !== null) {
            return true;
        }

        return false;
    }

    checkScope(role, scopes, group, action) {
        if (role === appConfig.ROLE.OWNER.CODE) {
            return true;
        }
        const actions = scopes[group];
        if (actions === true) {
            return true;
        }

        if (Array.isArray(actions) && includes(actions, action)) {
            return true;
        }

        return false;
    }

    momentToDate(moment) {
        return new Date(
            moment.year(),
            moment.month(),
            moment.date(),
            moment.hours(),
            moment.minutes(),
            moment.seconds(),
            moment.milliseconds(),
        );
    }

    convertStringToDate(str) {
        if (isNull(str) || str === 'null' || isEmpty(str)) {
            return null;
        }

        return moment(str).toDate();
    }

    formatText(string) {
        let str = string;
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
        str = str.replace(/đ/g, 'd');
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
        str = str.replace(/Đ/g, 'D');
        str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
        str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
        str = str.replace(/ + /g, ' ');
        str = str.trim();
        str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
        return str;
    }

    getNodeIndex(n) {
        let i = 0;
        if (n) {
            while (n = n.previousSibling) i++;
        }
        return i;
    }

    saveRangePosition(containerEl) {
        try {
            const range = window.getSelection().getRangeAt(0);
            let sC = range.startContainer; let eC = range.endContainer;
            const A = [];
            while (sC !== containerEl) {
                A.push(this.getNodeIndex(sC));
                sC = sC.parentNode;
            }
            const B = [];
            while (eC !== containerEl) {
                B.push(this.getNodeIndex(eC));
                eC = eC.parentNode;
            }
            return {
                sC: A, sO: range.startOffset, eC: B, eO: range.endOffset,
            };
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    saveRangePositionFull(containerEl) {
        try {
            const range = window.getSelection().getRangeAt(0);
            let sC = range.startContainer; let eC = range.endContainer;
            const A = [];
            while (sC !== containerEl) {
                A.push(this.getNodeIndex(sC));
                sC = sC.parentNode;
            }
            const B = [];
            while (eC !== containerEl) {
                B.push(this.getNodeIndex(eC));
                eC = eC.parentNode;
            }
            return {
                sC: A, sO: range.startOffset, eC: B, eO: range.endOffset,
            };
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    getWord(rp, containerEl) {
        // return '';
        if (window.getSelection().toString().replace(/\u00a0/g, ' ').length > 0) {
            return '';
        }
        if (window.getSelection && window.getSelection().getRangeAt) {
            const s = window.getSelection();
            const range = s.getRangeAt(0);
            if (!rp) {
                var sC = range.startContainer; var
                    eC = range.endContainer;
                const A = []; while (sC !== containerEl) { A.push(this.getNodeIndex(sC)); sC = sC.parentNode; }
                const B = []; while (eC !== containerEl) { B.push(this.getNodeIndex(eC)); eC = eC.parentNode; }
                rp = {
                    sC: A, sO: range.startOffset, eC: B, eO: range.endOffset,
                };
            }

            const node = s.anchorNode;
            if (range.startContainer.length > 0) {
                while (!range.toString().replace(/\u00a0/g, ' ').endsWith(' ')
                    && !range.toString().replace(/\u00a0/g, ' ').startsWith(' ') && range.startOffset >= 1
                ) {
                    range.setStart(node, (range.startOffset - 1));
                }
            }
            if (range.toString() !== '') {
                while (!range.toString().replace(/\u00a0/g, ' ').endsWith(' ')
                    && !range.toString().replace(/\u00a0/g, ' ').startsWith(' ') && range.endOffset < range.endContainer.length
                ) {
                    range.setEnd(node, range.endOffset + 1);
                }
            }

            const str = range.toString().trim();
            let x; let C; var sC = containerEl; var
                eC = containerEl;
            C = rp.sC; x = C.length; while (x--) sC = sC.childNodes[C[x]];
            C = rp.eC; x = C.length; while (x--) eC = eC.childNodes[C[x]];
            range.setStart(sC, rp.sO);
            range.setEnd(eC, rp.eO);
            s.removeAllRanges();
            s.addRange(range);
            return str;
        }
    }

    restoreRangePosition(containerEl, rp) {
        try {
            const sel = window.getSelection(); const range = sel.getRangeAt(0);
            let x; let C; let sC = containerEl; let eC = containerEl;
            C = rp.sC;
            x = C.length;
            while (x-- && sC && sC.childNodes.length > 0) {
                sC = sC.childNodes[C[x]];
            }
            C = rp.eC;
            x = C.length;
            while (x-- && eC && eC.childNodes.length > 0) {
                eC = eC.childNodes[C[x]];
            }

            range.setStart(sC, rp.sO);
            range.setEnd(eC, rp.eO);
            sel.removeAllRanges();
            sel.addRange(range);
        } catch (err) {
            console.log(err);
        }
    }

    showComponentAtCaret = (component, position) => {
        if (isEmpty(position)) {
            const span = document.createElement('span');
            span.setAttribute('id', 'bounding-box');
            this.pasteHtmlAtCaret(span.outerHTML, false);

            const realElement = document.getElementById('bounding-box');
            const newBoundingBox = realElement.getBoundingClientRect();

            const x = newBoundingBox.top + 20;
            const y = newBoundingBox.left;

            component.style.top = `${x}px`;
            component.style.left = `${y}px`;
            realElement.parentNode.removeChild(realElement);
        } else {
            component.style.top = `${position.top}px`;
            component.style.left = `${position.left}px`;
        }
    }

    showComponentAtCaretEdit = (component, position, childrenID) => {
        if (isEmpty(position)) {
            const realElement = document.getElementById(childrenID);
            const newBoundingBox = realElement.getBoundingClientRect();

            const x = (newBoundingBox.top - 85) + window.scrollY;
            const y = newBoundingBox.left;
            component.style.top = `${x}px`;
            component.style.left = `${y}px`;
            realElement.style.background = '#0bcb6b';
        } else {
            const realElement = document.getElementById(childrenID);
            component.style.top = `${position.top}px`;
            component.style.left = `${position.left}px`;
            realElement.style.background = '';
        }
    }

    pasteHtmlAtCaret(html, isScrolling = true, callback) {
        let sel; let range;
        if (window.getSelection) {
            // IE9 and non-IE
            sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0);
                range.deleteContents();
                // Range.createContextualFragment() would be useful here but is
                // only relatively recently standardized and is not supported in
                // some browsers (IE9, for one)
                const el = document.createElement('div');
                el.innerHTML = html;

                const spanTemp = document.createElement('span');
                spanTemp.setAttribute('id', 'scroll-temp-item');
                const frag = document.createDocumentFragment();
                let node; let lastNode;
                while ((node = el.firstChild)) {
                    lastNode = frag.appendChild(node);
                }
                if (isScrolling) {
                    frag.appendChild(spanTemp);
                }
                range.insertNode(frag);

                // Preserve the selection
                if (lastNode) {
                    range = range.cloneRange();
                    range.setStartAfter(lastNode);
                    range.collapse(true);
                    sel.removeAllRanges();
                    sel.addRange(range);
                }

                if (isScrolling) {
                    setTimeout(() => {
                        const span = document.getElementById('scroll-temp-item');
                        if (span) {
                            // span.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                            span.scrollIntoView({ block: 'nearest', inline: 'start' });
                            span.parentNode.removeChild(span);
                        }
                    }, 0);
                }
                if (callback) {
                    callback();
                }
            }
        } else if (document.selection && document.selection.type !== 'Control') {
            // IE < 9

            document.selection.createRange().pasteHTML(html);
        }
    }

    replaceWordAtCaret() {
        if (window.getSelection && window.getSelection().getRangeAt) {
            const s = window.getSelection();
            const range = s.getRangeAt(0);
            const node = s.anchorNode;
            if (range.startContainer.length > 0) {
                while (!range.toString().startsWith(' ') && range.startOffset >= 1) {
                    range.setStart(node, (range.startOffset - 1));
                }
            }
            if (range.toString() !== '') {
                while (!range.toString().endsWith(' ') && range.endOffset < range.endContainer.length) {
                    range.setEnd(node, range.endOffset + 1);
                }
            }
            range.deleteContents();
        }
    }

    replaceHtmlToValue(html) {
        const el = document.createElement('div');
        el.innerHTML = html;
        const spanList = el.querySelectorAll('a');
        for (let i = 0; i < spanList.length; i++) {
            const newItem = document.createElement('li');
            newItem.innerHTML = `{{${spanList[i].getAttribute('data')}}}`;
            spanList[i].parentNode.replaceChild(newItem, spanList[i]);
        }
        return el.innerText;
    }

    replaceValueToHtml(value, customFields) {
        let data = value;
        for (let i = 0; i < customFields.length; i++) {
            data = data.replace(`{{${customFields[i].alias}}}`, `<a id="badges-${this.getRandomInt(999999)}" `
                + `class="badges badges-primary" data="${customFields[i].alias}">${customFields[i].name}</a>`);
        }
        return data;
    }

    standardizedValue(string) {
        return string
            .replace(/&nbsp;/g, ' ')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<');
    }

    numberWithCommas(x) {
        if (!x) return (0);
        let parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
        // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    parseCredits(credit, fixed = 2) {
        try {
            return parseFloat(credit).toFixed(fixed);
        } catch (err) {
            return credit;
        }
    }

    isDate(text) {
        try {
            return validator.toDate(text);
        } catch (err) {
            // throw err;
        }
    }

    checkTextIsDOB(text) {
        try {
            if (text === undefined || !text) return false;

            const date = new Date(text);
            if (date && date != 'Invalid Date') return date;

            const newText = text.replace(/-/g, '/');
            let dd = false;
            let mm = false;
            let yy = false;
            if (newText.indexOf('/') >= 0) {
                const arr = newText.split('/');
                dd = parseInt(arr[0].trim());
                if (arr.length > 1) mm = parseInt(arr[1].trim());
                if (arr.length > 2) yy = parseInt(arr[2].trim());
            } else if (newText.length < 8) {
                return false;
            } else {
                dd = parseInt(newText.substr(0, 2));
                mm = parseInt(newText.substr(2, 2));
                yy = parseInt(newText.substr(4, 4));
            }

            if (mm > 12) {
                const temp = mm;
                mm = dd;
                dd = temp;
            }

            return new Date(`${mm}/${dd}/${yy}`);
        } catch (err) {
            return false;
        }
    }

    isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }
}

export default new BaseHelper();
