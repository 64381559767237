import { isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import actionAppIntegrate from '../../../../redux/futures/integrate_app/actions';
import config from '../../../../config/config';

function FormShopify(props) {
    const { t, dataApp, cb } = props;
    const isCreate = props.isCreate === undefined ? true : props.isCreate;

    const dispatch = useDispatch();
    const [appIntegrate, setAppIntegrate] = useState({});

    const urlWebhookShopify = useSelector((state) => state.appIntegrate.webhookUrlShopify);

    const eventChangeData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = {};
        data[name] = value;
        setAppIntegrate({ ...appIntegrate, ...data });
        if (isFunction(cb)) cb(data);
    };

    const copyClipboard = () => {
        if (!navigator.clipboard) return;

        const copyText = document.getElementById('url_weebhook');
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);

        window.LadiUI.toast('success', '', t('COMMON.COPIED'), 5000, 'bottom-left');
    };

    useEffect(() => {
        if (dataApp) setAppIntegrate(dataApp);
        if (!urlWebhookShopify) dispatch(actionAppIntegrate.integrateAppShopifyWebhook());
    }, [dataApp]);

    return <>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="api_url">{t('INTEGRATE_APPS.URL_WEBHOOK')}</label>
            <div className="ladiui flex-row">
                <input id="url_weebhook" name="url_weebhook" className="ladiui form-control mr-8" placeholder="URL Webhook"
                    value={`${config.API_V2_0}/webhook/shopify/${urlWebhookShopify ? urlWebhookShopify.api_key : ''}`}
                    readOnly={true}
                />
                <button className="ladiui btn btn-secondary" onClick={copyClipboard}>{t('COMMON.COPY')}</button>
            </div>
            <span style={{ fontSize: '13px', fontStyle: 'italic' }}><i>Sao chép vào trong cài đặt Webhook của shopify</i></span>
        </div>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="name">Tên</label>
            <input id="name" name="name" className="ladiui form-control" placeholder="Nhập Tên"
                value={appIntegrate.name || ''}
                onChange={(e) => eventChangeData(e)}
            />
        </div>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="api_url">URL cửa hàng</label>
            <div className="ladiui flex-row">
                <input id="api_url" name="api_url" className="ladiui form-control mr-8" placeholder="Nhập URL cửa hàng"
                    value={appIntegrate.api_url || ''}
                    onChange={(e) => eventChangeData(e)}
                    readOnly={!isCreate}
                />
            </div>
        </div>
    </>;
}

export default (withTranslation()(FormShopify));
