import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import actionStore from '../../../redux/futures/store/actions';

// eslint-disable-next-line max-lines-per-function
function ModalSenderEmail(props) {
    const { t, isEdit, cb } = props;

    const dispatch = useDispatch();
    const [senderEmail, setSenderEmail] = useState({});
    const senderEmailData = useSelector((state) => state.store.sender);

    const cbAction = () => {
        setSenderEmail({});
        window.LadiUI.closeModal('modal-sender-email');
        cb();
    };

    const eventCancel = () => {
        setSenderEmail({});
        window.LadiUI.closeModal('modal-sender-email');
    };
    const eventEdit = () => {
        const data = { id: senderEmail._id, ...senderEmail };
        dispatch(actionStore.updateSenderEmail(data, cbAction));
    };
    const eventCreate = () => {
        dispatch(actionStore.createSenderEmail(senderEmail, cbAction));
    };

    useEffect(() => {
        if (!isEdit) {
            setSenderEmail({});
        }
    }, [isEdit]);

    useEffect(() => {
        if (senderEmailData) {
            setSenderEmail(senderEmailData);
        } else {
            setSenderEmail({});
        }
    }, [senderEmailData]);

    return <>
        <div className="ladiui modal" id="modal-sender-email">
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">{isEdit ? 'Sửa email' : 'Thêm email'}</label>
                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>

                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2" >{t('STORES.NAME_SENDER')}</label>
                        <input id="name" name="name" className="ladiui form-control" placeholder="Nhập tên người gửi"
                            value={senderEmail.name || ''} onChange={(e) => setSenderEmail({ ...senderEmail, name: e.target.value })} />
                    </div>

                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2" >{t('STORES.EMAIL_SENDER')}</label>
                        {isEdit
                            ? <input className="ladiui form-control" disabled value={senderEmail.email || ''} />
                            : <input id="email" name="email" className="ladiui form-control" placeholder="Nhập email người gửi"
                                value={senderEmail.email || ''} onChange={(e) => setSenderEmail({ ...senderEmail, email: e.target.value })} />
                        }
                    </div>

                    <div className="ladiui modal-footer custom-ldp">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <button className="ladiui btn btn-secondary" onClick={eventCancel}>Huỷ bỏ</button>
                            {isEdit
                                ? <button className="ladiui btn btn-primary" onClick={eventEdit}>Cập nhật email</button>
                                : <button className="ladiui btn btn-primary" onClick={eventCreate}>Tạo email</button>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div >
    </>;
}

export default (withTranslation()(ModalSenderEmail));
