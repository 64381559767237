import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data, cb) => ({
    type: types.LIST_REQUEST_INVOICE,
    meta: {
        endpoint: endpoint.LIST_REQUEST_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

// const create = (data, cb) => ({
//     type: types.CREATE_INVOICE,
//     meta: {
//         endpoint: endpoint.CREATE_INVOICE,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         showNotify: true,
//         cb,
//     },
// });

const show = (id, cb) => ({
    type: types.SHOW_INVOICE_REQUEST,
    meta: {
        endpoint: endpoint.SHOW_INVOICE_REQUEST,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

// const update = (data, cb) => ({
//     type: types.UPDATE_INVOICE,
//     meta: {
//         endpoint: endpoint.UPDATE_INVOICE,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         cb,
//     },
// });

const approvedInvoiceRequest = (data, cb) => ({
    type: types.APPROVED_INVOICE_REQUEST,
    meta: {
        endpoint: endpoint.APPROVED_INVOICE_REQUEST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const rejectInvoiceRequest = (data, cb) => ({
    type: types.REJECT_INVOICE_REQUEST,
    meta: {
        endpoint: endpoint.REJECT_INVOICE_REQUEST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const cancelInvoiceRequest = (data, cb) => ({
    type: types.CANCEL_INVOICE_REQUEST,
    meta: {
        endpoint: endpoint.CANCEL_INVOICE_REQUEST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

// const deleteInvoice = (data, cb) => ({
//     type: types.DELETE_INVOICE,
//     meta: {
//         endpoint: endpoint.DELETE_INVOICE,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         cb,
//     },
// });

// const exportInvoice = (data, cb) => ({
//     type: types.EXPORT_INVOICE,
//     meta: {
//         endpoint: endpoint.EXPORT_INVOICE,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         showNotify: true,
//         cb,
//     },
// });

// const deleteMultiInvoice = (data, cb) => ({
//     type: types.DELETE_MULTI_INVOICE,
//     meta: {
//         endpoint: endpoint.DELETE_MULTI_INVOICE,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         showNotify: true,
//         cb,
//     },
// });

// const acceptMultiInvoice = (data, cb) => ({
//     type: types.ACCEPT_MULTI_INVOICE,
//     meta: {
//         endpoint: endpoint.INVOICE_UPDATE_STATUS,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         showNotify: true,
//         cb,
//     },
// });

const acceptMultiInvoiceRequest = (data, cb) => ({
    type: types.APPROVED_MULTI_INVOICE_REQUEST,
    meta: {
        endpoint: endpoint.APPROVED_MULTI_INVOICE_REQUEST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const cancelMultiInvoiceRequest = (data, cb) => ({
    type: types.CANCEL_MULTI_INVOICE_REQUEST,
    meta: {
        endpoint: endpoint.CANCEL_MULTI_INVOICE_REQUEST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const rejectMultiInvoiceRequest = (data, cb) => ({
    type: types.REJECT_MULTI_INVOICE_REQUEST,
    meta: {
        endpoint: endpoint.REJECT_MULTI_INVOICE_REQUEST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

// const paidMultiInvoice = (data, cb) => ({
//     type: types.PAID_MULTI_INVOICE,
//     meta: {
//         endpoint: endpoint.INVOICE_UPDATE_STATUS,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         showNotify: true,
//         cb,
//     },
// });

// const updateStatus = (data, cb) => ({
//     type: types.UPDATE_STATUS_INVOICE,
//     meta: {
//         endpoint: endpoint.UPDATE_STATUS_INVOICE,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         showNotify: true,
//         cb,
//     },
// });

// const exportBankInfoAffiliate = (data, cb) => ({
//     type: types.EXPORT_BANK_INFO_AFFILIATE,
//     meta: {
//         endpoint: endpoint.EXPORT_BANK_INFO_AFFILIATE,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         cb,
//     },
// });

// const sendMailConfirmInvoice = (data, cb) => ({
//     type: types.SEND_MAIL_CONFIRM_INVOICE,
//     meta: {
//         endpoint: endpoint.SEND_MAIL_CONFIRM_INVOICE,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         showNotify: true,
//         cb,
//     },
// });

// const createRequestWithdraw = (data, cb) => ({
//     type: types.CREATE_REQUEST_WITHDRAW,
//     meta: {
//         endpoint: endpoint.CREATE_REQUEST_WITHDRAW,
//         method: REQUEST_METHOD.POST,
//         body: data,
//         hasAuth: true,
//         showNotify: true,
//         cb,
//     },
// });

export default {
    list,
    approvedInvoiceRequest,
    rejectInvoiceRequest,
    cancelInvoiceRequest,
    // create,
    show,
    acceptMultiInvoiceRequest,
    cancelMultiInvoiceRequest,
    rejectMultiInvoiceRequest,
    // update,
    // delete: deleteInvoice,
    // exportInvoice,
    // deleteMultiInvoice,
    // acceptMultiInvoice,
    // rejectMultiInvoice,
    // paidMultiInvoice,
    // updateStatus,
    // exportBankInfoAffiliate,
    // sendMailConfirmInvoice,
    // createRequestWithdraw,
};
