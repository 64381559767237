import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../components/ConfirmModal';
import LadiDropdownMenu from '../../../components/LadiDropdownMenu';
import actionRedux from '../../../redux/futures/store/actions';

function CheckAllActionEmailSender(props) {
    const { t, cb } = props;
    const ids = props.ids || [];

    const listAction = [
        { value: 'DELETE_ALL', name: t('COMMON.DELETE_ALL') },
        // { value: 'VERIFIED_ALL', name: t('STORES.VERIFIED_ALL') },
    ];

    const dispatch = useDispatch();
    const [actionSelected, setActionSelected] = useState(null);
    const [modalConfirm, setModalConfirm] = useState({});
    const [classBtnConfirm, setClassBtnConfirm] = useState('btn-primary');

    const eventSelectAction = (action) => {
        let dataModal = {};
        switch (action) {
            case 'DELETE_ALL':
                setClassBtnConfirm('btn-danger');
                dataModal.title = 'Bạn có chắc chắn muốn xóa toàn bộ email được chọn?';
                dataModal.content = <div>Email mặc định sẽ không bị xoá.</div>;
                break;
            // case 'VERIFIED_ALL':
            //     setClassBtnConfirm('btn-primary');
            //     dataModal.title = 'Bạn có chắc chắn muốn xác nhận toàn bộ email được chọn?';
            //     dataModal.content = <div>Toàn bộ email sẽ được xác nhận</div>;
            //     break;
            default:
        }
        setActionSelected(action);
        setModalConfirm(dataModal);
        window.LadiUI.showModal('modal-confirm-action-all');
    };

    const eventOkActionAll = () => {
        if (actionSelected) {
            switch (actionSelected) {
                case 'DELETE_ALL':
                    dispatch(actionRedux.deleteMultiSenderEmail({ ids }, cb));
                    break;
                // case 'VERIFIED_ALL':
                //     dispatch(actionRedux.verifyMultiSenderEmail({ ids }, cb));
                //     break;
                default:
            }
        }
    };

    return ids.length > 0
        ? <>
            <div className="ladiui ladi-check-all-action">
                <LadiDropdownMenu
                    labelDropdown={'Chọn hành động'}
                    listProp={listAction}
                    defaultTextProp={t('COMMON.ACTION')}
                    hideLabel={true}
                    cbProp={eventSelectAction}
                    classSize=""
                    classDropdown="w-220"
                />
            </div>
            <ConfirmModal
                id="modal-confirm-action-all"
                title={modalConfirm.title || ''}
                content={modalConfirm.content || ''}
                onOk={() => eventOkActionAll()}
                cancelText={t('COMMISSIONS.CANCELED')}
                okText={t('COMMISSIONS.OK')}
                classBtn={classBtnConfirm || 'btn-primary'}
            />
        </>
        : <></>;
}

export default (withTranslation()(CheckAllActionEmailSender));
