import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import config from '../../config/config';
import PublisherFooter from './PublisherFooter';
import actionStore from '../../redux/futures/store/actions';
import PublisherLeft from './components/PublisherLeft';
import PublisherLogo from './components/PublisherLogo';

function ChangePassword(props) {
    const { t } = props;
    const store = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        const data = {
            ...store,
            url: window.location.origin,
            field: 'affiliate_dashboard_setting',
        };
        dispatch(actionStore.getAffiliateDashboardSettingPublic(data));
    }, []);

    return <>
        <div className="publisher-container">

            <div className="box-publisher">
                <PublisherLogo />
                <PublisherLeft />
                <div className="box-publisher-right">
                    <div className="box-title">Đổi mật khẩu</div>
                    <div className="box-sub-title"></div>
                    <form action="#">
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2">Mật khẩu hiện tại</label>
                            <input type="password" className="ladiui form-control" placeholder="Nhập mật khẩu" />
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2">Mật khẩu mới</label>
                            <input type="password" className="ladiui form-control" placeholder="Nhập mật khẩu mới" />
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2">Nhập lại mật khẩu mới</label>
                            <input type="password" className="ladiui form-control" placeholder="Nhập mật khẩu mới" />
                        </div>
                        <div className="ladiui form-group">
                            <button className="ladiui btn btn-primary">Đổi mật khẩu</button>
                        </div>
                        <div className="ladiui form-group">
                            Bạn chưa có tài khoản? <Link to={config.APP_AFFILIATE && origin !== config.AFFILIATE_DOMAIN ? '/register' : `/${store.store}/register`} className="ladiui link">Đăng ký ngay</Link>
                        </div>
                    </form>

                </div>

                <PublisherFooter />
            </div>

        </div>
    </>;
}
export default (withTranslation()(ChangePassword));
