import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const publisherLogin = (data, cb) => ({
    type: types.PUBLISHER_LOGIN,
    meta: {
        endpoint: endpoint.PUBLISHER_LOGIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const publisherRegister = (data, cb) => ({
    type: types.PUBLISHER_REGISTER,
    meta: {
        endpoint: endpoint.PUBLISHER_REGISTER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const forgotPassword = (data, cb) => ({
    type: types.PUBLISHER_FORGOT_PASSWORD,
    meta: {
        endpoint: endpoint.PUBLISHER_FORGOT_PASSWORD,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const resetPassword = (data, cb) => ({
    type: types.PUBLISHER_RESET_PASSWORD,
    meta: {
        endpoint: endpoint.PUBLISHER_RESET_PASSWORD,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const campaignShareDetail = (data, cb) => ({
    type: types.CAMPAIGN_SHARE_DETAIL,
    meta: {
        endpoint: endpoint.CAMPAIGN_SHARE_DETAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

export default {
    publisherLogin,
    publisherRegister,
    forgotPassword,
    resetPassword,
    campaignShareDetail,
};
