import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import BaseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import DefineConst from '../../commissions/DefineConst';
import actionCommission from '../../../redux/futures/commission/actions';
import actionInvoice from '../../../redux/futures/invoice/actions';
import LadiPagination from '../../../components/LadiPagination';

// eslint-disable-next-line max-lines-per-function
function ModalRequestWithdraw(props) {
    const { t, cb } = props;

    const defaultConditions = {
        page: 1,
        limit: 500,
        sort: { created_at: -1 },
        status: DefineConst.COMMISSION_STATUS.INVOICED,
        is_paid: { is_paid: 0 },
    };

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState(defaultConditions);
    const requestWithdrawSetting = useSelector((state) => state.store.requestWithdrawSetting);
    const user = useSelector((state) => state.store.userInfo.user);
    const listCommission = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.commissions.commissions }));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const selectLimit = (number) => {
        setConditions({ limit: number, page: 1 });
    };

    const selectPage = (number) => {
        setConditions({ page: number });
    };

    const closeModalRequestWithdraw = () => {
        window.LadiUI.closeModal('modal-create-request-withdraw');
    };

    const checkTime = (type, start, end) => {
        const today = new Date();
        const d = today.getDate();
        if (type === 'MULTI_DATE') {
            if (parseFloat(start) <= d && parseFloat(end) >= d) {
                return true;
            }
        } else if (parseFloat(start) === d) {
            return true;
        }
        return false;
    };

    const getTimeRequestWithdraw = (type, start, end) => {
        if (type === 'MULTI_DATE') {
            return `Từ ngày ${start} đến ngày ${end} hàng tháng`;
        }
        return `Ngày ${start} hàng tháng`;
    };

    const renderRecord = (item) => <tr className="ladiui table-vertical main" key={item._id}>
        <td><a href="#/" className="ladiui link"
            style={{
                display: 'block', overflow: 'hidden', maxWidth: '120px', textOverflow: 'ellipsis',
            }}
        >{item.data.code || item._id}</a></td>
        <td>{appConfig.LADISHARE.COMMISSION_TYPE.map((type) => (type.value === item.type ? type.name : ''))}</td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(item.total, '', systemCurrency)}</td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(item.amount, '', systemCurrency)}</td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(item.bonus, '', systemCurrency)}</td>
    </tr >;

    const cbModalRequestWithdraw = () => {
        if (_.isFunction(cb)) {
            cb();
        }
        closeModalRequestWithdraw();
    };

    const createRequestWithdraw = () => {
        const ids = [];
        for (let i = 0; i < listCommission.items.length; i++) {
            ids.push(listCommission.items[i]._id);
        }
        dispatch(actionInvoice.createRequestWithdraw({ ids }, cbModalRequestWithdraw));
    };

    useEffect(() => {
        dispatch(actionCommission.list(conditions));
    }, [conditions]);

    return requestWithdrawSetting ? <>
        <div className="ladiui modal" id="modal-create-request-withdraw">
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                {requestWithdrawSetting && requestWithdrawSetting.enable_create_request_withdraw_money
                    && checkTime(requestWithdrawSetting.type_create_request_withdraw_money, requestWithdrawSetting.create_request_withdraw_money_start_date, requestWithdrawSetting.create_request_withdraw_money_end_date)
                    ? <>
                        {listCommission.items.length > 0
                            ? < div className="ladiui modal-content custom-ldp">
                                <div className="ladiui modal-header custom-ldp">
                                    <label className="ladiui title-text-form-lever1">Tạo yêu cầu rút tiền</label>
                                    <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close" onClick={() => closeModalRequestWithdraw()}>
                                        <i className="ladiui icon icon-ldp-close"></i>
                                    </button>
                                </div>

                                <div className="ladiui form-group-custom">
                                    <label className="ladiui title-text-form-lever2" >Danh sách hoa hồng</label>
                                    <div className="ladiui table-scroll" style={{ maxHeight: '400px' }}>
                                        <table className="ladiui table text-left" style={{ minWidth: '400px', marginTop: '12px' }}>
                                            <thead>
                                                <tr className="ladiui table-vertical header">
                                                    <th className="col-action" style={{ width: '150px' }}>
                                                        {t('COMMISSIONS.REFERENCE_NO')}</th>
                                                    <th scope="col">{t('COMMISSIONS.TYPE')}</th>
                                                    <th scope="col" className="ladiui text-right">{t('COMMISSIONS.TOTAL_CURRENCY')}</th>
                                                    <th scope="col" className="ladiui text-right">{t('COMMISSIONS.COMMISSION')}</th>
                                                    <th scope="col" className="ladiui text-right">Thưởng</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listCommission.items.length > 0
                                                    ? listCommission.items.map((values) => (renderRecord(values)))
                                                    : (<tr className="ladiui table-vertical main"><td colSpan="9" className="text-center"><div>{t('COMMISSIONS.NO_COMMISSION')}</div></td></tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr className="ladiui table-vertical summary">
                                                    <td></td>
                                                    <td>
                                                        <span className="total-record">{t('COMMON.NUMBER_RECORD')}:&nbsp;</span>
                                                        <span className="bold-500">
                                                            {listCommission.summary.total ? listCommission.summary.total : 0}
                                                        </span>
                                                    </td>
                                                    <td className="ladiui text-right">
                                                        <span className="bold-500">
                                                            {listCommission.summary.salesTotal
                                                                ? BaseHelper.formatMoney(listCommission.summary.salesTotal, '', systemCurrency)
                                                                : BaseHelper.formatMoney(0, '', systemCurrency)}
                                                        </span>
                                                    </td>
                                                    <td className="ladiui text-right">
                                                        <span className="bold-500">
                                                            {listCommission.summary.commissionTotal
                                                                ? BaseHelper.formatMoney(listCommission.summary.commissionTotal, '', systemCurrency)
                                                                : BaseHelper.formatMoney(0, '', systemCurrency)}
                                                        </span>
                                                    </td>
                                                    <td className="ladiui text-right">
                                                        <span className="bold-500">
                                                            {listCommission.summary.commissionBonusTotal
                                                                ? BaseHelper.formatMoney(listCommission.summary.commissionBonusTotal, '', systemCurrency)
                                                                : BaseHelper.formatMoney(0, '', systemCurrency)}
                                                        </span>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>

                                <div>
                                    <div className="ladiui form-group-custom">
                                        <div className="ladiui flex-row">
                                            <label className="ladiui title-text-form-lever2">Thông tin thanh toán</label>
                                        </div>
                                    </div>
                                    <div className="order-info-content ladiui item bg-form bg-commissionn-plan" style={{ marginTop: 0 }}>
                                        <div className="ladiui item bg-form-sub" style={{ paddingBottom: '16px', margin: '0 16px' }}>
                                            <div className="d-grid-2 no-grid-mobile">
                                                <div className="ladiui pt-20">
                                                    <div style={{ marginBottom: '12px' }}>Chủ tài khoản</div>
                                                    <div>
                                                        <label className="ladiui title-text-form-lever2" style={{ textTransform: 'uppercase' }}>{user.fullname || ''}</label>
                                                    </div>
                                                </div>
                                                <div className="ladiui pt-20">
                                                    <div>
                                                        <div style={{ marginBottom: '12px' }}>Thông tin ngân hàng</div>
                                                    </div>
                                                    <div>
                                                        <label className="ladiui title-text-form-lever2" >Ngân hàng: {user.bank_name || ''}</label>
                                                        <label className="ladiui title-text-form-lever2" >Số tài khoản: {user.bank_account_number || ''}</label>
                                                        <label className="ladiui title-text-form-lever2" >Tên chủ khoản: {user.bank_account_name || ''}</label>
                                                        <label className="ladiui title-text-form-lever2" >Chi nhánh: {user.bank_department || ''}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="ladiui modal-footer custom-ldp-popupX mod">
                                    <div className="ladiui modal-footer-link">
                                    </div>
                                    <div className="ladiui footer-modal-btn">
                                        <button type="button" className="ladiui btn btn-secondary bold" onClick={() => closeModalRequestWithdraw()}>{t('COMMON.CANCELED')}</button>
                                        <button type="button" className="ladiui btn btn-primary" onClick={() => createRequestWithdraw()}>Tạo yêu cầu</button>
                                    </div>
                                </div>
                            </div> : <>
                                < div className="ladiui modal-content custom-ldp">
                                    <div className="ladiui modal-header custom-ldp">
                                        <label className="ladiui title-text-form-lever1">Tạo yêu cầu rút tiền</label>
                                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                                            <i className="ladiui icon icon-ldp-close"></i>
                                        </button>
                                    </div>
                                    <div className="ladiui form-group-custom text-center">
                                        Không đủ điều kiện tạo yêu cầu rút tiền
                                    </div>

                                    <div className="ladiui modal-footer custom-ldp-popupX mod">
                                        <div className="ladiui modal-footer-link">
                                        </div>
                                        <div className="ladiui footer-modal-btn">
                                            <button type="button" className="ladiui btn btn-primary bold" onClick={() => closeModalRequestWithdraw()}>{t('COMMON.OK')}</button>
                                        </div>
                                    </div>
                                </div>
                            </>}</>
                    : < div className="ladiui modal-content custom-ldp">
                        <div className="ladiui modal-header custom-ldp">
                            <label className="ladiui title-text-form-lever1">Tạo yêu cầu rút tiền</label>
                            <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>

                        <div className="ladiui form-group-custom text-center">
                            Thời gian yêu cầu rút tiền: {requestWithdrawSetting
                                ? getTimeRequestWithdraw(requestWithdrawSetting.type_create_request_withdraw_money, requestWithdrawSetting.create_request_withdraw_money_start_date, requestWithdrawSetting.create_request_withdraw_money_end_date)
                                : ''}
                        </div>
                        <div className="ladiui form-group-custom text-center">
                            Vui lòng chờ tới thời gian được phép tạo yêu cầu
                        </div>

                        <div className="ladiui modal-footer custom-ldp-popupX mod">
                            <div className="ladiui modal-footer-link">
                            </div>
                            <div className="ladiui footer-modal-btn">
                                <button type="button" className="ladiui btn btn-primary bold" onClick={() => closeModalRequestWithdraw()}>{t('COMMON.OK')}</button>
                            </div>
                        </div>
                    </div>}
            </div>
        </div >

    </> : <></>;
}

export default (withTranslation()(ModalRequestWithdraw));
