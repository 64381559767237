import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';
import BaseHelper from '../../../../helpers/BaseHelper';

function CommissionPlanPaymentBoxOrderPerCustomer(props) {
    const { t, paymentOrderPerCustomerProp, cb } = props;
    const [paymentOrderPerCustomer, setPaymentOrderPerCustomer] = useState({ enable: false });

    const renderExplain = () => {
        let explain = <></>;
        if (paymentOrderPerCustomer.min && paymentOrderPerCustomer.max) {
            explain = <>(*) Hoa hồng sẽ được tính nếu số đơn hàng của khách hàng đó nằm trong khoảng từ {BaseHelper.formatNumber(paymentOrderPerCustomer.min)} đến {BaseHelper.formatNumber(paymentOrderPerCustomer.max)}</>;
        }
        if (paymentOrderPerCustomer.min && !paymentOrderPerCustomer.max) {
            explain = <>(*) Hoa hồng sẽ được tính nếu số đơn hàng của khách hàng đó lớn hơn hoặc bằng {BaseHelper.formatNumber(paymentOrderPerCustomer.min)}</>;
        }
        if (!paymentOrderPerCustomer.min && paymentOrderPerCustomer.max) {
            explain = <>(*) Hoa hồng sẽ được tính nếu số đơn hàng của khách hàng đó nhỏ hơn hoặc bằng {BaseHelper.formatNumber(paymentOrderPerCustomer.max)}</>;
        }
        return explain;
    };

    const eventChangeInput = (e) => {
        const name = e.target.name;
        let value;
        if (
            e.target.tagName.toLowerCase() === 'input'
            && e.target.getAttribute('type') === 'checkbox'
        ) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        const data = { ...paymentOrderPerCustomer };
        data[name] = value;
        setPaymentOrderPerCustomer(data);
        cb(data);
    };

    const changeInputValue = (fieldName, value) => {
        const data = { ...paymentOrderPerCustomer };
        data[fieldName] = value;
        setPaymentOrderPerCustomer(data);
        cb(data);
    };

    useEffect(() => {
        setPaymentOrderPerCustomer({ ...paymentOrderPerCustomer, ...paymentOrderPerCustomerProp });
    }, [paymentOrderPerCustomerProp]);

    return <>
        <div className="ladiui clearfix pt-20">
            <label className="ladiui title-text-form-lever2 left">Trả theo số đơn hàng của từng khách hàng</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox"
                        name="enable"
                        checked={paymentOrderPerCustomer !== null && (paymentOrderPerCustomer.enable || false)}
                        onChange={(e) => eventChangeInput(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Trả hoa hồng nếu mỗi khách hàng có số lượng đơn hàng đạt yêu cầu</div>
        </div>
        {paymentOrderPerCustomer && paymentOrderPerCustomer.enable
            ? <>
                <div className="ladiui clearfix pt-20">
                    <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Số đơn tối thiểu</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                value={paymentOrderPerCustomer.min || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Số đơn tối thiểu"
                                onValueChange={(values) => changeInputValue('min', values.value)}
                            />
                        </div>

                    </div>
                    <div className="ladiui left">
                        <label className="ladiui title-text-form-lever3">Số đơn tối đa</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                value={paymentOrderPerCustomer.max || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Số đơn tối đa"
                                onValueChange={(values) => changeInputValue('max', values.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>
                    {renderExplain()}
                </div>
            </>
            : <></>}
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBoxOrderPerCustomer));
