const cachedActions = {
    LIST_COUNTRIES: true,
    LIST_STATES: true,
    LIST_DISTRICTS: true,
    LIST_WARDS: true,
};

const cacheStorageService = store => next => async (action) => {
    if (!action.meta || !cachedActions[action.type]) {
        next(action);
        return;
    }
}

export default cacheStorageService;
export { cachedActions };