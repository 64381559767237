import { applyMiddleware, createStore, compose } from 'redux';
import { serviceMiddleware, localStorageMiddleware } from '../middlewares/services';
import cookiesMiddleware from '../middlewares/cookies';
import cacheStorageService from '../middlewares/cache_storage';
import notificationService from '../middlewares/notification';
import thunk from 'redux-thunk';
import createRootReducer from '../futures';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
        return applyMiddleware(routerMiddleware(history), thunk, cacheStorageService, serviceMiddleware,
            localStorageMiddleware, cookiesMiddleware, notificationService);
    } else {
        // Enable additional logging in non-production environments.
        return applyMiddleware(routerMiddleware(history), thunk, cacheStorageService, serviceMiddleware,
            localStorageMiddleware, cookiesMiddleware, notificationService);
    }
};

export const store = createStore(createRootReducer(history), compose(getMiddleware()));
