import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import appConfig from '../../config/app';
import DefineConst from './DefineConst';
import ModalCommissionPlan from './components/ModalCommissionPlan';
import actionCommissionPlan from '../../redux/futures/commission_plan/actions';
import actionAffiliateGroup from '../../redux/futures/affiliate_group/actions';
import ConfirmModal from '../../components/ConfirmModal';
import BaseHelper from '../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function
function CommissionPlan(props) {
    const { t } = props;
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { is_default: -1, _id: -1 },
    };

    const defaultCommissionPlanPayment = {
        name: '',
        affiliate_group_id: null,
        commission_plan_id: '',
        is_delete: false,
        level: 1,
        payment_for: DefineConst.PAYMENT_FOR.ORDER,
        payment_term: { payment_term_type: DefineConst.PAYMENT_TERM_TYPE.DAY, payment_term_number: 1 },
        payment_type: DefineConst.PAYMENT_TYPE.PAYMENT_TYPE_PERCENT,
        payment_value: 0,
        payment_intervals: {},
        payment_price_range: {},
        skus: {},
        status: true,
        _id: BaseHelper.generateRandomString(16),
        is_new: true,
    };

    const defaultCommissionPlan = {
        currency: 'VND',
        is_default: false,
        name: '',
        status: true,
        store_id: '',
        commision_plan_payment_items: [defaultCommissionPlanPayment],
        _id: '',
    };

    const dispatch = useDispatch();
    const [commissionPlan, setCommissionPlan] = useState(defaultCommissionPlan);
    const [conditions, setConditions] = useState(defaultConditions);
    const [modalCommissionPlan, setModalCommissionPlan] = useState({ title: t('COMMISSIONS.ADD_COMMISSION_PLAN'), nameButton: t('COMMISSIONS.ADD_COMMISSION_PLAN'), isEdit: false });
    const [isFetchData, setIsFetchData] = useState(false);

    const listCommissionPlan = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.commissionPlans.commissionPlans }));
    const listAffiliateGroup = useSelector((state) => ({ ...{ items: [], total: 0, pluckItems: {} }, ...state.affiliateGroups.groups }));
    const loadingApiAffiliateGroup = useSelector((state) => (state.affiliateGroups.loading));
    const loadingApiCommissionPlan = useSelector((state) => (state.commissionPlans.loading));

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const editCommissionPlan = (commissionPlan) => {
        // setCommissionPlan(commissionPlan);
        dispatch(actionCommissionPlan.show(commissionPlan._id));
        setModalCommissionPlan({ title: t('COMMISSIONS.EDIT_COMMISSION_PLAN_MODAL'), nameButton: t('COMMISSIONS.UPDATE'), isEdit: true });
        window.LadiUI.showModal('create-ladishare-comission-plan');
    };

    const eventMakeAsDefault = (commissionPlan) => {
        setIsFetchData(true);
        const data = { is_default: true, id: commissionPlan._id };
        dispatch(actionCommissionPlan.makeAsDefault(data));
    };

    const eventDeleteCommissionPlan = (commissionPlan) => {
        setCommissionPlan(commissionPlan);
    };

    const deleteCommissionPlan = () => {
        setIsFetchData(true);
        dispatch(actionCommissionPlan.delete({ id: commissionPlan._id }));
        window.LadiUI.closeModal('delete-modal');
    };

    const eventCreateCommissionPlan = () => {
        setCommissionPlan(defaultCommissionPlan);
        setModalCommissionPlan({ title: t('COMMISSIONS.ADD_COMMISSION_PLAN'), nameButton: t('COMMISSIONS.ADD_COMMISSION_PLAN'), isEdit: false });
        window.LadiUI.showModal('create-ladishare-comission-plan');
    };

    useEffect(() => {
        dispatch(actionAffiliateGroup.list({ pluck: ['_id', 'name'] }));
    }, []);

    useEffect(() => {
        if (loadingApiAffiliateGroup === false) dispatch(actionCommissionPlan.list(conditions));
    }, [loadingApiAffiliateGroup]);

    useEffect(() => {
        if (loadingApiCommissionPlan === false && isFetchData === true) {
            dispatch(actionCommissionPlan.list(conditions));
            setIsFetchData(false);
        }
    }, [loadingApiCommissionPlan]);

    useEffect(() => {
        if (loadingApiCommissionPlan !== undefined) dispatch(actionCommissionPlan.list(conditions));
    }, [conditions]);

    const renderHeaderTableCommissionPlanPayment = () => (<thead>
        <tr className="ladiui table-vertical header">
            <th className="ladiui text-pre" scope="col">{t('COMMISSIONS.NAME_COMMISSION_PLAN_PAYMENT')}</th>
            <th className="ladiui text-pre" scope="col">{t('COMMISSIONS.COMMISSION_PAYMENT_FOR')}</th>
            <th className="ladiui text-pre" scope="col">{t('COMMISSIONS.COMMISSION_PAYMENT_VALUE')}</th>
            <th className="ladiui text-pre" scope="col">{t('COMMISSIONS.COMMISSION_PAYMENT_LEVEL')}</th>
            <th className="ladiui text-pre" scope="col">{t('COMMISSIONS.COMMISSION_PAYMENT_AFFILIATE_GROUP')}</th>
        </tr>
    </thead>);

    const renderCommissionPlanPayment = (item) => (
        <tr className="ladiui table-vertical main" key={item._id}>
            <td>{item.name}</td>
            <td>
                {appConfig.LADISHARE.PAYMENT_FOR.map((paymentFor) => ((paymentFor.value === item.payment_for) ? paymentFor.name : ''))}
            </td>
            <td>
                {item.payment_type === DefineConst.PAYMENT_TYPE.PAYMENT_TYPE_PERCENT
                    ? `${BaseHelper.formatNumber(item.payment_value)}%`
                    : BaseHelper.formatMoney(item.payment_value)}
            </td>
            <td className="ladiui text-pre">{item.level === -1 ? 'Tất cả các cấp' : `Level ${item.level}`}</td>
            <td style={{ minWidth: '300px' }}>
                <ul className="list-group-aff">
                    {item.affiliate_group_id
                        ? item.affiliate_group_id.map((groupId) => <li key={groupId} style={{ padding: '6px' }} className="secondary-color">{listAffiliateGroup.pluckItems[groupId]}</li>)
                        : <></>}
                </ul>
            </td>
        </tr>);

    const renderCommissionPlan = (item) => (<div className="box-commission-plan" key={item._id}>
        <div className="ladiui card">
            <div className="ladiui card-header">
                <div className="left w-440">
                    <span className="bold-500 mr-8">{item.name}</span>
                    {item.is_default ? <span className="ladiui badge badge-success">{t('COMMISSIONS.DEFAULT')}</span> : ''}
                </div>
                <div className="left">
                    <span className="ladiui small-label">{t('COMMISSIONS.CURRENCY')}:</span><span className="bold-500 font-size-14"> {item.currency}</span>
                </div>
                <div className="right">
                    <div className="ladiui btn-group font-size-14">
                        <div className="ladiui dropdown">
                            <button data-toggle="dropdown"
                                className="ladiui-btn-dropdown dropdown-toggle">
                                <i className="ladiui icon icon-ldp-dot"></i>
                            </button>
                            <ul className="ladiui dropdown-menu custom-dropdown-menu"
                                aria-labelledby="dropdownMenuButton1">
                                <li><a className="ladiui dropdown-item" href="#/" onClick={() => editCommissionPlan(item)}>
                                    {t('COMMISSIONS.EDIT_COMMISSION_PLAN')}
                                </a></li>
                                {!item.is_default
                                    ? <li><a className="ladiui dropdown-item" href="#/" onClick={() => eventMakeAsDefault(item)}>
                                        {t('COMMISSIONS.MAKE_AS_DEFAULT')}
                                    </a></li>
                                    : <></>}
                                {!item.is_default
                                    ? < li onClick={() => eventDeleteCommissionPlan(item)}>
                                        <a className="ladiui dropdown-item lastChild btn-open-modal" data-toggle="modal" data-target="#delete-modal" href="#/">
                                            {t('COMMISSIONS.DELETE')}
                                        </a></li>
                                    : <></>}

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
            <div className="ladiui card-body">
                <table className="ladiui table text-left table-list-commission-plan-payment">
                    {renderHeaderTableCommissionPlanPayment()}
                    <tbody>
                        {item.commision_plan_payment_items.length > 0
                            ? item.commision_plan_payment_items.map((values) => (renderCommissionPlanPayment(values)))
                            : (<tr className="ladiui table-vertical main">
                                <td colSpan={6}>{t('COMMISSIONS.NO_COMMISSION_PLAN_PAYMENT')}</td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    </div >);

    return (
        <Fragment>
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui main-title">
                    {t('COMMISSIONS.LIST_COMMISSION_PLAN')}
                </div>
                <button type="button" className="ladiui btn-open-modal btn btn-primary" onClick={eventCreateCommissionPlan}>
                    <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                    <div className="ladiui btn-custom-text">{t('COMMISSIONS.ADD_COMMISSION_PLAN')}</div>
                </button>
            </div>
            <div className="ladiui access content-main-tool flex-row" style={{ marginTop: '10px' }}>
                <div className="ladiui search-group">
                    <input id="keyword_search" className="ladiui search-field dropdown-toggle form-control search-width" name="keyword" placeholder="Tìm kiếm" aria-expanded="false"
                        defaultValue={conditions.keyword || ''} onKeyDown={eventSearchKeyboard} />
                    <i className="ladiui icon icon-search" onClick={() => eventClickSearchButton()}></i>
                </div>
            </div>
            <div className="list-commission-plan">
                {listCommissionPlan.items.length > 0
                    ? listCommissionPlan.items.map((values) => (renderCommissionPlan(values)))
                    : (<div className="box-commission-plan">
                        <div className="ladiui card">
                            <div className="ladiui card-header">
                                <span className="ladiui small-label">{t('COMMISSIONS.NO_COMMISSION_PLAN')}</span>
                            </div>
                        </div>
                    </div>)}

            </div>

            <ModalCommissionPlan
                modalCommissionPlan={modalCommissionPlan}
                listAffiliateGroup={listAffiliateGroup}
                cb={() => dispatch(actionCommissionPlan.list(conditions))}
            />
            <ConfirmModal
                id="delete-modal"
                title="Bạn có chắc muốn xoá Gói hoa hồng này không?"
                content="Sau khi xoá Gói hoa hồng sẽ bị xoá vĩnh viễn bạn sẽ không thể xem lại được nữa"
                onOk={() => deleteCommissionPlan()}
                cancelText={t('COMMISSIONS.CANCELED')}
                okText={t('COMMISSIONS.DELETE_COMMISSION_PLAN')}
                classBtn="btn-danger"
            />
        </Fragment>
    );
}

export default (withTranslation()(CommissionPlan));
