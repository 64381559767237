import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import { useDispatch, useSelector } from 'react-redux';
import NumericFormat from 'react-number-format';
import styles from '../../../assets/css/custom.module.css';
import actionStore from '../../../redux/futures/store/actions';
import config from '../../../config/config';

// eslint-disable-next-line max-lines-per-function
function AffiliateDashboardSetting(props) {
    const { t } = props;

    const dispatch = useDispatch();
    const [dashboard, setDashboard] = useState({});
    const [general, setGeneral] = useState({});
    const affiliateDashboardSetting = useSelector((state) => (state.store.affiliateDashboardSetting));
    const generalSetting = useSelector((state) => (state.store.store));
    const logoUpload = useSelector((state) => (state.store.logoUpload));
    const iconUpload = useSelector((state) => (state.store.iconUpload));
    const user = useSelector((state) => state.store.userInfo);

    const cbLoadData = () => {
        dispatch(actionStore.show());
        dispatch(actionStore.getAffiliateDashboardSetting({ field: 'affiliate_dashboard_setting' }));
    };

    const eventUploadLogo = (event) => {
        const files = event.target.files;
        const uploadFile = files[0];

        const reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => {
                const height = 32;
                const width = (image.width * 32) / image.height;

                setDashboard({ ...dashboard, logo_width: Math.round(width), logo_height: height });
                return true;
            };
        };

        const form = new FormData();
        form.append('json_data', JSON.stringify({
            ladi_app: 'LADISHARE',
            file_type: 'IMAGE',
        }));
        form.append('files', uploadFile, uploadFile.name);
        dispatch(actionStore.uploadLogo(form));
    };

    const eventUploadIcon = (event) => {
        const files = event.target.files;
        const uploadFile = files[0];
        const form = new FormData();
        form.append('json_data', JSON.stringify({
            ladi_app: 'LADISHARE',
            file_type: 'IMAGE',
        }));
        form.append('files', uploadFile, uploadFile.name);
        dispatch(actionStore.uploadIcon(form));
    };

    const eventUpdate = () => {
        const dataUpdate = {
            affiliate_dashboard_setting: dashboard,
        };
        dispatch(actionStore.updateAffiliateDashboardSetting(dataUpdate, false));
        dispatch(actionStore.update(general));
    };
    const eventCancel = () => {
        cbLoadData();
    };

    const eventVerifyAndEnableSsl = () => {
        const dataUpdate = { domain: general.url_affiliate };
        dispatch(actionStore.verifyDomain(dataUpdate, true));
    };

    useEffect(() => {
        cbLoadData();
    }, []);

    useEffect(() => {
        if (generalSetting) setGeneral(generalSetting);
    }, [generalSetting]);

    useEffect(() => {
        if (affiliateDashboardSetting) setDashboard(affiliateDashboardSetting);
    }, [affiliateDashboardSetting]);

    useEffect(() => {
        if (logoUpload) setDashboard({ ...dashboard, logo: `${config.DOMAIN_CDN}/${logoUpload[0].path}` });
    }, [logoUpload]);

    useEffect(() => {
        if (iconUpload) setDashboard({ ...dashboard, icon: `${config.DOMAIN_CDN}/${iconUpload[0].path}` });
    }, [iconUpload]);
    return <>
        <div className="aff-dashboard-setting-content">
            <div className="ladiui form-group form-group-flex">
                <label className="ladiui title-text-form-lever2">Địa chỉ truy cập</label>
                <div style={{ width: 'calc(100% - 172px)', display: 'flex', alignItems: 'center' }}>
                    <input className="ladiui form-control" placeholder="Nhập domain affiliate" id="url_affiliate" name="url_affiliate"
                        value={general.url_affiliate || ''}
                        onChange={(e) => setGeneral({ ...general, ...{ url_affiliate: e.target.value } })} />
                    {general.url_affiliate && !general.is_verified_url_affiliate
                        ? <>
                            <button className="ladiui btn btn-link" onClick={() => eventVerifyAndEnableSsl()}>Xác thực và bật SSL</button>
                        </>
                        : general.url_affiliate ? <div style={{ whiteSpace: 'pre' }}><span className="ladi-icon icon-d-check color-verify" style={{ color: '#0BCB6B', fontSize: '20px', marginLeft: '4px' }}></span> Đã xác thực</div> : <></>}
                </div>
            </div>
            <div className="ladiui form-group form-group-flex">
                <label className="ladiui title-text-form-lever2">{t('STORES.AFFILIATE_URL')}</label>
                <div style={{ width: 'calc(100% - 172px)', display: 'flex', alignItems: 'center' }}>
                    <a href={`${config.AFFILIATE_DOMAIN}/${general.alias}`} target="_blank" rel="noreferrer" className="ladiui link">{`${config.AFFILIATE_DOMAIN}/${general.alias}`}</a>
                    {general.url_affiliate && general.is_verified_url_affiliate
                        ? <><span>&nbsp;hoặc&nbsp;</span><a href={`${!general.is_verified_url_affiliate ? 'http' : 'https'}://${general.url_affiliate}`} target="_blank" className="ladiui link" rel="noreferrer">{general.url_affiliate}</a></>
                        : <></>}
                </div>
            </div>
            <div className="ladiui form-group form-group-flex flex-start">
                <label className="ladiui title-text-form-lever2" htmlFor="exampleInputPassword1">Logo</label>
                <div className="form-group-file-image _logo">
                    <div className="img-preview">{dashboard.logo ? <img src={dashboard.logo} className={styles['img-fit']} /> : <></>}</div>
                    <label htmlFor="img-logo" className={styles['icon-upload-1']}>
                        <img src="https://w.ladicdn.com/ladiui/ladishare/find-replace0808.svg" />
                    </label>
                    <label className={styles['icon-upload-2']} onClick={() => setDashboard({ ...dashboard, logo: null })}>
                        <i className="ladiui icon-ldp icon-e-remove"></i>
                    </label>
                    <input type="file" className="" id="img-logo"
                        accept="image/*"
                        onChange={(e) => eventUploadLogo(e)} />
                </div>
                <div>
                    <div className="ladiui form-group-flex" style={{ marginLeft: '24px' }}>
                        <div>
                            <div className="" htmlFor="logo_height">Chiều dài (px)</div>
                            <div>
                                <NumericFormat id="logo_height" name="logo_height"
                                    style={{ width: '85px', marginTop: '6px' }}
                                    value={dashboard.logo_height || ''}
                                    allowLeadingZeros
                                    allowNegative={false}
                                    decimalScale={0}
                                    thousandSeparator=","
                                    className="ladiui form-control"
                                    placeholder="0"
                                    onValueChange={(values) => setDashboard({ ...dashboard, logo_height: values.value })}
                                />
                            </div>
                        </div>
                        <div style={{ marginLeft: '24px' }}>
                            <div className="" htmlFor="logo_width">Chiều rộng (px)</div>
                            <div>
                                <NumericFormat id="logo_width" name="logo_width"
                                    style={{ width: '85px', marginTop: '6px' }}
                                    value={dashboard.logo_width || ''}
                                    allowLeadingZeros
                                    allowNegative={false}
                                    decimalScale={0}
                                    thousandSeparator=","
                                    className="ladiui form-control"
                                    placeholder="0"
                                    onValueChange={(values) => setDashboard({ ...dashboard, logo_width: values.value })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ladiui form-group form-group-flex flex-start">
                <label className="ladiui title-text-form-lever2" htmlFor="exampleInputPassword1">Biểu tượng</label>
                <div className="form-group-file-image _icon">
                    <div className="img-preview">{dashboard.icon ? <img src={dashboard.icon} className={styles['img-fit']} /> : <></>}</div>
                    <label htmlFor="img-icon" className={styles['icon-upload-3']}>
                        Đổi ảnh khác
                    </label>
                    <input type="file" className="" id="img-icon"
                        accept="image/*"
                        onChange={(e) => eventUploadIcon(e)} />
                </div>
            </div>
            <div className="ladiui form-group form-group-flex">
                <label className="ladiui title-text-form-lever2" htmlFor="greeting_title">Câu chào mừng</label>
                <div style={{ width: 'calc(100% - 172px)' }}>
                    <input className="ladiui form-control" placeholder="Nhập nội dung" id="greeting_title"
                        value={dashboard.greeting_title || ''}
                        onChange={(e) => setDashboard({ ...dashboard, greeting_title: e.target.value })} />
                </div>
            </div>
            <div className="ladiui form-group form-group-flex">
                <label className="ladiui title-text-form-lever2" htmlFor="greeting_contents">Nội dung chào mừng</label>
                <div style={{ width: 'calc(100% - 172px)' }}>
                    <Editor
                        apiKey='d7comlpl6oluuhzdhk7kog9ell01o7otr0awkzev7cxd08wt'
                        onEditorChange={(contents) => setDashboard({ ...dashboard, greeting_contents: contents })}
                        initialValue={affiliateDashboardSetting && affiliateDashboardSetting.greeting_contents ? affiliateDashboardSetting.greeting_contents : ''}
                        init={{
                            height: 300,
                            menubar: 'insert',
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code wordcount',
                            ],
                            toolbar: 'undo redo | formatselect | '
                                + 'bold italic backcolor | alignleft aligncenter '
                                + 'alignright alignjustify | bullist numlist outdent indent | '
                                + 'removeformat | link',
                            content_style: 'body { font-family:Roboto, sans-serif; font-size:14px; color:#464646; font-weight: 400;}',
                        }}
                    />
                </div>
            </div>
            <div className="ladiui form-group form-group-flex">
                <label className="ladiui title-text-form-lever2" htmlFor="link_privacy">Link chính sách bảo mật</label>
                <div style={{ width: 'calc(100% - 172px)' }}>
                    <input className="ladiui form-control" placeholder="https://my-website.com/privacy" id="link_privacy"
                        value={dashboard.link_privacy || ''}
                        onChange={(e) => setDashboard({ ...dashboard, link_privacy: e.target.value })} />
                </div>
            </div>
            <div className="ladiui form-group form-group-flex">
                <label className="ladiui title-text-form-lever2" htmlFor="link_privacy">Link chính sách Affiliate</label>
                <div style={{ width: 'calc(100% - 172px)' }}>
                    <input className="ladiui form-control" placeholder="https://my-website.com/policy-affiliate" id="link_affiliate_policy"
                        value={dashboard.link_affiliate_policy || ''}
                        onChange={(e) => setDashboard({ ...dashboard, link_affiliate_policy: e.target.value })} />
                </div>
            </div>
            <div className="ladiui form-group form-group-flex">
                <label className="ladiui title-text-form-lever2" htmlFor="link_regulation">Link điều khoản sử dụng</label>
                <div style={{ width: 'calc(100% - 172px)' }}>
                    <input className="ladiui form-control" placeholder="https://my-website.com/regulation" id="link_regulation"
                        value={dashboard.link_regulation || ''}
                        onChange={(e) => setDashboard({ ...dashboard, link_regulation: e.target.value })} />
                </div>
            </div>
            <div className="ladiui form-group form-group-flex">
                <label className="ladiui title-text-form-lever2" htmlFor="link_help">Link liên hệ</label>
                <div style={{ width: 'calc(100% - 172px)' }}>
                    <input className="ladiui form-control" placeholder="https://my-website.com/help" id="link_help"
                        value={dashboard.link_help || ''}
                        onChange={(e) => setDashboard({ ...dashboard, link_help: e.target.value })} />
                </div>
            </div>


            <div className="ladiui form-group form-group-flex">
                <label className="ladiui title-text-form-lever2" htmlFor="link_help">Mã Javascript/Css tuỳ chỉnh</label>
                <div style={{ width: 'calc(100% - 172px)' }}>
                <textarea id="script-body" className="ladiui form-control" rows={12} style={{ color: '#6D6D6D' }} value={dashboard.custom_script || ''} onChange={(e) => setDashboard({ ...dashboard, custom_script: e.target.value })}/>
                </div>
            </div>

            <div className="ladiui form-group form-group-flex flex-start">
                <label className="ladiui title-text-form-lever2" htmlFor="disable_power_by">Tắt Power by</label>
                <div className="ladiui item-form switch">
                    <div style={{ width: 'calc(100% - 172px)' }}>

                        {user.store.pkg_name === 'TRAIL'
                            ? <label className="ladiui switch" style={{ margin: 0 }}>
                                <input type="checkbox" id="disable_power_by" name="" disabled
                                    checked={dashboard.disable_power_by || false} />
                                <span className="ladiui slider round" style={{ cursor: 'default' }}></span>
                            </label>
                            : <label className="ladiui switch" style={{ margin: 0 }}>
                                <input type="checkbox" id="disable_power_by" name="disable_power_by"
                                    checked={dashboard.disable_power_by || false} onChange={(e) => setDashboard({ ...dashboard, disable_power_by: e.target.checked })} />
                                <span className="ladiui slider round"></span>
                            </label>
                        }

                    </div>
                </div>
            </div>
            <div className="ladiui form-group">
                <button className="ladiui btn btn-primary right" onClick={eventUpdate}>{t('COMMON.UPDATE')}</button>
                <button className="ladiui btn right btn-secondary" style={{ marginRight: '40px' }} onClick={eventCancel}>{t('COMMON.CANCELED')}</button>
            </div>
        </div>
    </>;
}

export default (withTranslation()(AffiliateDashboardSetting));
