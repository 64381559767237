import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LadiDateRangePicker from '../../../components/LadiDateRangePicker';
import BaseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import TopAffiliateFilterCondition from './TopAffiliateFilterCondition';
import TopAffiliateSearchCondition from './TopAffiliateSearchCondition';
import actionCampaign from '../../../redux/futures/campaign/actions';
import DefineConst from '../../campaigns/DefineConst';
import LadiPagination from '../../../components/LadiPagination';

// eslint-disable-next-line max-lines-per-function
function Campaign(props) {
    const { t } = props;

    const defaultCondtions = {
        limit: 10,
        page: 1,
        sort: { is_priority: -1, created_at: -1 },
    };

    const moment = BaseHelper.getMoment();
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [conditions, setConditions] = useState(defaultCondtions);

    const listCampaign = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.campaigns.campaigns }));

    const cbList = () => {
        dispatch(actionCampaign.list(conditions));
    };

    const filterActionProp = (conditionRecived) => {
        setConditions({ ...conditionRecived });
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const eventExportData = () => {
        window.LadiUI.showModal('modal-export-commission');
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const getPlatform = (item) => {
        let platform = <></>;
        switch (item.type) {
            case DefineConst.PLATFORM_CAMPAIGN.FACEBOOK:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/fb.svg" /> Facebook
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.INSTAGRAM:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/instagram.svg" width={16} height={16} /> Instagram
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.LINKEDIN:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/linkedin.svg" width={16} height={16} /> Linkedin
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.TWITTER:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/twitter.svg" width={16} height={16} /> Twitter
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.BANNER:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/banner-ads-icon.svg" width={16} height={16} /> Banner
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.MAIL:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/mail.svg" width={16} height={16} /> Mail
                </div>;
                break;

            default:
        }
        return platform;
    };

    useEffect(() => {
        cbList();
    }, [conditions]);

    return <>
        <div className="ladiui content-main-tool flex-row align-item-baseline">
            <div className="mr-16">
                <LadiDateRangePicker
                    startDateId="fromDate"
                    endDateId="toDate"
                    startDate={fromDate}
                    endDate={toDate}
                    onDatesChange={({ startDate, endDate }) => {
                        const startDateNew = BaseHelper.getFromDate(startDate);
                        const endDateNew = BaseHelper.getToDate(endDate);
                        setFromDate(startDateNew);
                        setToDate(endDateNew);
                        setConditions({ ...conditions, ...{ date_from: startDateNew, date_to: endDateNew } });
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => {
                        setFocusedInput(focusedInput);
                    }}
                    showDefaultInputIcon={true}
                    inputIconPosition="after"
                    small={true}
                    hideKeyboardShortcutsPanel={true}
                    customInputIcon={
                        <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                    }
                    startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                    endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                    isOutsideRange={() => false}
                    isDayHighlighted={(date) => moment().isSame(date, 'day')}
                    minimumNights={0}
                    showClearDates={true}
                />
            </div>
            <div className="ladiui btn-group">
                <TopAffiliateFilterCondition conditionProp={conditions} filterActionProp={filterActionProp} />
            </div>
            <div style={{ minWidth: 'calc(100% - 664px)' }}>
                <TopAffiliateSearchCondition
                    keywordProp={conditions.keyword}
                    eventSearchKeyboardProp={eventSearchKeyboard}
                    eventClickSearchButtonProp={eventClickSearchButton}
                />
            </div>
            <div className="ladiui btn-group custom-btn-list-landingpage">
                <button className="ladiui btn btn-light" onClick={eventExportData}>
                    <img src="https://w.ladicdn.com/design-system/icons/icon-export.svg"
                        style={{ marginBottom: '2px', marginRight: '10px' }} />{t('COMMON.EXPORT_DATA')}
                </button>
            </div>
        </div>
        <table className="ladiui table text-left">
            <thead>
                <tr className="ladiui table-vertical header">
                    <th scope="col">Tiêu đề</th>
                    <th scope="col">Nền tảng</th>
                    <th scope="col" className="ladiui text-pre">Lượt click</th>
                    <th scope="col">Hoa hồng được giao</th>
                    <th scope="col">Tỷ lệ chuyển đổi</th>
                </tr>
            </thead>
            <tbody>
                {listCampaign.items.length > 0 ? listCampaign.items.map((item) => (<tr key={item._id} className="ladiui table-vertical main">
                    <td>
                        <Link to={`/campaigns/${item._id}`} className="ladiui link">{item.title}</Link>
                    </td>
                    <td>
                        {getPlatform(item)}
                    </td>
                    <td>
                        241.000
                    </td>
                    <td>45.000.000đ</td>
                    <td>45%</td>
                </tr>))
                    : <tr><td colSpan={5}>{t('NO_DATA')}</td></tr>}

            </tbody>
        </table>
        {listCampaign.items.length > 0
            ? <LadiPagination
                conditionsProp={conditions}
                listName={t('CAMPAIGNS.CAMPAIGN')}
                items={listCampaign.items}
                total={listCampaign.total}
                limitAction={selectLimit}
                pageAction={selectPage}
            /> : ''}
    </>;
}

export default (withTranslation()(Campaign));
