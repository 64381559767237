import i18n from '../../i18n';

export default {
    COUPON_PAYMENT_TYPE: {
        PERCENT: 'PERCENT',
        FIXED: 'FIXED',
    },
    COUPON_PAYMENT_TYPE_NAME: {
        PERCENT: `${i18n.t('COMMISSIONS.PAYMENT_TYPE.PERCENT', { interpolation: { escapeValue: false } })}`,
        FIXED: `${i18n.t('COMMISSIONS.PAYMENT_TYPE.FIXED')}`,
    },
    COUPON_PAYMENT_TYPE_ARRAY: [
        { name: `${i18n.t('COMMISSIONS.PAYMENT_TYPE.PERCENT', { interpolation: { escapeValue: false } })}`, value: 'PERCENT' },
        { name: `${i18n.t('COMMISSIONS.PAYMENT_TYPE.FIXED', { interpolation: { escapeValue: false } })}`, value: 'FIXED' },
    ],
    COUPON_TYPE: {
        DEFAULT: 'DEFAULT',
        CUSTOM: 'CUSTOM',
    },
};
