import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

function SearchCondition(props) {
    const {
        t, keywordProp, eventSearchKeyboardProp, eventClickSearchButtonProp,
    } = props;

    const [keyword, setKeyword] = useState();

    useEffect(() => {
        setKeyword(keywordProp);
    }, [keywordProp]);

    return <>
        <div className="ladiui search-group">
            <input id="keyword_search" className="ladiui search-field dropdown-toggle form-control search-width"
                name="keyword" placeholder={t('COMMON.SEARCH')} aria-expanded="false"
                defaultValue={keyword || ''} onKeyDown={(e) => eventSearchKeyboardProp(e)} />
            <i className="ladiui icon icon-search" onClick={(e) => eventClickSearchButtonProp(e)}></i>
        </div>
    </>;
}

export default (withTranslation()(SearchCondition));
