import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import appConfig, { endpoint } from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

const signIn = (email, password, app_code, store_id) => ({
    type: types.SIGNIN,
    meta: {
        endpoint: endpoint.AUTH_SIGNIN,
        method: REQUEST_METHOD.POST,
        body: {
            email,
            password,
            app_code,
            store_id,
        },
        hasAuth: false,
        hasStoreID: false,
    },
});

const signUp = (email, password, name) => ({
    type: types.SIGNUP,
    meta: {
        endpoint: endpoint.AUTH_SIGNUP,
        method: REQUEST_METHOD.POST,
        body: {
            fullname: name,
            email,
            password,
        },
        hasAuth: false,
        hasStoreID: false,
    },
});

const signOut = () => ({
    type: types.SIGNOUT,
});

const renewToken = (storeID, appCode = appConfig.APP.LF) => ({
    type: types.RENEW_TOKEN,
    meta: {
        endpoint: endpoint.AUTH_RENEW_TOKEN,
        method: REQUEST_METHOD.POST,
        body: {
            token: baseHelper.getCookie(appConfig.COOKIE.SSID),
            store_id: storeID,
            app_code: appCode,
        },
        hasAuth: false,

    },
});

const signInSuccess = (ladiUID, storeID) => ({
    type: types.SIGNIN_SUCCESS,
    payload: {
        ladi_uid: ladiUID,
        store_id: storeID,
    },
});

export default {
    signIn,
    signUp,
    signOut,
    renewToken,
    signInSuccess,
};
