import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import sprintf from 'i18next-sprintf-postprocessor';
import enJson from './language/en.json';
import viJson from './language/vi.json';

const resources = {
    en: {
        translation: enJson
    },
    vi: {
        translation: viJson
    }
}

i18next
    .use(sprintf)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'vi',
        keySeparator: '.',
        interpolation: {
            escapeValue: false
        },
        overloadTranslationOptionHandler: sprintf.overloadTranslationOptionHandler
    })

export default i18next;