import React, { useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumericFormat from 'react-number-format';
import LadiDropdown from '../../../components/LadiDropdown';
import LadiDropdownMultiSelect2 from '../../../components/LadiDropdownMultiSelect2';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';
import DefineConst from '../DefineConst';
import CommissionPlanPaymentBoxCountCustomer from './commission_plan_payment/CommissionPlanPaymentBoxCountCustomer';
import CommissionPlanPaymentBoxCountOrder from './commission_plan_payment/CommissionPlanPaymentBoxCountOrder';
import CommissionPlanPaymentBoxCountIntervals from './commission_plan_payment/CommissionPlanPaymentBoxCountIntervals';
import CommissionPlanPaymentBoxCountTotalOrder from './commission_plan_payment/CommissionPlanPaymentBoxCountTotalOrder';
import CommissionPlanPaymentBoxShopId from './commission_plan_payment/CommissionPlanPaymentBoxShopId';
import CommissionPlanPaymentBoxOrderPerCustomer from './commission_plan_payment/CommissionPlanPaymentBoxOrderPerCustomer';
import CommissionPlanPaymentBoxPriceRange from './commission_plan_payment/CommissionPlanPaymentBoxPriceRange';
import CommissionPlanPaymentBoxTime from './commission_plan_payment/CommissionPlanPaymentBoxTime';
import SKUs from './commission_plan_payment/SKUs';
import actionIntegration from '../../../redux/futures/integrate_app/actions';
import CommissionPlanPaymentBoxMaxAmount from './commission_plan_payment/CommissionPlanPaymentBoxMaxAmount';
import CommissionPlanPaymentBoxOrderSku from './commission_plan_payment/CommissionPlanPaymentBoxOrderSku';
import CommissionPlanPaymentCategoryBox from './commission_plan_payment/CommissionPlanPaymentCategoryBox';
import CommissionPlanPaymentBrandBox from './commission_plan_payment/CommissionPlanPaymentBrandBox';
import CommissionPlanPaymentBoxConditionParentLevel from './commission_plan_payment/CommissionPlanPaymentBoxConditionParentLevel';
import CommissionParent from './commission_plan_payment/CommissionParent';

// eslint-disable-next-line max-lines-per-function
function CommissionPlanPaymentBox(props) {
    const moment = BaseHelper.getMoment();
    const {
        t, commissionPlanPayment, eventChangeCommissionPlanPayment, eventRemoveCommissionPlanPayment, affiliateGroup, currency,
    } = props;

    const dispatch = useDispatch();
    const [itemCommissionPlanPayment, setItemCommissionPlanPayment] = useState({});
    const [focused, setFocused] = useState(false);
    const [dateExpired, setDateExpired] = useState(null);
    const listAppIntegrate = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.appIntegrate.integrateApps }));

    const eventChangeInputCommissionPlanPaymentBox = (e) => {
        const fieldName = e.target.name;
        let fieldValue;
        if (
            e.target.tagName.toLowerCase() === 'input'
            && e.target.getAttribute('type') === 'checkbox'
        ) {
            fieldValue = e.target.checked;
        } else {
            fieldValue = e.target.value;
        }
        const data = {};
        data[fieldName] = fieldValue;
        const item = { ...itemCommissionPlanPayment, ...data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const changeInputValue = (fieldName, value) => {
        const data = { ...itemCommissionPlanPayment };
        data[fieldName] = value;
        setItemCommissionPlanPayment(data);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, data);
    };

    const eventSelectLevel = (level) => {
        const item = { ...itemCommissionPlanPayment, ...{ level } };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventSelectPaymentFor = (type) => {
        const item = { ...itemCommissionPlanPayment, payment_for: type };
        if (type === DefineConst.PAYMENT_FOR.NEW_CUSTOMER || type === DefineConst.PAYMENT_FOR.LEAD) {
            item.payment_type = DefineConst.PAYMENT_TYPE.PAYMENT_TYPE_FIXED;
        }
        if (type === DefineConst.PAYMENT_FOR.NEW_CUSTOMER || type === DefineConst.PAYMENT_FOR.LEAD || type === DefineConst.PAYMENT_FOR.KPIS) {
            item.commission_calc_by = 'BY_COMMISSION';
        }
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventSelectPaymentType = (type) => {
        const item = { ...itemCommissionPlanPayment, ...{ payment_type: type } };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventEnableCommissionParent = (e) => {
        const item = { ...itemCommissionPlanPayment, enable_commission_parent: !itemCommissionPlanPayment.enable_commission_parent };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const changePrevPaymentValue = (value, level) => {
        const keyLevel = `commission_payment_value_level_${level === -1 ? 'all' : level}`;
        const item = { ...itemCommissionPlanPayment };
        item[keyLevel] = value;
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventSelectAffiliateGroup = (idGroups) => {
        let idGroup = [];
        for (let i = 0; i < idGroups.length; i++) {
            idGroup.push(idGroups[i].value);
        }
        const item = { ...itemCommissionPlanPayment, ...{ affiliate_group_id: idGroup } };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventSelectShopId = (idShops) => {
        let idShop = [];
        for (let i = 0; i < idShops.length; i++) {
            idShop.push(idShops[i].value);
        }
        const item = { ...itemCommissionPlanPayment, ...{ shop_id: idShop } };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const getAffiliateGroupDropdown = () => {
        const itemDropdown = [];
        for (let i = 0; i < affiliateGroup.items.length; i++) {
            itemDropdown.push({ value: affiliateGroup.items[i]._id, name: affiliateGroup.items[i].name });
        }
        return itemDropdown;
    };

    const getShopIdDropdown = () => {
        const itemDropdown = [];
        for (let i = 0; i < listAppIntegrate.items.length; i++) {
            itemDropdown.push({ value: listAppIntegrate.items[i]._id, name: listAppIntegrate.items[i].name });
        }
        return itemDropdown;
    };

    const getValueAffiliateGroupDropdown = (groupId) => {
        let idGroup = [];
        if (!groupId) {
            for (let i = 0; i < affiliateGroup.items.length; i++) {
                if (affiliateGroup.items[i].is_default) idGroup.push({ name: affiliateGroup.items[i].name, value: affiliateGroup.items[i]._id });
            }
        } else {
            for (let i = 0; i < affiliateGroup.items.length; i++) {
                if (groupId.includes(affiliateGroup.items[i]._id)) idGroup.push({ name: affiliateGroup.items[i].name, value: affiliateGroup.items[i]._id });
            }
        }
        return idGroup;
    };

    const getValueShopIdDropdown = (shopId) => {
        let idShop = [];
        if (shopId) {
            for (let i = 0; i < listAppIntegrate.items.length; i++) {
                if (shopId.includes(listAppIntegrate.items[i]._id)) idShop.push({ name: listAppIntegrate.items[i].name, value: listAppIntegrate.items[i]._id });
            }
        }
        return idShop;
    };

    const eventCommissionPlanPaymentKPIs = (data) => {
        const item = { ...itemCommissionPlanPayment, payment_kpis_term: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentIntervals = (data) => {
        const item = { ...itemCommissionPlanPayment, payment_intervals: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentCountCustomer = (data) => {
        const item = { ...itemCommissionPlanPayment, payment_count_customer: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentCountOrder = (data) => {
        const item = { ...itemCommissionPlanPayment, payment_count_order: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentCountTotalOrder = (data) => {
        const item = { ...itemCommissionPlanPayment, payment_count_total_order: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentConditionParentLevel = (data) => {
        const item = { ...itemCommissionPlanPayment, payment_condition_parent_level: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentOrderPerCustomer = (data) => {
        const item = { ...itemCommissionPlanPayment, payment_order_per_customer: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentSkus = (data) => {
        const item = { ...itemCommissionPlanPayment, skus: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentPriceRange = (data) => {
        const item = { ...itemCommissionPlanPayment, payment_price_range: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentMaxAmount = (data) => {
        const item = { ...itemCommissionPlanPayment, max_amount_commission: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentOrderSku = (data) => {
        const item = { ...itemCommissionPlanPayment, order_skus: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentBrandID = (data) => {
        const item = { ...itemCommissionPlanPayment, brand_ids: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventCommissionPlanPaymentCategoryID = (data) => {
        const item = { ...itemCommissionPlanPayment, category_ids: data };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const eventShowHelp = (id) => {
        window.LadiUI.showModal(id);
    };

    const eventChooseDateExpired = (date) => {
        setDateExpired(date);
        const item = { ...itemCommissionPlanPayment, expired_date: date };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const getCommissionCalcBy = (type) => {
        let data = [
            { name: 'Theo hoa hồng', value: 'BY_COMMISSION' },
        ];
        if (!['LEAD', 'NEW_CUSTOMER', 'KPIS'].includes(type)) {
            data.push({ name: 'Theo tổng hoá đơn', value: 'BY_TOTAL_ORDER' });
        }
        return data;
    };

    const eventSelectCommissionCalcBy = (type) => {
        const item = {
            ...itemCommissionPlanPayment,
            commission_calc_by: type,
            commission_payment_type_level_1: 'PERCENT',
            commission_payment_type_level_2: 'PERCENT',
            commission_payment_type_level_all: 'PERCENT',
        };
        const settings = itemCommissionPlanPayment.setting_parent_level || [
            {
                level: 2,
                parent_calc_number: 0,
                parent_calc_unit: 'PERCENT',
            },
        ];
        const newSettings = [];
        for (let i = 0; i < settings.length; i++) {
            const setting = settings[i];
            setting.parent_calc_unit = 'PERCENT';
            newSettings.push(setting);
        }
        item.setting_parent_level = newSettings;
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const selectCommissionLevelType = (type, level) => {
        const key = `commission_payment_type_level_${level === -1 ? 'all' : level}`;
        const data = {};
        data[key] = type;
        setItemCommissionPlanPayment({ ...itemCommissionPlanPayment, ...data });
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, { ...itemCommissionPlanPayment, ...data });
    };

    const getTypeOrderApply = () => [
        { name: 'Toàn bộ đơn hàng', value: 'ALL_ORDER' },
        { name: 'Đơn hàng đã thanh toán thành công', value: 'COMPLETED_ORDER' },
    ];

    const eventSelectAutoApprovedFor = (type) => {
        const item = {
            ...itemCommissionPlanPayment,
            auto_approved_commission_for: type,
        };
        setItemCommissionPlanPayment(item);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, item);
    };

    const getListSettingParentLevel = (items) => {
        if (!items || items.length <= 0) return [
            {
                level: 2,
                parent_calc_number: 0,
                parent_calc_unit: 'PERCENT',
            },
        ];

        return items.sort((a, b) => a.level - b.level);
    };

    const updateSettingParentLevel = (item) => {
        const settings = (!itemCommissionPlanPayment.setting_parent_level || itemCommissionPlanPayment.setting_parent_level.length <= 0)
            ? [item] : itemCommissionPlanPayment.setting_parent_level;
        const newSettings = [];
        for (let i = 0; i < settings.length; i++) {
            const setting = settings[i];
            if (setting.level === item.level) {
                newSettings.push(item);
            } else {
                newSettings.push(setting);
            }
        }
        const newItem = {
            ...itemCommissionPlanPayment,
            setting_parent_level: newSettings,
        };
        setItemCommissionPlanPayment(newItem);
        eventChangeCommissionPlanPayment(itemCommissionPlanPayment._id, newItem);
    };

    useEffect(() => {
        dispatch(actionIntegration.list({ sort: { _id: -1 }, limit: 1000 }));
    }, []);

    useEffect(() => {
        if (commissionPlanPayment) {
            setItemCommissionPlanPayment(commissionPlanPayment);
            if (commissionPlanPayment.expired_date) {
                setDateExpired(moment(commissionPlanPayment.expired_date));
            }
        }
    }, [commissionPlanPayment]);

    return <>
        <div className="ladiui item bg-form bg-commissionn-plan mt-22" key={itemCommissionPlanPayment._id}>
            <div className="ladiui item bg-form-sub">
                <div>
                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.NAME_COMMISSION_PLAN_PAYMENT')}</label>
                        <input type="text" name="name" className="ladiui form-control btn-sm" placeholder="Nhập tên cấu hình trả hoa hồng"
                            value={itemCommissionPlanPayment.name} onChange={(e) => eventChangeInputCommissionPlanPaymentBox(e)} />
                    </div>
                </div>
                <div className="ladiui pt-20 d-grid-4" style={{ gridTemplateColumns: '177px 177px 366px' }}>
                    <div className="ladiui">
                        <CommissionPlanPaymentBoxShopId
                            labelDropdown='Shop ID'
                            listProp={getShopIdDropdown()}
                            listCheckedProp={getValueShopIdDropdown(itemCommissionPlanPayment.shop_id)}
                            cb={eventSelectShopId}
                        />
                    </div>
                    <div className="ladiui time-expired-plan-payment">
                        <label className="ladiui title-text-form-lever2" >
                            Thời gian hết hạn
                            <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13"
                                style={{ marginBottom: '2px', marginLeft: '3px', cursor: 'pointer' }}
                                onClick={() => eventShowHelp('modal-help-expired-time')}
                            />
                        </label>
                        <SingleDatePicker
                            date={dateExpired} // momentPropTypes.momentObj or null
                            onDateChange={(date) => eventChooseDateExpired(date)} // PropTypes.func.isRequired
                            focused={focused} // PropTypes.bool
                            onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                            numberOfMonths={1}
                            showClearDate={true}
                            noBorder={true}
                            placeholder="Chọn ngày hết hạn"
                            readOnly={true}
                            isOutsideRange={(day) => false}
                            showDefaultInputIcon={true}
                            inputIconPosition="after"
                            small={true}
                            customInputIcon={
                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                            }
                        />

                    </div>
                    <div className="ladiui">
                        <LadiDropdownMultiSelect2
                            labelDropdown={t('COMMISSIONS.COMMISSION_PAYMENT_AFFILIATE_GROUP')}
                            listProp={getAffiliateGroupDropdown()}
                            listCheckedProp={getValueAffiliateGroupDropdown(itemCommissionPlanPayment.affiliate_group_id)}
                            cb={eventSelectAffiliateGroup}
                        />
                    </div>
                </div>

                <div className="ladiui clearfix pt-20 d-grid-4" style={{ gridTemplateColumns: '177px 177px 177px 177px' }}>
                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.COMMISSION_PAYMENT_FOR')}</label>
                        <div className="ladiui btn-group custom-form-dynamic">
                            <div className="ladiui btn-group">
                                <LadiDropdown
                                    listProp={appConfig.LADISHARE.PAYMENT_FOR.filter((item) => (item.value !== 'VISITOR'))}
                                    valueProp={itemCommissionPlanPayment.payment_for}
                                    hideLabel={true}
                                    validProp={true}
                                    classDropdown="dropdown-custom"
                                    classSize="btn-sm"
                                    cbProp={eventSelectPaymentFor}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever2" >
                            {t('COMMISSIONS.LEVEL')}
                            <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13"
                                style={{ marginBottom: '2px', marginLeft: '3px', cursor: 'pointer' }}
                                onClick={() => eventShowHelp('modal-help-level')}
                            />
                        </label>
                        <div className="ladiui btn-group custom-form-dynamic">
                            <div className="ladiui btn-group">
                                <LadiDropdown
                                    listProp={[...DefineConst.LEVEL_AFFILIATE, { name: 'Tất cả các level', value: -1 }]}
                                    valueProp={parseInt(itemCommissionPlanPayment.level)}
                                    hideLabel={true}
                                    validProp={true}
                                    classDropdown="dropdown-custom custom-dropdown-2"
                                    classSize="btn-sm"
                                    prefixName={itemCommissionPlanPayment.level === -1 ? '' : t('COMMISSIONS.LEVEL')}
                                    cbProp={eventSelectLevel}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ladiui">
                        {
                            itemCommissionPlanPayment.level === -1
                                ? <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.COMMISSION')} cho affiliate</label>
                                : <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.COMMISSION')} Level {itemCommissionPlanPayment.level}</label>
                        }
                        <div className="group-input-custom custom-form-dynamic">
                            <NumericFormat name="payment_value"
                                value={itemCommissionPlanPayment.payment_value}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={2}
                                thousandSeparator=","
                                className="ladiui form-control"
                                placeholder="0"
                                onValueChange={(values) => changeInputValue('payment_value', values.value)}
                            />

                            <div className="ladiui btn-group btn-group-custom" style={{ maxWidth: '40px' }}>
                                <div className="ladiui btn-group">
                                    <div className="ladiui dropdown">
                                        <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown btn-sm show" data-toggle="dropdown" style={{ maxWidth: '45px' }}>
                                            <i></i>
                                            <span className="ladiui dropdown-text font-size-11">
                                                {itemCommissionPlanPayment.payment_type === DefineConst.PAYMENT_TYPE.PAYMENT_TYPE_PERCENT ? '%' : currency}
                                            </span>
                                        </button>
                                        <ul className="ladiui dropdown-menu">
                                            <li><a className="ladiui dropdown-item font-size-11" href="#/"
                                                onClick={() => eventSelectPaymentType(DefineConst.PAYMENT_TYPE.PAYMENT_TYPE_FIXED)}>{currency}</a></li>
                                            {(itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.NEW_CUSTOMER
                                                || itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.LEAD) ? <></>
                                                : <li><a className="ladiui dropdown-item font-size-11" href="#/"
                                                    onClick={() => eventSelectPaymentType(DefineConst.PAYMENT_TYPE.PAYMENT_TYPE_PERCENT)}>%</a></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    {itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.PER_PRODUCT ? <>
                        <SKUs skusProp={itemCommissionPlanPayment.skus || null} cb={eventCommissionPlanPaymentSkus} />
                    </> : <></>}
                </div>
                {itemCommissionPlanPayment.level !== 1
                    ? <>
                        <div className="ladiui clearfix pt-20">
                            <label className="ladiui title-text-form-lever2 left">Hoa hồng cấp trên</label>
                            <div className="ladiui item-form switch">
                                <label className="ladiui switch" style={{ marginTop: '2px' }}>
                                    <input type="checkbox" id="create_commission_parent" name="create_commission_parent"
                                        checked={itemCommissionPlanPayment.enable_commission_parent || false}
                                        onChange={(e) => eventEnableCommissionParent(e)}
                                    />
                                    <span className="ladiui slider round"></span>
                                </label>
                            </div>
                            <div className="clearfix font-size-12">Trả hoa hồng cho cấp trên của affiliate</div>
                        </div>
                        {itemCommissionPlanPayment.enable_commission_parent
                            ? <div className="ladiui box-white">
                                <div className="ladiui clearfix d-grid-4" style={{ gridTemplateColumns: '169px 169px 169px 169px' }}>
                                    <div className="ladiui">
                                        <label className="ladiui title-text-form-lever3">Tính hoa hồng theo</label>
                                        <LadiDropdown
                                            listProp={getCommissionCalcBy(itemCommissionPlanPayment.payment_for)}
                                            valueProp={itemCommissionPlanPayment.commission_calc_by}
                                            hideLabel={true}
                                            validProp={true}
                                            classDropdown="dropdown-custom custom-dropdown-2"
                                            classSize="btn-sm"
                                            defaultTextProp="Chọn loại"
                                            cbProp={eventSelectCommissionCalcBy} />
                                    </div>
                                    {itemCommissionPlanPayment.level === 3
                                        ? <div className="ladiui">
                                            <label className="ladiui title-text-form-lever3" >{t('COMMISSIONS.COMMISSION')} Level 2</label>
                                            <div className="group-input-custom custom-form-dynamic">
                                                <NumericFormat name="level_2"
                                                    value={itemCommissionPlanPayment.commission_payment_value_level_2 || ''}
                                                    allowLeadingZeros
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    thousandSeparator=","
                                                    className="ladiui form-control"
                                                    placeholder="0"
                                                    onValueChange={(values) => changePrevPaymentValue(values.value, 2)}
                                                />
                                                <div className="ladiui btn-group btn-group-custom" style={{ maxWidth: '40px' }}>
                                                    <div className="ladiui btn-group">
                                                        <div className="ladiui dropdown">
                                                            <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown btn-sm show" data-toggle="dropdown" style={{ maxWidth: '45px' }}>
                                                                <i></i>
                                                                <span className="ladiui dropdown-text font-size-11">
                                                                    {itemCommissionPlanPayment.commission_payment_type_level_2 === 'FIXED' ? 'VND' : '%'}
                                                                </span>
                                                            </button>
                                                            <ul className="ladiui dropdown-menu">
                                                                {itemCommissionPlanPayment.commission_calc_by !== 'BY_COMMISSION'
                                                                    ? <li onClick={() => selectCommissionLevelType('FIXED', 2)}><a className="ladiui dropdown-item font-size-11" href="#/"
                                                                    >VND</a></li> : <></>}
                                                                <li onClick={() => selectCommissionLevelType('PERCENT', 2)}><a className="ladiui dropdown-item font-size-11" href="#/"
                                                                >%</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <></>}
                                    {itemCommissionPlanPayment.level === 2 || itemCommissionPlanPayment.level === 3
                                        ? <div className="ladiui">
                                            <label className="ladiui title-text-form-lever3" >{t('COMMISSIONS.COMMISSION')} Level 1</label>
                                            <div className="group-input-custom custom-form-dynamic">
                                                <NumericFormat name="level_1"
                                                    value={itemCommissionPlanPayment.commission_payment_value_level_1 || ''}
                                                    allowLeadingZeros
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    thousandSeparator=","
                                                    className="ladiui form-control"
                                                    placeholder="0"
                                                    onValueChange={(values) => changePrevPaymentValue(values.value, 1)}
                                                />
                                                <div className="ladiui btn-group btn-group-custom" style={{ maxWidth: '40px' }}>
                                                    <div className="ladiui btn-group">
                                                        <div className="ladiui dropdown">
                                                            <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown btn-sm show" data-toggle="dropdown" style={{ maxWidth: '45px' }}>
                                                                <i></i>
                                                                <span className="ladiui dropdown-text font-size-11">
                                                                    {itemCommissionPlanPayment.commission_payment_type_level_1 === 'FIXED' ? 'VND' : '%'}
                                                                </span>
                                                            </button>
                                                            <ul className="ladiui dropdown-menu">
                                                                {itemCommissionPlanPayment.commission_calc_by !== 'BY_COMMISSION'
                                                                    ? <li onClick={() => selectCommissionLevelType('FIXED', 1)}><a className="ladiui dropdown-item font-size-11" href="#/"
                                                                    >VND</a></li> : <></>}
                                                                <li onClick={() => selectCommissionLevelType('PERCENT', 1)}><a className="ladiui dropdown-item font-size-11" href="#/"
                                                                >%</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <></>}
                                    {itemCommissionPlanPayment.level === -1
                                        ? <><div className="ladiui">
                                            <label className="ladiui title-text-form-lever3" >{t('COMMISSIONS.COMMISSION')} cấp trên</label>
                                            <div className="group-input-custom custom-form-dynamic">
                                                <NumericFormat name="level_all"
                                                    value={itemCommissionPlanPayment.commission_payment_value_level_all || ''}
                                                    allowLeadingZeros
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    thousandSeparator=","
                                                    className="ladiui form-control"
                                                    placeholder="0"
                                                    onValueChange={(values) => changePrevPaymentValue(values.value, -1)}
                                                />
                                                <div className="ladiui btn-group btn-group-custom" style={{ maxWidth: '40px' }}>
                                                    <div className="ladiui btn-group">
                                                        <div className="ladiui dropdown">
                                                            <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown btn-sm show" data-toggle="dropdown" style={{ maxWidth: '45px' }}>
                                                                <i></i>
                                                                <span className="ladiui dropdown-text font-size-11">
                                                                    {itemCommissionPlanPayment.commission_payment_type_level_all === 'FIXED' ? 'VND' : '%'}
                                                                </span>
                                                            </button>
                                                            <ul className="ladiui dropdown-menu">
                                                                {itemCommissionPlanPayment.commission_calc_by !== 'BY_COMMISSION'
                                                                    ? <li onClick={() => selectCommissionLevelType('FIXED', -1)}><a className="ladiui dropdown-item font-size-11" href="#/"
                                                                    >VND</a></li> : <></>}
                                                                <li onClick={() => selectCommissionLevelType('PERCENT', -1)}><a className="ladiui dropdown-item font-size-11" href="#/"
                                                                >%</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            {getListSettingParentLevel(itemCommissionPlanPayment.setting_parent_level).map((item, index) => <CommissionParent key={index}
                                                settingData={item}
                                                updateSettingProp={updateSettingParentLevel}
                                                commissionCalcBy={itemCommissionPlanPayment.commission_calc_by}
                                            />)}
                                        </> : <></>}
                                </div>

                                <CommissionPlanPaymentBoxConditionParentLevel
                                    paymentFor={itemCommissionPlanPayment.payment_for}
                                    paymentConditionParentProp={itemCommissionPlanPayment.payment_condition_parent_level || { enable: false }}
                                    cb={eventCommissionPlanPaymentConditionParentLevel} />
                            </div>
                            : <></>}</>
                    : <></>}
                {itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.KPIS
                    ? <>
                        <CommissionPlanPaymentBoxTime
                            paymentTimeProp={itemCommissionPlanPayment.payment_kpis_term || { type: DefineConst.PAYMENT_TERM_TYPE.MONTH, payment_type: DefineConst.PAYMENT_KPI_TERM_TYPE.TOTAL }}
                            cb={eventCommissionPlanPaymentKPIs} />
                    </>
                    : <></>}
                {itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.ORDER
                    || itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.KPIS
                    ? <>
                        <CommissionPlanPaymentBoxCountCustomer
                            paymentFor={itemCommissionPlanPayment.payment_for}
                            paymentCountCustomerProp={itemCommissionPlanPayment.payment_count_customer || { enable: false }}
                            cb={eventCommissionPlanPaymentCountCustomer} />
                        <CommissionPlanPaymentBoxCountOrder
                            paymentFor={itemCommissionPlanPayment.payment_for}
                            paymentCountOrderProp={itemCommissionPlanPayment.payment_count_order || { enable: false }}
                            cb={eventCommissionPlanPaymentCountOrder} />
                        {itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.ORDER
                            ? <CommissionPlanPaymentBoxOrderPerCustomer
                                paymentFor={itemCommissionPlanPayment.payment_for}
                                paymentOrderPerCustomerProp={itemCommissionPlanPayment.payment_order_per_customer || { enable: false }}
                                cb={eventCommissionPlanPaymentOrderPerCustomer} />
                            : <></>}
                    </> : <></>
                }
                {itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.PER_PRODUCT
                    || itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.ORDER
                    || itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.KPIS
                    ? <CommissionPlanPaymentBoxCountTotalOrder
                        paymentFor={itemCommissionPlanPayment.payment_for}
                        paymentCountTotalOrderProp={itemCommissionPlanPayment.payment_count_total_order || { enable: false }}
                        cb={eventCommissionPlanPaymentCountTotalOrder} />
                    : <></>}

                {itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.NEW_CUSTOMER
                    || itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.LEAD
                    || itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.PER_PRODUCT
                    ? <CommissionPlanPaymentBoxCountIntervals
                        paymentFor={itemCommissionPlanPayment.payment_for}
                        paymentIntervalsProp={itemCommissionPlanPayment.payment_intervals || {}}
                        cb={eventCommissionPlanPaymentIntervals} />
                    : <></>}
                {itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.PER_PRODUCT
                    || itemCommissionPlanPayment.payment_for === DefineConst.PAYMENT_FOR.ORDER
                    ? <>
                        <CommissionPlanPaymentBoxPriceRange
                            paymentFor={itemCommissionPlanPayment.payment_for}
                            paymentPriceRangeProp={itemCommissionPlanPayment.payment_price_range || null}
                            cb={eventCommissionPlanPaymentPriceRange} />
                    </> : <></>
                }
                {[DefineConst.PAYMENT_FOR.ORDER, DefineConst.PAYMENT_FOR.PER_PRODUCT].includes(itemCommissionPlanPayment.payment_for)
                    ? <>
                        < div className="ladiui clearfix pt-20">
                            <label className="ladiui title-text-form-lever2 left">Tự động tạm duyệt hoa hồng</label>
                            <div className="ladiui item-form switch">
                                <label className="ladiui switch">
                                    <input type="checkbox"
                                        name="auto_approved_commission"
                                        checked={itemCommissionPlanPayment.auto_approved_commission || false}
                                        onChange={(e) => eventChangeInputCommissionPlanPaymentBox(e)} />
                                    <span className="ladiui slider round"></span>
                                </label>
                            </div>
                            <div className="clearfix font-size-12">Hoa hồng sẽ được tự động tạm duyệt cho affiliate tương ứng nếu phát sinh đơn hàng</div>
                            {itemCommissionPlanPayment.auto_approved_commission
                                ? <div className="ladiui left">
                                    <div className="group-input-custom custom-form-dynamic w-180 custom-dropdown-2" style={{ marginTop: '12px' }}>
                                        <LadiDropdown
                                            listProp={getTypeOrderApply()}
                                            valueProp={itemCommissionPlanPayment.auto_approved_commission_for || 'ALL_ORDER'}
                                            hideLabel={true}
                                            validProp={true}
                                            classDropdown="dropdown-custom"
                                            classSize="btn-sm"
                                            cbProp={eventSelectAutoApprovedFor}
                                        />
                                    </div>
                                </div> : <></>}
                        </div>
                        <CommissionPlanPaymentBoxOrderSku
                            paymentFor={itemCommissionPlanPayment.payment_for}
                            orderSkuProp={itemCommissionPlanPayment.order_skus || null}
                            cb={eventCommissionPlanPaymentOrderSku}
                        />
                        <CommissionPlanPaymentBrandBox
                            paymentFor={itemCommissionPlanPayment.payment_for}
                            brandIDProp={itemCommissionPlanPayment.brand_ids || null}
                            cb={eventCommissionPlanPaymentBrandID}
                        />
                        <CommissionPlanPaymentCategoryBox
                            paymentFor={itemCommissionPlanPayment.payment_for}
                            categoryIDProp={itemCommissionPlanPayment.category_ids || null}
                            cb={eventCommissionPlanPaymentCategoryID}
                        />
                    </>
                    : <></>
                }

                <CommissionPlanPaymentBoxMaxAmount
                    paymentFor={itemCommissionPlanPayment.payment_for}
                    maxAmountCommissionProp={itemCommissionPlanPayment.max_amount_commission || null}
                    cb={eventCommissionPlanPaymentMaxAmount} />

                <div className="ladiui flex-row custom-bg-form clearfix pt-20">
                    <div>

                    </div>
                    <div className="ladiui flex-row custom-create-dynamic">
                        <button type="button" className="ladiui font-size-12 btn btn-secondary" onClick={() => eventRemoveCommissionPlanPayment(itemCommissionPlanPayment._id)}>
                            <i className="ladiui icon icon-ldp-delete-black-sz"></i> {t('COMMISSIONS.DELET_COMMISSION_PLAN_PAYMENT')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBox));
