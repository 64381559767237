import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { withTranslation } from 'react-i18next';
import BaseHelper from '../../../../helpers/BaseHelper';
import DefineConst from '../../DefineConst';

// eslint-disable-next-line max-lines-per-function
function CommissionPlanPaymentBoxTime(props) {
    const moment = BaseHelper.getMoment();
    const id = BaseHelper.generateRandomString(10);
    const { t, paymentTimeProp, cb } = props;
    const [paymentTime, setPaymentTime] = useState({
        type: DefineConst.PAYMENT_TERM_TYPE.MONTH,
        payment_type: DefineConst.PAYMENT_KPI_TERM_TYPE.TOTAL,
        start_date: null,
        end_date: null,
    });

    const [startFocused, setStartFocused] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endFocused, setEndFocused] = useState(false);
    const [endDate, setEndDate] = useState(null);

    const renderExplain = () => {
        let explain = <></>;
        return explain;
    };

    const eventSelectType = (type) => {
        let data = { ...paymentTime, type };
        setPaymentTime(data);
        cb(data);
    };

    const eventSelectPaymentType = (type) => {
        let data = { ...paymentTime, payment_type: type };
        setPaymentTime(data);
        cb(data);
    };

    const eventChooseDate = (date, start) => {
        let data = paymentTime;
        if (start) {
            data = { ...paymentTime, start_date: date };
            setStartDate(date);
            setPaymentTime(data);
        } else {
            setEndDate(date);
            data = { ...paymentTime, end_date: date };
            setPaymentTime(data);
        }
        cb(data);
    };

    useEffect(() => {
        setPaymentTime({ ...paymentTime, ...paymentTimeProp });
        if (paymentTimeProp.start_date) {
            setStartDate(moment(paymentTimeProp.start_date));
        }
        if (paymentTimeProp.end_date) {
            setEndDate(moment(paymentTimeProp.end_date));
        }
    }, [paymentTimeProp]);

    return <>
        <div className="ladiui pt-20 d-grid-3">
            <div className="ladiui">
                <label className="ladiui title-text-form-lever2">Thời gian trả hoa hồng</label>
                <div className="group-input-custom custom-form-dynamic" style={{ width: '380px' }}>
                    <div className="ladiui radio">
                        <input type="radio" className="ladiui form-check-input" id={`time-pay-commission-1-${id}`}
                            name={`time-pay-commission-${id}`} value={DefineConst.PAYMENT_TERM_TYPE.MONTH}
                            checked={paymentTime.type === DefineConst.PAYMENT_TERM_TYPE.MONTH}
                            onChange={() => eventSelectType(DefineConst.PAYMENT_TERM_TYPE.MONTH)} />
                        <label htmlFor={`time-pay-commission-1-${id}`} className="mr-24" style={{ cursor: 'pointer' }}>Tháng</label>
                        <input type="radio" className="ladiui form-check-input" id={`time-pay-commission-2-${id}`}
                            name={`time-pay-commission-${id}`} value={DefineConst.PAYMENT_TERM_TYPE.QUARTER}
                            checked={paymentTime.type === DefineConst.PAYMENT_TERM_TYPE.QUARTER}
                            onChange={() => eventSelectType(DefineConst.PAYMENT_TERM_TYPE.QUARTER)} />
                        <label htmlFor={`time-pay-commission-2-${id}`} className="mr-24" style={{ cursor: 'pointer' }}>Quý</label>
                        <input type="radio" className="ladiui form-check-input" id={`time-pay-commission-3-${id}`}
                            name={`time-pay-commission-${id}`} value={DefineConst.PAYMENT_TERM_TYPE.YEAR}
                            checked={paymentTime.type === DefineConst.PAYMENT_TERM_TYPE.YEAR}
                            onChange={() => eventSelectType(DefineConst.PAYMENT_TERM_TYPE.YEAR)} />
                        <label htmlFor={`time-pay-commission-3-${id}`} className="mr-24" style={{ cursor: 'pointer' }}>Năm</label>
                        <input type="radio" className="ladiui form-check-input" id={`time-pay-commission-4-${id}`}
                            name={`time-pay-commission-${id}`} value={DefineConst.PAYMENT_TERM_TYPE.CUSTOM}
                            checked={paymentTime.type === DefineConst.PAYMENT_TERM_TYPE.CUSTOM}
                            onChange={() => eventSelectType(DefineConst.PAYMENT_TERM_TYPE.CUSTOM)} />
                        <label htmlFor={`time-pay-commission-4-${id}`} style={{ cursor: 'pointer' }}>Tuỳ chỉnh</label>
                    </div>
                </div>

            </div>
            {paymentTime.type === DefineConst.PAYMENT_TERM_TYPE.CUSTOM
                ? <><div className="ladiui">
                    <label className="ladiui title-text-form-lever2">Ngày bắt đầu</label>
                    <div className="group-input-custom custom-form-dynamic time-expired-plan-payment">
                        <SingleDatePicker
                            date={startDate} // momentPropTypes.momentObj or null
                            onDateChange={(date) => eventChooseDate(date, true)} // PropTypes.func.isRequired
                            focused={startFocused} // PropTypes.bool
                            onFocusChange={({ focused }) => setStartFocused(focused)} // PropTypes.func.isRequired
                            numberOfMonths={1}
                            showClearDate={true}
                            noBorder={true}
                            placeholder="Ngày bắt đầu"
                            readOnly={true}
                            disabled={paymentTime.type !== DefineConst.PAYMENT_TERM_TYPE.CUSTOM}
                            isOutsideRange={(day) => false}
                            showDefaultInputIcon={true}
                            inputIconPosition="after"
                            small={true}
                            customInputIcon={
                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                            }
                        />
                    </div>
                </div>
                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever2">Ngày kết thúc</label>
                        <div className="group-input-custom custom-form-dynamic time-expired-plan-payment">
                            <SingleDatePicker
                                date={endDate} // momentPropTypes.momentObj or null
                                onDateChange={(date) => eventChooseDate(date, false)} // PropTypes.func.isRequired
                                focused={endFocused} // PropTypes.bool
                                onFocusChange={({ focused }) => setEndFocused(focused)} // PropTypes.func.isRequired
                                numberOfMonths={1}
                                showClearDate={true}
                                noBorder={true}
                                placeholder="Ngày kết thúc"
                                readOnly={true}
                                disabled={paymentTime.type !== DefineConst.PAYMENT_TERM_TYPE.CUSTOM}
                                isOutsideRange={(day) => false}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                small={true}
                                customInputIcon={
                                    <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                                }
                            />
                        </div>
                    </div>
                </>
                : <></>}
        </div>
        <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>
            {renderExplain()}
        </div>
        <div className="ladiui pt-20 d-grid-3">
            <div className="ladiui">
                <label className="ladiui title-text-form-lever2">Hình thức trả thưởng</label>
                <div className="group-input-custom custom-form-dynamic" style={{ width: '380px' }}>
                    <div className="ladiui radio">
                        <input type="radio" className="ladiui form-check-input"
                            name={`time-pay-commission-type-${id}`}
                            id={`time-pay-commission-type-1-${id}`}
                            value={DefineConst.PAYMENT_KPI_TERM_TYPE.TOTAL}
                            checked={paymentTime.payment_type === DefineConst.PAYMENT_KPI_TERM_TYPE.TOTAL}
                            onChange={() => eventSelectPaymentType(DefineConst.PAYMENT_KPI_TERM_TYPE.TOTAL)} />
                        <label htmlFor={`time-pay-commission-type-1-${id}`} className="mr-24" style={{ cursor: 'pointer' }}>Trả theo tổng</label>
                        <input type="radio" className="ladiui form-check-input"
                            name={`time-pay-commission-type-${id}`}
                            id={`time-pay-commission-type-2-${id}`}
                            value={DefineConst.PAYMENT_KPI_TERM_TYPE.UNIT}
                            checked={paymentTime.payment_type === DefineConst.PAYMENT_KPI_TERM_TYPE.UNIT}
                            onChange={() => eventSelectPaymentType(DefineConst.PAYMENT_KPI_TERM_TYPE.UNIT)} />
                        <label htmlFor={`time-pay-commission-type-2-${id}`} className="mr-24" style={{ cursor: 'pointer' }}>Trả theo từng đơn</label>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBoxTime));
