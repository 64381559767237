import React, { useEffect, useRef } from "react";
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import baseHelper from '../../helpers/BaseHelper';
baseHelper.removeCookie('SET_CUSTOM_SCRIPT_FRONT');

function PublisherFooter(props) {
    const { t } = props;
    const affiliateDashboardSettingPublic = useSelector((state) => (state.store.affiliateDashboardSettingPublic));
    let _customScript = affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.custom_script ? affiliateDashboardSettingPublic.custom_script: '';
    const isSetCustomScript = baseHelper.getCookie('SET_CUSTOM_SCRIPT_FRONT');
    if (_customScript != '' && !isSetCustomScript) {
        var script = document.createRange().createContextualFragment(_customScript);
        document.body.appendChild(script);
        baseHelper.setCookie('SET_CUSTOM_SCRIPT_FRONT', true, 86400);
    }

    return <>
        <div className="publisher-footer">
            <div className="publisher-footer-item"><a
                href={affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.link_affiliate_policy
                    ? affiliateDashboardSettingPublic.link_affiliate_policy : 'https://ladipage.vn/chinhsachaffiliate'}>Chính sách Affiliate</a></div>
            <div className="publisher-footer-item"><a
                href={affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.link_privacy
                    ? affiliateDashboardSettingPublic.link_privacy : '#'}>Chính sách bảo mật</a></div>
            <div className="publisher-footer-item"><a
                href={affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.link_regulation
                    ? affiliateDashboardSettingPublic.link_regulation : '#'}>Điều khoản sử dụng</a></div>
            <div className="publisher-footer-item"> <a
                href={affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.link_help
                    ? affiliateDashboardSettingPublic.link_help : '#'}>Liên hệ</a></div>
        </div>

        {affiliateDashboardSettingPublic && !affiliateDashboardSettingPublic.disable_power_by
            ? <div className="publisher-footer" style={{ bottom: '-120px' }}>
                <div className="publisher-footer-item">Powered by
                    <img src="https://w.ladicdn.com/ladiui/ladishare/logo-ladishare.svg" alt="" />
                </div>
            </div>
            : <></>}

            {
                affiliateDashboardSettingPublic && affiliateDashboardSettingPublic.custom_script ? 
                <div />
                  : <></>
            }
    </>;
}
export default (withTranslation()(PublisherFooter));
