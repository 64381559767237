import React, { useEffect, useState } from 'react';

function ListStaffDropdownMultiSelect(props) {
    const { t, unCheckedListProp, cb, cbList } = props;

    const [selectedItem, setSelectedItem] = useState([]);
    const [unSelectedItem, setUnSelectedItem] = useState([]);

    const eventCheckItem = (item) => {
        const checkedItems = [...selectedItem, item];
        setSelectedItem(checkedItems);
        const data = unSelectedItem.filter((i) => i._id !== item._id);
        setUnSelectedItem(data);
        cb(checkedItems);
    };

    const eventUnCheckItem = (item) => {
        setUnSelectedItem([...unSelectedItem, item]);
        const data = selectedItem.filter((i) => i._id !== item._id);
        setSelectedItem(data);
        cb(data);
    };

    const eventKeyDownInputSearch = (e) => {
        if (e.keyCode === 13) {
            const keyword = document.getElementById('input-search-shop-id').value;
            let idSelect = [];
            for (let i = 0; i < selectedItem.length; i++) {
                idSelect.push(selectedItem[i]._id);
            }
            cbList(keyword, idSelect);
        }
    };

    const eventClickSearch = () => {
        const keyword = document.getElementById('input-search-shop-id').value;
        cbList(keyword);
    };

    const getNameShopId = () => {
        let names = [];
        for (let i = 0; i < selectedItem.length; i++) {
            names.push(selectedItem[i].first_name);
        }
        return names.join(', ');
    };

    const eventRemoveAllSelectItem = () => {
        setUnSelectedItem([...unSelectedItem, ...selectedItem]);
        setSelectedItem([]);
        cb([]);
    };

    useEffect(() => {
        if (unCheckedListProp) {
            let selectedItemIds = [];
            let unCheckedList = [];
            for (let i = 0; i < selectedItem.length; i++) {
                selectedItemIds.push(selectedItem[i]._id);
            }
            for (let i = 0; i < unCheckedListProp.length; i++) {
                if (!selectedItemIds.includes(unCheckedListProp[i]._id)) unCheckedList.push(unCheckedListProp[i]);
            }
            setUnSelectedItem(unCheckedList);
        }
    }, [unCheckedListProp]);

    return <>
        <div className="ladiui btn-group ladi-dropdown-multi-select">
            <div className="ladiui select-search dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle custom-main-tool ml-0" data-toggle="dropdown">
                    <i></i>
                    {selectedItem.length > 0
                        ? <>
                            <span className="ladiui dropdown-text">{getNameShopId()}</span>
                            <span className="ladiui-btn-tag">{selectedItem.length}</span>
                            <span className="ladiui removeName" style={{ marginRight: '12px' }}
                                onClick={() => eventRemoveAllSelectItem()}> <i
                                    className="ladiui icon icon-ldp-close"></i></span>
                        </>
                        : <><span className="ladiui dropdown-text">Chọn tài khoản</span></>}
                </button>

                <ul className="ladiui dropdown-menu custom-people" style={{ left: 0 }}>
                    <li className="ladiui search-group custom-people">
                        <input
                            className="ladiui search-field dropdown-toggle form-control custom-people"
                            placeholder="Tìm kiếm" aria-expanded="false"
                            id="input-search-shop-id"
                            onKeyDown={(e) => eventKeyDownInputSearch(e)} />
                        <i className="ladiui icon icon-search" onClick={eventClickSearch}></i>
                    </li>
                    <li className="ladiui checked">
                        <ul className="ladiui ul-selected">
                            {selectedItem.map((item) => <li key={item._id} className="ladiui"><label
                                className="ladiui search-multi"><input type="checkbox" onChange={() => eventUnCheckItem(item)} checked
                                    className="ladiui size-checkbox" />{item.first_name}<br /><span className="shop-id-comment">{item.email}</span></label>
                            </li>)}
                        </ul>
                    </li>
                    <li className="ladiui unchecked">
                        <ul className="ladiui ul-unselected">
                            {unSelectedItem.map((item) => <li key={item._id} className="ladiui"><label
                                className="ladiui search-multi"><input type="checkbox" onChange={() => eventCheckItem(item)}
                                    className="ladiui size-checkbox" />{item.first_name}<br /><span className="shop-id-comment">{item.email}</span></label>
                            </li>)}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </>;
}

export default ListStaffDropdownMultiSelect;
