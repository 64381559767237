import { isObject } from 'lodash';
import React, { useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseHelper from '../../../helpers/BaseHelper';
import DefineConst from '../DefineConst';
import ConditionChangeGroupBoxForCustomer from './ConditionChangeGroupBoxForCustomer';
import ConditionChangeGroupBoxForOrder from './ConditionChangeGroupBoxForOrder';
import ConditionChangeGroupBoxForTotalOrder from './ConditionChangeGroupBoxForTotalOrder';
import LadiDropdown from '../../../components/LadiDropdown';

// eslint-disable-next-line max-lines-per-function
function ConditionChangeGroupBox(props) {
    const moment = BaseHelper.getMoment();
    const id = BaseHelper.generateRandomString(10);
    const { t, conditionChangeGroupProp, removeConditionChangeGroupProp, cb } = props;
    const [conditionChangeGroup, setConditionChangeGroup] = useState({ id, time_type: DefineConst.TIME_TYPE.MONTH });
    const [startFocused, setStartFocused] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endFocused, setEndFocused] = useState(false);
    const [endDate, setEndDate] = useState(null);

    const listGroup = useSelector((state) => ({ ...{ items: [], total: 0, pluckItems: {} }, ...state.affiliateGroups.groups }));

    const getListGroup = () => {
        const data = [
            { name: 'Không chuyển nhóm', value: '-1' },
        ];
        for (let i = 0; i < listGroup.items.length; i++) {
            const group = listGroup.items[i];
            data.push({ name: group.name, value: group._id });
        }
        return data;
    };

    const eventSelectType = (type) => {
        const data = { ...conditionChangeGroup, time_type: type };
        setConditionChangeGroup(data);
        cb(data);
    };

    const eventChooseDate = (date, start) => {
        let data;
        if (start) {
            data = { ...conditionChangeGroup, start_date: date };
            setStartDate(date);
        } else {
            setEndDate(date);
            data = { ...conditionChangeGroup, end_date: date };
        }
        setConditionChangeGroup(data);
        cb(data);
    };

    const eventRemoveGroupBox = (data) => {
        removeConditionChangeGroupProp(data);
    };

    const changeGroupBoxForOrder = (data) => {
        const conditions = { ...conditionChangeGroup, condition_per_order: data };
        setConditionChangeGroup(conditions);
        cb(conditions);
    };

    const changeGroupBoxForTotalOrder = (data) => {
        const conditions = { ...conditionChangeGroup, condition_per_total_order: data };
        setConditionChangeGroup(conditions);
        cb(conditions);
    };

    const changeGroupBoxForCustomer = (data) => {
        const conditions = { ...conditionChangeGroup, condition_per_customer: data };
        setConditionChangeGroup(conditions);
        cb(conditions);
    };

    const eventSelectGroupPass = (value) => {
        const conditions = { ...conditionChangeGroup, pass_group_affiliate: value };
        setConditionChangeGroup(conditions);
        cb(conditions);
    };
    const eventSelectGroupFail = (value) => {
        const conditions = { ...conditionChangeGroup, fails_group_affiliate: value };
        setConditionChangeGroup(conditions);
        cb(conditions);
    };

    useEffect(() => {
        if (conditionChangeGroupProp) {
            setConditionChangeGroup({ ...conditionChangeGroup, ...conditionChangeGroupProp });
            if (conditionChangeGroupProp.start_date) {
                setStartDate(moment(conditionChangeGroupProp.start_date));
            }
            if (conditionChangeGroupProp.end_date) {
                setEndDate(moment(conditionChangeGroupProp.end_date));
            }
        }
    }, [conditionChangeGroupProp]);

    return <>
        <div className="ladiui item bg-form bg-commissionn-plan mt-22">
            <div className="ladiui item bg-form-sub">
                <div className="d-grid-2" style={{ minHeight: '62px' }}>
                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever2">Thời gian áp dụng</label>
                        <div className="group-input-custom custom-form-dynamic">
                            <div className="ladiui radio">
                                <input type="radio" className="ladiui form-check-input" id={`time-pay-commission-1-${id}`}
                                    name={`time-pay-commission-${id}`} value={DefineConst.TIME_TYPE.MONTH}
                                    checked={conditionChangeGroup.time_type === DefineConst.TIME_TYPE.MONTH}
                                    onChange={() => eventSelectType(DefineConst.TIME_TYPE.MONTH)} />
                                <label htmlFor={`time-pay-commission-1-${id}`} className="mr-24" style={{ cursor: 'pointer' }}>Tháng</label>
                                <input type="radio" className="ladiui form-check-input" id={`time-pay-commission-2-${id}`}
                                    name={`time-pay-commission-${id}`} value={DefineConst.TIME_TYPE.QUARTER}
                                    checked={conditionChangeGroup.time_type === DefineConst.TIME_TYPE.QUARTER}
                                    onChange={() => eventSelectType(DefineConst.TIME_TYPE.QUARTER)} />
                                <label htmlFor={`time-pay-commission-2-${id}`} className="mr-24" style={{ cursor: 'pointer' }}>Quý</label>
                                <input type="radio" className="ladiui form-check-input" id={`time-pay-commission-3-${id}`}
                                    name={`time-pay-commission-${id}`} value={DefineConst.TIME_TYPE.YEAR}
                                    checked={conditionChangeGroup.time_type === DefineConst.TIME_TYPE.YEAR}
                                    onChange={() => eventSelectType(DefineConst.TIME_TYPE.YEAR)} />
                                <label htmlFor={`time-pay-commission-3-${id}`} className="mr-24" style={{ cursor: 'pointer' }}>Năm</label>
                                <input type="radio" className="ladiui form-check-input" id={`time-pay-commission-4-${id}`}
                                    name={`time-pay-commission-${id}`} value={DefineConst.TIME_TYPE.CUSTOM}
                                    checked={conditionChangeGroup.time_type === DefineConst.TIME_TYPE.CUSTOM}
                                    onChange={() => eventSelectType(DefineConst.TIME_TYPE.CUSTOM)} />
                                <label htmlFor={`time-pay-commission-4-${id}`} style={{ cursor: 'pointer' }}>Tuỳ chỉnh</label>
                            </div>
                        </div>
                    </div>
                    {conditionChangeGroup.time_type === DefineConst.TIME_TYPE.CUSTOM
                        ? <div className="d-grid-2">
                            <div className="ladiui">
                                <label className="ladiui title-text-form-lever2">Ngày bắt đầu</label>
                                <div className="group-input-custom custom-form-dynamic time-expired-plan-payment">
                                    <SingleDatePicker
                                        date={startDate} // momentPropTypes.momentObj or null
                                        onDateChange={(date) => eventChooseDate(date, true)} // PropTypes.func.isRequired
                                        focused={startFocused} // PropTypes.bool
                                        onFocusChange={({ focused }) => setStartFocused(focused)} // PropTypes.func.isRequired
                                        numberOfMonths={1}
                                        showClearDate={true}
                                        noBorder={true}
                                        placeholder="Ngày bắt đầu"
                                        readOnly={true}
                                        disabled={conditionChangeGroup.time_type !== DefineConst.TIME_TYPE.CUSTOM}
                                        isOutsideRange={(day) => false}
                                        showDefaultInputIcon={true}
                                        inputIconPosition="after"
                                        small={true}
                                        customInputIcon={
                                            <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                                        }
                                    />
                                </div>
                            </div>
                            <div className="ladiui">
                                <label className="ladiui title-text-form-lever2">Ngày kết thúc</label>
                                <div className="group-input-custom custom-form-dynamic time-expired-plan-payment">
                                    <SingleDatePicker
                                        date={endDate} // momentPropTypes.momentObj or null
                                        onDateChange={(date) => eventChooseDate(date, false)} // PropTypes.func.isRequired
                                        focused={endFocused} // PropTypes.bool
                                        onFocusChange={({ focused }) => setEndFocused(focused)} // PropTypes.func.isRequired
                                        numberOfMonths={1}
                                        showClearDate={true}
                                        noBorder={true}
                                        placeholder="Ngày kết thúc"
                                        readOnly={true}
                                        disabled={conditionChangeGroup.time_type !== DefineConst.TIME_TYPE.CUSTOM}
                                        isOutsideRange={(day) => false}
                                        showDefaultInputIcon={true}
                                        inputIconPosition="after"
                                        small={true}
                                        customInputIcon={
                                            <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        : <></>}
                </div>

                <div className="ladiui clearfix pt-20 d-grid-2">
                    <ConditionChangeGroupBoxForOrder conditionChangeGroupProp={conditionChangeGroup} cb={changeGroupBoxForOrder} />
                    <ConditionChangeGroupBoxForTotalOrder conditionChangeGroupProp={conditionChangeGroup} cb={changeGroupBoxForTotalOrder} />
                    <ConditionChangeGroupBoxForCustomer conditionChangeGroupProp={conditionChangeGroup} cb={changeGroupBoxForCustomer} />
                </div>
                <hr className="mt-20" style={{ border: '1px solid #fff' }} />
                <div className="ladiui clearfix pt-20 d-grid-2">
                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever2" >Nếu đạt điều kiện thì chuyển sang nhóm</label>
                        <div className="ladiui btn-group custom-form-dynamic">
                            <div className="ladiui btn-group">
                                <LadiDropdown
                                    listProp={getListGroup()}
                                    valueProp={conditionChangeGroup.pass_group_affiliate}
                                    hideLabel={true}
                                    validProp={true}
                                    classDropdown="dropdown-custom"
                                    classSize="btn-sm"
                                    defaultTextProp="Chọn nhóm"
                                    cbProp={eventSelectGroupPass}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ladiui">
                        <label className="ladiui title-text-form-lever2" >Nếu không đạt điều kiện thì chuyển sang nhóm</label>
                        <div className="ladiui btn-group custom-form-dynamic">
                            <div className="ladiui btn-group">
                                <LadiDropdown
                                    listProp={getListGroup()}
                                    valueProp={conditionChangeGroup.fails_group_affiliate}
                                    hideLabel={true}
                                    validProp={true}
                                    classDropdown="dropdown-custom"
                                    classSize="btn-sm"
                                    defaultTextProp="Chọn nhóm"
                                    cbProp={eventSelectGroupFail}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ladiui flex-row custom-bg-form clearfix pt-20">
                    <div>

                    </div>
                    <div className="ladiui flex-row custom-create-dynamic">
                        <button type="button" className="ladiui font-size-12 btn btn-secondary" onClick={() => eventRemoveGroupBox(conditionChangeGroup)}>
                            <i className="ladiui icon icon-ldp-delete-black-sz"></i> Xoá điều kiện
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(ConditionChangeGroupBox));
