import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumericFormat from 'react-number-format';
import appConfig from '../../config/app';
import styles from '../../assets/css/custom.module.css';
import actionStore from '../../redux/futures/store/actions';
import config from '../../config/config';

// eslint-disable-next-line max-lines-per-function
function GeneralSetting(props) {
    const { t } = props;

    const dispatch = useDispatch();
    const [generalSetting, setGeneralSetting] = useState({});
    const [timezoneName, setTimezoneName] = useState();
    const [currencyName, setCurrencyName] = useState();

    const logoUpload = useSelector((state) => (state.store.logoUpload));

    const setting = useSelector((state) => (state.store.store));

    const eventUpdate = () => {
        dispatch(actionStore.update(generalSetting));
    };

    const eventCancel = () => {
        dispatch(actionStore.show());
    };

    const eventUploadLogo = (event) => {
        const files = event.target.files;
        const uploadFile = files[0];
        const form = new FormData();
        form.append('json_data', JSON.stringify({
            ladi_app: 'LADISHARE',
            file_type: 'IMAGE',
        }));
        form.append('files', uploadFile, uploadFile.name);
        dispatch(actionStore.uploadLogo(form));
    };

    useEffect(() => {
        dispatch(actionStore.show());
        dispatch(actionStore.getApiKeyInfo());
    }, []);

    useEffect(() => {
        if (setting) setGeneralSetting(setting);
    }, [setting]);

    useEffect(() => {
        Object.keys(appConfig.TIMEZONE).forEach((value) => {
            if (generalSetting.time_zone === appConfig.TIMEZONE[value].code) setTimezoneName(appConfig.TIMEZONE[value].name);
        });
    }, [generalSetting.time_zone]);

    useEffect(() => {
        appConfig.LADISHARE.CURRENCY_VALUES.forEach((value) => {
            if (generalSetting.currency === value.value) setCurrencyName(value.name);
        });
    }, [generalSetting.currency]);

    useEffect(() => {
        if (logoUpload) setGeneralSetting({ ...generalSetting, logo_store: `${config.DOMAIN_CDN}/${logoUpload[0].path}` });
    }, [logoUpload]);

    return <>
        <div className="ladiui content-main-title flex-row">
            <div className="ladiui">
                <div className="ladiui main-title">
                    {t('STORES.GENERAL_SETTING')}
                </div>
            </div>
        </div>
        <div className="ladiui general-setting-content">
            <div className="ladiui form-group flex-row" style={{ display: 'none' }}>
                <label className="ladiui title-text-form-lever2">{t('STORES.STORE_URL')}</label>
                <input className="ladiui form-control" placeholder="Nhập url store" id="url_store" name="url_store"
                    value={generalSetting.url_store || ''}
                    onChange={(e) => setGeneralSetting({ ...generalSetting, ...{ url_store: e.target.value } })} />
            </div>
            <div className="ladiui form-group flex-row">
                <label className="ladiui title-text-form-lever2">{t('STORES.NAME')}</label>
                <input className="ladiui form-control" placeholder="Nhập tên Store" id="name" name="name"
                    value={generalSetting.name || ''}
                    onChange={(e) => setGeneralSetting({ ...generalSetting, ...{ name: e.target.value } })} />
            </div>
            <div className="ladiui form-group flex-row">
                <label className="ladiui title-text-form-lever2">{t('STORES.TIMEZONE')}</label>
                <div className="ladiui dropdown" style={{ width: '100%' }}>
                    <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown custom-form-dynamic" style={{ width: '100%' }}
                        id="dropdownMenuButton3" data-toggle="dropdown">
                        <i></i>
                        <span className="ladiui dropdown-text">
                            {timezoneName}
                        </span>
                    </button>
                    <ul className="ladiui dropdown-menu custom-form-dynamic" style={{ width: 'calc(100% - 110px)' }}>
                        {Object.keys(appConfig.TIMEZONE).map((value) => (
                            <li key={appConfig.TIMEZONE[value].code}
                                onClick={() => setGeneralSetting({ ...generalSetting, ...{ time_zone: appConfig.TIMEZONE[value].code } })}>
                                <a className="ladiui dropdown-item" href="#/" >{appConfig.TIMEZONE[value].name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="ladiui form-group flex-row">
                <label className="ladiui title-text-form-lever2">{t('STORES.CURRENCY')}</label>
                <div className="ladiui dropdown" style={{ width: '100%' }}>
                    <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown custom-form-dynamic" style={{ width: '100%' }}
                        id="dropdownMenuButton3" data-toggle="dropdown">
                        <i></i>
                        <span className="ladiui dropdown-text">
                            {currencyName}
                        </span>
                    </button>
                    <ul className="ladiui dropdown-menu custom-form-dynamic" style={{ width: 'calc(100% - 110px)' }}>
                        {appConfig.LADISHARE.CURRENCY_VALUES.map((values) => (
                            <li key={values.value}
                                onClick={() => setGeneralSetting({ ...generalSetting, ...{ currency: values.value } })}>
                                <a className="ladiui dropdown-item" href="#/" >{values.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="ladiui form-group flex-row">
                <label className="ladiui title-text-form-lever2">Tên công ty</label>
                <input type="text" className="ladiui form-control" id="name_company" name="name_company"
                    value={typeof generalSetting.name_company !== 'undefined' ? generalSetting.name_company : ''}
                    onChange={(e) => setGeneralSetting({ ...generalSetting, name_company: e.target.value })}
                />
            </div>

            <div className="ladiui form-group flex-row">
                <label className="ladiui title-text-form-lever2">Logo công ty</label>
                <div className="form-group-file-image _logo">
                    <div className="img-preview">{generalSetting.logo_store ? <img src={generalSetting.logo_store} className={styles['img-fit']} /> : <></>}</div>
                    <label htmlFor="img-logo" className={styles['icon-upload-1']}>
                        <img src="https://w.ladicdn.com/ladiui/ladishare/find-replace0808.svg" />
                    </label>
                    <label className={styles['icon-upload-2']} onClick={() => setGeneralSetting({ ...generalSetting, logo_store: null })}>
                        <i className="ladiui icon-ldp icon-e-remove"></i>
                    </label>
                    <input type="file" className="" id="img-logo"
                        accept="image/*"
                        onChange={(e) => eventUploadLogo(e)} />
                </div>
            </div>

            <div className="ladiui form-group flex-row">
                <label className="ladiui title-text-form-lever2">Mã số thuế</label>
                <input type="number" className="ladiui form-control" id="tax_code" name="tax_code"
                    value={typeof generalSetting.tax_code !== 'undefined' ? generalSetting.tax_code : ''}
                    onChange={(e) => setGeneralSetting({ ...generalSetting, tax_code: e.target.value })}
                />
            </div>

            <div className="ladiui form-group flex-row">
                <label className="ladiui title-text-form-lever2">Địa chỉ</label>
                <input type="text" className="ladiui form-control" id="address" name="address"
                    value={typeof generalSetting.address !== 'undefined' ? generalSetting.address : ''}
                    onChange={(e) => setGeneralSetting({ ...generalSetting, address: e.target.value })}
                />
            </div>

            <div className="ladiui form-group flex-row">
                <label className="ladiui title-text-form-lever2">Thuế cá nhân %</label>
                <input type="number" className="ladiui form-control" id="vat" name="vat"
                    value={typeof generalSetting.vat !== 'undefined' ? generalSetting.vat : ''}
                    onChange={(e) => setGeneralSetting({ ...generalSetting, vat: e.target.value })}
                    style={{ width: 'auto' }}
                />
                <label className="ladiui switch" style={{ minWidth: '0', margin: '0', marginLeft: '24px' }}>
                    <input type="checkbox" id="invoice_include_vat_person" name="invoice_include_vat_person"
                        checked={generalSetting.invoice_include_vat_person ? generalSetting.invoice_include_vat_person : false}
                        onChange={() => setGeneralSetting({ ...generalSetting, invoice_include_vat_person: !generalSetting.invoice_include_vat_person })}
                    />
                    <span className="ladiui slider round"></span>
                </label>
                <label className="ladiui title-text-form-lever2" style={{ marginLeft: '12px' }}>Hoá đơn đã bao gồm thuế</label>

            </div>
            <div className="ladiui form-group flex-row">
                <label className="ladiui title-text-form-lever2">Thuế công ty %</label>
                <input type="number" className="ladiui form-control" id="vat_company" name="vat_company"
                    value={typeof generalSetting.vat_company !== 'undefined' ? generalSetting.vat_company : ''}
                    onChange={(e) => setGeneralSetting({ ...generalSetting, vat_company: e.target.value })}
                    style={{ width: 'auto' }}
                />
                <label className="ladiui switch" style={{ minWidth: '0', margin: '0', marginLeft: '24px' }}>
                    <input type="checkbox" id="invoice_include_vat_company" name="invoice_include_vat_company"
                        checked={generalSetting.invoice_include_vat_company ? generalSetting.invoice_include_vat_company : false}
                        onChange={() => setGeneralSetting({ ...generalSetting, invoice_include_vat_company: !generalSetting.invoice_include_vat_company })}
                    />
                    <span className="ladiui slider round"></span>
                </label>
                <label className="ladiui title-text-form-lever2" style={{ marginLeft: '12px' }}>Hoá đơn đã bao gồm thuế</label>
            </div>
            <div className="ladiui form-group flex-row" style={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
                <div className="label-setting">
                    <label className="ladiui title-text-form-lever2">Thiết lập điều kiện tính thuế cho affiliate</label>
                    <div style={{ marginTop: '12px' }}>Thuế cho affiliate sẽ được tính khi hoá đơn đạt giá trị tối thiểu</div>
                    {generalSetting.enable_vat_conditions
                        ? <>
                            <div className="ladiui form-group flex-row gap-24" style={{ marginTop: '24px' }}>
                                {/* <div></div> */}
                                <div>
                                    <label className="ladiui" style={{ marginBottom: '12px', display: 'block' }}>Giá trị hoá đơn lớn hơn hoặc bằng</label>
                                    <NumericFormat id="vat" name="vat"
                                        value={typeof generalSetting.vat_condition_min !== 'undefined' ? generalSetting.vat_condition_min : ''}
                                        allowLeadingZeros
                                        allowNegative={false}
                                        decimalScale={2}
                                        thousandSeparator=","
                                        className="ladiui form-control"
                                        placeholder="Nhập giá trị"
                                        onValueChange={(values) => setGeneralSetting({ ...generalSetting, vat_condition_min: values.value })}
                                    />
                                </div>
                                <div>
                                    <label className="ladiui" style={{ marginBottom: '12px', display: 'block' }}>Gộp các hoá đơn của những affiliate có chung số tài khoản ngân hàng</label>
                                    <div style={{ height: '34px' }}>
                                        <label className="ladiui switch" style={{ minWidth: '0', margin: '0' }}>
                                            <input type="checkbox" id="vat_condition_bank_account" name="vat_condition_bank_account"
                                                checked={generalSetting.vat_condition_bank_account ? generalSetting.vat_condition_bank_account : false}
                                                onChange={() => setGeneralSetting({ ...generalSetting, vat_condition_bank_account: !generalSetting.vat_condition_bank_account })}
                                            />
                                            <span className="ladiui slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <></>}
                </div>
                <div className="ladiui item-form switch">
                    <label className="ladiui switch" style={{ minWidth: '0', margin: '0' }}>
                        <input type="checkbox" id="enable_vat_conditions" name="enable_vat_conditions"
                            checked={generalSetting.enable_vat_conditions ? generalSetting.enable_vat_conditions : false}
                            onChange={() => setGeneralSetting({ ...generalSetting, enable_vat_conditions: !generalSetting.enable_vat_conditions })}
                        />
                        <span className="ladiui slider round"></span>
                    </label>
                </div>
            </div>
            <div className="ladiui form-group">
                <button className="ladiui btn btn-primary right" onClick={eventUpdate}>{t('STORES.UPDATE')}</button>
                <button className="ladiui btn right btn-secondary" style={{ marginRight: '40px' }} onClick={eventCancel}>{t('STORES.CANCELED')}</button>
            </div>
            <div className="clearfix"></div>
        </div>
    </>;
}

export default (withTranslation()(GeneralSetting));
