import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }
        case types.SIGNUP: {
            return {
                ...state,
                action: types.SIGNUP,
                status: action.status,
                message: action.message,
            };
        }
        case types.RENEW_TOKEN: {
            return {
                ...state,
                action: types.RENEW_TOKEN,
                status: action.status,
                message: action.message,
            };
        }
        case types.SIGNIN: {
            return {
                ...state,
                action: types.SIGNIN,
                status: action.status,
                message: action.message,
            };
        }
        case types.GET_USER_INFO: {
            return {
                ...state,
                action: types.GET_USER_INFO,
                language: action.status && action.payload.user ? action.payload.user.language : 'vi',
            };
        }
        default:
            return state;
    }
};
