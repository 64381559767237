
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import appConfig from '../../../config/app';

function AffiliateDropdownSelect(props) {
    const {
        t, selectedRef, ignoreAffiliates, cb,
    } = props;

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState({
        keyword: '',
        page: 1,
        limit: appConfig.PAGINATION.DEFAULT_LIMIT,
        sort: { created_at: -1 },
    });
    const [selectedItem, setSelectedItem] = useState([]);
    const [isSearch, setIsSearch] = useState(false);

    const listAffiliateFilter = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.affiliates.filterAffiliates }));

    const callApi = () => {
        let ignoreIds = [];
        if (ignoreAffiliates && ignoreAffiliates.length > 0) {
            for (let i = 0; i < ignoreAffiliates.length; i++) {
                ignoreIds.push(ignoreAffiliates[i]._id);
            }
        }
        dispatch(actionAffiliate.listFilter({ ...conditions, ids: { $nin: ignoreIds } }, setIsSearch(true)));
    };

    const eventKeyDownInputSearch = (e) => {
        if (e.keyCode === 13) {
            callApi();
        } else {
            setConditions({ ...conditions, keyword: e.target.value }); setIsSearch(true);
        }
    };

    const eventSelectAffiliate = (item) => {
        setSelectedItem(item);
        cb(item);
    };

    useEffect(() => {
        if (selectedRef) {
            setSelectedItem(selectedRef);
        } else {
            setSelectedItem({});
        }
    }, [selectedRef]);

    return <>
        <div className="ladiui select-search dropdown">
            <button
                className={'ladiui btn btn-outline-light dropdown-toggle'}
                data-toggle="dropdown"
                style={{ height: '34px', width: '168px' }}
                onClick={() => setIsSearch(false)}
            >
                <i></i>
                <span className="ladiui dropdown-text" style={{ marginRight: 0 }}>
                    {selectedItem.code || 'Chọn affiliate'}
                </span>
            </button>
            <ul className="ladiui dropdown-menu dropdown-right" style={{ width: '100%', maxHeight: '400px', overflowY: 'auto' }}>
                <li className="ladiui search-group custom-people">
                    <input
                        className="ladiui search-field dropdown-toggle form-control custom-people"
                        onChange={(e) => { setConditions({ ...conditions, keyword: e.target.value }); setIsSearch(true); }}
                        onKeyDown={(e) => eventKeyDownInputSearch(e)}
                        value={isSearch ? conditions.keyword : ''}
                        placeholder="Tìm kiếm"
                    />
                    <i className="ladiui icon icon-search" onClick={() => callApi()}></i>
                </li>
                {isSearch ? listAffiliateFilter.total > 0
                    ? listAffiliateFilter.items.map((item) => <li key={item._id}
                        onClick={() => eventSelectAffiliate(item)} >
                        <a className="ladiui dropdown-item" href="#/">
                            {item.code} - {item.email}
                        </a>
                    </li>)
                    : <></>
                    : <></>
                }
            </ul>
        </div>
    </>;
}

export default AffiliateDropdownSelect;
