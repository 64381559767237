import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_CUSTOMER: {
            return {
                ...state,
                action: types.LIST_CUSTOMER,
                customers: action.status ? { items: action.payload.items, total: action.payload.total } : { items: [], total: 0 },
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_CUSTOMER: {
            return {
                ...state,
                action: types.CREATE_CUSTOMER,
                customerCreated: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_CUSTOMER: {
            return {
                ...state,
                action: types.SHOW_CUSTOMER,
                customer: action.status ? action.payload.customer : {},
                affiliate: action.status ? action.payload.affiliate : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_CUSTOMER: {
            return {
                ...state,
                action: types.UPDATE_CUSTOMER,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_CUSTOMER: {
            return {
                ...state,
                action: types.DELETE_CUSTOMER,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_MULTI_CUSTOMER: {
            return {
                ...state,
                action: types.DELETE_MULTI_CUSTOMER,
                status: action.status,
                message: action.message,
            };
        }

        case types.EXPORT_CUSTOMER: {
            return {
                ...state,
                action: types.EXPORT_CUSTOMER,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
