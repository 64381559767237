import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiPagination from '../../components/LadiPagination';
import BaseHelper from '../../helpers/BaseHelper';
import SearchCondition from './components/SearchCondition';
import actionAffiliate from '../../redux/futures/affiliate/actions';
import DefineConst from '../affiliates/DefineConst';

function AffiliateReferral(props) {
    const { t } = props;
    const defaultCondtions = {
        limit: 10,
        page: 1,
        sort: { created_at: -1 },
    };

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState(defaultCondtions);
    const listAffiliateRef = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.affiliates.refAffiliates }));

    const cbList = () => {
        dispatch(actionAffiliate.listRef(conditions));
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const hideEmail = (text) => text.replace(/(.{2})(.*)(?=@)/,
        (gp1, gp2, gp3) => {
            for (let i = 0; i < gp3.length; i++) {
                gp2 += '*';
            } return gp2;
        });

    const hidePhone = (phone) => {
        const last4Digits = phone.slice(-4);
        const maskedNumber = last4Digits.padStart(phone.length, '*');
        return maskedNumber;
    };

    useEffect(() => {
        cbList();
    }, [conditions]);

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui flex-row">
                    <div className="ladiui main-title">
                        Danh sách Affiliate cấp dưới
                    </div>
                </div>
            </div>

            <div className="ladiui flex-row">
                <SearchCondition
                    keywordProp={conditions.keyword}
                    eventSearchKeyboardProp={eventSearchKeyboard}
                    eventClickSearchButtonProp={eventClickSearchButton}
                />
            </div>
            <div className="ladiui mobile-table-scroll">
                <table className="ladiui table text-left" style={{ minWidth: '650px' }}>
                    <thead>
                        <tr className="ladiui table-vertical header">
                            <th scope="col">Tên</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mã</th>
                            <th scope="col">Số điện thoại</th>
                            <th scope="col">Loại affiliate</th>
                            <th scope="col">Nhóm</th>
                            <th scope="col">Ngày cập nhật</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listAffiliateRef.items.length > 0 ? listAffiliateRef.items.map((item, key) => (<tr key={item._id} className="ladiui table-vertical main">
                            <td>
                                {item.fullname || ''}
                            </td>
                            <td>
                                {item.email}
                            </td>
                            <td>{item.code}</td>
                            <td>
                                {item.phone}
                            </td>
                            <td>
                                {item.type === DefineConst.AFFILIATE_TYPE.COMPANY ? 'Công ty' : 'Cá nhân'}
                            </td>
                            <td>
                                {item.group ? item.group.name : ''}
                            </td>
                            <td>{BaseHelper.formatStrToDate(item.updated_at, 'DD/MM/YYYY')}</td>
                        </tr>))
                            : <tr><td colSpan={6}>{t('NO_DATA')}</td></tr>}

                    </tbody>
                </table>
            </div>
            {listAffiliateRef.items.length > 0
                ? <LadiPagination
                    conditionsProp={conditions}
                    listName='affiliate'
                    items={listAffiliateRef.items}
                    total={listAffiliateRef.total}
                    limitAction={selectLimit}
                    pageAction={selectPage}
                /> : ''}
        </div>
    </>;
}

export default (withTranslation()(AffiliateReferral));
