import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from '../../../../assets/css/custom.module.css';

function MailContent(props) {
    const { t, contents } = props;
    const user = useSelector((state) => state.store.userInfo);
    const resizeIframe = () => {
        const iframe = document.getElementById('iframe-content');
        iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight + 40}px`;
    };

    return <>
        <label>Nội dung</label>
        <div className="content-left-detail">
            <div dangerouslySetInnerHTML={{
                __html: contents.contents,
            }}></div>
            {/* <iframe id="iframe-content" className={styles.iframe}
                onLoad={resizeIframe}
                srcDoc={`${`<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>${contents.contents}</body></html>`}`} /> */}
        </div>
    </>;
}
export default (withTranslation()(MailContent));
