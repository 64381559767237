/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import BaseHelper from '../../../helpers/BaseHelper';
import { status } from '../../../config/app';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import actionStore from '../../../redux/futures/store/actions';

function ModalInvoiceAffiliate(props) {
    const moment = BaseHelper.getMoment();
    const { t, ids, cb } = props;
    const dispatch = useDispatch();

    const [conditions, setConditions] = useState(null);
    const [isCheckCondition, setIsCheckCondition] = useState(false);
    const [purchaseNote, setPurchaseNote] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [startFocused, setStartFocused] = useState();
    const [endFocused, setEndFocused] = useState();

    const affiliatePassed = useSelector((state) => (state.affiliates.passedAffiliate ? state.affiliates.passedAffiliate.passedAffiliates : []));
    const setting = useSelector((state) => (state.store.affiliateSetting));

    const eventCancel = () => {
        window.LadiUI.closeModal('modal-invoice-selected-affiliate');
        setIsCheckCondition(false);
        setPurchaseNote(null);
        setConditions(null);
        setFromDate(null);
        setToDate(null);
    };

    const actionFilter = (type) => {
        let startDateNew = null;
        let endDateNew = null;
        switch (type) {
            case 'TODAY':
                startDateNew = moment();
                endDateNew = moment();
                break;
            case 'YESTERDAY':
                startDateNew = moment().subtract(1, 'day');
                endDateNew = moment().subtract(1, 'day');
                break;
            case '15DAYSAGO':
                startDateNew = moment().subtract(15, 'day');
                endDateNew = moment();
                break;
            case '30DAYSAGO':
                startDateNew = moment().subtract(30, 'day');
                endDateNew = moment();
                break;
            case '90DAYSAGO':
                startDateNew = moment().subtract(90, 'day');
                endDateNew = moment();
                break;
            case 'THIS_MONTH':
                startDateNew = moment().startOf('month');
                endDateNew = moment();
                break;
            case 'LAST_MONTH':
                startDateNew = moment().subtract(1, 'month').startOf('month');
                endDateNew = moment().subtract(1, 'month').endOf('month');
                break;
            default:
        }

        if (!conditions || (conditions && type !== conditions.filter_type)) {
            setFromDate(startDateNew);
            setToDate(endDateNew);
            setConditions({
                ...conditions, date_from: startDateNew.format('YYYY-MM-DD 00:00:01'), date_to: endDateNew.format('YYYY-MM-DD 23:59:59'), filter_type: type,
            });
        }
    };

    const cbCreateInvoiceMulti = () => {
        const response = cbCreateInvoiceMulti.response;
        window.LadiUI.closeModal('modal-invoice-selected-affiliate');
        if (response.data.code === status.OK) {
            window.LadiUI.showModal('modal-invoice-selected-affiliate-redirect');
        }
        setIsCheckCondition(false);
        setPurchaseNote(null);
        setConditions(null);
        setFromDate(null);
        setToDate(null);
        cb();
    };

    const createInvoice = () => {
        const data = {
            affiliatePassed,
            date_from: fromDate,
            date_to: toDate,
            note: purchaseNote,
        };
        dispatch(actionAffiliate.createInvoiceMultiAffiliate(data, cbCreateInvoiceMulti));
    };

    const checkConditionInvoice = () => {
        const data = { ...conditions, ids };
        dispatch(actionAffiliate.getPassedAffiliate(data, () => setIsCheckCondition(true)));
    };

    const eventChooseDate = (date, start) => {
        const dateFormat = BaseHelper.getFromDate(date);
        if (start) {
            setFromDate(dateFormat);
            setConditions({
                ...conditions, date_from: date ? date.format('YYYY-MM-DD') : null, filter_type: 'CUSTOM',
            });
        } else {
            setToDate(dateFormat);
            setConditions({
                ...conditions, date_to: date ? date.format('YYYY-MM-DD') : null, filter_type: 'CUSTOM',
            });
        }
    };

    useEffect(() => {
        setIsCheckCondition(false);
    }, [conditions]);

    useEffect(() => {
        const data = {
            field: 'affiliate_setting',
        };
        dispatch(actionStore.getAffiliateSetting(data));
    }, []);

    return <>
        <div className="ladiui modal bd-example-modal-lg" id="modal-invoice-selected-affiliate">
            <div className="ladiui modal-dialog modal-dialog-centered modal-lg">
                <div className="ladiui modal-content custom-ldp" style={{ fontWeight: '400' }}>
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">Tạo hoá đơn affiliate</label>
                        <button type="button" data-dismiss="modal" data-target="modal-export-commission" className="ladiui modal-close" onClick={eventCancel}>
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>

                    <div className="ladiui form-group-custom">
                        {setting && setting.limit_invoice_status
                            ? <label className="ladiui title-text-form-lever2" htmlFor="amount">* Số tiền cho hóa đơn phải lớn hơn hoặc bằng:
                                {BaseHelper.formatMoney(setting ? setting.limit_invoice_value : 0)}</label>
                            : <></>
                        }
                        {setting && setting.limit_customer_status
                            ? <label className="ladiui title-text-form-lever2" htmlFor="amount">* Số khách hàng phải lớn hơn hoặc bằng:
                                {BaseHelper.formatNumber(setting ? setting.limit_customer_value : 0)} khách hàng</label>
                            : <></>
                        }
                    </div>

                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2" >Chọn khoảng thời gian để lập hoá đơn</label>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <>
                                <div className="ladiui">
                                    {/* <label className="ladiui title-text-form-lever2">Ngày bắt đầu</label> */}
                                    <div className="group-input-custom custom-form-dynamic time-expired-plan-payment">
                                        <SingleDatePicker
                                            date={fromDate} // momentPropTypes.momentObj or null
                                            onDateChange={(date) => eventChooseDate(date, true)} // PropTypes.func.isRequired
                                            focused={startFocused} // PropTypes.bool
                                            onFocusChange={({ focused }) => setStartFocused(focused)} // PropTypes.func.isRequired
                                            numberOfMonths={1}
                                            showClearDate={true}
                                            noBorder={false}
                                            placeholder="Ngày bắt đầu"
                                            readOnly={true}
                                            // disabled={paymentTime.type !== DefineConst.PAYMENT_TERM_TYPE.CUSTOM}
                                            isOutsideRange={(day) => false}
                                            showDefaultInputIcon={true}
                                            inputIconPosition="after"
                                            small={true}
                                            customInputIcon={
                                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="ladiui">
                                    {/* <label className="ladiui title-text-form-lever2">Ngày kết thúc</label> */}
                                    <div className="group-input-custom custom-form-dynamic time-expired-plan-payment">
                                        <SingleDatePicker
                                            date={toDate} // momentPropTypes.momentObj or null
                                            onDateChange={(date) => eventChooseDate(date, false)} // PropTypes.func.isRequired
                                            focused={endFocused} // PropTypes.bool
                                            onFocusChange={({ focused }) => setEndFocused(focused)} // PropTypes.func.isRequired
                                            numberOfMonths={1}
                                            showClearDate={true}
                                            noBorder={false}
                                            placeholder="Ngày kết thúc"
                                            readOnly={true}
                                            // disabled={paymentTime.type !== DefineConst.PAYMENT_TERM_TYPE.CUSTOM}
                                            isOutsideRange={(day) => false}
                                            showDefaultInputIcon={true}
                                            inputIconPosition="after"
                                            small={true}
                                            customInputIcon={
                                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                            <button className={`ladiui btn btn-calendar sevendaysago ${conditions && conditions.filter_type === '15DAYSAGO' ? '' : 'btn-outline-light'}`}
                                onClick={() => actionFilter('15DAYSAGO')}
                            >15 ngày</button>
                            <button className={`ladiui btn btn-calendar thirtydaysago ${conditions && conditions.filter_type === '30DAYSAGO' ? '' : 'btn-outline-light'}`}
                                onClick={() => actionFilter('30DAYSAGO')}
                            >30 ngày</button>
                            <button className={`ladiui btn btn-calendar thismonth ${conditions && conditions.filter_type === 'THIS_MONTH' ? '' : 'btn-outline-light'}`}
                                onClick={() => actionFilter('THIS_MONTH')}
                            >Tháng này</button>
                            <button className={`ladiui btn btn-calendar lastmonth ${conditions && conditions.filter_type === 'LAST_MONTH' ? '' : 'btn-outline-light'}`}
                                onClick={() => actionFilter('LAST_MONTH')}
                            >Tháng trước</button>
                        </div>
                        <div className="ladiui form-group-custom">
                            <button className="ladiui btn btn-primary" onClick={checkConditionInvoice}>Kiểm tra</button>
                        </div>
                    </div>

                    {isCheckCondition
                        ? <>
                            <div style={{ marginTop: '12px' }}>
                                Số lượng affliate đủ điều kiện tạo hoá đơn: {affiliatePassed ? affiliatePassed.length : 0} affiliate
                            </div>

                            {affiliatePassed && affiliatePassed.length > 0
                                ? <>
                                    <div style={{ maxHeight: '500px', overflowY: 'scroll', marginTop: '25px' }}>
                                        <table className="ladiui table text-left" style={{ marginTop: 0 }}>
                                            <thead>
                                                <tr className="ladiui table-vertical header">
                                                    <th scope="col">#</th>
                                                    <th scope="col">Họ tên</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Mã</th>
                                                    <th scope="col">Số hoa hồng được lên hoá đơn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {affiliatePassed.map((item, index) => <tr className="ladiui table-vertical main" key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.fullname}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.code}</td>
                                                    <td>{item.commissions.length}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="ladiui form-group-custom">
                                        <label className="ladiui title-text-form-lever2">Ghi chú cho hoá đơn</label>
                                        <textarea className="ladiui form-control" placeholder="Nhập nội dung" rows={3}
                                            value={purchaseNote || ''}
                                            onChange={(e) => setPurchaseNote(e.target.value)}
                                        />
                                    </div>

                                    <div className="ladiui modal-footer custom-ldp">
                                        <div></div>
                                        <div className="ladiui footer-modal-btn">
                                            <button type="button" className="ladiui btn btn-secondary bold" onClick={eventCancel}>{t('COMMISSIONS.CANCELED')}</button>
                                            <button type="button" className="ladiui btn btn-primary" onClick={() => createInvoice()}>Tạo hoá đơn</button>
                                        </div>

                                    </div>
                                </> : <></>}
                        </>
                        : <></>
                    }
                </div>
            </div>
        </div >
    </>;
}
export default (withTranslation()(ModalInvoiceAffiliate));
