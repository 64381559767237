const config = {
        // LS
        API_V2_0: "https://api.ladishare.com/1.0",

        // UID
        API_UID_V2_0: "https://api.ladiuid.com/2.0",
        ACCOUNT_UID: "https://accounts.ladiuid.com",
        API_CDN: "https://api.files.ladicdn.com",
        AFFILIATE_DOMAIN: "https://aff.ladishare.com",
        APP_AFFILIATE: true,
	DOMAIN_CDN: 'https://w.ladicdn.com'
}

const authEndpoint = {
    AUTH_SIGNIN: `${config.ACCOUNT_UID}/signin?callback_url=https://app.ladishare.com&app_code=lf`,
    AUTH_SIGNUP: `${config.ACCOUNT_UID}/signup?callback_url=https://app.ladishare.com&app_code=lf`,
    AUTH_SIGNOUT: `${config.ACCOUNT_UID}/signin?logout=1&callback_url=https://app.ladishare.com&app_code=lf`,
}

export default config;

export {
    authEndpoint
}
