import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LadiDropdown from '../../../../components/LadiDropdown';

function CommissionPlanPaymentCategoryBox(props) {
    const conditionCategoryID = [
        { name: 'Chứa tất cả', value: 'AND' },
        { name: 'Chứa 1 trong', value: 'OR' },
    ];

    const {
        t, categoryIDProp, cb,
    } = props;

    const [categoryID, setCategoryID] = useState({ enable: false, condition: 'AND', category_ids: [] });

    const renderExplain = () => {
        let explain = <></>;
        if (categoryID.enable) {
            if (categoryID.category_ids.length === 0) {
                explain = <>(*) Đơn hàng chứa bất kỳ sản phẩm nào cũng sẽ được tính hoa hồng</>;
            } else if (categoryID.category_ids.length === 1) {
                explain = <>(*) Đơn hàng chứa sản phẩm có ID danh mục là {categoryID.category_ids.join(',')} sẽ được tính hoa hồng</>;
            } else if (categoryID.condition === 'AND') {
                explain = <>(*) Đơn hàng phải chứa toàn bộ các sản phẩm có ID danh mục là {categoryID.category_ids.join(',')} sẽ được tính hoa hồng</>;
            } else {
                explain = <>(*) Đơn hàng chứa 1 trong các sản phẩm có ID danh mục là {categoryID.category_ids.join(',')} sẽ được tính hoa hồng</>;
            }
        }
        return explain;
    };

    const eventEnableCategoryID = (e) => {
        const value = e.target.checked;
        const data = { ...categoryID, enable: value };
        setCategoryID(data);
        cb(data);
    };

    const eventSelectConditionCategoryID = (value) => {
        const data = { ...categoryID, condition: value };
        setCategoryID(data);
        cb(data);
    };

    const eventChangeCategoryID = (e) => {
        const category_ids = e.target.value.split(',');
        const data = { ...categoryID, category_ids };
        setCategoryID(data);
        cb(data);
    };

    useEffect(() => {
        setCategoryID({ ...categoryID, ...categoryIDProp });
    }, [categoryIDProp]);

    return <>
        <div className="ladiui clearfix pt-20">
            <label className="ladiui title-text-form-lever2 left">Thiết lập điều kiện Danh mục sản phẩm cho đơn hàng</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox"
                        name="enable"
                        checked={categoryID !== null && (categoryID.enable || false)}
                        onChange={(e) => eventEnableCategoryID(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Kiểm tra đơn hàng có các ID danh mục sản phẩm theo cài đặt dưới đây</div>
        </div>
        {categoryID && categoryID.enable
            ? <>
                <div className="ladiui clearfix pt-20">
                    <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Điều kiện đơn hàng</label>
                        <LadiDropdown
                            labelDropdown={t('COMMISSIONS.SELECT_CONDITION')}
                            listProp={conditionCategoryID}
                            valueProp={categoryID.condition}
                            hideLabel={true}
                            validProp={true}
                            classDropdown="dropdown-custom w-180"
                            classSize="btn-sm"
                            cbProp={eventSelectConditionCategoryID}
                        />

                    </div>
                    <div className="ladiui left">
                        <label className="ladiui title-text-form-lever3">Danh sách ID Danh mục sản phẩm</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <textarea className="ladiui form-control "
                                value={categoryID && categoryID.category_ids ? categoryID.category_ids.join(',') : ''}
                                onChange={eventChangeCategoryID} placeholder="Danh sách ID ngăn cách bằng dấu phẩy" />
                        </div>
                    </div>
                </div>
                <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>
                    {renderExplain()}
                </div>
            </>
            : <></>}
    </>;
}

export default (withTranslation()(CommissionPlanPaymentCategoryBox));
