import React, { useEffect, useState } from 'react';
import DefineConst from '../DefineConst';

function BankInfoDropdown(props) {
    const {
        t, bankName, cb,
    } = props;

    const [conditions, setConditions] = useState({});
    const [selectedItem, setSelectedItem] = useState([]);
    const [banks, setBanks] = useState([]);

    const getListBank = (keyword = '') => {
        keyword = keyword.toLocaleLowerCase();
        let banks = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(DefineConst.BANKS)) {
            if (value && (value.name.toLocaleLowerCase().includes(keyword) || key.toLocaleLowerCase().includes(keyword))) {
                banks.push({ ...value, code: key });
            }
        }
        return banks;
    };

    const eventSelectAffiliate = (item) => {
        setSelectedItem(item);
        cb(item);
    };

    useEffect(() => {
        setBanks(getListBank(conditions.keyword || ''));
    }, [conditions]);

    useEffect(() => {
        if (bankName) {
            let bank = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(DefineConst.BANKS)) {
                if (value.name.toLocaleLowerCase().includes(bankName.toLocaleLowerCase())) {
                    bank = { ...value, code: key };
                }
            }
            setSelectedItem(bank);
        } else {
            setSelectedItem({});
        }
    }, [bankName]);

    return <>
        <div className="ladiui select-search dropdown">
            <button
                className={'ladiui btn btn-outline-light dropdown-toggle'}
                data-toggle="dropdown"
                style={{ height: '34px' }}
            >
                <i></i>
                <span className="ladiui dropdown-text" style={{ marginRight: 0 }}>
                    {selectedItem.name || 'Chọn ngân hàng'}
                </span>
            </button>
            <ul className="ladiui dropdown-menu dropdown-right" style={{ width: '100%', maxHeight: '400px', overflowY: 'auto' }}>
                <li className="ladiui search-group custom-people">
                    <input
                        className="ladiui search-field dropdown-toggle form-control custom-people"
                        onChange={(e) => setConditions({ ...conditions, keyword: e.target.value })}
                        placeholder="Tìm kiếm"
                    />
                    <i className="ladiui icon icon-search"></i>
                </li>
                {
                    banks.map((item) => <li key={item.code} onClick={() => eventSelectAffiliate(item)} >
                        <a className="ladiui dropdown-item" href="#/">
                            {item.code} - {item.name}
                        </a>
                    </li>)
                }
            </ul>
        </div>
    </>;
}

export default BankInfoDropdown;
