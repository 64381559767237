import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from '../../../../assets/css/custom.module.css';
import config from '../../../../config/config';

function InstagramContent(props) {
    const { t, contents } = props;
    const user = useSelector((state) => state.store.userInfo);
    return contents
        ? <>
            <label>Nội dung</label>
            <div className="content-left-detail">
                {
                    contents.image
                        ? <div className={styles.banner}>
                            {contents.image.map((image, index) => <img key={index} className={styles['img-instagram']} src={`${config.DOMAIN_CDN}/${image.path}`} />)}
                        </div> : <><img src="https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png" className={styles['img-instagram']} /></>
                }
                <div className={styles['list-user']}>
                    <img className={styles['avartar-image-mini']} src="https://imgt.taimienphi.vn/cf/Images/np/2021/11/26/hinh-anh-avatar-dep-5.jpg" />
                    <img className={styles['avartar-image-mini']} src="https://imgt.taimienphi.vn/cf/Images/np/2021/11/26/hinh-anh-avatar-dep-5.jpg" />
                    <img className={styles['avartar-image-mini']} src="https://imgt.taimienphi.vn/cf/Images/np/2021/11/26/hinh-anh-avatar-dep-5.jpg" />
                    <span><span className={styles.bold}>ladishare</span> và <span className={styles.bold}>1,500 người khác</span> đã thích</span>
                </div>
                <div className={styles.content}>{contents.contents ? contents.contents : ''}</div>
            </div>
        </> : <></>;
}
export default (withTranslation()(InstagramContent));
