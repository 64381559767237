import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
// eslint-disable-next-line import/no-extraneous-dependencies
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';
import LadiDateRangePicker from '../../../components/LadiDateRangePicker';
import actionStatistics from '../../../redux/futures/statistics/actions';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import ChartConfig from '../../dashboard/ChartConfig';
import styles from '../../../assets/css/custom.module.css';

// eslint-disable-next-line max-lines-per-function
function DailyReport(props) {
    const { t } = props;
    const search = new URLSearchParams(window.location.search);
    const affiliateId = search.get('id');
    const options = ChartConfig.options;
    const chartLang = ChartConfig.lang;
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.DEFAULT_LIMIT,
        sort: { created_at: -1 },
    };

    const dispatch = useDispatch();
    const moment = BaseHelper.getMoment();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [conditions, setConditions] = useState(null);
    const [highChartOptions, setHighChartOptions] = useState(options);
    const [affiliate, setAffiliate] = useState({});
    const [affiliateConditions, setAffiliateConditions] = useState(defaultConditions);

    const report = useSelector((state) => (state.statistics.dailyReport));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));
    const listAffiliateFilter = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.affiliates.filterAffiliates }));
    const affiliateData = useSelector((state) => state.affiliates.affiliate);

    const eventExportData = () => {
        dispatch(actionStatistics.exportStatistics(conditions));
    };

    const eventSelectAffiliate = (item) => {
        if (item) {
            setConditions({ ...conditions, affiliate_id: item._id });
            setAffiliate(item);
        } else {
            setConditions({ ...conditions, affiliate_id: null });
            setAffiliate({});
        }
    };

    const eventClickShowListAffiliate = () => {
        dispatch(actionAffiliate.listFilter(affiliateConditions));
    };

    const eventKeyDownInputSearch = (e) => {
        if (e.keyCode === 13) dispatch(actionAffiliate.listFilter(affiliateConditions));
    };

    const getCR = (p1, p2) => {
        if (p2 === 0) return 0;
        const rs = (100 * parseFloat(p1)) / parseFloat(p2);
        return Math.round((rs + Number.EPSILON) * 100) / 100;
    };

    useEffect(() => {
        if (conditions) {
            if ((conditions.date_from && conditions.date_to) || (!conditions.date_from && !conditions.date_to)) {
                dispatch(actionStatistics.dailyReport(conditions));
            }
        }
    }, [conditions]);

    useEffect(() => {
        Highcharts.setOptions({
            lang: chartLang,
        });
        setFromDate(moment().startOf('month'));
        setToDate(moment());
        setConditions({
            ...conditions, date_from: moment().startOf('month').format('YYYY-MM-DD 00:00:01'), date_to: moment().format('YYYY-MM-DD 23:59:59'), affiliate_id: affiliateId,
        });
    }, []);

    useEffect(() => {
        const pointStartPendingCommission = report && report.chartData ? new Date(report.chartData.chartDataPendingCommission.start) : new Date();
        const pointStartApprovedCommission = report && report.chartData ? new Date(report.chartData.chartDataApprovedCommission.start) : new Date();
        const pointStartInvoicedCommission = report && report.chartData ? new Date(report.chartData.chartDataInvoicedCommission.start) : new Date();

        const pointStartNumberPendingCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberPendingCommission.start) : new Date();
        const pointStartNumberApprovedCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberApprovedCommission.start) : new Date();
        const pointStartNumberInvoicedCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberInvoicedCommission.start) : new Date();

        const series = [
            {
                name: 'Số hoa hồng chờ duyệt',
                color: '#E7B269',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberPendingCommission.data : [],
                pointStart: pointStartNumberPendingCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Số hoa hồng tạm duyệt',
                color: 'rgba(28, 0, 194, 0.65)',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberApprovedCommission.data : [],
                pointStart: pointStartNumberApprovedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Số hoa hồng đã duyệt',
                color: '#41B983',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberInvoicedCommission.data : [],
                pointStart: pointStartNumberInvoicedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Hoa hồng chờ duyệt',
                color: '#E7B269',
                data: report && report.chartData ? report.chartData.chartDataPendingCommission.data : [],
                pointStart: pointStartPendingCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
            {
                name: 'Hoa hồng tạm duyệt',
                color: 'rgba(28, 0, 194, 0.65)',
                data: report && report.chartData ? report.chartData.chartDataApprovedCommission.data : [],
                pointStart: pointStartApprovedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
            {
                name: 'Hoa hồng đã duyệt',
                color: '#41B983',
                data: report && report.chartData ? report.chartData.chartDataInvoicedCommission.data : [],
                pointStart: pointStartInvoicedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
        ];
        setHighChartOptions({ ...highChartOptions, series });
    }, [report]);

    useEffect(() => {
        if (affiliateId) dispatch(actionAffiliate.show(affiliateId));
    }, [affiliateId]);

    useEffect(() => {
        if (affiliateData) setAffiliate(affiliateData);
    }, [affiliateData]);

    return <>
        <div className="ladiui content-main-tool flex-row align-item-baseline mt-24" style={{ justifyContent: 'space-between' }}>
            <div className="ladiui flex-row">
                <LadiDateRangePicker
                    startDateId="fromDate"
                    endDateId="toDate"
                    startDate={fromDate}
                    endDate={toDate}
                    onDatesChange={({ startDate, endDate }) => {
                        const startDateNew = BaseHelper.getFromDate(startDate);
                        const endDateNew = BaseHelper.getToDate(endDate);
                        setFromDate(startDateNew);
                        setToDate(endDateNew);
                        setConditions({ ...conditions, ...{ date_from: startDateNew ? startDateNew.format('YYYY-MM-DD') : null, date_to: endDateNew ? endDateNew.format('YYYY-MM-DD') : null } });
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => {
                        setFocusedInput(focusedInput);
                    }}
                    showDefaultInputIcon={true}
                    inputIconPosition="after"
                    small={true}
                    hideKeyboardShortcutsPanel={true}
                    customInputIcon={
                        <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                    }
                    startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                    endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                    isOutsideRange={() => false}
                    isDayHighlighted={(date) => moment().isSame(date, 'day')}
                    minimumNights={0}
                    showClearDates={true}
                />
                <div>
                    <div className="ladiui select-search dropdown">
                        <button
                            className={'ladiui btn btn-outline-light dropdown-toggle'}
                            data-toggle="dropdown"
                            style={{ height: '38px', marginLeft: '12px', width: '200px' }}
                            onClick={() => eventClickShowListAffiliate()}
                        >
                            <i></i>
                            <span className="ladiui dropdown-text" style={{ marginRight: 0 }}>
                                {affiliate.email || 'Chọn affiliate'}
                            </span>
                        </button>
                        <ul className="ladiui dropdown-menu dropdown-right" style={{ width: '100%' }}>
                            <li className="ladiui search-group custom-people">
                                <input
                                    className="ladiui search-field dropdown-toggle form-control custom-people"
                                    onChange={(e) => setAffiliateConditions({ ...affiliateConditions, keyword: e.target.value })}
                                    onKeyDown={(e) => eventKeyDownInputSearch(e)}
                                    placeholder="Tìm kiếm"
                                />
                                <i className="ladiui icon icon-search" onClick={() => dispatch(actionAffiliate.listFilter(affiliateConditions))}></i>
                            </li>
                            <li onClick={() => eventSelectAffiliate(null)}>
                                <a className="ladiui dropdown-item" href="#/">Tất cả affiliate</a>
                            </li>
                            {listAffiliateFilter.total > 0
                                ? listAffiliateFilter.items.map((item) => <li key={item._id}
                                    onClick={() => eventSelectAffiliate(item)} >
                                    <a className="ladiui dropdown-item" href="#/">
                                        {item.fullname} ({item.code}) - {item.email}
                                    </a>
                                </li>)
                                : <li>
                                    <a className="ladiui dropdown-item" href="#/">Không tìm thấy kết quả</a>
                                </li>
                            }
                            {listAffiliateFilter.total > listAffiliateFilter.items.length
                                ? <li>
                                    <label className="ladiui search-checkbox default-text" ><a>...</a></label>
                                </li>
                                : <></>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="ladiui btn-group custom-btn-list-landingpage" style={{ marginLeft: '8px' }}>
                <button className="ladiui btn btn-light" onClick={eventExportData}>
                    <img src="https://w.ladicdn.com/design-system/icons/icon-export.svg"
                        style={{ marginBottom: '2px', marginRight: '10px' }} />{t('COMMON.EXPORT_DATA')}
                </button>
            </div>
        </div>
        <div>
            <div className="ladiui content-main-title flex-row affiliate-statistics">
                <div className="ladiui box-statistics">
                    <div>
                        <div className="ladiui flex">Số chuyển đổi&nbsp;
                            <div className="tooltip">
                                <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                                <span className="tooltiptext top" tooltiptext="Chuyển đổi = Đơn hàng hoặc Lead"></span>
                            </div></div>
                        <span className="font-size-18 bold-500">{BaseHelper.formatNumber(report ? report.totalConvert : 0)}</span>
                    </div>
                    <div className="box-statistics-icon">
                        <img src="https://w.ladicdn.com/ladiui/ladishare/affiliate-2.svg" />
                    </div>
                </div>
                <div className="ladiui box-statistics">
                    <div>
                        <div>Hoa hồng chờ duyệt</div>
                        <div className="font-size-18 bold-500">{BaseHelper.formatMoney(report ? report.totalPendingCommission : 0, '', systemCurrency)}</div>
                        <div className={styles['box-content-publisher-number']}>
                            <div>{BaseHelper.formatNumber(report ? report.numberPendingCommission : 0)}</div>
                            <div>&nbsp;({getCR(report ? report.numberPendingCommission : 0, report ? report.totalConvert : 0)}%)&nbsp;</div>
                            <div className="tooltip">
                                <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                                <span className="tooltiptext top" tooltiptext="CR = Chờ duyệt/Số chuyển đổi"></span>
                            </div>
                        </div>
                    </div>
                    <div className="box-statistics-icon">
                        <img src="https://w.ladicdn.com/ladiui/ladishare/traffic-2.svg" />
                    </div>
                </div>
                <div className="ladiui box-statistics">
                    <div>
                        <div>Hoa hồng tạm duyệt</div>
                        <div className="font-size-18 bold-500">{BaseHelper.formatMoney(report ? report.totalApprovedCommission : 0, '', systemCurrency)}</div>
                        <div className={styles['box-content-publisher-number']}>
                            <div>{BaseHelper.formatNumber(report ? report.numberApprovedCommission : 0)}</div>
                            <div>&nbsp;({getCR(report ? report.numberApprovedCommission : 0, report ? report.numberPendingCommission : 0)}%)&nbsp;</div>
                            <div className="tooltip">
                                <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                                <span className="tooltiptext top" tooltiptext="CR = Tạm duyệt/Chờ duyệt"></span>
                            </div>
                        </div>
                    </div>
                    <div className="box-statistics-icon">
                        <img src="https://w.ladicdn.com/ladiui/ladishare/commission-2.svg" />
                    </div>
                </div>
                <div className="ladiui box-statistics">
                    <div>
                        <div>Hoa hồng đã duyệt</div>
                        <div className="font-size-18 bold-500">{BaseHelper.formatMoney(report ? report.totalInvoicedCommission : 0, '', systemCurrency)}</div>
                        <div className={styles['box-content-publisher-number']}>
                            <div>{BaseHelper.formatNumber(report ? report.numberInvoicedCommission : 0)}</div>
                            <div>&nbsp;({getCR(report ? report.numberInvoicedCommission : 0, report ? report.numberApprovedCommission : 0)}%)&nbsp;</div>
                            <div className="tooltip">
                                <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                                <span className="tooltiptext top" tooltiptext="CR = Đã duyệt/Tạm duyệt"></span>
                            </div>
                        </div>
                    </div>
                    <div className="box-statistics-icon">
                        <img src="https://w.ladicdn.com/ladiui/ladishare/revenue-2.svg" />
                    </div>
                </div>
            </div>
        </div>
        <div style={{ overflowX: 'auto' }}>
            <div style={{ minWidth: '650px' }}>
                <HighchartsReact highcharts={Highcharts} options={highChartOptions} />
            </div>
        </div>
    </>;
}

export default (withTranslation()(DailyReport));
