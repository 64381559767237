import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LadiDropdownMenu from '../../../components/LadiDropdownMenu';
import ConfirmModal from '../../../components/ConfirmModal';
import actionCommission from '../../../redux/futures/commission/actions';
import DefineConst from '../DefineConst';

function CheckAllAction(props) {
    const { t, cb } = props;
    const ids = props.ids || [];

    const listAction = [
        { value: 'CALC_COMMISSION_ALL', name: t('COMMISSIONS.CALC_COMMISSION_ALL') },
        { value: 'RE_CALC_COMMISSION_ALL', name: t('COMMISSIONS.RE_CALC_COMMISSION_ALL') },
        { value: 'CANCEL_COMMISSION_ALL', name: t('COMMISSIONS.CANCEL_COMMISSION_ALL') },
    ];

    const dispatch = useDispatch();
    const [actionSelected, setActionSelected] = useState(null);
    const [modalConfirm, setModalConfirm] = useState({});

    const eventSelectAction = (action) => {
        let dataModal = {};
        switch (action) {
            case 'CALC_COMMISSION_ALL':
                dataModal.title = 'Bạn có chắc chắn muốn tính hoa hồng toàn bộ nguồn được chọn?';
                dataModal.content = <div>Những Hoa hồng ở trạng thái
                    <span className="bold-500" style={{ verticalAlign: 'top', padding: '0 2px', fontStyle: 'italic' }}>lên hóa đơn</span>
                    sẽ không bị xóa</div>;
                break;
            case 'RE_CALC_COMMISSION_ALL':
                dataModal.title = 'Bạn có chắc chắn muốn tính lại hoa hồng toàn bộ nguồn được chọn?';
                dataModal.content = <div>
                    Những nguồn đã tính hoa hồng mới được phép tính lại.
                </div>;
                break;
            case 'CANCEL_COMMISSION_ALL':
                dataModal.title = 'Bạn có chắc chắn muốn hủy toàn bộ hoa hồng được chọn?';
                dataModal.content = <div>Những Hoa hồng ở trạng thái
                    <span className="bold-500" style={{ verticalAlign: 'top', padding: '0 2px', fontStyle: 'italic' }}>lên hóa đơn</span>
                    sẽ không bị hủy</div>;
                break;
            default:
        }
        setActionSelected(action);
        setModalConfirm(dataModal);
        window.LadiUI.showModal('modal-confirm-action-all');
    };

    const eventOkActionAll = () => {
        if (actionSelected) {
            switch (actionSelected) {
                case 'CALC_COMMISSION_ALL':
                    // dispatch(actionCommission.deleteMultiCommission({ ids }, cb));
                    break;
                case 'RE_CALC_COMMISSION_ALL':
                    // dispatch(actionCommission.acceptMultiCommission({ ids, status: DefineConst.COMMISSION_STATUS.APPROVED }, cb));
                    break;
                case 'CANCEL_COMMISSION_ALL':
                    // dispatch(actionCommission.rejectMultiCommission({ ids, status: DefineConst.COMMISSION_STATUS.CANCELED }, cb));
                    break;
                default:
            }
        }
    };

    return ids.length > 0
        ? <>
            <div className="ladiui ladi-check-all-action">
                <LadiDropdownMenu
                    labelDropdown={'Chọn hành động'}
                    listProp={listAction}
                    defaultTextProp={t('COMMON.ACTION')}
                    hideLabel={true}
                    cbProp={eventSelectAction}
                    classSize="btn-sm"
                    classDropdown=""
                />
            </div>
            <ConfirmModal
                id="modal-confirm-action-all"
                title={modalConfirm.title || ''}
                content={modalConfirm.content || ''}
                onOk={() => eventOkActionAll()}
                cancelText={t('COMMISSIONS.CANCELED')}
                okText={t('COMMISSIONS.OK')}
                classBtn="btn-primary"
            />
        </>
        : <></>;
}

export default (withTranslation()(CheckAllAction));
