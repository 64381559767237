import { map } from 'lodash';
import Dashboard from './pages/dashboard/Dashboard';
import Affiliate from './pages/affiliates/Affiliate';
import i18n from './i18n';
import AffiliateDetail from './pages/affiliates/AffiliateDetail';
import Setting from './pages/settings/Setting';
import Commission from './pages/commissions/Commission';
import AppIntegrate from './pages/apps/AppIntegrate';
import Source from './pages/sources/Source';
import HaravanPostMessageIntegrate from './pages/oauth/HaravanPostMessageIntegrate';
import SapoPostMessageIntegrate from './pages/oauth/SapoPostMessageIntegrate';
import NhanhPostMessageIntegrate from './pages/oauth/NhanhPostMessageIntegrate';
import ShopifyPostMessageIntegrate from './pages/oauth/ShopifyPostMessageIntegrate';
import Invoice from './pages/invoices/Invoice';
import Campaign from './pages/campaigns/Campaign';
import Report from './pages/reports/Report';
import Login from './pages/publishers/Login';
import Register from './pages/publishers/Register';
import ChangePassword from './pages/publishers/ChangePassword';
import ForgotPassword from './pages/publishers/ForgotPassword';
import CampaignDetail from './pages/campaigns/CampaignDetail';
import config from './config/config';
import ResetPassword from './pages/publishers/ResetPassword';
import AffiliateAccount from './pages/affiliates/AffiliateAccount';
import CampaignShare from './pages/publishers/CampaignShare';
import InvoicePrint from './pages/invoices/InvoicePrint';
import Customer from './pages/customers/Customer';
import AffiliateReferral from './pages/referrals/AffiliateReferral';
import PublisherLink from './pages/links/PublisherLink';
import RequestInvoice from './pages/request_invoices/RequestInvoice';

const allRoutes = {
    '/':
        [
            {
                path: '/', exact: true, name: `${i18n.t('MENU.DASH_BOARD')}`, component: Dashboard,
            },
        ],
    '/dashboards':
        [
            {
                path: '/dashboards', exact: true, name: `${i18n.t('MENU.DASH_BOARD')}`, component: Dashboard,
            },
        ],
    '/affiliates':
        [
            {
                path: '/affiliates', exact: true, name: `${i18n.t('MENU.AFFILIATES')}`, component: Affiliate,
            },
            {
                path: '/affiliates/:id', exact: true, name: `${i18n.t('MENU.AFFILIATES')}`, component: AffiliateDetail,
            },
        ],
    '/commissions':
        [
            {
                path: '/commissions', exact: true, name: `${i18n.t('MENU.COMMISSIONS')}`, component: Commission,
            },
        ],
    '/customers':
        [
            {
                path: '/customers', exact: true, name: `${i18n.t('MENU.CUSTOMERS')}`, component: Customer,
            },
        ],
    '/campaigns':
        [
            {
                path: '/campaigns', exact: true, name: `${i18n.t('MENU.CAMPAIGNS')}`, component: Campaign,
            },
            {
                path: '/campaigns/:id', exact: true, name: `${i18n.t('MENU.CAMPAIGNS')}`, component: CampaignDetail,
            },
        ],
    '/invoices':
        [
            {
                path: '/invoices', exact: true, name: `${i18n.t('MENU.INVOICES')}`, component: Invoice,
            },
            {
                path: '/invoices/print/:id', exact: true, name: `${i18n.t('MENU.INVOICES')}`, component: InvoicePrint,
            },
        ],
    '/request-invoices':
        [
            {
                path: '/request-invoices', exact: true, name: `${i18n.t('MENU.REQUEST_INVOICES')}`, component: RequestInvoice,
            },
        ],
    '/reports':
        [
            {
                path: '/reports', exact: true, name: `${i18n.t('MENU.REPORTS')}`, component: Report,
            },
        ],
    '/sources':
        [
            {
                path: '/sources', exact: true, name: `${i18n.t('MENU.SOURCES')}`, component: Source,
            },
        ],
    '/apps':
        [
            {
                path: '/apps', exact: true, name: `${i18n.t('MENU.APPS')}`, component: AppIntegrate,
            },
        ],
    '/settings':
        [
            {
                path: '/settings', exact: true, name: `${i18n.t('MENU.SETTINGS')}`, component: Setting,
            },
            {
                path: '/settings/general', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
            {
                path: '/settings/affiliate', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
            {
                path: '/settings/member', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
            {
                path: '/settings/group-affiliate', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
            {
                path: '/settings/history', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
            {
                path: '/settings/email', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
            {
                path: '/settings/commission-plan', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
            {
                path: '/settings/sdk', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
            {
                path: '/settings/coupon-code', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
            {
                path: '/settings/ip', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
            {
                path: '/settings/package-info', name: `${i18n.t('MENU.SETTINGS')}`, exact: true, component: Setting,
            },
        ],
    '/account-info':
        [
            {
                path: '/account-info', exact: true, name: `${i18n.t('MENU.ACCOUNT_INFO')}`, component: AffiliateAccount,
            },
        ],
    '/oauth': [
        { path: '/oauth/haravan.html', exact: true, component: HaravanPostMessageIntegrate },
        { path: '/oauth/sapo', exact: true, component: SapoPostMessageIntegrate },
        { path: '/oauth/nhanh', exact: true, component: NhanhPostMessageIntegrate },
        { path: '/oauth/shopify', exact: true, component: ShopifyPostMessageIntegrate },
    ],
    '/publisher': [
        { path: '/:store/login', exact: true, component: Login },
        { path: '/:store/register', exact: true, component: Register },
        { path: '/:store/change-password', exact: true, component: ChangePassword },
        { path: '/:store/forgot-password', exact: true, component: ForgotPassword },
        { path: '/:store/reset-password', exact: true, component: ResetPassword },
        { path: '/campaign-share', exact: true, component: CampaignShare },
    ],
};
const publisherAllRoutes = {
    '/':
        [
            {
                path: '/', exact: true, name: `${i18n.t('MENU.DASH_BOARD')}`, component: Dashboard,
            },
        ],
    '/dashboards':
        [
            {
                path: '/dashboards', exact: true, name: `${i18n.t('MENU.DASH_BOARD')}`, component: Dashboard,
            },
        ],
    '/commissions':
        [
            {
                path: '/commissions', exact: true, name: `${i18n.t('MENU.COMMISSIONS')}`, component: Commission,
            },
        ],
    '/customers':
        [
            {
                path: '/customers', exact: true, name: `${i18n.t('MENU.CUSTOMERS')}`, component: Customer,
            },
        ],
    '/refs':
        [
            {
                path: '/refs', exact: true, name: `${i18n.t('MENU.REFS')}`, component: AffiliateReferral,
            },
        ],
    '/links':
        [
            {
                path: '/links', exact: true, name: `${i18n.t('MENU.LINKS_MANAGER')}`, component: PublisherLink,
            },
        ],
    '/campaigns':
        [
            {
                path: '/campaigns', exact: true, name: `${i18n.t('MENU.CAMPAIGNS')}`, component: Campaign,
            },
        ],
    '/invoices':
        [
            {
                path: '/invoices', exact: true, name: `${i18n.t('MENU.INVOICES')}`, component: Invoice,
            },
        ],
    '/request-invoices':
        [
            {
                path: '/request-invoices', exact: true, name: `${i18n.t('MENU.REQUEST_INVOICES')}`, component: RequestInvoice,
            },
        ],
    '/reports':
        [
            {
                path: '/reports', exact: true, name: `${i18n.t('MENU.REPORTS')}`, component: Report,
            },
            {
                path: '/invoices/print/:id', exact: true, name: `${i18n.t('MENU.INVOICES')}`, component: InvoicePrint,
            },
        ],
    '/account-info':
        [
            {
                path: '/account-info', exact: true, name: `${i18n.t('MENU.ACCOUNT_INFO')}`, component: AffiliateAccount,
            },
        ],
    '/publisher': [
        { path: '$$$/login', exact: true, component: Login },
        { path: '$$$/register', exact: true, component: Register },
        { path: '$$$/change-password', exact: true, component: ChangePassword },
        { path: '$$$/forgot-password', exact: true, component: ForgotPassword },
        { path: '$$$/reset-password', exact: true, component: ResetPassword },
        { path: '/campaign-share', exact: true, component: CampaignShare },
    ],
};

const menuItems = {
    '/': {
        keyStr: [''],
        key: 'dashboards',
        classLi: 'ladiui menu-item',
        to: '/',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-dashboards',
        title: 'MENU.DASH_BOARD',
    },
    '/affiliates': {
        keyStr: ['affiliates'],
        key: 'affiliates',
        classLi: 'ladiui menu-item',
        to: '/affiliates',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-affiliates',
        title: 'MENU.AFFILIATES',
    },
    '/commissions': {
        keyStr: ['commissions'],
        key: 'commissions',
        classLi: 'ladiui menu-item',
        to: '/commissions',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-commissions',
        title: 'MENU.COMMISSIONS',
    },
    '/customers': {
        keyStr: ['customers'],
        key: 'customers',
        classLi: 'ladiui menu-item',
        to: '/customers',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-customers',
        title: 'MENU.CUSTOMERS',
    },
    '/campaigns': {
        keyStr: ['campaigns'],
        key: 'campaigns',
        classLi: 'ladiui menu-item',
        to: '/campaigns',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-campaigns',
        title: 'MENU.CAMPAIGNS',
    },
    '/invoices': {
        keyStr: ['invoices'],
        key: 'invoices',
        classLi: 'ladiui menu-item',
        to: '/invoices',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-invoices',
        title: 'MENU.INVOICES',
    },
    '/request-invoices': {
        keyStr: ['request-invoices'],
        key: 'request-invoices',
        classLi: 'ladiui menu-item',
        to: '/request-invoices',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-request-invoices',
        title: 'MENU.REQUEST_INVOICES',
    },
    '/reports': {
        keyStr: ['reports'],
        key: 'reports',
        classLi: 'ladiui menu-item',
        to: '/reports',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-reports',
        title: 'MENU.REPORTS',
    },
    '/apps': {
        keyStr: ['apps'],
        key: 'apps',
        classLi: 'ladiui menu-item',
        to: '/apps',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-apps',
        title: 'MENU.APPS',
    },
    '/settings': {
        keyStr: ['settings'],
        key: 'settings',
        classLi: 'ladiui menu-item',
        to: '/settings/general',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-settings',
        title: 'MENU.SETTINGS',
    },
};

const publisherMenuItems = {
    '/': {
        keyStr: [''],
        key: 'dashboards',
        classLi: 'ladiui menu-item',
        to: '/',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-dashboards',
        title: 'MENU.DASH_BOARD',
    },
    '/reports': {
        keyStr: ['reports'],
        key: 'reports',
        classLi: 'ladiui menu-item',
        to: '/reports',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-reports',
        title: 'MENU.REPORTS',
    },
    '/commissions': {
        keyStr: ['commissions'],
        key: 'commissions',
        classLi: 'ladiui menu-item',
        to: '/commissions',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-commissions',
        title: 'MENU.COMMISSIONS',
    },
    '/customers': {
        keyStr: ['customers'],
        key: 'customers',
        classLi: 'ladiui menu-item',
        to: '/customers',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-customers',
        title: 'MENU.CUSTOMERS',
    },
    '/campaigns': {
        keyStr: ['campaigns'],
        key: 'campaigns',
        classLi: 'ladiui menu-item',
        to: '/campaigns',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-campaigns',
        title: 'MENU.CAMPAIGNS',
    },
    '/links': {
        keyStr: ['links'],
        key: 'links',
        classLi: 'ladiui menu-item more-item-menu',
        to: '/links',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-links',
        title: 'MENU.LINKS_MANAGER',
    },
    '/invoices': {
        keyStr: ['invoices'],
        key: 'invoices',
        classLi: 'ladiui menu-item more-item-menu',
        to: '/invoices',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-invoices',
        title: 'MENU.INVOICES',
    },
    '/request-invoices': {
        keyStr: ['request-invoices'],
        key: 'request-invoices',
        classLi: 'ladiui menu-item more-item-menu',
        to: '/request-invoices',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-request-invoices',
        title: 'MENU.REQUEST_INVOICES',
    },
    '/refs': {
        keyStr: ['refs'],
        key: 'refs',
        classLi: 'ladiui menu-item more-item-menu',
        to: '/refs',
        classNav: 'ladiui flex-row-menu-item',
        classIcon: 'ladiui icon icon-menu-affiliates',
        title: 'MENU.REFS',
    },
};
class Routes {
    getRoutesByScopes(scopes = {}) {
        const routes = [];
        if (scopes === 'ADVERTISER') {
            map(allRoutes, (values) => {
                routes.push(...values);
            });
            return routes;
        }
        map(publisherAllRoutes, (values) => {

            map(values, (item, key) => {
                if (!config.APP_AFFILIATE || (config.APP_AFFILIATE && window.location.origin === config.AFFILIATE_DOMAIN)) {
                    values[key].path = values[key].path.replace('$$$', '/:store');
                } else {
                    values[key].path = values[key].path.replace('$$$', '');
                }
            });

            routes.push(...values);
        });
        return routes;
    }

    getItemByScopes(scopes = {}) {
        if (scopes === 'ADVERTISER') {
            return menuItems;
        }
        return publisherMenuItems;
    }
}
export default new Routes();
