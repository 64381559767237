import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from '../../../../assets/css/custom.module.css';

function TwitterContent(props) {
    const { t, contents } = props;
    const user = useSelector((state) => state.store.userInfo);
    return <>
        <label>Nội dung</label>
        <div className="content-left-detail">
            <div className={styles.avatar}>
                <div className={styles['avartar-image']}>
                    {user.user.avatar ? <img src={user.user.avatar} alt="" width={40} height={40}
                        style={{ objectFit: 'cover' }} className={styles.rounded} /> : user.user.fullname.charAt(0)}
                </div>
                <div>
                    <label className={styles['avartar-name']}>Ladishare</label><span className={styles['account-id']}>@ladishare2022</span>
                    <div className={styles.content}>{contents}</div>
                </div>
            </div>
            <div className={styles.tool}>
                <img src="https://w.ladicdn.com/design-system/icons/twitter-comment.svg" className={styles['mr-8']} />
                <img src="https://w.ladicdn.com/design-system/icons/twitter-like.svg" className={styles['mr-8']} />
                <img src="https://w.ladicdn.com/design-system/icons/twitter-share.svg" className={styles['mr-8']} />
            </div>
        </div>
    </>;
}
export default (withTranslation()(TwitterContent));
