import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BaseHelper from '../../helpers/BaseHelper';
import actionStore from '../../redux/futures/store/actions';
import ConditionChangeGroupBox from './components/ConditionChangeGroupBox';
import DefineConst from './DefineConst';

function ModalConditionChangeGroupAffiliate(props) {
    const {
        t,
    } = props;
    const [listConditionChangeGroup, setListConditionChangeGroup] = useState([]);
    const setting = useSelector((state) => (state.store.affiliateChangeGroupSetting));

    const dispatch = useDispatch();

    const eventAddMoreCondition = () => {
        const id = BaseHelper.generateRandomString(10);
        const defaultConditionChangeGroup = { id, time_type: DefineConst.TIME_TYPE.MONTH };
        const data = [...listConditionChangeGroup, defaultConditionChangeGroup];
        setListConditionChangeGroup(data);
    };

    const eventChangeConditionChangeGroup = (data) => {
        const items = [];
        for (let i = 0; i < listConditionChangeGroup.length; i++) {
            if (listConditionChangeGroup[i].id === data.id) {
                items.push(data);
            } else {
                items.push(listConditionChangeGroup[i]);
            }
        }
        setListConditionChangeGroup(items);
    };

    const eventRemoveConditionChangeGroup = (data) => {
        const items = listConditionChangeGroup.filter((item) => item.id !== data.id);
        setListConditionChangeGroup(items);
    };

    const eventOk = () => {
        const data = { affiliate_change_group_setting: listConditionChangeGroup };
        dispatch(actionStore.updateAffiliateChangeGroupSetting(data));
        // window.LadiUI.closeModal('modal-setting-change-group');
    };

    const eventCancel = () => {
        window.LadiUI.closeModal('modal-setting-change-group');
    };

    useEffect(() => {
        if (setting) setListConditionChangeGroup(setting);
    }, [setting]);

    return <div className="ladiui modal modal-custom-2" id="modal-setting-change-group">
        <div className="ladiui modal-dialog modal-dialog-centered custom-popups-width">
            <div className="ladiui modal-content custom-ldp">
                <div className="ladiui modal-header custom-popupX">
                    <label className="ladiui title-text-form-lever1">Điều kiện chuyển nhóm Affiliate</label>
                    <button type="button" onClick={() => eventCancel()}
                        className="ladiui modal-close">
                        <i className="ladiui icon icon-ldp-close"></i>
                    </button>
                </div>
                <div className="ladiui form-group-custom">
                    <div className="ladiui flex-row">
                        <label className="ladiui title-text-form-lever2" >Danh sách điều kiện</label>
                    </div>
                    <div className="font-size-14">Bạn có thể thiết lập các điều kiện để nâng hoặc xuống hạng cho Affiliate</div>
                </div>
                {listConditionChangeGroup.map((conditionChangeGroup) => <ConditionChangeGroupBox
                    key={conditionChangeGroup.id}
                    conditionChangeGroupProp={conditionChangeGroup}
                    removeConditionChangeGroupProp={eventRemoveConditionChangeGroup}
                    cb={eventChangeConditionChangeGroup} />)}
                <a href="#/" className="ladiui proviso" onClick={eventAddMoreCondition}>
                    <img src="https://w.ladicdn.com/design-system/icons/icon-add-circle-outline.svg" alt="" />
                    <p className="ladiui proviso-text">Thêm điều kiện</p>
                </a>
                <div className="ladiui modal-footer custom-ldp-popupX mod">
                    <div className="ladiui modal-footer-link">
                    </div>
                    <div className="ladiui footer-modal-btn">
                        <button type="button" className="ladiui btn btn-secondary bold" onClick={() => eventCancel()}>{t('COMMON.CANCELED')}</button>
                        <button type="button" className="ladiui btn btn-primary" onClick={eventOk}>Cập nhật</button>
                    </div>
                </div>
            </div>
        </div>
    </div >;
}

export default (withTranslation()(ModalConditionChangeGroupAffiliate));
