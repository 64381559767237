import * as types from './types';
import * as commonTypes from '../common/types';

// eslint-disable-next-line max-lines-per-function
export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_LINK: {
            return {
                ...state,
                action: types.LIST_LINK,
                linkPublisher: action.status ? { items: action.payload.items, total: action.payload.total } : { items: [], total: 0 },
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_LINK: {
            return {
                ...state,
                action: types.SHOW_LINK,
                linkPublisherDetail: action.status ? action.payload.linkPublisher : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_LINK_PUBLISHER: {
            return {
                ...state,
                action: types.CREATE_LINK_PUBLISHER,
                linkPublisherCreated: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_LINK_PUBLISHER: {
            return {
                ...state,
                action: types.DELETE_LINK_PUBLISHER,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
