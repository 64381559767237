import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import appConfig from '../../config/app';
import ConfirmModal from '../../components/ConfirmModal';
import FilterCondition from './components/FilterCondition';

import LadiDateRangePicker from '../../components/LadiDateRangePicker';
import BaseHelper from '../../helpers/BaseHelper';
import ModalAffiliate from './components/ModalAffiliate';
import LadiPagination from '../../components/LadiPagination';

import actionAffiliate from '../../redux/futures/affiliate/actions';
import actionAffiliateGroup from '../../redux/futures/affiliate_group/actions';
import actionIntegration from '../../redux/futures/integrate_app/actions';
import CheckAllActionAffiliate from './components/CheckAllActionAffiliate';
import ExportAffiliate from './components/ExportAffiliate';
import DefineConst from './DefineConst';
import ModalAddAffiliate from './components/ModalAddAffiliate';
import ExportBankInfoAffiliate from './components/ExportBankInfoAffiliate';

// eslint-disable-next-line max-lines-per-function
function Affiliate(props) {
    const { t } = props;
    const emptyAffiliate = {
        affiliate_group_id: null,
        alias: '',
        code: '',
        created_at: '',
        creator_id: '',
        email: null,
        phone: null,
        fullname: null,
        other_info: null,
        is_delete: false,
        owner_id: '',
        status: appConfig.LADISHARE.AFFILIATE_STATUS[0].value,
        store_id: '',
        updated_at: '',
        _id: '',
    };
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { created_at: -1 },
    };

    const dispatch = useDispatch();
    const [sortDesc, setSortDesc] = useState(true);
    const [modalAffiliate, setModalAffiliate] = useState({ title: t('AFFILIATES.ADD_AFFILIATE'), nameButton: t('AFFILIATES.ADD_AFFILIATE') });
    const [affiliate, setAffiliate] = useState(emptyAffiliate);
    const [conditions, setConditions] = useState(defaultConditions);
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const moment = BaseHelper.getMoment();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const listAffiliate = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.affiliates.affiliates }));
    const loadingApiAffiliate = useSelector((state) => (state.affiliates.loading));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const cbListAffiliate = () => {
        setSelectedIds([]);
        setCheckAll(false);
        dispatch(actionAffiliate.list(conditions));
    };

    const openModalAffiliate = () => {
        dispatch(actionAffiliateGroup.list({ pluck: ['_id', 'name'] }));
        window.LadiUI.showModal('modal-add-affiliate');
    };

    const eventDeleteAffiliate = (affiliateDetail) => {
        setAffiliate(affiliateDetail);
    };

    const eventApprovedAffiliate = (item) => {
        setAffiliate(item);
        window.LadiUI.showModal('modal-approved-affiliate');
    };

    const eventLockAffiliate = (item) => {
        setAffiliate(item);
        window.LadiUI.showModal('modal-lock-affiliate');
    };

    const eventEditAffiliate = (affiliateDetail) => {
        dispatch(actionAffiliate.show(affiliateDetail._id));
        dispatch(actionAffiliateGroup.list({ pluck: ['_id', 'name'] }));
        setModalAffiliate({ title: t('AFFILIATES.EDIT_AFFILIATE'), nameButton: t('AFFILIATES.UPDATE') });
        window.LadiUI.showModal('white-page');
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const eventCheckAffiliate = (e) => {
        const id = e.target.value;
        if (e.target.checked) {
            setSelectedIds([...selectedIds, ...[id]]);
        } else {
            const ids = selectedIds.filter((item) => item !== id);
            setSelectedIds(ids);
        }
    };

    const eventCheckMultiAffiliate = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setSelectedIds(ids);
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const sortAffiliate = (sortCondition) => {
        setSortDesc(!sortDesc);
        setConditions({ ...conditions, ...{ sort: sortCondition } });
    };

    const filterActionProp = (conditionRecived) => {
        setConditions({ ...conditionRecived });
    };

    const eventOkDeleteAffiliate = () => {
        dispatch(actionAffiliate.delete({ id: affiliate._id }, cbListAffiliate));
    };

    const eventOkApprovedAffiliate = () => {
        dispatch(actionAffiliate.updateStatusMultiAffiliate({ ids: [affiliate._id], status: DefineConst.AFFILIATE_STATUS.ACTIVED }, cbListAffiliate));
    };

    const eventOkLockAffiliate = () => {
        dispatch(actionAffiliate.updateStatusMultiAffiliate({ ids: [affiliate._id], status: DefineConst.AFFILIATE_STATUS.LOCKED }, cbListAffiliate));
    };

    const eventExportData = () => {
        window.LadiUI.showModal('modal-export-data');
    };

    const actionExportProp = (actionExport) => {
        let exportCondition = { ...conditions };
        switch (actionExport) {
            case DefineConst.EXPORT_ACTIONS.FIRST_PAGE:
                exportCondition.page = 1;
                break;
            case DefineConst.EXPORT_ACTIONS.SELECTED_RECORDS:
                if (selectedIds.length === 0) {
                    window.LadiUI.toast('danger', '', 'Vui lòng chọn ít nhất 1 bản ghi', 5000, 'bottom-left');
                    return;
                }
                exportCondition = { ids: selectedIds.join(',') };
                break;
            case DefineConst.EXPORT_ACTIONS.ALL_RECORDS:
                delete exportCondition.page;
                delete exportCondition.limit;
                break;
            default:
        }
        dispatch(actionAffiliate.exportAffiliate(exportCondition));
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.AFFILIATE_STATUS.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-warning"></i>
                        <div>
                            Chờ duyệt
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.AFFILIATE_STATUS.ACTIVED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            Đã duyệt
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.AFFILIATE_STATUS.LOCKED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-mute"></i>
                        <div>
                            Đã khoá
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    const renderAffiliate = (item) => (<tr className="ladiui table-vertical main" key={item._id}>
        <td scope="row">
            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element"
                   name="checkbox" checked={selectedIds.includes(item._id)}
                   value={item._id} onChange={eventCheckAffiliate}/>
        </td>
        <td><Link to={`/affiliates/${item._id}`} className="ladiui link">{item.fullname}</Link></td>
        <td>{item.email}</td>
        <td>{item.phone}</td>
        <td>{item.code}</td>
        <td>{BaseHelper.formatStrToDate(item.created_at, 'DD/MM/YYYY')}</td>
        <td>{listAffiliate.findGroups.map((itemGroup) => (itemGroup._id === item.affiliate_group_id ? itemGroup.name : ''))}</td>
        <td style={{whiteSpace: 'nowrap'}}>
            {getStatus(item.status)}
        </td>
        <td>
            {item.type === DefineConst.AFFILIATE_TYPE.COMPANY ? 'Công ty' : 'Cá nhân'}
        </td>
        <td>{item.affiliate_ref ? `${item.affiliate_ref.code} - ${item.affiliate_ref.fullname}` : ''}</td>
        <td className="ladiui text-right">
            {BaseHelper.formatNumber(item.summary.total_lead || 0)}
        </td>
        <td className="ladiui text-right">
            {BaseHelper.formatNumber(item.number_click_links || 0)}
        </td>
        <td className="ladiui text-right">
            {BaseHelper.formatMoney(item.summary.total_order_value || 0, '', systemCurrency)}
        </td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(item.paid_money || 0, '', systemCurrency)}</td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(parseFloat(item.balance || 0) + parseFloat(item.paid_money || 0), '', systemCurrency)}</td>
        <td className="ladiui text-left">
            <div className="tooltip">
                <span className="affiliate-utm-source">{item.utm_source ? item.utm_source : ""}</span>
                <span className="tooltiptext left" tooltiptext={item.utm_source ? item.utm_source : ""}></span>
            </div>
        </td>
        <td className="ladiui-dropdown">
            <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                    <button data-toggle="dropdown"
                            className="ladiui-btn-dropdown dropdown-toggle ml-24">
                        <i className="ladiui icon icon-ldp-dot"></i>
                    </button>
                    <ul className="ladiui dropdown-menu custom-dropdown-menu"
                        aria-labelledby="dropdownMenuButton1">
                        <li onClick={() => eventEditAffiliate(item)}><a className="ladiui dropdown-item" href="#/">
                            {t('AFFILIATES.EDIT')}
                        </a></li>
                        {item.status === DefineConst.AFFILIATE_STATUS.PENDING
                            ? <li onClick={() => eventApprovedAffiliate(item)}><a className="ladiui dropdown-item"
                                                                                  href="#/">
                                Duyệt
                            </a></li>
                            : <></>}
                        {item.status !== DefineConst.AFFILIATE_STATUS.LOCKED
                            ?
                            <li onClick={() => eventLockAffiliate(item)}><a className="ladiui dropdown-item" href="#/">
                                Khoá
                            </a></li>
                            : <></>}
                        <li onClick={() => eventDeleteAffiliate(item)}>
                            <a className="ladiui dropdown-item lastChild btn-open-modal" data-toggle="modal"
                               data-target="#delete-modal" href="#/">
                                {t('AFFILIATES.DELETE')}
                            </a></li>

                    </ul>
                </div>
            </div>
        </td>
    </tr>);

    const cbResetFile = () => {
        document.getElementById('import-data-input').value = '';
    };

    const importFile = (event) => {
        const file = event.target.files[0];
        const form = new FormData();
        form.append('file', file, file.name);
        dispatch(actionAffiliate.importData(form, cbResetFile));
    };

    useEffect(() => {
        cbListAffiliate();
        dispatch(actionIntegration.list({ sort: { _id: -1 }, limit: 1000 }));
    }, []);

    useEffect(() => {
        setCheckAll(false);
        setSelectedIds([]);
        if (loadingApiAffiliate !== undefined) dispatch(actionAffiliate.list(conditions));
    }, [conditions]);

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui flex-row">
                    <div className="ladiui main-title">
                        {t('AFFILIATES.LIST_AFFILIATE')}
                    </div>
                    <div style={{ marginLeft: '22px' }}>
                        <LadiDateRangePicker
                            startDateId="fromDate"
                            endDateId="toDate"
                            startDate={fromDate}
                            endDate={toDate}
                            onDatesChange={({ startDate, endDate }) => {
                                const startDateNew = BaseHelper.getFromDate(startDate);
                                const endDateNew = BaseHelper.getToDate(endDate);
                                setFromDate(startDateNew);
                                setToDate(endDateNew);
                                setConditions({ ...conditions, ...{ date_from: startDateNew, date_to: endDateNew } });
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => {
                                setFocusedInput(focusedInput);
                            }}
                            showDefaultInputIcon={true}
                            inputIconPosition="after"
                            small={true}
                            hideKeyboardShortcutsPanel={true}
                            customInputIcon={
                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                            }
                            startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            isOutsideRange={() => false}
                            isDayHighlighted={(date) => moment().isSame(date, 'day')}
                            minimumNights={0}
                            showClearDates={true}
                        />
                    </div>

                </div>
                <div className="ladiui flex" style={{ gap: 12 }}>
                    <div className="ladiui">
                        <div className="ladiui dropdown">
                            <button className="ladiui btn btn-outline-primary dropdown-toggle import-data-btn" data-toggle="dropdown" style={{ minWidth: 0 }}>
                                Nhập/Xuất
                            </button>
                            <ul className="ladiui dropdown-menu">
                                <li onClick={() => { document.getElementById('import-data-input').click(); }}>
                                    <a className="ladiui dropdown-item" href="#/">Nhập danh sách</a>
                                    <input
                                        type="file"
                                        name="file"
                                        accept=".xlsx, .xls"
                                        style={{ display: 'none' }}
                                        id="import-data-input"
                                        onChange={(e) => importFile(e)} />
                                </li>
                                <li onClick={() => eventExportData()}>
                                    <a className="ladiui dropdown-item" href="#/">Xuất excel</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button type="button" className="ladiui btn btn-primary btn-lg" onClick={() => openModalAffiliate()}>
                        <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                        <div className="ladiui btn-custom-text ">{t('AFFILIATES.ADD_AFFILIATE')}</div>
                    </button>
                </div>
            </div>
            <div className="ladiui content-main-tool flex-row align-item-baseline">
                <div className="ladiui btn-group">
                    <FilterCondition conditionProp={conditions} filterActionProp={filterActionProp} />
                </div>
                <div className="ladiui search-group" style={{ minWidth: 'calc(100% - 210px)' }}>
                    <input id="keyword_search" className="ladiui search-field dropdown-toggle form-control search-width" name="keyword" placeholder="Tìm kiếm" aria-expanded="false"
                        defaultValue={conditions.keyword || ''} onKeyDown={eventSearchKeyboard} />
                    <i className="ladiui icon icon-search" onClick={() => eventClickSearchButton()}></i>
                </div>
            </div>
            <div className="ladi-card ladiui-table-responsive">
                <table className="ladiui table text-left"   >
                <thead>
                <tr className="ladiui table-vertical header">
                    <th className="ladiui col-first" scope="col">
                        <input type="checkbox"
                               className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-commission"
                               name="checkbox" checked={checkAll} onChange={eventCheckMultiAffiliate}/>
                    </th>
                    {
                        selectedIds.length > 0 ? < th scope="col" width="200">
                                {<CheckAllActionAffiliate ids={selectedIds} cb={cbListAffiliate}/>}
                            </th>
                            : <th scope="col" width="200"
                                  className={`ladiui text-pre sort ${conditions.sort.fullname ? (sortDesc ? 'sort-asc' : 'sort-desc') : ''}`}
                                  onClick={() => {
                                      sortAffiliate({fullname: sortDesc ? -1 : 1});
                                  }}>{t('AFFILIATES.NAME')}<span className="sort-icon"></span></th>
                    }
                    <th scope="col"
                        className={`ladiui text-pre sort ${conditions.sort.email ? (sortDesc ? 'sort-asc' : 'sort-desc') : ''}`}
                        onClick={() => {
                            sortAffiliate({email: sortDesc ? -1 : 1});
                        }}>{t('AFFILIATES.EMAIL')}<span className="sort-icon"></span></th>
                    <th scope="col"
                        className={`ladiui text-pre sort ${conditions.sort.phone ? (sortDesc ? 'sort-asc' : 'sort-desc') : ''}`}
                        onClick={() => {
                            sortAffiliate({phone: sortDesc ? -1 : 1});
                        }}>Số ĐT<span className="sort-icon"></span></th>
                    <th scope="col"
                        className={`ladiui text-pre sort ${conditions.sort.code ? (sortDesc ? 'sort-asc' : 'sort-desc') : ''}`}
                        onClick={() => {
                            sortAffiliate({code: sortDesc ? -1 : 1});
                        }}>{t('AFFILIATES.CODE')}<span className="sort-icon"></span></th>
                    <th scope="col"
                        className={`ladiui text-pre sort ${conditions.sort.created_at ? (sortDesc ? 'sort-asc' : 'sort-desc') : ''}`}
                        onClick={() => {
                            sortAffiliate({created_at: sortDesc ? -1 : 1});
                        }}>{t('AFFILIATES.JOIN_AT')}<span className="sort-icon"></span></th>
                    <th scope="col">{t('AFFILIATES.GROUP')}</th>
                    <th scope="col">{t('AFFILIATES.STATUS_LABEL')}</th>
                    <th scope="col" className="ladiui text-pre">Loại affiliate</th>
                    <th scope="col" className="ladiui text-pre">Người giới thiệu</th>
                    <th scope="col" className="ladiui text-right">Lead</th>
                    <th scope="col" className="ladiui text-right">Lượt giới thiệu</th>
                    <th scope="col" className="ladiui text-right text-pre">Doanh thu</th>
                    <th scope="col" className="ladiui text-right">{t('AFFILIATES.PURCHASE')}</th>
                    <th scope="col" className="ladiui text-right">{t('AFFILIATES.COMMISSION')}</th>
                    <th scope="col" className="ladiui text-left text-pre">UTM Source</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                    <tbody>
                    {listAffiliate.items.length > 0
                        ? listAffiliate.items.map((values) => (renderAffiliate(values)))
                        : (<tr className="ladiui table-vertical main"><td colSpan="11" className="text-center"><div>{t('AFFILIATES.NO_AFFILIATE')}</div></td></tr>)}
                </tbody>
                <tfoot>
                    <tr className="ladiui table-vertical summary">
                        <td colSpan={12}><span className="bold-500 ml-32">{t('AFFILIATES.TOTAL_ALL')}</span></td>
                        <td className="ladiui text-right text-pre" colSpan="2">
                            <span className="bold-500">
                                {listAffiliate.summary.totalPaidMoney
                                    ? BaseHelper.formatMoney(listAffiliate.summary.totalPaidMoney, '', systemCurrency)
                                    : BaseHelper.formatMoney(0, '', systemCurrency)}
                            </span>
                        </td>
                        <td className="ladiui text-right text-pre">
                            <span className="bold-500">
                                {listAffiliate.summary
                                    ? BaseHelper.formatMoney(listAffiliate.summary.totalPaidMoney + listAffiliate.summary.totalBalance, '', systemCurrency)
                                    : BaseHelper.formatMoney(0, '', systemCurrency)}
                            </span>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
            </div>

            {listAffiliate.items.length > 0
                ? <LadiPagination
                    conditionsProp={conditions}
                    listName={t('AFFILIATES.AFFILIATE')}
                    items={listAffiliate.items}
                    total={listAffiliate.total}
                    limitAction={selectLimit}
                    pageAction={selectPage}
                /> : ''}

            <ModalAffiliate
                title={modalAffiliate.title}
                btnOk={modalAffiliate.nameButton}
                btnCancel={t('AFFILIATES.CANCELED')}
                cb={cbListAffiliate} />

            <ModalAddAffiliate
                cb={cbListAffiliate} />

            <ConfirmModal
                id="delete-modal"
                title="Bạn có chắc muốn xoá Affiliate này không?"
                content={<><div>Sau khi xoá Affiliate sẽ bị xoá vĩnh viễn bạn sẽ không thể xem lại được nữa.</div><div>Các thông tin liên quan tới affiliate như hoa hồng, hoá đơn, yêu cầu rút tiền,... sẽ bị xoá và không thể khôi phục.</div></>}
                onOk={() => eventOkDeleteAffiliate()}
                cancelText={t('AFFILIATES.CANCELED')}
                okText={t('AFFILIATES.DELETE_AFFILIATE')}
                classBtn="btn-danger"
            />

            <ConfirmModal
                id="modal-approved-affiliate"
                title="Bạn có chắc muốn duyệt Affiliate này không?"
                content="Bạn có thể thay đổi trạng thái bất cứ lúc nào"
                onOk={() => eventOkApprovedAffiliate()}
                cancelText={t('AFFILIATES.CANCELED')}
                okText={t('COMMON.OK')}
                classBtn="btn-primary"
            />

            <ConfirmModal
                id="modal-lock-affiliate"
                title="Bạn có chắc muốn khoá Affiliate này không?"
                content="Bạn có thể thay đổi trạng thái bất cứ lúc nào"
                onOk={() => eventOkLockAffiliate()}
                cancelText={t('AFFILIATES.CANCELED')}
                okText={t('COMMON.OK')}
                classBtn="btn-primary"
            />

            <ExportAffiliate actionExportProp={actionExportProp} />

            <div className="ladiui modal" id="modal-invoice-selected-affiliate-redirect">
                <div className="ladiui modal-dialog modal-sm">
                    <div className="ladiui modal-content custom-ldp">
                        <div className="ladiui modal-header custom-ldp">
                            <label className="ladiui title-text-form-lever1">Xem danh sách hoá đơn?</label>
                            <button type="button" data-dismiss="modal" data-target="modal-export-commission" className="ladiui modal-close">
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>

                        <div className="ladiui" style={{ fontWeight: '400' }}>
                            <div>
                                Bạn có muốn xem danh sách các hoá đơn vừa tạo?
                            </div>
                        </div>

                        <div className="ladiui modal-footer custom-ldp">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button type="button" data-dismiss="modal" data-target="modal-export-commission"
                                    className="ladiui btn btn-secondary bold">{t('COMMISSIONS.CANCELED')}</button>
                                <a className="ladiui btn btn-primary" href="/invoices">Xem danh sách</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </div>
    </>;
}

export default (withTranslation()(Affiliate));
