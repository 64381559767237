import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LadiDropdown from '../../../../components/LadiDropdown';

function SKUs(props) {
    const { t, skusProp, cb } = props;
    const [skus, setSkus] = useState({ include: true, skus: [] });
    const [sendData, setSendData] = useState(false);

    const conditionSku = [
        { name: 'Bao gồm', value: true },
        { name: 'Loại trừ', value: false },
    ];

    const eventChangeSkus = (e) => {
        const data = e.target.value.split(',');
        setSkus({ ...skus, skus: data });
    };

    const eventSelectConditionSku = (value) => {
        setSkus({ ...skus, include: value });
    };

    useEffect(() => {
        setSendData(true);
    }, []);

    useEffect(() => {
        setSkus((_.isNull(skusProp) || _.isEmpty(skusProp)) ? { include: true, skus: [] } : skusProp);
        if (_.isEmpty(skusProp)) {
            setSendData(true);
        }
    }, [skusProp]);

    useEffect(() => {
        if (sendData) cb(skus);
    }, [skus]);

    return <>
        <div className="ladiui clearfix pt-20">
            <div className="ladiui left mr-8">
                <LadiDropdown
                    labelDropdown={t('COMMISSIONS.SELECT_CONDITION')}
                    listProp={conditionSku}
                    valueProp={skus.include}
                    hideLabel={false}
                    validProp={true}
                    classDropdown="dropdown-custom w-180"
                    classSize="btn-sm"
                    cbProp={eventSelectConditionSku}
                />
                <div className="group-input-custom custom-form-dynamic" style={{ width: '175px', marginTop: '4px' }}>
                    <textarea className="ladiui form-control " value={skus && skus.skus ? skus.skus.join(',') : ''} onChange={eventChangeSkus} placeholder="Danh sách SKU ngăn cách bằng dấu phẩy" />
                </div>
            </div>
        </div>
    </>;
}
export default (withTranslation()(SKUs));
