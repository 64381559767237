import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LadiDateRangePicker from '../../../components/LadiDateRangePicker';
import BaseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import LadiPagination from '../../../components/LadiPagination';

// eslint-disable-next-line max-lines-per-function
function Invoice(props) {
    const { t } = props;

    const search = new URLSearchParams(window.location.search);
    const affiliateId = search.get('id');

    const moment = BaseHelper.getMoment();
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.DEFAULT_LIMIT,
        sort: { created_at: -1 },
        ids: affiliateId,
        date_from: moment().startOf('month').format('YYYY-MM-DD 00:00:01'),
        date_to: moment().format('YYYY-MM-DD 23:59:59'),
    };
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(moment().startOf('month'));
    const [toDate, setToDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);
    const [conditions, setConditions] = useState(defaultConditions);
    const [affiliate, setAffiliate] = useState({});
    const [affiliateConditions, setAffiliateConditions] = useState(defaultConditions);

    const listAffiliate = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.affiliates.affiliates }));
    const listAffiliateFilter = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.affiliates.filterAffiliates }));
    const affiliateData = useSelector((state) => state.affiliates.affiliate);
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventSelectAffiliate = (item) => {
        if (item) {
            setConditions({ ...conditions, ids: item._id });
            setAffiliate(item);
        } else {
            setConditions({ ...conditions, ids: null });
            setAffiliate({});
        }
    };

    const eventClickShowListAffiliate = () => {
        dispatch(actionAffiliate.listFilter(affiliateConditions));
    };

    const eventKeyDownInputSearch = (e) => {
        if (e.keyCode === 13) dispatch(actionAffiliate.listFilter(affiliateConditions));
    };

    useEffect(() => {
        dispatch(actionAffiliate.list(conditions));
    }, [conditions]);

    useEffect(() => {
        if (affiliateData) setAffiliate(affiliateData);
    }, [affiliateData]);

    return <>
        <div className="ladiui content-main-tool flex-row align-item-baseline mt-24" style={{ justifyContent: 'space-between' }}>
            <div className="ladiui flex-row">
                <LadiDateRangePicker
                    startDateId="fromDate"
                    endDateId="toDate"
                    startDate={fromDate}
                    endDate={toDate}
                    onDatesChange={({ startDate, endDate }) => {
                        const startDateNew = BaseHelper.getFromDate(startDate);
                        const endDateNew = BaseHelper.getToDate(endDate);
                        setFromDate(startDateNew);
                        setToDate(endDateNew);
                        if ((startDateNew && endDateNew) || (!startDateNew && !endDateNew)) {
                            setConditions({ ...conditions, ...{ date_from: startDateNew, date_to: endDateNew } });
                        }
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => {
                        setFocusedInput(focusedInput);
                    }}
                    showDefaultInputIcon={true}
                    inputIconPosition="after"
                    small={true}
                    hideKeyboardShortcutsPanel={true}
                    customInputIcon={
                        <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                    }
                    startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                    endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                    isOutsideRange={() => false}
                    isDayHighlighted={(date) => moment().isSame(date, 'day')}
                    minimumNights={0}
                    showClearDates={true}
                />
                <div>
                    <div className="ladiui select-search dropdown">
                        <button
                            className={'ladiui btn btn-outline-light dropdown-toggle'}
                            data-toggle="dropdown"
                            style={{ height: '38px', marginLeft: '12px', width: '200px' }}
                            onClick={() => eventClickShowListAffiliate()}
                        >
                            <i></i>
                            <span className="ladiui dropdown-text" style={{ marginRight: 0 }}>
                                {affiliate.email || 'Chọn affiliate'}
                            </span>
                        </button>
                        <ul className="ladiui dropdown-menu dropdown-right" style={{ width: '100%' }}>
                            <li className="ladiui search-group custom-people">
                                <input
                                    className="ladiui search-field dropdown-toggle form-control custom-people"
                                    onChange={(e) => setAffiliateConditions({ ...affiliateConditions, keyword: e.target.value })}
                                    onKeyDown={(e) => eventKeyDownInputSearch(e)}
                                    placeholder="Tìm kiếm"
                                />
                                <i className="ladiui icon icon-search" onClick={() => dispatch(actionAffiliate.listFilter(affiliateConditions))}></i>
                            </li>
                            <li onClick={() => eventSelectAffiliate(null)}>
                                <a className="ladiui dropdown-item" href="#/">Tất cả affiliate</a>
                            </li>
                            {listAffiliateFilter.total > 0
                                ? listAffiliateFilter.items.map((item) => <li key={item._id}
                                    onClick={() => eventSelectAffiliate(item)} >
                                    <a className="ladiui dropdown-item" href="#/">
                                        {item.fullname} ({item.code}) - {item.email}
                                    </a>
                                </li>)
                                : <li>
                                    <a className="ladiui dropdown-item" href="#/">Không tìm thấy kết quả</a>
                                </li>
                            }
                            {listAffiliateFilter.total > listAffiliateFilter.items.length
                                ? <li>
                                    <label className="ladiui search-checkbox default-text" ><a>...</a></label>
                                </li>
                                : <></>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <table className="ladiui table text-left">
            <thead>
                <tr className="ladiui table-vertical header">
                    <th scope="col">Affiliate</th>
                    <th scope="col">Lần thanh toán cuối</th>
                    <th scope="col">Dịch vụ thanh toán</th>
                    <th scope="col">Tổng đã thanh toán</th>
                    <th scope="col">Tổng tiền còn lại</th>
                </tr>
            </thead>
            <tbody>
                {listAffiliate.items.length > 0 ? listAffiliate.items.map((item) => (<tr key={item._id} className="ladiui table-vertical main">
                    <td><Link to={`/affiliates/${item._id}`} className="ladiui link">{item.fullname}</Link></td>
                    <td>
                        {BaseHelper.formatStrToDate(item.last_invoice || null)}
                    </td>
                    <td>
                        Banking
                    </td>
                    <td>{BaseHelper.formatMoney(item.paid_money || 0, '', systemCurrency)}</td>
                    <td>{BaseHelper.formatMoney(item.balance || 0, '', systemCurrency)}</td>
                </tr>))
                    : <tr><td colSpan={5}>{t('NO_DATA')}</td></tr>}

            </tbody>
        </table>
        {
            listAffiliate.items.length > 0
                ? <LadiPagination
                    conditionsProp={conditions}
                    listName={t('AFFILIATES.AFFILIATE')}
                    items={listAffiliate.items}
                    total={listAffiliate.total}
                    limitAction={selectLimit}
                    pageAction={selectPage}
                /> : ''
        }
    </>;
}

export default (withTranslation()(Invoice));
