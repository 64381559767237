import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BaseHelper from '../../helpers/BaseHelper';
import actions from '../../redux/futures/store/actions';

// eslint-disable-next-line max-lines-per-function
function IPSetting(props) {
    const { t } = props;
    const dispatch = useDispatch();
    const blockIPs = useSelector((state) => (state.store.blockIPs));
    const [storeSetting, setStoreSetting] = useState({});

    const cbLoad = () => {
        const data = { field: 'block_ips' };
        dispatch(actions.getBlockIPSetting(data));
    };

    const updateIPSetting = () => {
        dispatch(actions.updateBlockIPSetting(storeSetting));
    };

    useEffect(() => {
        cbLoad();
    }, []);

    useEffect(() => {
        setStoreSetting({ block_ips: blockIPs });
    }, [blockIPs]);

    return <>
        <div className="ladiui content-main-title flex-row">
            <div className="ladiui">
                <div className="ladiui main-title">
                    Blacklist IP
                </div>
                <div className="mt-24" style={{ lineHeight: '24px' }}>
                    Tính năng này cho phép bạn chặn các yêu cầu đến từ 1 danh sách IP. Các yêu cầu đến từ 1 trong những IP trong danh sách dưới đây sẽ không được xử lý bao gồm Thông tin Đơn hàng và Hoa hồng.<br />
                    - Bạn có thể thiết lập theo dải IP bằng cách sử dụng dấu *.<br />
                    - Mỗi IP sẽ nằm trên một dòng.
                </div>
                <div className="ladiui form-group" style={{ position: 'relative', width: '614px' }}>
                    <textarea className="ladiui form-control" rows={12} onChange={(e) => setStoreSetting({ block_ips: e.target.value })} value={storeSetting.block_ips} />
                </div>
                <div className="ladiui affiliate-setting-content">
                    <div className="ladiui form-group">
                        <button className="ladiui btn btn-primary right" onClick={updateIPSetting}>Cập nhật</button>
                        <button className="ladiui btn right btn-secondary" style={{ marginRight: '40px' }} onClick={() => setStoreSetting({ block_ips: blockIPs })}>Hủy bỏ</button>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(IPSetting));
