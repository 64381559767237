import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BaseHelper from '../../../helpers/BaseHelper';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import DefineConst from '../../affiliates/DefineConst';

function RecentlyAffiliate(props) {
    const { t } = props;

    const defaultConditions = {
        page: 1,
        limit: 5,
        sort: { created_at: -1 },
    };

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState(defaultConditions);

    const listAffiliate = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.affiliates.affiliates }));

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.AFFILIATE_STATUS.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-warning"></i>
                        <div>
                            Đăng ký mới
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.AFFILIATE_STATUS.ACTIVED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            Đang hoạt động
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.AFFILIATE_STATUS.LOCKED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-mute"></i>
                        <div>
                            Đã khoá
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    useEffect(() => {
        dispatch(actionAffiliate.list(conditions));
    }, []);

    return <>
        <div className="ladiui box-statistics box-no-padding box-no-heigh" style={{ flex: '1 1 auto', display: 'block' }}>
            <div className="ladiui box-title flex-row">
                <label className="ladiui">5 Affiliates mới đăng ký</label>
                <Link to='/affiliates' className="ladiui link">Xem thêm &rsaquo;</Link>
            </div>
            <div className="box-body" style={{ padding: '0 24px' }}>
                <table className="ladiui table text-left">
                    <thead>
                        <tr className="ladiui table-vertical header">
                            <th scope="col">Affiliate</th>
                            <th scope="col">Ngày tham gia</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listAffiliate.items ? listAffiliate.items.map((item) => (
                                <tr key={item._id} className="ladiui table-vertical main">
                                    <td>
                                        <Link to={`/affiliates/${item._id}`} className="ladiui link">{item.email}</Link>
                                    </td>
                                    <td>
                                        {BaseHelper.formatStrToDate(item.created_at, 'DD/MM/YYYY')}
                                    </td>
                                    <td>
                                        {getStatus(item.status)}
                                    </td>
                                </tr>
                            )) : <tr className="ladiui table-vertical main">
                                <td colSpan={3}>
                                    {t('NO_DATA')}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="box-footer"></div>
        </div>
    </>;
}

export default (withTranslation()(RecentlyAffiliate));
