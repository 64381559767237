import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import AffiliateDropdownMultiSelect from '../../commissions/components/AffiliateDropdownMultiSelect';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import config from '../../../config/config';

// eslint-disable-next-line max-lines-per-function
function FilterCondition(props) {
    const { t, conditionProp, filterActionProp } = props;

    const dispatch = useDispatch();
    const [attributeFilter, setAttributeFilter] = useState(null);
    const [conditionFilter, setConditionFilter] = useState(null);
    const [valueFilter, setValueFilter] = useState(null);
    const [allConditionSelect, setAllConditionSelect] = useState([]);
    const [showListFilter, setShowListFilter] = useState(false);
    const [conditionAffiliate, setConditionAffiliate] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { created_at: -1 },
    });

    const affiliates = useSelector((state) => (state.affiliates.affiliates));

    const eventCloseFilterCondition = () => {
        setAttributeFilter(null);
        setConditionFilter(null);
        setValueFilter(null);
        setShowListFilter(false);
    };

    const eventFilterCondition = () => {
        if (!attributeFilter || !conditionFilter || !valueFilter) {
            return;
        }
        const allCondition = allConditionSelect.filter((value) => value.attributeFilter.value !== attributeFilter.value);

        const newAllConditionFilter = [...allCondition, ...[{ attributeFilter, conditionFilter, valueFilter }]];
        setAllConditionSelect(newAllConditionFilter);

        const filterItems = {};

        filterItems[attributeFilter.value] = {};
        if (conditionFilter.value === '$regex') {
            filterItems[attributeFilter.value][conditionFilter.value] = valueFilter.value;
            filterItems[attributeFilter.value].$options = 'i';
        } else if (conditionFilter.value === '$notRegex') {
            // filterItems[attributeFilter.value].$not = { $regex: valueFilter.value, $options: 'i' };
            filterItems[attributeFilter.value] = { $regex: `^((?!${valueFilter.value}).)*$`, $options: 'i' };
        } else if (conditionFilter.value === '$e') {
            filterItems[attributeFilter.value] = valueFilter.value;
        } else {
            filterItems[attributeFilter.value][conditionFilter.value] = valueFilter.value;
        }
        const conditionSend = { ...conditionProp, ...filterItems };
        filterActionProp(conditionSend);
        eventCloseFilterCondition();
    };

    const eventRemoveFilter = (attributeFilterValue) => {
        const allCondition = allConditionSelect.filter((value) => value.attributeFilter.value !== attributeFilterValue);
        setAllConditionSelect(allCondition);
        delete conditionProp[attributeFilterValue];
        filterActionProp(conditionProp);
    };

    const eventSetAttributeFilter = (attributeFilter) => {
        setAttributeFilter(attributeFilter);
        setConditionFilter(null);
        setValueFilter(null);
    };

    document.addEventListener('click', (event) => {
        const box = document.getElementById('filter-condtion-area');
        if (box && !box.contains(event.target)) {
            // setAttributeFilter(null);
            // setConditionFilter(null);
            // setValueFilter(null);
            // setShowListFilter(false);
        }
    });

    const selectMultiDropdownAffiliate = (data) => {
        let values = [];
        let names = [];
        for (let i = 0; i < data.length; i++) {
            values.push(data[i]._id);
            names.push(data[i].email);
        }
        setValueFilter({ name: names.join(', '), value: values });
    };

    const cbListAffiliateDropdown = (keyword, idIgnore = []) => {
        const dataConditions = { ...conditionAffiliate, keyword };
        if (idIgnore.length > 0) {
            dataConditions._id = { $nin: idIgnore };
        }
        dispatch(actionAffiliate.list(dataConditions));
    };

    const renderStatusCondition = () => <>
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: 'là', value: '$regex' })}><a className="ladiui dropdown-item" href="#/">{'là'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'không phải', value: '$notRegex' })}><a className="ladiui dropdown-item" href="#/">{'không phải'}</a></li>
                </ul>
            </div>
        </div>
        <div className="ladiui btn-group">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">
                        {valueFilter ? valueFilter.name : t('COMMON.SELECT_STATUS')}
                    </span>
                </button>
                <ul className="ladiui dropdown-menu">
                    {appConfig.LADISHARE.INVOICE_REQUEST_STATUS.map((item) => <li key={item.value}
                        onClick={() => setValueFilter({ name: item.name, value: item.value })}>
                        <a className="ladiui dropdown-item" href="#/">{item.name}</a>
                    </li>)}
                </ul>
            </div>
        </div>
    </>;

    const renderSalesCondition = () => <>
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: 'bằng', value: '$e' })}><a className="ladiui dropdown-item" href="#/">{'bằng'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'lớn hơn hoặc bằng', value: '$gte' })}><a className="ladiui dropdown-item" href="#/">{'lớn hơn hoặc bằng'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'nhỏ hơn hoặc bằng', value: '$lte' })}><a className="ladiui dropdown-item" href="#/">{'nhỏ hơn hoặc bằng'}</a></li>
                </ul>
            </div>
        </div>
        <div>
            <input type="number" className="ladiui form-control" placeholder="0" min={0}
                value={valueFilter ? valueFilter.value : ''}
                onChange={(e) => setValueFilter({ name: e.target.value, value: parseFloat(e.target.value || 0) })} />
        </div>
    </>;

    const renderAffiliateSelectDropdown = () => <>
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: 'là', value: '$in' })}><a className="ladiui dropdown-item" href="#/">{'là'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'không phải', value: '$nin' })}><a className="ladiui dropdown-item" href="#/">{'không phải'}</a></li>
                </ul>
            </div>
        </div>
        <AffiliateDropdownMultiSelect unCheckedListProp={affiliates ? affiliates.items : []} cb={selectMultiDropdownAffiliate} cbList={cbListAffiliateDropdown} />
    </>;

    const renderDetailCondition = (condition) => {
        switch (condition) {
            case 'status': return renderStatusCondition();
            case 'amount': return renderSalesCondition();
            case 'aff_ids': return renderAffiliateSelectDropdown();
            default: return '';
        }
    };

    useEffect(() => {
        if (attributeFilter && attributeFilter.value === 'aff_ids') {
            cbListAffiliateDropdown('', []);
        }
    }, [attributeFilter]);

    return <>
        <div id="filter-condtion-area" className={allConditionSelect.length > 0 ? 'filter-condtion-area mr-24 mb-20' : 'filter-condtion-area mr-24'}>
            <button className="ladiui btn btn-outline-light" onClick={() => setShowListFilter(!showListFilter)}>
                <img src="https://w.ladicdn.com/design-system/icons/icon-filter.svg" style={{ marginBottom: '2px', marginRight: '10px' }} />Thêm điều kiện lọc
            </button>
            <div className={showListFilter ? 'list-filter-condition pt-0 show' : 'list-filter-condition pt-0'}>
                <div className="ladiui flex-row mt-0 content-condition-filter">
                    <div className="mr-8">
                        <div className="ladiui btn-group">
                            <div className="ladiui dropdown">
                                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                                    data-toggle="dropdown">
                                    <i className="ladiui icon"></i>
                                    <span className="ladiui dropdown-text">
                                        {attributeFilter ? attributeFilter.name : 'Chọn tiêu chí'}
                                    </span>
                                </button>
                                <ul className="ladiui dropdown-menu">
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Trạng thái', value: 'status' })}>
                                        <a className="ladiui dropdown-item" href="#/">Trạng thái</a>
                                    </li>
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Hoa hồng', value: 'amount' })}>
                                        <a className="ladiui dropdown-item" href="#/">Hoa hồng</a>
                                    </li>
                                    {config.APP_AFFILIATE
                                        ? <></>
                                        : <li onClick={() => eventSetAttributeFilter({ name: 'Affiliate', value: 'aff_ids' })}>
                                            <a className="ladiui dropdown-item" href="#/">Affiliate</a>
                                        </li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {attributeFilter ? renderDetailCondition(attributeFilter.value) : ''}
                </div>
                <div className="ladiui footer-list-filter-condition flex-row">
                    <button type="button" className="ladiui btn btn-secondary btn-sm mr-8" onClick={() => eventCloseFilterCondition()}>Hủy</button>
                    <button type="button" className="ladiui btn btn-primary btn-sm" onClick={() => eventFilterCondition()}>Áp dụng</button>
                </div>
                <div className="clearfix"></div>
            </div>
            <div className="ladiui result-filter-condition flex-row">
                {allConditionSelect.map((item, index) => <span className="ladiui btn-tag" key={index}>
                    {item.attributeFilter.name} {item.conditionFilter.name} {item.valueFilter.name}
                    <img className="cursor-pointer"
                        style={{ width: '12px', marginLeft: '8px' }}
                        src="https://w.ladicdn.com/design-system/icons/icon-close.svg"
                        onClick={() => eventRemoveFilter(item.attributeFilter.value)} />
                </span>)}
            </div>
        </div>
    </>;
}

export default (withTranslation()(FilterCondition));
