import React from 'react';
import { withTranslation } from 'react-i18next';
import AffiliateConfigSetting from './components/AffiliateConfigSetting';
import AffiliateDashboardSetting from './components/AffiliateDashboardSetting';

// eslint-disable-next-line max-lines-per-function
function AffiliateSetting(props) {
    const { t } = props;

    return <>
        <div className="ladiui content-main-title flex-row">
            <div className="ladiui">
                <div className="ladiui main-title">
                    {t('STORES.AFFILIATE_SETTING')}
                </div>
            </div>
        </div>
        <div className="ladiui action-history">
            <ul className="ladiui nav nav-tabs new-tab-custom" role="tablist">
                <li className="ladiui nav-item" role="presentation">
                    <span className="ladiui tab-link active" data-toggle="tab"
                        data-parent="myTab" data-target="tab-aff-config-setting">Thiết lập cấu hình</span>
                </li>
                <li className="ladiui nav-item" role="presentation">
                    <span className="ladiui tab-link" data-toggle="tab" data-parent="myTab"
                        data-target="tab-aff-dashboard-setting">Thiết lập giao diện</span>
                </li>
            </ul>
            <div className="ladiui tab-content" id="tab-detail-affiliate-content">
                <div className="ladiui tab-pane fade show open" id="tab-aff-config-setting" role="tabpanel"
                    aria-labelledby="tab-config-email">
                    <AffiliateConfigSetting />
                </div>
                <div className="ladiui tab-pane fade" id="tab-aff-dashboard-setting" role="tabpanel"
                    aria-labelledby="tab-config-email">
                    <AffiliateDashboardSetting />
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(AffiliateSetting));
