import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';

function CommissionParent(props) {
    const { settingData, commissionCalcBy, updateSettingProp } = props;

    const [settingParentLevel, setSettingParentLevel] = useState(null);

    const changeSettingParentLevel = (data) => {
        const items = { ...settingParentLevel, ...data };
        setSettingParentLevel(items);
        updateSettingProp(items);
    };

    useEffect(() => {
        if (settingData) setSettingParentLevel(settingData);
    }, [settingData]);

    return settingParentLevel
        ? <div className="ladiui">
            <label className="ladiui title-text-form-lever3" >Hoa hồng cấp trên bậc {settingParentLevel.level || ''}</label>
            <div className="group-input-custom custom-form-dynamic">
                <NumericFormat name="level_all"
                    value={settingParentLevel.parent_calc_number || ''}
                    allowLeadingZeros
                    allowNegative={false}
                    decimalScale={2}
                    thousandSeparator=","
                    className="ladiui form-control"
                    placeholder="0"
                    onValueChange={(values) => changeSettingParentLevel({ parent_calc_number: values.value })}
                />
                <div className="ladiui btn-group btn-group-custom" style={{ maxWidth: '40px' }}>
                    <div className="ladiui btn-group">
                        <div className="ladiui dropdown">
                            <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown btn-sm show"
                                data-toggle="dropdown" style={{ maxWidth: '45px' }}>
                                <i></i>
                                <span className="ladiui dropdown-text font-size-11">
                                    {settingParentLevel.parent_calc_unit === 'FIXED' ? 'VND' : '%'}
                                </span>
                            </button>
                            <ul className="ladiui dropdown-menu">
                                {commissionCalcBy !== 'BY_COMMISSION'
                                    ? <li onClick={() => changeSettingParentLevel({ parent_calc_unit: 'FIXED' })}>
                                        <a className="ladiui dropdown-item font-size-11" href="#/"
                                        >VND</a>
                                    </li> : <></>}
                                <li onClick={() => changeSettingParentLevel({ parent_calc_unit: 'PERCENT' })}>
                                    <a className="ladiui dropdown-item font-size-11" href="#/"
                                    >%</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> : <></>;
}

export default (withTranslation()(CommissionParent));
