import * as types from './types';
import * as commonTypes from '../common/types';

// eslint-disable-next-line max-lines-per-function
export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_ACTIVITY: {
            return {
                ...state,
                action: types.LIST_ACTIVITY,
                activities: action.status ? { items: action.payload.items, staffs: action.payload.staffs, total: action.payload.total }
                    : { items: [], staffs: [], total: 0 },
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
