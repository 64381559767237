export const LIST_TRAFFIC = 'LIST_TRAFFIC';
export const LIST_ORDER = 'LIST_ORDER';
export const LIST_LEAD = 'LIST_LEAD';
export const LIST_NEW_CUSTOMER = 'LIST_NEW_CUSTOMER';
export const LIST_PRODUCT = 'LIST_PRODUCT';
export const LIST_VISITOR = 'LIST_VISITOR';
export const CREATE_TRAFFIC = 'CREATE_TRAFFIC';
export const SHOW_TRAFFIC = 'SHOW_TRAFFIC';
export const UPDATE_TRAFFIC = 'UPDATE_TRAFFIC';
export const DELETE_TRAFFIC = 'DELETE_TRAFFIC';
export const CALCULATE_COMMISSION_FOR_TRAFFIC = 'CALCULATE_COMMISSION_FOR_TRAFFIC';
export const RE_CALCULATE_COMMISSION_FOR_TRAFFIC = 'RE_CALCULATE_COMMISSION_FOR_TRAFFIC';
export const CANCEL_COMMISSION_FOR_TRAFFIC = 'CANCEL_COMMISSION_FOR_TRAFFIC';
