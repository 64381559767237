import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.PUBLISHER_LOGIN: {
            return {
                ...state,
                action: types.PUBLISHER_LOGIN,
                publisher: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.PUBLISHER_REGISTER: {
            return {
                ...state,
                action: types.PUBLISHER_REGISTER,
                publisher: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.PUBLISHER_FORGOT_PASSWORD: {
            return {
                ...state,
                action: types.PUBLISHER_FORGOT_PASSWORD,
                emailPublisher: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.PUBLISHER_RESET_PASSWORD: {
            return {
                ...state,
                action: types.PUBLISHER_RESET_PASSWORD,
                pwPublisher: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.CAMPAIGN_SHARE_DETAIL: {
            return {
                ...state,
                action: types.CAMPAIGN_SHARE_DETAIL,
                campaignShare: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
