import * as types from './types';
import * as commonTypes from '../common/types';

// eslint-disable-next-line max-lines-per-function
export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_COMMISSION: {
            return {
                ...state,
                action: types.LIST_COMMISSION,
                commissions: action.status ? { items: action.payload.items, total: action.payload.total, summary: action.payload.summary } : { items: [], total: 0, summary: {} },
                affiliates: action.status ? action.payload.affiliates : [],
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_COMMISSION: {
            return {
                ...state,
                action: types.CREATE_COMMISSION,
                commissionCreated: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_COMMISSION: {
            return {
                ...state,
                action: types.SHOW_COMMISSION,
                commission: action.status ? action.payload.commission : {},
                commissionPlan: action.status ? action.payload.commissionPlan : {},
                commissionPlanPayment: action.status ? action.payload.commissionPlanPayment : {},
                histories: action.status ? action.payload.histories : [],
                affiliate: action.status ? action.payload.affiliate : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_COMMISSION: {
            return {
                ...state,
                action: types.UPDATE_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_STATUS_COMMISSION: {
            return {
                ...state,
                action: types.UPDATE_STATUS_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.BACK_TO_PENDING_STATUS_COMMISSION: {
            return {
                ...state,
                action: types.BACK_TO_PENDING_STATUS_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_COMMISSION: {
            return {
                ...state,
                action: types.DELETE_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.EXPORT_COMMISSION: {
            return {
                ...state,
                action: types.EXPORT_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.EXPORT_CONFIRM_COMMISSION: {
            return {
                ...state,
                action: types.EXPORT_CONFIRM_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_MULTI_COMMISSION: {
            return {
                ...state,
                action: types.DELETE_MULTI_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.ACCEPT_MULTI_COMMISSION: {
            return {
                ...state,
                action: types.ACCEPT_MULTI_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.REJECT_MULTI_COMMISSION: {
            return {
                ...state,
                action: types.REJECT_MULTI_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.INVOICE_MULTI_COMMISSION: {
            return {
                ...state,
                action: types.INVOICE_MULTI_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.CONFIRM_MULTI_COMMISSION: {
            return {
                ...state,
                action: types.CONFIRM_MULTI_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.GROUP_INVOICED_MULTI_COMMISSION: {
            return {
                ...state,
                action: types.GROUP_INVOICED_MULTI_COMMISSION,
                commissionInvoicedGroup: action.status ? action.payload.passedAffiliates : [],
                status: action.status,
                message: action.message,
            };
        }

        case types.APPROVE_COMMISSION: {
            return {
                ...state,
                action: types.APPROVE_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.INVOICE_COMMISSION: {
            return {
                ...state,
                action: types.INVOICE_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.CANCEL_COMMISSION: {
            return {
                ...state,
                action: types.CANCEL_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.CONFIRM_COMMISSION: {
            return {
                ...state,
                action: types.CONFIRM_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.RECHECK_COMMISSION: {
            return {
                ...state,
                action: types.RECHECK_COMMISSION,
                status: action.status,
                message: action.message,
            };
        }

        case types.TOP_COMMISSION: {
            return {
                ...state,
                action: types.TOP_COMMISSION,
                topCommissions: action.status ? action.payload : [],
                status: action.status,
                message: action.message,
            };
        }

        case types.TOP_TOTAL_COMMISSION: {
            return {
                ...state,
                action: types.TOP_TOTAL_COMMISSION,
                topTotalCommissions: action.status ? action.payload : [],
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
