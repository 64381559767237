import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_COUPON_CODE: {
            return {
                ...state,
                action: types.LIST_COUPON_CODE,
                coupons: action.status ? { items: action.payload.items, total: action.payload.total } : { items: [], total: 0 },
                affiliates: action.status ? action.payload.affiliates : [],
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_COUPON_CODE: {
            return {
                ...state,
                action: types.CREATE_COUPON_CODE,
                couponCreated: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_COUPON_CODE: {
            return {
                ...state,
                action: types.SHOW_COUPON_CODE,
                coupon: action.status ? action.payload.coupon : {},
                affiliate: action.status ? action.payload.affiliate : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_COUPON_CODE: {
            return {
                ...state,
                action: types.UPDATE_COUPON_CODE,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_COUPON_CODE: {
            return {
                ...state,
                action: types.DELETE_COUPON_CODE,
                status: action.status,
                message: action.message,
            };
        }

        case types.SYNC_COUPON_CODE: {
            return {
                ...state,
                action: types.SYNC_COUPON_CODE,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_MULTI_COUPON_CODE: {
            return {
                ...state,
                action: types.DELETE_MULTI_COUPON_CODE,
                status: action.status,
                message: action.message,
            };
        }

        case types.SYNC_MULTI_COUPON_CODE: {
            return {
                ...state,
                action: types.SYNC_MULTI_COUPON_CODE,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
