import React from 'react';
import {
    withRouter, Route, Switch, Redirect, BrowserRouter,
} from 'react-router-dom';
import { map } from 'lodash';
import { createBrowserHistory } from 'history';
import routes from '../routes';
import config from '../config/config';

function LayoutPublisher(props) {
    const scopes = 'PUBLISHER';
    const availableRoutes = routes.getRoutesByScopes(scopes);
    const paths = window.location.pathname.split('/');

    const history = createBrowserHistory({
        basename: paths[1],
    });
    return config.APP_AFFILIATE && window.location.origin === config.AFFILIATE_DOMAIN
        ? <>
            <section className="">
                <Switch>
                    {
                        map(availableRoutes, (route, index) => <Route history={history} key={index} path={route.path} exact={route.exact} component={route.component} />)
                    }
                    <Route history={history} render={() => <Redirect to='/' />} />
                </Switch>
            </section>
        </>
        : <>
            <section className="">
                <Switch>
                    {
                        map(availableRoutes, (route, index) => <Route key={index} path={route.path} exact={route.exact} component={route.component} />)
                    }
                    <Route render={() => <Redirect to='/' />} />
                </Switch>
            </section>
        </>;
}

export default withRouter((LayoutPublisher));
