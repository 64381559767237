import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_CAMPAIGN: {
            return {
                ...state,
                action: types.LIST_CAMPAIGN,
                campaigns: action.status ? { items: action.payload.items, total: action.payload.total } : { items: [], total: 0 },
                status: action.status,
                message: action.message,
            };
        }

        case types.LIST_CAMPAIGN_FILTER: {
            return {
                ...state,
                action: types.LIST_CAMPAIGN_FILTER,
                filterCampaigns: action.status ? { items: action.payload.items, total: action.payload.total } : { items: [], total: 0 },
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_CAMPAIGN: {
            return {
                ...state,
                action: types.CREATE_CAMPAIGN,
                campaign: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_CAMPAIGN: {
            return {
                ...state,
                action: types.SHOW_CAMPAIGN,
                campaign: action.status ? action.payload.campaign : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_CAMPAIGN: {
            return {
                ...state,
                action: types.UPDATE_CAMPAIGN,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_CAMPAIGN: {
            return {
                ...state,
                action: types.DELETE_CAMPAIGN,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPLOAD_IMAGE_CAMPAIGN: {
            return {
                ...state,
                action: types.UPLOAD_IMAGE_CAMPAIGN,
                imageUpload: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPLOAD_IMAGE_PROFILE_CAMPAIGN: {
            return {
                ...state,
                action: types.UPLOAD_IMAGE_PROFILE_CAMPAIGN,
                imageProfileUpload: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_SHORT_LINK_CAMPAIGN: {
            return {
                ...state,
                action: types.GET_SHORT_LINK_CAMPAIGN,
                shortLink: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
