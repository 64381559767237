import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LadiDropdown from '../../../../components/LadiDropdown';

function CommissionPlanPaymentBrandBox(props) {
    const conditionBrandID = [
        { name: 'Chứa tất cả', value: 'AND' },
        { name: 'Chứa 1 trong', value: 'OR' },
    ];

    const {
        t, brandIDProp, cb,
    } = props;

    const [brandID, setBrandID] = useState({ enable: false, condition: 'AND', brand_ids: [] });

    const renderExplain = () => {
        let explain = <></>;
        if (brandID.enable) {
            if (brandID.brand_ids && brandID.brand_ids.length === 0) {
                explain = <>(*) Đơn hàng chứa bất kỳ sản phẩm nào cũng sẽ được tính hoa hồng</>;
            } else if (brandID.brand_ids && brandID.brand_ids.length === 1) {
                explain = <>(*) Đơn hàng chứa sản phẩm thuộc Brand ID là {brandID.brand_ids.join(',')} sẽ được tính hoa hồng</>;
            } else if (brandID.condition === 'AND') {
                explain = <>(*) Đơn hàng phải chứa toàn bộ các sản phẩm thuộc Brand ID là {brandID.brand_ids.join(',')} sẽ được tính hoa hồng</>;
            } else {
                explain = <>(*) Đơn hàng chứa 1 trong các sản phẩm thuộc Brand ID là {brandID.brand_ids.join(',')} sẽ được tính hoa hồng</>;
            }
        }
        return explain;
    };

    const eventEnableBrandID = (e) => {
        const value = e.target.checked;
        const data = { ...brandID, enable: value };
        setBrandID(data);
        cb(data);
    };

    const eventSelectConditionbrandID = (value) => {
        const data = { ...brandID, condition: value };
        setBrandID(data);
        cb(data);
    };

    const eventChangeBrandID = (e) => {
        const brand_ids = e.target.value.split(',');
        const data = { ...brandID, brand_ids };
        setBrandID(data);
        cb(data);
    };

    useEffect(() => {
        setBrandID({ ...brandID, ...brandIDProp });
    }, [brandIDProp]);

    return <>
        <div className="ladiui clearfix pt-20">
            <label className="ladiui title-text-form-lever2 left">Thiết lập điều kiện ID thương hiệu cho đơn hàng</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox"
                        name="enable"
                        checked={brandID !== null && (brandID.enable || false)}
                        onChange={(e) => eventEnableBrandID(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Kiểm tra đơn hàng có các sản phẩm thuộc ID thương hiệu theo cài đặt dưới đây</div>
        </div>
        {brandID && brandID.enable
            ? <>
                <div className="ladiui clearfix pt-20">
                    <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Điều kiện đơn hàng</label>
                        <LadiDropdown
                            labelDropdown={t('COMMISSIONS.SELECT_CONDITION')}
                            listProp={conditionBrandID}
                            valueProp={brandID.condition}
                            hideLabel={true}
                            validProp={true}
                            classDropdown="dropdown-custom w-180"
                            classSize="btn-sm"
                            cbProp={eventSelectConditionbrandID}
                        />

                    </div>
                    <div className="ladiui left">
                        <label className="ladiui title-text-form-lever3">Danh sách ID của Thương hiệu</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <textarea className="ladiui form-control "
                                value={brandID && brandID.brand_ids ? brandID.brand_ids.join(',') : ''}
                                onChange={eventChangeBrandID} placeholder="Danh sách ID Thương hiệu ngăn cách bằng dấu phẩy" />
                        </div>
                    </div>
                </div>
                <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>
                    {renderExplain()}
                </div>
            </>
            : <></>}
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBrandBox));
