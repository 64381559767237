import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import LadiDropdown from '../../../components/LadiDropdown';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import actionAffiliateGroup from '../../../redux/futures/affiliate_group/actions';
import ModalAddAffiliateGroup from './ModalAddAffiliateGroup';
import DefineConst from '../DefineConst';
import AffiliateShopId from './AffiliateShopId';
import AffiliateDropdownSelect from './AffiliateDropdownSelect';
import BankInfoDropdown from './BankInfoDropdown';

// eslint-disable-next-line max-lines-per-function
function ModalAffiliate(props) {
    const {
        t, title, btnOk, btnCancel, cb,
    } = props;

    const styleInvalid = { border: '1px solid var(--danger-color)' };
    const styleValid = { boder: '1px solid var(--main-line)' };
    const defaultValidated = {
        fullname: false,
        email: false,
        phone: false,
        status: false,
        code: false,
    };
    const defaultIsValidated = {
        fullname: true,
        email: true,
        phone: true,
        status: true,
        code: true,
    };

    const dispatch = useDispatch();
    const [affiliate, setAffiliate] = useState();
    const [affiliateRefCode, setAffiliateRefCode] = useState(null);
    const [valid, setValid] = useState(defaultValidated);
    const listAffiliateGroup = useSelector((state) => ({ ...{ items: [], total: 0, pluckItems: {} }, ...state.affiliateGroups.groups }));
    const affiliateDetails = useSelector((state) => (state.affiliates.affiliate));
    const refAffiliateDetails = useSelector((state) => (state.affiliates.refAffiliate));
    const listAppIntegrate = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.appIntegrate.integrateApps }));

    const changeDataForm = (e) => {
        const field = {};
        const name = e.target.name;
        const value = e.target.value;
        field[name] = value;
        setAffiliate({ ...affiliate, ...field });
        const validatedData = {};
        if (e.target.hasAttribute('required')) {
            if (value === '') {
                validatedData[name] = false;
            } else {
                validatedData[name] = true;
            }
        }
        setValid({ ...valid, ...validatedData });
    };

    const actionOk = () => {
        const nameElement = document.getElementById('fullname');
        const emailElement = document.getElementById('email');
        const phoneElement = document.getElementById('phone');
        const validValue = {};
        if (nameElement.value === '') {
            validValue.fullname = false;
            setValid({ ...valid, ...validValue });
            window.LadiUI.toast('danger', '', 'Vui lòng nhập đầy đủ họ tên', 5000, 'bottom-left');
            return;
        }

        if (emailElement.value === '') {
            validValue.email = false;
            setValid({ ...valid, ...validValue });
            window.LadiUI.toast('danger', '', 'Vui lòng nhập email', 5000, 'bottom-left');
            return;
        }

        if (phoneElement.value === '') {
            validValue.phone = false;
            setValid({ ...valid, ...validValue });
            window.LadiUI.toast('danger', '', 'Vui lòng nhập số điện thoại', 5000, 'bottom-left');
            return;
        }

        if (affiliate.affiliate_group_id === '') {
            validValue.affiliate_group_id = false;
            setValid({ ...valid, ...validValue });
            window.LadiUI.toast('danger', '', 'Vui lòng chọn nhóm affiliate', 5000, 'bottom-left');
            return;
        }

        if (affiliate.code.replaceAll(' ', '') !== affiliate.code) {
            validValue.code = false;
            setValid({ ...valid, ...validValue });
            window.LadiUI.toast('danger', '', 'Vui lòng nhập mã affiliate không có khoảng trắng', 5000, 'bottom-left');
            return;
        }

        const data = { ...affiliate, affiliate_ref_code: affiliateRefCode };
        data.id = affiliate._id;
        dispatch(actionAffiliate.update(data, cb));
        setAffiliate({});
        setAffiliateRefCode(null);
        window.LadiUI.closeModal('white-page');
    };

    const eventCancel = () => {
        setValid(defaultIsValidated);
    };

    const eventSelectStatus = (value) => {
        setAffiliate({ ...affiliate, ...{ status: value } });
        setValid({ ...valid, ...{ status: true } });
    };

    const eventAddAffiliateGroup = () => {
        window.LadiUI.showModal('modal-add-affiliate-group');
    };

    const eventSelectTypeAffiliate = (value) => {
        setAffiliate({ ...affiliate, type: value });
    };

    const getShopIdDropdown = () => {
        const itemDropdown = [];
        for (let i = 0; i < listAppIntegrate.items.length; i++) {
            itemDropdown.push({ value: listAppIntegrate.items[i]._id, name: listAppIntegrate.items[i].name });
        }
        return itemDropdown;
    };

    const getValueShopIdDropdown = (shopId) => {
        let idShop = [];
        if (shopId) {
            for (let i = 0; i < listAppIntegrate.items.length; i++) {
                if (shopId.includes(listAppIntegrate.items[i]._id)) idShop.push({ name: listAppIntegrate.items[i].name, value: listAppIntegrate.items[i]._id });
            }
        }
        return idShop;
    };

    const eventSelectShopId = (idShops) => {
        let idShop = [];
        for (let i = 0; i < idShops.length; i++) {
            idShop.push(idShops[i].value);
        }
        const item = { ...affiliate, shop_id: idShop };
        setAffiliate(item);
    };

    const selectAffiliateRef = (item) => {
        setAffiliateRefCode(item.code);
    };

    const selectBank = (item) => {
        setAffiliate({ ...affiliate, bank_name: item.name });
    };

    useEffect(() => {
        if (affiliateDetails) {
            setValid(defaultIsValidated);
            setAffiliate({ ...affiliateDetails, password: '' });
        }
    }, [affiliateDetails]);

    useEffect(() => {
        setAffiliateRefCode(refAffiliateDetails ? refAffiliateDetails.code : null);
    }, [refAffiliateDetails]);

    return affiliate ? <>
        <div className="ladiui modal" id="white-page">
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">{title}</label>
                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="d-grid-2 gap-24">
                        <div className="ladiui form-group-custom" style={{ width: '360px' }}>
                            <label className="ladiui title-text-form-lever2" >{t('AFFILIATES.NAME')}</label>
                            <input id="fullname" name="fullname" className="ladiui form-control" placeholder="Nhập họ tên" required style={!valid.fullname ? styleInvalid : styleValid}
                                value={affiliate.fullname || ''} onChange={changeDataForm} />
                        </div>
                        <div className='ladiui item flex-row'>
                            <LadiDropdown
                                labelDropdown='Loại affiliate'
                                listProp={appConfig.LADISHARE.AFFILIATE_TYPE}
                                valueProp={affiliate.type || DefineConst.AFFILIATE_TYPE.PERSONAL}
                                hideLabel={false}
                                validProp={valid.status}
                                classDropdown={'dropdown-status-affiliate'}
                                cbProp={eventSelectTypeAffiliate}
                            />
                        </div>
                    </div>
                    <div className="ladiui form-group-custom" style={{ width: '100%' }}>
                        <label className="ladiui title-text-form-lever2" >{t('AFFILIATES.EMAIL')}</label>
                        <input id="email" name="email" className="ladiui form-control" placeholder="Nhập email" required
                            style={!valid.email ? styleInvalid : styleValid}
                            value={affiliate.email || ''} onChange={changeDataForm} />
                    </div>
                    <div className="d-grid-3 gap-24">
                        <div className="ladiui form-group-custom">
                            <label className="ladiui title-text-form-lever2" >{t('AFFILIATES.PHONE')}</label>
                            <input id="phone" name="phone" className="ladiui form-control" placeholder="Nhập số điện thoại" required
                                style={!valid.phone ? styleInvalid : styleValid}
                                value={affiliate.phone || ''} onChange={changeDataForm} />
                        </div>
                        <div className="ladiui form-group-custom">
                            <label className="ladiui title-text-form-lever2" >Mã affiliate</label>
                            <input id="code" name="code" className="ladiui form-control lowercase" maxLength="20" placeholder="Nhập mã" required
                                style={!valid.code ? styleInvalid : styleValid}
                                value={affiliate.code || ''}
                                onChange={changeDataForm} />
                        </div>
                        {/* <div className="ladiui form-group-custom">
                            <label className="ladiui title-text-form-lever2" >{t('AFFILIATES.REFERENCE')}</label>
                            <input id="affiliate_ref_code" name="affiliate_ref_code lowercase" className="ladiui form-control" maxLength="20" placeholder="Nhập mã"
                                value={affiliateRefCode || ''}
                                onChange={(e) => setAffiliateRefCode(e.target.value)} />
                        </div> */}

                        <div className="ladiui form-group-custom">
                            <label className="ladiui title-text-form-lever2" >{t('AFFILIATES.REFERENCE')}</label>
                            <AffiliateDropdownSelect selectedRef={refAffiliateDetails} ignoreAffiliates={[affiliate]} cb={selectAffiliateRef} />
                        </div>

                    </div>
                    {/* <div className="d-grid-2 gap-24"> */}
                    <div className="ladiui item flex-row">
                        <div className="ladiui item-form">
                            <div>
                                <label className="ladiui title-text-form-lever2">{t('AFFILIATES.GROUP')} {t('AFFILIATES.AFFILIATE')}</label>
                            </div>

                            <div className="ladiui">
                                <input type="hidden" id="affiliate_group_id"
                                    defaultValue={affiliate.affiliate_group_id ? affiliate.affiliate_group_id : ''} />
                                <div className="ladiui btn-group ">
                                    <div className="ladiui dropdown">
                                        <button
                                            className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                                            id="dropdownMenuButton3" data-toggle="dropdown">
                                            <i></i>
                                            <span className="ladiui dropdown-text" style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {affiliate.affiliate_group_id
                                                    ? listAffiliateGroup.pluckItems[affiliate.affiliate_group_id] : t('AFFILIATES.SELECT_GROUP')}
                                            </span>
                                        </button>
                                        <ul className="ladiui dropdown-menu">
                                            <li><a className="ladiui btn btn-link" href="#/" onClick={eventAddAffiliateGroup}>+ Thêm mới nhóm affiliate</a></li>
                                            <li><a className="ladiui btn btn-link" href="#/"
                                                onClick={() => (dispatch(actionAffiliateGroup.list({ pluck: ['_id', 'name'] })))}>- Tải lại danh sách nhóm</a></li>
                                            {listAffiliateGroup.items.map((values) => <li key={values._id}
                                                onClick={() => setAffiliate({ ...affiliate, ...{ affiliate_group_id: values._id } })}>
                                                <a className="ladiui dropdown-item" href="#/">{values.name}</a>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={affiliate._id === '' ? 'ladiui item flex-row hidden' : 'ladiui item flex-row'}>
                            <LadiDropdown
                                labelDropdown={t('AFFILIATES.STATUS_LABEL')}
                                listProp={appConfig.LADISHARE.AFFILIATE_STATUS}
                                valueProp={affiliate.status}
                                hideLabel={false}
                                validProp={valid.status}
                                classDropdown={'dropdown-status-affiliate'}
                                cbProp={eventSelectStatus}
                            />
                        </div> */}
                    {/* </div> */}
                    <div className="ladiui item flex-row">
                        <div className="ladiui item-form">
                            <AffiliateShopId
                                labelDropdown='Shop ID'
                                listProp={getShopIdDropdown()}
                                listCheckedProp={getValueShopIdDropdown(affiliate.shop_id)}
                                cb={eventSelectShopId}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="ladiui form-group-custom">
                            <div className="ladiui flex-row">
                                <label className="ladiui title-text-form-lever2">Thông tin tài khoản ngân hàng</label>
                            </div>
                        </div>
                        <div className="order-info-content ladiui item bg-form bg-commissionn-plan" style={{ marginTop: 0 }}>
                            <div className="ladiui item bg-form-sub" style={{ paddingBottom: '16px', margin: '0 16px' }}>
                                <div className="d-grid-2">
                                    <div className="ladiui pt-20">
                                        <label className="ladiui title-text-form-lever2">Ngân hàng</label>
                                        <input type="text" id="bank_name" name="bank_name" className="ladiui form-control"
                                            placeholder="Tên ngân hàng"
                                            value={affiliate.bank_name || ''} onChange={changeDataForm}
                                        />
                                        {/* <BankInfoDropdown bankName={affiliate.bank_name || ''} cb={selectBank} /> */}
                                    </div>
                                    <div className="ladiui pt-20">
                                        <label className="ladiui title-text-form-lever2">Số tài khoản</label>
                                        <input type="text" id="bank_account_number" name="bank_account_number" className="ladiui form-control"
                                            placeholder="Nhập số tài khoản"
                                            value={affiliate.bank_account_number || ''} onChange={changeDataForm}
                                        />
                                    </div>
                                    <div className="ladiui">
                                        <label className="ladiui title-text-form-lever2">Tên chủ khoản</label>
                                        <input type="text" id="bank_account_name" name="bank_account_name" className="ladiui form-control"
                                            placeholder="Nhập tên chủ khoản"
                                            value={affiliate.bank_account_name || ''} onChange={changeDataForm}
                                        />
                                    </div>
                                    <div className="ladiui">
                                        <label className="ladiui title-text-form-lever2">Chi nhánh</label>
                                        <input type="text" id="bank_department" name="bank_department" className="ladiui form-control" placeholder="Nhập chi nhánh"
                                            value={affiliate.bank_department || ''} onChange={changeDataForm}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2" >Thông tin khác</label>
                        <textarea id="other_info" name="other_info" className="ladiui form-control" rows={3} placeholder="Nhập nội dung"
                            value={affiliate.other_info || ''} onChange={changeDataForm} />
                    </div>

                    <div>

                    </div>
                    <div className="ladiui modal-footer custom-ldp">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <button type="button" data-dismiss="modal" data-target="white-page"
                                className="ladiui btn btn-secondary bold" onClick={eventCancel}>{btnCancel}</button>
                            <button type="button" className="ladiui btn btn-primary" onClick={actionOk}>{btnOk}</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <ModalAddAffiliateGroup />
    </> : <><div className="ladiui modal" id="white-page"></div></>;
}

export default (withTranslation()(ModalAffiliate));
