import * as types from './types';
import * as commonTypes from '../common/types';
import { type } from 'i18next-sprintf-postprocessor';

// eslint-disable-next-line max-lines-per-function
export default (state = { stores: [], userInfo: { currentStore: {} } }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }
        case types.LIST_STORE: {
            return {
                ...state,
                action: types.LIST_STORE,
                stores: action.status ? action.payload.stores : [],
                totalRecord: action.status ? action.payload.total_record : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            };
        }
        case types.DELETE_STORES: {
            return {
                ...state,
                action: types.DELETE_STORES,
                status: action.status,
                message: action.message,
            };
        }
        case types.DELETE_STORE: {
            return {
                ...state,
                action: types.DELETE_STORE,
                status: action.status,
                message: action.message,
            };
        }
        case types.SHOW_STORE: {
            return {
                ...state,
                action: types.SHOW_STORE,
                status: action.status,
                message: action.message,
                store: action.status ? action.payload.store : {},
            };
        }
        case types.CREATE_STORE: {
            return {
                ...state,
                action: types.CREATE_STORE,
                status: action.status,
                message: action.message,
            };
        }
        case types.UPDATE_STORE: {
            return {
                ...state,
                action: types.UPDATE_STORE,
                status: action.status,
                message: action.message,
            };
        }
        case types.GET_USER_INFO: {
            return {
                ...state,
                action: types.GET_USER_INFO,
                status: action.status,
                message: action.message,
                userInfo: action.status ? { ...action.payload } : {},
            };
        }

        case types.GET_API_KEY_INFO: {
            return {
                ...state,
                action: types.GET_API_KEY_INFO,
                status: action.status,
                message: action.message,
                apiKeyInfo: action.status ? { ...action.payload } : {},
            };
        }

        case types.RE_CREATE_API_KEY: {
            return {
                ...state,
                action: types.RE_CREATE_API_KEY,
                status: action.status,
                message: action.message,
            };
        }

        case types.APPLY_DEFAULT_ADDRESS_STORE: {
            return {
                ...state,
                action: types.APPLY_DEFAULT_ADDRESS_STORE,
                status: action.status,
                message: action.message,
            };
        }
        case types.CREATE_NEW_IP: {
            return {
                ...state,
                action: types.CREATE_NEW_IP,
                status: action.status,
                message: action.message,
            };
        }
        case types.DELETE_IP: {
            return {
                ...state,
                action: types.DELETE_IP,
                status: action.status,
                message: action.message,
            };
        }
        case types.GET_AFFILIATE_SETTING: {
            return {
                ...state,
                action: types.GET_AFFILIATE_SETTING,
                status: action.status,
                message: action.message,
                affiliateSetting: action.status ? { ...action.payload.setting } : {},
            };
        }

        case types.GET_COOKIE_SETTING: {
            return {
                ...state,
                action: types.GET_COOKIE_SETTING,
                status: action.status,
                message: action.message,
                cookieSetting: action.status ? { ...action.payload.setting } : {},
            };
        }

        case types.SET_COOKIE_SETTING: {
            return {
                ...state,
                action: types.SET_COOKIE_SETTING,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_AFFILIATE_SETTING: {
            return {
                ...state,
                action: types.UPDATE_AFFILIATE_SETTING,
                status: action.status,
                message: action.message,
            };
        }

        case types.LIST_SENDER_EMAIL: {
            return {
                ...state,
                action: types.LIST_SENDER_EMAIL,
                senders: action.status ? { items: action.payload.items, total: action.payload.total } : { items: [], total: 0 },
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_SENDER_EMAIL: {
            return {
                ...state,
                action: types.CREATE_SENDER_EMAIL,
                sender: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_SENDER_EMAIL: {
            return {
                ...state,
                action: types.UPDATE_SENDER_EMAIL,
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_SENDER_EMAIL: {
            return {
                ...state,
                action: types.SHOW_SENDER_EMAIL,
                sender: action.status ? action.payload.senderEmail : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_SENDER_EMAIL: {
            return {
                ...state,
                action: types.DELETE_SENDER_EMAIL,
                status: action.status,
                message: action.message,
            };
        }

        case types.MAKE_AS_DEFAULT_SENDER_EMAIL: {
            return {
                ...state,
                action: types.MAKE_AS_DEFAULT_SENDER_EMAIL,
                status: action.status,
                message: action.message,
            };
        }

        case types.VERIFY_SENDER_EMAIL: {
            return {
                ...state,
                action: types.VERIFY_SENDER_EMAIL,
                status: action.status,
                message: action.message,
            };
        }

        case types.VERIFY_MULTI_SENDER_EMAIL: {
            return {
                ...state,
                action: types.VERIFY_MULTI_SENDER_EMAIL,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_MULTI_SENDER_EMAIL: {
            return {
                ...state,
                action: types.DELETE_MULTI_SENDER_EMAIL,
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_AFFILIATE_DASHBOARD_SETTING: {
            return {
                ...state,
                action: types.GET_AFFILIATE_DASHBOARD_SETTING,
                status: action.status,
                message: action.message,
                affiliateDashboardSetting: action.status ? { ...action.payload.setting } : {},
            };
        }

        case types.GET_AFFILIATE_DASHBOARD_SETTING_PUBLIC: {
            return {
                ...state,
                action: types.GET_AFFILIATE_DASHBOARD_SETTING_PUBLIC,
                status: action.status,
                message: action.message,
                affiliateDashboardSettingPublic: action.status ? { ...action.payload.setting } : {},
                affiliateDashboardSettingStorePublic: action.status ? { ...action.payload.store } : {},
            };
        }

        case types.GET_AFFILIATE_SETTING_PUBLIC: {
            return {
                ...state,
                action: types.GET_AFFILIATE_SETTING_PUBLIC,
                status: action.status,
                message: action.message,
                affiliateSettingPublic: action.status ? { ...action.payload.setting } : {},
            };
        }

        case types.UPLOAD_LOGO_STORE: {
            return {
                ...state,
                action: types.UPLOAD_LOGO_STORE,
                logoUpload: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPLOAD_ICON_STORE: {
            return {
                ...state,
                action: types.UPLOAD_ICON_STORE,
                iconUpload: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_AFFILIATE_DASHBOARD_SETTING: {
            return {
                ...state,
                action: types.UPDATE_AFFILIATE_DASHBOARD_SETTING,
                status: action.status,
                message: action.message,
            };
        }

        case types.VERIFY_DOMAIN_AFFILIATE: {
            return {
                ...state,
                action: types.VERIFY_DOMAIN_AFFILIATE,
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_BLOCK_IP_SETTING: {
            return {
                ...state,
                action: types.GET_BLOCK_IP_SETTING,
                status: action.status,
                message: action.message,
                blockIPs: action.status ? action.payload.setting : '',
            };
        }

        case types.UPDATE_BLOCK_IP_SETTING: {
            return {
                ...state,
                action: types.UPDATE_BLOCK_IP_SETTING,
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_AFFILIATE_CHANGE_GROUP_SETTING: {
            return {
                ...state,
                action: types.GET_AFFILIATE_CHANGE_GROUP_SETTING,
                status: action.status,
                message: action.message,
                affiliateChangeGroupSetting: action.status ? action.payload.setting : '',
            };
        }

        case types.UPDATE_AFFILIATE_CHANGE_GROUP_SETTING: {
            return {
                ...state,
                action: types.UPDATE_AFFILIATE_CHANGE_GROUP_SETTING,
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_TEMPLATE_EMAIL: {
            return {
                ...state,
                action: types.GET_AFFILIATE_CHANGE_GROUP_SETTING,
                status: action.status,
                message: action.message,
                templateEmail: action.status ? action.payload : '',
            };
        }

        case types.UPDATE_TEMPLATE_EMAIL: {
            return {
                ...state,
                action: types.UPDATE_TEMPLATE_EMAIL,
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_REQUEST_WITHDRAW_SETTING: {
            return {
                ...state,
                action: types.GET_REQUEST_WITHDRAW_SETTING,
                status: action.status,
                message: action.message,
                requestWithdrawSetting: action.status ? { ...action.payload.setting } : {},
            };
        }

        default:
            return state;
    }
};
