import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../assets/css/custom.module.css';
import appConfig from '../../../config/app';
import LadiDropdown from '../../../components/LadiDropdown';
import DefineConst from '../DefineConst';
import actionCoupon from '../../../redux/futures/coupon_code/actions';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import BaseHelper from '../../../helpers/BaseHelper';
import AppDiscount from './AppDiscount';

// eslint-disable-next-line max-lines-per-function
function ModalCouponCode(props) {
    const { t, isEdit, cb } = props;
    const defaultApp = {
        _id: BaseHelper.generateRandomString(16),
        app_type: null,
        customer_discount_number: 0,
        customer_discount_type: DefineConst.COUPON_PAYMENT_TYPE.PERCENT,
    };

    const dispatch = useDispatch();
    const [coupon, setCoupon] = useState({});
    const [appIntegrateCoupon, setAppIntegrateCoupon] = useState([]);
    const [affiliateConditions, setAffiliateConditions] = useState(null);
    const [affiliate, setAffiliate] = useState({});
    const listAffiliate = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.affiliates.affiliates }));
    const couponData = useSelector((state) => state.coupons.coupon);
    const listAppIntegrate = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.appIntegrate.integrateApps }));

    const getAppCouponDefault = () => {
        const appIntegration = [];
        for (let i = 0; i < listAppIntegrate.items.length; i++) {
            const integrate = listAppIntegrate.items[i];
            if (integrate.auto_sync_coupon_code) {
                const item = {
                    ...defaultApp,
                    _id: BaseHelper.generateRandomString(16),
                    app_type: integrate.type,
                    integration_id: integrate._id,
                    integration: integrate,
                    is_new: true,
                };
                if (integrate.enable_discount_for_customer) {
                    item.customer_discount_number = integrate.discount_for_customer;
                    item.customer_discount_type = integrate.discount_for_customer_type;
                }
                appIntegration.push(item);
            }
        }
        return appIntegration;
    };

    const cbAction = () => {
        setCoupon({});
        setAffiliate({});
        setAffiliateConditions(null);
        setAppIntegrateCoupon(getAppCouponDefault());
        window.LadiUI.closeModal('modal-coupon-code');
        cb();
    };
    const eventCancel = () => {
        window.LadiUI.closeModal('modal-coupon-code');
    };
    const eventEdit = () => {
        if (!coupon.code) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập mã giảm giá', 5000, 'bottom-left');
            return;
        }

        if (!coupon.affiliate_id) {
            window.LadiUI.toast('danger', '', 'Vui lòng chọn affiliate', 5000, 'bottom-left');
            return;
        }

        for (let i = 0; i < appIntegrateCoupon.length; i++) {
            const app = appIntegrateCoupon[i];
            if (!app.integration_id) {
                window.LadiUI.toast('danger', '', 'Vui lòng chọn tích hợp', 5000, 'bottom-left');
                return;
            }
            if (app.customer_discount_number === null || app.customer_discount_number === '') {
                window.LadiUI.toast('danger', '', 'Vui lòng điền mức giảm giá', 5000, 'bottom-left');
                return;
            }
            if (app.customer_discount_type === 'PERCENT' && app.customer_discount_number > 100) {
                window.LadiUI.toast('danger', '', 'Vui lòng điền mức giảm giá trong khoảng từ 0 tới 99', 5000, 'bottom-left');
                return;
            }
        }
        dispatch(actionCoupon.update({ coupon, app_integrate_coupons: appIntegrateCoupon }, cbAction));
    };
    const eventCreate = () => {
        if (!coupon.code) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập mã giảm giá', 5000, 'bottom-left');
            return;
        }

        if (!coupon.affiliate_id) {
            window.LadiUI.toast('danger', '', 'Vui lòng chọn affiliate', 5000, 'bottom-left');
            return;
        }

        for (let i = 0; i < appIntegrateCoupon.length; i++) {
            const app = appIntegrateCoupon[i];
            if (!app.integration_id) {
                window.LadiUI.toast('danger', '', 'Vui lòng chọn tích hợp', 5000, 'bottom-left');
                return;
            }
            if (app.customer_discount_number === null || app.customer_discount_number === '') {
                window.LadiUI.toast('danger', '', 'Vui lòng điền mức giảm giá', 5000, 'bottom-left');
                return;
            }
            if (app.customer_discount_type === 'PERCENT' && app.customer_discount_number > 100) {
                window.LadiUI.toast('danger', '', 'Vui lòng điền mức giảm giá trong khoảng từ 0 tới 99', 5000, 'bottom-left');
                return;
            }
        }

        dispatch(actionCoupon.create({ coupon, app_integrate_coupons: appIntegrateCoupon }, cbAction));
    };

    const eventSelectAffiliate = (item) => {
        setCoupon({ ...coupon, affiliate_id: item._id, affiliate_code: item.code });
        setAffiliate(item);
    };

    const eventAddMoreApp = () => {
        const items = [...appIntegrateCoupon];
        items.push({ ...defaultApp, _id: BaseHelper.generateRandomString(16), is_new: true });
        setAppIntegrateCoupon(items);
    };

    const eventRemoveApp = (data) => {
        const items = [...appIntegrateCoupon];
        const newItems = items.filter((item) => item._id !== data._id);
        setAppIntegrateCoupon(newItems);
    };

    const eventChangeApp = (data) => {
        const items = [...appIntegrateCoupon];
        const newItems = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item._id === data._id) {
                item.integration_id = data.integration_id;
                item.app_type = data.app_type;
                item.customer_discount_type = data.customer_discount_type;
                item.customer_discount_number = data.customer_discount_number;
                item.integration = data.integration;
            }
            newItems.push(item);
        }
        setAppIntegrateCoupon(newItems);
    };

    const searchAffiliate = () => {
        dispatch(actionAffiliate.list(affiliateConditions));
    };

    const handleKeyDownSearchAffiliate = (e) => {
        if (e.keyCode === 13) {
            dispatch(actionAffiliate.list(affiliateConditions));
        }
    };

    useEffect(() => {
        if (!isEdit) {
            setCoupon({});
            setAffiliate({});
            setAffiliateConditions(null);
            setAppIntegrateCoupon(getAppCouponDefault());
        }
    }, [isEdit]);

    useEffect(() => {
        if (couponData) {
            setCoupon(couponData);
            setAffiliate(couponData.affiliate || {});
            setAffiliateConditions(null);
            setAppIntegrateCoupon(couponData.app_integrate_coupons);
        }
    }, [couponData]);

    return <div className="ladiui modal" id="modal-coupon-code">
        <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
            <div className="ladiui modal-content custom-ldp">
                <div className="ladiui modal-header custom-ldp">
                    <label className="ladiui title-text-form-lever1">{isEdit ? 'Sửa coupon' : 'Thêm coupon'}</label>
                    <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                        <i className="ladiui icon icon-ldp-close"></i>
                    </button>
                </div>
                <div className={styles['group-coupon-code']}>
                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2" >{t('COUPONS.CODE')}</label>
                        <input id="code" name="code" className="ladiui form-control" placeholder="Nhập mã coupon" style={{ textTransform: 'uppercase' }}
                            required readOnly={coupon.type === DefineConst.COUPON_TYPE.DEFAULT}
                            value={coupon.code || ''} onChange={(e) => setCoupon({ ...coupon, code: e.target.value })} />
                    </div>

                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2" >{t('COUPONS.AFFILIATE_EMAIL')}</label>
                        {coupon.type === DefineConst.COUPON_TYPE.DEFAULT
                            ? <input id="code" name="code" className="ladiui form-control" readOnly
                                value={affiliate.email || ''} />
                            : <div className="ladiui select-search dropdown">
                                <button
                                    className={'ladiui btn btn-outline-light dropdown-toggle w-100-percent'}
                                    data-toggle="dropdown">
                                    <i></i>
                                    <span className="ladiui dropdown-text" style={{ marginRight: 0 }}>
                                        {affiliate.email || 'Chọn affiliate'}
                                    </span>
                                </button>
                                <ul className="ladiui dropdown-menu dropdown-right" style={{ width: '100%', maxHeight: '500px', overflowY: 'auto' }}>
                                    <li className="ladiui search-group custom-people">
                                        <input
                                            className="ladiui search-field dropdown-toggle form-control custom-people"
                                            onChange={(e) => setAffiliateConditions({ ...affiliateConditions, keyword: e.target.value })}
                                            onKeyDown={(e) => handleKeyDownSearchAffiliate(e)}
                                            placeholder="Tìm kiếm"
                                        />
                                        <i className="ladiui icon icon-search" onClick={searchAffiliate}></i>
                                    </li>
                                    {listAffiliate.total > 0
                                        ? listAffiliate.items.map((item) => <li key={item._id}
                                            onClick={() => eventSelectAffiliate(item)} >
                                            <a className="ladiui dropdown-item text-ellipsis" href="#/" title={`${item.code} - ${item.fullname}`}>
                                                {item.code} - {item.fullname}
                                            </a>
                                        </li>)
                                        : <></>
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>

                <div className="ladiui clearfix">
                    <label className="ladiui title-text-form-lever2 left margin-label" style={{ marginRight: '0' }}></label>
                </div>

                <div className="list-app-discount">
                    <label className="ladiui title-text-form-lever2" >{t('COUPONS.DISCOUNT_FOR_CUSTOMER')}</label>
                    <div className="clearfix font-size-12">Cài đặt và đồng bộ thông tin mã giảm giá cho từng tích hơp.</div>
                    {
                        appIntegrateCoupon.length > 0 ? appIntegrateCoupon.map((item, index) => <AppDiscount
                            key={index}
                            appProp={item}
                            // appExistProp={appExist}
                            appIntegrateCouponProp={appIntegrateCoupon}
                            changeAppProp={eventChangeApp}
                            removeAppProp={eventRemoveApp}
                        />)
                            : <></>
                    }
                    {appIntegrateCoupon.length < listAppIntegrate.total
                        ? <a href="#/" className="ladiui proviso" onClick={eventAddMoreApp}>
                            <img src="https://w.ladicdn.com/design-system/icons/icon-add-circle-outline.svg" alt="" />
                            <p className="ladiui proviso-text">Thêm giảm giá</p>
                        </a>
                        : <></>}
                </div>
                <div className="ladiui modal-footer custom-ldp">
                    <div></div>
                    <div className="ladiui footer-modal-btn">
                        <button className="ladiui btn btn-secondary" onClick={eventCancel}>Huỷ bỏ</button>
                        {isEdit
                            ? <button className="ladiui btn btn-primary" onClick={eventEdit}>Cập nhật coupon</button>
                            : <button className="ladiui btn btn-primary" onClick={() => eventCreate()}>Tạo coupon</button>
                        }
                    </div>

                </div>
            </div>
        </div>
    </div >;
}
export default (withTranslation()(ModalCouponCode));
