import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import TopCommission from './top_affiliate/TopCommission';
import TopEarner from './top_affiliate/TopEarner';

function TopAffiliate(props) {
    const { t } = props;
    const [conditions, setConditions] = useState({});

    return <>
        <div className="ladiui content-main-tool flex-row align-item-baseline">
        </div>
        <div className="statistics-top-affiliate">
            <TopCommission conditionProp={conditions} />
            <TopEarner conditionProp={conditions} />
        </div>
    </>;
}

export default (withTranslation()(TopAffiliate));
