export default {
    STAFF_ROLE: {
        MANAGER: 'MANAGER',
        ACCOUNTANT: 'ACCOUNTANT',
        SALES: 'SALES',
        OWNER: 'OWNER',
    },
    STAFF_ROLE_NAME: {
        MANAGER: 'Mananger',
        ACCOUNTANT: 'Accountant',
        SALES: 'Sales',
        OWNER: 'Owner',
    },
};
