import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import BaseHelper from '../../helpers/BaseHelper';
import ModalAffiliate from './components/ModalAffiliate';
import NoteAffiliate from './components/NoteAffiliate';
import actionAffiliate from '../../redux/futures/affiliate/actions';
import actionAffiliateGroup from '../../redux/futures/affiliate_group/actions';
import ModalPurchase from './components/ModalPurchase';
import ListInvoice from './components/ListInvoice';
import DefineConst from './DefineConst';
import config from '../../config/config';
import styles from '../../assets/css/custom.module.css';
import ListCustomer from './components/ListCustomer';

// eslint-disable-next-line max-lines-per-function
function AffiliateDetail(props) {
    const { t } = props;
    const { id } = useParams();
    const dispatch = useDispatch();

    const [loadData, setLoadData] = useState({
        commission: false, invoice: false, customer: false,
    });
    const affiliate = useSelector((state) => (state.affiliates.affiliate));
    const statistics = useSelector((state) => (state.affiliates.statistics));
    const refAffiliate = useSelector((state) => (state.affiliates.refAffiliate));
    const listAffiliateGroup = useSelector((state) => ({ ...{ items: [], total: 0, pluckItems: {} }, ...state.affiliateGroups.groups }));
    const store = useSelector((state) => (state.store.userInfo.store));

    const cbAffiliate = () => {
        dispatch(actionAffiliate.show(id));
    };

    const eventEditAffiliate = () => {
        window.LadiUI.showModal('white-page');
    };

    const eventPurchase = () => {
        window.LadiUI.showModal('modal-purchase');
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.AFFILIATE_STATUS.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <div className="ladiui badge badge-warning bold-500 mt-8">
                            Đăng ký mới
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.AFFILIATE_STATUS.ACTIVED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <div className="ladiui badge badge-success bold-500 mt-8">
                            Đang hoạt động
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.AFFILIATE_STATUS.LOCKED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <div className="ladiui badge badge-mute bold-500 mt-8">
                            Đã khoá
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    useEffect(() => {
        dispatch(actionAffiliateGroup.list({ pluck: ['_id', 'name'] }, cbAffiliate));
    }, []);

    return (
        affiliate
            ? <>
                <div className="ladiui content-list-landingpage">
                    <div className="ladiui content-tag content-left">
                        <div className="ladiui flex-row">
                            {affiliate.avatar
                                ? <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-avata.svg" alt="" width="64" height="64" />
                                : <div className="ladiui avt-text">{affiliate.fullname ? affiliate.fullname.charAt(0) : ''}</div>}
                            <div className="ml-16 info-affiliate-detail" style={{ width: 'calc(100% - 80px)' }}>
                                <label className="bold-500 font-size-16">{affiliate.fullname}</label>
                                <p className={`info-email ${styles['text-ellipsis']}`} title={affiliate.email}>{affiliate.email}</p>
                                <p style={{ marginTop: '10px' }}>
                                    <a href="#/" onClick={eventEditAffiliate} className="edit-info-affiliate">
                                        <img src="https://w.ladicdn.com/design-system/icons/icon-edit.svg" /> {t('AFFILIATES.EDIT_INFO')}
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div className="ladiui group-info mt-16">
                            <label>{t('AFFILIATES.STATUS_AFFILIATE')}</label>
                            {getStatus(affiliate.status)}
                        </div>
                        <div className="ladiui group-info">
                            <label>{t('GROUP_AFFILIATES.GROUP_AFFILIATE')}</label>
                            <p className="bold-500">{affiliate.affiliate_group_id
                                ? listAffiliateGroup.pluckItems[affiliate.affiliate_group_id] : ''}</p>
                        </div>
                        <div className="ladiui group-info">
                            <label>{t('AFFILIATES.JOIN_AT')}</label>
                            <p className="bold-500">{BaseHelper.formatStrToDate(affiliate.created_at, 'DD/MM/YYYY')}</p>
                        </div>
                        <div className="ladiui group-info">
                            <label>{t('AFFILIATES.REFERENCE')}</label>
                            <p className="bold-500">{refAffiliate ? refAffiliate.code : 'Không'}</p>
                        </div>

                        <hr className="ladiui divider" />

                        <div className="ladiui group-info">
                            <label className="color-grey">{t('AFFILIATES.AFFILIATE_DASHBOARD')}</label>
                            <p>
                                <a href={store && store.url_affiliate ? (`${!store.is_verified_url_affiliate ? 'http' : 'https'}://${store.url_affiliate}`) : `${config.AFFILIATE_DOMAIN}/${store && store.alias ? store.alias : ''}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="ladiui link"
                                >{store && store.url_affiliate
                                    ? store.url_affiliate
                                    : `${config.AFFILIATE_DOMAIN}/${store && store.alias ? store.alias : ''}`}</a>
                            </p>
                        </div>
                        <div className="ladiui group-info">
                            <label className="color-grey">{t('AFFILIATES.CODE')} {t('AFFILIATES.AFFILIATE')}</label>
                            <p>{affiliate.code}</p>
                        </div>
                        <div className="ladiui group-info">
                            <label className="color-grey">{t('AFFILIATES.PHONE')}</label>
                            <p>{affiliate.phone}</p>
                        </div>

                    </div>

                    <div className="ladiui content-main content-right">
                        <div className="ladiui action-history">
                            <div className="link-to-report">
                                <Link to={`/reports?id=${id}`}
                                    style={{
                                        color: '#1c00c2', fontWeight: 'normal', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    }}
                                ><img src="https://w.ladicdn.com/ladiui/ladishare/report-link0808.svg" className="mr-8" />Xem chi tiết báo cáo</Link>
                            </div>
                            <ul className="ladiui nav nav-tabs new-tab-custom" id="tab-detail-affiliate" role="tablist">
                                <li className="ladiui nav-item" role="presentation">
                                    <span className="ladiui tab-link active" id="tab-detail-affiliate-dashboard-tab" data-toggle="tab"
                                        data-parent="myTab" data-target="tab-detail-affiliate-dashboard">Tổng quan</span>
                                </li>
                                <li className="ladiui nav-item" role="presentation" onClick={() => setLoadData({ ...loadData, ...{ customer: true } })}>
                                    <span className="ladiui tab-link" id="tab-detail-customer-tab" data-toggle="tab" data-parent="myTab"
                                        data-target="tab-detail-customer">Danh sách khách hàng</span>
                                </li>
                                <li className="ladiui nav-item" role="presentation" onClick={() => setLoadData({ ...loadData, ...{ invoice: true } })}>
                                    <span className="ladiui tab-link" id="tab-detail-affiliate-invoice-tab" data-toggle="tab" data-parent="myTab"
                                        data-target="tab-detail-affiliate-invoice">Hóa đơn thanh toán</span>
                                </li>
                            </ul>
                            <div className="ladiui tab-content" id="tab-detail-affiliate-content">
                                <div className="ladiui tab-pane fade show open" id="tab-detail-affiliate-dashboard" role="tabpanel"
                                    aria-labelledby="tab-detail-affiliate-dashboard-tab">
                                    <div className="ladiui">
                                        {/* Statistics */}
                                        <div className="ladiui content-main-title flex-row affiliate-statistics gap-20">
                                            <div className="ladiui box-statistics">
                                                <div>
                                                    <span className="font-size-18 bold-500">{statistics ? statistics.convert : 0}</span>
                                                    <div className={styles['text-box-1']}>Số chuyển đổi&nbsp;
                                                        <div className="tooltip">
                                                            <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                                                            <span className="tooltiptext top" tooltiptext="Chuyển đổi = Đơn hàng hoặc Lead"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ladiui box-statistics">
                                                <div>
                                                    <span className="font-size-18 bold-500">{statistics ? statistics.pendingCommission : 0}</span>
                                                    <div className={styles['text-box-1']}>Số hoa hồng chờ duyệt</div>
                                                </div>
                                            </div>
                                            <div className="ladiui box-statistics">
                                                <div>
                                                    <span className="font-size-18 bold-500">{statistics ? statistics.approvedCommission : 0}</span>
                                                    <div className={styles['text-box-1']}>Số hoa hồng tạm duyệt</div>
                                                </div>
                                            </div>
                                            <div className="ladiui box-statistics">
                                                <div>
                                                    <span className="font-size-18 bold-500">{statistics ? statistics.invoicedCommission : 0}</span>
                                                    <div className={styles['text-box-1']}>Số hoa hồng đã duyệt</div>
                                                </div>
                                            </div>
                                            <div className="ladiui box-statistics">
                                                <div>
                                                    <span className="font-size-18 bold-500">{statistics ? statistics.canceledCommission : 0}</span>
                                                    <div className={styles['text-box-1']}>Số hoa hồng đã huỷ</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Statistics */}

                                        {/* Commission */}
                                        <div className="bold-500 mt-16">
                                            <span>Thống kê hoa hồng </span>
                                            (<Link to={`/commissions?keyword=code:${affiliate.code}`}
                                                style={{ color: '#1c00c2', fontWeight: 'normal' }}
                                            >Xem danh sách hoa hồng</Link>)
                                        </div>
                                        <div className="ladiui content-main-title flex-row affiliate-statistics gap-16">
                                            <div className="ladiui box-statistics box-bg-grey">
                                                <div>
                                                    <span className="font-size-18 bold-500">
                                                        {BaseHelper.formatMoney(parseFloat(statistics ? statistics.pendingTotalCommission : 0))}
                                                    </span>
                                                    <div className="color-grey">Hoa hồng chờ duyệt</div>
                                                </div>
                                            </div>
                                            <div className="ladiui box-statistics box-bg-grey">
                                                <div>
                                                    <span className="font-size-18 bold-500">
                                                        {BaseHelper.formatMoney(parseFloat(statistics ? statistics.approvedTotalCommission : 0))}
                                                    </span>
                                                    <div className="color-grey">Hoa hồng tạm duyệt</div>
                                                </div>
                                            </div>
                                            <div className="ladiui box-statistics box-bg-grey">
                                                <div>
                                                    <span className="font-size-18 bold-500">
                                                        {BaseHelper.formatMoney(parseFloat(statistics ? statistics.invoicedTotalCommission : 0))}
                                                    </span>
                                                    <div className="color-grey">Hoa hồng đã duyệt</div>
                                                </div>
                                            </div>
                                            <div className="ladiui box-statistics box-bg-grey">
                                                <div>
                                                    <span className="font-size-18 bold-500">
                                                        {BaseHelper.formatMoney(parseFloat(affiliate.balance || 0))}
                                                    </span>
                                                    <div className="color-grey">Ví của tôi</div>
                                                </div>
                                                <div>
                                                    <div><button type="button" className="ladiui btn btn-primary btn-sm"
                                                        onClick={eventPurchase}>Thanh toán</button></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End commission */}

                                        {/* Timeline */}
                                        <div className="ladiui flex-row affiliate-timeline-note">
                                            <div>
                                                <span className="bold-500">Hoạt động</span>
                                                <div className="box box-timeline"></div>
                                            </div>
                                            <div>
                                                <span className="bold-500">{t('COMMON.NOTE')}</span>
                                                <NoteAffiliate idProp={affiliate._id} noteProp={affiliate.note} />
                                            </div>
                                        </div>
                                        {/* End timeline */}

                                    </div>
                                </div>
                                <div className="ladiui tab-pane fade" id="tab-detail-customer" role="tabpanel"
                                    aria-labelledby="tab-detail-customer-tab">
                                    <div className="ladiui">
                                        {loadData.customer ? <ListCustomer affiliateIdProp={affiliate._id} /> : <></>}
                                    </div>
                                </div>
                                <div className="ladiui tab-pane fade" id="tab-detail-affiliate-invoice" role="tabpanel"
                                    aria-labelledby="tab-detail-affiliate-invoice-tab">
                                    <div className="ladiui">
                                        {loadData.invoice ? <ListInvoice affiliateIdProp={affiliate._id} /> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalAffiliate
                    key={affiliate._id}
                    title={t('AFFILIATES.EDIT_AFFILIATE')}
                    btnOk={t('AFFILIATES.UPDATE')}
                    btnCancel={t('AFFILIATES.CANCELED')}
                    dataAffiliate={affiliate}
                    listAffiliateGroup={listAffiliateGroup}
                    cb={() => dispatch(actionAffiliate.show(id))} />

                <ModalPurchase
                    maximumPurchase={affiliate.balance}
                    affiliateId={affiliate._id}
                    cb={cbAffiliate}
                />
            </> : '');
}

export default (withTranslation()(AffiliateDetail));
