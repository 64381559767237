import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
// eslint-disable-next-line import/no-extraneous-dependencies
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector } from 'react-redux';
import BaseHelper from '../../helpers/BaseHelper';
import actionStatistics from '../../redux/futures/statistics/actions';
import styles from '../../assets/css/custom.module.css';
import HistoryActionPublisher from './components/HistoryActionPublisher';
import CampaignPublisher from './components/CampaignPublisher';
import WarningAccountActive from './components/WarningAccountActive';
import ChartConfig from './ChartConfig';
import FilterDashboardPublisher from './components/FilterDashboardPublisher';
import config from '../../config/config';

// eslint-disable-next-line max-lines-per-function
function DashboardPublisher(props) {
    const { t } = props;
    const options = ChartConfig.options;
    const chartLang = ChartConfig.lang;
    const dispatch = useDispatch();
    const [conditions, setConditions] = useState();
    const [highChartOptions, setHighChartOptions] = useState(options);
    const report = useSelector((state) => (state.statistics.dailyReportPublisher));
    const user = useSelector((state) => state.store.userInfo.user);
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));
    const store = useSelector((state) => (state.store.userInfo.store));

    const [isAffiliateDomain, setIsAffiliateDomain] = useState(false);

    const filterAction = (data) => {
        setConditions({ ...conditions, ...data });
    };

    const getCR = (p1, p2) => {
        if (p2 === 0) return 0;
        const rs = (100 * parseFloat(p1)) / parseFloat(p2);
        return Math.round((rs + Number.EPSILON) * 100) / 100;
    };

    const getUrlReferral = () => {
        if (!store) return '';
        const protocol = !store.is_verified_url_affiliate ? 'http' : 'https';
        const urlRef = store.url_affiliate
            ? (`${protocol}://${store.url_affiliate}/register?ref=${user ? user.code : ''}`)
            : `${config.AFFILIATE_DOMAIN}/${store.alias}/register?ref=${user ? user.code : ''}`;
        return urlRef;
    };

    const copyClipboard = (id) => {
        if (!navigator.clipboard) return;

        const copyText = document.getElementById(id);
        navigator.clipboard.writeText(copyText.value);
        window.LadiUI.toast('success', '', t('COMMON.COPIED'), 5000, 'bottom-left');
    };

    useEffect(() => {
        const pointStartPendingCommission = report && report.chartData ? new Date(report.chartData.chartDataPendingCommission.start) : new Date();
        const pointStartApprovedCommission = report && report.chartData ? new Date(report.chartData.chartDataApprovedCommission.start) : new Date();
        const pointStartInvoicedCommission = report && report.chartData ? new Date(report.chartData.chartDataInvoicedCommission.start) : new Date();

        const pointStartNumberPendingCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberPendingCommission.start) : new Date();
        const pointStartNumberApprovedCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberApprovedCommission.start) : new Date();
        const pointStartNumberInvoicedCommission = report && report.chartData ? new Date(report.chartData.chartDataNumberInvoicedCommission.start) : new Date();

        const series = [
            {
                name: 'Số hoa hồng chờ duyệt',
                color: '#E7B269',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberPendingCommission.data : [],
                pointStart: pointStartNumberPendingCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Số hoa hồng tạm duyệt',
                color: 'rgba(28, 0, 194, 0.65)',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberApprovedCommission.data : [],
                pointStart: pointStartNumberApprovedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Số hoa hồng đã duyệt',
                color: '#41B983',
                type: 'column',
                data: report && report.chartData ? report.chartData.chartDataNumberInvoicedCommission.data : [],
                pointStart: pointStartNumberInvoicedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 0,
            },
            {
                name: 'Hoa hồng chờ duyệt',
                color: '#E7B269',
                data: report && report.chartData ? report.chartData.chartDataPendingCommission.data : [],
                pointStart: pointStartPendingCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
            {
                name: 'Hoa hồng tạm duyệt',
                color: 'rgba(28, 0, 194, 0.65)',
                data: report && report.chartData ? report.chartData.chartDataApprovedCommission.data : [],
                pointStart: pointStartApprovedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
            {
                name: 'Hoa hồng đã duyệt',
                color: '#41B983',
                data: report && report.chartData ? report.chartData.chartDataInvoicedCommission.data : [],
                pointStart: pointStartInvoicedCommission.getTime(),
                pointInterval: 3600 * 1000 * 24,
                yAxis: 1,
            },
        ];

        setHighChartOptions({ ...highChartOptions, series });
    }, [report]);

    useEffect(() => {
        if (conditions) dispatch(actionStatistics.dailyReportPublisher(conditions));
    }, [conditions]);

    useEffect(() => {
        Highcharts.setOptions({
            lang: chartLang,
        });

        let domainUrl = window.location.href;
        if (domainUrl.includes('affiliate.ladipage.com') || domainUrl.includes('aff.dev.ladishare.com')) {
            setIsAffiliateDomain(true);
        }
    }, []);

    const renderTotalOrder = () => {
        let total = 0;
        let totalPending = report && report.totalPendingCommission ? report.totalPendingCommission : 0;
        let totalApproved = report && report.totalApprovedCommission ? report.totalApprovedCommission : 0;
        let totalInvoiced = report && report.totalInvoicedCommission ? report.totalInvoicedCommission : 0;
        if (report) {
            total = BaseHelper.formatNumber(totalPending + totalApproved + totalInvoiced);
        }

        return total;
    }

    const copyLinkRefClipboard = () => {
        if (!navigator.clipboard) return;

        const copyText = `https://ladipage.vn/?ref=${user.code}`;
        navigator.clipboard.writeText(copyText);
        window.LadiUI.toast('success', '', t('COMMON.COPIED'), 5000, 'bottom-left');
    }

    return <>
        <div className="ladiui content-main-full">
            <WarningAccountActive />
            <div className="ladiui content-main-title content-main-report flex-row">
                <div className="ladiui flex-row header-main-report">
                    <div className="ladiui main-title main-title-dashboard">
                        Tổng quan
                    </div>
                    <FilterDashboardPublisher filterAction={filterAction} />
                </div>
            </div>
            <div className="ladiui content-main-title dashboard-publisher">
                <div className="ladiui flex-row gap-24 align-item-top">
                    <div className="dashboard-left-publisher">
                        <div className="ladiui flex-row gap-24 dashboard-statistics-publisher">
                            {
                                isAffiliateDomain
                                ?
                                    <>
                                        <div className={styles['box-statistics-publisher']}>
                                            <div className={styles['box-content-publisher-name']}>Khách hàng</div>
                                            <div
                                                className={styles['box-content-publisher-title']}>{report && report.customerStatics ? report.customerStatics.total_invited : 0}
                                            </div>
                                            <div className={styles['box-content-publisher-number']}>
                                                <div className="tooltip">
                                                    <img
                                                        src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg"
                                                        alt=""
                                                        width="13" style={{marginBottom: '3px'}}></img>
                                                    <span className="tooltiptext bottom center max-w-275"
                                                          tooltiptext="Số lượng leads đăng ký tài khoản LadiPage"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles['box-statistics-publisher']}>
                                            <div className={styles['box-content-publisher-name']}>Tổng lượt truy cập
                                            </div>
                                            <div
                                                className={styles['box-content-publisher-title']}>{report && report.trafficReport ? report.trafficReport.total_traffic : 0} lượt
                                            </div>
                                            <div className={styles['box-content-publisher-number']}>
                                                <div className="tooltip">
                                                    <img
                                                        src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg"
                                                        alt=""
                                                        width="13" style={{marginBottom: '3px'}}></img>
                                                    <span className="tooltiptext bottom center"
                                                          tooltiptext="Tổng lượt truy cập"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles['box-statistics-publisher']}>
                                            <div className={styles['box-content-publisher-name']}>Người truy cập
                                            </div>
                                            <div
                                                className={styles['box-content-publisher-title']}>{report && report.trafficReport ? report.trafficReport.unique_traffic : 0} lượt
                                            </div>
                                            <div className={styles['box-content-publisher-number']}>
                                                <div className="tooltip">
                                                    <img
                                                        src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg"
                                                        alt=""
                                                        width="13" style={{marginBottom: '3px'}}></img>
                                                    <span className="tooltiptext bottom center"
                                                          tooltiptext="Tổng lượt truy cập"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles['box-statistics-publisher']}>
                                            <div className={styles['box-content-publisher-name']}>Tổng đơn hàng</div>
                                            <div
                                                className={styles['box-content-publisher-title']}>{report && report.customerStatics ? report.customerStatics.total_order_count : 0} đơn
                                            </div>
                                            <div className={styles['box-content-publisher-number']}>
                                                {/*<div>{BaseHelper.formatNumber(report ? report.numberPendingCommission : 0)}</div>*/}
                                                {/*<div>&nbsp;({getCR(report ? report.numberPendingCommission : 0, report ? report.totalConvert : 0)}%)&nbsp;</div>*/}
                                                <div className="tooltip">
                                                    <img
                                                        src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg"
                                                        alt=""
                                                        width="13" style={{marginBottom: '3px'}}></img>
                                                    <span className="tooltiptext bottom center max-w-275"
                                                          tooltiptext="Số lượng khách hàng trả phí thành công"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles['box-statistics-publisher']}>
                                            <div className={styles['box-content-publisher-name']}>Hoa hồng đã duyệt
                                            </div>
                                            <div
                                                className={styles['box-content-publisher-title']}>{BaseHelper.formatMoney(report ? report.totalInvoicedCommission : 0, '', systemCurrency)}</div>
                                            <div className={styles['box-content-publisher-number']}>
                                                <div>{BaseHelper.formatNumber(report ? report.numberInvoicedCommission : 0)}</div>
                                                <div>&nbsp;({getCR(report ? report.numberInvoicedCommission : 0, report ? report.numberApprovedCommission : 0)}%)&nbsp;</div>
                                                <div className="tooltip">
                                                    <img
                                                        src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg"
                                                        alt=""
                                                        width="13" style={{marginBottom: '3px'}}></img>
                                                    <span className="tooltiptext top center"
                                                          tooltiptext="CR = Đã duyệt/Tạm duyệt"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                    <div className={`${styles['box-statistics-publisher']}`}>
                                        <div className={styles['box-content-publisher-name']} style={{marginTop: '10px'}}>Chuyển
                                            đổi&nbsp;
                                            <div className="tooltip">
                                                <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt=""
                                                     width="13" style={{marginBottom: '3px'}}></img>
                                                <span className="tooltiptext bottom"
                                                      tooltiptext="Chuyển đổi = Đơn hàng hoặc Lead"></span>
                                            </div>
                                        </div>
                                <div
                                    className={styles['box-content-publisher-title']}>{BaseHelper.formatNumber(report ? report.totalConvert : 0)}</div>
                            </div>
                                    <div className={styles['box-statistics-publisher']}>
                                        <div className={styles['box-content-publisher-name']}>Hoa hồng chờ duyệt</div>
                                        <div
                                            className={styles['box-content-publisher-title']}>{BaseHelper.formatMoney(report ? report.totalPendingCommission : 0, '', systemCurrency)}</div>
                                        <div className={styles['box-content-publisher-number']}>
                                            <div>{BaseHelper.formatNumber(report ? report.numberPendingCommission : 0)}</div>
                                            <div>&nbsp;({getCR(report ? report.numberPendingCommission : 0, report ? report.totalConvert : 0)}%)&nbsp;</div>
                                            <div className="tooltip">
                                                <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt=""
                                                     width="13" style={{marginBottom: '3px'}}></img>
                                                <span className="tooltiptext bottom left"
                                                      tooltiptext="CR = Chờ duyệt/Số chuyển đổi"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles['box-statistics-publisher']}>
                                        <div className={styles['box-content-publisher-name']}>Hoa hồng tạm duyệt</div>
                                        <div
                                            className={styles['box-content-publisher-title']}>{BaseHelper.formatMoney(report ? report.totalApprovedCommission : 0, '', systemCurrency)}</div>
                                        <div className={styles['box-content-publisher-number']}>
                                            <div>{BaseHelper.formatNumber(report ? report.numberApprovedCommission : 0)}</div>
                                            <div>&nbsp;({getCR(report ? report.numberApprovedCommission : 0, report ? report.numberPendingCommission : 0)}%)&nbsp;</div>
                                            <div className="tooltip">
                                                <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt=""
                                                     width="13" style={{marginBottom: '3px'}}></img>
                                                <span className="tooltiptext top" tooltiptext="CR = Tạm duyệt/Chờ duyệt"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles['box-statistics-publisher']}>
                                        <div className={styles['box-content-publisher-name']}>Hoa hồng đã duyệt</div>
                                        <div
                                            className={styles['box-content-publisher-title']}>{BaseHelper.formatMoney(report ? report.totalInvoicedCommission : 0, '', systemCurrency)}</div>
                                        <div className={styles['box-content-publisher-number']}>
                                            <div>{BaseHelper.formatNumber(report ? report.numberInvoicedCommission : 0)}</div>
                                            <div>&nbsp;({getCR(report ? report.numberInvoicedCommission : 0, report ? report.numberApprovedCommission : 0)}%)&nbsp;</div>
                                            <div className="tooltip">
                                                <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt=""
                                                     width="13" style={{marginBottom: '3px'}}></img>
                                                <span className="tooltiptext top left"
                                                      tooltiptext="CR = Đã duyệt/Tạm duyệt"></span>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                            }
                        </div>

                        <div
                            className={`${styles['box-statistics-publisher']} ${styles['no-height']} ${styles['mt-16']} ${styles['p-0']}`}>
                            <div className={styles['box-title']}>
                                <label className={styles.bold}>Thống kê hoa hồng và chuyển đổi</label>
                                <div className="ladiui">
                                </div>
                            </div>
                            <div className="box-body" style={{overflowX: 'auto'}}>
                                <div style={{minWidth: '650px'}}>
                                    <HighchartsReact highcharts={Highcharts} options={highChartOptions}/>
                                </div>
                            </div>
                            <div className="box-footer"></div>
                        </div>

                    </div>
                    <div className="dashboard-right-publisher">
                        {
                            isAffiliateDomain && user && user.ranking
                            &&
                            <div
                                className={`${styles['box-statistics-publisher']}`} style={{ marginBottom: "16px" }}>
                                <div
                                    className={`${styles['box-statistics-publisher-2']}`}>
                                    <div className={styles['box-statistics-publisher-img']}>
                                        {
                                            user.ranking.label == "Diamond"
                                                ?
                                                <img src="https://w.ladicdn.com/ladiui/icons/ldicon-diamond-plan.svg"/>
                                                :
                                                user.ranking.label == "Silver"
                                                    ? <img
                                                        src="https://w.ladicdn.com/ladiui/icons/ldicon-silver-plan.svg"/>
                                                    : <img
                                                        src="https://w.ladicdn.com/ladiui/icons/ldicon-gold-plan.svg"/>
                                        }

                                    </div>
                                    <div className={styles['box-statistics-publisher-detail']}>
                                        <div className={styles['box-statistics-publisher-detail-title']}>Thành viên</div>
                                        <div className={styles['box-statistics-publisher-title-membership-class']}>Hạng {user.ranking ? user.ranking.label : ""}</div>
                                    </div>
                                </div>
                                <div className={styles['box-statistics-publisher-desc-membership-class']}>
                                    <span>Cấp độ sẽ được cập nhật lại sau ngày 30/09/2024. <a style={{ color: "#1c00c2" }} id="view-detail-box-statistics">Xem chi tiết</a></span>
                                </div>
                            </div>
                        }


                        <div
                            className={`${styles['box-statistics-publisher']} ${styles['box-statistics-publisher-2']}`}>
                            <div className={styles['box-statistics-publisher-img']}>
                                <img src="https://w.ladicdn.com/ladiui/ladishare/wallet0808.svg" />
                            </div>
                            <div className={styles['box-statistics-publisher-detail']}>
                                <div className={styles['box-statistics-publisher-detail-title']}>Ví của tôi</div>
                                <div
                                    className={styles['box-statistics-publisher-detail-amount']}>{BaseHelper.formatMoney(user ? user.balance : 0, '', systemCurrency)}</div>
                                <div className={styles['box-statistics-publisher-detail-purchase']}>
                                    <div className={styles['box-statistics-publisher-detail-purchase-title']}>Số tiền đã
                                        nhận
                                    </div>
                                    <div
                                        className={styles['box-statistics-publisher-detail-purchase-amount']}>{BaseHelper.formatMoney(user ? user.paid_money : 0, '', systemCurrency)}</div>
                                </div>
                            </div>
                        </div>


                        {
                            isAffiliateDomain
                                ?
                                <>
                                    <div className={`${styles['box-statistics-publisher']} ${styles['no-height']} ${styles['p-0']} ${styles['mt-16']}`}>
                                        <div className={styles['box-title']}>
                                            <label className={styles.bold}>Link giới thiệu của bạn</label>
                                        </div>
                                        <div className="box-body">
                                            <div className={styles['m-16']}>
                                                <div className="ladiui form-group">
                                                    {user && user.ref
                                                        ?
                                                        <div className="ladiui mb-10"><span>Bạn được giới thiệu bởi: </span><span
                                                            className="ladiui bold">{user.ref.code}</span></div>
                                                        : <></>}
                                                    <div className="ladiui mb-10">
                                                        Giới thiệu khách hàng đăng ký tài khoản LadiPage qua link có mã của bạn.
                                                    </div>
                                                    <div style={{position: 'relative'}}>
                                                        <input className="ladiui form-control input-base-link"
                                                               readOnly={true}
                                                               value={`https://ladipage.vn/?ref=${user.code}`} id="url-referral-aff"/>
                                                        <a href="#/" className="get-short-link"
                                                           onClick={() => copyLinkRefClipboard()}>
                                                            <i className="ladiui icon icon-ldp-replication"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-footer"></div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={`${styles['box-statistics-publisher']} ${styles['no-height']} ${styles['p-0']} ${styles['mt-16']}`}>
                                        <div className={styles['box-title']}>
                                            <label className={styles.bold}>Giới thiệu bạn bè</label>
                                        </div>
                                        <div className="box-body">
                                            <div className={styles['m-16']}>
                                                <div className="ladiui form-group">
                                                    {user && user.ref
                                                        ?
                                                        <div className="ladiui mb-10"><span>Bạn được giới thiệu bởi: </span><span
                                                            className="ladiui bold">{user.ref.code}</span></div>
                                                        : <></>}
                                                    <div className="ladiui mb-10">Hãy giới thiệu và mời người khác tham gia
                                                        cùng bạn
                                                        để được chia sẻ hoa hồng từ họ
                                                    </div>
                                                    <div style={{position: 'relative'}}>
                                                        <input className="ladiui form-control input-base-link"
                                                               readOnly={true}
                                                               value={getUrlReferral()} id="url-referral-aff"/>
                                                        <a href="#/" className="get-short-link"
                                                           onClick={() => copyClipboard('url-referral-aff')}>
                                                            <i className="ladiui icon icon-ldp-replication"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-footer"></div>
                                    </div>
                                </>
                        }
                        {
                            isAffiliateDomain && user && user.customer_statics && user.customer_statics.total_invited > 0
                                ?
                                <div className="ladiui block-banner-affiliate custom-v2">
                                    <a href="http://ldp.ink/0aqZa" target="_blank">
                                        <img src="https://w.ladicdn.com/5938d6181deeeec1a5d3ffaf/300x150-banner-minicamp-ladipage-learning3x-100-20240628092422-pfdgw.jpg"/>
                                    </a>
                                </div>
                                : ""
                        }
                        <CampaignPublisher/>

                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(DashboardPublisher));
