import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LadiPagination from '../../../components/LadiPagination';
import trafficAction from '../../../redux/futures/source/actions';
import DefineConst from '../DefineConst';
import DefineConstAppIntegrate from '../../apps/DefineConst';
import BaseHelper from '../../../helpers/BaseHelper';
import ConfirmModal from '../../../components/ConfirmModal';

// eslint-disable-next-line max-lines-per-function
function Order(props) {
    const { t, conditionProps } = props;

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState();
    const [traffic, setTraffic] = useState({});

    const listLead = useSelector((state) => ({ ...{ items: [], affiliates: [], total: 0 }, ...state.traffics.leads }));

    const cb = () => {
        dispatch(trafficAction.list(conditions));
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventCalcCommission = (item) => {
        setTraffic(item);
        window.LadiUI.showModal('modal-confirm-calc-commission-for-lead');
    };

    const eventOkCalcCommissionForTraffic = () => {
        const data = { id: traffic._id };
        dispatch(trafficAction.calcCommissionForTraffic(data, cb));
    };

    const eventReCalcCommission = (item) => {
        setTraffic(item);
        window.LadiUI.showModal('modal-confirm-re-calc-commission-for-lead');
    };

    const eventOkReCalcCommissionForTraffic = () => {
        const data = { id: traffic._id };
        dispatch(trafficAction.reCalcCommissionForTraffic(data, cb));
    };

    const eventCancelTraffic = (item) => {
        setTraffic(item);
        window.LadiUI.showModal('modal-confirm-cancel-commission-for-lead');
    };

    const eventOkCancelCommissionForTraffic = () => {
        const data = { id: traffic._id };
        dispatch(trafficAction.cancelCommissionForTraffic(data, cb));
    };

    const eventDeleteTraffic = (item) => {
        setTraffic(item);
        window.LadiUI.showModal('modal-confirm-delete-lead');
    };

    const eventOkDeleteTraffic = () => {
        const data = { id: traffic._id };
        dispatch(trafficAction.delete(data, cb));
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.TRAFFIC_STATUS_VALUES.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-warning"></i>
                        <div>
                            {DefineConst.TRAFFIC_STATUS.PENDING}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.TRAFFIC_STATUS_VALUES.CALCULATE_COMMISSION:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {DefineConst.TRAFFIC_STATUS.CALCULATE_COMMISSION}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.TRAFFIC_STATUS_VALUES.RE_CALCULATE_COMMISSION:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {DefineConst.TRAFFIC_STATUS.RE_CALCULATE_COMMISSION}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.TRAFFIC_STATUS_VALUES.CANCELED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-mute"></i>
                        <div>
                            {DefineConst.TRAFFIC_STATUS.CANCELED}
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    useEffect(() => {
        setConditions({ ...conditions, ...conditionProps, type: DefineConst.TRAFFIC_TYPE.LEAD });
    }, [conditionProps]);

    useEffect(() => {
        if (conditions) dispatch(trafficAction.listLead(conditions));
    }, [conditions]);

    return <>
        <table className="ladiui table text-left">
            <thead>
                <tr className="ladiui table-vertical header">
                    <th scope="col">
                        <input data-toggle="checkbox" type="checkbox" className="ladiui checkbox-all size-checkbox form-check-input-checkbox"
                            name="checkbox" />
                    </th>
                    <th scope="col">Afffiliate</th>
                    <th scope="col">Email</th>
                    <th scope="col">Số điện thoại</th>
                    <th scope="col">Nguồn</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">{t('COMMON.CREATED_AT')}</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {listLead.total > 0
                    ? listLead.items.map((item) => <tr className="ladiui table-vertical main" key={item._id}>
                        <td scope="row">
                            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" name="checkbox" />
                        </td>
                        <td>
                            {listLead.affiliates.map((affiliate) => (affiliate._id === item.affiliate_id
                                ? <Link key={affiliate._id} to={`/affiliates/${affiliate._id}`}>{affiliate.email}</Link> : ''))}
                        </td>
                        <td>
                            {item.email}
                        </td>
                        <td>
                            {item.phone}
                        </td>
                        <td>
                            {
                                item.sources ? item.sources.map((source) => (<div key={source}>
                                    <img src={source ? DefineConstAppIntegrate.INTEGRATE_APPS[source].logo : ''} height="20" />&nbsp;
                                    {source ? DefineConstAppIntegrate.INTEGRATE_APPS[source].name : ''}
                                </div>)) : <></>
                            }
                        </td>
                        <td>{getStatus(item.status)}</td>
                        <td>{BaseHelper.formatStrToDate(item.created_at)}</td>
                        <td className="ladiui-dropdown">
                            <div className="ladiui btn-group">
                                <div className="ladiui dropdown">
                                    <button data-toggle="dropdown"
                                        className="ladiui-btn-dropdown dropdown-toggle">
                                        <i className="ladiui icon icon-ldp-dot"></i>
                                    </button>
                                    <ul className="ladiui dropdown-menu custom-dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1">
                                        {item.status === DefineConst.TRAFFIC_STATUS_VALUES.PENDING
                                            || item.status === DefineConst.TRAFFIC_STATUS_VALUES.CANCELED
                                            ? <li onClick={() => eventCalcCommission(item)}>
                                                <a className="ladiui dropdown-item" href="#/">
                                                    Tính hoa hồng
                                                </a>
                                            </li> : <></>
                                        }
                                        {item.status === DefineConst.TRAFFIC_STATUS_VALUES.CALCULATE_COMMISSION
                                            ? <li onClick={() => eventReCalcCommission(item)}>
                                                <a className="ladiui dropdown-item" href="#/">
                                                    Tính lại hoa hồng
                                                </a>
                                            </li> : <></>
                                        }
                                        {item.status === DefineConst.TRAFFIC_STATUS_VALUES.CALCULATE_COMMISSION
                                            || item.status === DefineConst.TRAFFIC_STATUS_VALUES.RE_CALCULATE_COMMISSION
                                            ? <li onClick={() => eventCancelTraffic(item)}>
                                                <a className="ladiui dropdown-item" href="#/">
                                                    Hủy hoa hồng
                                                </a>
                                            </li> : <></>
                                        }
                                        {item.status === DefineConst.TRAFFIC_STATUS_VALUES.PENDING
                                            || item.status === DefineConst.TRAFFIC_STATUS_VALUES.CANCELED
                                            ? <li onClick={() => eventDeleteTraffic(item)}>
                                                <a className="ladiui dropdown-item lastChild" href="#/">
                                                    Xoá
                                                </a>
                                            </li> : <></>
                                        }

                                    </ul>
                                </div>
                            </div>
                        </td>
                    </tr>)
                    : <tr><td colSpan={8}>{t('SOURCES.NO_LEAD_EXIST')}</td></tr>
                }
            </tbody>
        </table>
        {listLead.total > 0
            ? <LadiPagination
                conditionsProp={conditions}
                listName={t('SOURCES.LEAD')}
                items={listLead.items}
                total={listLead.total}
                limitAction={selectLimit}
                pageAction={selectPage}
            /> : <></>}
        <ConfirmModal
            id="modal-confirm-delete-lead"
            title="Bạn có chắc muốn xoá nguồn này không?"
            content="Sau khi xoá nguồn sẽ bị xoá vĩnh viễn bạn sẽ không thể xem lại được nữa"
            onOk={() => eventOkDeleteTraffic()}
            cancelText={t('SOURCES.CANCELED')}
            okText={t('SOURCES.OK')}
            classBtn="btn-danger"
        />

        <ConfirmModal
            id="modal-confirm-calc-commission-for-lead"
            title="Bạn có chắc muốn tính hoa hồng cho nguồn này không?"
            content="Bạn chỉ có thể tính lại hoa hồng khi chúng chưa được lên hóa đơn."
            onOk={() => eventOkCalcCommissionForTraffic()}
            cancelText={t('SOURCES.CANCELED')}
            okText={t('SOURCES.OK')}
            classBtn="btn-primary"
        />

        <ConfirmModal
            id="modal-confirm-re-calc-commission-for-lead"
            title="Bạn có chắc muốn tính lại hoa hồng cho nguồn này không?"
            content="Bạn chỉ có thể tính lại hoa hồng khi chúng chưa được lên hóa đơn."
            onOk={() => eventOkReCalcCommissionForTraffic()}
            cancelText={t('SOURCES.CANCELED')}
            okText={t('SOURCES.OK')}
            classBtn="btn-primary"
        />

        <ConfirmModal
            id="modal-confirm-cancel-commission-for-lead"
            title="Bạn có chắc muốn hủy hoa hồng cho nguồn này không?"
            content="Dữ liệu hoa hồng cho nguồn này sẽ bị xóa vĩnh viễn."
            onOk={() => eventOkCancelCommissionForTraffic()}
            cancelText={t('SOURCES.CANCELED')}
            okText={t('SOURCES.OK')}
            classBtn="btn-primary"
        />
    </>;
}

export default (withTranslation()(Order));
