import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiDateRangePicker from '../../components/LadiDateRangePicker';
import BaseHelper from '../../helpers/BaseHelper';
import appConfig from '../../config/app';
import SearchCondition from './components/SearchCondition';
import FilterCondition from './components/FilterCondition';
import DefineConst from './DefineConst';
import actionStore from '../../redux/futures/store/actions';
import actionRequestInvoice from '../../redux/futures/request_invoice/actions';
import config from '../../config/config';
import LadiPagination from '../../components/LadiPagination';
import CheckAllActionRequestInvoice from './components/CheckAllActionRequestInvoice';
import ConfirmModal from '../../components/ConfirmModal';
import ModalInvoiceRequestDetail from './components/ModalInvoiceRequestDetail';
import ModalRequestWithdraw from '../invoices/components/ModalRequestWithdraw';

// eslint-disable-next-line max-lines-per-function
function RequestInvoice(props) {
    const { t } = props;

    const defaultCondtions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { _id: 'DESC' },
    };
    const dispatch = useDispatch();
    const moment = BaseHelper.getMoment();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [conditions, setConditions] = useState(defaultCondtions);
    const [selectedIds, setSelectedIds] = useState([]);
    const [invoiceRequest, setInvoiceRequest] = useState(null);


    const [checkAll, setCheckAll] = useState(false);
    const listRequestInvoice = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.requestInvoice.requestInvoices }));
    const requestWithdrawSetting = useSelector((state) => state.store.requestWithdrawSetting);

    const filterActionProp = (conditionRecived) => {
        setConditions({ ...conditionRecived });
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, limit: number, page: 1 });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, page: number });
    };

    const cbLoadList = () => {
        dispatch(actionRequestInvoice.list(conditions));
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
    };


    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.INVOICE_STATUS.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-warning"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.PENDING')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.APPROVED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.APPROVED')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.CANCELED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-mute"></i>
                        <div>
                            {t('INVOICES.INVOICE_STATUS.CANCELED')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.INVOICE_STATUS.REJECT:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-mute"></i>
                        <div>
                            Từ chối
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    const showModalDetailRequest = (item) => {
        setInvoiceRequest(item);
        window.LadiUI.showModal('modal-detail-invoice-request');
        dispatch(actionRequestInvoice.show(item._id));
    };

    const eventConfirmApprovedInvoiceRequest = (item) => {
        setInvoiceRequest(item);
        window.LadiUI.showModal('modal-confirm-approved-invoice-request');
    };

    const eventOkUpdateStatusApprovedInvoiceRequest = () => {
        dispatch(actionRequestInvoice.approvedInvoiceRequest({ id: invoiceRequest._id }, cbLoadList));
    };

    const eventConfirmRejectInvoiceRequest = (item) => {
        setInvoiceRequest(item);
        window.LadiUI.showModal('modal-reject-approved-invoice-request');
    };

    const eventOkUpdateStatusRejectInvoiceRequest = () => {
        dispatch(actionRequestInvoice.rejectInvoiceRequest({ id: invoiceRequest._id }, cbLoadList));
    };

    const eventConfirmCancelInvoiceRequest = (item) => {
        setInvoiceRequest(item);
        window.LadiUI.showModal('modal-cancel-approved-invoice-request');
    };

    const eventOkUpdateStatusCancelInvoiceRequest = () => {
        dispatch(actionRequestInvoice.cancelInvoiceRequest({ id: invoiceRequest._id }, cbLoadList));
    };

    const renderRecord = (item) => <tr className="ladiui table-vertical main" key={item._id}>
        <td scope="row">
            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element"
                name="checkbox" checked={selectedIds.includes(item._id)}
                value={item._id} onChange={eventCheck} />
        </td>

        <td>{item.affiliate.code}</td>
        <td>{item.affiliate.email}</td>
        <td>{getStatus(item.status)}</td>
        <td>{BaseHelper.formatStrToDate(item.created_at || null, 'DD/MM/YYYY')}</td>
        <td className="ladiui text-right">{item.commission_ids.length}</td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(item.total || 0)}</td>
        <td className="ladiui-dropdown">
            <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                    <button data-toggle="dropdown"
                        className="ladiui-btn-dropdown dropdown-toggle">
                        <i className="ladiui icon icon-ldp-dot"></i>
                    </button>
                    <ul className="ladiui dropdown-menu custom-dropdown-menu"
                        aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a className="ladiui dropdown-item" href="#/" onClick={() => showModalDetailRequest(item)}>
                                Chi tiết
                            </a>
                        </li>
                        {!config.APP_AFFILIATE
                            ? <>
                                {item.status === DefineConst.INVOICE_STATUS.PENDING
                                    ? <li>
                                        <a className="ladiui dropdown-item" href="#/"
                                            onClick={() => eventConfirmApprovedInvoiceRequest(item)}>
                                            Duyệt yêu cầu
                                        </a>
                                    </li> : <></>
                                }
                                {item.status === DefineConst.INVOICE_STATUS.PENDING
                                    ? <li>
                                        <a className="ladiui dropdown-item" href="#/"
                                            onClick={() => eventConfirmRejectInvoiceRequest(item)}>
                                            Từ chối
                                        </a>
                                    </li> : <></>
                                }
                            </>
                            : <>
                                {item.status === DefineConst.INVOICE_STATUS.PENDING
                                    ? <li>
                                        <a className="ladiui dropdown-item" href="#/"
                                            onClick={() => eventConfirmCancelInvoiceRequest(item)}>
                                            Huỷ
                                        </a>
                                    </li> : <></>
                                }
                            </>}

                    </ul>
                </div>
            </div>
        </td>
    </tr>;

    const openModalRequestWithdraw = () => {
        window.LadiUI.showModal('modal-create-request-withdraw');
    };

    useEffect(() => {
        dispatch(actionStore.getRequestWithdrawSetting());
    }, []);

    useEffect(() => {
        cbLoadList();
    }, [conditions]);

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title flex-row content-main-report">
                <div className="ladiui flex-row header-main-report">
                    <div className="ladiui main-title">
                        Danh sách yêu cầu
                    </div>
                    <div className="calendar-search">
                        <LadiDateRangePicker
                            startDateId="fromDate"
                            endDateId="toDate"
                            startDate={fromDate}
                            endDate={toDate}
                            onDatesChange={({ startDate, endDate }) => {
                                const startDateNew = BaseHelper.getFromDate(startDate);
                                const endDateNew = BaseHelper.getToDate(endDate);
                                setFromDate(startDateNew);
                                setToDate(endDateNew);
                                if ((startDateNew && endDateNew) || (!startDateNew && !endDateNew)) {
                                    setConditions({ ...conditions, ...{ date_from: startDateNew ? startDateNew.format('YYYY-MM-DD') : null, date_to: endDateNew ? endDateNew.format('YYYY-MM-DD') : null } });
                                }
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => {
                                setFocusedInput(focusedInput);
                            }}
                            showDefaultInputIcon={true}
                            inputIconPosition="after"
                            small={true}
                            hideKeyboardShortcutsPanel={true}
                            customInputIcon={
                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                            }
                            startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            isOutsideRange={() => false}
                            isDayHighlighted={(date) => moment().isSame(date, 'day')}
                            minimumNights={0}
                            showClearDates={true}
                        />
                    </div>
                </div>
                <div className="ladiui flex" style={{ gap: 12 }}>
                    {config.APP_AFFILIATE && requestWithdrawSetting && requestWithdrawSetting.enable_create_request_withdraw_money
                        ? <button className="ladiui btn btn-primary btn-request-invoice" style={{ minWidth: 0 }}
                            onClick={() => openModalRequestWithdraw()}>
                            Tạo yêu cầu rút tiền
                        </button>
                        : <></>}
                </div>
            </div>
            <div className="ladiui content-main-tool flex-row align-item-baseline content-main-tool-2">
                <div className="ladiui btn-group main-filter-condition">
                    <FilterCondition conditionProp={conditions} filterActionProp={filterActionProp} />
                </div>
                <div className="main-search-condition">
                    <SearchCondition
                        keywordProp={conditions.keyword}
                        eventSearchKeyboardProp={eventSearchKeyboard}
                        eventClickSearchButtonProp={eventClickSearchButton}
                    />
                </div>
            </div>
            <div className="ladiui mobile-table-scroll">
                <table className="ladiui table text-left" style={{ minWidth: '1050px' }}>
                    <thead>
                        <tr className="ladiui table-vertical header">
                            <th className="ladiui col-first" scope="col">
                                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-invoice"
                                    name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                            </th>
                            <th className="col-action" style={{ width: '170px' }}>
                                {selectedIds.length > 0 ? <CheckAllActionRequestInvoice ids={selectedIds} cb={() => cbLoadList()} /> : t('AFFILIATES.CODE_AFFILIATE')}
                            </th>
                            <th>{t('AFFILIATES.EMAIL')}</th>
                            <th>{t('INVOICES.STATUS')}</th>
                            <th scope="col">Ngày tạo</th>
                            <th scope="col" className="ladiui text-right">Tổng số hoa hồng</th>
                            <th scope="col" className="ladiui text-right">Tổng tiền hoa hồng</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listRequestInvoice.items.length > 0
                            ? listRequestInvoice.items.map((values) => (renderRecord(values)))
                            : (<tr className="ladiui table-vertical main"><td colSpan={config.APP_AFFILIATE ? 6 : 7} className="text-center"><div>{t('INVOICES.NO_INVOICE')}</div></td></tr>)}
                    </tbody>
                </table>
            </div>
            {listRequestInvoice.items.length > 0
                ? <LadiPagination
                    conditionsProp={conditions}
                    listName="yêu cầu"
                    items={listRequestInvoice.items}
                    total={listRequestInvoice.total}
                    limitAction={selectLimit}
                    pageAction={selectPage}
                /> : ''}
        </div>

        <ModalInvoiceRequestDetail />

        {config.APP_AFFILIATE
            ? <ModalRequestWithdraw cb={cbLoadList} />
            : <></>}

        <ConfirmModal
            id="modal-confirm-approved-invoice-request"
            title='Bạn có chắc chắn muốn duyệt yêu cầu rút tiền?'
            content={<div>Hệ thống sẽ tự tạo hoá đơn dựa trên số hoa hồng được liệt kê trong yêu cầu
            </div>}
            onOk={() => eventOkUpdateStatusApprovedInvoiceRequest()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="modal-reject-approved-invoice-request"
            title='Bạn có chắc chắn muốn từ chối yêu cầu rút tiền?'
            content={<div>Yêu cầu chưa được duyệt sẽ được phép đổi trạng thái sang từ chối
            </div>}
            onOk={() => eventOkUpdateStatusRejectInvoiceRequest()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="modal-cancel-approved-invoice-request"
            title='Bạn có chắc chắn muốn huỷ yêu cầu rút tiền?'
            content={<div>Yêu cầu chưa được duyệt sẽ được phép huỷ
            </div>}
            onOk={() => eventOkUpdateStatusCancelInvoiceRequest()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />
    </>;
}

export default (withTranslation()(RequestInvoice));
