import i18n from '../../i18n';

export default {
    TIME_TYPE: {
        MONTH: 'MONTH',
        QUARTER: 'QUARTER',
        YEAR: 'YEAR',
        CUSTOM: 'CUSTOM',
    },
};
