import React, { Component } from 'react';
import './assets/css/fonts.css';
import './assets/css/style.css';
import './assets/css/style_v2.css';
import './assets/css/ladipage.css';
import './assets/css/other.css';
import './assets/css/mobile.css';
import queryString, { parse } from 'query-string';
import Layout from './layout/Layout';
import LayoutPublisher from './layout/LayoutPublisher';
import appConfig from './config/app';
import config, { authEndpoint } from './config/config';
import baseHelper from './helpers/BaseHelper';
import 'moment/locale/vi';
import 'react-dates/initialize';
import LayoutEmpty from './layout/LayoutEmpty';

class App extends Component {
  constructor(props) {
    super(props);

    const parser = queryString.parse(this.props.location.search);
    if (parser.token) {
      baseHelper.setCookie(appConfig.COOKIE.SSID, parser.token);
      delete parser.token;
      const restParam = queryString.stringify(parser);
      props.history.push(`?${restParam}`);
    }
  }

  render() {
    const token = baseHelper.getCookie(appConfig.COOKIE.SSID);
    const origin = window.location.origin;
    const pathName = window.location.pathname;
    const paths = pathName.split('/');
    if (config.APP_AFFILIATE) {
      // Thêm ref khi vào trang affiliate
      const parser = queryString.parse(window.location.search);
      if (parser.ref) {
        baseHelper.setCookie(appConfig.COOKIE.REF, parser.ref, 90);
      }
    }
    if (config.APP_AFFILIATE
      && (window.location.pathname.indexOf('/login') !== -1
        || window.location.pathname.indexOf('/register') !== -1
        || window.location.pathname.indexOf('/forgot-password') !== -1
        || window.location.pathname.indexOf('/reset-password') !== -1
      )) {
      return <LayoutPublisher />;
    }

    if (window.location.pathname.indexOf('/campaign-share') !== -1
      || window.location.pathname.indexOf('/invoices/print') !== -1) {
      return <LayoutEmpty />;
    }

    if (!token) {
      if (config.APP_AFFILIATE) {
        if (origin === config.AFFILIATE_DOMAIN) {
          const storeAlias = baseHelper.getCookie(appConfig.COOKIE.STORE_ALIAS);
          const storePrefix = storeAlias || paths[1];
          if (storePrefix === undefined || storePrefix === null || storePrefix === '') {
            return 'NOT FOUND STORE';
          }
          window.location.href = `/${storePrefix}/login`;
        } else {
          window.location.href = '/login';
        }
      } else {
        const redirectParser = queryString.parseUrl(authEndpoint.AUTH_SIGNIN);
        redirectParser.query.callback_url = window.location.href;

        const redirectUrl = queryString.stringifyUrl(redirectParser);

        window.location.href = redirectUrl;
      }
    } else {
      return <Layout />;
    }
  }
}

export default App;
