import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';
import BaseHelper from '../../../../helpers/BaseHelper';

function CommissionPlanPaymentBoxCountOrder(props) {
    const { t, paymentCountOrderProp, cb } = props;
    const [paymentCountOrder, setPaymentCountOrder] = useState({ enable: false });

    const renderExplain = () => {
        let explain = <></>;
        if (paymentCountOrder.min && paymentCountOrder.max) {
            explain = <>(*) Hoa hồng sẽ được tính nếu số lượng đơn hàng của affiliate đó nằm trong khoảng từ {BaseHelper.formatNumber(paymentCountOrder.min)} đến {BaseHelper.formatNumber(paymentCountOrder.max)}</>;
        }
        if (paymentCountOrder.min && !paymentCountOrder.max) {
            explain = <>(*) Hoa hồng sẽ được tính nếu số lượng đơn hàng của affiliate đó lớn hơn hoặc bằng {BaseHelper.formatNumber(paymentCountOrder.min)}</>;
        }
        if (!paymentCountOrder.min && paymentCountOrder.max) {
            explain = <>(*) Hoa hồng sẽ được tính nếu số đơn hàng của affiliate đó nhỏ hơn hoặc bằng {BaseHelper.formatNumber(paymentCountOrder.max)}</>;
        }
        return explain;
    };

    const eventChangeInput = (e) => {
        const name = e.target.name;
        let value;
        if (
            e.target.tagName.toLowerCase() === 'input'
            && e.target.getAttribute('type') === 'checkbox'
        ) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        const data = { ...paymentCountOrder };
        data[name] = value;
        setPaymentCountOrder(data);
        cb(data);
    };

    const changeInputValue = (fieldName, value) => {
        const data = { ...paymentCountOrder };
        data[fieldName] = value;
        setPaymentCountOrder(data);
        cb(data);
    };

    useEffect(() => {
        setPaymentCountOrder({ ...paymentCountOrder, ...paymentCountOrderProp });
    }, [paymentCountOrderProp]);

    return <>
        <div className="ladiui clearfix pt-20">
            <label className="ladiui title-text-form-lever2 left">Trả theo số đơn hàng của affiliate</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox"
                        name="enable"
                        checked={paymentCountOrder !== null && (paymentCountOrder.enable || false)}
                        onChange={(e) => eventChangeInput(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Trả hoa hồng nếu affiliate có số lượng đơn hàng của affiliate đó đạt yêu cầu</div>
        </div>
        {paymentCountOrder && paymentCountOrder.enable
            ? <>
                <div className="ladiui clearfix pt-20">
                    <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Số đơn hàng tối thiểu</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                value={paymentCountOrder.min || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Số đơn hàng tối thiểu"
                                onValueChange={(values) => changeInputValue('min', values.value)}
                            />
                        </div>

                    </div>
                    <div className="ladiui left">
                        <label className="ladiui title-text-form-lever3">Số đơn hàng tối đa</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                value={paymentCountOrder.max || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Số đơn hàng tối đa"
                                onValueChange={(values) => changeInputValue('max', values.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>
                    {renderExplain()}
                </div>
            </>
            : <></>}
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBoxCountOrder));
