/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiDropdownMenu from '../../../components/LadiDropdownMenu';
import ConfirmModal from '../../../components/ConfirmModal';
import actionCommission from '../../../redux/futures/commission/actions';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import actionStore from '../../../redux/futures/store/actions';
import DefineConst from '../DefineConst';
import config from '../../../config/config';
import { status } from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';

function CheckAllActionCommission(props) {
    const { t, cb } = props;
    const ids = props.ids || [];

    const [purchaseNote, setPurchaseNote] = useState();

    const setting = useSelector((state) => (state.store.affiliateSetting));
    const affiliatePassed = useSelector((state) => (state.commissions.commissionInvoicedGroup ? state.commissions.commissionInvoicedGroup : []));

    const listAction = [
        { value: 'ACCEPT_ALL', name: t('COMMISSIONS.COMMISSION_STATUS.APPROVED') },
        { value: 'INVOICE_ALL', name: t('COMMISSIONS.COMMISSION_STATUS.INVOICED') },
        { value: 'CANCEL_ALL', name: t('COMMISSIONS.COMMISSION_STATUS.CANCELED') },
        { value: 'CONFIRM_ALL', name: 'Đồng ý đối soát' },
        // { value: 'CREATE_INVOICE_ALL', name: 'Lên hoá đơn' },
    ];

    const dispatch = useDispatch();
    const [actionSelected, setActionSelected] = useState(null);
    const [modalConfirm, setModalConfirm] = useState({});
    const [classBtnConfirm, setClassBtnConfirm] = useState('btn-primary');

    const getListAction = () => {
        if (config.APP_AFFILIATE) return listAction.filter((item) => item.value === 'CONFIRM_ALL');
        return listAction.filter((item) => item.value !== 'CONFIRM_ALL');
    };

    const eventSelectAction = (action) => {
        let dataModal = {};
        switch (action) {
            case 'ACCEPT_ALL':
                setClassBtnConfirm('btn-primary');
                dataModal.title = 'Bạn có chắc chắn muốn tạm duyệt toàn bộ hoa hồng được chọn?';
                dataModal.content = 'Những hoa hồng ở trạng thái chờ duyệt mới được phép chuyển sang trạng thái tạm duyệt';
                setActionSelected(action);
                setModalConfirm(dataModal);
                window.LadiUI.showModal('modal-confirm-action-all');
                break;
            case 'CANCEL_ALL':
                setClassBtnConfirm('btn-primary');
                dataModal.title = 'Bạn có chắc chắn muốn từ chối toàn bộ hoa hồng được chọn?';
                dataModal.content = 'Những hoa hồng ở trạng thái duyệt sẽ không thể từ chối';
                setActionSelected(action);
                setModalConfirm(dataModal);
                window.LadiUI.showModal('modal-confirm-action-all');
                break;
            case 'INVOICE_ALL':
                setClassBtnConfirm('btn-primary');
                dataModal.title = 'Bạn có chắc chắn muốn duyệt toàn bộ hoa hồng được chọn?';
                dataModal.content = 'Bạn không thể huỷ hoa hồng khi chúng đã được duyệt';
                setActionSelected(action);
                setModalConfirm(dataModal);
                window.LadiUI.showModal('modal-confirm-action-all');
                break;

            case 'CONFIRM_ALL':
                setClassBtnConfirm('btn-primary');
                dataModal.title = 'Bạn có chắc chắn muốn đối soát toàn bộ hoa hồng được chọn?';
                dataModal.content = 'Những hoa hồng đã được tạm duyệt mới được phép đối soát';
                setActionSelected(action);
                setModalConfirm(dataModal);
                window.LadiUI.showModal('modal-confirm-action-all');
                break;

            // case 'CREATE_INVOICE_ALL':
            //     dispatch(actionCommission.groupCommissionInvoiced({ ids }));
            //     window.LadiUI.showModal('modal-confirm-list-commission-invoice');
            //     break;
            default:
        }
    };

    const eventOkActionAll = () => {
        if (actionSelected) {
            switch (actionSelected) {
                case 'ACCEPT_ALL':
                    dispatch(actionCommission.acceptMultiCommission({ ids, status: DefineConst.COMMISSION_STATUS.APPROVED }, cb));
                    break;
                case 'CANCEL_ALL':
                    dispatch(actionCommission.rejectMultiCommission({ ids, status: DefineConst.COMMISSION_STATUS.CANCELED }, cb));
                    break;
                case 'INVOICE_ALL':
                    dispatch(actionCommission.invoiceMultiCommission({ ids }, cb));
                    break;
                case 'CONFIRM_ALL':
                    dispatch(actionCommission.confirmMultiCommission({ ids }, cb));
                    break;
                default:
            }
        }
    };

    // const eventCancel = () => {
    //     window.LadiUI.closeModal('modal-confirm-list-commission-invoice');
    //     setPurchaseNote(null);
    // };

    // const cbCreateInvoiceMulti = () => {
    //     const response = cbCreateInvoiceMulti.response;
    //     window.LadiUI.closeModal('modal-invoice-selected-affiliate');
    //     if (response.data.code === status.OK) {
    //         window.LadiUI.showModal('modal-invoice-selected-affiliate-redirect');
    //     }
    //     setPurchaseNote(null);
    //     cb();
    // };

    // const createInvoice = () => {
    //     const data = {
    //         affiliatePassed,
    //         note: purchaseNote,
    //     };
    //     dispatch(actionAffiliate.createInvoiceMultiAffiliate(data, cbCreateInvoiceMulti));
    // };

    useEffect(() => {
        const data = {
            field: 'affiliate_setting',
        };
        dispatch(actionStore.getAffiliateSetting(data));
    }, []);

    return ids.length > 0
        ? <>
            <div className="ladiui ladi-check-all-action">
                <LadiDropdownMenu
                    labelDropdown={'Chọn hành động'}
                    listProp={getListAction()}
                    defaultTextProp={t('COMMON.ACTION')}
                    hideLabel={true}
                    cbProp={eventSelectAction}
                    classSize=""
                    classDropdown=""
                />
            </div>
            <ConfirmModal
                id="modal-confirm-action-all"
                title={modalConfirm.title || ''}
                content={modalConfirm.content || ''}
                onOk={() => eventOkActionAll()}
                cancelText={t('COMMISSIONS.CANCELED')}
                okText={t('COMMISSIONS.OK')}
                classBtn={classBtnConfirm || 'btn-primary'}
            />

            {/* <div className="ladiui modal bd-example-modal-lg" id="modal-confirm-list-commission-invoice">
                <div className="ladiui modal-dialog modal-dialog-centered modal-lg">
                    <div className="ladiui modal-content custom-ldp" style={{ fontWeight: '400' }}>
                        <div className="ladiui modal-header custom-ldp">
                            <label className="ladiui title-text-form-lever1">Tạo hoá đơn affiliate</label>
                            <button type="button" data-dismiss="modal" data-target="modal-export-commission" className="ladiui modal-close" onClick={eventCancel}>
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>

                        <div className="ladiui form-group-custom">
                            {setting && setting.limit_invoice_status
                                ? <label className="ladiui title-text-form-lever2" htmlFor="amount">* Số tiền cho hóa đơn phải lớn hơn hoặc bằng:
                                    {BaseHelper.formatMoney(setting ? setting.limit_invoice_value : 0)}</label>
                                : <></>
                            }
                            {setting && setting.limit_customer_status
                                ? <label className="ladiui title-text-form-lever2" htmlFor="amount">* Số khách hàng phải lớn hơn hoặc bằng:
                                    {BaseHelper.formatNumber(setting ? setting.limit_customer_value : 0)} khách hàng</label>
                                : <></>
                            }
                        </div>

                        <>
                            <div style={{ marginTop: '12px' }}>
                                Số lượng affliate đủ điều kiện tạo hoá đơn: {affiliatePassed ? affiliatePassed.length : 0} affiliate
                            </div>

                            {affiliatePassed && affiliatePassed.length > 0
                                ? <>
                                    <div style={{ maxHeight: '500px', overflowY: 'scroll', marginTop: '25px' }}>
                                        <table className="ladiui table text-left" style={{ marginTop: 0 }}>
                                            <thead>
                                                <tr className="ladiui table-vertical header">
                                                    <th scope="col">#</th>
                                                    <th scope="col">Họ tên</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Mã</th>
                                                    <th scope="col">Số hoa hồng được lên hoá đơn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {affiliatePassed.map((item, index) => <tr className="ladiui table-vertical main" key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.fullname}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.code}</td>
                                                    <td>{item.commissions.length}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="ladiui form-group-custom">
                                        <label className="ladiui title-text-form-lever2">Ghi chú cho hoá đơn</label>
                                        <textarea className="ladiui form-control" placeholder="Nhập nội dung" rows={3}
                                            value={purchaseNote || ''}
                                            onChange={(e) => setPurchaseNote(e.target.value)}
                                        />
                                    </div>

                                    <div className="ladiui modal-footer custom-ldp">
                                        <div></div>
                                        <div className="ladiui footer-modal-btn">
                                            <button type="button" className="ladiui btn btn-secondary bold" onClick={eventCancel}>{t('COMMISSIONS.CANCELED')}</button>
                                            <button type="button" className="ladiui btn btn-primary" onClick={() => createInvoice()}>Tạo hoá đơn</button>
                                        </div>

                                    </div>
                                </> : <></>}
                        </>
                    </div>
                </div>
            </div >

            <div className="ladiui modal" id="modal-invoice-selected-affiliate-redirect">
                <div className="ladiui modal-dialog modal-sm">
                    <div className="ladiui modal-content custom-ldp">
                        <div className="ladiui modal-header custom-ldp">
                            <label className="ladiui title-text-form-lever1">Xem danh sách hoá đơn?</label>
                            <button type="button" data-dismiss="modal" data-target="modal-export-commission" className="ladiui modal-close">
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>

                        <div className="ladiui" style={{ fontWeight: '400' }}>
                            <div>
                                Bạn có muốn xem danh sách các hoá đơn vừa tạo?
                            </div>
                        </div>

                        <div className="ladiui modal-footer custom-ldp">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button type="button" data-dismiss="modal" data-target="modal-export-commission"
                                    className="ladiui btn btn-secondary bold">{t('COMMISSIONS.CANCELED')}</button>
                                <a className="ladiui btn btn-primary" href="/invoices">Xem danh sách</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div > */}
        </>
        : <></>;
}

export default (withTranslation()(CheckAllActionCommission));
