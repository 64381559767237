import { isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import actionAppIntegrate from '../../../../redux/futures/integrate_app/actions';

function FormSapo(props) {
    const { t, dataApp, cb } = props;
    const isCreate = props.isCreate === undefined ? true : props.isCreate;

    const dispatch = useDispatch();
    const [appIntegrate, setAppIntegrate] = useState({});
    const [isConnect, setIsConnect] = useState(false);
    const [openWindowsConfirm, setOpenWindowsConfirm] = useState(false);

    const urlOauthSapo = useSelector((state) => state.appIntegrate.urlSapo);
    const sapoAccess = useSelector((state) => state.appIntegrate.sapoAccess);

    const eventChangeData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = {};
        data[name] = value;
        setAppIntegrate({ ...appIntegrate, ...data });
        if (isFunction(cb)) cb(data);
    };
    const cbAccessSapo = () => {
        setIsConnect(true);
    };

    const eventListenerSapo = (e) => {
        const data = e.data;
        if (data.code) {
            const reqDataAccess = { code: data.code, store_url: appIntegrate.api_url };
            dispatch(actionAppIntegrate.getAccessSapo(reqDataAccess, cbAccessSapo));
        }
    };

    const cbOpenWindowConfirm = () => {
        setOpenWindowsConfirm(true);
    };

    const eventConnect = () => {
        if (isConnect) {
            setIsConnect(false);
        } else if (appIntegrate.api_url) {
            setOpenWindowsConfirm(false);
            dispatch(actionAppIntegrate.integrateAppSapo({ store_url: appIntegrate.api_url }, cbOpenWindowConfirm));
        }
    };

    useEffect(() => {
        if (dataApp) setAppIntegrate(dataApp);
    }, [dataApp]);

    useEffect(() => {
        if (!openWindowsConfirm || !urlOauthSapo) return;
        const width = 500;
        const height = 650;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);
        const targetWin = window.open(urlOauthSapo,
            'popupAuthSapo', `width=${width}, height=${height}, left=${left}, top=${top}`);
        targetWin.focus();
        window.addEventListener('message', eventListenerSapo, false);
    }, [urlOauthSapo, openWindowsConfirm]);

    useEffect(() => {
        if (isConnect && sapoAccess) {
            const data = { ...appIntegrate, token: sapoAccess };
            setAppIntegrate(data);
            if (isFunction(cb)) cb(data);
        }
    }, [sapoAccess]);
    return <>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="name">Tên</label>
            <input id="name" name="name" className="ladiui form-control" placeholder="Nhập Tên"
                value={appIntegrate.name || ''}
                onChange={(e) => eventChangeData(e)}
            />
        </div>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="api_url">URL cửa hàng</label>
            <div className="ladiui flex-row">
                <input id="api_url" name="api_url" className="ladiui form-control mr-8" placeholder="Nhập URL cửa hàng"
                    value={appIntegrate.api_url || ''}
                    onChange={(e) => eventChangeData(e)}
                    readOnly={isCreate ? isConnect : true}
                />
                {isCreate ? <button className="ladiui btn btn-primary" onClick={() => eventConnect()}>{isConnect ? 'Kết nối lại' : 'Kết nối'}</button> : <></>}
            </div>
        </div>
    </>;
}

export default (withTranslation()(FormSapo));
