export default {
    TYPE_CAMPAIGN: {
        SOCIETY: 'SOCIETY',
        BANNER: 'BANNER',
        MAIL: 'MAIL',
    },
    PLATFORM_SOCIETY: [
        { name: 'Facebook', value: 'FACEBOOK' },
        { name: 'Instagram', value: 'INSTAGRAM' },
        { name: 'Linkedin', value: 'LINKEDIN' },
        { name: 'Twitter', value: 'TWITTER' },
    ],
    PLATFORM_CAMPAIGN: {
        FACEBOOK: 'FACEBOOK',
        INSTAGRAM: 'INSTAGRAM',
        LINKEDIN: 'LINKEDIN',
        TWITTER: 'TWITTER',
        BANNER: 'BANNER',
        MAIL: 'MAIL',
    },
    PLATFORM_SOCIETY_ARRAY: [
        { name: 'Facebook', value: 'FACEBOOK' },
        { name: 'Instagram', value: 'INSTAGRAM' },
        { name: 'Linkedin', value: 'LINKEDIN' },
        { name: 'Twitter', value: 'TWITTER' },
        { name: 'Banner', value: 'BANNER' },
        { name: 'Mail', value: 'MAIL' },
    ],
    STATUS: {
        PRIVATE: 'PRIVATE',
        PUBLIC: 'PUBLIC',
    },
};
