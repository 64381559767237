import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.LIST_COMMISSION_PLAN,
    meta: {
        endpoint: endpoint.COMMISSION_PLAN_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const create = (data, cb) => ({
    type: types.CREATE_COMMISSION_PLAN,
    meta: {
        endpoint: endpoint.COMMISSION_PLAN_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const show = (id) => ({
    type: types.SHOW_COMMISSION_PLAN,
    meta: {
        endpoint: endpoint.COMMISSION_PLAN_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
    },
    hasSubAction: true,
});

const getDefaultCommissionPlan = () => ({
    type: types.SHOW_COMMISSION_PLAN_DEFAULT,
    meta: {
        endpoint: endpoint.SHOW_COMMISSION_PLAN_DEFAULT,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    },
    hasSubAction: true,
});

const update = (data, cb) => ({
    type: types.UPDATE_COMMISSION_PLAN,
    meta: {
        endpoint: endpoint.COMMISSION_PLAN_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteCommissionPlan = (data) => ({
    type: types.DELETE_COMMISSION_PLAN,
    meta: {
        endpoint: endpoint.COMMISSION_PLAN_DELETE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
    },
});

const makeAsDefault = (data) => ({
    type: types.MAKE_AS_DEFAULT_COMMISSION_PLAN,
    meta: {
        endpoint: endpoint.COMMISSION_PLAN_MAKE_AS_DEFAULT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
    },
});

export default {
    list,
    create,
    show,
    getDefaultCommissionPlan,
    update,
    delete: deleteCommissionPlan,
    makeAsDefault,
};
