import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import BaseHelper from '../../helpers/BaseHelper';
import appConfig from '../../config/app';
import actionInvoice from '../../redux/futures/invoice/actions';
import storeActions from '../../redux/futures/store/actions';

function InvoicePrint(props) {
    const { t } = props;
    const { id } = useParams();

    const dispatch = useDispatch();

    const invoice = useSelector((state) => (state.invoices.invoice || {}));
    const affiliate = useSelector((state) => (state.invoices.invoiceAffiliate || {}));
    const commission = useSelector((state) => (state.invoices.invoiceCommission || {}));
    const store = useSelector((state) => (state.store.userInfo.store));

    const printInvoice = () => {
        window.print();
    };

    useEffect(() => {
        dispatch(storeActions.getUserInfo());
    }, []);
    useEffect(() => {
        dispatch(actionInvoice.show(id));
    }, [id]);

    return <>
        <div className="detail-invoice-print">
            <div className="box-code">
                <label>Mã đơn:&nbsp;</label>
                <div className="code">{invoice.code}</div>
            </div>
            <div className="box-info-invoice">
                <div>
                    <label className="text-uppercase">Thông tin cửa hàng</label>
                    <div>
                        <label>Tên cửa hàng:</label>
                        <div>{store ? store.name : ''}</div>
                    </div>
                </div>
            </div>
            <div className="box-info-invoice mt-24">
                <label className="text-uppercase">Thông tin khách hàng</label>
                <div className="invoice-flex">
                    <div>
                        <label>Họ tên:</label>
                        <div>{affiliate.fullname || ''}</div>
                        <label>Email:</label>
                        <div>{affiliate.email || ''}</div>
                    </div>
                    <div>
                        <label>Mã Affiliate:</label>
                        <div className="text-uppercase">{affiliate.code || ''}</div>
                    </div>
                    <div>
                        <label>Ghi chú:</label>
                        <div>{invoice.note || ''}</div>
                    </div>
                </div>
            </div>
            <div>
                <label>Thông tin hoá đơn</label>
                <div>
                    <table className="ladiui table table-invoice-detail">
                        <thead>
                            <tr>
                                <th width="1%">STT</th>
                                <th>Ngày tạo</th>
                                <th className="ladiui text-right">Số tiền</th>
                                <th className="ladiui text-right">Thuế</th>
                                <th className="ladiui text-right">Thành tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="ladiui text-center">1</div>
                                </td>
                                <td>
                                    <div className="ladiui text-center">{BaseHelper.formatStrToDate(invoice.created_at)}</div>
                                </td>
                                <td>
                                    <div className="ladiui text-right">{BaseHelper.formatMoney(invoice.amount || 0)}</div>
                                </td>
                                <td>
                                    <div className="ladiui text-right">- {BaseHelper.formatMoney(invoice.tax || 0)}</div>
                                </td>
                                <td>
                                    <div className="ladiui text-right">{BaseHelper.formatMoney(parseFloat(invoice.amount || 0) - parseFloat(invoice.tax || 0))}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{ marginTop: '30px' }}>
                <label>Thông tin hoa hồng đối soát</label>
                <div>
                    <table className="ladiui table table-invoice-detail">
                        <thead>
                            <tr className="ladiui table-vertical header">
                                <th>STT</th>
                                <th>Mã tham chiếu</th>
                                <th>Loại</th>
                                <th>Ngày duyệt</th>
                                <th>Tổng tiền</th>
                                <th>Hoa hồng</th>
                                <th>Thưởng</th>
                            </tr>
                        </thead>
                        {commission && commission.length > 0
                            ? <tbody>
                                {commission.map((item, index) => <tr className="ladiui table-vertical main" key={item._id}>
                                    <td> <div className="ladiui text-center">{index + 1}</div></td>
                                    <td> <div className="ladiui text-center">{item.data.code || item._id}</div></td>
                                    <td> <div className="ladiui text-center">{appConfig.LADISHARE.COMMISSION_TYPE.map((type) => (type.value === item.type ? type.name : ''))}</div></td>
                                    <td> <div className="ladiui text-center">{BaseHelper.formatStrToDate(item.commission_date)}</div></td>
                                    <td><div className="ladiui text-right">{BaseHelper.formatMoney(item.total, '')}</div></td>
                                    <td><div className="ladiui text-right">{BaseHelper.formatMoney(item.amount, '')}</div></td>
                                    <td><div className="ladiui text-right">{BaseHelper.formatMoney(item.bonus, '')}</div></td>
                                </tr>)}
                            </tbody>
                            : <></>}
                    </table>
                </div>
            </div>
        </div>
        <div className="no-print box-print-button">
            <button className="ladiui btn btn-primary" onClick={printInvoice}>In hoá đơn</button>
        </div>
    </>;
}

export default (withTranslation()(InvoicePrint));
