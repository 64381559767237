import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_STAFF: {
            return {
                ...state,
                action: types.LIST_STAFF,
                staffs: action.status ? { items: action.payload.items, total: action.payload.total } : { items: [], total: 0 },
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_STAFF: {
            return {
                ...state,
                action: types.CREATE_STAFF,
                staff: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_STAFF: {
            return {
                ...state,
                action: types.SHOW_STAFF,
                staff: action.status ? action.payload.staff : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_STAFF: {
            return {
                ...state,
                action: types.UPDATE_STAFF,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_STAFF: {
            return {
                ...state,
                action: types.DELETE_STAFF,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_MULTI_STAFF: {
            return {
                ...state,
                action: types.DELETE_MULTI_STAFF,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
