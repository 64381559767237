import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function CouponFilterCondition(props) {
    const { t, conditionProp, filterActionProp } = props;

    const [attributeFilter, setAttributeFilter] = useState(null);
    const [conditionFilter, setConditionFilter] = useState(null);
    const [valueFilter, setValueFilter] = useState(null);
    const [allConditionSelect, setAllConditionSelect] = useState([]);
    const [showListFilter, setShowListFilter] = useState(false);

    const eventFilterCondition = () => {
        if (!attributeFilter || !conditionFilter || !valueFilter) {
            return;
        }
        const allCondition = allConditionSelect.filter((value) => value.attributeFilter.value !== attributeFilter.value);

        const newAllConditionFilter = [...allCondition, ...[{ attributeFilter, conditionFilter, valueFilter }]];
        setAllConditionSelect(newAllConditionFilter);

        const filterItems = {};

        filterItems[attributeFilter.value] = {};
        if (conditionFilter.value === '$regex') {
            filterItems[attributeFilter.value][conditionFilter.value] = valueFilter.value;
            filterItems[attributeFilter.value].$options = 'i';
        } else if (conditionFilter.value === '$notRegex') {
            // filterItems[attributeFilter.value].$not = { $regex: valueFilter.value, $options: 'i' };
            filterItems[attributeFilter.value] = { $regex: `^((?!${valueFilter.value}).)*$`, $options: 'i' };
        } else {
            filterItems[attributeFilter.value][conditionFilter.value] = valueFilter.value;
        }
        const conditionSend = { ...conditionProp, ...filterItems };
        filterActionProp(conditionSend);
    };

    const eventCloseFilterCondition = () => {
        setAttributeFilter(null);
        setConditionFilter(null);
        setValueFilter(null);
        setShowListFilter(false);
    };

    const eventRemoveFilter = (attributeFilterValue) => {
        const allCondition = allConditionSelect.filter((value) => value.attributeFilter.value !== attributeFilterValue);
        setAllConditionSelect(allCondition);
        delete conditionProp[attributeFilterValue];
        filterActionProp(conditionProp);
    };

    const eventSetAttributeFilter = (attributeFilter) => {
        setAttributeFilter(attributeFilter);
        setConditionFilter(null);
        setValueFilter(null);
    };

    document.addEventListener('click', (event) => {
        const box = document.getElementById('filter-condtion-area');
        if (box && !box.contains(event.target)) {
            setAttributeFilter(null);
            setConditionFilter(null);
            setValueFilter(null);
            setShowListFilter(false);
        }
    });

    const renderStatusCondition = () => <div className="ladiui flex-row">
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: '=', value: '$regex' })}><a className="ladiui dropdown-item" href="#/">{'='}</a></li>
                    <li onClick={() => setConditionFilter({ name: '<>', value: '$notRegex' })}><a className="ladiui dropdown-item" href="#/">{'<>'}</a></li>
                </ul>
            </div>
        </div>
        <div className="ladiui btn-group">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">
                        {valueFilter ? valueFilter.name : t('COMMON.SELECT_STATUS')}
                    </span>
                </button>
                <ul className="ladiui dropdown-menu">
                    {appConfig.LADISHARE.INVOICE_STATUS.map((item) => <li key={item.value}
                        onClick={() => setValueFilter({ name: item.name, value: item.value })}>
                        <a className="ladiui dropdown-item" href="#/">{item.name}</a>
                    </li>)}
                </ul>
            </div>
        </div>
    </div>;

    const renderSalesCondition = () => <div className="ladiui flex-row">
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: '=', value: '$e' })}><a className="ladiui dropdown-item" href="#/">{'='}</a></li>
                    <li onClick={() => setConditionFilter({ name: '>=', value: '$gte' })}><a className="ladiui dropdown-item" href="#/">{'>='}</a></li>
                    <li onClick={() => setConditionFilter({ name: '<=', value: '$lte' })}><a className="ladiui dropdown-item" href="#/">{'<='}</a></li>
                </ul>
            </div>
        </div>
        <input type="number" className="ladiui form-control" placeholder="0" min={0}
            value={valueFilter ? valueFilter.value : ''}
            onChange={(e) => setValueFilter({ name: e.target.value, value: e.target.value })} />
    </div>;

    const renderDetailCondition = (condition) => {
        switch (condition) {
            case 'status': return renderStatusCondition();
            case 'amount': return renderSalesCondition();
            default: return '';
        }
    };

    return <>
        <div id="filter-condtion-area" className={allConditionSelect.length > 0 ? 'filter-condtion-area mb-10' : 'filter-condtion-area'}>
            <button className="ladiui btn btn-outline-light" onClick={() => setShowListFilter(!showListFilter)}>
                <img src="https://w.ladicdn.com/design-system/icons/icon-filter.svg" style={{ marginBottom: '2px', marginRight: '10px' }} />Thêm điều kiện lọc
            </button>
            <div className={showListFilter ? 'list-filter-condition pt-0 show' : 'list-filter-condition pt-0'}>
                <div className="ladiui flex-row mt-0">
                    <div className="mr-8">
                        <div className="ladiui btn-group">
                            <div className="ladiui dropdown">
                                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                                    data-toggle="dropdown">
                                    <i className="ladiui icon"></i>
                                    <span className="ladiui dropdown-text">
                                        {attributeFilter ? attributeFilter.name : 'Chọn tiêu chí'}
                                    </span>
                                </button>
                                <ul className="ladiui dropdown-menu">
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Trạng thái', value: 'status' })}>
                                        <a className="ladiui dropdown-item" href="#/">Trạng thái</a>
                                    </li>
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Số tiền', value: 'amount' })}>
                                        <a className="ladiui dropdown-item" href="#/">Số tiền</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {attributeFilter ? renderDetailCondition(attributeFilter.value) : ''}
                </div>
                <div className="ladiui footer-list-filter-condition flex-row">
                    <button type="button" className="ladiui btn btn-secondary btn-sm mr-8" onClick={() => eventCloseFilterCondition()}>Hủy</button>
                    <button type="button" className="ladiui btn btn-primary btn-sm" onClick={() => eventFilterCondition()}>Áp dụng</button>
                </div>
                <div className="clearfix"></div>
            </div>
            <div className="ladiui result-filter-condition flex-row">
                {allConditionSelect.map((item, index) => <span className="ladiui btn-tag" key={index}>
                    {item.attributeFilter.name} {item.conditionFilter.name} {item.valueFilter.name}
                    <img className="cursor-pointer"
                        style={{ width: '12px', marginLeft: '8px' }}
                        src="https://w.ladicdn.com/design-system/icons/icon-close.svg"
                        onClick={() => eventRemoveFilter(item.attributeFilter.value)} />
                </span>)}
            </div>
        </div>
    </>;
}

export default (withTranslation()(CouponFilterCondition));
