import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BaseHelper from '../../../../helpers/BaseHelper';

import actionCommission from '../../../../redux/futures/commission/actions';

function TopCommission(props) {
    const { t, conditionProp } = props;

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState({});

    const topCommissions = useSelector((state) => (state.commissions.topCommissions));

    const cbList = () => {
        dispatch(actionCommission.getTopCommission(conditions));
    };

    const getNameAffiliate = (id) => {
        if (!topCommissions.affiliates) return '';

        let name = '';
        for (let i = 0; i < topCommissions.affiliates.length; i++) {
            const affiliate = topCommissions.affiliates[i];
            if (affiliate._id === id) {
                name = affiliate.fullname;
            }
        }
        return name;
    };

    useEffect(() => {
        cbList();
    }, []);

    useEffect(() => {
        setConditions(conditionProp);
    }, [conditionProp]);
    return <>
        <div>
            <div className="title">Hoa hồng</div>
            <div className="content">
                <table className="ladiui table text-left">
                    <thead>
                        <tr className="ladiui table-vertical header">
                            <th scope="col">
                                STT
                            </th>
                            <th scope="col">Affiliate</th>
                            <th scope="col" align="right">Số hoa hồng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            topCommissions
                                ? topCommissions.items.map((item, index) => (<tr key={item._id} className="ladiui table-vertical main">
                                    <td scope="row">
                                        {index + 1}
                                    </td>
                                    <td>
                                        <Link to={`/affiliates/${item.affiliate_id}`} className="ladiui link">{getNameAffiliate(item.affiliate_id)}</Link>
                                    </td>
                                    <td align="right">
                                        {BaseHelper.formatNumber(item.total_commission_number || 0)}
                                    </td>
                                </tr>))
                                : <></>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </>;
}
export default (withTranslation()(TopCommission));
