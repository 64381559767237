import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from '../../../../assets/css/custom.module.css';

function LinkedinContent(props) {
    const { t, contents } = props;
    return <>
        <label>Nội dung</label>
        <div className="content-left-detail">
            <div style={{ whiteSpace: 'pre-wrap' }}>{contents}</div>
        </div>
    </>;
}
export default (withTranslation()(LinkedinContent));
