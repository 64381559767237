import React, { useEffect, useState } from 'react';
import { isFunction } from 'lodash';
import { withTranslation } from 'react-i18next';

function LadiDropdown(props) {
    const {
        t, labelDropdown, listProp, valueProp, defaultTextProp, hideLabel, cbProp, validProp, classDropdown, classSize, prefixName,
    } = props;
    const styleInvalid = { border: '1px solid var(--danger-color)' };
    const styleValid = { boder: '1px solid var(--main-line)' };

    const [nameDropdown, setNameDropdown] = useState('');
    const [valid, setValid] = useState(true);

    const eventSelectDropdown = (item) => {
        let name = '';
        if (prefixName) name += `${prefixName} `;
        setNameDropdown(name + item.name);
        setValid(true);
        if (isFunction(cbProp)) {
            cbProp(item.value);
        }
    };
    useEffect(() => {
        if (valueProp === null || valueProp === undefined) {
            setNameDropdown(defaultTextProp || t('COMMON.SELECT_VALUE'));
            return;
        }
        let name = '';
        if (prefixName) name += `${prefixName} `;
        listProp.forEach((value) => {
            if (valueProp === value.value) setNameDropdown(name + value.name);
        });
    }, [valueProp]);

    useEffect(() => {
        setValid(validProp);
    }, [validProp]);
    return <>
        <div className={`ladiui item-form ${classDropdown}`}>
            {!hideLabel
                ? <div>
                    <label className="ladiui title-text-form-lever2">{labelDropdown}</label>
                </div> : <></>
            }

            <div className="ladiui btn-group custom-form" style={{ marginRight: 0 }}>
                <div className="ladiui dropdown">
                    <button
                        className={`ladiui btn btn-outline-light dropdown-toggle ${classSize || ''}`}
                        data-toggle="dropdown" style={valid ? styleValid : styleInvalid}>
                        <i></i>
                        <span className="ladiui dropdown-text">{nameDropdown}</span>
                    </button>
                    <ul className="ladiui dropdown-menu">
                        {listProp.map((item) => <li key={item.value + item.name.replace(' ', '')} onClick={() => eventSelectDropdown(item)}>
                            <a className="ladiui dropdown-item" href="#/">{item.name}</a>
                        </li>)}
                    </ul>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(LadiDropdown));
