import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import actionAffiliate from '../../../redux/futures/affiliate/actions';

function NoteAffiliate(props) {
    const { idProp, noteProp, t } = props;

    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [note, setNote] = useState(null);

    const eventCancel = () => {
        setNote(noteProp);
        setIsEdit(false);
    };

    const eventUpdate = () => {
        const data = {
            id: idProp,
            note,
        };
        dispatch(actionAffiliate.updateOther(data));
        setIsEdit(false);
    };

    useEffect(() => {
        setNote(noteProp);
    }, [props.noteProp]);

    return <>
        <div className="ladiui note-content" style={{ position: 'relative' }}>
            {isEdit ? <div className="ladiui form-group mt-8">
                <textarea className="ladiui form-control" rows={5} value={note} onChange={(e) => setNote(e.target.value)} />
                <div className="ladiui mt-48 flex-row">
                    <button className="ladiui btn btn-sm mr-8" onClick={eventCancel}>{t('AFFILIATES.CANCELED')}</button>
                    <button className="ladiui btn btn-sm btn-primary" onClick={eventUpdate}>{t('AFFILIATES.UPDATE')}</button>
                </div>
            </div>
                : <>
                    <div className="box box-note"><p style={{ whiteSpace: 'pre-wrap' }}>{note}</p></div>
                    <button className="ladiui btn btn-link" onClick={() => setIsEdit(true)}><i className="ladiui icon icon-ldp-edit"></i></button>
                </>}

        </div>
    </>;
}

export default (withTranslation()(NoteAffiliate));
