import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data, cb) => ({
    type: types.LIST_CAMPAIGN,
    meta: {
        endpoint: endpoint.LIST_CAMPAIGN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const listFilter = (data, cb) => ({
    type: types.LIST_CAMPAIGN_FILTER,
    meta: {
        endpoint: endpoint.LIST_CAMPAIGN_FILTER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const create = (data, cb) => ({
    type: types.CREATE_CAMPAIGN,
    meta: {
        endpoint: endpoint.CREATE_CAMPAIGN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const show = (id, cb) => ({
    type: types.SHOW_CAMPAIGN,
    meta: {
        endpoint: endpoint.SHOW_CAMPAIGN,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const update = (data, notify = true, cb) => ({
    type: types.UPDATE_CAMPAIGN,
    meta: {
        endpoint: endpoint.UPDATE_CAMPAIGN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: notify,
        cb,
    },
});

const deleteCampaign = (data, cb) => ({
    type: types.DELETE_CAMPAIGN,
    meta: {
        endpoint: endpoint.DELETE_CAMPAIGN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const uploadImage = (data, cb) => ({
    type: types.UPLOAD_IMAGE_CAMPAIGN,
    meta: {
        endpoint: endpoint.UPLOAD_IMAGE_CAMPAIGN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        checkPublisher: false,
        cb,
    },
});

const uploadImageProfile = (data, cb) => ({
    type: types.UPLOAD_IMAGE_PROFILE_CAMPAIGN,
    meta: {
        endpoint: endpoint.UPLOAD_IMAGE_PROFILE_CAMPAIGN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        checkPublisher: false,
        cb,
    },
});

const getShortLink = (data, notify = true, cb) => ({
    type: types.GET_SHORT_LINK_CAMPAIGN,
    meta: {
        endpoint: endpoint.GET_SHORT_LINK_CAMPAIGN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: notify,
        cb,
    },
});

export default {
    list,
    listFilter,
    create,
    show,
    update,
    delete: deleteCampaign,
    uploadImage,
    uploadImageProfile,
    getShortLink,
};
