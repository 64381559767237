import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LadiDateRangePicker from '../../components/LadiDateRangePicker';
import LadiPagination from '../../components/LadiPagination';
import appConfig from '../../config/app';
import config from '../../config/config';
import BaseHelper from '../../helpers/BaseHelper';
import actionCustomer from '../../redux/futures/customer/actions';
import SearchCondition from './components/SearchCondition';
import ExportCustomer from './components/ExportCustomer';
import DefineConst from './DefineConst';
import ModalCommission from './components/ModalCommission';

// eslint-disable-next-line max-lines-per-function
function Customer(props) {
    const { t } = props;
    const defaultCondtions = {
        limit: 10,
        page: 1,
        sort: { created_at: -1 },
    };

    const dispatch = useDispatch();
    const moment = BaseHelper.getMoment();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [customer, setCustomer] = useState(null);

    const [conditions, setConditions] = useState(defaultCondtions);
    const listCustomer = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.customers.customers }));

    const cbList = () => {
        dispatch(actionCustomer.list(conditions));
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        if (e.target.checked) {
            setSelectedIds([...selectedIds, ...[id]]);
        } else {
            const ids = selectedIds.filter((item) => item !== id);
            setSelectedIds(ids);
        }
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setSelectedIds(ids);
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const getTypeCustomer = (item) => {
        let text = '';
        switch (item.type) {
            case 'LEAD': text = 'LEAD'; break;
            case 'CUSTOMER': text = 'Khách hàng'; break;
            case 'NEW_CUSTOMER': text = 'Khách hàng mới'; break;
            default:
        }
        return text;
    };

    const eventExportData = () => {
        window.LadiUI.showModal('modal-export-data');
    };

    const actionExportProp = (actionExport) => {
        let exportCondition = { ...conditions };
        switch (actionExport) {
            case DefineConst.EXPORT_ACTIONS.FIRST_PAGE:
                exportCondition.page = 1;
                break;
            case DefineConst.EXPORT_ACTIONS.SELECTED_RECORDS:
                if (selectedIds.length === 0) {
                    window.LadiUI.toast('danger', '', 'Vui lòng chọn ít nhất 1 bản ghi', 5000, 'bottom-left');
                    return;
                }
                exportCondition = { ids: selectedIds.join(',') };
                break;
            case DefineConst.EXPORT_ACTIONS.ALL_RECORDS:
                delete exportCondition.page;
                delete exportCondition.limit;
                break;
            default:
        }
        dispatch(actionCustomer.exportCustomer(exportCondition));
    };

    const openModalCommissionCustomer = (item) => {
        setCustomer(item);
        window.LadiUI.showModal('modal-commission-custom');
    };

    useEffect(() => {
        cbList();
    }, [conditions]);
    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui flex-row">
                    <div className="ladiui main-title">
                        {t('CUSTOMERS.LIST_CUSTOMER')}
                    </div>
                    <div style={{ marginLeft: '22px' }}>
                        <LadiDateRangePicker
                            startDateId="fromDate"
                            endDateId="toDate"
                            startDate={fromDate}
                            endDate={toDate}
                            onDatesChange={({ startDate, endDate }) => {
                                const startDateNew = BaseHelper.getFromDate(startDate);
                                const endDateNew = BaseHelper.getToDate(endDate);
                                setFromDate(startDateNew);
                                setToDate(endDateNew);
                                if ((startDateNew && endDateNew) || (!startDateNew && !endDateNew)) {
                                    setConditions({ ...conditions, ...{ date_from: startDateNew, date_to: endDateNew } });
                                }
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => {
                                setFocusedInput(focusedInput);
                            }}
                            showDefaultInputIcon={true}
                            inputIconPosition="after"
                            small={true}
                            hideKeyboardShortcutsPanel={true}
                            customInputIcon={
                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                            }
                            startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            isOutsideRange={() => false}
                            isDayHighlighted={(date) => moment().isSame(date, 'day')}
                            minimumNights={0}
                            showClearDates={true}
                        />
                    </div>
                </div>
                {!config.APP_AFFILIATE
                    ? <div className="ladiui flex" style={{ gap: 12 }}>
                        <div className="ladiui">
                            <div className="ladiui dropdown">
                                <button className="ladiui btn btn-outline-primary dropdown-toggle import-data-btn" data-toggle="dropdown" style={{ minWidth: 0 }}>
                                    Xuất dữ liệu
                                </button>
                                <ul className="ladiui dropdown-menu" style={{ right: 0 }}>
                                    <li onClick={() => eventExportData()}>
                                        <a className="ladiui dropdown-item" href="#/">Xuất danh sách</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> : <></>}
            </div>

            <div className="ladiui flex-row">
                <SearchCondition
                    keywordProp={conditions.keyword}
                    eventSearchKeyboardProp={eventSearchKeyboard}
                    eventClickSearchButtonProp={eventClickSearchButton}
                />
            </div>
            <div className="ladiui mobile-table-scroll">
                <table className="ladiui table text-left" style={{ minWidth: '600px' }}>
                    <thead>
                        <tr className="ladiui table-vertical header">
                            {!config.APP_AFFILIATE
                                ? <th className="ladiui col-first" scope="col">
                                    <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                        name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                                </th> : <></>}
                            <th scope="col">Tên</th>
                            <th scope="col">Email</th>
                            <th scope="col">Số điện thoại</th>
                            <th scope="col">Loại</th>
                            <th scope="col">Số đơn hàng</th>
                            <th scope="col">Người giới thiệu</th>
                            <th scope="col">Ngày cập nhật</th>
                            <th scope="col">Ngày tạo</th>
                            {/* <th scope="col">UTM Campaign</th> */}
                            {/* <th scope="col">UTM Content</th> */}
                            {/* <th scope="col">UTM Medium</th> */}
                            {/* <th scope="col">UTM Source</th> */}
                            {/* <th scope="col">UTM Term</th> */}
                            {!config.APP_AFFILIATE
                                ? <th scope="col"></th> : <></>}
                        </tr>
                    </thead>
                    <tbody>
                        {listCustomer.items.length > 0 ? listCustomer.items.map((item, key) => (<tr key={item._id} className="ladiui table-vertical main">
                            {!config.APP_AFFILIATE
                                ? <td scope="row">
                                    <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element"
                                        name="checkbox" checked={selectedIds.includes(item._id)}
                                        value={item._id} onChange={eventCheck} />
                                </td> : <></>}
                            <td>
                                {item.name || 'Khách hàng'}
                            </td>
                            <td>
                                {config.APP_AFFILIATE && item.store_id !== '630467e4cf2d6f00134ea2be' ? item.email_hidden : item.email}
                            </td>
                            <td>
                                {config.APP_AFFILIATE && item.store_id !== '630467e4cf2d6f00134ea2be' ? item.phone_hidden : item.phone}
                            </td>
                            <td>
                                {getTypeCustomer(item)}
                            </td>
                            <td className="ladiui text-center">{item.total_order}</td>
                            <td>
                                <Link to={`/affiliates/${item.affiliate ? item.affiliate._id : ''}`} className="ladiui link">
                                    {item.affiliate ? `${item.affiliate.code} - ${item.affiliate.fullname}` : ''}
                                </Link>
                            </td>
                            <td>{BaseHelper.formatStrToDate(item.updated_at, 'DD/MM/YYYY')}</td>
                            <td>{BaseHelper.formatStrToDate(item.created_at, 'DD/MM/YYYY')}</td>
                            {!config.APP_AFFILIATE
                                ? <td className="ladiui-dropdown">
                                    <div className="ladiui btn-group">
                                        <div className="ladiui dropdown">
                                            <button data-toggle="dropdown"
                                                className="ladiui-btn-dropdown dropdown-toggle">
                                                <i className="ladiui icon icon-ldp-dot"></i>
                                            </button>
                                            <ul className="ladiui dropdown-menu custom-dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1">
                                                {!config.APP_AFFILIATE
                                                    ? <>
                                                        <li onClick={() => openModalCommissionCustomer(item)}>
                                                            <a className="ladiui dropdown-item" href="#/">
                                                                Tạo hoa hồng
                                                            </a>
                                                        </li>
                                                    </>
                                                    : <></>}

                                            </ul>
                                        </div>
                                    </div>
                                </td> : <></>}
                        </tr>))
                            : <tr><td colSpan={6}>{t('NO_DATA')}</td></tr>}

                    </tbody>
                </table>
            </div>
            {listCustomer.items.length > 0
                ? <LadiPagination
                    conditionsProp={conditions}
                    listName={t('CUSTOMERS.CUSTOMERS')}
                    items={listCustomer.items}
                    total={listCustomer.total}
                    limitAction={selectLimit}
                    pageAction={selectPage}
                /> : ''}
        </div>
        <ExportCustomer actionExportProp={actionExportProp} />
        <ModalCommission customerProp={customer} cbProp={cbList} />
    </>;
}
export default (withTranslation()(Customer));
