import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LadiDropdown from '../../components/LadiDropdown';
import appConfig from '../../config/app';
import DefineConst from './DefineConst';
import actionStaff from '../../redux/futures/staff/actions';

function ModalStaff(props) {
    const {
        t, title, btnOk, btnCancel, dataStaff, setIsFetchData,
    } = props;

    const styleInvalid = { border: '1px solid var(--danger-color)' };
    const styleValid = { boder: '1px solid var(--main-line)' };
    const defaultValidated = {
        email: false,
        role: false,
    };
    const defaultIsValidated = {
        email: true,
        role: true,
    };

    const dispatch = useDispatch();
    const [staff, setStaff] = useState(dataStaff);
    const [valid, setValid] = useState(defaultValidated);

    const changeDataForm = (e) => {
        const name = e.target.name;
        const field = {};
        if (e.target.type === 'checkbox') {
            field[name] = e.target.checked;
        } else {
            const value = e.target.value;
            field[name] = value;
            const validatedData = {};
            if (e.target.hasAttribute('required')) {
                if (value === '') {
                    validatedData[name] = false;
                } else {
                    validatedData[name] = true;
                }
            }
            setValid({ ...valid, ...validatedData });
        }
        setStaff({ ...staff, ...field });
    };

    const actionOk = () => {
        let isValid = true;
        let validData = {};
        if (!staff.email) {
            isValid = false;
            validData.email = false;
        }
        if (!staff.role) {
            isValid = false;
            validData.role = false;
        }
        if (!isValid) {
            setValid({ ...valid, ...validData });
            window.LadiUI.toast('danger', '', 'Vui lòng điền đầy đủ thông tin');
            return;
        }

        if (staff._id) {
            const data = { ...staff, ...{ id: staff._id } };
            dispatch(actionStaff.update(data));
        } else {
            dispatch(actionStaff.create(staff));
        }
        setIsFetchData();
        window.LadiUI.closeModal('white-page');
    };

    const eventCancel = () => {
        setValid(defaultIsValidated);
    };

    const eventSelectRole = (role) => {
        setStaff({ ...staff, ...{ role } });
        setValid({ ...valid, ...{ role: true } });
    };

    useEffect(() => {
        setStaff(dataStaff);
        setValid(defaultIsValidated);
    }, [dataStaff]);

    return <div className="ladiui modal" id="white-page">
        <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
            <div className="ladiui modal-content custom-ldp">
                <div className="ladiui modal-header custom-ldp">
                    <label className="ladiui title-text-form-lever1">{title}</label>
                    <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                        <i className="ladiui icon icon-ldp-close"></i>
                    </button>
                </div>
                <div className="ladiui form-group-custom">
                    <label className="ladiui title-text-form-lever2" >{t('STAFFS.EMAIL')}</label>
                    <input id="email" name="email" className="ladiui form-control" placeholder="Nhập email" required style={!valid.email ? styleInvalid : styleValid}
                        value={staff.email || ''} onChange={changeDataForm} />
                </div>
                {dataStaff.role !== DefineConst.STAFF_ROLE.OWNER
                    ? <div className="ladiui form-group-custom">
                        <LadiDropdown
                            labelDropdown={t('STAFFS.ROLE')}
                            listProp={appConfig.LADISHARE.STAFF_ROLES.filter((item) => item.value !== DefineConst.STAFF_ROLE.OWNER)}
                            valueProp={staff.role}
                            hideLabel={false}
                            validProp={valid.role}
                            cbProp={eventSelectRole}
                            defaultTextProp="Chọn quyền"
                        />
                    </div> : <></>
                }

                <div className="ladiui modal-footer custom-ldp">
                    <div></div>
                    <div className="ladiui footer-modal-btn">
                        <button type="button" data-dismiss="modal" data-target="white-page"
                            className="ladiui btn btn-secondary bold" onClick={eventCancel}>{btnCancel}</button>
                        <button type="button" className="ladiui btn btn-primary" onClick={actionOk}>{btnOk}</button>
                    </div>

                </div>
            </div>
        </div>
    </div >;
}

export default (withTranslation()(ModalStaff));
