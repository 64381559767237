import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiPagination from '../../components/LadiPagination';
import ConfirmModal from '../../components/ConfirmModal';
import appConfig from '../../config/app';
import actionCoupon from '../../redux/futures/coupon_code/actions';
import actionIntegration from '../../redux/futures/integrate_app/actions';
import LadiSearchInput from '../../components/LadiSearchInput';
import ModalCouponCode from './components/ModalCouponCode';
import CouponFilterCondition from './components/CouponFilterCondition';
import DefineConst from './DefineConst';
import DefineConstIntegrate from '../apps/DefineConst';
import BaseHelper from '../../helpers/BaseHelper';
import CheckAllActionCoupon from './components/CheckAllActionCoupon';

// eslint-disable-next-line max-lines-per-function
function CouponCode(props) {
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.DEFAULT_LIMIT,
        sort: { _id: -1 },
    };

    const { t } = props;

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState(defaultConditions);
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [isEdit, setIsEdit] = useState();
    const [coupon, setCoupon] = useState({});
    const listCoupon = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.coupons.coupons }));
    const affiliates = useSelector((state) => state.coupons.affiliates);
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const cbLoadList = () => {
        dispatch(actionCoupon.list(conditions));
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setSelectedIds(ids);
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const eventCreateCoupon = () => {
        setIsEdit(false);
        window.LadiUI.showModal('modal-coupon-code');
    };

    const eventEditCoupon = (item) => {
        setIsEdit(true);
        dispatch(actionCoupon.show(item._id));
        window.LadiUI.showModal('modal-coupon-code');
    };

    const eventSyncCoupon = (item) => {
        setCoupon(item);
        window.LadiUI.showModal('modal-confirm-sync');
    };

    const eventOkActionSync = () => {
        dispatch(actionCoupon.syncCoupon({ id: coupon._id }, cbLoadList));
    };

    const eventDeleteCoupon = (item) => {
        setCoupon(item);
        window.LadiUI.showModal('modal-confirm-delete');
    };

    const eventOkActionDelete = () => {
        dispatch(actionCoupon.delete({ id: coupon._id }, cbLoadList));
    };

    const renderAppIntegrate = (appIntegrate) => {
        let syncStatus = 'Chưa đồng bộ';
        switch (appIntegrate.status) {
            case 'SYNC': syncStatus = 'Đã đồng bộ'; break;
            case 'IN_SYNC': syncStatus = 'Đang đồng bộ'; break;
            case 'NOT_SYNC': syncStatus = 'Chưa đồng bộ'; break;
            default:
        }
        return <tr key={appIntegrate._id}>
            <td width={50}>
                <img src={DefineConstIntegrate.INTEGRATE_APPS[appIntegrate.app_type] ? DefineConstIntegrate.INTEGRATE_APPS[appIntegrate.app_type].logo : ''} width="30px" />
            </td>
            <td>{appIntegrate.integration.config.shop_id}</td>
            <td width={150}>{syncStatus}</td>
        </tr>;
    };

    const renderRecord = (item) => <tr className="ladiui table-vertical main" key={item._id}>
        <td scope="row">
            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element" name="checkbox" checked={selectedIds.includes(item._id)}
                value={item._id} onChange={eventCheck} />
        </td>
        <td><a href="#/" className="ladiui link" onClick={() => eventEditCoupon(item)} style={{ textTransform: 'uppercase' }}>{item.code}</a></td>
        <td>{item.affiliate ? item.affiliate.email : ''}</td>
        <td>
            <table className="table-integrate-app-coupon"><tbody>{item.app_integrate_coupons.map((appItem, index) => renderAppIntegrate(appItem))}</tbody></table>
        </td>
        <td className="ladiui-dropdown">
            <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                    <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                        <i className="ladiui icon icon-ldp-dot"></i>
                    </button>
                    <ul className="ladiui dropdown-menu custom-dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li onClick={() => eventEditCoupon(item)}><a className="ladiui dropdown-item" href="#/">
                            {t('COMMON.EDIT')}
                        </a></li>
                        <li onClick={() => eventSyncCoupon(item)}><a className="ladiui dropdown-item" href="#/">
                            {t('COUPONS.SYNCHRONIZATION_COUPON_TO_APP')}
                        </a></li>
                        {item.type === DefineConst.COUPON_TYPE.CUSTOM
                            ? <li onClick={() => eventDeleteCoupon(item)}><a className="ladiui dropdown-item" href="#/">
                                {t('COMMON.DELETE')}
                            </a></li>
                            : <></>}
                    </ul>
                </div>
            </div>
        </td>
    </tr>;

    useEffect(() => {
        dispatch(actionCoupon.list(conditions));
    }, [conditions]);

    useEffect(() => {
        dispatch(actionIntegration.list({
            sort: { _id: -1 }, group: 'SHOP', type: { $in: ['SAPO', 'HARAVAN', 'WORDPRESS', 'LADISALES'] }, limit: 1000,
        }));
    }, []);

    return <>
        <div className="ladiui content-main-title flex-row">
            <div className="ladiui">
                <div className="ladiui main-title">
                    {t('COUPONS.LIST_COUPON')}
                </div>
                <span className="color-1" style={{ marginTop: '8px', display: 'block' }}>Đăng ký và quản lý mã giảm giá cho cửa hàng của bạn.</span>
            </div>
            <button type="button" className="ladiui btn-open-modal btn btn-primary" onClick={eventCreateCoupon}>
                <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                <div className="ladiui btn-custom-text">{t('COUPONS.ADD_COUPON')}</div>
            </button>
        </div>
        <div className="ladiui access content-main-tool flex-row" style={{ marginTop: '10px' }}>
            <div style={{ minWidth: '270px' }}>
                <LadiSearchInput
                    keywordProp={conditions ? conditions.keyword : ''}
                    eventSearchKeyboardProp={eventSearchKeyboard}
                    eventClickSearchButtonProp={eventClickSearchButton}
                />
            </div>
        </div>
        <div className="list-group-affiliate">
            <table className="ladiui table text-left">
                <thead>
                    <tr className="ladiui table-vertical header">
                        <th className="ladiui col-first" scope="col">
                            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-commission"
                                name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                        </th>
                        <th>
                            {selectedIds.length > 0 ? <CheckAllActionCoupon ids={selectedIds} cb={() => dispatch(actionCoupon.list(conditions))} /> : t('COUPONS.CODE')}
                        </th>
                        <th className="ladiui" scope="col">{t('COUPONS.AFFILIATE_EMAIL')}</th>
                        <th className="ladiui" scope="col">Tích hợp giảm giá</th>
                        <th className="ladiui" scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {listCoupon.items.length > 0
                        ? listCoupon.items.map((values) => (renderRecord(values)))
                        : (<tr className="ladiui table-vertical main">
                            <td colSpan={6}>
                                <span className="ladiui small-label">{t('NO_DATA')}</span>
                            </td>
                        </tr>)}
                </tbody>
            </table>
            {listCoupon.total > 0
                ? <LadiPagination
                    conditionsProp={conditions}
                    listName={t('COUPONS.CODE')}
                    items={listCoupon.items}
                    total={listCoupon.total}
                    limitAction={selectLimit}
                    pageAction={selectPage}
                /> : ''}

        </div>
        <ModalCouponCode isEdit={isEdit} cb={() => dispatch(actionCoupon.list(conditions))} />
        <ConfirmModal
            id="modal-confirm-sync"
            title="Bạn chắc chắn muốn đồng bộ mã khuyến mãi này?"
            content="Mã khuyến mãi sẽ được đồng bộ tới tất cả các ứng dụng đã được tích hợp."
            onOk={() => eventOkActionSync()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="modal-confirm-delete"
            title="Bạn chắc chắn muốn xoá mã khuyến mãi này?"
            content="Mã khuyến mãi sẽ được xoá khỏi hệ thống và trong tất cả các ứng dụng đã được tích hợp."
            onOk={() => eventOkActionDelete()}
            cancelText={t('COMMON.CANCELED')}
            okText={t('COMMON.OK')}
            classBtn="btn-danger"
        />
    </>;
}
export default (withTranslation()(CouponCode));
