import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import authActions from '../../redux/futures/auth/actions';
import styles from '../../assets/css/custom.module.css';
import BaseHelper from '../../helpers/BaseHelper';
import appConfig from '../../config/app';
import config from '../../config/config';
import actionAffiliateGroup from '../../redux/futures/affiliate_group/actions';
import actionStore from '../../redux/futures/store/actions';

// eslint-disable-next-line max-lines-per-function
function Topbar(props) {
    const { t, availableRoutes } = props;
    const dispatch = useDispatch();
    const user = useSelector((state) => state.store.userInfo);
    const group = useSelector((state) => state.affiliateGroups.group);

    const [isAffDomain, setIsAffDomain] = useState(false);
    
    const getNameScreen = () => {
        let nameScreen = '';
        const paths = window.location.pathname.split('/');
        if (paths[1] === '') {
            return t('MENU.DASH_BOARD');
        }
        availableRoutes.forEach((values, index) => {
            const controller = values.path.split('/');
            if (controller[1] == paths[1]) {
                nameScreen = values.name;
            }
        });
        return nameScreen;
    };

    const changeStore = (storeID) => {
        BaseHelper.setCookie(appConfig.COOKIE.STORE_ID, storeID);
        window.location.href = '/';
    };

    const getPkg = (pkg) => {
        let pkgContent = <></>;
        switch (pkg) {
            case 'TRAIL':
                pkgContent = <button className="ladiui status-pro flex-row" style={{ height: '18px', padding: '2px 6px', cursor: 'pointer' }}>
                    <div className="ladiui status-text">TRAIL</div>
                </button>;
                break;
            case 'STANDARD':
                pkgContent = <button className="ladiui status-starter flex-row" style={{ height: '18px', padding: '2px 6px', cursor: 'pointer' }}>
                    <div className="ladiui status-text">STANDARD</div>
                </button>;
                break;
            case 'BUSINESS':
                pkgContent = <button className="ladiui status-premium flex-row" style={{ height: '18px', padding: '2px 6px', cursor: 'pointer' }}>
                    <div className="ladiui status-text">BUSINESS</div>
                </button>;
                break;

            default:
        }
        return pkgContent;
    };

    const getUpgradeStatus = (store) => {
        if (config.APP_AFFILIATE) return false;

        if (!store) return true;

        if (store.pkg_name === 'TRAIL') return true;

        if (store.pkg_exp) {
            const today = new Date();
            const last7Day = today.setDate(today.getDate() - 7);
            const exp = new Date(store.pkg_exp);
            if (exp.getTime() <= last7Day) return true;
        }
        return false;
    };

    const eventUpgrade = () => {
        window.LadiUI.showModal('modal-upgrade-package');
    };

    const eventSignOut = (store) => {
        if (config.APP_AFFILIATE) BaseHelper.setCookie(appConfig.COOKIE.STORE_ALIAS, store.alias);
        dispatch(authActions.signOut());
    };

    useEffect(() => {
        if (config.APP_AFFILIATE) {
            dispatch(actionAffiliateGroup.show(user.user.affiliate_group_id));

            if (user) {
                let showModalUpdateBankInfo = BaseHelper.getCookie('SHOW_MODAL_UPDATE_BANK_INFO');

                if (typeof showModalUpdateBankInfo == 'undefined') {
                    showModalUpdateBankInfo = (user.user.bank_name && user.user.bank_account_number && user.user.bank_account_name) ? 'off' : 'on';
                    BaseHelper.setCookie('SHOW_MODAL_UPDATE_BANK_INFO', showModalUpdateBankInfo, 1);
                }
                if (showModalUpdateBankInfo === 'on') {
                    window.LadiUI.showModal('modal-update-bank-info');
                }
            }
        }

        let domainUrl = window.location.href;
        if (domainUrl.includes('affiliate.ladipage.com') || domainUrl.includes('aff.dev.ladishare.com')) {
            setIsAffDomain(true);
        }

        dispatch(actionStore.getAffiliateDashboardSetting({ field: 'affiliate_dashboard_setting' }));
    }, []);
    return <>
        <div className="ladiui topbar flex-row">
            <div className="ladiui topbar-menu">
                {getNameScreen()}
            </div>
            <div className="ladiui topbar-menu-right flex-row">
                <div className="ladiui app-tools flex-row">
                    <div className="ladiui tool-help" style={{ position: 'relative' }}>
                        <a href="#/" className="dropdown-toggle" data-toggle="dropdown">
                            <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" />
                        </a>
                        <ul className="ladiui dropdown-menu">
                            <li>
                                <a className="ladiui dropdown-item" href="https://ladipage.vn/tai-lieu-affiliate?utm_source=nopaid-0-affladipage-ldp-%3Ftaikhoan-huongdan-aff">
                                    <i className="ladiui icon icon-ldp-helper-document"></i>
                                    Hướng dẫn
                                </a>
                            </li>
                            <li>
                                <a className="ladiui dropdown-item" href="https://www.ladiboost.vn/request?by=LadiShare" target="_blank" rel="noreferrer">
                                    <i className="ladiui icon icon-ldp-helper-document"></i>
                                    Yêu cầu tính năng
                                </a>
                            </li>
                            {
                                isAffDomain
                                    ?
                                    <li>
                                        <a className="ladiui dropdown-item"
                                           href="https://www.facebook.com/groups/900657465061235/?gidzl=WFm6NdG4e2-1vmzHTnoeB8dZ159W7laebE01MMD8go77u51POqlvAPUpK5LdIln_b-5I03R7pPv9TWMX8G" target="_blank"
                                           rel="noreferrer">
                                            <i className="ladiui icon icon-ldp-fb-group"></i>
                                            Cộng đồng Affiliate LadiPage
                                        </a>
                                    </li>
                                    :
                                    <li>
                                        <a className="ladiui dropdown-item"
                                           href="https://www.facebook.com/groups/ladipagevietnam" target="_blank"
                                           rel="noreferrer">
                                            <i className="ladiui icon icon-ldp-fb-group"></i>
                                            Cộng đồng LadiPage
                                        </a>
                                    </li>
                            }

                                </ul>
                                </div>

                            {getUpgradeStatus(user.store)
                                ? <div className="ml-12 mr-24">
                                <button className="ladiui btn btn-outline-primary" onClick={eventUpgrade}>Nâng cấp
                        </button>
                    </div>
                    : <></>
                    }

                </div>
                <div className="ladiui account">

                    <div className={`${styles['topbar-account']} ladiui my-account dropdown-toggle`}
                         data-toggle="dropdown">
                        <div className="ladiui my-account-avata">
                        {user.user.avatar ? <img src={user.user.avatar} alt=""
                                width={40} height={40} style={{ objectFit: 'cover', borderRadius: '50%' }} /> : user.user.fullname.charAt(0)}
                        </div>
                    </div>
                    <div className={`ladiui account dropdown-menu ${styles['r-10']}`}>
                        <div className={`ladiui account-content ${styles['p-0']}`}>
                            <div className="ladiui account-header">

                                <div className="ladiui account-header-text" style={{ padding: '14px 24px 0 24px' }}>
                                    <span className="ladiui account-name body-1"> {user.user.fullname} {user.user.role ? `(${user.user.role})` : ''}</span>
                                    <div className="ladiui account-email subtitle-2 secondary-text">{user.user.email}</div>
                                    {/*{!config.APP_AFFILIATE*/}
                                    {/*    ? <div className="ladiui account-status" style={{ marginTop: '10px' }}>*/}
                                    {/*        <div className={`ladiui my-account-type ${styles['p-0']}`} onClick={eventUpgrade}>*/}
                                    {/*            {getPkg(user.store.pkg_name)}*/}
                                    {/*        </div>*/}
                                    {/*        <span className="ladiui account-duration secondary-text caption" onClick={eventUpgrade} style={{ cursor: 'pointer' }}>*/}
                                    {/*            Thời hạn: {user.store.pkg_exp ? BaseHelper.formatStrToDate(user.store.pkg_exp, 'DD/MM/YYYY') : 'Không thời hạn'}</span>*/}
                                    {/*    </div>*/}
                                    {/*    : <div className="ladiui account-status" style={{ marginTop: '10px' }}>*/}
                                    {/*        <div className={`ladiui my-account-type ${styles['p-0']}`}>*/}
                                    {/*            {group ? <button className="ladiui status-starter flex-row" style={{*/}
                                    {/*                height: '18px', padding: '2px 6px', cursor: 'pointer', width: '100%',*/}
                                    {/*            }}>*/}
                                    {/*                <div className="ladiui status-text">{group.label}</div>*/}
                                    {/*            </button> : <></>}*/}
                                    {/*        </div>*/}
                                    {/*    </div>}*/}
                                </div>
                            </div>
                        </div>
                        {!config.APP_AFFILIATE
                            ? <div className="ladiui account-body">
                                {user && user.stores
                                    ? user.stores.map((item, index) => <a key={index} href="#/"
                                        className={`ladiui dropdown-item account-body-item ${item._id === user.store._id ? 'active' : ''}`}
                                        onClick={() => changeStore(item._id)}>
                                        <div className="ladiui avartar">
                                            <div className="ladiui body-avatar">
                                                <img src="https://w.ladicdn.com/ladiui/icons/ldicon-showcase.svg" />
                                            </div>
                                        </div>
                                        <div className="ladiui account-body-content">
                                            <span className="ladiui account-name default-text subtitle-2">{item.name}</span>
                                            <div></div>
                                        </div>
                                        {item._id === user.store._id
                                            ? <i className="ladiui account-tick icon icon-account-check"></i>
                                            : <></>}
                                    </a>)
                                    : <></>}
                            </div>
                            : <></>}

                        <div className={`ladiui account-content-footer ${styles['p-0']}`}>
                            <div className="ladiui account-footer">

                            </div>
                        </div>
                        {config.APP_AFFILIATE
                            ? <a className="ladiui dropdown-item" href="/account-info">
                                <i className="ladiui icon icon-ldp-setting-builder"></i>
                                Quản lý tài khoản
                            </a>
                            : <a className="ladiui dropdown-item" target="_blank" href={config.ACCOUNT_UID ? config.ACCOUNT_UID : 'http://accounts.ladiuid.com'} rel="noreferrer">
                                <i className="ladiui icon icon-ldp-setting-builder"></i>
                                Quản lý tài khoản
                            </a>}
                        <a className="ladiui dropdown-item" onClick={() => eventSignOut(user.store)}>
                            <i className="ladiui icon icon-ldp-log-out"></i>
                            Đăng xuất
                        </a>
                    </div>
                </div>
            </div>
        </div >
        <div className="ladiui modal" id="modal-upgrade-package">
            <div className="ladiui modal-dialog modal-dialog-centered custom-package-type">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <h1 className="ladiui modal-title bold">Thông tin gói sử dụng</h1>
                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close"><i className="ladiui icon icon-ldp-close"></i></button>
                    </div>
                    <div className="ladiui container-package-type">
                        <div className="ladiui item-package">
                            <div className="ladiui text-package-type">Tên gói</div>
                            <div className="ladiui value-package-type custom">{user.store.pkg_name}</div>
                        </div>
                        <div className="ladiui item-package">
                            <div className="ladiui text-package-type">Ngày hết hạn</div>
                            <div className="ladiui value-package-type">{user.store.pkg_exp ? BaseHelper.formatStrToDate(user.store.pkg_exp, 'DD/MM/YYYY') : 'Không thời hạn'}</div>
                        </div>
                        <div className="ladiui item-package">
                            <div className="ladiui text-package-type">Số affiliate còn lại có thể tạo</div>
                            <div className="ladiui value-package-type">{user.store.affiliate_total ? (user.store.affiliate_total !== -1 ? user.store.affiliate_total : 'Không giới hạn') : 0}</div>
                        </div>
                        <div className="ladiui item-package">
                            <div className="ladiui text-package-type">Số chuyển đổi còn lại</div>
                            <div className="ladiui value-package-type">{user.store.conversion_per_month ? (user.store.conversion_per_month !== -1 ? user.store.conversion_per_month : 'Không giới hạn') : 0}</div>
                        </div>
                    </div>
                    <div className="ladiui modal-footer custom-package-type">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <a href="https://www.ladishare.vn/" target="_blank" className="ladiui btn small txt body3 btn-primary" rel="noreferrer">Gia hạn &amp; Nâng cấp</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="ladiui modal" id="modal-update-bank-info">
            <div className="ladiui modal-dialog modal-dialog-centered custom-package-type">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <h1 className="ladiui modal-title bold">Cập nhật thông tin ngân hàng</h1>
                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close"
                            onClick={() => BaseHelper.setCookie('SHOW_MODAL_UPDATE_BANK_INFO', 'off', 1)}><i className="ladiui icon icon-ldp-close"></i></button>
                    </div>
                    <div className="ladiui container-package-type">
                        Thông tin tài khoản ngân hàng của bạn chưa được cập nhật. Vui lòng bổ sung để đảm bảo quyền lợi nhận được hoa hồng.
                    </div>
                    <div className="ladiui modal-footer custom-package-type">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <a href="/account-info" className="ladiui btn small txt body3 btn-primary" rel="noreferrer" onClick={() => BaseHelper.setCookie('SHOW_MODAL_UPDATE_BANK_INFO', 'off', 1)}>Cập nhật tài khoản</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
export default (withTranslation()(Topbar));
