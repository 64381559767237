import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../components/ConfirmModal';
import LadiDropdown from '../../components/LadiDropdown';
import appConfig from '../../config/app';
import ModalAppIntegrate from './components/ModalAppIntegrate';
import ModalSettingAppIntegrate from './components/ModalSettingAppIntegrate';
import SearchCondition from './components/SearchCondition';
import DefineConst from './DefineConst';
import actionAppIntegrate from '../../redux/futures/integrate_app/actions';
import LadiPagination from '../../components/LadiPagination';
import CheckAllActionApp from './components/CheckAllActionApp';
import BaseHelper from '../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function
function AppIntegrate(props) {
    const { t } = props;

    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { created_at: -1 },
    };

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState(defaultConditions);
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [appIntegrate, setAppIntegrate] = useState(null);
    const [resetModal, setResetModal] = useState(true);
    const listAppIntegrate = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.appIntegrate.integrateApps }));

    const cb = () => {
        dispatch(actionAppIntegrate.list(conditions));
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setSelectedIds(ids);
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const eventShowModalAddIntegrateApp = () => {
        setResetModal(true);
        window.LadiUI.showModal('modal-add-integrate-app');
    };

    const eventDisconnect = (item) => {
        setAppIntegrate(item);
        window.LadiUI.showModal('modal-confirm-disconnect');
    };

    const eventSelectApp = (data) => {
        setConditions({ ...conditions, type: data });
    };

    const eventOkDisconnect = () => {
        dispatch(actionAppIntegrate.delete({ ids: [appIntegrate._id] }, cb));
    };

    const eventConfig = (item) => {
        setAppIntegrate(item);
        window.LadiUI.showModal('modal-setting-integrate-app');
    };

    const getPlatformName = (item) => {
        let formContent;
        switch (item.type) {
            case DefineConst.INTEGRATE_APPS_TYPE.LADISALES:
                formContent = '';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.SHOPIFY:
                formContent = 'Shopify';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.GETFLY:
                formContent = 'Getfly';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.WOOCOMMERCE:
                formContent = 'Woocommerce';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.HARAVAN:
                formContent = 'Haravan';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.SAPO:
                formContent = '';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.NHANH:
                formContent = 'Nhanh.vn';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.WORDPRESS:
                formContent = 'Woocommerce';
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.SDK:
                formContent = 'SDK - Custom API';
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.LADIFLOW:
                formContent = 'LadiFlow';
                break;

            default: break;
        }
        return formContent;
    };

    const eventChangeStatus = (e, item) => {
        const status = e.target.checked ? DefineConst.STATUS.ON : DefineConst.STATUS.OFF;
        const data = {
            id: item._id,
            status,
            name: item.name,
        };
        dispatch(actionAppIntegrate.updateStatus(data));
    };

    const copyClipboard = (id) => {
        if (!navigator.clipboard) return;

        const copyText = document.getElementById(id);
        navigator.clipboard.writeText(copyText.textContent);
        window.LadiUI.toast('success', '', t('COMMON.COPIED'), 5000, 'bottom-left');
    };

    const renderRecord = (item) => <tr className="ladiui table-vertical main" key={item._id}>
        <td scope="row">
            {!item.is_default
                ? <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element"
                    name="checkbox" checked={selectedIds.includes(item._id)}
                    value={item._id} onChange={eventCheck} />
                : <></>}
        </td>
        <td>
            <div className="ladiui flex">{item.name}
                {!item.is_default
                    ? <></>
                    : <button className="ladiui status-premium flex-row" style={{
                        height: '18px',
                        padding: '2px 6px',
                        marginLeft: '5px',
                        background: 'var(--secondary-color)',
                        borderColor: 'var(--secondary-color)',
                    }}>
                        <div className="ladiui status-text" style={{ color: 'var(--main-default)' }}>Mặc định</div>
                    </button>}
            </div>
        </td>
        <td>
            <div className="ladiui flex-row" style={{ alignItems: 'center' }}>
                <img src={DefineConst.INTEGRATE_APPS[item.type] ? DefineConst.INTEGRATE_APPS[item.type].logo : ''} height="20px" className="mr-8" />{getPlatformName(item)}
            </div>
        </td>
        <td>{item.group || 'SHOP'}</td>
        <td><span id={`shop-id-${item._id}`} onClick={() => copyClipboard(`shop-id-${item._id}`)}
            style={{ cursor: 'pointer' }}>{item.config.shop_id || ''}</span></td>
        <td>
            <label className="ladiui switch m-0">
                <input type="checkbox" defaultChecked={item.status === DefineConst.STATUS.ON}
                    onChange={(e) => eventChangeStatus(e, item)}
                />
                <span className="ladiui slider round"></span>
            </label>
        </td>
        <td>{BaseHelper.formatStrToDate(item.created_at, 'DD/MM/YYYY')}</td>
        <td className="ladiui-dropdown">
            {!item.is_default
                ? <div className="ladiui btn-group">
                    <div className="ladiui dropdown">
                        <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                            <i className="ladiui icon icon-ldp-dot"></i>
                        </button>
                        <ul className="ladiui dropdown-menu custom-dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li onClick={() => eventConfig(item)}><a className="ladiui dropdown-item" href="#/">
                                Cài đặt
                            </a></li>
                            <li onClick={() => eventDisconnect(item)}><a className="ladiui dropdown-item" href="#/">
                                Xoá ứng dụng
                            </a></li>
                        </ul>
                    </div>
                </div>
                : <></>}
        </td>
    </tr>;

    useEffect(() => {
        if (conditions) dispatch(actionAppIntegrate.list(conditions));
    }, [conditions]);

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui flex-row">
                    <div className="ladiui main-title">
                        {t('INTEGRATE_APPS.LIST_INTEGRATE_APP')}
                    </div>
                </div>
                <button type="button" className="ladiui btn btn-primary btn-lg" onClick={() => eventShowModalAddIntegrateApp()}>
                    <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                    <div className="ladiui btn-custom-text ">Thêm tích hợp</div>
                </button>
            </div>
            <div className="ladiui content-main-tool flex-row align-item-baseline">
                <div style={{ minWidth: '270px' }}>
                    <SearchCondition
                        keywordProp={conditions ? conditions.keyword : ''}
                        eventSearchKeyboardProp={eventSearchKeyboard}
                        eventClickSearchButtonProp={eventClickSearchButton}
                    />
                </div>
                <div className="ladiui btn-group custom-btn-list-landingpage">
                    <LadiDropdown
                        labelDropdown={t('AFFILIATES.STATUS_LABEL')}
                        listProp={[{ name: 'Chọn nền tảng', value: null }, ...appConfig.LADISHARE.APPS_INTEGRATE, { value: 'SDK', name: 'SDK - Custom API' }]}
                        valueProp={null}
                        hideLabel={true}
                        validProp={true}
                        defaultTextProp={'Nền tảng'}
                        cbProp={eventSelectApp}
                    />
                </div>
            </div>
            <div>
                <table className="ladiui table text-left">
                    <thead>
                        <tr className="ladiui table-vertical header">
                            <th className="ladiui col-first" scope="col">
                                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-commission"
                                    name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                            </th>
                            <th>
                                {selectedIds.length > 0 ? <CheckAllActionApp ids={selectedIds} cb={() => dispatch(actionAppIntegrate.list(conditions))} /> : 'Tên tài khoản liên kết'}
                            </th>
                            <th className="ladiui" scope="col">Loại tài khoản</th>
                            <th className="ladiui" scope="col">Nhóm</th>
                            <th className="ladiui" scope="col">Shop ID</th>
                            <th className="ladiui" scope="col">Trạng thái</th>
                            <th className="ladiui" scope="col">Ngày cập nhật</th>
                            <th className="ladiui" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listAppIntegrate.items.length > 0
                            ? listAppIntegrate.items.map((values) => (renderRecord(values)))
                            : (<tr className="ladiui table-vertical main">
                                <td colSpan={6}>
                                    <span className="ladiui small-label">{t('NO_DATA')}</span>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
                {listAppIntegrate.total > 0
                    ? <LadiPagination
                        conditionsProp={conditions}
                        listName="tích hợp"
                        items={listAppIntegrate.items}
                        total={listAppIntegrate.total}
                        limitAction={selectLimit}
                        pageAction={selectPage}
                    /> : ''}
            </div>
        </div>
        <ModalAppIntegrate
            title={'Chọn nền tảng'}
            resetModal={resetModal}
            cbResetModal={() => setResetModal(false)}
            cb={cb}
        />

        <ModalSettingAppIntegrate
            appIntegrateProp={appIntegrate}
            cb={cb}
        />

        <ConfirmModal
            id="modal-confirm-disconnect"
            title="Bạn có chắc muốn ngắt kết nối ứng dụng không?"
            content="Sau khi ngắt kết nối, bạn có thể tiến hành liên kết lại với ứng dụng."
            onOk={() => eventOkDisconnect()}
            cancelText={t('INTEGRATE_APPS.CANCELED')}
            okText={t('INTEGRATE_APPS.OK')}
            classBtn="btn-primary"
        />
    </>;
}

export default (withTranslation()(AppIntegrate));
