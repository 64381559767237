import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';
import CommissionPlanPaymentBox from './CommissionPlanPaymentBox';
import DefineConst from '../DefineConst';
import actionCommissionPlan from '../../../redux/futures/commission_plan/actions';

// eslint-disable-next-line max-lines-per-function
function ModalCommissionPlan(props) {
    const {
        t, modalCommissionPlan, listAffiliateGroup, cb,
    } = props;

    const styleInvalid = { border: '1px solid var(--danger-color)' };
    const styleValid = { boder: '1px solid var(--main-line)' };

    const defaultValidated = {
        name: false,
    };
    const defaultIsValidated = {
        name: true,
    };

    const defaultCommissionPlanPayment = {
        name: '',
        affiliate_group_id: null,
        commission_plan_id: '',
        is_delete: false,
        level: 1,
        payment_for: DefineConst.PAYMENT_FOR.ORDER,
        payment_term: { payment_term_type: DefineConst.PAYMENT_TERM_TYPE.DAY, payment_term_number: 1 },
        payment_type: DefineConst.PAYMENT_TYPE.PAYMENT_TYPE_PERCENT,
        payment_value: 0,
        commission_calc_by: 'BY_COMMISSION',
        commission_payment_type_level_1: 'PERCENT',
        commission_payment_type_level_2: 'PERCENT',
        commission_payment_type_level_all: 'PERCENT',
        commission_payment_value_level_1: 0,
        commission_payment_value_level_2: 0,
        payment_intervals: {},
        payment_price_range: {},
        skus: {},
        status: true,
        _id: BaseHelper.generateRandomString(16),
        is_new: true,
    };

    const defaultCommissionPlan = {
        currency: 'VND',
        is_default: false,
        name: '',
        status: true,
        store_id: '',
        commision_plan_payment_items: defaultCommissionPlanPayment,
        _id: '',
    };

    const dispatch = useDispatch();
    const [commissionPlan, setCommissionPlan] = useState(defaultCommissionPlan);
    const [validated, setValidated] = useState(defaultValidated);
    const [commissionPlanPayments, setCommissionPlanPayments] = useState([defaultCommissionPlanPayment]);
    const commissionPlanDetail = useSelector((state) => (state.commissionPlans.commissionPlan));
    const commissionPlanPaymentsDetail = useSelector((state) => (state.commissionPlans.commissionPlanPayments));

    const eventChangeInputCommissionPlan = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        const data = {};
        data[fieldName] = fieldValue;
        setCommissionPlan({ ...commissionPlan, ...data });
        const validatedData = {};
        if (e.target.hasAttribute('required')) {
            if (fieldValue === '') {
                validatedData[fieldName] = false;
            } else {
                validatedData[fieldName] = true;
            }
        }
        setValidated({ ...validated, ...validatedData });
    };

    const eventChangeCommissionPlanPayment = (commissionPlanPaymentId, item) => {
        for (let i = 0; i < commissionPlanPayments.length; i++) {
            if (commissionPlanPayments[i]._id === commissionPlanPaymentId) {
                commissionPlanPayments[i] = item;
            }
        }
        setCommissionPlanPayments(commissionPlanPayments);
    };

    const eventRemoveCommissionPlanPayment = (id) => {
        const newListCommissionPlanPayment = commissionPlanPayments.filter((value) => (value._id !== id));
        setCommissionPlanPayments(newListCommissionPlanPayment);
    };

    const eventAddMoreCommissionPlanPayment = () => {
        const newCommissionPlanPayment = { ...defaultCommissionPlanPayment, ...{ _id: BaseHelper.generateRandomString(16) } };
        setCommissionPlanPayments([...commissionPlanPayments, ...[newCommissionPlanPayment]]);
    };

    const eventCancelModal = () => {
        window.LadiUI.closeModal('create-ladishare-comission-plan');
        setCommissionPlan(defaultCommissionPlan);
        setCommissionPlanPayments([defaultCommissionPlanPayment]);
    };

    const eventSubmitCommissionPlan = () => {
        let isValid = true;
        const validValue = {};
        if (commissionPlan.name === '') {
            isValid = false;
            validValue.name = false;
        }
        if (!isValid) {
            setValidated({ ...validated, ...validValue });
            window.LadiUI.toast('danger', '', 'Vui lòng điền đầy đủ thông tin', 5000, 'bottom-left');
            return;
        }
        for (let i = 0; i < commissionPlanPayments.length; i++) {
            if (commissionPlanPayments[i].affiliate_group_id && commissionPlanPayments[i].affiliate_group_id.length === 0) {
                isValid = false;
            }
        }
        if (!isValid) {
            window.LadiUI.toast('danger', '', 'Vui lòng chọn nhóm affiliate cho cấu hình', 5000, 'bottom-left');
            return;
        }

        const data = {
            ...commissionPlan,
            ...{ commision_plan_payment_items: commissionPlanPayments },
        };
        if (commissionPlan._id !== '') {
            data.id = commissionPlan._id;
            dispatch(actionCommissionPlan.update(data, cb));
        } else {
            dispatch(actionCommissionPlan.create(data, cb));
        }
        setCommissionPlan(defaultCommissionPlan);
        setCommissionPlanPayments([defaultCommissionPlanPayment]);
        window.LadiUI.closeModal('create-ladishare-comission-plan');
    };

    useEffect(() => {
        if (commissionPlanDetail) {
            setCommissionPlan(commissionPlanDetail);
        }
        setValidated(defaultIsValidated);
    }, [commissionPlanDetail]);

    useEffect(() => {
        if (commissionPlanPaymentsDetail) {
            for (let i = 0; i < commissionPlanPaymentsDetail.length; i++) {
                commissionPlanPaymentsDetail[i].affiliate_group_id = commissionPlanPaymentsDetail[i].affiliate_group_id.filter((groupId) => groupId !== '-1');
            }
            setCommissionPlanPayments(commissionPlanPaymentsDetail);
        }
    }, [commissionPlanPaymentsDetail]);

    useEffect(() => {
        if (!modalCommissionPlan.isEdit) {
            setCommissionPlan(defaultCommissionPlan);
            setCommissionPlanPayments([defaultCommissionPlanPayment]);
            // setValidated(defaultValidated);
        }
    }, [modalCommissionPlan.isEdit]);

    return commissionPlan ? <>
        <div className="ladiui modal" id="create-ladishare-comission-plan">
            <div className="ladiui modal-dialog modal-dialog-centered custom-popups-width">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-popupX">
                        <label className="ladiui title-text-form-lever1">{modalCommissionPlan.title}</label>
                        <button type="button" data-dismiss="modal" data-target="create-ladishare-comission-plan"
                            className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui">
                        <div className="ladiui form-group-custom left">
                            <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.NAME_COMMISSION_PLAN')}</label>
                            <input id="name" name="name" className="ladiui form-control btn-sm w-576" placeholder="Nhập tên gói hoa hồng" required
                                style={!validated.name ? styleInvalid : styleValid}
                                value={commissionPlan.name ? commissionPlan.name : ''} onChange={eventChangeInputCommissionPlan} />
                        </div>
                        <div className="ladiui form-group-custom right">
                            <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.CURRENCY')}</label>
                            <div className="ladiui btn-group custom-form-dynamic">
                                <div className="ladiui btn-group">
                                    <div className="ladiui dropdown">
                                        <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown custom-form-dynamic show btn-sm"
                                            id="dropdownMenuButton3" data-toggle="dropdown">
                                            <i></i><span className="ladiui dropdown-text">{appConfig.LADISHARE.CURRENCY_VALUES.map((values) => (
                                                values.value === commissionPlan.currency ? values.name : ''
                                            ))}</span>
                                        </button>
                                        <ul className="ladiui dropdown-menu custom-form-dynamic w-175">
                                            {appConfig.LADISHARE.CURRENCY_VALUES.map((values) => (
                                                <li key={values.value}>
                                                    <a className="ladiui dropdown-item font-size-14" href="#/"
                                                        onClick={() => setCommissionPlan({ ...commissionPlan, ...{ currency: values.value } })}>
                                                        {values.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="ladiui form-group-custom">
                        <div className="ladiui flex-row">
                            <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.COMMISSION_PLAN_PAYMENT')}</label>
                        </div>
                        <div className="font-size-14">Bạn có thể sử dụng nhiều cấu hình hoa hồng để thiết lập cho các Affiliates</div>
                    </div>
                    {commissionPlanPayments ? commissionPlanPayments.map((item) => <CommissionPlanPaymentBox
                        key={item._id}
                        commissionPlanPayment={item}
                        eventChangeCommissionPlanPayment={eventChangeCommissionPlanPayment}
                        eventRemoveCommissionPlanPayment={eventRemoveCommissionPlanPayment}
                        affiliateGroup={listAffiliateGroup}
                        currency={commissionPlan.currency}
                    />) : ''}
                    <a href="#/" className="ladiui proviso" onClick={eventAddMoreCommissionPlanPayment}>
                        <img src="https://w.ladicdn.com/design-system/icons/icon-add-circle-outline.svg" alt="" />
                        <p className="ladiui proviso-text">{t('COMMISSIONS.ADD_COMMISSION_PLAN_PAYMENT')}</p>
                    </a>
                    <div className="ladiui modal-footer custom-ldp-popupX mod">
                        <div className="ladiui modal-footer-link">
                        </div>
                        <div className="ladiui footer-modal-btn">
                            <button type="button" className="ladiui btn btn-secondary bold" onClick={() => eventCancelModal()}>{t('COMMISSIONS.CANCELED')}</button>
                            <button type="button" className="ladiui btn btn-primary" onClick={eventSubmitCommissionPlan}>{modalCommissionPlan.nameButton}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="ladiui modal" id="modal-help-level">
            <div className="ladiui modal-dialog modal-dialog-centered">
                <div className="ladiui modal-content">
                    <div className="ladiui modal-header">
                        <label className="ladiui title-text-form-lever1">Thông tin về Level</label>
                        <button data-dismiss="modal" data-target="smallModal"
                            className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui modal-body">
                        <div style={{ lineHeight: '24px' }}>- Hoa hồng sẽ được tính cho Affiliate có cấp (Level) tương ứng<br />
                            - Level 1: Là những Affiliate không được giới thiệu từ bất kỳ Affiliate nào<br />
                            - Level 2: Là những Affiliate được giới thiệu từ Affiliate Level 1<br />
                            - Level 3: Là những Affiliate được giới thiệu từ Affiliate Level 2<br />
                            - Tất cả các level: Hoa hồng sẽ được tính ngay cho affiliate mà không cần dựa trên cấp bậc.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="ladiui modal" id="modal-help-expired-time">
            <div className="ladiui modal-dialog modal-dialog-centered">
                <div className="ladiui modal-content">
                    <div className="ladiui modal-header">
                        <label className="ladiui title-text-form-lever1">Thông tin về thời gian cấu hình</label>
                        <button data-dismiss="modal" data-target="smallModal"
                            className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui modal-body">
                        <div style={{ lineHeight: '24px' }}>- Hoa hồng sẽ được tính tới thời điểm đã được cài đặt.<br />
                            - Nếu không cài đặt, hoa hồng sẽ được tính tại bất kỳ thời điểm nào.</div>
                    </div>
                </div>
            </div>
        </div>
    </> : <><div className="ladiui modal" id="create-ladishare-comission-plan"></div></>;
}

export default (withTranslation()(ModalCommissionPlan));
