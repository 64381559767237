import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LadiDateRangePicker from '../../../components/LadiDateRangePicker';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';



function FilterDashboardPublisher(props) {
    const { t, filterAction } = props;

    const moment = BaseHelper.getMoment();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [conditions, setConditions] = useState(null);

    const actionFilter = (type) => {
        let startDateNew = null;
        let endDateNew = null;
        switch (type) {
            case 'TODAY':
                startDateNew = moment();
                endDateNew = moment();
                break;
            case 'YESTERDAY':
                startDateNew = moment().subtract(1, 'day');
                endDateNew = moment().subtract(1, 'day');
                break;
            case '7DAYSAGO':
                startDateNew = moment().subtract(7, 'day');
                endDateNew = moment();
                break;
            case '30DAYSAGO':
                startDateNew = moment().subtract(30, 'day');
                endDateNew = moment();
                break;
            case '90DAYSAGO':
                startDateNew = moment().subtract(90, 'day');
                endDateNew = moment();
                break;
            case 'THIS_MONTH':
                startDateNew = moment().startOf('month');
                endDateNew = moment();
                break;
            case 'LAST_MONTH':
                startDateNew = moment().subtract(1, 'month').startOf('month');
                endDateNew = moment().subtract(1, 'month').endOf('month');
                break;
            default:
        }

        if (type !== conditions.filter_type) {
            setFromDate(startDateNew);
            setToDate(endDateNew);
            setConditions({
                ...conditions, date_from: startDateNew.format('YYYY-MM-DD 00:00:01'), date_to: endDateNew.format('YYYY-MM-DD 23:59:59'), filter_type: type,
            });
        }
    };

    useEffect(() => {
        setFromDate(moment().startOf('month'));
        setToDate(moment());
        setConditions({
            ...conditions, date_from: moment().startOf('month').format('YYYY-MM-DD 00:00:01'), date_to: moment().format('YYYY-MM-DD 23:59:59'), filter_type: 'THIS_MONTH',
        });
    }, []);

    useEffect(() => {
        if (conditions) {
            if ((conditions.date_from && conditions.date_to) || (!conditions.date_from && !conditions.date_to)) {
                filterAction(conditions);
            }
        }
    }, [conditions]);

    const actionFilterByQuarter = () => {
        let toDay = new Date();
        let currentMonth = toDay.getMonth() + 1;
        let currentYear = toDay.getFullYear();

        const timezone = moment.tz.guess();

        let startDate, endDate;

        let quarter = null;

        if (currentMonth >= 1 && currentMonth <= 3) {
            quarter = 1;
        } else if (currentMonth >= 4 && currentMonth <= 6) {
            quarter = 2;
        } else if (currentMonth >= 7 && currentMonth <= 9) {
            quarter = 3;
        } else {
            quarter = 4;
        }

        switch (quarter) {
            case 1:
                startDate = new Date(currentYear, 0, 1); // Quý 1: Tháng 1
                endDate = new Date(currentYear, 2, 31); // Quý 1: Tháng 3
                break;
            case 2:
                startDate = new Date(currentYear, 3, 1); // Quý 2: Tháng 4
                endDate = new Date(currentYear, 5, 30); // Quý 2: Tháng 6
                break;
            case 3:
                startDate = new Date(currentYear, 6, 1); // Quý 3: Tháng 7
                endDate = new Date(currentYear, 8, 30); // Quý 3: Tháng 9
                break;
            case 4:
                startDate = new Date(currentYear, 9, 1); // Quý 4: Tháng 10
                endDate = new Date(currentYear, 11, 31); // Quý 4: Tháng 12
                break;
            default:
                console.error('Invalid quarter:', quarter);
        }


        setFromDate(moment.tz(startDate, timezone));
        setToDate(moment.tz(endDate, timezone));
        setConditions({
            ...conditions, date_from: moment.tz(startDate, timezone).format('YYYY-MM-DD 00:00:01'), date_to: moment.tz(endDate, timezone).format('YYYY-MM-DD 23:59:59'), filter_type: 'THIS_QUARTER',
        });

    }

    return conditions ? <>
        <div className="ladiui mt-22 flex gap-12 filter-calendar-dashboard-publisher">
            <LadiDateRangePicker
                startDateId="fromDate"
                endDateId="toDate"
                startDate={fromDate}
                endDate={toDate}
                onDatesChange={({startDate, endDate}) => {
                    const startDateNew = BaseHelper.getFromDate(startDate);
                    const endDateNew = BaseHelper.getToDate(endDate);
                    setFromDate(startDateNew);
                    setToDate(endDateNew);
                    setConditions({
                        ...conditions,
                        date_from: startDateNew ? startDateNew.format('YYYY-MM-DD') : null,
                        date_to: endDateNew ? endDateNew.format('YYYY-MM-DD') : null,
                        filter_type: 'CUSTOM',
                    });
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => {
                    setFocusedInput(focusedInput);
                }}
                showDefaultInputIcon={true}
                inputIconPosition="after"
                small={true}
                hideKeyboardShortcutsPanel={true}
                customInputIcon={
                    <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg"/>
                }
                startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                isOutsideRange={() => false}
                isDayHighlighted={(date) => moment().isSame(date, 'day')}
                minimumNights={0}
                showClearDates={true}
                textDefault={t('COMMON.SELECT_TIME')}
            />
            <button
                className={`ladiui btn btn-calendar today ${conditions.filter_type === 'TODAY' ? '' : 'btn-outline-light'}`}
                onClick={() => actionFilter('TODAY')}
            >Hôm nay
            </button>
            <button
                className={`ladiui btn btn-calendar yesterday ${conditions.filter_type === 'YESTERDAY' ? '' : 'btn-outline-light'}`}
                onClick={() => actionFilter('YESTERDAY')}
            >Hôm qua
            </button>
            <button
                className={`ladiui btn btn-calendar sevendaysago ${conditions.filter_type === '7DAYSAGO' ? '' : 'btn-outline-light'}`}
                onClick={() => actionFilter('7DAYSAGO')}
            >7 ngày trước
            </button>
            <button
                className={`ladiui btn btn-calendar thirtydaysago ${conditions.filter_type === '30DAYSAGO' ? '' : 'btn-outline-light'}`}
                onClick={() => actionFilter('30DAYSAGO')}
            >30 ngày trước
            </button>
            <button
                className={`ladiui btn btn-calendar thirtydaysago ${conditions.filter_type === 'THIS_QUARTER' ? '' : 'btn-outline-light'}`}
                onClick={() => actionFilterByQuarter()}
            >Quý này
            </button>
            <button
                className={`ladiui btn btn-calendar thismonth ${conditions.filter_type === 'THIS_MONTH' ? '' : 'btn-outline-light'}`}
                onClick={() => actionFilter('THIS_MONTH')}
            >Tháng này
            </button>
            <button
                className={`ladiui btn btn-calendar lastmonth ${conditions.filter_type === 'LAST_MONTH' ? '' : 'btn-outline-light'}`}
                onClick={() => actionFilter('LAST_MONTH')}
            >Tháng trước
            </button>
        </div>
    </> : <></>;
}

export default (withTranslation()(FilterDashboardPublisher));
