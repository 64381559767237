import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data, cb) => ({
    type: types.LIST_AFFILIATE,
    meta: {
        endpoint: endpoint.AFFILIATE_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const listFilter = (data, cb) => ({
    type: types.AFFILIATE_LIST_FILTER,
    meta: {
        endpoint: endpoint.AFFILIATE_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const create = (data, cb) => ({
    type: types.CREATE_AFFILIATE,
    meta: {
        endpoint: endpoint.AFFILIATE_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        showNotify: true,
        hasAuth: true,
        cb,
    },
});

const show = (id, cb) => ({
    type: types.SHOW_AFFILIATE,
    meta: {
        endpoint: endpoint.AFFILIATE_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const update = (data, cb) => ({
    type: types.UPDATE_AFFILIATE,
    meta: {
        endpoint: endpoint.AFFILIATE_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteAffiliate = (data, cb) => ({
    type: types.DELETE_AFFILIATE,
    meta: {
        endpoint: endpoint.AFFILIATE_DELETE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const exportAffiliate = (data, cb) => ({
    type: types.EXPORT_AFFILIATE,
    meta: {
        endpoint: endpoint.AFFILIATE_EXPORT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteMultiAffiliate = (data, cb) => ({
    type: types.DELETE_MULTI_AFFILIATE,
    meta: {
        endpoint: endpoint.AFFILIATE_DELETE_MULTI,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const getPassedAffiliate = (data, cb) => ({
    type: types.AFFILIATE_PASSED_INVOICE,
    meta: {
        endpoint: endpoint.AFFILIATE_PASSED_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: false,
        cb,
    },
});

const updateStatusMultiAffiliate = (data, cb) => ({
    type: types.UPDATE_STATUS_MULTI_AFFILIATE,
    meta: {
        endpoint: endpoint.AFFILIATE_UPDATE_STATUS_MULTI,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const updateOther = (data, cb) => ({
    type: types.UPDATE_OTHER_AFFILIATE,
    meta: {
        endpoint: endpoint.UPDATE_OTHER_AFFILIATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const updateMultiAffiliate = (data, cb) => ({
    type: types.UPDATE_MULTI_AFFILIATE,
    meta: {
        endpoint: endpoint.UPDATE_MULTI_AFFILIATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const getLevelAffiliate = (data, showNotify = true, cb) => ({
    type: types.GET_LEVEL_AFFILIATE,
    meta: {
        endpoint: endpoint.GET_LEVEL_AFFILIATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify,
        cb,
    },
});

const importData = (data, cb) => ({
    type: types.IMPORT_AFFILIATE,
    meta: {
        endpoint: endpoint.IMPORT_AFFILIATE,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
        checkPublisher: false,
        form: data,
        showNotify: true,
        cb,
    },
});

const createInvoiceMultiAffiliate = (data, cb) => ({
    type: types.CREATE_MULTI_AFFILIATE_INVOICE_MONTH,
    meta: {
        endpoint: endpoint.CREATE_MULTI_AFFILIATE_INVOICE_MONTH,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const listRef = (data, cb) => ({
    type: types.LIST_AFFILIATE_REF,
    meta: {
        endpoint: endpoint.LIST_AFFILIATE_REF,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const updateUserData = (data, cb) => ({
    type: types.UPDATE_USER_DATA,
    meta: {
        endpoint: endpoint.UPDATE_USER_DATA,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

export default {
    list,
    listFilter,
    create,
    show,
    update,
    delete: deleteAffiliate,
    exportAffiliate,
    deleteMultiAffiliate,
    updateStatusMultiAffiliate,
    updateOther,
    updateMultiAffiliate,
    getLevelAffiliate,
    importData,
    getPassedAffiliate,
    createInvoiceMultiAffiliate,
    listRef,
    updateUserData
};
