import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data, cb) => ({
    type: types.LIST_INVOICE,
    meta: {
        endpoint: endpoint.LIST_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const create = (data, cb) => ({
    type: types.CREATE_INVOICE,
    meta: {
        endpoint: endpoint.CREATE_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const show = (id, cb) => ({
    type: types.SHOW_INVOICE,
    meta: {
        endpoint: endpoint.SHOW_INVOICE,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const update = (data, cb) => ({
    type: types.UPDATE_INVOICE,
    meta: {
        endpoint: endpoint.UPDATE_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const deleteInvoice = (data, cb) => ({
    type: types.DELETE_INVOICE,
    meta: {
        endpoint: endpoint.DELETE_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const exportInvoice = (data, cb) => ({
    type: types.EXPORT_INVOICE,
    meta: {
        endpoint: endpoint.EXPORT_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const exportCommissionInvoice = (data, cb) => ({
    type: types.EXPORT_COMMISSION_INVOICE,
    meta: {
        endpoint: endpoint.EXPORT_COMMISSION_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteMultiInvoice = (data, cb) => ({
    type: types.DELETE_MULTI_INVOICE,
    meta: {
        endpoint: endpoint.DELETE_MULTI_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const acceptMultiInvoice = (data, cb) => ({
    type: types.ACCEPT_MULTI_INVOICE,
    meta: {
        endpoint: endpoint.INVOICE_UPDATE_STATUS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const rejectMultiInvoice = (data, cb) => ({
    type: types.REJECT_MULTI_INVOICE,
    meta: {
        endpoint: endpoint.INVOICE_UPDATE_STATUS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const paidMultiInvoice = (data, cb) => ({
    type: types.PAID_MULTI_INVOICE,
    meta: {
        endpoint: endpoint.INVOICE_UPDATE_STATUS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const updateStatus = (data, cb) => ({
    type: types.UPDATE_STATUS_INVOICE,
    meta: {
        endpoint: endpoint.UPDATE_STATUS_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const exportBankInfoAffiliate = (data, cb) => ({
    type: types.EXPORT_BANK_INFO_AFFILIATE,
    meta: {
        endpoint: endpoint.EXPORT_BANK_INFO_AFFILIATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const sendMailConfirmInvoice = (data, cb) => ({
    type: types.SEND_MAIL_CONFIRM_INVOICE,
    meta: {
        endpoint: endpoint.SEND_MAIL_CONFIRM_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const createRequestWithdraw = (data, cb) => ({
    type: types.CREATE_REQUEST_WITHDRAW,
    meta: {
        endpoint: endpoint.CREATE_REQUEST_WITHDRAW,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const updateNote = (data, cb) => ({
    type: types.UPDATE_NOTE_INVOICE,
    meta: {
        endpoint: endpoint.UPDATE_NOTE_INVOICE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

export default {
    list,
    create,
    show,
    update,
    delete: deleteInvoice,
    exportInvoice,
    exportCommissionInvoice,
    deleteMultiInvoice,
    acceptMultiInvoice,
    rejectMultiInvoice,
    paidMultiInvoice,
    updateStatus,
    exportBankInfoAffiliate,
    sendMailConfirmInvoice,
    createRequestWithdraw,
    updateNote,
};
