import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import actionStore from '../../../redux/futures/store/actions';

// eslint-disable-next-line max-lines-per-function
function ModalPurchase(props) {
    const {
        t, affiliateId, cb,
    } = props;

    const moment = BaseHelper.getMoment();

    const dispatch = useDispatch();
    const [isCheckCondition, setIsCheckCondition] = useState(false);
    const [purchaseNote, setPurchaseNote] = useState();

    const [conditions, setConditions] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [startFocused, setStartFocused] = useState();
    const [endFocused, setEndFocused] = useState();

    const affiliatePassed = useSelector((state) => (state.affiliates.passedAffiliate ? state.affiliates.passedAffiliate.passedAffiliates : []));
    const setting = useSelector((state) => (state.store.affiliateSetting));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const actionFilter = (type) => {
        let startDateNew = null;
        let endDateNew = null;
        switch (type) {
            case 'TODAY':
                startDateNew = moment();
                endDateNew = moment();
                break;
            case 'YESTERDAY':
                startDateNew = moment().subtract(1, 'day');
                endDateNew = moment().subtract(1, 'day');
                break;
            case '15DAYSAGO':
                startDateNew = moment().subtract(15, 'day');
                endDateNew = moment();
                break;
            case '30DAYSAGO':
                startDateNew = moment().subtract(30, 'day');
                endDateNew = moment();
                break;
            case '90DAYSAGO':
                startDateNew = moment().subtract(90, 'day');
                endDateNew = moment();
                break;
            case 'THIS_MONTH':
                startDateNew = moment().startOf('month');
                endDateNew = moment();
                break;
            case 'LAST_MONTH':
                startDateNew = moment().subtract(1, 'month').startOf('month');
                endDateNew = moment().subtract(1, 'month').endOf('month');
                break;
            default:
        }

        if (!conditions || (conditions && type !== conditions.filter_type)) {
            setFromDate(startDateNew);
            setToDate(endDateNew);
            setConditions({
                ...conditions, date_from: startDateNew.format('YYYY-MM-DD 00:00:01'), date_to: endDateNew.format('YYYY-MM-DD 23:59:59'), filter_type: type,
            });
        }
    };

    const checkConditionInvoice = () => {
        const data = { ...conditions, ids: [affiliateId] };
        dispatch(actionAffiliate.getPassedAffiliate(data, () => setIsCheckCondition(true)));
    };

    const cbCreate = () => {
        window.LadiUI.closeModal('modal-purchase');
        setIsCheckCondition(false);
        setPurchaseNote(null);
        setConditions(null);
        setFromDate(null);
        setToDate(null);
        cb();
    };

    const eventCancel = () => {
        window.LadiUI.closeModal('modal-purchase');
        setIsCheckCondition(false);
        setPurchaseNote(null);
        setConditions(null);
        setFromDate(null);
        setToDate(null);
    };
    const actionOk = () => {
        const data = {
            affiliatePassed,
            date_from: fromDate,
            date_to: toDate,
            note: purchaseNote,
        };
        dispatch(actionAffiliate.createInvoiceMultiAffiliate(data, cbCreate));
    };
    const cbLoadData = () => {
        const data = {
            field: 'affiliate_setting',
        };
        dispatch(actionStore.getAffiliateSetting(data));
    };

    const eventChooseDate = (date, start) => {
        const dateFormat = BaseHelper.getFromDate(date);
        if (start) {
            setFromDate(dateFormat);
            setConditions({
                ...conditions, date_from: date ? date.format('YYYY-MM-DD') : null, filter_type: 'CUSTOM',
            });
        } else {
            setToDate(dateFormat);
            setConditions({
                ...conditions, date_to: date ? date.format('YYYY-MM-DD') : null, filter_type: 'CUSTOM',
            });
        }
    };

    useEffect(() => {
        cbLoadData();
    }, []);

    return <>
        <div className="ladiui modal bd-example-modal-lg" id="modal-purchase">
            <div className="ladiui modal-dialog modal-dialog-centered modal-lg">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">Thanh toán tiền Affiliate</label>
                        <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close" onClick={eventCancel}>
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui form-group-custom">
                        {setting && setting.limit_invoice_status
                            ? <label className="ladiui title-text-form-lever2" htmlFor="amount">* Số tiền cho hóa đơn phải lớn hơn hoặc bằng:
                                {BaseHelper.formatMoney(setting ? setting.limit_invoice_value : 0)}</label>
                            : <></>
                        }
                        {setting && setting.limit_customer_status
                            ? <label className="ladiui title-text-form-lever2" htmlFor="amount">* Số khách hàng phải lớn hơn hoặc bằng:
                                {BaseHelper.formatNumber(setting ? setting.limit_customer_value : 0)} khách hàng</label>
                            : <></>
                        }
                    </div>

                    <div className="ladiui" style={{ fontWeight: '400' }}>
                        <div className="ladiui form-group-custom">
                            <label className="ladiui title-text-form-lever2" >Chọn khoảng thời gian để lập hoá đơn</label>
                            <div style={{ display: 'flex', gap: '12px' }}>
                                <>
                                    <div className="ladiui">
                                        {/* <label className="ladiui title-text-form-lever2">Ngày bắt đầu</label> */}
                                        <div className="group-input-custom custom-form-dynamic time-expired-plan-payment">
                                            <SingleDatePicker
                                                date={fromDate} // momentPropTypes.momentObj or null
                                                onDateChange={(date) => eventChooseDate(date, true)} // PropTypes.func.isRequired
                                                focused={startFocused} // PropTypes.bool
                                                onFocusChange={({ focused }) => setStartFocused(focused)} // PropTypes.func.isRequired
                                                numberOfMonths={1}
                                                showClearDate={true}
                                                noBorder={false}
                                                placeholder="Ngày bắt đầu"
                                                readOnly={true}
                                                // disabled={paymentTime.type !== DefineConst.PAYMENT_TERM_TYPE.CUSTOM}
                                                isOutsideRange={(day) => false}
                                                showDefaultInputIcon={true}
                                                inputIconPosition="after"
                                                small={true}
                                                customInputIcon={
                                                    <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="ladiui">
                                        {/* <label className="ladiui title-text-form-lever2">Ngày kết thúc</label> */}
                                        <div className="group-input-custom custom-form-dynamic time-expired-plan-payment">
                                            <SingleDatePicker
                                                date={toDate} // momentPropTypes.momentObj or null
                                                onDateChange={(date) => eventChooseDate(date, false)} // PropTypes.func.isRequired
                                                focused={endFocused} // PropTypes.bool
                                                onFocusChange={({ focused }) => setEndFocused(focused)} // PropTypes.func.isRequired
                                                numberOfMonths={1}
                                                showClearDate={true}
                                                noBorder={false}
                                                placeholder="Ngày kết thúc"
                                                readOnly={true}
                                                // disabled={paymentTime.type !== DefineConst.PAYMENT_TERM_TYPE.CUSTOM}
                                                isOutsideRange={(day) => false}
                                                showDefaultInputIcon={true}
                                                inputIconPosition="after"
                                                small={true}
                                                customInputIcon={
                                                    <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                                <button className={`ladiui btn btn-calendar sevendaysago ${conditions && conditions.filter_type === '15DAYSAGO' ? '' : 'btn-outline-light'}`}
                                    onClick={() => actionFilter('15DAYSAGO')}
                                >15 ngày</button>
                                <button className={`ladiui btn btn-calendar thirtydaysago ${conditions && conditions.filter_type === '30DAYSAGO' ? '' : 'btn-outline-light'}`}
                                    onClick={() => actionFilter('30DAYSAGO')}
                                >30 ngày</button>
                                <button className={`ladiui btn btn-calendar thismonth ${conditions && conditions.filter_type === 'THIS_MONTH' ? '' : 'btn-outline-light'}`}
                                    onClick={() => actionFilter('THIS_MONTH')}
                                >Tháng này</button>
                                <button className={`ladiui btn btn-calendar lastmonth ${conditions && conditions.filter_type === 'LAST_MONTH' ? '' : 'btn-outline-light'}`}
                                    onClick={() => actionFilter('LAST_MONTH')}
                                >Tháng trước</button>
                            </div>
                            <div className="ladiui form-group-custom">
                                <button className="ladiui btn btn-primary" onClick={checkConditionInvoice}>Kiểm tra</button>
                            </div>
                        </div>
                    </div>
                    {isCheckCondition
                        ? <>{affiliatePassed && affiliatePassed.length > 0
                            ? <>
                                <div style={{ marginTop: '25px' }}>
                                    Số lượng hoa hồng đủ điều kiện tạo hoá đơn: {affiliatePassed[0].commissions.length} hoa hồng
                                </div>
                                {affiliatePassed[0].commissions.length > 0
                                    ? <div style={{ maxHeight: '500px', overflowY: 'scroll', marginTop: '25px' }}>
                                        <table className="ladiui table text-left" style={{ marginTop: 0 }}>
                                            <thead>
                                                <tr className="ladiui table-vertical header">
                                                    <th>#</th>
                                                    <th>Mã tham chiếu</th>
                                                    <th>Loại</th>
                                                    <th>Ngày duyệt</th>
                                                    <th className="ladiui text-right">Tổng tiền</th>
                                                    <th className="ladiui text-right">Hoa hồng</th>
                                                    <th className="ladiui text-right">Thưởng</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {affiliatePassed[0].commissions.map((item, index) => <tr className="ladiui table-vertical main" key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.data.code || item._id}</td>
                                                    <td>{appConfig.LADISHARE.COMMISSION_TYPE.map((type) => (type.value === item.type ? type.name : ''))}</td>
                                                    <td>{BaseHelper.formatStrToDate(item.commission_date)}</td>
                                                    <td className="ladiui text-right">{BaseHelper.formatMoney(item.total, '', systemCurrency)}</td>
                                                    <td className="ladiui text-right">{BaseHelper.formatMoney(item.amount, '', systemCurrency)}</td>
                                                    <td className="ladiui text-right">{BaseHelper.formatMoney(item.bonus, '', systemCurrency)}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                    : <></>}
                                <div className="ladiui form-group-custom">
                                    <label className="ladiui title-text-form-lever2">Ghi chú cho hoá đơn</label>
                                    <textarea className="ladiui form-control" placeholder="Nhập nội dung" rows={3}
                                        value={purchaseNote || ''}
                                        onChange={(e) => setPurchaseNote(e.target.value)}
                                    />
                                </div>

                                <div className="ladiui modal-footer custom-ldp">
                                    <div></div>
                                    <div className="ladiui footer-modal-btn">
                                        <button type="button" data-dismiss="modal" data-target="white-page"
                                            className="ladiui btn btn-secondary bold" onClick={eventCancel}>Hủy bỏ</button>
                                        <button type="button" className="ladiui btn btn-primary" onClick={actionOk}>Tạo hóa đơn thanh toán</button>
                                    </div>

                                </div>
                            </>
                            : <div style={{ marginTop: '12px' }}>Chưa đạt điều kiện tạo hoá đơn</div>}
                        </>
                        : <></>}
                </div>
            </div>
        </div >
    </>;
}

export default (withTranslation()(ModalPurchase));
