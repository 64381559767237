export default {
    AFFILIATE_STATUS: {
        PENDING: 'PENDING',
        ACTIVED: 'ACTIVED',
        LOCKED: 'LOCKED',
    },
    AFFILIATE_TYPE: {
        PERSONAL: 'PERSONAL',
        COMPANY: 'COMPANY',
    },
    EXPORT_ACTIONS: {
        FIRST_PAGE: 'FIRST_PAGE',
        SELECTED_RECORDS: 'SELECTED_RECORDS',
        ALL_RECORDS: 'ALL_RECORDS',
    },
    BANKS: {
        VCB: {
            napasCode: '970436',
            disburseCode: '970436',
            name: 'VietcomBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/VCB.png',
        },
        CTG: {
            napasCode: '970415',
            disburseCode: '970415',
            name: 'VietinBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/CTG.png',
        },
        TCB: {
            napasCode: '970407',
            disburseCode: '970407',
            name: 'Techcombank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/TCB.png',
        },
        BIDV: {
            napasCode: '970418',
            disburseCode: '970418',
            name: 'BIDV',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/BIDV.png',
        },
        VARB: {
            napasCode: '970405',
            disburseCode: '970405',
            name: 'AgriBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/VARB.png',
        },
        NVB: {
            napasCode: '970419',
            disburseCode: '970419',
            name: 'Navibank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/NVB.png',
        },
        STB: {
            napasCode: '970403',
            disburseCode: '970403',
            name: 'Sacombank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/STB.png',
        },
        ACB: {
            napasCode: '970416',
            disburseCode: '970416',
            name: 'ACB',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/ACB.png',
        },
        MB: {
            napasCode: '970422',
            disburseCode: '970422',
            name: 'MBBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/MB.png',
        },
        TPB: {
            napasCode: '970423',
            disburseCode: '970423',
            name: 'TPBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/TPB.png',
        },
        SVB: {
            napasCode: '970424',
            disburseCode: '970424',
            name: 'Shinhan Bank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/SVB.png',
        },
        VIB: {
            napasCode: '970441',
            disburseCode: '970441',
            name: 'VIB Bank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/VIB.png',
        },
        VPB: {
            napasCode: '970432',
            disburseCode: '970432',
            name: 'VPBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/VPB.png',
        },
        SHB: {
            napasCode: '970443',
            disburseCode: '970443',
            name: 'SHB',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/SHB.png',
        },
        EIB: {
            napasCode: '970431',
            disburseCode: '970431',
            name: 'Eximbank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/EIB.png',
        },
        BVB: {
            napasCode: '970438',
            disburseCode: '970438',
            name: 'BaoVietBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/BVB.png',
        },
        VCCB: {
            napasCode: '970454',
            disburseCode: '970454',
            name: 'VietcapitalBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/VCCB.png',
        },
        SCB: {
            napasCode: '970429',
            disburseCode: '970429',
            name: 'SCB',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/SCB.png',
        },
        VRB: {
            napasCode: '970421',
            disburseCode: '970421',
            name: 'VietNam - Russia Bank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/VPB.png',
        },
        ABB: {
            napasCode: '970425',
            disburseCode: '970425',
            name: 'ABBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/ABB.png',
        },
        PVCB: {
            napasCode: '970412',
            disburseCode: '970412',
            name: 'PVCombank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/PVCB.png',
        },
        OJB: {
            napasCode: '970414',
            disburseCode: '970414',
            name: 'OceanBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/OJB.png',
        },
        NAB: {
            napasCode: '970428',
            disburseCode: '970428',
            name: 'NamA bank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/NAB.png',
        },
        HDB: {
            napasCode: '970437,970420',
            disburseCode: '970437',
            name: 'HDBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/HDB.png',
        },
        VB: {
            napasCode: '970433',
            disburseCode: '970433',
            name: 'VietBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/VB.png',
        },
        CFC: {
            napasCode: '970460',
            disburseCode: '',
            name: 'VietCredit',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/CFC.png',
        },
        PBVN: {
            napasCode: '970439',
            disburseCode: '970439',
            name: 'Public bank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/PBVN.png',
        },
        HLB: {
            napasCode: '',
            disburseCode: '970442',
            name: 'Hongleong Bank',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_hong_leon_bank.png',
        },
        PGB: {
            napasCode: '970430',
            disburseCode: '970430',
            name: 'PG Bank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/PGB.png',
        },
        COB: {
            napasCode: '',
            disburseCode: '970446',
            name: 'Co.op Bank',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_coop_bank.png',
        },
        CIMB: {
            napasCode: '',
            disburseCode: '422589',
            name: 'CIMB',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_cimb.png',
        },
        IVB: {
            napasCode: '970434',
            disburseCode: '970434',
            name: 'Indovina ',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/IVB.png',
        },
        DAB: {
            napasCode: '',
            disburseCode: '970406',
            name: 'DongABank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/DAB.png',
        },
        GPB: {
            napasCode: '970408',
            disburseCode: '970408',
            name: 'GPBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/GPB.png',
        },
        NASB: {
            napasCode: '970409',
            disburseCode: '970409',
            name: 'BacABank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/NASB.png',
        },
        VAB: {
            napasCode: '970427',
            disburseCode: '970427',
            name: 'VietABank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/VAB.png',
        },
        SGB: {
            napasCode: '970400',
            disburseCode: '970400',
            name: 'SaigonBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/SGB.png',
        },
        MSB: {
            napasCode: '970426',
            disburseCode: '970426',
            name: 'Maritime Bank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/MSB.png',
        },
        LPB: {
            napasCode: '970449',
            disburseCode: '970449',
            name: 'LienVietPostBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/LPB.png',
        },
        KLB: {
            napasCode: '970452',
            disburseCode: '970452',
            name: 'KienLongBank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/KLB.png',
        },
        IBKHN: {
            napasCode: '',
            disburseCode: '970455',
            name: 'IBK - Ha Noi',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_ibk_bank.png',
        },
        WOO: {
            napasCode: '970457',
            disburseCode: '970457',
            name: 'Woori bank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/WOO.png',
        },
        SEAB: {
            napasCode: '970440',
            disburseCode: '970440',
            name: 'SeABank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/Seab.png',
        },
        UOB: {
            napasCode: '970458',
            disburseCode: '970458',
            name: 'Overseas Bank',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/UOB.png',
        },
        OCB: {
            napasCode: '970448',
            disburseCode: '970448',
            name: 'OCB',
            bankLogoUrl: 'https://img.mservice.com.vn/momo_app_v2/img/OCB.png',
        },
        MAFC: {
            napasCode: '970468',
            disburseCode: '',
            name: 'Mirae Asset',
            bankLogoUrl: '',
        },
        KEBHANAHCM: {
            napasCode: '970466',
            disburseCode: '',
            name: 'Keb Hana - Ho Chi Minh',
            bankLogoUrl: '',
        },
        KEBHANAHN: {
            napasCode: '970467',
            disburseCode: '',
            name: 'Keb Hana - Ha Noi',
            bankLogoUrl: '',
        },
        STANDARD: {
            napasCode: '',
            disburseCode: '970410',
            name: 'Standard Chartered',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_standard_chartered.png',
        },
        CAKE: {
            napasCode: '',
            disburseCode: '546034',
            name: 'CAKE',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_cake.png',
        },
        Ubank: {
            napasCode: '',
            disburseCode: '546035',
            name: 'Ubank',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_ubank.png',
        },
        NonghyupBankHN: {
            napasCode: '',
            disburseCode: '801011',
            name: 'Nonghyup Bank - HN',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_nonghyu.png',
        },
        KBHN: {
            napasCode: '',
            disburseCode: '970462',
            name: 'Kookmin - HN',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_kookmin_hn.png',
        },
        KBHCM: {
            napasCode: '',
            disburseCode: '970463',
            name: 'Kookmin - HCM',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_kookmin_hcm.png',
        },
        DBSHCM: {
            napasCode: '',
            disburseCode: '796500',
            name: 'DBS - HCM',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_dbs.png',
        },
        CBBank: {
            napasCode: '',
            disburseCode: '970444',
            name: 'CBBank',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_cbbank.png',
        },
        KBankHCM: {
            napasCode: '',
            disburseCode: '668888',
            name: 'KBank - HCM',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_kbank.png',
        },
        HSBC: {
            napasCode: '',
            disburseCode: '458761',
            name: 'HSBC',
            bankLogoUrl: 'https://img.mservice.io/momo_app_v2/new_version/All_team_/new_logo_bank/ic_hsbc.png',
        },
    },
};
