import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import styles from '../../../assets/css/custom.module.css';
import DefineConst from '../DefineConst';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
// import actionCampaign from '../../../redux/futures/campaign/actions';
import config from '../../../config/config';
import BaseHelper from '../../../helpers/BaseHelper';
import LadiPagination from '../../../components/LadiPagination';
import actionLink from '../../../redux/futures/link/actions';
import ModalPublisherLink from './ModalPublisherLink';

// eslint-disable-next-line max-lines-per-function
function ModalShareCampaign(props) {
    const { t } = props;
    const id = BaseHelper.generateRandomString(10);
    const dispatch = useDispatch();
    // const [shortLink, setShortLink] = useState('');
    const defaultCondtions = {
        limit: 10,
        page: 1,
        sort: { created_at: -1 },
    };

    const [conditions, setConditions] = useState(defaultCondtions);
    const campaign = useSelector((state) => (state.campaigns.campaign));
    const affiliate = useSelector((state) => (state.affiliates.affiliate));
    const userInfo = useSelector((state) => state.store.userInfo);
    // const shortLinkData = useSelector((state) => state.campaigns.shortLink);
    const listLink = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.links.linkPublisher }));

    const copyClipboard = (id) => {
        /* Get the text field */
        const copyText = document.getElementById(id);
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        window.LadiUI.toast('success', '', t('COMMON.COPIED'), 5000, 'bottom-left');
    };

    const getUrl = (link, affifiliateCode) => {
        if (!link) return;
        let textUtm = 'ref';

        let locationUrl = window.location.href;

        if (locationUrl && locationUrl.includes('affiliate.ladiflow.vn')) {
            textUtm = 'ladiflow_ref';
        }
        try {
            const url = new URL(link);
            url.searchParams.append(textUtm, affifiliateCode);
            const urlRender = url.toString();
            return urlRender;
        } catch (err) {
            return link;
        }
    };

    const downloadImage = (url, fileName) => {
        saveAs(url, fileName);
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const showModalCreateLink = () => {
        window.LadiUI.showModal('modal-publisher-link');
    };

    useEffect(() => {
        dispatch(actionAffiliate.show(userInfo.user._id));
    }, []);

    // useEffect(() => {
    //     if (shortLinkData) setShortLink(shortLinkData.short_link);
    // }, [shortLinkData]);

    useEffect(() => {
        // if (campaign) setShortLink(campaign.short_link);
        if (campaign) {
            dispatch(actionLink.list({ ...conditions, campaign_id: campaign._id }));
        }
    }, [campaign]);
    return campaign ? <>
        <div className="ladiui modal bd-example-modal-lg" id="modal-campaign-share">
            <div className="ladiui modal-dialog modal-lg">
                <div className="ladiui modal-content">
                    <div className="ladiui modal-header">
                        <h2 className="ladiui title-text-form-lever1">Chia sẻ</h2>
                        <button data-dismiss="modal" data-target="modal-campaign-banner"
                            className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui modal-body">
                        {![DefineConst.PLATFORM_CAMPAIGN.MAIL].includes(campaign.type)
                            ? <div className="ladiui form-group">
                                <label className="ladiui title-text-form-lever2" htmlFor={`input-base-link-${id}`}>Link gốc</label>
                                <div style={{ position: 'relative' }}>
                                    <input className="ladiui form-control input-base-link" placeholder="Nhập tiêu đề" id={`input-base-link-${id}`}
                                        value={getUrl(campaign.url, affiliate.code)}
                                        onChange={() => { }}
                                        readOnly
                                    />
                                    <a href="#/" className="get-short-link" onClick={() => copyClipboard(`input-base-link-${id}`)}>
                                        <i className="ladiui icon icon-ldp-replication"></i>
                                    </a>
                                </div>
                            </div>
                            : <></>}
                        {[DefineConst.PLATFORM_CAMPAIGN.BANNER].includes(campaign.type)
                            ? <div className="ladiui form-group">
                                <label className="ladiui title-text-form-lever2">Ảnh bài viết</label>
                                <div className="list-share-banner-img">
                                    {campaign.image
                                        ? campaign.image.map((image, index) => <div className="list-share-banner-img-item" key={index}>
                                            <div style={{ position: 'relative' }} className="list-share-banner-img-item-image">
                                                <img src={`${config.DOMAIN_CDN}/${image.path}`} className="list-share-banner-img-item-img" />
                                                <div className="list-share-banner-img-item-download">
                                                    <button onClick={() => downloadImage(`${config.DOMAIN_CDN}/${image.path}`, image.name)} className="ladiui btn btn-primary" rel="noreferrer">Tải ảnh</button>
                                                </div>
                                            </div>
                                            <div className="list-share-banner-img-item-embeded">
                                                <div className={`${styles['d-block']}`}>
                                                    <span className="left">Mã nhúng</span>
                                                </div>
                                                <div className="clearfix" style={{ position: 'relative' }}>
                                                    <a href="#/" className="get-short-link" onClick={() => copyClipboard(`embed-code-${index}`)}>
                                                        <i className="ladiui icon icon-ldp-replication"></i>
                                                    </a>
                                                    <textarea readOnly id={`embed-code-${index}`} rows={3} className={`content-left-detail ${styles['embed-code']}`}
                                                        onChange={() => { }}
                                                        value={`<iframe width="${image.width || 250}" height="${image.height || 250}" src="${config.AFFILIATE_DOMAIN}/campaign-share?url=${config.DOMAIN_CDN}/${image.path}&cpid=${campaign._id}&afc=${affiliate.code}" title="${campaign.title}" frameborder="0"></iframe>`} />
                                                </div>
                                            </div>
                                        </div>) : <></>}
                                </div>
                            </div>
                            : <>
                                {campaign.image
                                    ? <div className="ladiui form-group">
                                        <label className="ladiui title-text-form-lever2">Ảnh bài viết</label>
                                        <div className="list-share-banner-img" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '12px' }}>
                                            {campaign.image.map((image, index) => <div className="list-share-banner-img-item" key={index} style={{ display: 'unset' }}>
                                                <div style={{ position: 'relative' }} className="list-share-banner-img-item-image">
                                                    <img src={`${config.DOMAIN_CDN}/${image.path}`} className="list-share-banner-img-item-img" style={{ width: '100%' }} />
                                                    <div className="list-share-banner-img-item-download">
                                                        <button onClick={() => downloadImage(`${config.DOMAIN_CDN}/${image.path}`, image.name)} className="ladiui btn btn-primary" rel="noreferrer">Tải ảnh</button>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                    : <></>}
                            </>}

                        {campaign.contents
                            ? <>
                                <div className="ladiui form-group">
                                    <label className="ladiui title-text-form-lever2">
                                        <span className="left">Nội dung</span>
                                        <span className={`right ${styles['copy-link']}`} onClick={() => copyClipboard(`contents-campaign-${campaign._id}`)}>Sao chép nội dung</span>
                                    </label>
                                    <div className="clearfix"></div>
                                    <div className={styles['mt-8']}>
                                        {campaign.type === DefineConst.TYPE_CAMPAIGN.MAIL
                                            ? <>
                                                <div dangerouslySetInnerHTML={{
                                                    __html: campaign.contents,
                                                }}></div>
                                                <textarea id={`contents-campaign-${campaign._id}`} className="ladiui form-control" value={campaign.contents} readOnly rows={10} style={{ display: 'none' }} />
                                            </>
                                            : <>
                                                <textarea id={`contents-campaign-${campaign._id}`} className="ladiui form-control" value={campaign.contents} readOnly rows={10} />
                                            </>}
                                    </div>
                                </div>
                            </>
                            : <></>}

                        {campaign.type !== DefineConst.TYPE_CAMPAIGN.MAIL
                            ? <div className="ladiui form-group">
                                <label className="ladiui title-text-form-lever2">
                                    <span className="left">Link rút gọn chiến dịch</span>
                                    <button className={`right ${styles['copy-link']} ladiui btn btn-primary`} onClick={() => showModalCreateLink()}>Tạo link</button>
                                </label>
                                <div className="clearfix"></div>
                                <div className={styles['mt-8']}>
                                    <div className="ladiui table-scroll">
                                        <table className="ladiui table text-left" style={{ minWidth: '650px' }}>
                                            <thead>
                                                <tr className="ladiui table-vertical header">
                                                    <th scope="col">Link gốc</th>
                                                    <th scope="col" className="ladiui text-pre">Link rút gọn</th>
                                                    <th scope="col" className="ladiui text-pre">UTM source</th>
                                                    <th scope="col" className="ladiui text-pre">UTM medium</th>
                                                    <th scope="col" className="ladiui text-pre">UTM campaign</th>
                                                    <th scope="col" className="ladiui text-pre">UTM content</th>
                                                    <th scope="col" className="ladiui text-pre">Lượt click</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listLink.items.length > 0 ? listLink.items.map((item, key) => (<tr key={item._id} className="ladiui table-vertical main">
                                                    <td>
                                                        <div style={{ position: 'relative' }}>
                                                            <input className="ladiui form-control input-copy-link" style={{ minWidth: '150px' }}
                                                                id={`base_url_link_${item._id}`}
                                                                defaultValue={`${item.base_url}`}
                                                                readOnly />
                                                            <a href="#/" className="button-copy-link" onClick={() => copyClipboard(`base_url_link_${item._id}`)}>
                                                                <i className="ladiui icon icon-ldp-replication"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style={{ position: 'relative' }}>
                                                            <input className="ladiui form-control input-copy-link" style={{ minWidth: '150px' }}
                                                                id={`short_link_${item._id}`}
                                                                defaultValue={`${item.short_link}`}
                                                                readOnly />
                                                            <a href="#/" className="button-copy-link" onClick={() => copyClipboard(`short_link_${item._id}`)}>
                                                                <i className="ladiui icon icon-ldp-replication"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {item.utm_source}
                                                    </td>
                                                    <td>
                                                        {item.utm_medium}
                                                    </td>
                                                    <td>
                                                        {item.utm_campaign}
                                                    </td>
                                                    <td>{item.utm_content}</td>
                                                    <td className="ladiui text-center">{item.number_click || 0}</td>
                                                </tr>))
                                                    : <tr><td colSpan={8} className="ladiui text-center">{t('NO_DATA')}</td></tr>}

                                            </tbody>
                                        </table>
                                    </div>
                                    {listLink.items.length > 0
                                        ? <LadiPagination
                                            conditionsProp={conditions}
                                            listName='link'
                                            items={listLink.items}
                                            total={listLink.total}
                                            limitAction={selectLimit}
                                            pageAction={selectPage}
                                        /> : ''}
                                </div>
                            </div>
                            : <></>}
                    </div>
                </div>
            </div>
        </div>
        {campaign.type !== DefineConst.TYPE_CAMPAIGN.MAIL
            ? <ModalPublisherLink isEdit={false} cb={() => dispatch(actionLink.list({ ...conditions, campaign_id: campaign._id }))} />
            : <></>}
    </> : <><div className="ladiui modal bd-example-modal-lg" id="modal-campaign-share"></div></>;
}

export default (withTranslation()(ModalShareCampaign));
