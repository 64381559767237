import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';
import BaseHelper from '../../../../helpers/BaseHelper';
import DefineConst from '../../DefineConst';

function CommissionPlanPaymentBoxCountTotalOrder(props) {
    const { t, paymentFor, paymentCountTotalOrderProp, cb } = props;
    const [paymentCountTotalOrder, setPaymentCountTotalOrder] = useState({ enable: false });

    const renderExplain = () => {
        let explain = <></>;
        if (paymentCountTotalOrder.min && paymentCountTotalOrder.max) {
            explain = <>(*) Hoa hồng sẽ được tính nếu tổng doanh thu của affiliate đó nằm trong khoảng từ {BaseHelper.formatNumber(paymentCountTotalOrder.min)} đến {BaseHelper.formatNumber(paymentCountTotalOrder.max)}</>;
        }
        if (paymentCountTotalOrder.min && !paymentCountTotalOrder.max) {
            explain = <>(*) Hoa hồng sẽ được tính nếu tổng doanh thu của affiliate đó lớn hơn hoặc bằng {BaseHelper.formatNumber(paymentCountTotalOrder.min)}</>;
        }
        if (!paymentCountTotalOrder.min && paymentCountTotalOrder.max) {
            explain = <>(*) Hoa hồng sẽ được tính nếu tổng doanh thu của affiliate đó nhỏ hơn hoặc bằng {BaseHelper.formatNumber(paymentCountTotalOrder.max)}</>;
        }
        return explain;
    };

    const eventChangeInput = (e) => {
        const name = e.target.name;
        let value;
        if (
            e.target.tagName.toLowerCase() === 'input'
            && e.target.getAttribute('type') === 'checkbox'
        ) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        const data = { ...paymentCountTotalOrder };
        data[name] = value;
        setPaymentCountTotalOrder(data);
        cb(data);
    };

    const changeInputValue = (fieldName, value) => {
        const data = { ...paymentCountTotalOrder };
        data[fieldName] = value;
        setPaymentCountTotalOrder(data);
        cb(data);
    };

    useEffect(() => {
        setPaymentCountTotalOrder({ ...paymentCountTotalOrder, ...paymentCountTotalOrderProp });
    }, [paymentCountTotalOrderProp]);

    return <>
        <div className="ladiui clearfix pt-20">
            <label className="ladiui title-text-form-lever2 left">Trả theo tổng doanh thu</label>
            <div className="ladiui item-form switch">
                <label className="ladiui switch">
                    <input type="checkbox" name="enable"
                        checked={paymentCountTotalOrder !== null && (paymentCountTotalOrder.enable || false)}
                        onChange={(e) => eventChangeInput(e)} />
                    <span className="ladiui slider round"></span>
                </label>
            </div>
            <div className="clearfix font-size-12">Trả hoa hồng nếu tổng doanh thu của affiliate đó đạt yêu cầu</div>
        </div>
        {paymentCountTotalOrder && paymentCountTotalOrder.enable
            ? <>
                <div className="ladiui clearfix pt-20">
                    <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Giá trị tối thiểu</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                value={paymentCountTotalOrder.min || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Giá trị tối thiểu"
                                onValueChange={(values) => changeInputValue('min', values.value)}
                            />
                        </div>

                    </div>
                    <div className="ladiui left mr-8">
                        <label className="ladiui title-text-form-lever3">Giá trị tối đa</label>
                        <div className="group-input-custom custom-form-dynamic w-180">
                            <NumericFormat
                                value={paymentCountTotalOrder.max || ''}
                                allowLeadingZeros
                                allowNegative={false}
                                decimalScale={0}
                                thousandSeparator=","
                                className="ladiui form-control btn-sm w-175"
                                placeholder="Giá trị tối đa"
                                onValueChange={(values) => changeInputValue('max', values.value)}
                            />
                        </div>
                    </div>
                    {paymentFor !== DefineConst.PAYMENT_FOR.KPIS
                        ? <div className="ladiui left">
                            <label className="ladiui title-text-form-lever3" htmlFor="monthly_reset">Reset hàng tháng</label>
                            <div className="ladiui item-form switch check-switch-2">
                                <label className="ladiui switch">
                                    <input type="checkbox"
                                        name="monthly_reset"
                                        checked={paymentCountTotalOrder.monthly_reset || false}
                                        onChange={(e) => eventChangeInput(e)}
                                    />
                                    <span className="ladiui slider round"></span>
                                </label>
                            </div>
                        </div>
                        : <></>}
                </div>
                <div className="ladiui clearfix" style={{ paddingTop: '10px', fontSize: '12px', fontStyle: 'italic' }}>
                    {renderExplain()}
                </div>
            </>
            : <></>}
    </>;
}

export default (withTranslation()(CommissionPlanPaymentBoxCountTotalOrder));
