import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../../../../assets/css/custom.module.css';
import BaseHelper from '../../../../helpers/BaseHelper';
import DefineConst from '../../../commissions/DefineConst';

function HistoryCommission() {
    const report = useSelector((state) => (state.statistics.dailyReportPublisher));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const renderItem = (item) => {
        let data;
        switch (item.type) {
            case DefineConst.COMMISSION_TYPE.ORDER:
                data = <><div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiui/ladishare/shopping-bag1208.svg" /></div>
                    <div className="title">Đơn hàng</div>
                    <div className="content"><span>{BaseHelper.formatMoney(item.amount, '', systemCurrency)}</span></div>
                    <div className="time">{BaseHelper.formatStrToDate(item.created_at, 'DD/MM/YYY HH:SS')}</div></>;
                break;
            case DefineConst.COMMISSION_TYPE.LEAD:
                data = <><div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiui/ladishare/lead0808.svg" /></div>
                    <div className="title">Lead mới</div>
                    <div className="content"><span>{BaseHelper.formatMoney(item.amount, '', systemCurrency)}</span></div>
                    <div className="time">{BaseHelper.formatStrToDate(item.created_at, 'DD/MM/YYY HH:SS')}</div></>;
                break;

            case DefineConst.COMMISSION_TYPE.PER_PRODUCT:
                data = <><div className="box-statistics-icon"><i className="ladiui icon-ldp icon-products"></i></div>
                    <div className="title">Sản phẩm</div>
                    <div className="content"><span>{BaseHelper.formatMoney(item.amount, '', systemCurrency)}</span></div>
                    <div className="time">{BaseHelper.formatStrToDate(item.created_at, 'DD/MM/YYY HH:SS')}</div></>;
                break;
            default:
        }

        return data;
    };

    return <>
        <div className={`${styles['box-statistics-publisher']} ${styles['no-height']} ${styles['p-0']}`}>
            <div className={styles['box-title']}>
                <label className={styles.bold}>Hoa hồng</label>
            </div>
            <div className="box-body">
                <ul className="history-action">
                    {report && report.listCommission
                        ? report.listCommission.map((item) => <li key={item._id}>
                            {renderItem(item)}
                        </li>)
                        : <></>
                    }
                </ul>
            </div>
            <div className="box-footer"></div>
        </div>
    </>;
}

export default HistoryCommission;
