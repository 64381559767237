/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';

function ListCommissionInvoice(props) {
    const { t, propCommissions, minInvoice, cbSelectCommission } = props;

    const [checkAll, setCheckAll] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [propCommissionAll, setPropCommissionAll] = useState([]);
    const [propCommissionSelect, setPropCommissionSelect] = useState([]);

    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element-commission-invoice-modal');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
        if (ids.length === propCommissionAll.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
    };

    const eventCancel = () => {
        window.LadiUI.closeModal('modal-select-commission-invoice');
    };

    const confirmSelectedCommission = () => {
        if (selectedIds.length <= 0) {
            window.LadiUI.toast('danger', '', 'Số hoa hồng lên hoá đơn phải lớn hơn 0', 5000, 'bottom-left');
            return;
        }

        const data = [];
        let total = 0;
        for (let i = 0; i < propCommissionAll.length; i++) {
            if (selectedIds.includes(propCommissionAll[i]._id)) {
                data.push(propCommissionAll[i]);
                total += parseFloat(propCommissionAll[i].amount || 0) + parseFloat(propCommissionAll[i].bonus || 0);
            }
        }

        if (total < minInvoice) {
            window.LadiUI.toast('danger', '', `Tổng số tiền lên hoá đơn phải lớn hơn hoặc bằng ${BaseHelper.formatMoney(minInvoice, '', systemCurrency)}`, 5000, 'bottom-left');
            return;
        }

        cbSelectCommission({ ...propCommissions.select, commissions: data, totalCommissionValue: total });
        window.LadiUI.closeModal('modal-select-commission-invoice');
        window.LadiUI.toast('success', '', 'Thay đổi thành công', 5000, 'bottom-left');
    };

    useEffect(() => {
        if (propCommissions) {
            const allCommission = propCommissions.all.commissions || [];
            const selectCommission = propCommissions.select.commissions || [];
            let ids = [];
            for (let i = 0; i < selectCommission.length; i++) {
                ids.push(selectCommission[i]._id);
            }
            setPropCommissionAll(allCommission);
            setPropCommissionSelect(selectCommission);
            setSelectedIds(ids);
            if (ids.length === allCommission.length) {
                setCheckAll(true);
            }
        }
    }, [propCommissions]);

    return <>
        <div className="ladiui modal bd-example-modal-lg" id="modal-select-commission-invoice">
            <div className="ladiui modal-dialog modal-dialog-centered modal-lg" style={{ width: '750px' }}>
                <div className="ladiui modal-content custom-ldp" style={{ fontWeight: '400' }}>
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">Danh sách hoa hồng</label>
                        <button type="button" data-dismiss="modal" data-target="modal-export-commission" className="ladiui modal-close" onClick={eventCancel}>
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>

                    {propCommissionAll
                        ? <>
                            {propCommissionAll.length > 0
                                ? <>
                                    <div className="ladiui table-scroll" style={{ marginTop: '12px' }}>
                                        <table className="ladiui table text-left" style={{ marginTop: 0, minWidth: '1000px' }}>
                                            <thead>
                                                <tr className="ladiui table-vertical header">
                                                    <th className="ladiui col-first" scope="col">
                                                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-invoice"
                                                            name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                                                    </th>
                                                    <th scope="col">Mã tham chiếu</th>
                                                    <th scope="col">Email khách hàng</th>
                                                    <th scope="col">Loại</th>
                                                    <th scope="col">Cập nhật trạng thái</th>
                                                    <th scope="col">Ngày tạo</th>
                                                    <th scope="col">Tổng tiền</th>
                                                    <th scope="col">Hoa hồng</th>
                                                    <th scope="col">Thưởng</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {propCommissionAll.map((item, index) => <tr className="ladiui table-vertical main" key={item._id}>
                                                    <td scope="row">
                                                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element-commission-invoice-modal"
                                                            name="checkbox" checked={selectedIds.includes(item._id)}
                                                            value={item._id} onChange={eventCheck} />
                                                    </td>
                                                    <td><a href="#/" className="ladiui link"
                                                        style={{ display: 'block', overflow: 'hidden', maxWidth: '120px', textOverflow: 'ellipsis' }}
                                                    >{item.data.code || item._id}</a></td>
                                                    <td>{item.customer ? item.customer.email : ''}</td>
                                                    <td>{appConfig.LADISHARE.COMMISSION_TYPE.map((type) => (type.value === item.type ? type.name : ''))}</td>
                                                    <td>{BaseHelper.formatStrToDate(item.commission_date)}</td>
                                                    <td>{BaseHelper.formatStrToDate(item.created_at)}</td>
                                                    <td className="ladiui text-right">{BaseHelper.formatMoney(item.total, '', systemCurrency)}</td>
                                                    <td className="ladiui text-right">{BaseHelper.formatMoney(item.amount, '', systemCurrency)}</td>
                                                    <td className="ladiui text-right">{BaseHelper.formatMoney(item.bonus, '', systemCurrency)}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                    <label className="ladiui title-text-form-lever2" style={{ marginTop: '12px' }}>Tổng số hoa hồng được lên hoá đơn: {selectedIds.length}</label>

                                    <div className="ladiui modal-footer custom-ldp">
                                        <div></div>
                                        <div className="ladiui footer-modal-btn">
                                            <button type="button" className="ladiui btn btn-secondary bold" onClick={eventCancel}>{t('COMMISSIONS.CANCELED')}</button>
                                            <button type="button" className="ladiui btn btn-primary" onClick={() => confirmSelectedCommission()}>Lưu thay đổi</button>
                                        </div>

                                    </div>
                                </> : <><label className="ladiui title-text-form-lever2" style={{ marginTop: '24px' }}>Không tồn tại hoa hồng đủ điều kiện để lập hoá đơn</label></>}
                        </>
                        : <></>
                    }
                </div>
            </div>
        </div>
    </>;
}
export default (withTranslation()(ListCommissionInvoice));
