import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import commonReducer from './common/reducer';
import authReducer from './auth/reducer';
import customerReducer from './customer/reducer';
import storeReducer from './store/reducer';
import affiliateReducer from './affiliate/reducer';
import affiliateGroupReducer from './affiliate_group/reducer';
import commissionReducer from './commission/reducer';
import commissionPlanReducer from './commission_plan/reducer';
import staffReducer from './staff/reducer';
import sourceReducer from './source/reducer';
import integrateAppReducer from './integrate_app/reducer';
import invoiceReducer from './invoice/reducer';
import statisticReducer from './statistics/reducer';
import publisherReducer from './publisher/reducer';
import campaignReducer from './campaign/reducer';
import couponReducer from './coupon_code/reducer';
import activityReducer from './activity/reducer';
import linkReducer from './link/reducer';
import requestInvoiceReducer from './request_invoice/reducer';

export default (history) => combineReducers({
    store: storeReducer,
    common: commonReducer,
    auth: authReducer,
    customers: customerReducer,
    affiliates: affiliateReducer,
    affiliateGroups: affiliateGroupReducer,
    commissions: commissionReducer,
    commissionPlans: commissionPlanReducer,
    staffs: staffReducer,
    traffics: sourceReducer,
    appIntegrate: integrateAppReducer,
    invoices: invoiceReducer,
    campaigns: campaignReducer,
    statistics: statisticReducer,
    coupons: couponReducer,
    publishers: publisherReducer,
    activities: activityReducer,
    links: linkReducer,
    requestInvoice: requestInvoiceReducer,
    router: connectRouter(history),
});
