import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data, cb) => ({
    type: types.LIST_COUPON_CODE,
    meta: {
        endpoint: endpoint.LIST_COUPON_CODE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const create = (data, cb) => ({
    type: types.CREATE_COUPON_CODE,
    meta: {
        endpoint: endpoint.CREATE_COUPON_CODE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const show = (id, cb) => ({
    type: types.SHOW_COUPON_CODE,
    meta: {
        endpoint: endpoint.SHOW_COUPON_CODE,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const update = (data, cb) => ({
    type: types.UPDATE_COUPON_CODE,
    meta: {
        endpoint: endpoint.UPDATE_COUPON_CODE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteCoupon = (data, cb) => ({
    type: types.DELETE_COUPON_CODE,
    meta: {
        endpoint: endpoint.DELETE_COUPON_CODE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const syncCoupon = (data, cb) => ({
    type: types.SYNC_COUPON_CODE,
    meta: {
        endpoint: endpoint.SYNC_COUPON_CODE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteMulti = (data, cb) => ({
    type: types.DELETE_MULTI_COUPON_CODE,
    meta: {
        endpoint: endpoint.DELETE_MULTI_COUPON_CODE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const syncMulti = (data, cb) => ({
    type: types.SYNC_MULTI_COUPON_CODE,
    meta: {
        endpoint: endpoint.SYNC_MULTI_COUPON_CODE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

export default {
    list,
    create,
    show,
    update,
    delete: deleteCoupon,
    syncCoupon,
    deleteMulti,
    syncMulti,
};
