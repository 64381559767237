import React from 'react';
import styles from '../../../assets/css/custom.module.css';

function HistoryActionPublisher() {
    return <>
        <div className={`${styles['box-statistics-publisher']} ${styles['no-height']} ${styles['p-0']} ${styles['mt-16']}`}>
            <div className={styles['box-title']}>
                <label className={styles.bold}>Hoạt động</label>
            </div>
            <div className="box-body">
                <ul className="history-action">
                    <li>
                        <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                        <div className="title">Affiliate mới</div>
                        <div className="content"><a href="#/">sontruonggiang@gmail.com</a></div>
                        <div className="time">12 phút trước</div>
                    </li>
                    <li>
                        <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                        <div className="title">Lead mới</div>
                        <div className="content"><a href="#/">sontruonggiang@gmail.com</a></div>
                        <div className="time">12 phút trước</div>
                    </li>
                    <li>
                        <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                        <div className="title">Đơn hàng mới</div>
                        <div className="content"><a href="#/">sontruonggiang@gmail.com</a></div>
                        <div className="time">12 phút trước</div>
                    </li>
                    <li>
                        <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                        <div className="title">Đơn hàng mới</div>
                        <div className="content"><a href="#/">sontruonggiang@gmail.com</a></div>
                        <div className="time">12 phút trước</div>
                    </li>
                    <li>
                        <div className="box-statistics-icon"><img src="https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg" /></div>
                        <div className="title">Lead mới</div>
                        <div className="content"><a href="#/">sontruonggiang@gmail.com</a></div>
                        <div className="time">12 phút trước</div>
                    </li>
                </ul>
            </div>
            <div className="box-footer"></div>
        </div>
    </>;
}

export default HistoryActionPublisher;
