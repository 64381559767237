import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LadiDropdownMenu from '../../../components/LadiDropdownMenu';
import ConfirmModal from '../../../components/ConfirmModal';
import actionInvoice from '../../../redux/futures/invoice/actions';
import DefineConst from '../DefineConst';

function CheckAllActionInvoice(props) {
    const { t, cb } = props;
    const ids = props.ids || [];

    const listAction = [
        // { value: 'DELETE_ALL', name: t('COMMON.DELETE_ALL') },
        { value: 'ACCEPT_ALL', name: t('COMMON.ACCEPT_ALL') },
        { value: 'PAID_ALL', name: t('INVOICES.PAID_ALL') },
        { value: 'CANCEL_ALL', name: 'Huỷ' },
    ];

    const dispatch = useDispatch();
    const [actionSelected, setActionSelected] = useState(null);
    const [modalConfirm, setModalConfirm] = useState({});
    const [classBtnConfirm, setClassBtnConfirm] = useState('btn-primary');

    const eventSelectAction = (action) => {
        let dataModal = {};
        switch (action) {
            // case 'DELETE_ALL':
            //     setClassBtnConfirm('btn-danger');
            //     dataModal.title = 'Bạn có chắc chắn muốn xóa toàn bộ invoice được chọn?';
            //     dataModal.content = <div>Những Invoice ở trạng thái
            //         <span className="bold-500" style={{ verticalAlign: 'top', padding: '0 2px', fontStyle: 'italic' }}>lên hóa đơn</span>
            //         sẽ không bị xóa</div>;
            //     break;
            case 'ACCEPT_ALL':
                setClassBtnConfirm('btn-primary');
                dataModal.title = 'Bạn có chắc chắn muốn duyệt toàn bộ hoá đơn được chọn?';
                dataModal.content = 'Những hoá đơn ở trạng thái chờ duyệt mới được phép chuyển sang trạng thái duyệt';
                break;
            case 'CANCEL_ALL':
                setClassBtnConfirm('btn-primary');
                dataModal.title = 'Bạn có chắc chắn muốn huỷ toàn bộ hoá đơn được chọn?';
                dataModal.content = 'Những hoá đơn đã thanh toán sẽ không bị huỷ';
                break;
            case 'PAID_ALL':
                setClassBtnConfirm('btn-primary');
                dataModal.title = 'Bạn có chắc chắn muốn thanh toán toàn bộ hoá đơn được chọn?';
                dataModal.content = 'Những hoá đơn ở trạng thái duyệt mới được phép chuyển sang trạng thái thanh toán';
                break;
            default:
        }
        setActionSelected(action);
        setModalConfirm(dataModal);
        window.LadiUI.showModal('modal-confirm-action-all');
    };

    const eventOkActionAll = () => {
        if (actionSelected) {
            switch (actionSelected) {
                // case 'DELETE_ALL':
                //     dispatch(actionInvoice.deleteMultiInvoice({ ids }, cb));
                //     break;
                case 'ACCEPT_ALL':
                    dispatch(actionInvoice.acceptMultiInvoice({ ids, status: DefineConst.INVOICE_STATUS.APPROVED }, cb));
                    break;
                case 'CANCEL_ALL':
                    dispatch(actionInvoice.rejectMultiInvoice({ ids, status: DefineConst.INVOICE_STATUS.CANCELED }, cb));
                    break;
                case 'PAID_ALL':
                    dispatch(actionInvoice.paidMultiInvoice({ ids, status: DefineConst.INVOICE_STATUS.PAID }, cb));
                    break;
                default:
            }
        }
    };

    return ids.length > 0
        ? <>
            <div className="ladiui ladi-check-all-action">
                <LadiDropdownMenu
                    labelDropdown={'Chọn hành động'}
                    listProp={listAction}
                    defaultTextProp={t('COMMON.ACTION')}
                    hideLabel={true}
                    cbProp={eventSelectAction}
                    classSize=""
                    classDropdown=""
                />
            </div>
            <ConfirmModal
                id="modal-confirm-action-all"
                title={modalConfirm.title || ''}
                content={modalConfirm.content || ''}
                onOk={() => eventOkActionAll()}
                cancelText={t('COMMISSIONS.CANCELED')}
                okText={t('COMMISSIONS.OK')}
                classBtn={classBtnConfirm || 'btn-primary'}
            />
        </>
        : <></>;
}

export default (withTranslation()(CheckAllActionInvoice));
