import React from 'react';
import { withTranslation } from 'react-i18next';
import styles from '../../../../assets/css/custom.module.css';
import config from '../../../../config/config';

function BannerContent(props) {
    const { t, campaign } = props;
    const copyClipboard = (id) => {
        const copyText = document.getElementById(id);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        window.LadiUI.toast('success', '', t('COMMON.COPIED'), 5000, 'bottom-left');
    };

    return <>
        <label>Nội dung</label>
        {campaign && campaign.image
            ? campaign.image.map((image, index) => <div key={index} style={{ marginBottom: '40px' }}>
                <div className="content-left-detail">
                    <div className={styles.banner}>
                        <img className={styles['img-instagram']} src={`${config.DOMAIN_CDN}/${image.path}`} />
                    </div>
                </div>
                <label className={`${styles['mt-16']} ${styles['d-block']}`}>
                    <span className="left">Mã nhúng</span>
                    <span className={`right ${styles['copy-link']}`} onClick={() => copyClipboard(`embed-code-${index}`)}>Sao chép nội dung</span>
                </label>
                <div className="clearfix">
                    <textarea readOnly id={`embed-code-${index}`} rows={3} className={`content-left-detail ${styles['embed-code']}`}
                        onChange={() => { }}
                        value={`<iframe width="${image.width || 250}" height="${image.height || 250}" 
                        src="${config.AFFILIATE_DOMAIN}/campaign-share?url=${config.DOMAIN_CDN}/${image.path}&cpid=${campaign._id}&afc=aff_code" title="${campaign.title}" 
                        frameborder="0"></iframe>`} />
                </div>
            </div>) : <></>}
    </>;
}
export default (withTranslation()(BannerContent));
