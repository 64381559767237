import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isArray } from 'lodash';
import ShopIdDropdownMultiSelect from './ShopIdDropdownMultiSelect';
import LadiDropdownMultiSelect from '../../../components/LadiDropdownMultiSelect';
import appConfig from '../../../config/app';
import actionIntegration from '../../../redux/futures/integrate_app/actions';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import actionCommissionPlan from '../../../redux/futures/commission_plan/actions';
import config from '../../../config/config';
import LadiDateRangePicker from '../../../components/LadiDateRangePicker';
import BaseHelper from '../../../helpers/BaseHelper';
import AffiliateDropdownMultiSelect from './AffiliateDropdownMultiSelect';
import CommissionPlanPaymentMultiSelect from './CommissionPlanPaymentMultiSelect';

// eslint-disable-next-line max-lines-per-function
function FilterCondition(props) {
    const moment = BaseHelper.getMoment();

    const { t, conditionProp, filterActionProp } = props;

    const [conditionIntegration, setConditionIntegration] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { created_at: -1 },
    });

    const [conditionAffiliate, setConditionAffiliate] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { created_at: -1 },
    });

    const dispatch = useDispatch();
    const [attributeFilter, setAttributeFilter] = useState(null);
    const [conditionFilter, setConditionFilter] = useState(null);
    const [valueFilter, setValueFilter] = useState(null);
    const [allConditionSelect, setAllConditionSelect] = useState([]);
    const [showListFilter, setShowListFilter] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const listAppIntegrate = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.appIntegrate.integrateApps }));
    const affiliates = useSelector((state) => (state.affiliates.affiliates));
    const commissionPlanDefault = useSelector((state) => (state.commissionPlans.commissionPlanDefault));
    let commisionPlanPaymentItems = [];
    if (commissionPlanDefault && commissionPlanDefault.commision_plan_payment_items) {
        commisionPlanPaymentItems = commissionPlanDefault.commision_plan_payment_items;
    }
    const eventCloseFilterCondition = () => {
        setAttributeFilter(null);
        setConditionFilter(null);
        setValueFilter(null);
        setShowListFilter(false);
        setFromDate(null);
        setToDate(null);
    };

    const eventFilterCondition = () => {
        if (!attributeFilter || !conditionFilter || !valueFilter) {
            return;
        }

        if (isArray(valueFilter.value) && valueFilter.value.length <= 0) {
            return;
        }
        const allCondition = allConditionSelect.filter((value) => value.attributeFilter.value !== attributeFilter.value);

        const newAllConditionFilter = [...allCondition, ...[{ attributeFilter, conditionFilter, valueFilter }]];
        setAllConditionSelect(newAllConditionFilter);

        const filterItems = {};

        filterItems[attributeFilter.value] = {};
        if (conditionFilter.value === '$regex') {
            filterItems[attributeFilter.value][conditionFilter.value] = valueFilter.value;
            filterItems[attributeFilter.value].$options = 'i';
        } else if (conditionFilter.value === '$notRegex') {
            filterItems[attributeFilter.value] = { $regex: `^((?!${valueFilter.value}).)*$`, $options: 'i' };
        } else if (conditionFilter.value === '$e') {
            filterItems[attributeFilter.value] = valueFilter.value;
        } else {
            filterItems[attributeFilter.value][conditionFilter.value] = valueFilter.value;
        }
        const conditionSend = { ...conditionProp, ...filterItems };
        filterActionProp(conditionSend);
        eventCloseFilterCondition();
    };

    const eventRemoveFilter = (attributeFilterValue) => {
        const allCondition = allConditionSelect.filter((value) => value.attributeFilter.value !== attributeFilterValue);
        setAllConditionSelect(allCondition);
        delete conditionProp[attributeFilterValue];
        filterActionProp(conditionProp);
    };

    const eventSetAttributeFilter = (attributeFilter) => {
        setAttributeFilter(attributeFilter);
        if (attributeFilter.value === 'commission_date') {
            setConditionFilter({ name: 'trong khoảng thời gian:', value: 'date_range' });
        } else if (attributeFilter.value === 'aff_ids') {
        } else if (attributeFilter.value === 'is_confirm') {
            setConditionFilter({ name: ':', value: 'is_confirm' });
        } else if (attributeFilter.value === 'is_invoiced') {
            setConditionFilter({ name: ':', value: 'is_invoiced' });
        } else if (attributeFilter.value === 'is_paid') {
            setConditionFilter({ name: ':', value: 'is_paid' });
        } else if (attributeFilter.value === 'commission_plan_payment_id') {
            dispatch(actionCommissionPlan.getDefaultCommissionPlan());
            setConditionFilter({ name: 'Chọn điều kiện', value: 'commission_plan_payment_id' });
        } else {
            setConditionFilter(null);
        }
        setValueFilter(null);
        setFromDate(null);
        setToDate(null);
        if (attributeFilter.value === 'shop_id') {
            dispatch(actionIntegration.list(conditionIntegration));
        }
    };

    const selectMultiDropdown = (data) => {
        let values = [];
        let names = [];
        for (let i = 0; i < data.length; i++) {
            values.push(data[i].value);
            names.push(data[i].name);
        }
        setValueFilter({ name: names.join(', '), value: values });
    };

    const selectMultiDropdownShopId = (data) => {
        let values = [];
        let names = [];
        for (let i = 0; i < data.length; i++) {
            values.push(data[i].config.shop_id);
            names.push(data[i].name);
        }
        setValueFilter({ name: names.join(', '), value: values });
    };

    const cbListAppIntegrates = (keyword, idIgnore = []) => {
        const dataConditions = { ...conditionIntegration, keyword };
        if (idIgnore.length > 0) {
            dataConditions._id = { $nin: idIgnore };
        }
        dispatch(actionIntegration.list(dataConditions));
    };

    const selectMultiDropdownAffiliate = (data) => {
        let values = [];
        let names = [];
        for (let i = 0; i < data.length; i++) {
            values.push(data[i]._id);
            names.push(data[i].email);
        }
        setValueFilter({ name: names.join(', '), value: values });
    };

    const selectMultiDropdownCommissionPaymentPlan = (data) => {
        let values = [];
        let names = [];
        for (let i = 0; i < data.length; i++) {
            values.push(data[i]._id);
            names.push(data[i].name);
        }
        setValueFilter({ name: names.join(', '), value: values });
    };

    const cbListAffiliateDropdown = (keyword, idIgnore = []) => {
        const dataConditions = { ...conditionAffiliate, keyword };
        if (idIgnore.length > 0) {
            dataConditions._id = { $nin: idIgnore };
        }
        dispatch(actionAffiliate.list(dataConditions));
    };

    const cbListCommissionPlanDefaultDropdown = () => {
        dispatch(actionCommissionPlan.getDefaultCommissionPlan());
    };

    const renderStatusCondition = () => <>
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: 'là', value: '$regex' })}><a className="ladiui dropdown-item" href="#/">{'là'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'không phải', value: '$notRegex' })}><a className="ladiui dropdown-item" href="#/">{'không phải'}</a></li>
                </ul>
            </div>
        </div>
        <div className="ladiui btn-group">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">
                        {valueFilter ? valueFilter.name : t('COMMON.SELECT_STATUS')}
                    </span>
                </button>
                <ul className="ladiui dropdown-menu">
                    {appConfig.LADISHARE.COMMISSION_STATUS.filter((item) => item.value !== 'INVOICE_CANCEL').map((item) => <li key={item.value}
                        onClick={() => setValueFilter({ name: item.name, value: item.value })}>
                        <a className="ladiui dropdown-item" href="#/">{item.name}</a>
                    </li>)}
                </ul>
            </div>
        </div>
    </>;

    const renderIsConfirmCondition = () => <>
        <div className="ladiui btn-group">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">
                        {valueFilter ? valueFilter.name : t('COMMON.SELECT_STATUS')}
                    </span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li
                        onClick={() => setValueFilter({ name: 'Đã đối soát', value: 1 })}>
                        <a className="ladiui dropdown-item" href="#/">Đã đối soát</a>
                    </li>
                    <li
                        onClick={() => setValueFilter({ name: 'Chưa đối soát', value: 0 })}>
                        <a className="ladiui dropdown-item" href="#/">Chưa đối soát</a>
                    </li>
                </ul>
            </div>
        </div>
    </>;

    const renderIsInvoicedCondition = () => <>
        <div className="ladiui btn-group">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">
                        {valueFilter ? valueFilter.name : t('COMMON.SELECT_STATUS')}
                    </span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li
                        onClick={() => setValueFilter({ name: 'Đã lên hoá đơn', value: 1 })}>
                        <a className="ladiui dropdown-item" href="#/">Đã lên hoá đơn</a>
                    </li>
                    <li
                        onClick={() => setValueFilter({ name: 'Chưa lên hoá đơn', value: 0 })}>
                        <a className="ladiui dropdown-item" href="#/">Chưa lên hoá đơn</a>
                    </li>
                </ul>
            </div>
        </div>
    </>;

    const renderIsPaidCondition = () => <>
        <div className="ladiui btn-group">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">
                        {valueFilter ? valueFilter.name : t('COMMON.SELECT_STATUS')}
                    </span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li
                        onClick={() => setValueFilter({ name: 'Đã thanh toán', value: 1 })}>
                        <a className="ladiui dropdown-item" href="#/">Đã thanh toán</a>
                    </li>
                    <li
                        onClick={() => setValueFilter({ name: 'Chưa thanh toán', value: 0 })}>
                        <a className="ladiui dropdown-item" href="#/">Chưa thanh toán</a>
                    </li>
                </ul>
            </div>
        </div>
    </>;

    const renderSalesCondition = () => <>
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: 'bằng', value: '$e' })}><a className="ladiui dropdown-item" href="#/">{'bằng'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'lớn hơn hoặc bằng', value: '$gte' })}><a className="ladiui dropdown-item" href="#/">{'lớn hơn hoặc bằng'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'nhỏ hơn hoặc bằng', value: '$lte' })}><a className="ladiui dropdown-item" href="#/">{'nhỏ hơn hoặc bằng'}</a></li>
                </ul>
            </div>
        </div>
        <div>
            <input type="number" className="ladiui form-control" placeholder="0" min={0}
                value={valueFilter ? valueFilter.value : ''}
                onChange={(e) => setValueFilter({ name: e.target.value, value: parseFloat(e.target.value || 0) })} />
        </div>
    </>;

    const renderSourceCondition = () => <>
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: 'là', value: '$in' })}><a className="ladiui dropdown-item" href="#/">{'là'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'không phải', value: '$nin' })}><a className="ladiui dropdown-item" href="#/">{'không phải'}</a></li>
                </ul>
            </div>
        </div>
        <LadiDropdownMultiSelect unCheckedListProp={appConfig.LADISHARE.APPS_INTEGRATE_FILTER_COMMISSION} cb={selectMultiDropdown} />
    </>;

    const renderUtmCondition = () => <>
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: 'chứa', value: '$regex' })}><a className="ladiui dropdown-item" href="#/">{'chứa'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'không chứa', value: '$notRegex' })}><a className="ladiui dropdown-item" href="#/">{'không chứa'}</a></li>
                </ul>
            </div>
        </div>
        <div>
            <input type="text" className="ladiui form-control" placeholder="Nhập nội dung" style={{ width: '170px' }}
                value={valueFilter ? valueFilter.value : ''}
                onChange={(e) => setValueFilter({ name: e.target.value, value: e.target.value })} />
        </div>
    </>;

    const renderShopIdCondition = () => <>
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: 'là', value: '$in' })}><a className="ladiui dropdown-item" href="#/">{'là'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'không phải', value: '$nin' })}><a className="ladiui dropdown-item" href="#/">{'không phải'}</a></li>
                </ul>
            </div>
        </div>
        <ShopIdDropdownMultiSelect unCheckedListProp={listAppIntegrate.items} cb={selectMultiDropdownShopId} cbList={cbListAppIntegrates} />
    </>;

    const renderCommissionDate = () => <div className="ladiui flex-row" style={{ width: '255px' }}>
        <LadiDateRangePicker
            startDateId="fromDate"
            endDateId="toDate"
            startDate={fromDate}
            endDate={toDate}
            onDatesChange={({ startDate, endDate }) => {
                const startDateNew = BaseHelper.getFromDate(startDate);
                const endDateNew = BaseHelper.getToDate(endDate);
                setFromDate(startDateNew);
                setToDate(endDateNew);
                if ((startDateNew && endDateNew) || (!startDateNew && !endDateNew)) {
                    setValueFilter({ name: `${startDateNew ? startDateNew.format('DD/MM/YYYY') : null} - ${endDateNew ? endDateNew.format('DD/MM/YYYY') : null}`, value: { date_from: startDateNew ? startDateNew.format('YYYY-MM-DD 00:00:01') : null, date_to: endDateNew ? endDateNew.format('YYYY-MM-DD 23:59:59') : null } });
                }
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => {
                setFocusedInput(focusedInput);
            }}
            showDefaultInputIcon={true}
            inputIconPosition="after"
            small={true}
            hideKeyboardShortcutsPanel={true}
            customInputIcon={
                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
            }
            startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
            endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
            isOutsideRange={() => false}
            isDayHighlighted={(date) => moment().isSame(date, 'day')}
            minimumNights={0}
            showClearDates={true}
        />
    </div>;

    const renderAffiliateSelectDropdown = () => <>
        <div className="ladiui btn-group mr-8 pt-0 pb-0">
            <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                    data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{conditionFilter ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                    <li onClick={() => setConditionFilter({ name: 'là', value: '$in' })}><a className="ladiui dropdown-item" href="#/">{'là'}</a></li>
                    <li onClick={() => setConditionFilter({ name: 'không phải', value: '$nin' })}><a className="ladiui dropdown-item" href="#/">{'không phải'}</a></li>
                </ul>
            </div>
        </div>
        <AffiliateDropdownMultiSelect unCheckedListProp={affiliates.items} cb={selectMultiDropdownAffiliate} cbList={cbListAffiliateDropdown} />
    </>;

    const renderCommissionPaymentPlanSelectDropdown = () => <>
    <div className="ladiui btn-group mr-8 pt-0 pb-0">
        <div className="ladiui dropdown">
            <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                <i className="ladiui icon"></i>
                <span className="ladiui dropdown-text">{conditionFilter && conditionFilter.name != '' ? conditionFilter.name : t('COMMON.SELECT_CONDITION')}</span>
            </button>
            <ul className="ladiui dropdown-menu">
                <li onClick={() => setConditionFilter({ name: 'là', value: '$in' })}><a className="ladiui dropdown-item" href="#/">{'là'}</a></li>
                <li onClick={() => setConditionFilter({ name: 'không phải', value: '$nin' })}><a className="ladiui dropdown-item" href="#/">{'không phải'}</a></li>
            </ul>
        </div>
    </div>
    <CommissionPlanPaymentMultiSelect unCheckedListProp={commisionPlanPaymentItems} cb={selectMultiDropdownCommissionPaymentPlan} cbList={cbListCommissionPlanDefaultDropdown} />
    </>;

    const renderDetailCondition = (condition) => {
        switch (condition) {
            case 'status': return renderStatusCondition();
            case 'amount': return renderSalesCondition();
            case 'source': return renderSourceCondition();
            case 'utm': return renderUtmCondition();
            case 'shop_id': return renderShopIdCondition();
            case 'commission_date': return renderCommissionDate();
            case 'aff_ids': return renderAffiliateSelectDropdown();
            case 'is_confirm': return renderIsConfirmCondition();
            case 'is_invoiced': return renderIsInvoicedCondition();
            case 'is_paid': return renderIsPaidCondition();
            case 'commission_plan_payment_id': return renderCommissionPaymentPlanSelectDropdown();
            default: return '';
        }
    };

    useEffect(() => {
        document.addEventListener('click', (event) => {
            const box = document.getElementById('filter-condtion-area');
            if (box && !box.contains(event.target)) {
                // setAttributeFilter(null);
                // setConditionFilter(null);
                // setValueFilter(null);
                // setShowListFilter(false);
            }
        });
    }, []);

    return <>
        <div id="filter-condtion-area" className={allConditionSelect.length > 0 ? 'filter-condtion-area mr-24 mb-20' : 'filter-condtion-area mr-24'}>
            <button className="ladiui btn btn-outline-light" onClick={() => setShowListFilter(!showListFilter)}>
                <img src="https://w.ladicdn.com/design-system/icons/icon-filter.svg" style={{ marginBottom: '2px', marginRight: '10px' }} />Thêm điều kiện lọc
            </button>
            <div className={showListFilter ? 'list-filter-condition pt-0 show' : 'list-filter-condition pt-0'}>
                <div className="ladiui flex-row mt-0 content-condition-filter">
                    <div className="mr-8">
                        <div className="ladiui btn-group">
                            <div className="ladiui dropdown">
                                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                                    data-toggle="dropdown">
                                    <i className="ladiui icon"></i>
                                    <span className="ladiui dropdown-text">
                                        {attributeFilter ? attributeFilter.name : 'Chọn tiêu chí'}
                                    </span>
                                </button>
                                <ul className="ladiui dropdown-menu">
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Trạng thái', value: 'status' })}>
                                        <a className="ladiui dropdown-item" href="#/">Trạng thái</a>
                                    </li>
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Hoa hồng', value: 'amount' })}>
                                        <a className="ladiui dropdown-item" href="#/">Hoa hồng</a>
                                    </li>
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Nền tảng', value: 'source' })}>
                                        <a className="ladiui dropdown-item" href="#/">Nền tảng</a>
                                    </li>
                                    <li onClick={() => eventSetAttributeFilter({ name: 'UTM', value: 'utm' })}>
                                        <a className="ladiui dropdown-item" href="#/">UTM</a>
                                    </li>
                                    {config.APP_AFFILIATE
                                        ? <></>
                                        : <li onClick={() => eventSetAttributeFilter({ name: 'Cửa hàng', value: 'shop_id' })}>
                                            <a className="ladiui dropdown-item" href="#/">Cửa hàng</a>
                                        </li>}
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Affiliate', value: 'aff_ids' })}>
                                        <a className="ladiui dropdown-item" href="#/">Affiliate</a>
                                    </li>
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Đối soát', value: 'is_confirm' })}>
                                        <a className="ladiui dropdown-item" href="#/">Đối soát</a>
                                    </li>
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Lên hoá đơn', value: 'is_invoiced' })}>
                                        <a className="ladiui dropdown-item" href="#/">Lên hoá đơn</a>
                                    </li>
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Thanh toán', value: 'is_paid' })}>
                                        <a className="ladiui dropdown-item" href="#/">Thanh toán</a>
                                    </li>
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Cập nhật trạng thái lần cuối', value: 'commission_date' })}>
                                        <a className="ladiui dropdown-item" href="#/">Cập nhật trạng thái lần cuối</a>
                                    </li>
                                    <li onClick={() => eventSetAttributeFilter({ name: 'Cấu hình hoa hồng', value: 'commission_plan_payment_id' })}>
                                        <a className="ladiui dropdown-item" href="#/">Cấu hình hoa hồng</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {attributeFilter ? renderDetailCondition(attributeFilter.value) : ''}
                </div>
                <div className="ladiui footer-list-filter-condition flex-row">
                    <button type="button" className="ladiui btn btn-secondary btn-sm mr-8" onClick={() => eventCloseFilterCondition()}>Hủy</button>
                    <button type="button" className="ladiui btn btn-primary btn-sm" onClick={() => eventFilterCondition()}>Áp dụng</button>
                </div>
                <div className="clearfix"></div>
            </div>
            <div className="ladiui result-filter-condition flex-row">
                {allConditionSelect.map((item, index) => <span className="ladiui btn-tag" key={index}>
                    {item.attributeFilter.name} {item.conditionFilter.name} {item.valueFilter.name}
                    <img className="cursor-pointer"
                        style={{ width: '12px', marginLeft: '8px' }}
                        src="https://w.ladicdn.com/design-system/icons/icon-close.svg"
                        onClick={() => eventRemoveFilter(item.attributeFilter.value)} />
                </span>)}
            </div>
        </div>
    </>;
}

export default (withTranslation()(FilterCondition));
