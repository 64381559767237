export const LIST_INVOICE = 'LIST_INVOICE';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const SHOW_INVOICE = 'SHOW_INVOICE';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const EXPORT_INVOICE = 'EXPORT_INVOICE';
export const EXPORT_COMMISSION_INVOICE = 'EXPORT_COMMISSION_INVOICE';
export const DELETE_MULTI_INVOICE = 'DELETE_MULTI_INVOICE';
export const ACCEPT_MULTI_INVOICE = 'ACCEPT_MULTI_INVOICE';
export const REJECT_MULTI_INVOICE = 'REJECT_MULTI_INVOICE';
export const PAID_MULTI_INVOICE = 'PAID_MULTI_INVOICE';
export const UPDATE_STATUS_INVOICE = 'UPDATE_STATUS_INVOICE';
export const EXPORT_BANK_INFO_AFFILIATE = 'EXPORT_BANK_INFO_AFFILIATE';
export const SEND_MAIL_CONFIRM_INVOICE = 'SEND_MAIL_CONFIRM_INVOICE';
export const CREATE_REQUEST_WITHDRAW = 'CREATE_REQUEST_WITHDRAW';
export const UPDATE_NOTE_INVOICE = 'UPDATE_NOTE_INVOICE';
