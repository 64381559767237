/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import actionAffiliate from '../../redux/futures/affiliate/actions';
import actionStore from '../../redux/futures/store/actions';
import BankInfoDropdown from './components/BankInfoDropdown';

function AffiliateAccount(props) {
    const { t } = props;
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({});
    const [inputTypePassword, setInputTypePassword] = useState(true);
    const [inputTypePasswordRepeat, setInputTypePasswordRepeat] = useState(true);

    const [isAffiliateDomain, setIsAffiliateDomain] = useState(false);

    const user = useSelector((state) => state.store.userInfo.user);

    const cbLoadData = () => {
        dispatch(actionStore.getUserInfo());
    };

    useEffect(() => {
        let domainUrl = window.location.href;
        if (domainUrl.includes('affiliate.ladipage.com') || domainUrl.includes('aff.dev.ladishare.com')) {
           setIsAffiliateDomain(true);
        }
    }, [])

    const updateAccountInfo = () => {
        const data = {
            id: userInfo._id,
            fullname: userInfo.fullname,
            bank_name: userInfo.bank_name,
            bank_account_number: userInfo.bank_account_number,
            bank_account_name: userInfo.bank_account_name,
            bank_department: userInfo.bank_department,
        };
        dispatch(actionAffiliate.updateOther(data));
    };

    const updateAccountPassword = () => {
        if (userInfo.password_new && userInfo.password_repeat && userInfo.password_new === userInfo.password_repeat) {
            const data = { id: userInfo._id, password_new: userInfo.password_new };
            dispatch(actionAffiliate.updateOther(data));
        } else {
            window.LadiUI.toast('danger', null, 'Mật khẩu mới và mật khẩu nhắc lại khác nhau', 5000, 'bottom-left');
        }
    };

    const selectBank = (item) => {
        setUserInfo({ ...userInfo, bank_name: item.name });
    };

    useEffect(() => {
        if (user) setUserInfo({ ...user, password: '' });
    }, [user]);

    const handleCopyLinkHelp = () => {
        if (userInfo && userInfo.code) {
            let link = `https://ladipage.vn/?ref=${userInfo && userInfo.code ? userInfo.code : ''}`;
            navigator.clipboard.writeText(link);

            window.LadiUI.toast('success', '', t('COMMON.COPIED'), 5000, 'bottom-left');
        }
    }

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui action-history" style={{ marginTop: '28px' }}>
                <ul className="ladiui nav nav-tabs new-tab-custom" id="tab-list" role="tablist">
                    <li className="ladiui nav-item" role="presentation">
                        <span className="ladiui tab-link active" id="tab-general-info" data-toggle="tab"
                            data-parent="myTab" data-target="tab-general-info-detail">Thông tin chung</span>
                    </li>
                    <li className="ladiui nav-item" role="presentation" onClick={() => { }}>
                        <span className="ladiui tab-link" id="tab-change-password" data-toggle="tab" data-parent="myTab"
                            data-target="tab-change-password-detail">Thay đổi mật khẩu</span>
                    </li>
                </ul>
                <div className="ladiui tab-content" id="tab-content">
                    <div className="ladiui tab-pane fade show open" id="tab-general-info-detail" role="tabpanel"
                        aria-labelledby="tab-general-info-detail">
                        <div className="ladiui affiliate-account-box">
                            <div className="ladiui form-group flex">
                                <label className="ladiui title-text-form-lever2" htmlFor="fullname"
                                       style={{width: '180px'}}>Họ và tên</label>
                                <input name="fullname" id="fullname" className="ladiui form-control"
                                       placeholder="Nhập tên"
                                       style={{width: '492px'}}
                                       value={userInfo.fullname || ''}
                                       onChange={(e) => setUserInfo({...userInfo, fullname: e.target.value})}
                                />
                            </div>
                            <div className="ladiui form-group flex">
                                <label className="ladiui title-text-form-lever2" htmlFor="email"
                                       style={{width: '180px'}}>Email</label>
                                <input name="email" id="email" className="ladiui form-control"
                                       placeholder="Nhập email" disabled
                                       style={{width: '492px'}}
                                       defaultValue={userInfo.email || ''}
                                />
                            </div>
                            {
                                isAffiliateDomain
                                &&
                            <>
                                {
                                    userInfo.ranking
                                    &&
                                    <div className="ladiui form-group flex">
                                        <label className="ladiui title-text-form-lever2" htmlFor="fullname"
                                               style={{width: '180px'}}>Hạng thành viên</label>
                                        <div className="membership-class-group">
                                            <div className={`item ${userInfo.ranking ? userInfo.ranking.label : ""}`}>
                                                <span>{userInfo.ranking ? userInfo.ranking.label : ""}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="ladiui form-group flex">
                                    <label className="ladiui title-text-form-lever2" htmlFor="linkhelp"
                                           style={{width: '180px'}}>Link giới thiệu</label>
                                    <div className="item-link-help">
                                        <input name="linkhelp" id="linkhelp" className="ladiui form-control"
                                               placeholder="Nhập link giới thiệu" disabled
                                               style={{width: '492px'}}
                                               value={`https://ladipage.vn/?ref=${userInfo && userInfo.code ? userInfo.code : ''}`}
                                        />
                                        <span onClick={handleCopyLinkHelp}>Sao chép</span>
                                    </div>
                                </div>
                            </>
                            }
                            <div className="ladiui form-group flex">
                                <label className="ladiui title-text-form-lever2" htmlFor="phone"
                                       style={{width: '180px'}}>Điện thoại</label>
                                <input name="phone" id="phone" className="ladiui form-control"
                                       placeholder="Nhập số điện thoại" disabled
                                       style={{width: '492px'}}
                                       defaultValue={userInfo.phone || ''}
                                />
                            </div>


                            <div className="ladiui form-group flex">
                                <label className="ladiui title-text-form-lever2" htmlFor="bank_name"
                                       style={{width: '180px'}}>Ngân hàng</label>
                                <input name="bank_name" id="bank_name" className="ladiui form-control"
                                       placeholder="Tên ngân hàng"
                                       style={{width: '492px'}}
                                       value={userInfo.bank_name || ''}
                                       onChange={(e) => setUserInfo({...userInfo, bank_name: e.target.value})}
                                />
                                {/* <div style={{ width: '492px' }}>
                                    <BankInfoDropdown bankName={userInfo.bank_name || ''} cb={selectBank} />
                                </div> */}
                            </div>
                            <div className="ladiui form-group flex">
                                <label className="ladiui title-text-form-lever2" htmlFor="bank_account_number"
                                       style={{width: '180px'}}>Số tài khoản</label>
                                <input name="bank_account_number" id="bank_account_number"
                                       className="ladiui form-control"
                                       placeholder="Nhập số tài khoản"
                                       style={{width: '492px'}}
                                       value={userInfo.bank_account_number || ''}
                                       onChange={(e) => setUserInfo({...userInfo, bank_account_number: e.target.value})}
                                />
                            </div>
                            <div className="ladiui form-group flex">
                                <label className="ladiui title-text-form-lever2" htmlFor="bank_account_name"
                                       style={{width: '180px'}}>Tên chủ khoản</label>
                                <input name="bank_account_name" id="bank_account_name" className="ladiui form-control"
                                       placeholder="Nhập tên chủ khoản"
                                       style={{width: '492px'}}
                                       value={userInfo.bank_account_name || ''}
                                       onChange={(e) => setUserInfo({...userInfo, bank_account_name: e.target.value})}
                                />
                            </div>
                            <div className="ladiui form-group flex">
                                <label className="ladiui title-text-form-lever2" htmlFor="bank_department"
                                       style={{width: '180px'}}>Chi nhánh</label>
                                <input name="bank_department" id="bank_department" className="ladiui form-control"
                                       placeholder="Nhập chi nhánh"
                                       style={{width: '492px'}}
                                       value={userInfo.bank_department || ''}
                                       onChange={(e) => setUserInfo({...userInfo, bank_department: e.target.value})}
                                />
                            </div>
                            <div className="ladiui form-group">
                                <button className="ladiui btn btn-primary right" onClick={updateAccountInfo}>Cập nhật
                                </button>
                                <button className="ladiui btn right btn-secondary" style={{marginRight: '40px'}}
                                        onClick={cbLoadData}>Hủy bỏ
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="ladiui tab-pane fade" id="tab-change-password-detail" role="tabpanel"
                         aria-labelledby="tab-change-password-detail">
                        <div className="ladiui">
                            <div className="ladiui affiliate-account-box">
                                <div className="ladiui form-group flex" style={{position: 'relative'}}>
                                    <label className="ladiui title-text-form-lever2" htmlFor="password_new"
                                           style={{width: '180px'}}>Mật khẩu mới</label>
                                    <input name="password_new" id="password_new" className="ladiui form-control"
                                           type={inputTypePassword ? 'password' : 'text'}
                                           placeholder="Nhập mật khẩu"
                                           style={{ width: '492px' }}
                                        value={userInfo.password_new || ''}
                                        onChange={(e) => setUserInfo({ ...userInfo, password_new: e.target.value })}
                                    />
                                    <img src={`https://w.ladicdn.com/ladiui/ladishare/${inputTypePassword ? 'eye_hide1909' : 'eye_show1909'}.svg`}
                                        style={{ position: 'absolute', bottom: '10px', right: '12px', cursor: 'pointer' }}
                                        onClick={() => setInputTypePassword(!inputTypePassword)}
                                    />
                                    {/* <i className="ladi-icon icon-preview ladi-date-picker-icon"
                                        style={{ position: 'absolute', bottom: '10px', right: '12px', cursor: 'pointer' }}
                                        onClick={() => setInputTypePassword(!inputTypePassword)}
                                    ></i> */}
                                </div>
                                <div className="ladiui form-group flex" style={{ position: 'relative' }}>
                                    <label className="ladiui title-text-form-lever2" htmlFor="password_repeat" style={{ width: '180px' }}>Nhập lại mật khẩu mới</label>
                                    <input name="password_repeat" id="password_repeat" className="ladiui form-control" type={inputTypePasswordRepeat ? 'password' : 'text'}
                                        placeholder="Nhập lại mật khẩu"
                                        style={{ width: '492px' }}
                                        value={userInfo.password_repeat || ''}
                                        onChange={(e) => setUserInfo({ ...userInfo, password_repeat: e.target.value })}
                                    />
                                    <img src={`https://w.ladicdn.com/ladiui/ladishare/${inputTypePasswordRepeat ? 'eye_hide1909' : 'eye_show1909'}.svg`}
                                        style={{ position: 'absolute', bottom: '10px', right: '12px', cursor: 'pointer' }}
                                        onClick={() => setInputTypePasswordRepeat(!inputTypePasswordRepeat)}
                                    />
                                    {/* <i className="ladi-icon icon-preview ladi-date-picker-icon"
                                        style={{ position: 'absolute', bottom: '10px', right: '12px', cursor: 'pointer' }}
                                        onClick={() => setInputTypePasswordRepeat(!inputTypePasswordRepeat)}
                                    ></i> */}
                                </div>
                                <div className="ladiui form-group">
                                    <button className="ladiui btn btn-primary right" onClick={updateAccountPassword}>Cập nhật</button>
                                    <button className="ladiui btn right btn-secondary" style={{ marginRight: '40px' }} onClick={cbLoadData}>Hủy bỏ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(AffiliateAccount));
