import { isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import actionAppIntegrate from '../../../../redux/futures/integrate_app/actions';

function FormHaravan(props) {
    const { t, dataApp, cb } = props;
    const isCreate = props.isCreate === undefined ? true : props.isCreate;

    const dispatch = useDispatch();
    const [appIntegrate, setAppIntegrate] = useState({});
    const [isConnect, setIsConnect] = useState(false);
    const [openWindowsConfirm, setOpenWindowsConfirm] = useState(false);

    const urlOauthHaraval = useSelector((state) => state.appIntegrate.urlHaravan);

    const eventChangeData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = {};
        data[name] = value;
        setAppIntegrate({ ...appIntegrate, ...data });
        if (isFunction(cb)) cb(data);
    };

    const eventListenerHaravan = (e) => {
        const data = e.data;
        if (data.accessToken && data.shopID) {
            const dataAccess = { token: data.accessToken, shop_id: data.shopID };
            setIsConnect(true);
            setAppIntegrate({ ...appIntegrate, ...dataAccess });
            if (isFunction(cb)) cb(dataAccess);
        }
    };

    const cbOpenWindowConfirm = () => {
        setOpenWindowsConfirm(true);
    };

    const eventConnect = () => {
        if (isConnect) {
            setIsConnect(false);
        } else if (appIntegrate.api_url) {
            setOpenWindowsConfirm(false);
            dispatch(actionAppIntegrate.integrateAppHaravan({}, cbOpenWindowConfirm));
        }
    };

    useEffect(() => {
        if (dataApp) setAppIntegrate(dataApp);
    }, [dataApp]);

    useEffect(() => {
        if (!openWindowsConfirm || !urlOauthHaraval) return;
        const width = 500;
        const height = 650;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);
        const targetWin = window.open(urlOauthHaraval,
            'popupAuthHaravan', `width=${width}, height=${height}, left=${left}, top=${top}`);
        targetWin.focus();
        window.addEventListener('message', eventListenerHaravan, false);
    }, [urlOauthHaraval, openWindowsConfirm]);

    return <>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="name">Tên</label>
            <input id="name" name="name" className="ladiui form-control" placeholder="Nhập Tên"
                value={appIntegrate.name || ''}
                onChange={(e) => eventChangeData(e)}
            />
        </div>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="app_token">App token</label>
            <input id="app_token" name="app_token" className="ladiui form-control" placeholder="Nhập app token"
                value={appIntegrate.app_token || ''}
                onChange={(e) => eventChangeData(e)}
            />
            <span style={{ display: 'block', marginTop: '5px', fontSize: '12px' }}>* App token dùng để đồng bộ mã khuyến mãi lên trên website</span>
        </div>
        <div className="ladiui form-group">
            <label className="ladiui title-text-form-lever2" htmlFor="api_url">URL cửa hàng</label>
            <div className="ladiui flex-row">
                <input id="api_url" name="api_url" className="ladiui form-control mr-8" placeholder="Nhập URL cửa hàng"
                    value={appIntegrate.api_url || ''}
                    onChange={(e) => eventChangeData(e)}
                    readOnly={isCreate ? isConnect : true}
                />
                {isCreate ? <button className="ladiui btn btn-primary" onClick={() => eventConnect()}>{isConnect ? 'Kết nối lại' : 'Kết nối'}</button> : <></>}
            </div>
        </div>
    </>;
}

export default (withTranslation()(FormHaravan));
