import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal';
import LadiPagination from '../../../components/LadiPagination';

import appConfig from '../../../config/app';
import config from '../../../config/config';
import BaseHelper from '../../../helpers/BaseHelper';
import actionCommission from '../../../redux/futures/commission/actions';
import actionStore from '../../../redux/futures/store/actions';
import DefineConst from '../DefineConst';
import CheckAllActionCommission from './CheckAllActionCommission';
import DetailCommission from './DetailCommission';

// eslint-disable-next-line max-lines-per-function
function ListCommission(props) {
    const {
        t, setIsEdit, conditionsProp, conditionActionProp, selectedIdsProp, setSelectedIdsProp,
    } = props;

    const dispatch = useDispatch();
    const [selectedIds, setSelectedIds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const [commission, setCommission] = useState();
    const [reloadList, setReloadList] = useState(false);
    const [requestRecheckNote, setRequestRecheckNote] = useState(null);

    const loading = useSelector((state) => (state.commissions.loading));
    const listCommission = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.commissions.commissions }));
    const affiliates = useSelector((state) => (state.commissions.affiliates));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));
    const setting = useSelector((state) => (state.store.affiliateSetting));

    const cbLoad = () => {
        dispatch(actionCommission.list(conditionsProp));
    };

    const selectLimit = (number) => {
        conditionActionProp({ limit: number, page: 1 });
    };

    const selectPage = (number) => {
        conditionActionProp({ page: number });
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
        setSelectedIdsProp(ids);
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setSelectedIds(ids);
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
        setSelectedIdsProp(ids);
    };

    const eventDetailCommission = (item) => {
        setIsEdit(true);
        setCommission(item);
        window.LadiUI.showModal('modal-detail-commission');
    };

    const eventUpdateStatusApprovedCommission = (item) => {
        setIsEdit(true);
        setCommission(item);
        window.LadiUI.showModal('modal-confirm-approved-commission');
    };

    const eventOkUpdateStatusApprovedCommission = () => {
        dispatch(actionCommission.approveCommission({ id: commission._id }, cbLoad));
    };

    const eventBackToPending = (item) => {
        setIsEdit(true);
        setCommission(item);
        window.LadiUI.showModal('modal-back-to-pending-commission');
    };

    const eventOkBackToPendingCommission = () => {
        dispatch(actionCommission.backToPending({ id: commission._id }, cbLoad));
    };

    const eventUpdateStatusCancelCommission = (item) => {
        setIsEdit(true);
        setCommission(item);
        window.LadiUI.showModal('modal-confirm-cancel-commission');
    };

    const eventOkUpdateStatusCancelCommission = () => {
        dispatch(actionCommission.cancelCommission({ id: commission._id }, cbLoad));
    };

    const eventInvoiceCommission = (item) => {
        setIsEdit(true);
        setCommission(item);
        window.LadiUI.showModal('modal-confirm-invoice-commission');
    };

    const eventOkInvoiceCommission = () => {
        dispatch(actionCommission.invoiceCommission({ id: commission._id }, cbLoad));
    };

    const eventConfirmCommission = (item) => {
        setIsEdit(true);
        setCommission(item);
        window.LadiUI.showModal('modal-confirm-confirm-commission');
    };

    const eventOkConfirmCommission = () => {
        dispatch(actionCommission.confirmCommission({ id: commission._id, is_confirm: 1 }, cbLoad));
    };

    const eventRequestReCheckCommission = (item) => {
        setIsEdit(true);
        setCommission(item);
        setRequestRecheckNote(item.recheck_content || '');
        window.LadiUI.showModal('modal-request-re-check-commission');
    };

    const eventCancelRequestReCheckCommission = () => {
        window.LadiUI.closeModal('modal-request-re-check-commission');
        setRequestRecheckNote(null);
    };

    const cbRecheckCommission = () => {
        window.LadiUI.closeModal('modal-request-re-check-commission');
        setRequestRecheckNote(null);
        cbLoad();
    };

    const eventOkRequestReCheckCommission = () => {
        const data = {
            note: requestRecheckNote,
            id: commission._id,
        };
        dispatch(actionCommission.recheckCommission(data, cbRecheckCommission));
    };

    const eventEditCommission = (item) => {
        setIsEdit(true);
        dispatch(actionCommission.show(item._id));
        window.LadiUI.showModal('modal-commission-custom');
    };

    const showModalRecheckContent = (item) => {
        setIsEdit(true);
        setCommission(item);
        window.LadiUI.showModal('modal-recheck-content');
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.COMMISSION_STATUS.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-warning"></i>
                        <div>
                            {t('COMMISSIONS.COMMISSION_STATUS.PENDING')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.COMMISSION_STATUS.APPROVED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {t('COMMISSIONS.COMMISSION_STATUS.APPROVED')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.COMMISSION_STATUS.INVOICED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {t('COMMISSIONS.COMMISSION_STATUS.INVOICED')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.COMMISSION_STATUS.CANCELED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-danger"></i>
                        <div>
                            {t('COMMISSIONS.COMMISSION_STATUS.CANCELED')}
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    const getConditionInvoice = (item) => {
        if (item.status !== DefineConst.COMMISSION_STATUS.APPROVED) {
            return false;
        }
        if (setting && setting.allow_invoice_commission_without_confirm === true) {
            return true;
        }
        if (item.is_confirm === true) {
            return true;
        }
        return false;
    };

    const renderRecord = (item) => <tr className="ladiui table-vertical main" key={item._id}>
        <td scope="row">
            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element" name="checkbox" checked={selectedIds.includes(item._id)}
                value={item._id} onChange={eventCheck} />
        </td>
        <td><a href="#/" className="ladiui link"
            style={{ display: 'block', overflow: 'hidden', maxWidth: '120px', textOverflow: 'ellipsis' }}
            onClick={() => eventDetailCommission(item)}>{item.data.code || item._id}</a></td>
        <td>
            {affiliates.map((affiliate) => (affiliate._id === item.affiliate_id
                ? config.APP_AFFILIATE
                    ? <div key={affiliate._id}>{affiliate.code}</div>
                    : <Link key={affiliate._id} to={`/affiliates/${affiliate._id}`} className="ladiui link">{affiliate.code}</Link>
                : ''))}
        </td>
        <td>{item.customer ? item.customer.email : ''}</td>
        <td>{getStatus(item.status)}</td>
        <td className="ladiui text-pre">{appConfig.LADISHARE.COMMISSION_TYPE.map((type) => (type.value === item.type ? type.name : ''))}</td>
        <td className="ladiui text-center">
            {item.is_confirm
                ? <img src="https://w.ladicdn.com/ladiui/icons/ldicon-colored-circle-check.svg" width={15} />
                : item.is_recheck ? <img src="https://w.ladicdn.com/ladiui/icons/ldicon-colored-warning.svg" width={15}
                    onClick={() => showModalRecheckContent(item)}
                    style={{ cursor: 'pointer' }} /> : '-'}
        </td>
        <td className="ladiui text-center">
            {item.is_paid
                ? <img src="https://w.ladicdn.com/ladiui/icons/ldicon-colored-circle-check.svg" width={15} />
                : <></>}
        </td>
        <td>{BaseHelper.formatStrToDate(item.commission_date, 'DD/MM/YYYY')}</td>
        <td>{BaseHelper.formatStrToDate(item.created_at, 'DD/MM/YYYY')}</td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(item.total, '', systemCurrency)}</td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(item.amount, '', systemCurrency)}</td>
        <td className="ladiui text-right">{BaseHelper.formatMoney(item.bonus, '', systemCurrency)}</td>
        <td className="ladiui-dropdown">
            <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                    <button data-toggle="dropdown"
                        className="ladiui-btn-dropdown dropdown-toggle ml-24">
                        <i className="ladiui icon icon-ldp-dot"></i>
                    </button>
                    <ul className="ladiui dropdown-menu custom-dropdown-menu"
                        aria-labelledby="dropdownMenuButton1">
                        <li onClick={() => eventDetailCommission(item)}>
                            <a className="ladiui dropdown-item" href="#/">
                                {t('COMMISSIONS.DETAIL_COMMISSION')}
                            </a>
                        </li>
                        {!config.APP_AFFILIATE
                            ? <>
                                {item.create_type === 'CUSTOM' && item.status !== 'INVOICED' && !item.is_confirm
                                    ? < li onClick={() => eventEditCommission(item)}>
                                        <a className="ladiui dropdown-item" href="#/">
                                            Chỉnh sửa hoa hồng
                                        </a>
                                    </li>
                                    : <></>}

                                {getConditionInvoice(item)
                                    ? <li onClick={() => eventInvoiceCommission(item)}>
                                        <a className="ladiui dropdown-item" href="#/">
                                            {t('COMMISSIONS.COMMISSION_STATUS.INVOICED')}
                                        </a>
                                    </li> : <></>
                                }
                                {item.status === DefineConst.COMMISSION_STATUS.PENDING || item.status === DefineConst.COMMISSION_STATUS.INVOICE_CANCEL
                                    ? <li onClick={() => eventUpdateStatusApprovedCommission(item)}>
                                        <a className="ladiui dropdown-item" href="#/">
                                            {t('COMMISSIONS.ACCEPT_COMMISSION')}
                                        </a>
                                    </li> : <></>
                                }
                                {item.status === DefineConst.COMMISSION_STATUS.INVOICED
                                    ? <li onClick={() => eventBackToPending(item)}>
                                        <a className="ladiui dropdown-item" href="#/">
                                            Chuyển lại chờ duyệt
                                        </a>
                                    </li> : <></>
                                }
                                {item.status === DefineConst.COMMISSION_STATUS.PENDING || item.status === DefineConst.COMMISSION_STATUS.APPROVED
                                    ? <li onClick={() => eventUpdateStatusCancelCommission(item)}>
                                        <a className="ladiui dropdown-item" href="#/">
                                            {t('COMMISSIONS.REFUSE_COMMISSION')}
                                        </a>
                                    </li> : <></>
                                }</>
                            : <></>}

                        {config.APP_AFFILIATE
                            ? <>{item.status === DefineConst.COMMISSION_STATUS.APPROVED && !item.is_confirm
                                ? <>
                                    <li onClick={() => eventConfirmCommission(item)}>
                                        <a className="ladiui dropdown-item" href="#/">
                                            Đồng ý đối soát
                                        </a>
                                    </li>
                                    <li onClick={() => eventRequestReCheckCommission(item)}>
                                        <a className="ladiui dropdown-item" href="#/">
                                            Yêu cầu xem xét lại
                                        </a>
                                    </li>
                                </> : <></>
                            }</>
                            : <></>}

                    </ul>
                </div>
            </div>
        </td>
    </tr >;

    useEffect(() => {
        const data = {
            field: 'affiliate_setting',
        };
        dispatch(actionStore.getAffiliateSetting(data));
    }, []);

    useEffect(() => {
        dispatch(actionCommission.list(conditionsProp));
        setSelectedIds([]);
        setCheckAll(false);
    }, [conditionsProp]);

    useEffect(() => {
        setSelectedIds(selectedIdsProp);
    }, [selectedIdsProp]);

    useEffect(() => {
        if (loading === false && reloadList === true) {
            dispatch(actionCommission.list(conditionsProp));
            setReloadList(false);
        }
    }, [loading]);

    return <>
        <div className="ladiui mobile-table-scroll">
            <table className="ladiui table text-left" style={{ marginTop: '15px', minWidth: '1240px' }}>
                <thead>
                    <tr className="ladiui table-vertical header">
                        <th className="ladiui col-first" scope="col">
                            <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-commission"
                                name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                        </th>
                        <th className="col-action" style={{ width: '170px' }}>
                            {selectedIds.length > 0 ? <CheckAllActionCommission ids={selectedIds} cb={() => setReloadList(true)} /> : t('COMMISSIONS.REFERENCE_NO')}</th>
                        <th>
                            {t('AFFILIATES.CODE_AFFILIATE')}
                        </th>
                        <th>Email khách hàng</th>
                        <th style={{ width: '98px' }}>{t('COMMISSIONS.STATUS')}</th>
                        <th scope="col">{t('COMMISSIONS.TYPE')}</th>
                        <th className="ladiui text-center">Đối soát</th>
                        <th className="ladiui text-center">Thanh toán</th>
                        <th>{t('COMMISSIONS.COMMISSION_DATE')}</th>
                        <th>{t('COMMISSIONS.CREATED_DATE')}</th>
                        <th scope="col" className="ladiui text-right">{t('COMMISSIONS.TOTAL_CURRENCY')}</th>
                        <th scope="col" className="ladiui text-right">{t('COMMISSIONS.COMMISSION')}</th>
                        <th scope="col" className="ladiui text-right">Thưởng</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {listCommission.items.length > 0
                        ? listCommission.items.map((values) => (renderRecord(values)))
                        : (<tr className="ladiui table-vertical main"><td colSpan="9" className="text-center"><div>{t('COMMISSIONS.NO_COMMISSION')}</div></td></tr>)}
                </tbody>
                <tfoot>
                    <tr className="ladiui table-vertical summary">
                        <td colSpan={config.APP_AFFILIATE ? 8 : 8}><span className="bold-500 ml-32">{t('AFFILIATES.TOTAL_ALL')}</span></td>
                        <td className="ladiui text-right text-pre" colSpan="2">
                            <span className="total-record">{t('COMMON.NUMBER_RECORD')}:&nbsp;</span>
                            <span className="bold-500">
                                {listCommission.summary.total ? listCommission.summary.total : 0}
                            </span>
                        </td>
                        <td className="ladiui text-right text-pre">
                            <span className="bold-500">
                                {listCommission.summary.salesTotal
                                    ? BaseHelper.formatMoney(listCommission.summary.salesTotal, '', systemCurrency)
                                    : BaseHelper.formatMoney(0, '', systemCurrency)}
                            </span>
                        </td>
                        <td className="ladiui text-right text-pre">
                            <span className="bold-500">
                                {listCommission.summary.commissionTotal
                                    ? BaseHelper.formatMoney(listCommission.summary.commissionTotal, '', systemCurrency)
                                    : BaseHelper.formatMoney(0, '', systemCurrency)}
                            </span>
                        </td>
                        <td className="ladiui text-right text-pre">
                            <span className="bold-500">
                                {listCommission.summary.commissionBonusTotal
                                    ? BaseHelper.formatMoney(listCommission.summary.commissionBonusTotal, '', systemCurrency)
                                    : BaseHelper.formatMoney(0, '', systemCurrency)}
                            </span>
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
        {listCommission.items.length > 0
            ? <LadiPagination
                conditionsProp={conditionsProp}
                listName={t('COMMISSIONS.COMMISSION')}
                items={listCommission.items}
                total={listCommission.total}
                limitAction={selectLimit}
                pageAction={selectPage}
            /> : ''}
        <DetailCommission idProp={commission ? commission._id : null} setReloadList={setReloadList} />

        <ConfirmModal
            id="modal-confirm-invoice-commission"
            title='Bạn có chắc chắn muốn thanh toán hoa hồng được chọn?'
            content={<div>Những Hoa hồng đã được affiliate đối soát thành công mới được phép chuyển sang trạng thái duyệt.
            </div>}
            onOk={() => eventOkInvoiceCommission()}
            cancelText={t('COMMISSIONS.CANCELED')}
            okText={t('COMMISSIONS.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="modal-confirm-confirm-commission"
            title='Bạn có chắc chắn muốn đối soát hoa hồng được chọn?'
            content={<div>Những Hoa hồng đã được tạm duyệt mới được phép chuyển sang trạng thái đối soát.
            </div>}
            onOk={() => eventOkConfirmCommission()}
            cancelText={t('COMMISSIONS.CANCELED')}
            okText={t('COMMISSIONS.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="modal-confirm-approved-commission"
            title='Bạn có chắc chắn muốn tạm duyệt hoa hồng được chọn?'
            content={<div>Những Hoa hồng ở trạng thái chờ duyệt mới được phép chuyển sang trạng thái tạm duyệt.
            </div>}
            onOk={() => eventOkUpdateStatusApprovedCommission()}
            cancelText={t('COMMISSIONS.CANCELED')}
            okText={t('COMMISSIONS.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="modal-confirm-cancel-commission"
            title='Bạn có chắc chắn muốn huỷ hoa hồng được chọn?'
            content={<div>Những Hoa hồng ở trạng thái chờ duyệt mới được phép huỷ.
            </div>}
            onOk={() => eventOkUpdateStatusCancelCommission()}
            cancelText={t('COMMISSIONS.CANCELED')}
            okText={t('COMMISSIONS.OK')}
            classBtn="btn-primary"
        />
        <ConfirmModal
            id="modal-back-to-pending-commission"
            title='Bạn muốn chuyển hoa hồng về trạng thái chờ duyệt?'
            content={<div>Những hoa hồng đã lên hoá đơn sẽ không thể đưa về chờ duyệt. Trạng thái đã đối soát sẽ bị huỷ bỏ.
            </div>}
            onOk={() => eventOkBackToPendingCommission()}
            cancelText={t('COMMISSIONS.CANCELED')}
            okText={t('COMMISSIONS.OK')}
            classBtn="btn-primary"
        />

        <div className="ladiui modal" id="modal-request-re-check-commission">
            <div className="ladiui modal-dialog modal-dialog-centered modal-lg">
                <div className="ladiui modal-content custom-ldp" style={{ fontWeight: '400' }}>
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">Yêu cầu kiểm tra lại hoa hồng</label>
                        <button type="button" data-dismiss="modal" data-target="modal-export-commission" className="ladiui modal-close" onClick={eventCancelRequestReCheckCommission}>
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>

                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2">Nội dung yêu cầu xem xét lại:</label>
                        <textarea className="ladiui form-control" placeholder="Nhập nội dung" rows={3}
                            value={requestRecheckNote || ''}
                            onChange={(e) => setRequestRecheckNote(e.target.value)}
                        />
                    </div>
                    <div className="ladiui modal-footer custom-ldp">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <button type="button" className="ladiui btn btn-secondary bold" onClick={eventCancelRequestReCheckCommission}>{t('COMMISSIONS.CANCELED')}</button>
                            <button type="button" className="ladiui btn btn-primary" onClick={() => eventOkRequestReCheckCommission()}>Yêu cầu xem xét</button>
                        </div>

                    </div>
                </div>
            </div>
        </div >

        <div className="ladiui modal" id="modal-recheck-content">
            <div className="ladiui modal-dialog modal-dialog-centered">
                <div className="ladiui modal-content">
                    <div className="ladiui modal-header">
                        <label className="ladiui title-text-form-lever1">Nội dung phản hồi</label>
                        <button data-dismiss="modal" data-target="smallModal"
                            className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui modal-body">
                        <div style={{ lineHeight: '24px', whiteSpace: 'pre-wrap' }}>
                            {commission ? commission.recheck_content : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(ListCommission));
