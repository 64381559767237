import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BaseHelper from '../../../helpers/BaseHelper';
import DefineConst from '../DefineConst';

import actionCommission from '../../../redux/futures/commission/actions';
import InfoOrder from './InfoOrder';
import config from '../../../config/config';

// eslint-disable-next-line max-lines-per-function
function DetailCommission(props) {
    const { t, idProp, setReloadList } = props;

    const dispatch = useDispatch();
    const commission = useSelector((state) => (state.commissions.commission));
    const commissionPlan = useSelector((state) => (state.commissions.commissionPlan));
    const commissionPlanPayment = useSelector((state) => (state.commissions.commissionPlanPayment));
    const histories = useSelector((state) => (state.commissions.histories));
    const affiliate = useSelector((state) => (state.commissions.affiliate));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const loadCommission = () => {
        dispatch(actionCommission.show(idProp));
    };

    const eventApproveCommission = () => {
        dispatch(actionCommission.approveCommission({ id: commission._id }, loadCommission));
        setReloadList(true);
        window.LadiUI.closeModal('modal-detail-commission');
    };

    const eventCancelCommission = () => {
        dispatch(actionCommission.cancelCommission({ id: commission._id }, loadCommission));
        setReloadList(true);
        window.LadiUI.closeModal('modal-detail-commission');
    };

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.COMMISSION_STATUS.PENDING:
                statusContent = <>
                    <span className='ladiui badge badge-warning'>
                        {t('COMMISSIONS.COMMISSION_STATUS.PENDING')}
                    </span>
                </>;
                break;
            case DefineConst.COMMISSION_STATUS.APPROVED:
                statusContent = <>
                    <span className='ladiui badge badge-success'>
                        {t('COMMISSIONS.COMMISSION_STATUS.APPROVED')}
                    </span>
                </>;
                break;
            case DefineConst.COMMISSION_STATUS.INVOICED:
                statusContent = <>
                    <span className='ladiui badge badge-success'>
                        {t('COMMISSIONS.COMMISSION_STATUS.INVOICED')}
                    </span>
                </>;
                break;
            case DefineConst.COMMISSION_STATUS.CANCELED:
                statusContent = <>
                    <span className='ladiui badge badge-mute'>
                        {t('COMMISSIONS.COMMISSION_STATUS.CANCELED')}
                    </span>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    const getCreateType = (type) => {
        switch (type) {
            case 'CUSTOM': return 'Thủ công';
            case 'CUSTOM_AFF_LEVEL': return 'Thủ công cấp trên';
            case 'AUTO': return 'Tự động';
            case 'AUTO_AFF_LEVEL': return 'Tự động cấp trên';
            default: return '';
        }
    };

    const getPlanPaymentValue = (planPayment, commission) => {
        const paymentType = planPayment ? planPayment.payment_type : commission.plan_payment_type;
        const paymentValue = planPayment ? planPayment.payment_value : commission.plan_payment_value;

        if (paymentType === DefineConst.PAYMENT_TYPE.PAYMENT_TYPE_PERCENT) {
            return `${BaseHelper.formatNumber(paymentValue || 0)}%`;
        }
        return BaseHelper.formatMoney(paymentValue || 0);
    };

    useEffect(() => {
        if (idProp) loadCommission();
    }, [idProp]);

    return commission ? <>
        <div className="ladiui modal" id="modal-detail-commission">
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">Chi tiết hoa hồng {commission && commission.data ? (commission.data.code || commission._id) : ''}</label>
                        <button type="button" data-dismiss="modal" data-target="modal-export-commission" className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="content-detail-commission-value">
                        <div className="detail-info-commission">
                            <label className="label-info">Thông tin Affiliate</label>
                            <div className="mt-4"><span className="label-info-2">Mã:</span> {affiliate ? affiliate.code : ''}</div>
                            <div className="mt-4"><a className="ladiui link" href={affiliate ? `/affiliates/${affiliate._id}` : '#'}>{affiliate ? affiliate.fullname : ''}</a></div>
                            <div className="mt-4">{affiliate ? affiliate.email : ''}</div>
                            <div className="label-info-2 mt-24">Trạng thái hoa hồng</div>
                            <div className="mt-4">{getStatus(commission.status)}</div>
                        </div>
                        <div className="detail-total-commission">
                            <div className="mt-8">
                                <label className="label-info-2">Hoa hồng</label>
                                <div>{commission.amount ? BaseHelper.formatMoney(commission.amount, '', systemCurrency) : BaseHelper.formatMoney(0, '', systemCurrency)}</div>
                            </div>
                            <div className="mt-8">
                                <label className="label-info-2">Hoa hồng thưởng</label>
                                <div>{commission.bonus ? BaseHelper.formatMoney(commission.bonus, '', systemCurrency) : BaseHelper.formatMoney(0, '', systemCurrency)}</div>
                            </div>
                            <div className="detail-total-commission-value-group">
                                <label className="label-info">Tổng</label>
                                <div className="detail-total-commission-value">{commission ? BaseHelper.formatMoney(parseFloat(commission.amount || 0) + parseFloat(commission.bonus || 0), '', systemCurrency) : BaseHelper.formatMoney(0, '', systemCurrency)}</div>
                            </div>
                        </div>
                    </div>

                    <div className="ladiui flex-row flex-item-detail-commission">
                        {commission.create_type === 'AUTO'
                            ? <div className="ladiui item-detail-commission">
                                <label className="ladiui title-text-form-lever2" >Gói hoa hồng</label>
                                <div>{commissionPlan ? commissionPlan.name : ''}</div>
                            </div>
                            : <div className="ladiui item-detail-commission">
                                <label className="ladiui title-text-form-lever2" >Hình thức hoa hồng</label>
                                <div>{getCreateType(commission.create_type)}</div>
                            </div>}
                        <div className="ladiui item-detail-commission">
                            <label className="ladiui title-text-form-lever2" >Mức hoa hồng</label>
                            <div>
                                {commission.create_type === 'AUTO'
                                    ? <>
                                        {getPlanPaymentValue(commissionPlanPayment, commission)}
                                    </> : <>
                                        {commission.plan_payment_type === DefineConst.PAYMENT_TYPE.PAYMENT_TYPE_PERCENT
                                            ? `${BaseHelper.formatNumber(commission.plan_payment_value || 0)}%`
                                            : BaseHelper.formatMoney(commission.plan_payment_value || 0)}
                                    </>}
                            </div>
                        </div>
                        <div className="ladiui item-detail-commission">
                            <label className="ladiui title-text-form-lever2" >Loại hoa hồng</label>
                            <div>
                                {DefineConst.PAYMENT_FOR_NAME[commission.type]}
                            </div>
                        </div>
                        {commission && commission.note && commission.note !== ''
                            ? <div className="ladiui item-detail-commission">
                                <label className="ladiui title-text-form-lever2" >Ghi chú</label>
                                <div>{commission ? commission.note : ''}</div>
                            </div> : <></>}
                    </div>
                    {commission && commission.type !== 'KPIS'
                        ? <InfoOrder key={commission._id} order={commission.data} commission={commission} customer={commission.customer || {}} product={commission.product || {}} commissionHistories={histories} />
                        : <></>}

                    {!config.APP_AFFILIATE && (commission.status === DefineConst.COMMISSION_STATUS.PENDING || commission.status === DefineConst.COMMISSION_STATUS.INVOICE_CANCEL)
                        ? <div className="ladiui modal-footer custom-ldp">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button type="button"
                                    className="ladiui btn btn-danger bold"
                                    onClick={() => eventCancelCommission()}>{t('COMMISSIONS.REFUSE_COMMISSION')}</button>
                                <button type="button" className="ladiui btn btn-primary"
                                    onClick={() => eventApproveCommission()}>{t('COMMISSIONS.ACCEPT_COMMISSION')}</button>
                            </div>

                        </div>
                        : <></>
                    }
                </div>
            </div>
        </div >
    </> : <><div className="ladiui modal" id="modal-detail-commission"></div></>;
}
export default (withTranslation()(DetailCommission));
