/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseHelper from '../../../helpers/BaseHelper';
import DefineConst from '../DefineConst';

function InfoOrder(props) {
    const {
        t, order, customer, commission, product, commissionHistories,
    } = props;

    const [orderObject, setOrderObject] = useState({});
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const getNameOwner = (item) => {
        let name = 'Hệ thống';
        if (item && item.staff) {
            name = [item.staff.first_name, item.staff.last_name].join(' ');
            if (name.trim() === '') {
                name = 'Hệ thống';
            }
        }
        return name;
    };

    const getContentHistory = (item) => {
        if (!item || !item.data) return '';

        const data = JSON.parse(item.data);
        let content = <></>;
        switch (item.action) {
            case 'COMMISSION_CREATE':
                content = <>Tạo hoa hồng</>;
                break;
            case 'COMMISSION_UPDATE':
                content = <>Cập nhật hoa hồng</>;
                break;
            case 'COMMISSION_DELETE':
                content = <>Xoá hoa hồng</>;
                break;
            case 'COMMISSION_EXPORT':
                content = <>Xuất danh sách hoa hồng</>;
                break;
            case 'COMMISSION_UPDATE_STATUS':
                content = <>Chuyển trạng thái từ {DefineConst.COMMISSION_STATUS_NAME[data.prev_status]} sang {DefineConst.COMMISSION_STATUS_NAME[data.current_status]}</>;
                break;
            case 'COMMISSION_INVOICED':
                content = <>Duyệt hoa hồng</>;
                break;
            case 'COMMISSION_CONFIRM':
                content = <>Chấp nhận đối soát</>;
                break;
            case 'COMMISSION_CREATE_INVOICED':
                content = <>Lên hoá đơn cho hoa hồng</>;
                break;
            case 'COMMISSION_RECHECK':
                content = <>Yêu cầu kiểm tra lại hoa hồng</>;
                break;
            default:
        }
        return content;
    };

    useEffect(() => {
        setOrderObject(order || {});
    }, [order]);

    return <>
        <div className="ladiui mt-8">
            <ul className="ladiui nav nav-tabs new-tab-custom tab-detail-commission" id="myTab" role="tablist">
                {![DefineConst.COMMISSION_TYPE.NEW_CUSTOMER, DefineConst.COMMISSION_TYPE.LEAD].includes(commission.type)
                    ? <li className="ladiui nav-item" role="presentation">
                        <span className="ladiui tab-link active" id="commission-tab" data-toggle="tab"
                            data-parent="myTab" data-target="commission">Chi tiết đơn hàng</span>
                    </li> : <></>}
                {[DefineConst.COMMISSION_TYPE.PER_PRODUCT].includes(commission.type)
                    ? <li className="ladiui nav-item" role="presentation">
                        <span className="ladiui tab-link" id="per_product-tab" data-toggle="tab"
                            data-parent="myTab" data-target="per_product">Thông tin sản phẩm</span>
                    </li> : <></>}
                <li className="ladiui nav-item" role="presentation">
                    <span className={`ladiui tab-link ${[DefineConst.COMMISSION_TYPE.NEW_CUSTOMER, DefineConst.COMMISSION_TYPE.LEAD].includes(commission.type) ? 'active' : ''}`} id="infoCustomer-tab" data-toggle="tab"
                        data-parent="myTab" data-target="infoCustomer">Khách hàng</span>
                </li>
                <li className="ladiui nav-item" role="presentation">
                    <span className="ladiui tab-link" id="commissionHistory-tab" data-toggle="tab"
                        data-parent="myTab" data-target="commissionHistory">Lịch sử hoa hồng</span>
                </li>
            </ul>
            <div className="ladiui tab-content" id="myTabContent">
                {![DefineConst.COMMISSION_TYPE.NEW_CUSTOMER, DefineConst.COMMISSION_TYPE.LEAD].includes(commission.type)
                    ? <div className="ladiui tab-pane fade show open" id="commission" role="tabpanel"
                        aria-labelledby="commission-tab">
                        <div className="ladiui">
                            <div className="ladiui flex-item-detail-commission">
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.ORDER_CODE')}</label>
                                    <div>{orderObject.code || commission._id}</div>
                                </div>
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.ORDER_PRICE')}</label>
                                    <div>{BaseHelper.formatMoney(orderObject.total || 0, '', systemCurrency)}</div>
                                </div>
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.ORDER_SOURCE')}</label>
                                    <div>{orderObject.source || ' '}</div>
                                </div>
                            </div>
                            <div className="ladiui flex-row flex-item-detail-commission">
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.ORDER_TIME')}</label>
                                    <div>{BaseHelper.formatStrToDate(orderObject.order_date)}</div>
                                </div>
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.ORDER_METHOD')}</label>
                                    <div>{orderObject.method}</div>
                                </div>
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >{t('COMMISSIONS.ORDER_IP')}</label>
                                    <div>{orderObject.ip_address}</div>
                                </div>
                            </div>
                            <div className="ladiui flex-row flex-item-detail-commission">
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >Shop ID</label>
                                    <div>{commission.shop_id}</div>
                                </div>
                            </div>

                        </div>
                    </div> : <></>}

                {[DefineConst.COMMISSION_TYPE.PER_PRODUCT].includes(commission.type)
                    ? <div className="ladiui tab-pane fade" id="per_product" role="tabpanel"
                        aria-labelledby="per_product-tab">
                        <div className="ladiui">
                            <div className="ladiui d-grid-2">
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >Mã sản phẩm</label>
                                    <div>{product.sku}</div>
                                </div>
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >Tên sản phẩm</label>
                                    <div>{product.name}</div>
                                </div>
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >Giá</label>
                                    <div>{product.price}</div>
                                </div>
                                <div className="ladiui item-detail-commission">
                                    <label className="ladiui title-text-form-lever2" >Số lượng</label>
                                    <div>{product.quantity}</div>
                                </div>
                            </div>
                        </div>
                    </div> : <></>}

                <div className={`ladiui tab-pane fade ${[DefineConst.COMMISSION_TYPE.NEW_CUSTOMER, DefineConst.COMMISSION_TYPE.LEAD].includes(commission.type) ? 'show open' : ''}`} id="infoCustomer" role="tabpanel"
                    aria-labelledby="infoCustomer-tab">
                    <div className="ladiui">
                        <div className="ladiui flex-item-detail-commission">
                            <div className="ladiui item-detail-commission">
                                <label className="ladiui title-text-form-lever2" >Email</label>
                                <div>{customer.email}</div>
                            </div>
                            <div className="ladiui item-detail-commission">
                                <label className="ladiui title-text-form-lever2" >Tên khách hàng</label>
                                <div>{customer.name}</div>
                            </div>
                            <div className="ladiui item-detail-commission">
                                <label className="ladiui title-text-form-lever2" >Số điện thoại</label>
                                <div>{customer.phone}</div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="ladiui tab-pane fade" id="commissionHistory" role="tabpanel"
                    aria-labelledby="commissionHistory-tab">
                    <div className="ladiui">
                        <div className="ladiui table-scroll">
                            <table className="ladiui table text-left table-padding" style={{ marginTop: 0, minWidth: '400px' }}>
                                <thead>
                                    <tr className="ladiui table-vertical header">
                                        <th className="ladiui text-pre col-first" scope="col">Thời gian</th>
                                        <th className="ladiui text-pre">Hành động</th>
                                        <th className="ladiui text-pre">Người thực hiện</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {commissionHistories.map((item) => <tr className="ladiui table-vertical main" key={item._id}>
                                        <td className="ladiui text-pre">{BaseHelper.formatStrToDate(item.created_at)}</td>
                                        <td>{getContentHistory(item)}</td>
                                        <td className="ladiui text-pre">{getNameOwner(item)}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(InfoOrder));
