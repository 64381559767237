import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LadiDropdown from '../../components/LadiDropdown';
import SearchCondition from '../apps/components/SearchCondition';
import DefineConst from './DefineConst';
import actionAffiliateGroup from '../../redux/futures/affiliate_group/actions';
import actionCampaign from '../../redux/futures/campaign/actions';
import ModalCampaignMail from './components/ModalCampaignMail';
import ModalCampaignBanner from './components/ModalCampaignBanner';
import ModalCampaignSociety from './components/ModalCampaignSociety';
import BaseHelper from '../../helpers/BaseHelper';
import LadiPagination from '../../components/LadiPagination';
import ConfirmModal from '../../components/ConfirmModal';
import config from '../../config/config';

// eslint-disable-next-line max-lines-per-function
function CampaignAdvertiser(props) {
    const { t } = props;

    const defaultCondtions = {
        limit: 10,
        page: 1,
        sort: { is_priority: -1, created_at: -1 },
    };

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState(defaultCondtions);
    const [campaign, setCampaign] = useState({});
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const listCampaign = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.campaigns.campaigns }));

    const cbList = () => {
        dispatch(actionCampaign.list(conditions));
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) setConditions({ ...conditions, ...{ keyword: e.target.value, page: 1 } });
    };

    const eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        setConditions({ ...conditions, ...{ keyword: keywordSearch, page: 1 } });
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventSelectApp = (data) => {
        setConditions({ ...conditions, type: data });
    };

    const eventSelectPlatform = () => {
        window.LadiUI.showModal('modal-select-platform');
    };

    const eventSelectCampaign = (type) => {
        let modalCampaign;
        window.LadiUI.closeModal('modal-select-platform');
        const docCampaign = {};
        switch (type) {
            case DefineConst.TYPE_CAMPAIGN.SOCIETY:
                modalCampaign = 'modal-campaign-society';
                break;
            case DefineConst.TYPE_CAMPAIGN.BANNER:
                modalCampaign = 'modal-campaign-banner';
                docCampaign.type = DefineConst.PLATFORM_CAMPAIGN.BANNER;
                break;
            case DefineConst.TYPE_CAMPAIGN.MAIL:
                modalCampaign = 'modal-campaign-mail';
                docCampaign.type = DefineConst.PLATFORM_CAMPAIGN.MAIL;
                break;
            default:
        }
        setCampaign(docCampaign);
        setIsEdit(false);
        window.LadiUI.showModal(modalCampaign);
    };

    const eventChangePublicStatus = (e, item) => {
        const status = e.target.checked ? DefineConst.STATUS.PUBLIC : DefineConst.STATUS.PRIVATE;
        const data = {
            id: item._id,
            status,
        };
        dispatch(actionCampaign.update(data));
    };

    const getCampImage = (item) => {
        const img = item.image_profile.path;
        let _imgSrc = '';
        if (img.startsWith("http")) {
            _imgSrc = img;
        } else {
            console.log(img);
            _imgSrc = 'https://w.ladicdn.com/' + img;
        }
        
        return _imgSrc;
    }

    const getPlatform = (item) => {
        let platform = <></>;
        switch (item.type) {
            case DefineConst.PLATFORM_CAMPAIGN.FACEBOOK:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/fb.svg" className="mr-8" />Facebook
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.INSTAGRAM:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/instagram.svg" width={16} height={16} className="mr-8" />Instagram
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.LINKEDIN:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/linkedin.svg" width={16} height={16} className="mr-8" />Linkedin
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.TWITTER:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/twitter.svg" width={16} height={16} className="mr-8" />Twitter
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.BANNER:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/banner-ads-icon.svg" width={16} height={16} className="mr-8" />Banner
                </div>;
                break;
            case DefineConst.PLATFORM_CAMPAIGN.MAIL:
                platform = <div>
                    <img src="https://w.ladicdn.com/ladiui/ladishare/mail.svg" width={16} height={16} className="mr-8" />Mail
                </div>;
                break;

            default:
        }
        return platform;
    };

    const eventClickDelete = (item) => {
        setSelectedCampaign(item);
        window.LadiUI.showModal('delete-modal');
    };

    const eventOkDelete = () => {
        dispatch(actionCampaign.delete({ id: selectedCampaign._id }, cbList));
    };

    const eventClickPrioritized = (item) => {
        const data = {
            id: item._id,
            is_priority: true,
        };
        dispatch(actionCampaign.update(data, true, cbList));
    };

    const eventClickEdit = (item) => {
        let modalCampaign;
        switch (item.type) {
            case DefineConst.PLATFORM_CAMPAIGN.FACEBOOK:
            case DefineConst.PLATFORM_CAMPAIGN.INSTAGRAM:
            case DefineConst.PLATFORM_CAMPAIGN.LINKEDIN:
            case DefineConst.PLATFORM_CAMPAIGN.TWITTER:
                modalCampaign = 'modal-campaign-society';
                break;
            case DefineConst.PLATFORM_CAMPAIGN.BANNER:
                modalCampaign = 'modal-campaign-banner';
                break;
            case DefineConst.PLATFORM_CAMPAIGN.MAIL:
                modalCampaign = 'modal-campaign-mail';
                break;
            default:
        }
        setCampaign({ ...campaign, ...item });
        setIsEdit(true);
        window.LadiUI.showModal(modalCampaign);
    };

    useEffect(() => {
        dispatch(actionAffiliateGroup.list({ pluck: ['_id', 'name'] }));
    }, []);

    useEffect(() => {
        cbList();
    }, [conditions]);

    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui flex-row">
                    <div className="ladiui main-title">
                        {t('CAMPAIGNS.LIST_CAMPAIGN')}
                    </div>
                </div>
                <button type="button" className="ladiui btn btn-primary btn-lg" onClick={eventSelectPlatform}>
                    <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                    <div className="ladiui btn-custom-text ">{t('CAMPAIGNS.ADD_CAMPAIGN')}</div>
                </button>
            </div>
            <div className="ladiui content-main-tool flex-row align-item-baseline">
                <div>
                    <SearchCondition
                        keywordProp={conditions.keyword}
                        eventSearchKeyboardProp={eventSearchKeyboard}
                        eventClickSearchButtonProp={eventClickSearchButton}
                    />
                </div>
                <div className="ladiui btn-group custom-btn-list-landingpage">
                    <LadiDropdown
                        listProp={[{ name: 'Chọn nền tảng', value: null }, ...DefineConst.PLATFORM_SOCIETY_ARRAY]}
                        valueProp={null}
                        hideLabel={true}
                        validProp={true}
                        defaultTextProp={'Chọn nền tảng'}
                        cbProp={eventSelectApp}
                    />
                </div>
            </div>

            <table className="ladiui table text-left">
                <thead>
                    <tr className="ladiui table-vertical header">
                        <th scope="col">Tiêu đề</th>
                        <th scope="col" className="ladiui text-pre">Ảnh đại diện</th>
                        <th scope="col" className="ladiui text-pre">Nền tảng</th>
                        <th scope="col">Link gốc</th>
                        <th scope="col" className="ladiui text-pre">Công khai</th>
                        <th scope="col" className="ladiui text-pre">Ngày cập nhật</th>
                        <th scope="col" className="ladiui text-pre">Lượt click</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {listCampaign.items.length > 0 ? listCampaign.items.map((item, key) => (<tr key={item._id} className="ladiui table-vertical main">
                        <td>
                            <div style={{ maxWidth: '500px' }}> <Link to={`/campaigns/${item._id}`} className="ladiui link">{item.title}</Link></div>
                        </td>
                        <td>
                            {item.image_profile
                                ? <div className="img-banner-preview">
                                    {item.image_profile.path ? <>
                                        <img src={getCampImage(item)} height="40" style={{ borderRadius: '2px', border: '1px solid #F1F3F4', padding: '2px' }} />
                                    </> : <></>}
                                </div>
                                : <></>}
                        </td>
                        <td className="ladiui text-pre">
                            {getPlatform(item)}
                        </td>
                        <td>
                            <div style={{ wordBreak: 'break-all' }}><a href={item.url} target="_blank" rel="noreferrer" className="ladiui link">{item.url}</a></div>
                        </td>
                        <td>
                            <label className="ladiui switch m-0">
                                <input type="checkbox" defaultChecked={item.status !== DefineConst.STATUS.PRIVATE}
                                    onChange={(e) => eventChangePublicStatus(e, item)}
                                />
                                <span className="ladiui slider round"></span>
                            </label>
                        </td>
                        <td>{BaseHelper.formatStrToDate(item.updated_at, 'DD/MM/YYYY')}</td>
                        <td className="ladiui text-center">{item.number_click || 0}</td>
                        <td className="ladiui-dropdown">
                            <div className="ladiui btn-group">
                                <div className="ladiui dropdown">
                                    <button data-toggle="dropdown"
                                        className="ladiui-btn-dropdown dropdown-toggle">
                                        <i className="ladiui icon icon-ldp-dot"></i>
                                    </button>
                                    <ul className="ladiui dropdown-menu custom-dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1">
                                        <li onClick={() => eventClickPrioritized(item)}>
                                            <a className="ladiui dropdown-item" href="#/">
                                                Ưu tiên
                                            </a>
                                        </li>
                                        <li onClick={() => eventClickEdit(item)}><a className="ladiui dropdown-item" href="#/">
                                            Chỉnh sửa
                                        </a></li>
                                        {/* <li>
                                            <Link className="ladiui dropdown-item" to={`/campaigns/${item._id}`}>Xem nội dung</Link>
                                        </li> */}
                                        <li onClick={() => eventClickDelete(item)}>
                                            <a className="ladiui dropdown-item lastChild" href="#/">
                                                Xoá
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </td>
                    </tr>))
                        : <tr><td colSpan={6}>{t('NO_DATA')}</td></tr>}

                </tbody>
            </table>
            {listCampaign.items.length > 0
                ? <LadiPagination
                    conditionsProp={conditions}
                    listName={t('CAMPAIGNS.CAMPAIGN')}
                    items={listCampaign.items}
                    total={listCampaign.total}
                    limitAction={selectLimit}
                    pageAction={selectPage}
                /> : ''}

            <div className="ladiui modal" id="modal-select-platform">
                <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                    <div className="ladiui modal-content custom-ldp">
                        <div className="ladiui modal-header custom-ldp">
                            <label className="ladiui title-text-form-lever1">Chọn loại chiến dịch</label>
                            <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="list-app-integrate grid-2">
                            <div className="box-app-integrate" onClick={() => eventSelectCampaign(DefineConst.TYPE_CAMPAIGN.SOCIETY)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src="https://w.ladicdn.com/ladiui/ladishare/society.svg" /></div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">
                                        <label>Mạng xã hội</label>
                                        <p>Các mạng xã hội như Facebook, Instagram, Twitter,...</p>
                                    </div>
                                </div>
                            </div>
                            <div className="box-app-integrate" onClick={() => eventSelectCampaign(DefineConst.TYPE_CAMPAIGN.BANNER)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src="https://w.ladicdn.com/ladiui/ladishare/banner-ads.svg" /></div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">
                                        <label>Banner</label>
                                        <p>Banner quảng cáo.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="box-app-integrate" onClick={() => eventSelectCampaign(DefineConst.TYPE_CAMPAIGN.MAIL)}>
                                <div className="box-app-integrate-header flex-row">
                                    <div className="box-app-integrate-logo"><img src="https://w.ladicdn.com/ladiui/ladishare/mail.svg" /></div>
                                    <div className="box-app-integrate-option"></div>
                                </div>
                                <div className="box-app-integrate-content">
                                    <div className="box-app-integrate-content-description">
                                        <label>Email</label>
                                        <p>Hòm thư điện tử gmail.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCampaignSociety campaignProp={campaign} isEdit={isEdit} cb={() => dispatch(actionCampaign.list(conditions))} />
            <ModalCampaignBanner campaignProp={campaign} isEdit={isEdit} cb={() => dispatch(actionCampaign.list(conditions))} />
            <ModalCampaignMail campaignProp={campaign} isEdit={isEdit} cb={() => dispatch(actionCampaign.list(conditions))} />
            <ConfirmModal
                id="delete-modal"
                title="Bạn có chắc muốn xoá chiến dịch này không?"
                content="Sau khi xoá, chiến dịch sẽ bị xoá vĩnh viễn bạn sẽ không thể xem lại được nữa"
                onOk={() => eventOkDelete()}
                cancelText={t('CAMPAIGNS.CANCELED')}
                okText={t('CAMPAIGNS.DELETE_CAMPAIGN')}
                classBtn="btn-danger"
            />
        </div>
    </>;
}

export default (withTranslation()(CampaignAdvertiser));
