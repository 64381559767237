import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import routes from '../../routes';
import SidebarItem from '../../components/SidebarItem';
import config from '../../config/config';
import actionStore from '../../redux/futures/store/actions';
import BaseHelper from '../../helpers/BaseHelper';
import appConfig from '../../config/app';

import actionAffiliate from '../../redux/futures/affiliate/actions';

import authActions from '../../redux/futures/auth/actions';

// eslint-disable-next-line max-lines-per-function
function Sidebar(props) {
    const { scopes, store } = props.userInfo;
    const availableItems = routes.getItemByScopes(scopes);
    const dispatch = useDispatch();
    const affiliateDashboardSetting = useSelector((state) => (state.store.affiliateDashboardSetting));
    const user = useSelector((state) => state.store.userInfo);
    const storeInfo = useSelector((state) => state.store.userInfo.store);

    const [isShowMenuAff, setIsShowMenuAff] = useState(false);

    const eventSignOut = (store) => {
        if (config.APP_AFFILIATE) {
            BaseHelper.setCookie(appConfig.COOKIE.STORE_ALIAS, store.alias);
        }
        dispatch(authActions.signOut());
    };

    useEffect(() => {
        if (config.APP_AFFILIATE) {
            const data = {
                field: 'affiliate_dashboard_setting',
            };
            dispatch(actionStore.getAffiliateDashboardSetting(data));
        }
        getDomCallback();
        getIframePopupX();

        let domainUrl = window.location.href;
        if (domainUrl.includes('affiliate.ladipage.com') || domainUrl.includes('aff.dev.ladishare.com')) {
            setIsShowMenuAff(true);
        }

    }, []);

    useEffect(() => {
        if (affiliateDashboardSetting && config.APP_AFFILIATE) {
            let link = document.querySelector('link[rel~="icon"]');
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = affiliateDashboardSetting.icon;
        }
    }, [affiliateDashboardSetting]);

    useEffect(() => {
        if (user && config.APP_AFFILIATE) {
            const nameStore = user.store ? user.store.name : 'Ladishare - Affiliate Marketing Platform';
            let title = document.querySelector('title');
            if (!title) {
                title = document.createElement('title');
                document.getElementsByTagName('head')[0].appendChild(title);
            }
            title.innerText = nameStore;
        }
    }, [user]);

    const getDomCallback =  () => {
        var frame = document.querySelector(".ladi-popupx-inline iframe");
        if (frame?.clientHeight > 0) {

            document.body.style.setProperty(
                '--notification-height',
                frame?.clientHeight + 'px'
            );
        } else {
            setTimeout(() => {
                getDomCallback();
            }, [100])
        }
    };

    const getIframePopupX = () => {
        var frame = document.querySelector(".ladi-popupx[data-ladipage-id='66418f519ae79400116994ca']");
        if (frame) {
            BaseHelper.setCookie("POPUP1_SHOW", "ON");
        } else {
            setTimeout(() => {
                getIframePopupX();
            }, [100])
        }
    }

    // const handleShowPopupxCommissionPolicy = () => {
    //     BaseHelper.setCookie("COMMISSION_POLICY", 1);
    // }

    useEffect(() => {
        window.addEventListener(
            'message',
            (event) => {
                let dataParse = typeof (event.data) == "string" ? JSON.parse(event.data) : event.data;
                if (dataParse) {
                    if (dataParse.type == 'LADISHARE_SUBMIT_SURVEY_FORM') {
                        // BaseHelper.setCookie("IS_DONE_POPUP_SURVEY", 1);
                        let payload = {
                                    user_data : {
                                        complete_survey: true
                                    }
                        };

                        dispatch(actionAffiliate.updateUserData(payload));
                    }

                    if (dataParse.type == 'LADISHARE_SURVEY_POPUP') {
                                if (dataParse.action == 'GET_INFO') {
                                    var iframe = document.querySelector('iframe.ladi-popupx[data-ladipage-id="66418f519ae79400116994ca"]');
                                    iframe.contentWindow.postMessage(JSON.stringify({
                                        type: 'LADISHARE_SURVEY_POPUP',
                                        action: 'SET_INFO',
                                        user: user.user,
                                        store: storeInfo
                                    }), '*');
                                }
                    }
                }
            },
            false
        );
        return () => {
            window.removeEventListener('message', () => {});
        };
    }, []);

    return (<>
        <aside className="ladiui aside pc" >
            <nav className="ladiui nav">
                <ul className="ladiui menu">
                    <li className="ladiui logo-item logo">
                        <a href="/" className="ladiui flex-row-menu-item">
                            {affiliateDashboardSetting && affiliateDashboardSetting.icon && config.APP_AFFILIATE
                                ? <img src={affiliateDashboardSetting.icon} height="32" alt="" />
                                : <img src="https://w.ladicdn.com/ladiui/ladishare/icon-ladishare.svg" alt="" />
                            }
                        </a>
                    </li>
                    <li className="ladiui logo-item logo-hover">
                        <a href="/" className="ladiui flex-row-menu-item">
                            {affiliateDashboardSetting && affiliateDashboardSetting.logo && config.APP_AFFILIATE
                                ? <img src={affiliateDashboardSetting.logo} height="32" alt="" />
                                : <img src="https://w.ladicdn.com/ladiui/ladishare/logo-ladishare.svg" alt="" />
                            }
                        </a>
                    </li>
                    {scopes === 'ADVERTISER'
                        ? map(availableItems, (item) => (
                            <SidebarItem
                                keyStr={item.keyStr}
                                key={item.key}
                                classLi={item.classLi}
                                to={item.to}
                                classNav={item.classNav}
                                classIcon={item.classIcon}
                                title={item.title}
                                scopes={scopes}
                            />
                        ))
                        : map(availableItems, (item) => {
                                    if (!isShowMenuAff) {
                                         return (
                                             <SidebarItem
                                                 keyStr={item.keyStr}
                                                 key={item.key}
                                                 classLi={item.classLi}
                                                 to={config.APP_AFFILIATE ? item.to : `/${store.alias}${item.to}`}
                                                 classNav={item.classNav}
                                                 classIcon={item.classIcon}
                                                 title={item.title}
                                                 scopes={scopes}
                                             />
                                         )
                                    } else {
                                        if (!item.key.includes('request-invoices') && !item.key.includes('refs')) {
                                            return (
                                                <SidebarItem
                                                    keyStr={item.keyStr}
                                                    key={item.key}
                                                    classLi={item.classLi}
                                                    to={config.APP_AFFILIATE ? item.to : `/${store.alias}${item.to}`}
                                                    classNav={item.classNav}
                                                    classIcon={item.classIcon}
                                                    title={item.title}
                                                    scopes={scopes}
                                                />
                                            )
                                        }
                                    }
                                    // <SidebarItem
                                    //     keyStr={item.keyStr}
                                    //     key={item.key}
                                    //     classLi={item.classLi}
                                    //     to={config.APP_AFFILIATE ? item.to : `/${store.alias}${item.to}`}
                                    //     classNav={item.classNav}
                                    //     classIcon={item.classIcon}
                                    //     title={item.title}
                                    //     scopes={scopes}
                                    // />
                        })
                    }

                    {!isShowMenuAff
                        ? "" : (
                            <>
                                <li className="ladiui menu-item" id="commission-policy-menu-item">
                                    <a className="ladiui flex-row-menu-item">
                                        <i className="ladiui icon icon-menu-commissions"></i>
                                        <span className="ladiui menu-text">Chính sách hoa hồng</span>
                                    </a>
                                </li>
                                <li className="ladiui menu-item">
                                    <a className="ladiui flex-row-menu-item" href="https://ladipage.vn/chinhsachaffiliate" target="_blank">
                                        <i className="ladiui icon icon-help-documents"></i>
                                        <span className="ladiui menu-text">Điều khoản Affiliate</span>
                                    </a>
                                </li>
                                <li className="ladiui menu-item">
                                    <a className="ladiui flex-row-menu-item" href="https://ladipage.vn/tai-lieu-affiliate?utm_source=nopaid-0-affladipage-ldp-menudoc-huongdan-aff" target="_blank">
                                        <i className="ladiui icon icon-help-documents"></i>
                                        <span className="ladiui menu-text">Hướng dẫn</span>
                                    </a>
                                </li>
                            </>
                        )
                    }
                </ul>
            </nav>
        </aside>

            <aside className="ladiui aside mobile">
                <nav>
                    <ul className='ladiui menu-mobile'>
                        {scopes === 'ADVERTISER'
                            ? map(availableItems, (item) => (
                                <SidebarItem
                                keyStr={item.keyStr}
                                key={item.key}
                                classLi={item.classLi}
                                to={item.to}
                                classNav={item.classNav}
                                classIcon={item.classIcon}
                                title={item.title}
                                scopes={scopes}
                            />
                        ))
                        : map(availableItems, (item) => (
                            <SidebarItem
                                keyStr={item.keyStr}
                                key={item.key}
                                classLi={item.classLi}
                                to={config.APP_AFFILIATE ? item.to : `/${store.alias}${item.to}`}
                                classNav={item.classNav}
                                classIcon={item.classIcon}
                                title={item.title}
                                scopes={scopes}
                            />
                        ))
                    }
                    <li className="ladiui menu-item menu-item-more">
                        <a className="ladiui flex-row-menu-item" href="#/" aria-current="page">
                            <i className="ladiui icon icon-menu-more"></i>
                            <span className="ladiui menu-text">More</span>
                        </a>
                        <ul className="ladiui sub-menu">
                            <li className="ladiui sub-menu-item">
                                <a className="ladiui flex-row-menu-item" href="/account-info" aria-current="page">
                                    <i className="ladiui icon icon-menu-account-setting"></i>
                                    <span className="ladiui menu-text">Quản lý tài khoản</span>
                                </a>
                            </li>
                            <li className="ladiui sub-menu-item">
                                <a className="ladiui flex-row-menu-item" href="/refs" aria-current="page">
                                    <i className="ladiui icon icon-menu-affiliates"></i>
                                    <span className="ladiui menu-text">Affiliate cấp dưới</span>
                                </a>
                            </li>
                            <li className="ladiui sub-menu-item">
                                <a className="ladiui flex-row-menu-item" href="/links" aria-current="page">
                                    <i className="ladiui icon icon-menu-links"></i>
                                    <span className="ladiui menu-text">Quản lý Link</span>
                                </a>
                            </li>
                            <li className="ladiui sub-menu-item">
                                <a className="ladiui flex-row-menu-item" href="/invoices" aria-current="page">
                                    <i className="ladiui icon icon-menu-invoices"></i>
                                    <span className="ladiui menu-text">Hoá đơn</span>
                                </a>
                            </li>
                            <li className="ladiui sub-menu-item">
                                <a className="ladiui flex-row-menu-item" href="/request-invoices" aria-current="page">
                                    <i className="ladiui icon icon-menu-request-invoices"></i>
                                    <span className="ladiui menu-text">Yêu cầu rút tiền</span>
                                </a>
                            </li>

                            <li className="ladiui sub-menu-item">
                                <a className="ladiui flex-row-menu-item" href="#/" aria-current="page" onClick={() => eventSignOut(user.store)}>
                                    <i className="ladiui icon icon-menu-logout"></i>
                                    <span className="ladiui menu-text">Đăng xuất</span>
                                </a>
                            </li>

                        </ul>
                    </li>
                </ul>
            </nav>
        </aside>
    </>
    );
}

export default (withTranslation()(Sidebar));
