import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data, cb) => ({
    type: types.LIST_STAFF,
    meta: {
        endpoint: endpoint.STAFF_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const create = (data, cb) => ({
    type: types.CREATE_STAFF,
    meta: {
        endpoint: endpoint.STAFF_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const show = (id, cb) => ({
    type: types.SHOW_STAFF,
    meta: {
        endpoint: endpoint.STAFF_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const update = (data, cb) => ({
    type: types.UPDATE_STAFF,
    meta: {
        endpoint: endpoint.STAFF_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteStaff = (data, cb) => ({
    type: types.DELETE_STAFF,
    meta: {
        endpoint: endpoint.STAFF_DELETE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteMulti = (data, cb) => ({
    type: types.DELETE_MULTI_STAFF,
    meta: {
        endpoint: endpoint.DELETE_MULTI_STAFF,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

export default {
    list,
    create,
    show,
    update,
    delete: deleteStaff,
    deleteMulti
};
