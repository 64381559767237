export const LIST_INTEGRATE_APP = 'LIST_INTEGRATE_APP';
export const CREATE_INTEGRATE_APP = 'CREATE_INTEGRATE_APP';
export const SHOW_INTEGRATE_APP = 'SHOW_INTEGRATE_APP';
export const UPDATE_INTEGRATE_APP = 'UPDATE_INTEGRATE_APP';
export const UPDATE_STATUS_INTEGRATE_APP = 'UPDATE_STATUS_INTEGRATE_APP';
export const DELETE_INTEGRATE_APP = 'DELETE_INTEGRATE_APP';
export const INTEGRATE_APP_HARAVAN = 'INTEGRATE_APP_HARAVAN';
export const INTEGRATE_APP_SAPO = 'INTEGRATE_APP_SAPO';
export const GET_ACCESS_SAPO = 'GET_ACCESS_SAPO';
export const INTEGRATE_APP_NHANH = 'INTEGRATE_APP_NHANH';
export const GET_ACCESS_NHANH = 'GET_ACCESS_NHANH';
export const INTEGRATE_APP_SHOPIFY = 'INTEGRATE_APP_SHOPIFY';
export const GET_ACCESS_SHOPIFY = 'GET_ACCESS_SHOPIFY';
export const INTEGRATE_APP_SHOPIFY_WEBHOOK = 'INTEGRATE_APP_SHOPIFY_WEBHOOK';
export const INTEGRATE_APP_GETFLY_WEBHOOK = 'INTEGRATE_APP_GETFLY_WEBHOOK';
