import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const getUserInfo = () => ({
    type: types.GET_USER_INFO,
    meta: {
        endpoint: endpoint.STORE_INFO,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    },
});

const getApiKeyInfo = () => ({
    type: types.GET_API_KEY_INFO,
    meta: {
        endpoint: endpoint.GET_API_KEY_INFO,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    },
});

const reCreateApiKey = (data, cb) => ({
    type: types.RE_CREATE_API_KEY,
    meta: {
        endpoint: endpoint.RE_CREATE_API_KEY,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const update = (data) => ({
    type: types.UPDATE_STORE,
    meta: {
        endpoint: endpoint.STORE_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
    },
});

const show = (id) => ({
    type: types.SHOW_STORE,
    meta: {
        endpoint: endpoint.STORE_SHOW,
        method: REQUEST_METHOD.POST,
        body: { id },
        hasAuth: true,
    },
});

const getAffiliateSetting = (data) => ({
    type: types.GET_AFFILIATE_SETTING,
    meta: {
        endpoint: endpoint.AFFILIATE_SETTING_GET,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const getCookieSetting = (data) => ({
    type: types.GET_COOKIE_SETTING,
    meta: {
        endpoint: endpoint.GET_COOKIE_SETTING,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const setCookieSetting = (data) => ({
    type: types.SET_COOKIE_SETTING,
    meta: {
        endpoint: endpoint.SET_COOKIE_SETTING,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
    },
});

const updateAffiliateSetting = (data) => ({
    type: types.UPDATE_AFFILIATE_SETTING,
    meta: {
        endpoint: endpoint.AFFILIATE_SETTING_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
    },
});

const listSender = (data, cb) => ({
    type: types.LIST_SENDER_EMAIL,
    meta: {
        endpoint: endpoint.LIST_SENDER_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
    },
});

const createSenderEmail = (data, cb) => ({
    type: types.CREATE_SENDER_EMAIL,
    meta: {
        endpoint: endpoint.CREATE_SENDER_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const updateSenderEmail = (data, cb) => ({
    type: types.UPDATE_SENDER_EMAIL,
    meta: {
        endpoint: endpoint.UPDATE_SENDER_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const showSenderEmail = (id, cb) => ({
    type: types.SHOW_SENDER_EMAIL,
    meta: {
        endpoint: endpoint.SHOW_SENDER_EMAIL,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const deleteSenderEmail = (data, cb) => ({
    type: types.DELETE_SENDER_EMAIL,
    meta: {
        endpoint: endpoint.DELETE_SENDER_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const makeAsDefaultSenderEmail = (id, cb) => ({
    type: types.MAKE_AS_DEFAULT_SENDER_EMAIL,
    meta: {
        endpoint: endpoint.MAKE_AS_DEFAULT_SENDER_EMAIL,
        method: REQUEST_METHOD.POST,
        body: {
            id,
        },
        hasAuth: true,
        cb,
    },
    hasSubAction: true,
});

const verifySenderEmail = (data, cb) => ({
    type: types.VERIFY_SENDER_EMAIL,
    meta: {
        endpoint: endpoint.VERIFY_SENDER_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const verifyMultiSenderEmail = (data, cb) => ({
    type: types.VERIFY_MULTI_SENDER_EMAIL,
    meta: {
        endpoint: endpoint.VERIFY_MULTI_SENDER_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const deleteMultiSenderEmail = (data, cb) => ({
    type: types.DELETE_MULTI_SENDER_EMAIL,
    meta: {
        endpoint: endpoint.DELETE_MULTI_SENDER_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb,
    },
});

const getAffiliateDashboardSetting = (data) => ({
    type: types.GET_AFFILIATE_DASHBOARD_SETTING,
    meta: {
        endpoint: endpoint.GET_AFFILIATE_DASHBOARD_SETTING,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const getAffiliateDashboardSettingPublic = (data) => ({
    type: types.GET_AFFILIATE_DASHBOARD_SETTING_PUBLIC,
    meta: {
        endpoint: endpoint.GET_AFFILIATE_DASHBOARD_SETTING_PUBLIC,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const getAffiliateSettingPublic = (data) => ({
    type: types.GET_AFFILIATE_SETTING_PUBLIC,
    meta: {
        endpoint: endpoint.GET_AFFILIATE_DASHBOARD_SETTING_PUBLIC,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const uploadLogo = (data, cb) => ({
    type: types.UPLOAD_LOGO_STORE,
    meta: {
        endpoint: endpoint.UPLOAD_LOGO_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        checkPublisher: false,
        cb,
    },
});

const uploadIcon = (data, cb) => ({
    type: types.UPLOAD_ICON_STORE,
    meta: {
        endpoint: endpoint.UPLOAD_ICON_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        checkPublisher: false,
        cb,
    },
});

const updateAffiliateDashboardSetting = (data, showNotify = true) => ({
    type: types.UPDATE_AFFILIATE_DASHBOARD_SETTING,
    meta: {
        endpoint: endpoint.UPDATE_AFFILIATE_DASHBOARD_SETTING,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify,
    },
});

const verifyDomain = (data, showNotify = true) => ({
    type: types.VERIFY_DOMAIN_AFFILIATE,
    meta: {
        endpoint: endpoint.VERIFY_DOMAIN_AFFILIATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify,
    },
});

const getBlockIPSetting = (data) => ({
    type: types.GET_BLOCK_IP_SETTING,
    meta: {
        endpoint: endpoint.GET_BLOCK_IP_SETTING,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const updateBlockIPSetting = (data, showNotify = true) => ({
    type: types.UPDATE_BLOCK_IP_SETTING,
    meta: {
        endpoint: endpoint.UPDATE_BLOCK_IP_SETTING,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify,
    },
});

const getAffiliateChangeGroupSetting = (data) => ({
    type: types.GET_AFFILIATE_CHANGE_GROUP_SETTING,
    meta: {
        endpoint: endpoint.GET_AFFILIATE_CHANGE_GROUP_SETTING,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const updateAffiliateChangeGroupSetting = (data, showNotify = true, cb) => ({
    type: types.UPDATE_AFFILIATE_CHANGE_GROUP_SETTING,
    meta: {
        endpoint: endpoint.UPDATE_AFFILIATE_CHANGE_GROUP_SETTING,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify,
        cb,
    },
});

const getTemplateEmail = (data) => ({
    type: types.GET_TEMPLATE_EMAIL,
    meta: {
        endpoint: endpoint.GET_TEMPLATE_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const updateTemplateEmail = (data, showNotify = true, cb) => ({
    type: types.UPDATE_TEMPLATE_EMAIL,
    meta: {
        endpoint: endpoint.UPDATE_TEMPLATE_EMAIL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify,
        cb,
    },
});

const getRequestWithdrawSetting = (data) => ({
    type: types.GET_REQUEST_WITHDRAW_SETTING,
    meta: {
        endpoint: endpoint.GET_REQUEST_WITHDRAW_SETTING,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

export default {
    getUserInfo,
    getApiKeyInfo,
    reCreateApiKey,
    update,
    show,
    getAffiliateSetting,
    updateAffiliateSetting,
    listSender,
    createSenderEmail,
    updateSenderEmail,
    showSenderEmail,
    deleteSenderEmail,
    makeAsDefaultSenderEmail,
    verifySenderEmail,
    verifyMultiSenderEmail,
    deleteMultiSenderEmail,
    getAffiliateDashboardSetting,
    uploadLogo,
    uploadIcon,
    updateAffiliateDashboardSetting,
    getAffiliateDashboardSettingPublic,
    getAffiliateSettingPublic,
    verifyDomain,
    getBlockIPSetting,
    updateBlockIPSetting,
    getAffiliateChangeGroupSetting,
    updateAffiliateChangeGroupSetting,
    getTemplateEmail,
    updateTemplateEmail,
    getCookieSetting,
    setCookieSetting,
    getRequestWithdrawSetting,
};
