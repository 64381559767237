import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BaseHelper from '../../../helpers/BaseHelper';
import actionCommission from '../../../redux/futures/commission/actions';
import DefineConst from '../../commissions/DefineConst';

function RecentlyCommission(props) {
    const { t } = props;

    const defaultConditions = {
        page: 1,
        limit: 5,
        sort: { created_at: -1 },
    };

    const dispatch = useDispatch();
    const [conditions, setConditions] = useState(defaultConditions);
    const listCommission = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.commissions.commissions }));
    const affiliates = useSelector((state) => (state.commissions.affiliates));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const getStatus = (status) => {
        let statusContent = <></>;
        switch (status) {
            case DefineConst.COMMISSION_STATUS.PENDING:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-warning"></i>
                        <div>
                            {t('COMMISSIONS.COMMISSION_STATUS.PENDING')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.COMMISSION_STATUS.APPROVED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {t('COMMISSIONS.COMMISSION_STATUS.APPROVED')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.COMMISSION_STATUS.INVOICED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-success"></i>
                        <div>
                            {t('COMMISSIONS.COMMISSION_STATUS.INVOICED')}
                        </div>
                    </div>
                </>;
                break;
            case DefineConst.COMMISSION_STATUS.CANCELED:
                statusContent = <>
                    <div className="ladiui flex-row">
                        <i className="ladiui icon icon-ldp-status-mute"></i>
                        <div>
                            {t('COMMISSIONS.COMMISSION_STATUS.CANCELED')}
                        </div>
                    </div>
                </>;
                break;
            default:
        }
        return statusContent;
    };

    useEffect(() => {
        dispatch(actionCommission.list(conditions));
    }, []);

    return <>
        <div className="ladiui box-statistics box-no-padding box-no-heigh" style={{ flex: '1 1 auto', display: 'block' }}>
            <div className="ladiui box-title flex-row">
                <label className="ladiui">5 hoa hồng gần nhất</label>
                <Link to='/commissions' className="ladiui link">Xem thêm &rsaquo;</Link>
            </div>
            <div className="box-body" style={{ padding: '0 24px' }}>
                <table className="ladiui table text-left">
                    <thead>
                        <tr className="ladiui table-vertical header">
                            <th scope="col">Affiliate</th>
                            <th scope="col">Số tiền</th>
                            <th scope="col">Hoa hồng</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listCommission.items ? listCommission.items.map((item) => (
                                <tr key={item._id} className="ladiui table-vertical main">
                                    <td>
                                        {affiliates.map((affiliate) => (affiliate._id === item.affiliate_id
                                            ? <Link key={affiliate._id} to={`/affiliates/${affiliate._id}`}>{affiliate.email}</Link>
                                            : ''))}
                                    </td>
                                    <td>{BaseHelper.formatMoney(item.total, '', systemCurrency)}</td>
                                    <td>{BaseHelper.formatMoney(item.amount, '', systemCurrency)}</td>
                                    <td>
                                        {getStatus(item.status)}
                                    </td>
                                </tr>
                            )) : <tr className="ladiui table-vertical main">
                                <td colSpan={4}>
                                    {t('NO_DATA')}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="box-footer"></div>
        </div>
    </>;
}

export default (withTranslation()(RecentlyCommission));
