import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseHelper from '../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function
function PackageInfo(props) {
    const { t } = props;
    const user = useSelector((state) => state.store.userInfo);

    return <>
        <div className="ladiui content-main-title flex-row">
            <div className="ladiui">
                <div className="ladiui main-title">
                    Thông tin gói sử dụng
                </div>
                <div className="package-info">
                    <div className="ladiui container-package-type">
                        <div className="ladiui item-package">
                            <div className="ladiui text-package-type">Tên gói</div>
                            <div className="ladiui value-package-type custom">{user.store.pkg_name}</div>
                        </div>
                        <div className="ladiui item-package">
                            <div className="ladiui text-package-type">Ngày hết hạn</div>
                            <div className="ladiui value-package-type">{user.store.pkg_exp ? BaseHelper.formatStrToDate(user.store.pkg_exp, 'DD/MM/YYYY') : 'Không thời hạn'}</div>
                        </div>
                        <div className="ladiui item-package">
                            <div className="ladiui text-package-type">Số affiliate còn lại có thể tạo</div>
                            <div className="ladiui value-package-type">{user.store.affiliate_total ? (user.store.affiliate_total !== -1 ? user.store.affiliate_total : 'Không giới hạn') : 0}</div>
                        </div>
                        <div className="ladiui item-package">
                            <div className="ladiui text-package-type">Số chuyển đổi còn lại</div>
                            <div className="ladiui value-package-type">{user.store.conversion_per_month ? (user.store.conversion_per_month !== -1 ? user.store.conversion_per_month : 'Không giới hạn') : 0}</div>
                        </div>
                    </div>
                    <div className="ladiui modal-footer custom-package-type">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <a href="https://www.ladishare.vn/" target="_blank" className="ladiui btn small txt body3 btn-primary" rel="noreferrer">Gia hạn &amp; Nâng cấp</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(PackageInfo));
