import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../../components/ConfirmModal';
import LadiDropdownMenu from '../../../components/LadiDropdownMenu';
import actionRedux from '../../../redux/futures/affiliate/actions';
import DefineConst from '../DefineConst';
import actionAffiliateGroup from '../../../redux/futures/affiliate_group/actions';
import { status } from '../../../config/app';
import ModalInvoiceAffiliate from './ModalInvoiceAffiliate';

// eslint-disable-next-line max-lines-per-function
function CheckAllActionAffiliate(props) {
    const { t, cb } = props;
    const ids = props.ids || [];

    const listAction = [
        { value: 'ACCEPT_ALL', name: t('AFFILIATES.ACTIVE_ALL') },
        { value: 'LOCK_ALL', name: t('AFFILIATES.LOCK_ALL') },
        { value: 'DELETE_ALL', name: t('AFFILIATES.DELETE_ALL_SELECTED') },
        { value: 'CHANGE_AFFILIATE_GROUP_ALL', name: 'Đổi nhóm' },
        { value: 'INVOICED_ALL', name: 'Lập hoá đơn' },
    ];

    const dispatch = useDispatch();
    const [actionSelected, setActionSelected] = useState(null);
    const [modalConfirm, setModalConfirm] = useState({});
    const [classBtnConfirm, setClassBtnConfirm] = useState('btn-primary');
    const [selectedAffiliateGroup, setSelectedtAffiliateGroup] = useState({});
    const affiliateGroups = useSelector((state) => (state.affiliateGroups.groups ? state.affiliateGroups.groups.items : []));

    const eventSelectAction = (action) => {
        if (action === 'CHANGE_AFFILIATE_GROUP_ALL') {
            setActionSelected(action);
            window.LadiUI.showModal('modal-select-affiliate-group');
        } else if (action === 'INVOICED_ALL') {
            setActionSelected(action);
            window.LadiUI.showModal('modal-invoice-selected-affiliate');
        } else {
            let dataModal = {};
            switch (action) {
                case 'DELETE_ALL':
                    setClassBtnConfirm('btn-danger');
                    dataModal.title = 'Bạn có chắc chắn muốn xóa toàn bộ affiliate được chọn?';
                    dataModal.content = <div>Những affiliate bị xóa sẽ không thể khôi phục.</div>;
                    break;
                case 'ACCEPT_ALL':
                    setClassBtnConfirm('btn-primary');
                    dataModal.title = 'Bạn có chắc chắn muốn duyệt toàn bộ affiliate được chọn?';
                    dataModal.content = <div>
                        Những affiliate ở trạng thái chờ duyệt mới được phép chuyển sang trạng thái đã duyệt
                    </div>;
                    break;
                case 'LOCK_ALL':
                    setClassBtnConfirm('btn-primary');
                    dataModal.title = 'Bạn có chắc chắn muốn khóa toàn bộ affiliate được chọn?';
                    dataModal.content = <div>Bạn có thể thay đổi trạng thái affiliate bất cứ lúc nào</div>;
                    break;
                default:
            }
            setActionSelected(action);
            setModalConfirm(dataModal);
            window.LadiUI.showModal('modal-confirm-action-all');
        }
    };

    const eventOkActionAll = () => {
        if (actionSelected) {
            switch (actionSelected) {
                case 'DELETE_ALL':
                    dispatch(actionRedux.deleteMultiAffiliate({ ids }, cb));
                    break;
                case 'ACCEPT_ALL':
                    dispatch(actionRedux.updateStatusMultiAffiliate({ ids, status: DefineConst.AFFILIATE_STATUS.ACTIVED }, cb));
                    break;
                case 'LOCK_ALL':
                    dispatch(actionRedux.updateStatusMultiAffiliate({ ids, status: DefineConst.AFFILIATE_STATUS.LOCKED }, cb));
                    break;
                default:
            }
        }
    };

    const eventAddAffiliateGroup = () => {
        window.LadiUI.showModal('modal-add-affiliate-group');
    };

    const eventOkSelectAffiliateGroup = () => {
        dispatch(actionRedux.updateMultiAffiliate({ ids, affiliate_group_id: selectedAffiliateGroup._id }, cb));
    };

    const eventCancelSelectAffiliateGroup = () => {
        setSelectedtAffiliateGroup({});
        window.LadiUI.closeModal('modal-add-affiliate-group');
    };

    return ids.length > 0
        ? <>
            <div className="ladiui ladi-check-all-action">
                <LadiDropdownMenu
                    labelDropdown={'Chọn hành động'}
                    listProp={listAction}
                    defaultTextProp={t('COMMON.ACTION')}
                    hideLabel={true}
                    cbProp={eventSelectAction}
                    classSize=""
                    classDropdown=""
                />
            </div>
            <ConfirmModal
                id="modal-confirm-action-all"
                title={modalConfirm.title || ''}
                content={modalConfirm.content || ''}
                onOk={() => eventOkActionAll()}
                cancelText={t('COMMISSIONS.CANCELED')}
                okText={t('COMMISSIONS.OK')}
                classBtn={classBtnConfirm || 'btn-primary'}
            />
            <div className="ladiui modal" id="modal-select-affiliate-group">
                <div className="ladiui modal-dialog modal-sm">
                    <div className="ladiui modal-content custom-ldp">
                        <div className="ladiui modal-header custom-ldp">
                            <label className="ladiui title-text-form-lever1">Thay đổi nhóm Affiliate</label>
                            <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="ladiui flex mt-24">
                            <label className="ladiui title-text-form-lever2" style={{ width: '200px' }}>Chọn nhóm affiliate</label>
                            <div className="ladiui btn-group ml-24">
                                <div className="ladiui dropdown">
                                    <button
                                        className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                                        id="dropdownMenuButton3" data-toggle="dropdown"
                                        style={{ width: '300px' }}>
                                        <i></i>
                                        <span className="ladiui dropdown-text">
                                            {selectedAffiliateGroup.name || 'Chọn nhóm affiliate'}
                                        </span>
                                    </button>
                                    <ul className="ladiui dropdown-menu" style={{ width: '100%' }}>
                                        <li><a className="ladiui btn btn-link" href="#/"
                                            onClick={eventAddAffiliateGroup}>+ Thêm mới nhóm affiliate</a></li>
                                        <li><a className="ladiui btn btn-link" href="#/"
                                            onClick={() => (dispatch(actionAffiliateGroup.list({ pluck: ['_id', 'name'] })))}>- Tải lại danh sách nhóm</a></li>
                                        {affiliateGroups.map((values) => <li key={values._id}
                                            onClick={() => setSelectedtAffiliateGroup(values)}>
                                            <a className="ladiui dropdown-item" href="#/">{values.name}</a>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="ladiui modal-footer custom-ldp">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button type="button" data-dismiss="modal" data-target="white-page"
                                    className="ladiui btn btn-secondary bold" onClick={eventCancelSelectAffiliateGroup}>Hủy</button>
                                <button type="button" className="ladiui btn btn-primary" onClick={eventOkSelectAffiliateGroup}>Cập nhật</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
            <ModalInvoiceAffiliate ids={ids} cb={cb} />
        </>
        : <></>;
}

export default (withTranslation()(CheckAllActionAffiliate));
