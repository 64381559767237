export default {
    STATUS: {
        ON: true,
        OFF: false,
    },
    INTEGRATE_APPS_TYPE: {
        LADISALES: 'LADISALES',
        SDK: 'SDK',
        HARAVAN: 'HARAVAN',
        SHOPIFY: 'SHOPIFY',
        GETFLY: 'GETFLY',
        WORDPRESS: 'WORDPRESS',
        SAPO: 'SAPO',
        NHANH: 'NHANH',
        LADIFLOW: 'LADIFLOW',
    },
    INTEGRATE_APPS: {
        LADISALES: {
            type: 'LADISALES',
            name_default: 'Ladisales',
            logo: 'https://w.ladicdn.com/ladiui/ladisales/icons/logo-ladisales-fw.svg',
            description: 'Tích hợp để đồng bộ dữ liệu đơn hàng các thông tin sản phẩm.',
        },
        SDK: {
            type: 'SDK',
            name_default: 'SDK',
            logo: 'https://w.ladicdn.com/ladishare/logo-sdk.svg',
            description: 'Tích hợp để đồng bộ dữ liệu đơn hàng các thông tin sản phẩm.',
        },
        HARAVAN: {
            type: 'HARAVAN',
            name_default: 'Haravan',
            logo: 'https://w.ladicdn.com/ladishare/logo-haravan.svg',
            description: 'Tích hợp để đồng bộ dữ liệu đơn hàng các thông tin sản phẩm.',
        },
        SHOPIFY: {
            type: 'SHOPIFY',
            name_default: 'Shopify',
            logo: 'https://w.ladicdn.com/ladishare/logo-shopify.svg',
            description: 'Tích hợp để đồng bộ dữ liệu đơn hàng các thông tin sản phẩm.',
        },
        GETFLY: {
            type: 'GETFLY',
            name_default: 'Getfly',
            logo: 'https://getfly.vn/assets/images/favicon.ico',
            description: 'Tích hợp để đồng bộ dữ liệu đơn hàng các thông tin sản phẩm.',
        },
        WORDPRESS: {
            type: 'WORDPRESS',
            name_default: 'Wordpress',
            logo: 'https://w.ladicdn.com/ladishare/logo-wordpress.svg',
            description: 'Tích hợp để đồng bộ dữ liệu đơn hàng các thông tin sản phẩm.',
        },
        SAPO: {
            type: 'SAPO',
            name_default: 'Sapo',
            logo: 'https://w.ladicdn.com/ladishare/logo-sapo.svg',
            description: 'Tích hợp để đồng bộ dữ liệu đơn hàng các thông tin sản phẩm.',
        },
        NHANH: {
            type: 'NHANH',
            name_default: 'Nhanh',
            logo: 'https://w.ladicdn.com/v2/source/builder/img/editor_sdk/icon-ldp-logo-nhanhvn.svg',
            description: 'Tích hợp để đồng bộ dữ liệu đơn hàng các thông tin sản phẩm.',
        },
        LADIPAGE: {
            type: 'LADIPAGE',
            name_default: 'Ladipage',
            logo: 'https://w.ladicdn.com/57b167caca57d39c18a1c57e/page_h-20220401071258.svg',
            description: 'Tích hợp để đồng bộ dữ liệu đơn hàng các thông tin sản phẩm.',
        },
        LADIFLOW: {
            type: 'LADIFLOW',
            name_default: 'LadiFlow',
            logo: 'https://w.ladicdn.com/ladiui/icons/ldicon-colored-brand-ladiflow.svg',
            description: 'Tích hợp để đồng bộ dữ liệu đơn hàng các thông tin sản phẩm.',
        },
    },
};
