import React from 'react';
import actionStore from '../../redux/futures/store/actions';
export default class Footer extends React.Component {

    
    render() {
        return (
            <div className="none">                
                <iframe title="print_frame " name="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
            </div>
        );
    }
}
