import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LadiDateRangePicker from '../../../components/LadiDateRangePicker';
import appConfig from '../../../config/app';
import styles from '../../../assets/css/custom.module.css';
import BaseHelper from '../../../helpers/BaseHelper';
import HistoryCommission from './publisher/HistoryCommission';
import ChartCommission from './publisher/ChartCommission';

import actionStatistics from '../../../redux/futures/statistics/actions';

function PublisherReport(props) {
    const { t } = props;
    const dispatch = useDispatch();
    const moment = BaseHelper.getMoment();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [conditions, setConditions] = useState(null);
    const report = useSelector((state) => (state.statistics.dailyReportPublisher));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const getCR = (p1, p2) => {
        if (p2 === 0) return 0;
        const rs = (100 * parseFloat(p1)) / parseFloat(p2);
        return Math.round((rs + Number.EPSILON) * 100) / 100;
    };

    useEffect(() => {
        setFromDate(moment().startOf('month'));
        setToDate(moment());
        setConditions({
            ...conditions, date_from: moment().startOf('month').format('YYYY-MM-DD 00:00:01'), date_to: moment().format('YYYY-MM-DD 23:59:59'), filter_type: 'THIS_MONTH',
        });
    }, []);

    useEffect(() => {
        if (conditions) {
            if ((conditions.date_from && conditions.date_to) || (!conditions.date_from && !conditions.date_to)) {
                dispatch(actionStatistics.dailyReportPublisher(conditions));
            }
        }
    }, [conditions]);
    return <>
        <div className="ladiui content-main-full">
            <div className="ladiui content-main-title content-main-report flex-row">
                <div className="ladiui flex-row header-main-report">
                    <div className="ladiui main-title">
                        Chi tiết báo cáo
                    </div>
                    <div className="calendar-search">
                        <LadiDateRangePicker
                            startDateId="fromDate"
                            endDateId="toDate"
                            startDate={fromDate}
                            endDate={toDate}
                            onDatesChange={({ startDate, endDate }) => {
                                const startDateNew = BaseHelper.getFromDate(startDate);
                                const endDateNew = BaseHelper.getToDate(endDate);
                                setFromDate(startDateNew);
                                setToDate(endDateNew);
                                setConditions({ ...conditions, ...{ date_from: startDateNew ? startDateNew.format('YYYY-MM-DD') : null, date_to: endDateNew ? endDateNew.format('YYYY-MM-DD') : null } });
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => {
                                setFocusedInput(focusedInput);
                            }}
                            showDefaultInputIcon={true}
                            inputIconPosition="after"
                            small={true}
                            hideKeyboardShortcutsPanel={true}
                            customInputIcon={
                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                            }
                            startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                            isOutsideRange={() => false}
                            isDayHighlighted={(date) => moment().isSame(date, 'day')}
                            minimumNights={0}
                            showClearDates={true}
                        />
                    </div>

                </div>
            </div>
            <div className="ladiui content-main-title flex-row gap-24 align-item-top dashboard-publisher">
                <div className="dashboard-left-publisher">
                    <div className="ladiui flex-row gap-24 dashboard-statistics-publisher">
                        <div className={styles['box-statistics-publisher']}>
                            <div className={styles['box-content-publisher-name']} style={{ marginTop: '10px' }}>Chuyển đổi&nbsp;
                                <div className="tooltip">
                                    <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                                    <span className="tooltiptext bottom" tooltiptext="Chuyển đổi = Đơn hàng hoặc Lead"></span>
                                </div>
                            </div>
                            <div className={styles['box-content-publisher-title']}>{BaseHelper.formatNumber(report ? report.totalConvert : 0)}</div>
                        </div>
                        <div className={styles['box-statistics-publisher']}>
                            <div className={styles['box-content-publisher-name']}>Hoa hồng chờ duyệt</div>
                            <div className={styles['box-content-publisher-title']}>{BaseHelper.formatMoney(report ? report.totalPendingCommission : 0, '', systemCurrency)}</div>
                            <div className={styles['box-content-publisher-number']}>
                                <div>{BaseHelper.formatNumber(report ? report.numberPendingCommission : 0)}</div>
                                <div>&nbsp;({getCR(report ? report.numberPendingCommission : 0, report ? report.totalConvert : 0)}%)&nbsp;</div>
                                <div className="tooltip">
                                    <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                                    <span className="tooltiptext bottom left" tooltiptext="CR = Chờ duyệt/Số chuyển đổi"></span>
                                </div>
                            </div>
                        </div>
                        <div className={styles['box-statistics-publisher']}>
                            <div className={styles['box-content-publisher-name']}>Hoa hồng tạm duyệt</div>
                            <div className={styles['box-content-publisher-title']}>{BaseHelper.formatMoney(report ? report.totalApprovedCommission : 0, '', systemCurrency)}</div>
                            <div className={styles['box-content-publisher-number']}>
                                <div>{BaseHelper.formatNumber(report ? report.numberApprovedCommission : 0)}</div>
                                <div>&nbsp;({getCR(report ? report.numberApprovedCommission : 0, report ? report.numberPendingCommission : 0)}%)&nbsp;</div>
                                <div className="tooltip">
                                    <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                                    <span className="tooltiptext top" tooltiptext="CR = Tạm duyệt/Chờ duyệt"></span>
                                </div>
                            </div>
                        </div>
                        <div className={styles['box-statistics-publisher']}>
                            <div className={styles['box-content-publisher-name']}>Hoa hồng đã duyệt</div>
                            <div className={styles['box-content-publisher-title']}>{BaseHelper.formatMoney(report ? report.totalInvoicedCommission : 0, '', systemCurrency)}</div>
                            <div className={styles['box-content-publisher-number']}>
                                <div>{BaseHelper.formatNumber(report ? report.numberInvoicedCommission : 0)}</div>
                                <div>&nbsp;({getCR(report ? report.numberInvoicedCommission : 0, report ? report.numberApprovedCommission : 0)}%)&nbsp;</div>
                                <div className="tooltip">
                                    <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg" alt="" width="13" style={{ marginBottom: '3px' }}></img>
                                    <span className="tooltiptext top left" tooltiptext="CR = Đã duyệt/Tạm duyệt"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ChartCommission conditionsProp={conditions} />
                </div>
                <div className="dashboard-right-publisher">
                    {/* <CampaignPublisher /> */}
                    <HistoryCommission />
                </div>
            </div>
        </div>
    </>;
}

export default (withTranslation()(PublisherReport));
