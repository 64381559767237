import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import NumericFormat from 'react-number-format';
import DefineConst from '../DefineConst';
import actionAppIntegrate from '../../../redux/futures/integrate_app/actions';
import FormHaravan from './forms/FormHaravan';
import FormSapo from './forms/FormSapo';
import FormShopify from './forms/FormShopify';
import FormGetfly from './forms/FormGetfly';
import FormWooCommerce from './forms/FormWooCommerce';
import FormWordpress from './forms/FormWordpress';
import FormLadiSales from './forms/FormLadiSales';
import FormSdk from './forms/FormSdk';
import FormNhanh from './forms/FormNhanh';
import LadiDropdown from '../../../components/LadiDropdown';

// eslint-disable-next-line max-lines-per-function
function ModalSettingAppIntegrate(props) {
    const { t, appIntegrateProp, cb } = props;

    const dispatch = useDispatch();
    const [appIntegrate, setAppIntegrate] = useState(null);

    const eventUpdateSettingAppIntegrate = () => {
        dispatch(actionAppIntegrate.update(appIntegrate, cb));
        window.LadiUI.closeModal('modal-setting-integrate-app');
    };

    const eventForm = (data) => {
        setAppIntegrate({ ...appIntegrate, ...data });
    };

    const renderForm = () => {
        let formContent = <></>;
        switch (appIntegrate.type) {
            case DefineConst.INTEGRATE_APPS_TYPE.LADISALES:
                formContent = <FormLadiSales dataApp={appIntegrate} cb={eventForm} isCreate={false} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.SHOPIFY:
                formContent = <FormShopify dataApp={appIntegrate} cb={eventForm} isCreate={false} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.GETFLY:
                formContent = <FormGetfly dataApp={appIntegrate} cb={eventForm} isCreate={false} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.WOOCOMMERCE:
                formContent = <FormWooCommerce dataApp={appIntegrate} cb={eventForm} isCreate={false} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.HARAVAN:
                formContent = <FormHaravan dataApp={appIntegrate} cb={eventForm} isCreate={false} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.SAPO:
                formContent = <FormSapo dataApp={appIntegrate} cb={eventForm} isCreate={false} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.NHANH:
                formContent = <FormNhanh dataApp={appIntegrate} cb={eventForm} isCreate={false} />;
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.WORDPRESS:
                formContent = <FormWordpress dataApp={appIntegrate} cb={eventForm} isCreate={false} />;
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.SDK:
                formContent = <FormSdk dataApp={appIntegrate} cb={eventForm} isCreate={false} />;
                break;

            default: break;
        }
        return formContent;
    };

    const getPlatformName = () => {
        let formContent;
        switch (appIntegrate.type) {
            case DefineConst.INTEGRATE_APPS_TYPE.LADISALES:
                formContent = '';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.SHOPIFY:
                formContent = 'Shopify';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.GETFLY:
                formContent = 'Getfly';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.WOOCOMMERCE:
                formContent = 'Woocommerce';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.HARAVAN:
                formContent = 'Haravan';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.SAPO:
                formContent = '';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.NHANH:
                formContent = 'Nhanh.vn';
                break;
            case DefineConst.INTEGRATE_APPS_TYPE.WORDPRESS:
                formContent = 'Woocommerce';
                break;

            case DefineConst.INTEGRATE_APPS_TYPE.SDK:
                formContent = 'SDK - Custom API';
                break;

            default: break;
        }
        return formContent;
    };

    const eventSelectUnit = (value) => {
        setAppIntegrate({ ...appIntegrate, discount_for_customer_type: value });
    };

    useEffect(() => {
        if (appIntegrateProp) {
            delete appIntegrateProp.config._id;
            const dataApp = { ...appIntegrateProp, ...appIntegrateProp.config };
            setAppIntegrate({ ...dataApp });
        }
    }, [appIntegrateProp]);
    return <div className="ladiui modal" id="modal-setting-integrate-app">
        <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
            <div className="ladiui modal-content custom-ldp">
                {appIntegrate
                    ? <>
                        <div className="ladiui modal-header custom-ldp">
                            <label className="ladiui title-text-form-lever1" style={{ display: 'flex' }}>
                                <img src={DefineConst.INTEGRATE_APPS[appIntegrate.type].logo} height="24px" />{getPlatformName()}
                            </label>
                            <button type="button" data-dismiss="modal" data-target="white-page" className="ladiui modal-close">
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="box-app-integrate-content-description" style={{ padding: '12px 0 24px 0' }}>
                            {appIntegrate.description}
                        </div>
                        <div className="box-app-integrate-content-setting">
                            {renderForm()}

                            {![DefineConst.INTEGRATE_APPS_TYPE.SDK, DefineConst.INTEGRATE_APPS_TYPE.SHOPIFY, DefineConst.INTEGRATE_APPS_TYPE.GETFLY].includes(appIntegrate.type)
                                ? <>
                                    <div className="ladiui form-group clearfix">
                                        <label className="ladiui title-text-form-lever2 left margin-label" style={{ marginRight: '0' }}>Tự động đồng bộ mã giảm giá</label>
                                        <div className="ladiui item-form switch">
                                            <label className="ladiui switch">
                                                <input type="checkbox" id="auto_sync_coupon_code" name="auto_sync_coupon_code"
                                                    checked={appIntegrate.auto_sync_coupon_code || false}
                                                    onChange={() => setAppIntegrate({ ...appIntegrate, auto_sync_coupon_code: !appIntegrate.auto_sync_coupon_code })}
                                                />
                                                <span className="ladiui slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="ladiui form-group clearfix">
                                        <label className="ladiui title-text-form-lever2 left margin-label" style={{ marginRight: '0' }}>Giảm giá cho khách hàng</label>
                                        <div className="ladiui item-form switch">
                                            <label className="ladiui switch">
                                                <input type="checkbox" id="enable_discount_for_customer" name="enable_discount_for_customer"
                                                    checked={appIntegrate.enable_discount_for_customer || false}
                                                    onChange={() => setAppIntegrate({ ...appIntegrate, enable_discount_for_customer: !appIntegrate.enable_discount_for_customer })}
                                                />
                                                <span className="ladiui slider round"></span>
                                            </label>
                                        </div>
                                        <div className="clearfix font-size-13">Cài đặt giá trị giảm giá mặc định cho các mã khuyến mãi</div>
                                        {appIntegrate.enable_discount_for_customer
                                            ? <div className="ladiui flex clearfix box-gray gap-24">
                                                <div>
                                                    <label className="ladiui title-text-form-lever3 left margin-label">Giảm giá</label>
                                                    <NumericFormat id="discount_for_customer" name="discount_for_customer"
                                                        value={appIntegrate.discount_for_customer}
                                                        allowLeadingZeros
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        thousandSeparator=","
                                                        className="ladiui form-control"
                                                        placeholder="Nhập giá trị"
                                                        onValueChange={(values) => setAppIntegrate({ ...appIntegrate, discount_for_customer: values.value })}
                                                    />
                                                </div>
                                                <div>
                                                    <label className="ladiui title-text-form-lever3 left margin-label">Đơn vị</label>
                                                    <LadiDropdown
                                                        labelDropdown={t('AFFILIATES.STATUS_LABEL')}
                                                        listProp={[{ value: 'PERCENT', name: '%' }, { name: 'VNĐ', value: 'FIXED' }]}
                                                        valueProp={appIntegrate.discount_for_customer_type || 'FIXED'}
                                                        hideLabel={true}
                                                        validProp={true}
                                                        classDropdown="dropdown-custom"
                                                        classSize="btn-size-34"
                                                        defaultTextProp={'%'}
                                                        cbProp={eventSelectUnit}
                                                    />
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <label className="ladiui title-text-form-lever3 left margin-label">Ghi đè mã giảm giá cũ</label>
                                                    <div className="ladiui item-form switch">
                                                        <label className="ladiui switch m-0">
                                                            <input type="checkbox" id="overwrite_discount" name="overwrite_discount"
                                                                checked={appIntegrate.overwrite_discount || false}
                                                                onChange={() => setAppIntegrate({ ...appIntegrate, overwrite_discount: !appIntegrate.overwrite_discount })}
                                                            />
                                                            <span className="ladiui slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> : <></>}
                                    </div>
                                </>
                                : <></>}
                        </div>
                        <div className="ladiui modal-footer custom-ldp">
                            <div></div>
                            <div className="ladiui footer-modal-btn">
                                <button type="button" data-dismiss="modal" data-target="white-page"
                                    className="ladiui btn btn-secondary">Hủy bỏ</button>
                                <button type="button" className="ladiui btn btn-primary" onClick={eventUpdateSettingAppIntegrate}>Lưu thông tin</button>
                            </div>

                        </div>
                    </> : <></>
                }
            </div>
        </div>
    </div >;
}

export default (withTranslation()(ModalSettingAppIntegrate));
