/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import BaseHelper from '../../../helpers/BaseHelper';
import AffiliateDropdownMultiSelect from './AffiliateDropdownMultiSelect';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import actionStore from '../../../redux/futures/store/actions';
import actionInvoice from '../../../redux/futures/invoice/actions';
import ListCommissionInvoice from './ListCommissionInvoice';
import LadiDropdown from '../../../components/LadiDropdown';
import DefineConst from '../../affiliates/DefineConst';

function ModalCreateInvoice(props) {
    const moment = BaseHelper.getMoment();
    const { t, conditionsProp } = props;
    const dispatch = useDispatch();

    const [conditions, setConditions] = useState({});
    const [isCheckCondition, setIsCheckCondition] = useState(false);
    const [purchaseNote, setPurchaseNote] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [startFocused, setStartFocused] = useState();
    const [endFocused, setEndFocused] = useState();
    const [checkAll, setCheckAll] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [conditionAffiliate, setConditionAffiliate] = useState({});
    const [affiliatePassedCustom, setAffiliatePassedCustom] = useState([]);
    const [commissionChange, setCommissionChange] = useState(null);

    const affiliatePassed = useSelector((state) => (state.affiliates.passedAffiliate ? state.affiliates.passedAffiliate.passedAffiliates : []));
    const setting = useSelector((state) => (state.store.affiliateSetting));
    const listAffiliateFilter = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.affiliates.filterAffiliates }));
    const systemCurrency = useSelector((state) => (state.store.userInfo.store.currency));

    const eventCancel = () => {
        window.LadiUI.closeModal('modal-create-invoice');
        setIsCheckCondition(false);
        setPurchaseNote(null);
        setConditions(null);
        setFromDate(null);
        setToDate(null);
        setCheckAll(false);
        setSelectedIds([]);
    };

    const actionFilter = (type) => {
        let startDateNew = null;
        let endDateNew = null;
        switch (type) {
            case 'TODAY':
                startDateNew = moment();
                endDateNew = moment();
                break;
            case 'YESTERDAY':
                startDateNew = moment().subtract(1, 'day');
                endDateNew = moment().subtract(1, 'day');
                break;
            case '15DAYSAGO':
                startDateNew = moment().subtract(15, 'day');
                endDateNew = moment();
                break;
            case '30DAYSAGO':
                startDateNew = moment().subtract(30, 'day');
                endDateNew = moment();
                break;
            case '90DAYSAGO':
                startDateNew = moment().subtract(90, 'day');
                endDateNew = moment();
                break;
            case 'THIS_MONTH':
                startDateNew = moment().startOf('month');
                endDateNew = moment();
                break;
            case 'LAST_MONTH':
                startDateNew = moment().subtract(1, 'month').startOf('month');
                endDateNew = moment().subtract(1, 'month').endOf('month');
                break;
            default:
        }

        if (!conditions || (conditions && type !== conditions.filter_type)) {
            setFromDate(startDateNew);
            setToDate(endDateNew);
            setConditions({
                ...conditions, date_from: startDateNew.format('YYYY-MM-DD 00:00:01'), date_to: endDateNew.format('YYYY-MM-DD 23:59:59'), filter_type: type,
            });
        }
    };

    const cbCreateInvoiceMulti = () => {
        window.LadiUI.closeModal('modal-create-invoice');
        setIsCheckCondition(false);
        setPurchaseNote(null);
        setConditions(null);
        setFromDate(null);
        setToDate(null);
        setCheckAll(false);
        setSelectedIds([]);
        dispatch(actionInvoice.list(conditionsProp));
    };

    const createInvoice = () => {
        let affiliateInvoices = [];
        for (let i = 0; i < affiliatePassedCustom.length; i++) {
            if (selectedIds.includes(affiliatePassedCustom[i]._id)) {
                affiliateInvoices.push(affiliatePassedCustom[i]);
            }
        }

        if (affiliateInvoices.length <= 0) {
            window.LadiUI.toast('danger', '', 'Vui lòng chọn affiliate để lên hoá đơn', 5000, 'bottom-left');
            return;
        }

        const data = {
            affiliatePassed: affiliateInvoices,
            date_from: fromDate ? `${fromDate.format('YYYY-MM-DD')} 00:00:01` : null,
            date_to: toDate ? `${toDate.format('YYYY-MM-DD')} 23:59:59` : null,
            note: purchaseNote,
        };
        dispatch(actionAffiliate.createInvoiceMultiAffiliate(data, cbCreateInvoiceMulti));
    };

    const checkConditionInvoice = () => {
        const affiliateIds = [];

        if (conditions && conditions.affiliateIds && conditions.affiliateIds.length > 0) {
            for (let i = 0; i < conditions.affiliateIds.length; i++) {
                affiliateIds.push(conditions.affiliateIds[i]._id);
            }
        }
        const data = { ...conditions, ids: affiliateIds };
        dispatch(actionAffiliate.getPassedAffiliate(data, () => setIsCheckCondition(true)));
    };

    const eventChooseDate = (date, start) => {
        const dateFormat = BaseHelper.getFromDate(date);
        if (start) {
            setFromDate(dateFormat);
            setConditions({
                ...conditions, date_from: date ? date.format('YYYY-MM-DD') : null, filter_type: 'CUSTOM',
            });
        } else {
            setToDate(dateFormat);
            setConditions({
                ...conditions, date_to: date ? date.format('YYYY-MM-DD') : null, filter_type: 'CUSTOM',
            });
        }
    };

    const eventCheck = (e) => {
        const id = e.target.value;
        let ids = [];
        if (e.target.checked) {
            ids = [...selectedIds, ...[id]];
        } else {
            ids = selectedIds.filter((item) => item !== id);
        }
        setSelectedIds(ids);
    };

    const eventCheckMulti = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element-invoice-modal');
            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        setSelectedIds(ids);
    };

    const cbListAffiliateDropdown = (keyword, idIgnore = []) => {
        const dataConditions = { ...conditionAffiliate, keyword };
        if (idIgnore.length > 0) {
            dataConditions._id = { $nin: idIgnore };
        }
        dispatch(actionAffiliate.listFilter(dataConditions));
    };

    const selectMultiDropdownAffiliate = (item) => {
        setConditions({ ...conditions, affiliateIds: item });
    };

    const chooseCommission = (item) => {
        let affiliateSelect = item;
        for (let i = 0; i < affiliatePassed.length; i++) {
            if (item._id === affiliatePassed[i]._id) {
                affiliateSelect = affiliatePassed[i];
            }
        }
        setCommissionChange({ all: affiliateSelect, select: item });
        window.LadiUI.showModal('modal-select-commission-invoice');
    };

    const cbSelectCommission = (affiliate) => {
        const data = [];
        for (let i = 0; i < affiliatePassedCustom.length; i++) {
            if (affiliatePassedCustom[i]._id === affiliate._id) {
                data.push(affiliate);
            } else {
                data.push(affiliatePassedCustom[i]);
            }
        }
        setAffiliatePassedCustom(data);
    };

    const getTotalInvoiced = () => {
        if (affiliatePassedCustom.length <= 0) return BaseHelper.formatMoney(0, '', systemCurrency);

        let total = 0;
        for (let i = 0; i < affiliatePassedCustom.length; i++) {
            if (selectedIds.includes(affiliatePassedCustom[i]._id)) {
                total += parseFloat(affiliatePassedCustom[i].totalCommissionValue);
            }
        }
        return BaseHelper.formatMoney(total, '', systemCurrency);
    };

    const getListTypeAffiliate = () => {
        return [
            { name: 'Cá nhân', value: DefineConst.AFFILIATE_TYPE.PERSONAL },
            { name: 'Công ty', value: DefineConst.AFFILIATE_TYPE.COMPANY },
        ];
    };

    const eventSelectType = (type) => {
        setConditions({ ...conditions, type });
    };

    useEffect(() => {
        setIsCheckCondition(false);
        setAffiliatePassedCustom([]);
    }, [conditions]);

    useEffect(() => {
        const data = {
            field: 'affiliate_setting',
        };
        dispatch(actionStore.getAffiliateSetting(data));
    }, []);

    useEffect(() => {
        if (affiliatePassed && affiliatePassed.length > 0) setAffiliatePassedCustom(affiliatePassed);
    }, [affiliatePassed]);

    return <>
        <div className="ladiui modal bd-example-modal-lg" id="modal-create-invoice">
            <div className="ladiui modal-dialog modal-dialog-centered modal-lg">
                <div className="ladiui modal-content custom-ldp" style={{ fontWeight: '400' }}>
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">Tạo hoá đơn affiliate</label>
                        <button type="button" data-dismiss="modal" data-target="modal-export-commission" className="ladiui modal-close" onClick={eventCancel}>
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>

                    <div className="ladiui form-group-custom">
                        {setting && setting.limit_invoice_status
                            ? <label className="ladiui title-text-form-lever2" htmlFor="amount">* Số tiền cho hóa đơn phải lớn hơn hoặc bằng:
                                {BaseHelper.formatMoney(setting ? setting.limit_invoice_value : 0)}</label>
                            : <></>
                        }
                        {setting && setting.limit_customer_status
                            ? <label className="ladiui title-text-form-lever2" htmlFor="amount">* Số khách hàng phải lớn hơn hoặc bằng:
                                {BaseHelper.formatNumber(setting ? setting.limit_customer_value : 0)} khách hàng</label>
                            : <></>
                        }
                    </div>

                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2" >Chọn khoảng thời gian để lập hoá đơn</label>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <>
                                <div className="ladiui">
                                    {/* <label className="ladiui title-text-form-lever2">Ngày bắt đầu</label> */}
                                    <div className="group-input-custom custom-form-dynamic time-expired-plan-payment">
                                        <SingleDatePicker
                                            date={fromDate} // momentPropTypes.momentObj or null
                                            onDateChange={(date) => eventChooseDate(date, true)} // PropTypes.func.isRequired
                                            focused={startFocused} // PropTypes.bool
                                            onFocusChange={({ focused }) => setStartFocused(focused)} // PropTypes.func.isRequired
                                            numberOfMonths={1}
                                            showClearDate={true}
                                            noBorder={false}
                                            placeholder="Ngày bắt đầu"
                                            readOnly={true}
                                            // disabled={paymentTime.type !== DefineConst.PAYMENT_TERM_TYPE.CUSTOM}
                                            isOutsideRange={(day) => false}
                                            showDefaultInputIcon={true}
                                            inputIconPosition="after"
                                            small={true}
                                            customInputIcon={
                                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="ladiui">
                                    {/* <label className="ladiui title-text-form-lever2">Ngày kết thúc</label> */}
                                    <div className="group-input-custom custom-form-dynamic time-expired-plan-payment">
                                        <SingleDatePicker
                                            date={toDate} // momentPropTypes.momentObj or null
                                            onDateChange={(date) => eventChooseDate(date, false)} // PropTypes.func.isRequired
                                            focused={endFocused} // PropTypes.bool
                                            onFocusChange={({ focused }) => setEndFocused(focused)} // PropTypes.func.isRequired
                                            numberOfMonths={1}
                                            showClearDate={true}
                                            noBorder={false}
                                            placeholder="Ngày kết thúc"
                                            readOnly={true}
                                            // disabled={paymentTime.type !== DefineConst.PAYMENT_TERM_TYPE.CUSTOM}
                                            isOutsideRange={(day) => false}
                                            showDefaultInputIcon={true}
                                            inputIconPosition="after"
                                            small={true}
                                            customInputIcon={
                                                <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                            <button className={`ladiui btn btn-calendar sevendaysago ${conditions && conditions.filter_type === '15DAYSAGO' ? '' : 'btn-outline-light'}`}
                                onClick={() => actionFilter('15DAYSAGO')}
                            >15 ngày</button>
                            <button className={`ladiui btn btn-calendar thirtydaysago ${conditions && conditions.filter_type === '30DAYSAGO' ? '' : 'btn-outline-light'}`}
                                onClick={() => actionFilter('30DAYSAGO')}
                            >30 ngày</button>
                            <button className={`ladiui btn btn-calendar thismonth ${conditions && conditions.filter_type === 'THIS_MONTH' ? '' : 'btn-outline-light'}`}
                                onClick={() => actionFilter('THIS_MONTH')}
                            >Tháng này</button>
                            <button className={`ladiui btn btn-calendar lastmonth ${conditions && conditions.filter_type === 'LAST_MONTH' ? '' : 'btn-outline-light'}`}
                                onClick={() => actionFilter('LAST_MONTH')}
                            >Tháng trước</button>
                        </div>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <div style={{ width: '100%' }}>
                                <label className="ladiui title-text-form-lever2" style={{ marginTop: '12px' }}>Chọn affiliate để kiểm tra</label>
                                <div>
                                    <AffiliateDropdownMultiSelect unCheckedListProp={listAffiliateFilter.items} cb={selectMultiDropdownAffiliate} cbList={cbListAffiliateDropdown} />
                                </div>
                            </div>
                            <div>
                                <label className="ladiui title-text-form-lever2" style={{ marginTop: '12px' }}>Loại affiliate</label>
                                <div>
                                    <LadiDropdown
                                        listProp={getListTypeAffiliate()}
                                        valueProp={conditions ? conditions.type : null}
                                        hideLabel={true}
                                        validProp={true}
                                        classDropdown="dropdown-custom"
                                        classSize=""
                                        defaultTextProp="Chọn loại"
                                        cbProp={eventSelectType}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ladiui form-group-custom">
                            <button className="ladiui btn btn-primary" onClick={checkConditionInvoice}>Kiểm tra</button>
                        </div>
                    </div>

                    {isCheckCondition
                        ? <>
                            {affiliatePassedCustom && affiliatePassedCustom.length > 0
                                ? <>
                                    <label className="ladiui title-text-form-lever2" style={{ marginTop: '24px' }}>Chọn affiliate mà bạn muốn lập hoá đơn</label>
                                    <div style={{ maxHeight: '500px', overflowY: 'scroll', marginTop: '12px' }}>
                                        <table className="ladiui table text-left" style={{ marginTop: 0 }}>
                                            <thead>
                                                <tr className="ladiui table-vertical header">
                                                    <th className="ladiui col-first" scope="col">
                                                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-all-invoice"
                                                            name="checkbox" checked={checkAll} onChange={eventCheckMulti} />
                                                    </th>
                                                    <th scope="col">Họ tên</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Mã</th>
                                                    <th scope="col">Loại</th>
                                                    <th scope="col">Số hoa hồng</th>
                                                    <th className="ladiui text-right">Tổng tiền</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {affiliatePassedCustom.map((item, index) => <tr className="ladiui table-vertical main" key={item._id}>
                                                    <td scope="row">
                                                        <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox checkbox-element-invoice-modal"
                                                            name="checkbox" checked={selectedIds.includes(item._id)}
                                                            value={item._id} onChange={eventCheck} />
                                                    </td>
                                                    <td>{item.fullname}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.code}</td>
                                                    <td>{item.type === DefineConst.AFFILIATE_TYPE.COMPANY ? 'Công ty' : 'Cá nhân'}</td>
                                                    <td className="ladiui text-right">{item.commissions.length}</td>
                                                    <td className="ladiui text-right">
                                                        {BaseHelper.formatMoney(item.totalCommissionValue || 0, '', systemCurrency)}
                                                    </td>
                                                    <td>
                                                        <button className="ladiui btn btn-sm btn-link" onClick={() => chooseCommission(item)}>Chỉnh sửa</button>
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="ladiui form-group-custom">
                                        <label className="ladiui title-text-form-lever2">Tổng số tiền lên hoá đơn: {getTotalInvoiced()}</label>
                                    </div>

                                    <div className="ladiui form-group-custom">
                                        <label className="ladiui title-text-form-lever2">Ghi chú cho hoá đơn</label>
                                        <textarea className="ladiui form-control" placeholder="Nhập nội dung" rows={3}
                                            value={purchaseNote || ''}
                                            onChange={(e) => setPurchaseNote(e.target.value)}
                                        />
                                    </div>

                                    <div className="ladiui modal-footer custom-ldp">
                                        <div></div>
                                        <div className="ladiui footer-modal-btn">
                                            <button type="button" className="ladiui btn btn-secondary bold" onClick={eventCancel}>{t('COMMISSIONS.CANCELED')}</button>
                                            <button type="button" className="ladiui btn btn-primary" onClick={() => createInvoice()}>Tạo hoá đơn</button>
                                        </div>

                                    </div>
                                </> : <><label className="ladiui title-text-form-lever2" style={{ marginTop: '24px' }}>Không tồn tại affiliate nào đủ điều kiện để lập hoá đơn</label></>}
                        </>
                        : <></>
                    }
                </div>
            </div>
        </div>

        <ListCommissionInvoice propCommissions={commissionChange} minInvoice={setting && setting.limit_invoice_status ? setting.limit_invoice_value : 0} cbSelectCommission={cbSelectCommission} />
    </>;
}
export default (withTranslation()(ModalCreateInvoice));
