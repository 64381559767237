import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import appConfig, { status } from '../../config/app';
import config from '../../config/config';
import actionPublisher from '../../redux/futures/publisher/actions';
import PublisherFooter from './PublisherFooter';
import actionStore from '../../redux/futures/store/actions';
import PublisherLeft from './components/PublisherLeft';
import PublisherLogo from './components/PublisherLogo';
import BaseHelper from '../../helpers/BaseHelper';
import ValidatorHelper from '../../helpers/ValidatorHelper';

// eslint-disable-next-line max-lines-per-function
function Register(props) {
    const { t } = props;
    const store = useParams();
    const dispatch = useDispatch();
    const search = useLocation().search;
    const [affiliate, setAffiliate] = useState({ ...store, url: window.location.origin });
    const [readOnlyRef, setReadOnlyRef] = useState(false);
    const affiliateSettingPublic = useSelector((state) => (state.store.affiliateSettingPublic));

    const cbLogin = () => {
        const response = cbLogin.response;
        if (response.data.code === status.OK) {
            const res = response.data.data;
            BaseHelper.setCookie(appConfig.COOKIE.SSID, res.token);
            BaseHelper.setCookie(appConfig.COOKIE.STORE_ID, res.store_id);
            BaseHelper.setCookie(appConfig.COOKIE.STORE_ALIAS, res.store_alias);
            window.location.href = `/${store.store}/`;
        } else {
            window.LadiUI.toast('danger', null, response.data.message);
        }
    };

    const cb = () => {
        const response = cb.response;
        if (response.data.code === status.CREATED) {
            window.LadiUI.toast('success', null, response.data.message, 5000, 'bottom-left');
            const dataLogin = {
                email: affiliate.email,
                password: affiliate.password,
                url: window.location.origin,
                ...store,
            };
            dispatch(actionPublisher.publisherLogin(dataLogin, cbLogin));
        } else {
            window.LadiUI.toast('danger', null, response.data.message, 5000, 'bottom-left');
        }
    };

    const eventChangePhone = (e) => {
        const data = { phone: e.target.value };
        if (affiliateSettingPublic.use_phone_as_affiliate_code) {
            data.code = e.target.value;
        }
        setAffiliate({ ...affiliate, ...data });
    };
    const eventRegister = () => {
        // validate
        if (!affiliate.fullname) {
            window.LadiUI.toast('danger', '', 'Vui lòng điền đầy đủ họ và tên', 5000, 'bottom-left');
            return;
        }
        if (!affiliate.email) {
            window.LadiUI.toast('danger', '', 'Vui lòng điền đầy đủ email', 5000, 'bottom-left');
            return;
        }
        if (!ValidatorHelper.isEmail(affiliate.email)) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập đúng định dạng email', 5000, 'bottom-left');
            return;
        }
        if (!affiliate.phone) {
            window.LadiUI.toast('danger', '', 'Vui lòng điền đầy đủ số điện thoại', 5000, 'bottom-left');
            return;
        }
        if (!affiliate.password) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập mật khẩu', 5000, 'bottom-left');
            return;
        }
        dispatch(actionPublisher.publisherRegister(affiliate, cb));
    };

    const convertCode = (code) => {
        let codeResult = code.toLowerCase();
        codeResult = BaseHelper.formatText(codeResult);
        return codeResult;
    };

    useEffect(() => {
        const data = {
            ...store,
            url: window.location.origin,
            field: 'affiliate_dashboard_setting',
        };
        dispatch(actionStore.getAffiliateDashboardSettingPublic(data));

        data.field = 'affiliate_setting';
        dispatch(actionStore.getAffiliateSettingPublic(data));
    }, []);

    useEffect(() => {
        let refAffiliate = new URLSearchParams(search).get('ref');
        let utmSoucce = new URLSearchParams(search).get('utm_source');

        if (refAffiliate) {
            BaseHelper.setCookie(appConfig.COOKIE.REF, refAffiliate, 90);
            setAffiliate({ ...affiliate, affiliate_ref_code: refAffiliate });
            setReadOnlyRef(true);
        } else {
            refAffiliate = BaseHelper.getCookie(appConfig.COOKIE.REF);
            if (refAffiliate) {
                setAffiliate({ ...affiliate, affiliate_ref_code: refAffiliate });
                setReadOnlyRef(true);
            }
        }

        if (utmSoucce) {
            setAffiliate({ ...affiliate, utm_source: utmSoucce });
        }
    }, [search]);

    return <>
        <div className="publisher-container">

            <div className="box-publisher">
                <PublisherLogo />
                <PublisherLeft />
                <div className="box-publisher-right">
                    <div className="box-title">Đăng ký tài khoản    </div>
                    <div className="box-sub-title">Tham gia cùng chúng tôi để cùng chia sẻ cơ hội kiếm tiền bền vững.</div>
                    <form action="#">
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2">Họ và tên</label>
                            <input type="text" className="ladiui form-control" placeholder="Họ và tên của bạn" name="fullname"
                                required autoFocus
                                value={affiliate.fullname || ''}
                                onChange={(e) => setAffiliate({ ...affiliate, fullname: e.target.value })}
                            />
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2">Địa chỉ email</label>
                            <input type="email" className="ladiui form-control" placeholder="Nhập địa chỉ email của bạn" name="email"
                                required
                                value={affiliate.email || ''}
                                onChange={(e) => setAffiliate({ ...affiliate, email: e.target.value })}
                            />
                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2">Số điện thoại</label>
                            <input type="tel" className="ladiui form-control" placeholder="Nhập số điện thoại của bạn" name="phone"
                                required
                                value={affiliate.phone || ''}
                                onChange={(e) => eventChangePhone(e)}
                            />
                        </div>
                        {affiliateSettingPublic && affiliateSettingPublic.auto_create_discount_code_affiliate
                            ? <></>
                            : <div className="ladiui form-group">
                                <label className="ladiui title-text-form-lever2">Mã Affiliate của bạn</label>
                                <input type="text" className="ladiui form-control" placeholder="Nhập mã affiliate" name="code"
                                    required
                                    value={affiliate.code ? convertCode(affiliate.code) : ''}
                                    onChange={(e) => setAffiliate({ ...affiliate, code: e.target.value })}
                                />
                            </div>
                        }
                        <div className="ladiui form-group">
                            <label className="ladiui title-text-form-lever2">Mật khẩu</label>
                            <input type="password" className="ladiui form-control" placeholder="Nhập mật khẩu" name="password"
                                required
                                value={affiliate.password || ''}
                                onChange={(e) => setAffiliate({ ...affiliate, password: e.target.value })}
                            />
                        </div>
                        {affiliateSettingPublic && affiliateSettingPublic.disable_input_ref_aff
                            ? <></> : <div className="ladiui form-group">
                                <label className="ladiui title-text-form-lever2">Người giới thiệu (Không bắt buộc)</label>
                                <input type="text" className="ladiui form-control input-ref-register" placeholder="Nhập mã người giới thiệu" name="ref"
                                    readOnly={readOnlyRef}
                                    value={affiliate.affiliate_ref_code || ''}
                                    onChange={(e) => setAffiliate({ ...affiliate, affiliate_ref_code: e.target.value })}
                                />
                            </div>}
                        <div className="ladiui form-group">
                            <button type="button" className="ladiui btn btn-warning" onClick={() => eventRegister()}>Đăng ký tài khoản</button>
                        </div>
                        <div className="ladiui form-group">
                            Bạn đã có tài khoản? <Link to={config.APP_AFFILIATE && origin !== config.AFFILIATE_DOMAIN ? '/login' : `/${store.store}/login`} className="ladiui link">Đăng nhập</Link>
                        </div>
                    </form>

                </div>

                <PublisherFooter />
            </div>

        </div>
    </>;
}
export default (withTranslation()(Register));
