import * as types from './types';
import * as commonTypes from '../common/types';

// eslint-disable-next-line max-lines-per-function
export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            };
        }

        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_AFFILIATE_GROUP: {
            return {
                ...state,
                action: types.LIST_AFFILIATE_GROUP,
                groups: action.status ? { items: action.payload.items, total: action.payload.total, pluckItems: action.payload.pluckItems }
                    : { items: [], total: 0, pluckItems: {} },
                status: action.status,
                message: action.message,
            };
        }

        case types.CREATE_AFFILIATE_GROUP: {
            return {
                ...state,
                action: types.CREATE_AFFILIATE_GROUP,
                group: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.SHOW_AFFILIATE_GROUP: {
            return {
                ...state,
                action: types.SHOW_AFFILIATE_GROUP,
                group: action.status ? action.payload.group : {},
                status: action.status,
                message: action.message,
            };
        }

        case types.UPDATE_AFFILIATE_GROUP: {
            return {
                ...state,
                action: types.UPDATE_AFFILIATE_GROUP,
                status: action.status,
                message: action.message,
            };
        }

        case types.DELETE_AFFILIATE_GROUP: {
            return {
                ...state,
                action: types.DELETE_AFFILIATE_GROUP,
                status: action.status,
                message: action.message,
            };
        }

        case types.MAKE_AS_DEFAULT_AFFILIATE_GROUP: {
            return {
                ...state,
                action: types.MAKE_AS_DEFAULT_AFFILIATE_GROUP,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
