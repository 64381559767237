import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../config/app';
import LadiPagination from '../../components/LadiPagination';
import actionHistory from '../../redux/futures/activity/actions';
import actionStaff from '../../redux/futures/staff/actions';
import BaseHelper from '../../helpers/BaseHelper';
import DefineConst from './DefineConst';
import LadiDateRangePicker from '../../components/LadiDateRangePicker';
import LadiDropdown from '../../components/LadiDropdown';
import ListStaffDropdownMultiSelect from './components/ListStaffDropdownMultiSelect';

// eslint-disable-next-line max-lines-per-function
function History(props) {
    const { t } = props;
    const moment = BaseHelper.getMoment();
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        date_from: moment().startOf('month').format('YYYY-MM-DD 00:00:01'),
        date_to: moment().format('YYYY-MM-DD 23:59:59'),
    };

    const [fromDate, setFromDate] = useState(moment().startOf('month'));
    const [toDate, setToDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);

    const dispatch = useDispatch();

    const [conditions, setConditions] = useState(defaultConditions);
    const [conditionStaff, setConditionStaff] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        sort: { created_at: -1 },
    });

    const activities = useSelector((state) => state.activities.activities);
    const listStaff = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.staffs.staffs }));

    const cbLoadList = () => {
        dispatch(actionHistory.list(conditions));
    };

    const selectLimit = (number) => {
        setConditions({ ...conditions, ...{ limit: number, page: 1 } });
    };

    const selectPage = (number) => {
        setConditions({ ...conditions, ...{ page: number } });
    };

    const eventSelectObject = (value) => {
        setConditions({ ...conditions, page: 1, object_type: value, action_type: null });
    };

    const eventSelectAction = (value) => {
        setConditions({ ...conditions, page: 1, action_type: value });
    };

    const selectMultiDropdownStaff = (data) => {
        let values = [];
        let names = [];
        for (let i = 0; i < data.length; i++) {
            values.push(data[i].ladi_uid);
            names.push(data[i].name);
        }

        setConditions({ ...conditions, page: 1, ladi_uids: values });
    };

    const cbListStaff = (keyword, idIgnore = []) => {
        const dataConditions = { ...conditionStaff, keyword };
        if (idIgnore.length > 0) {
            dataConditions._id = { $nin: idIgnore };
        }
        dispatch(actionStaff.list(dataConditions));
    };

    useEffect(() => {
        cbLoadList();
    }, [conditions]);

    useEffect(() => {
        dispatch(actionStaff.list(conditionStaff));
    }, [conditionStaff]);

    const renderListGroup = (item) => (<tr className="ladiui table-vertical main" key={item._id}>
        <td>
            {DefineConst.HISTORY_ACTION[item.action] || ''}
        </td>
        <td>
            {DefineConst.HISTORY_OBJECT[item.object_type] || ''}
        </td>
        <td>
            {item.object_name ? item.object_name : ''}
        </td>
        <td>
            {[item.staff.first_name, item.staff.last_name].join(' ').trim() === '' ? 'Hệ thống' : [item.staff.first_name, item.staff.last_name].join(' ').trim()}
        </td>
        <td>{BaseHelper.formatStrToDate(item.created_at, 'HH:mm DD/MM/YYYY')}</td>
    </tr>);

    return activities
        ? <>
            <div className="ladiui content-main-title flex-row">
                <div className="ladiui">
                    <div className="ladiui main-title">
                        Lịch sử hoạt động
                    </div>
                </div>
            </div>
            <div className="ladiui access content-main-tool flex-row" style={{ marginTop: '10px' }}>
                <div>
                    <LadiDateRangePicker
                        startDateId="fromDate"
                        endDateId="toDate"
                        startDate={fromDate}
                        endDate={toDate}
                        onDatesChange={({ startDate, endDate }) => {
                            const startDateNew = BaseHelper.getFromDate(startDate);
                            const endDateNew = BaseHelper.getToDate(endDate);
                            setFromDate(startDateNew);
                            setToDate(endDateNew);
                            if ((startDateNew && endDateNew) || (!startDateNew && !endDateNew)) {
                                setConditions({ ...conditions, page: 1, ...{ date_from: startDateNew ? startDateNew.format('YYYY-MM-DD 00:00:01') : null, date_to: endDateNew ? endDateNew.format('YYYY-MM-DD 23:59:59') : null } });
                            }
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => {
                            setFocusedInput(focusedInput);
                        }}
                        showDefaultInputIcon={true}
                        inputIconPosition="after"
                        small={true}
                        hideKeyboardShortcutsPanel={true}
                        customInputIcon={
                            <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                        }
                        startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                        endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE.toUpperCase()}
                        isOutsideRange={() => false}
                        isDayHighlighted={(date) => moment().isSame(date, 'day')}
                        minimumNights={0}
                        showClearDates={true}
                    />
                </div>
                <div className="ml-24">
                    <LadiDropdown
                        labelDropdown="Đối tượng"
                        defaultTextProp="Chọn đối tượng"
                        listProp={[{ name: 'Tất cả đối tượng', value: 'ALL_OBJECT' }, ...DefineConst.HISTORY_OBJECT_TYPE_DROPDOWN]}
                        valueProp={conditions.object_type}
                        hideLabel={true}
                        validProp={true}
                        cbProp={eventSelectObject}
                    />
                </div>
                {conditions.object_type && conditions.object_type !== 'ALL_OBJECT'
                    ? <div className="ml-24">
                        <LadiDropdown
                            labelDropdown="Hành động"
                            defaultTextProp="Chọn hành động"
                            listProp={conditions.object_type ? [{ name: 'Tất cả hành động', value: 'ALL_ACTION' }, ...DefineConst.HISTORY_ACTION_TYPE_DROPDOWN[conditions.object_type]] : []}
                            valueProp={conditions.action_type}
                            hideLabel={true}
                            validProp={true}
                            cbProp={eventSelectAction}
                        />
                    </div>
                    : <></>}
                <div className="ml-24">
                    <ListStaffDropdownMultiSelect unCheckedListProp={listStaff.items} cb={selectMultiDropdownStaff} cbList={cbListStaff} />
                </div>
            </div>
            <div className="list-group-affiliate mt-24">
                <table className="ladiui table text-left mt-0">
                    <thead>
                        <tr className="ladiui table-vertical header">
                            <th>Hành động</th>
                            <th>Đối tượng</th>
                            <th>Nội dung</th>
                            <th className="ladiui" scope="col">Người thực hiện</th>
                            <th className="ladiui" scope="col">Thời gian</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activities.items.length > 0
                            ? activities.items.map((values) => (renderListGroup(values)))
                            : (<tr className="ladiui table-vertical main">
                                <td colSpan={4}>
                                    <span className="ladiui small-label">Không có lịch sử nào.</span>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
                {activities.items
                    ? <LadiPagination
                        conditionsProp={conditions}
                        listName={'lịch sử'}
                        items={activities.items}
                        total={activities.total}
                        limitAction={selectLimit}
                        pageAction={selectPage}
                    /> : ''}

            </div>
        </> : '';
}

export default (withTranslation()(History));
